import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { getCarriers } from 'src/@http/carriers'
import CardLabelList from 'src/pages/components/labels/CardLabelAccountList'
import DialogPayment from 'src/pages/components/labels/DialogAddCard'
import { CrudView } from 'src/types/forms/CrudView'
import { AccountCarrierModel } from 'src/types/models/AccountCarriersModels'
import { logger } from 'src/utils/Logger'
import { getKeyObjectSecurity } from 'src/utils/misc'
import appStore from 'src/zustand/app'

import SelectorCourierMultiple from '../components/carrier/SelectorCourierMultiple'
// import AutocompleteCarrierMulti from '../components/carrier/AutocompleteCarrierMulti'
import AutocompleteClientMulti from '../components/clients/AutocompleteClientMulti'
import GenericButton from '../components/generic/GenericButton'
import WrapperSecurity from '../components/generic/WrapperSeurityObject'
import { useCarrierAccount } from '../components/labels-server/label-server-hooks'
import FiltersOrders from '../components/orderEditor/FiltersOrders'
import TextFieldInputAdornment from '../components/orderEditor/TextFieldInputAdornment'

import BalanceAccountTable from './balance-account-details'

const LabelsAccount = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { paramsV2 } = appStore()
  const currentPath = location.pathname
  const [openModalPaymentSuccessful, setOpenModalPaymentSuccessful] = useState(false)
  const [dataPaginationGrid, setDataPaginationGrid] = useState({
    loading: true,
    rows: [],
    rowCount: 0,
    rowsPerPageOptions: [5, 10, 15],
    pageSize: 20,
    page: 1
  })
  const [carrierSelected, setCarrierSelected] = useState<AccountCarrierModel | null>(null)
  const [introducedAmount, setIntroducedAmount] = useState<number>(0)
  const [balanceAccountFilterDates, setBalanceAccountFilterDates] = useState<{
    StartDate: string
    EndDate: string
  }>({
    StartDate: '',
    EndDate: ''
  })
  const [courierIdsFilter, setCourierIdsFilter] = useState<number[]>([])

  useEffect(() => {
    getCarriers(dataPaginationGrid.pageSize, dataPaginationGrid.page)
      .then(res => logger.log('Res:', res))
      .catch(err => logger.log(err))
  })

  const carriersAccountSummary = useCarrierAccount({
    params: {
      Status: 0,
      intCarrierId: 0,
      intCustomerId: paramsV2.CustomerId,
      intShopId: currentPath === '/labels' ? 0 : paramsV2.ShopId,
      PageSize: 50,
      CurrentPage: 1,
      moduleId: currentPath === '/labels' ? 2 : 3
    },
    path: currentPath
  })

  const levelActual = currentPath === '/labels' ? 'customer' : 'client'

  return (
    <Grid container spacing={6}>
      <Typography variant='h5' sx={{ mx: 4, my: 5 }}>
        {t('LABEL_SERVER')}
      </Typography>
      <WrapperSecurity securityKey={getKeyObjectSecurity({
        CUSTOMER: 'add_new_account_customer_btn',
        CLIENT: 'add_new_account_client_btn'
      })}>
        <Grid item xs={12} width={'100%'} textAlign='right'>
          <GenericButton onClick={() => navigate('account/new', { state: { mode: CrudView.ADD, level: levelActual } })}>
            {t('ADD_NEW_ACCOUNT')}
          </GenericButton>
        </Grid>
      </WrapperSecurity>
      <Grid item xs={12}>
        <CardLabelList
          data={carriersAccountSummary}
          setAmount={(amount: number) => setIntroducedAmount(amount)}
          accountSelected={(carrier: any) => setCarrierSelected(carrier)}
          level={levelActual}
        />
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ padding: '1rem' }}>
          <Box display='flex' maxWidth={900} mx='auto' alignItems='center' columnGap={2} my={2}>
            <AutocompleteClientMulti
              size={'small'}
              onSelectClient={client => logger.log('Client en padre:', client)}
              label={t('CLIENT')}
            />
            <SelectorCourierMultiple
              title={"Courier"}
              onHandleSelectedOptions={courierIds => setCourierIdsFilter(courierIds)}
              actualLevel={levelActual}
            />
            <TextFieldInputAdornment sx={{ maxWidth: 500 }} />
          </Box>
          <Grid padding={3} container>
            <FiltersOrders setFilter={filterLimits => setBalanceAccountFilterDates(filterLimits)} />
          </Grid>
          <Grid pt={4}>
            <BalanceAccountTable carrierIds={courierIdsFilter} filterDateLimits={balanceAccountFilterDates} />
          </Grid>
        </Card>
      </Grid>
      <DialogPayment
        introducedAmount={introducedAmount}
        carrierSelected={carrierSelected}
        onFinished={() => setOpenModalPaymentSuccessful(true)}
      />
      <MessagePaymentSuccessful
        open={openModalPaymentSuccessful}
        onClose={() => setOpenModalPaymentSuccessful(false)}
      />
    </Grid>
  )
}

const MessagePaymentSuccessful = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        <Icon fontSize={64} color='green' icon='simple-line-icons:check' />
        <Typography variant='subtitle1' mt={3}>
          {t('PAYMENT_MADE_SUCCESSFULLY')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>Operation number: OP123</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>{t('CLOSE')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default LabelsAccount

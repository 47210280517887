import { FormControlLabel, PaletteMode, Radio, RadioGroup } from '@mui/material'

interface CarrierRateRadioGroupProps {
  typeTableRateSheet: string
  setTypeTableRateSheet: (value: string) => void
  mode: PaletteMode
}

const CarrierRateRadioGroup = ({ typeTableRateSheet, setTypeTableRateSheet, mode }: CarrierRateRadioGroupProps) => {
  return (
    <>
      <RadioGroup row value={typeTableRateSheet} onChange={e => setTypeTableRateSheet(e.target.value)} sx={{ flex: 1 }}>
        <FormControlLabel
          value={'Discount'}
          control={<Radio color={mode === 'light' ? 'secondary' : 'primary'} size='small' />}
          label={'Discount'}
        />
        <FormControlLabel
          value={'Markup'}
          control={<Radio color={mode === 'light' ? 'secondary' : 'primary'} size='small' />}
          label={'Both'}
        />
        <FormControlLabel
          value={'Set Rate'}
          control={<Radio color={mode === 'light' ? 'secondary' : 'primary'} size='small' />}
          label={'Public'}
        />
      </RadioGroup>
    </>
  )
}

export default CarrierRateRadioGroup

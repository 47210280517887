import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, Stack, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
// import PageHeader from 'src/@core/components/page-header'
import { getUsersByAdmin } from 'src/@http/user'
import ServerDataGrid, { ServiceDataGridProps } from 'src/pages/components/generic/ServerDataGrid'
import WrapperSecurity from 'src/pages/components/generic/WrapperSeurityObject'
import { UserGetModel } from 'src/types/models/userModels'
import appStore from 'src/zustand/app'
import userStore from 'src/zustand/user'

import columns from './columns'

export const fakeData: UserGetModel[] = []

const AdminUsersList = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { refreshUserGridDate, setRefreshUserGridDate } = userStore()
  const { paramsV2 } = appStore()
  const service = async ({ currectPage, pageSize }: ServiceDataGridProps) => {
    return await getUsersByAdmin(pageSize, currectPage)
  }

  useEffect(() => {
    setRefreshUserGridDate()
  }, [paramsV2.CustomerId])

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Stack flexDirection='row' justifyContent='space-between'>
              <CardHeader title={t('USER_LIST')} subheader={t('FIND_ALL_THE_USERS_REGISTERED_IN_THE_PLATFORM')} />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  sx={{ mr: 4, mb: 2 }}
                  color='secondary'
                  variant='outlined'
                  startIcon={<Icon icon='mdi:export-variant' fontSize={20} />}
                >
                  {t('EXPORT')}
                </Button>
                <WrapperSecurity securityKey='add_user_btn'>
                  <Button sx={{ mb: 2 }} onClick={() => navigate('/users-admin/new')} variant='contained'>
                    <Typography color={'white'}>{t('ADD_USER')}</Typography>
                  </Button>
                </WrapperSecurity>
              </Box>
            </Stack>
            <Grid container spacing={6}></Grid>
          </CardContent>

          <Divider sx={{ mb: 2 }} />

          <ServerDataGrid columns={columns} service={service} refreshDate={refreshUserGridDate} />
        </Card>
      </Grid>
    </Grid>
  )
}

export default AdminUsersList

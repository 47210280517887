import { mountStoreDevtool } from 'simple-zustand-devtools'
import { getModules, getProfileById, getProfiles, getProfilesByModuleId, getSecurityTypeObjects, SecurityObjectTypeModel } from 'src/@http/profiles'
import { getObjectSecurity } from 'src/@http/profiles'
import { getPermisionList, getUser, User } from 'src/@http/user'
import { ProfileModel, SecurityObjectModel } from 'src/types/models/ProfileModel'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type State = {
  profile: User | null
  profiles: ProfileModel[]
  profileSelected: ProfileModel | null
  securityObjects: SecurityObjectModel[]
  securityObjectsTypes: SecurityObjectTypeModel[]
  refreshObjectGridDate: Date
  permissionList: SecurityObjectModel[]
  profileSelectorSelectedId: number
  securityFormatted: any[]
  modules: any[]
  profilesByModule: any[]
}

type Actions = {
  reset: () => void
  setProfile: (id: number, returnValue?: boolean) => Promise<void | User>
  setProfiles: () => void
  setProfilesById: (id: number) => void
  setSecurityObjects: (list: SecurityObjectModel[] | null, doCall?: boolean) => Promise<void>
  setSecurityObjectsTypes: () => void
  setRefreshObjectGridDate: () => void
  setProfileSelectorSelectedId: (profileId: number) => void
  getPermissionList: (id: number) => Promise<void>
  setModules: () => void
  setProfilesByModule: (moduleId: number) => void
}

const initialState: State = {
  profile: null,
  profileSelected: null,
  securityObjects: [],
  securityObjectsTypes: [],
  refreshObjectGridDate: new Date(),
  profiles: [],
  modules: [],
  permissionList: [],
  profileSelectorSelectedId: 0,
  securityFormatted: [],
  profilesByModule: []
}

const profileStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      setProfile: async (id: number, returnValue?: boolean) => {
        const res = await getUser(id)
        set({ profile: res })

        if (returnValue) return res
      },
      setProfiles: async () => {
        set({ profiles: await getProfiles() })
      },
      setModules: async () => {
        set({ modules: await getModules() })
      },
      getPermissionList: async (id: number) => {
        await get().setSecurityObjects(null, true);
        
        // Trae los objetos de las cosas que el usuario puede ver según su/sus perfiles
        const permissionListName = (await getPermisionList(id)).map(pl => pl.vchName)
        
        const securityFormatted = get().securityObjects.map(so => ({
          name: so.vchName,
          statusActive: permissionListName.includes(so.vchName)
        }))
        set({ securityFormatted: securityFormatted })
      },
      setProfilesById: async (id: number) => {
        set({ profileSelected: await getProfileById(id) })
      },
      setSecurityObjects: async (securityObjects: SecurityObjectModel[] | null, doCall?: boolean) => {
        if (doCall && securityObjects === null) {
          try {
            const res = await getObjectSecurity(999, 1)
            set({ securityObjects: res.list })
          } catch (error) {
            set({ securityObjects: [] })
          }
        } else {
          set({ securityObjects: securityObjects || [] })
        }
      },
      setSecurityObjectsTypes: async () => {
        set({ securityObjectsTypes: await getSecurityTypeObjects() })
      },
      setRefreshObjectGridDate: () => {
        set({ refreshObjectGridDate: new Date() })
      },
      setProfileSelectorSelectedId: (profileId: number) => {
        set({ profileSelectorSelectedId: profileId })
      },
      reset: () => {
        set(initialState)
      },
      setProfilesByModule: async (moduleId: number) => {
        try {
          const res = await getProfilesByModuleId({ Status: 1, moduleId })
          set({ profilesByModule: res })
        } catch (error) {
          set({ profilesByModule: [] })
        }
      }
    }),
    {
      name: 'profile-store'
    }
  )
)

if (process.env.NODE_ENV === 'development') mountStoreDevtool('profile-store', profileStore)

export default profileStore

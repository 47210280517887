import { Toaster } from 'react-hot-toast'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// ** Imports for Handsontable plugin
import { registerAllModules } from 'handsontable/registry'

import { SettingsConsumer, SettingsProvider } from './@core/context/settingsContext'
import ReactHotToast from './@core/styles/libs/react-hot-toast'
import ThemeComponent from './@core/theme/ThemeComponent'
import PrivateRoute from './layouts/components/PrivateRoute'
import UserLayout from './layouts/UserLayout'
import EzAdminUserList from './pages/admin-user/list'
import EzAdminUserNewOrEdit from './pages/admin-user/newOrEdit'
import ClientAdminUsersList from './pages/admin-user-client'
import ClientAdminUserNewOrEdit from './pages/admin-user-client/newOrEdit'
import CustomerAdminUsersList from './pages/admin-users/list'
import CustomerAdminUserNewOrEdit from './pages/admin-users/newOrEdit'
import ClientRateSheet from './pages/c-rate-sheet/index'
import CompaniesEditor from './pages/companies-editor'
import CompaniesAddOrEdit from './pages/companies-editor/newOrEdit'
import CustomerBatch from './pages/components/customer-batch/CustomerBatch'
import UnderConstruction from './pages/components/layouts/UnderConstruction'
import ReportServiceSummary from './pages/components/reports/ReportServiceSummary'
import ShopIntegration from './pages/components/shop-integration/shop-integration'
import ShopInvoice from './pages/components/shop-invoice'
import EventPage from './pages/event'
import ForgotPassword from './pages/forgot-password'
import Home from './pages/home'
import NewDashboard from './pages/home/dashboard'
import HomeClient from './pages/homeClient'
import Invoice from './pages/invoice'
import Import from './pages/invoice/import'
import InvoicePreview from './pages/invoice/preview'
import Labels from './pages/labels'
import BalanceAccountDetails from './pages/labels/balance-account-details'
import LabelsServer from './pages/labelsServer'
import AccountServicesList from './pages/labelsServer/accounts/accountServicesList'
import AddOrEditAccount from './pages/labelsServer/accounts/newOrEdit'
import Shipp from './pages/labelsServer/shipp'
import ShippForm from './pages/labelsServer/shipp/shippForm'
import Login from './pages/login'
import ManageObjectEditor from './pages/objectSecurity-editor/manage-object-editor'
import OrderEditor from './pages/order-editor'
import OrderEdit from './pages/order-editor/edit'
import OrderPreview from './pages/order-editor/fullpreview'
import OrderEditorAddOrEdit from './pages/order-editor/newOrEdit'
import OrderEditorShop from './pages/order-editor/OrderEditorShop'
import ProductEditor from './pages/products-editor'
import Profile from './pages/profile'
import ProfilesEditor from './pages/profiles-editor'
import ProfileAddOrEdit from './pages/profiles-editor/newOrEdit'
import CarriersPage from './pages/rate-sheets/carriers'
import CarrierInvoice from './pages/rate-sheets/carriers/carrierInvoice'
import CarrierAddOrEdit from './pages/rate-sheets/carriers/newOrEdit'
import CarrierRegions from './pages/rate-sheets/carriers/newOrEdit/carrierRegions'
import ShopPage from './pages/rate-sheets/customers'
import CustomerCarrierRS from './pages/rate-sheets/customers/carriers'
import CustomerAddOrEdit from './pages/rate-sheets/customers/newOrEdit/'
import RegionManager from './pages/region-manager'
import RegionManagerAddOrEdit from './pages/region-manager/newOrEdit'
import RolesEditor from './pages/roles-editor'
import RoleAddOrEdit from './pages/roles-editor/newOrEdit'
import ServicesEditor from './pages/services-editor'
import EditServices from './pages/services-editor/edit'
import NewServices from './pages/services-editor/new'
import TaskSchedulerAdmin from './pages/task-scheduler-admin'
import TaskEditAdmin from './pages/task-scheduler-admin/edit'
import TaskAddAdmin from './pages/task-scheduler-admin/new'
import TaskTabAdmin from './pages/task-scheduler-admin/task-tab'
import TaskStepTabAdmin from './pages/task-scheduler-admin/taskstep-tab'
import TaskSchedulerCustomer from './pages/task-scheduler-customer'
import TaskEditCustomer from './pages/task-scheduler-customer/edit'
import TaskAddCustomer from './pages/task-scheduler-customer/new'
import TaskTabCustomer from './pages/task-scheduler-customer/task-tab'

import 'handsontable/dist/handsontable.full.min.css'
import 'handsontable/dist/handsontable.full.min.css'

// ** Register modules for handsontable plugin
registerAllModules()

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings }) => {
            return (
              <ThemeComponent settings={settings}>
                <Router>
                  <AppRouteHome />
                  <AppRouterAuth />
                  <AppRouterOrder />
                  <AppRouterCarriers />
                  <AppRouterCustomer />
                  <AppRoutesReports />
                  <AppRouterClient />
                  <AppRouterSettings />
                  <AppOtherRoutes />
                  <AppRouterSettingsEz />
                </Router>

                <ReactHotToast>
                  <Toaster position={settings.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
                </ReactHotToast>
              </ThemeComponent>
            )
          }}
        </SettingsConsumer>
      </SettingsProvider>
    </QueryClientProvider>
  )
}

function AppRouteHome() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <UserLayout />
          </PrivateRoute>
        }
      >
        <Route path={'/dashboard'} element={<Home />} />
        <Route path={'/c-dashboard'} element={<HomeClient />} />
        <Route path={'/admin-dashboard'} element={<NewDashboard />} />
        <Route path={'/'} element={<Navigate to='/admin-dashboard' />} />
        <Route path={'/profile'} element={<Profile />} />
      </Route>
    </Routes>
  )
}

function AppRouterAuth() {
  return (
    <Routes>
      <Route path={'/login'} element={<Login />} />
      <Route path={'/forgot-password'} element={<ForgotPassword />} />
    </Routes>
  )
}

function AppRouterOrder() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <UserLayout />
          </PrivateRoute>
        }
      >
        <Route path={'/order-editor'} element={<OrderEditor />} />
        <Route path={'/order-editor/add'} element={<OrderEditorAddOrEdit />} />
        <Route path={'/order-editor/preview/'} element={<OrderPreview />} />
        <Route path={'/order-editor/edit'} element={<OrderEdit />} />
      </Route>
    </Routes>
  )
}

function AppRouterCarriers() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <UserLayout />
          </PrivateRoute>
        }
      >
        <Route path={'/carriers'} element={<CarriersPage />} />
        <Route path={'/carriers/base-rate/:courierId'} element={<CustomerCarrierRS typePage={'carrier'} />} />
        <Route path={'/carriers/add'} element={<CarrierAddOrEdit />} />
        <Route path={'/carriers/view'} element={<CarrierAddOrEdit />} />
        <Route path={'carriers/Regions'} element={<CarrierRegions />} />
        <Route path={'carriers/services-editor'} element={<ServicesEditor />} />
        <Route path={'carriers/services-editor/new'} element={<NewServices />} />
        <Route path={'carriers/services-editor/edit'} element={<EditServices />} />
        <Route path={'/carriers/invoice/:carrierId'} element={<CarrierInvoice />} />
      </Route>
    </Routes>
  )
}

function AppRouterCustomer() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <UserLayout />
          </PrivateRoute>
        }
      >
        <Route path={'/users-admin'} element={<CustomerAdminUsersList />} />
        <Route path={'/users-admin/new'} element={<CustomerAdminUserNewOrEdit />} />
        <Route path={'/users-admin/:id'} element={<CustomerAdminUserNewOrEdit />} />

        <Route path={'customer'} element={<ShopPage />} />
        <Route path={'customer/rate-sheets/:customerId'} element={<CustomerCarrierRS typePage={'shop'} />} />
        <Route path={'customer/add'} element={<CustomerAddOrEdit />} />
        <Route path={'customer/view'} element={<CustomerAddOrEdit />} />
        <Route path={'customer/products-editor/:customerId'} element={<ProductEditor />} />
        <Route path={'customer/batch'} element={<CustomerBatch />} />
        <Route path={'customer/invoice'} element={<ShopInvoice />} />
        <Route path={'customer/couriers'} element={<UnderConstruction titlePage='Couriers' />} />
        <Route path={'/labels'} element={<Labels />} />
        <Route path={'/labels/account/new'} element={<AddOrEditAccount />} />
        <Route path={'/labels/account/edit'} element={<AddOrEditAccount />} />
        <Route path={'/labels/account/services-associates'} element={<AccountServicesList />} />
        <Route path={'/shipp'} element={<Shipp />} />
        <Route path={'/client-labels/shipp/new'} element={<ShippForm />} />
      </Route>
    </Routes>
  )
}

function AppRouterClient() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <UserLayout />
          </PrivateRoute>
        }
      >
        <Route path={'/admin-user-client'} element={<ClientAdminUsersList />} />
        <Route path={'/admin-user-client/new'} element={<ClientAdminUserNewOrEdit />} />
        <Route path={'/admin-user-client/:id'} element={<ClientAdminUserNewOrEdit />} />
        <Route path={'/client-labels'} element={<Labels />} />
        <Route path={'/client-labels/account/new'} element={<AddOrEditAccount />} />
        <Route path={'/client-labels/account/edit'} element={<AddOrEditAccount />} />
        <Route path={'/client-labels/account/services-associates'} element={<AccountServicesList />} />
        <Route path='/client-shipp' element={<Shipp />} />
      </Route>
    </Routes>
  )
}

function AppRouterSettings() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <UserLayout />
          </PrivateRoute>
        }
      >
        <Route path={'/roles-editor'} element={<RolesEditor />} />
        <Route path={'/roles-editor/add'} element={<RoleAddOrEdit />} />
        <Route path={'/roles-editor/edit'} element={<RoleAddOrEdit />} />
        <Route path={'/roles-editor/view'} element={<RoleAddOrEdit />} />
        <Route path={'/managers-editor'} element={<UnderConstruction />} />
        <Route path={'/profiles-editor'} element={<ProfilesEditor />} />
        <Route path={'/profiles-editor/add'} element={<ProfileAddOrEdit />} />
        <Route path={'/profiles-editor/edit'} element={<ProfileAddOrEdit />} />
        <Route path={'/manage-object-security'} element={<ManageObjectEditor />} />
        <Route path={'admin/job'} element={<TaskSchedulerAdmin />} />
        <Route path={'admin/job/view'} element={<TaskTabAdmin />} />
        <Route path={'admin/job/view/step'} element={<TaskStepTabAdmin />} />
        <Route path={'admin/job/add'} element={<TaskAddAdmin />} />
        <Route path={'admin/job/edit'} element={<TaskEditAdmin />} />
        <Route path={'/task'} element={<TaskSchedulerCustomer />} />
        <Route path={'/task/view'} element={<TaskTabCustomer />} />
        <Route path={'/task/add'} element={<TaskAddCustomer />} />
        <Route path={'/task/edit'} element={<TaskEditCustomer />} />
        <Route path={'/support'} element={<UnderConstruction />} />
      </Route>
    </Routes>
  )
}
function AppRouterSettingsEz() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <UserLayout />
          </PrivateRoute>
        }
      >
        <Route path={'/admin-user'} element={<EzAdminUserList />} />
        <Route path={'/admin-user/new'} element={<EzAdminUserNewOrEdit />} />
        <Route path={'/admin-user/:id'} element={<EzAdminUserNewOrEdit />} />
        <Route path={'/admin-label-server'} element={<LabelsServer />} />
        <Route path={'/admin-label-server/account/new'} element={<AddOrEditAccount />} />
        <Route path={'/admin-label-server/account/edit'} element={<AddOrEditAccount />} />
        <Route path={'/admin-label-server/account/services-associates'} element={<AccountServicesList />} />
        <Route path={'/balance-account-details/:courierId'} element={<BalanceAccountDetails />} />
        <Route path={'/company-editor'} element={<CompaniesEditor />} />
        <Route path={'/company-editor/:id'} element={<CompaniesAddOrEdit />} />
        <Route path={'/company-editor/rate-sheets/:id'} element={<CustomerCarrierRS typePage='customer' />} />
        <Route path={'/shopIntegration'} element={<ShopIntegration />} />
        <Route path={'/events'} element={<EventPage />} />
      </Route>
    </Routes>
  )
}

function AppRoutesReports() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <UserLayout />
          </PrivateRoute>
        }
      >
        <Route path={'/reports/admin'} element={<UnderConstruction />} />
        <Route path={'reports/service/summary'} element={<ReportServiceSummary />} />
        <Route path={'reports/service/version/summary'} element={<ReportServiceSummary />} />
        <Route path={'/reports/accounting'} element={<UnderConstruction />} />
        <Route path={'/reports/executive'} element={<UnderConstruction />} />
        <Route path={'/reports/customer'} element={<UnderConstruction />} />
        <Route path={'/reports/others'} element={<UnderConstruction />} />
      </Route>
    </Routes>
  )
}

function AppOtherRoutes() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <UserLayout />
          </PrivateRoute>
        }
      >
        <Route path={'/rate-sheets'} element={<CustomerCarrierRS typePage={'carrier'} />} />
        <Route path={'/invoice-test'} element={<Invoice />} />
        <Route path={'/invoice'} element={<Invoice />} />
        <Route path={'/invoice/import'} element={<Import />} />
        <Route path={'/invoice/preview'} element={<InvoicePreview />} />
        <Route path={'/RegionManager'} element={<RegionManager />} />
        <Route path={'/RegionManager/Add'} element={<RegionManagerAddOrEdit />} />
        <Route path={'/RegionManager/Edit'} element={<RegionManagerAddOrEdit />} />
        <Route path={'/invoices'} element={<ShopInvoice />} />
        <Route path={'/orders'} element={<OrderEditorShop />} />
        <Route path={'/c-rate-sheets'} element={<ClientRateSheet />} />
        <Route path={'/reports'} element={<UnderConstruction />} />
        <Route path={'client/reports'} element={<UnderConstruction />} />
      </Route>
    </Routes>
  )
}

export default App

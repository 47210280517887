import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type State = {
  isEditMode: number
  measure: number | string
  measureName: string
  zoneDtos: ZoneDto[]
  weightDtos: WeightDto[]
  resetZonesAndWeigths: boolean
}

type ZoneDto = {
  id: number
  intServiceZoneId: number
  vchName: string | null
  vchShortName: string | null
  vchSourceId: string | null
  intCarrierId: number
  vchCarrierName: string | null
  intCountryId: number
  vchCountryName: string | null
  intServiceId: number
  vchServiceName: string | null
  intStatusId: number
  vchStatusName: string | null
  intCreatedUserId: number
  intUpdatedUserId: number
  dtmCreatedDate: string
  dtmUpdatedDate: string
  intRegionId: number | null
}

type WeightDto = {
  id: number
  intServiceWeigthId: number
  vchCodWeigth: string | null
  intCarrierId: number
  vchCarrierName: string | null
  intServiceId: number
  vchServiceName: string | null
  decWeigthMin: number
  decWeigthMax: number
  tinUnitMeasureId: number
  vchUnitMeasureName: string | null
  intStatusId: number
  vchStatusName: string | null
  intCreatedUserId: number
  intUpdatedUserId: number
  dtmCreatedDate: string | null
  dtmUpdatedDate: string | null
}

type Actions = {
  reset: () => void
  setisEditMode: (isEditMode: number) => void
  setMeasure: (measure: number | string) => void
  setMeasureName: (measureName: string) => void
  setZoneDtos: (zoneDtos: ZoneDto[]) => void
  setWeightDtos: (weightDtos: WeightDto[]) => void
  setResetZonesAndWeigths: (resetZonesAndWeigths: boolean) => void
}

const initialState: State = {
  isEditMode: 0,
  resetZonesAndWeigths: false,
  measureName: 'All',
  measure: '-1',
  zoneDtos: [],
  weightDtos: []
}

const ServicesStore = create<State & Actions>()(
  persist(
    (set) => ({
      ...initialState,
      setResetZonesAndWeigths: (resetZonesAndWeigths: boolean) => {
        set({ resetZonesAndWeigths })
      },
      setisEditMode: (isEditMode: number) => {
        set({ isEditMode })
      },
      setMeasure: (measure: number | string) => {
        set({ measure })
        // appStore.getState().filterWeightsByMeasureName(get().measureName)
        // appStore.setState({cellListShop: appStore.getState().cellListShop.filter(cell => cell.tinUnitMeasureId === 1)})
      },
      setMeasureName: (measureName: string) => {
        set({ measureName })
      },
      setZoneDtos: (zoneDtos: ZoneDto[]) => {
        set({ zoneDtos })
      },
      setWeightDtos: (weightDtos: WeightDto[]) => {
        set({ weightDtos })
      },
      reset: () => {
        set(initialState)
      }
    }),
    {
      name: 'Services-store'
    }
  )
)

if (process.env.NODE_ENV === 'development') mountStoreDevtool('Services-store', ServicesStore)

export default ServicesStore

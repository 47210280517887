// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { axiosInstance } from 'src/@http'
import { axiosInstanceShippForm } from 'src/@http'
import appStore from 'src/zustand/app'

// ** Axios Imports
// import axios from 'axios'

const initProfile = {
  _id: '',
  username: 'cspinella0',
  last_name: 'Spinella',
  first_name: 'Cosette',
  middle_name: 'Farlie',
  my_picture: '/images/avatars/user-lg.png',
  country: 'El Salvador',
  user_type: 'admin',
  last_login: new Date('2023-01-03T06:41:21Z'),
  preferred_languague: 'Español',
  email_address: 'cspinella0@google.cn',
  telephone: '654-521-2575',
  birthday: new Date('04/15/2023')
}

// const { setCustomerRateSheet } = appStore()

interface InitialState<T> {
  [index: string]: T
}

const initialState: InitialState<any> = {
  userName: '',
  userType: '',
  token: '',
  profile: initProfile,
  resToken: {}
}

export const appUserProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setToken(state, action) {
      window.localStorage.setItem('token', action.payload)
      state.token = action.payload
      state.resToken = action.payload
    },
    setTokenTest(state, action) {
      state.token = action.payload.access_token
    },
    setUserName(state, action) {
      state.userName = action.payload
    },
    setUserType(state, action) {
      state.userName = action.payload
    },
    setProfile(state, action) {
      state.profile = action.payload
      state.profile.birthday = new Date(action.payload.birthday)
    },
    logoutClearProfile(state: any) {
      /* Establece todos los atributos del usuario en su estado inicial */
      // setCustomerRateSheet([])
      for (const key in initialState) {
        state[key] = initialState[key]
      }
      appStore.getState().logout()
      window.localStorage.removeItem('token')
      delete axiosInstance.defaults.headers.common['Authorization']
      delete axiosInstanceShippForm.defaults.headers.common['Authorization']
    }
  }
})

export const { setUserName, setUserType, setProfile, setToken, setTokenTest, logoutClearProfile } =
  appUserProfileSlice.actions

export default appUserProfileSlice.reducer

import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import CustomChip from 'src/@core/components/mui/chip'
import PageHeader from 'src/@core/components/page-header'
import { deleteServices, getServices } from 'src/@http/services'
import { ServicesCarrierModel } from 'src/types/models/ServicesCarrierModel'
import appStore from 'src/zustand/app'

import { IconAction } from '../components/rateSheets/TableRateSheet'

const RowOptions = ({ row, state, setRows }: { row: any; state: any; setRows: any }) => {
  const { t } = useTranslation()
  const [isDialogOpen, setDialogOpen] = useState(false)
  const handleCloseDialog = () => {
    setDialogOpen(false)
  }
  const handleDelete = () => {
    setDialogOpen(true)
  }
  const handleConfirmDelete = async () => {
    try {
      await deleteServices(row.intServiceId)
      toast.success(t('Service deleted') + '! 👌', { duration: 4000 })
      setRows((prevRows: any[]) => prevRows.filter((prevRow: any) => prevRow.intServiceId !== row.intServiceId))
    } catch (err) {
      toast.error(err + ' 🙄', { duration: 4000 })
    }
    setDialogOpen(false)
  }
  const navigate = useNavigate()

  const handleEditService = async () => {
    navigate('edit', {
      state: {
        ...state,
        intServiceId: row.intServiceId,
        vchName: row.vchName,
        vchShortName: row.vchShortName,
        vchSourceId: row.vchSourceId,
        intServiceClasificationId: row.intServiceClasificationId,
        // packageType: row.packageType
      }
    })
  }

  return (
    <Stack display='flex' flexDirection='row' width='100%' justifyContent='center'>
      <IconAction title={t('EDIT')} icon='mdi:pencil' onClick={handleEditService} datacy='edit-service' />

      <IconAction title={t('DELETE')} icon='ic:outline-delete' onClick={handleDelete} datacy='delete-service' />
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogContent>
          <DialogContentText>{t('Are you sure you want to delete this service?')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='primary'>
            {t('Cancel')}
          </Button>
          <Button onClick={handleConfirmDelete} color='primary' data-cy='delete-service-btn-confirm'>
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

const ServicesEditor = () => {
  const { state } = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { serviceEditorCarrierId, paramsV2 } = appStore()
  const [rows, setRows] = useState<ServicesCarrierModel[]>([])
  const [isLoading, setLoading] = useState(false)
  const columns: GridColumns = [
    {
      flex: 1,
      field: 'intServiceId',
      headerName: 'Id',
      minWidth: 100,
      maxWidth: 150
    },
    {
      field: 'vchName',
      headerName: 'Name',
      flex: 1,
      minWidth: 250,
      maxWidth: 300
    },
    {
      field: 'vchShortName',
      headerName: 'Short Name',
      flex: 1,
      minWidth: 120
    },
    {
      field: 'vchSourceId',
      headerName: 'Source Id',
      flex: 1,
      minWidth: 175
    },
    {
      field: 'serviceClasificationName',
      headerName: 'Service Clasification',
      flex: 1,
      minWidth: 175
    },
    {
      field: 'vchStatusName',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: params => (
        <Stack width='100%' display='flex' flexDirection='row' justifyContent='center'>
          <CustomChip
            label={params.value}
            skin='light'
            color={params.row.vchStatusColor ? params.row.vchStatusColor : 'success'}
          />
        </Stack>
      )
    },
    {
      field: 'dtmCreatedDate',
      headerName: 'Created Date',
      flex: 1,
      minWidth: 120,
      renderCell: params => (
        <Typography variant='subtitle2'>{format(new Date(params.value), 'MM/dd/yy', { locale: enUS })}</Typography>
      )
    },
    {
      field: '',
      headerName: 'Action',
      headerAlign: 'center',
      flex: 1,
      minWidth: 250,
      maxWidth: 250,
      renderCell: params => <RowOptions row={params.row} state={state} setRows={setRows} />
    }
  ]
  const handleAddService = () => {
    navigate('new', { state: state })
  }
  useEffect(() => {
    setLoading(true)
    getServices(serviceEditorCarrierId || state.data.intCarrierId)
      .then(data => {
        setRows(data)
      })
      .catch(error => {
        toast.error(error.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [serviceEditorCarrierId, paramsV2])

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <PageHeader
          title={
            <Typography variant='h5' mb={5}>
              {t('SERVICES_EDITOR')}
            </Typography>
          }
        />
        <Stack flexDirection='row' justifyContent='space-between' alignItems='center' mb={3}>
          <Typography variant='body2'>
            <b>Courier</b>: {state ? state.data.vchName : '-'}
          </Typography>
          <Button
            variant='contained'
            style={{ color: 'white' }}
            onClick={handleAddService}>
            {t('ADD_SERVICE')}
          </Button>
        </Stack>
        <Card>
          <DataGrid
            checkboxSelection
            disableSelectionOnClick
            autoHeight
            loading={isLoading}
            columns={columns}
            rows={rows}
            getRowId={row => row.intServiceId}
          />
        </Card>
      </Grid>
    </Grid>
  )
}

export default ServicesEditor

import { useEffect, useState } from 'react'
import React from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme
} from '@mui/material'
import { desiredRoutes } from 'src/types/enums/PagesByLevel'
import customerStore from 'src/zustand/customer'

interface AutocompleteUsersProps {
  onSelectClient: (user: any) => void
  label: string
  disabled?: boolean
  shopSelected?: any
  size?: 'small' | 'medium'
  width?: number | string
  addViewMobile?: boolean
}

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  }
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 160
    }
  }
}

const AutocompleteClientMulti = ({...props}: AutocompleteUsersProps) => {
  const [viewMobile, setViewModal] = useState<boolean | undefined>(props.addViewMobile)
  // const [value, setValue] = useState<any | null>(null)
  /* const updateShopInfo = (shop: ShopModel | null) => {
    onSelectClient(shop)
    setShopGlobal(shop)
    setValue(shop || null)
  } */
  const { setShops, shops } = customerStore()
  const theme = useTheme()
  const [personName, setPersonName] = React.useState<string[]>(['Sun Warrior'])

  useEffect(() => {
    if (shops.length === 0) setShops()
  }, [])

  useEffect(() => {
    if (props.addViewMobile !== undefined) {
      setViewModal(props.addViewMobile)
    }
  }, [props.addViewMobile])

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value }
    } = event
    setPersonName(typeof value === 'string' ? value.split(',') : value)
  }

  const isVisible = desiredRoutes.CLIENTMULTI.includes(location.pathname);

  if (!isVisible) {
    return null;
  }

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 350 }}>
        <InputLabel>Client</InputLabel>
        <Select
          multiple
          size='small'
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label='Client' />}
          sx={{ width: '350px' }}
          MenuProps={MenuProps}
        >
          {shops.map(shop => (
            <MenuItem key={shop.id} value={shop.id} style={getStyles(shop.id.toString(), personName, theme)}>
              {shop.vchName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default AutocompleteClientMulti

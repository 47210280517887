import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Card, CardContent, CardHeader, Typography } from "@mui/material"
import { DataGrid, GridColumns } from "@mui/x-data-grid"
import { getCarrierAccountById } from "src/@http/carrier-account"
import { ServicesGetModel } from "src/types/models/CarrierModel"
import { logger } from "src/utils/Logger"

const getTitle = (accountNumber: string, nameCourier: string) => {
  return (
    <Typography variant="h6">
      Services associated with the account <strong>{accountNumber}</strong> (Courier: {nameCourier})
    </Typography>
  )
}

const columns: GridColumns = [
  { field: 'intServiceId', headerName: 'Service ID', flex: 1 },
  { field: 'vchServiceName', headerName: 'Service', flex: 2 },
  { field: 'vchStatusName', headerName: 'Status', flex: 1 }
]

interface StateType {
  carrierName: string;
  accountNumber: string;
  courierId: number;
  id: number;
}

const AccountServicesList = () => {
  const location = useLocation()
  const state = location.state as { data: StateType }
  const [list, setList] = useState<ServicesGetModel[]>([])
  const { carrierName, accountNumber, courierId, id } = state.data

  logger.log('state data: ', state.data)

  const getServices = async () => {
    const resp = await getCarrierAccountById(id)
    logger.log('Services: ', resp.services)
    setList(resp.services)
  }

  useEffect(() => {
    getServices()
    logger.log('AccountServicesList: ', list)
  }, [courierId])

  return (
    <Card>
      <CardHeader title={getTitle(accountNumber, carrierName)} />
      <CardContent>
        <DataGrid autoHeight columns={columns} rows={list} getRowId={(row) => row.intServiceId} />
      </CardContent>
    </Card>
  )
}

export default AccountServicesList

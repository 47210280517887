import { Icon } from '@iconify/react'
import { Grid, IconButton, LinearProgress, Stack, Typography } from '@mui/material'
import { DataGrid, GridColumns, GridRowsProp } from '@mui/x-data-grid'
import CustomAvatar from 'src/@core/components/mui/avatar'

interface Row {
  id: number
  carriers: {
    logo: string
    title: string
    subtitle: string
  }
  fileName: string
  description: string
  progress: number
}

const row: GridRowsProp = [
  {
    id: 1,
    carriers: {
      logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/United_Parcel_Service_logo_2014.svg/320px-United_Parcel_Service_logo_2014.svg.png',
      title: 'BGC eCommerce App',
      subtitle: 'React Project'
    },
    fileName: 'Eileen',
    description: 'Eileen',
    uploadTime: 'Eileen',
    progress: 78
  },
  {
    id: 2,
    carriers: {
      logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/United_Parcel_Service_logo_2014.svg/320px-United_Parcel_Service_logo_2014.svg.png',
      title: 'Falcon Logo Design',
      subtitle: 'Figma Project'
    },
    fileName: 'Owen',
    description: 'Owen',
    uploadTime: 'Owen',
    progress: 18
  },
  {
    id: 3,
    carriers: {
      logo: 'https://ps.w.org/dhl-for-woocommerce/assets/icon.svg?rev=1777040',
      title: 'BGC eCommerce App',
      subtitle: 'React Project'
    },
    fileName: 'Eileen',
    description: 'Eileen',
    uploadTime: 'Eileen',
    progress: 72
  }
]

const columns: GridColumns = [
  {
    field: 'carriers',
    headerName: 'Carriers',
    flex: 1,
    minWidth: 350,
    renderCell: (params: any) => (
      <Grid container columnGap={3}>
        <Grid
          sx={{ display: { xs: 'none', md: 'flex', lg: 'flex' } }}
          item
          xs={6}
          lg={2}
          justifyContent='center'
          alignItems='center'
        >
          <CustomAvatar src={params.value.logo} alt='logo' sx={{ width: 30, height: 30 }} />
        </Grid>
        <Grid item xs={6} lg={8} display='flex' flexDirection='column' justifyContent='flex-start'>
          <Typography variant='subtitle2' fontWeight={600}>
            {params.value.title}
          </Typography>
          <Typography variant='subtitle2' fontWeight={300}>
            {params.value.subtitle}
          </Typography>
        </Grid>
      </Grid>
    )
  },
  {
    field: 'fileName',
    headerName: 'File Name',
    minWidth: 200
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 200
  },
  {
    field: 'uploadTime',
    headerName: 'Upload Time',
    minWidth: 200
  },
  {
    field: 'progress',
    headerName: 'Progress',
    minWidth: 200,
    renderCell: (params: any) => (
      <Stack display='flex' flexDirection='column' sx={{ width: '100%' }}>
        <Typography variant='body1'>{params.value}%</Typography>
        <LinearProgress
          color='success'
          variant='determinate'
          value={params.value}
          sx={{ height: 5, borderRadius: 3 }}
        />
      </Stack>
    )
  },
  {
    field: '',
    headerName: 'Action',
    minWidth: 200,
    renderCell: ({ row }: CellType) => <RowOptions id={row.id} />
  }
]

interface CellType {
  row: Row
}
const RowOptions = ({ id }: { id: number }) => {
  return (
    <Stack display='flex' flexDirection='row' width='100%' justifyContent='space-between'>
      <IconButton size='small'>
        <Icon icon='mdi:dots-vertical' />
      </IconButton>
    </Stack>
  )
}

const TableImportInvoice = () => {
  return (
    <>
      <DataGrid
        autoHeight
        rows={row}
        columns={columns}
        getRowHeight={() => 61}
        pageSize={100}
        disableSelectionOnClick
        rowsPerPageOptions={[10, 25, 50]}
      />
    </>
  )
}

export default TableImportInvoice

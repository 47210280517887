export const Status = {
  ACTIVE: 1,
  INACTIVE: 2,
  DELETED: 3,
  ALL: -1
}

export const colorAccordingStatus = (status: string) => {
  if (status.toUpperCase() === Object.keys(Status)[0]) {
    return 'success'
  } else if (status.toUpperCase() === Object.keys(Status)[1]) {
    return 'secondary'
  } else if (status.toUpperCase() === Object.keys(Status)[2]) {
    return 'default'
  }
}

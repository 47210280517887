import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { createObjectSecurity, updateObjectSecurity } from 'src/@http/profiles';
import { SecurityObjectPost } from 'src/types/models/ProfileModel';
import { logger } from 'src/utils/Logger';
import profileStore from 'src/zustand/profile';

type InitialForm = {
  vchName: string;
  vchObjectDescription: string;
  tinSecurityObjecType: number | string;
  vchObjectCod: string;
}

let initialForm: InitialForm = {
  vchName: '',
  vchObjectDescription: '',
  tinSecurityObjecType: '',
  vchObjectCod: ''
}

interface AddEditObjSecurityProps {
  objectSecurity?: any, 
  handleSuccessAction: (success: boolean) => void
  extraData?: any
}

const AddEditObjSecurity = ({ objectSecurity, handleSuccessAction, extraData }: AddEditObjSecurityProps) => {
  const { securityObjectsTypes } = profileStore()
  const { t } = useTranslation()
  
  if (objectSecurity) {
    initialForm = { ...objectSecurity }
  } else {
    initialForm.tinSecurityObjecType = securityObjectsTypes.length > 0 ? securityObjectsTypes[0].id : ''
  }
  
  const { register, handleSubmit } = useForm({
    defaultValues: initialForm
  });

  const onSubmit = async (data: any) => {
    const payload: SecurityObjectPost = {
      vchName: data.vchName,
      intStatusId: 1,
      tinSecurityObjecType: data.tinSecurityObjecType,
      tinModuleId: objectSecurity ? data.tinModuleId : extraData.tinModuleId,
      vchObjectCod: data.vchObjectCod,
      vchObjectDescription: data.vchObjectDescription,
      intSecurityObjectFatherId: objectSecurity ? data.intSecurityObjectFatherId : extraData.intSecurityObjectFatherId
    }
    logger.log('payload', payload)
    // MODE EDIT
    if (objectSecurity) {
      try {
        payload.id = data.id
        await updateObjectSecurity(payload)
        handleSuccessAction(true)
      } catch (e) {
        logger.log(e)
      }
    } else {
      try {
        await createObjectSecurity(payload)
        handleSuccessAction(true)
      } catch (e: any) {
        logger.log(e)
        toast.error(e.DescriptionError)
      }
    }
  };

  return (
    <form style={{ width: objectSecurity ? '80%' : '89%', maxWidth: '80%'}} onSubmit={handleSubmit(onSubmit)}>
      <Grid container flexDirection='column' spacing={2} alignItems='center' width='100%'>
        <Grid item xs={12} md={12} sx={{ width: '100%' }}>
          <TextField
            size='small'
            {...register('vchName')}
            label={t('NAME')}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} md={12} sx={{ width: '100%' }}>
          <TextField
            size='small'
            {...register('vchObjectDescription')}
            label={t('DESCRIPTION')}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} md={12} sx={{ width: '100%' }}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="select-label">{t('TYPES_OF_SECURITY_OBJECTS')}</InputLabel>
            <Select
              size='small'
              {...register('tinSecurityObjecType')}
              labelId="select-label"
              label={t('TYPES_OF_SECURITY_OBJECTS')}
              defaultValue={initialForm.tinSecurityObjecType}
            >
              {securityObjectsTypes ? securityObjectsTypes.map((option, index) => (
                <MenuItem key={index} value={option.id}>{option.description}</MenuItem>
              )) : null}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} sx={{ width: '100%' }}>
          <TextField
            size='small'
            {...register('vchObjectCod')}
            label={t('OBJECT_CODE')}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ display: 'flex', columnGap: '10px' }}>
          <Button type="submit" variant="outlined" color="primary">
            {t('SAVE')}
          </Button>
          <Button variant="text" color="primary" onClick={() => {
            handleSuccessAction(false)
          }}>
            {t('CANCEL')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddEditObjSecurity;

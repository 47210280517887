import { useTranslation } from 'react-i18next'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import PageHeader from 'src/@core/components/page-header'

import TableSchedulerCustomer from './tablescheduler'

const TaskSchedulerCustomer = () => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <PageHeader
          title={<Typography variant='h5'>{t('TASK_SCHEDULER')}</Typography>}
          subtitle={<Typography variant='body2'>Job Scheduler list</Typography>}
        />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <TableSchedulerCustomer />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default TaskSchedulerCustomer

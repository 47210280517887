import { createSlice } from '@reduxjs/toolkit'
import { LstCarrierRateDetail } from 'src/types/models/CarrierModel'

export interface WeigthModel {
  id?: number
  intServiceWeightId: number
  intCarrierId: number
  intServiceId: number
  vchCodWeight: string
  decWeigthMin: string | number
  decWeigthMax: string | number
  description: string
  disabled?: boolean
}

interface InitialState {
  weightsRateSheets: WeigthModel[]
  weightsBaseSheets: WeigthModel[]
  baseRateDetails: LstCarrierRateDetail[]
  courierSelected: any
}

const initial: InitialState = {
  weightsRateSheets: [],
  weightsBaseSheets: [],
  baseRateDetails: [],
  courierSelected: null
}

export const RatesSlice = createSlice({
  name: 'rates',
  initialState: initial,
  reducers: {
    addWeightsCustomer: (state, action) => {
      state.weightsRateSheets = action.payload
    },
    addWeightsCarriers: (state, action) => {
      state.weightsBaseSheets = action.payload
    },
    addBaseRateDetails: (state, action) => {
      state.baseRateDetails = action.payload
    },
    setCourierSelected: (state, action) => {
      state.courierSelected = action.payload
    }
  }
})

export default RatesSlice.reducer
export const { addWeightsCustomer, addWeightsCarriers, addBaseRateDetails, setCourierSelected } = RatesSlice.actions

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import FilterCarrierRateSheet from 'src/pages/components/rateSheets/FilterCarrierRateSheet'
import TableRateSheetCarriers from 'src/pages/components/rateSheets/TableRateSheetCarriers'
import TableRateSheetCustomer from 'src/pages/components/rateSheets/TableRateSheetCustomer'
import { setCourierSelected } from 'src/store/apps/rates'
import appStore from 'src/zustand/app'

export const initialConfigBtnSave = {
  showBtn: false,
  isSaveAs: false,
  loadingBtn: false,
  textSave: 'CHANGES_UNSAVED',
  disableBtn: false,
  refresh: 0
}

const CustomerCarrierRS = (props: { typePage: 'shop' | 'customer' | 'carrier' }) => {
  const { state } = useLocation()
  const userData = {
    user: state?.data || null,
    type: state?.type || props.typePage
  }

  console.log({ userData })

  const dispatch = useDispatch()
  const [configBtnSave, setConfigBtnSave] = useState(initialConfigBtnSave)
  const { setHeaderBaseRateDetails } = appStore(state => ({
    setHeaderBaseRateDetails: state.setHeaderBaseRateDetails
  }))
  const [headerRateDetailsExecuted, setHeaderRateDetailsExecuted] = useState(false)

  useEffect(() => {
    if (!headerRateDetailsExecuted) {
      setHeaderBaseRateDetails({})
      setHeaderRateDetailsExecuted(true)
    }
    dispatch(setCourierSelected(userData.user))
  }, [userData.user])

  return (
    <Grid container>
      <Grid item xs={12}>
        <FilterCarrierRateSheet
          dataUser={{
            data: userData.user,
            type: userData.type
          }}
          configBtnSave={configBtnSave}
          setConfigBtnSave={setConfigBtnSave}
        >
          <>
            {userData.type === 'shop' || userData.type === 'customer' ? (
              <TableRateSheetCustomer
                setConfigBtnSave={setConfigBtnSave}
                configBtnSave={configBtnSave}
                dataUser={userData.type}
              />
            ) : (
              <TableRateSheetCarriers
                setConfigBtnSave={setConfigBtnSave}
                configBtnSave={configBtnSave}
                dataUser={userData.type}
              />
            )}
          </>
        </FilterCarrierRateSheet>
      </Grid>
    </Grid>
  )
}

export default CustomerCarrierRS

import { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { DataGrid } from '@mui/x-data-grid'
import { format } from 'date-fns'
// ** Icon Imports
import Icon from 'src/@core/components/icon'
import { RateSheetVersionsGetModel } from 'src/types/models/CarrierModel'
import { logger } from 'src/utils/Logger'

import { DialogGeneric } from '../../generic/DialogGeneric'

interface RateSheetVersionsModalProp {
  handleVersionSelected: (version: RateSheetVersionsGetModel) => void
  typeUser?: string
  list?: any[]
  newSheet: boolean
}

const RateSheetVersionsModal = forwardRef((props: RateSheetVersionsModalProp, ref) => {
  const { handleVersionSelected, list, typeUser, newSheet } = props
  const { t } = useTranslation()
  const [selectVersionRateSheet, setSelectVersionRateSheet] = useState<number | null>(null)

  useEffect(() => {
    if (typeUser === 'shop') {
      setSelectVersionRateSheet(list && list.length !== 0 ? list[0].bigShopRateId : null)
    } else if (typeUser === 'customer') {
      setSelectVersionRateSheet(list && list.length !== 0 ? list[0].bigCustomerRateId : null)
    } else {
      setSelectVersionRateSheet(list && list.length !== 0 ? list[0].bigCarrierRateId : null)
    }
  }, [list])

  const cols = [
    {
      field: 'id',
      headerName: 'Select',
      flex: 0.5,
      renderCell: (params: any) => {
        const isSelected = selectVersionRateSheet && selectVersionRateSheet === params.row.id

        return (
          <IconButton
            onClick={() => handleRowSelection(params.row.id)}
            aria-label='capture screenshot'
            color={isSelected ? 'success' : 'secondary'}
          >
            <Icon icon={isSelected ? 'ep:select' : 'material-symbols-light:select'} fontSize={20} />
          </IconButton>
        )
      }
    },
    {
      field: 'vchNombreHoja',
      headerName: 'Sheet Code',
      flex: 2,
      renderCell: (params: any) => {
        const isSelected = selectVersionRateSheet && selectVersionRateSheet === params.row.id
        const cellContent = params.value?.replace(/"/g, '')

        return (
          <Typography
            color={isSelected ? 'green' : 'secondary'}
            title={cellContent}
            style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
          >
            {cellContent}
          </Typography>
        )
      }
    },
    {
      field: 'vchName',
      headerName: 'Sheet Name',
      flex: 1,
      renderCell: (params: any) => {
        const isSelected = selectVersionRateSheet && selectVersionRateSheet === params.row.id

        return <Typography color={isSelected ? 'green' : 'secondary'}>{params.value?.replace(/"/g, '')}</Typography>
      }
    },
    {
      field: 'dtmStartDate',
      headerName: 'Start Date',
      flex: 1,
      type: 'date',
      editable: true,
      valueFormatter: (params: any) => {
        return params.value ? format(new Date(params.value), 'MM/dd/yyyy') : '-'
      }
    },
    {
      field: 'dtmEndDate',
      headerName: 'End Date',
      flex: 1,
      type: 'date',
      editable: true,
      valueFormatter: (params: any) => {
        return params.value ? format(new Date(params.value), 'MM/dd/yyyy') : '-'
      }
    }
  ]

  const getVersionAccordingTypeUser = (prop: string) => {
    if (list) {
      const selected = list.filter(rs => rs[prop] === selectVersionRateSheet)[0]
      if (selected) handleVersionSelected(selected)
    }
  }

  const handleConfirm = () => {
    if (typeUser === 'shop') {
      getVersionAccordingTypeUser('bigShopRateId')
    } else if (typeUser === 'customer') {
      getVersionAccordingTypeUser('bigCustomerRateId')
    } else {
      getVersionAccordingTypeUser('bigCarrierRateId')
    }
  }

  const handleRowSelection = (row: any) => {
    logger.log('Params handle row', row)
    setSelectVersionRateSheet(row)
  }

  return (
    <DialogGeneric
      maxWidth={1000}
      title={newSheet ? t('NEW_RATE_SHEET_FROM_ANOTHER_VERSION') : t('RATE_SHEET_VERSIONS')}
      ref={ref}
      confirmText='Confirm'
      confirmFunc={handleConfirm}
      zIndex={1500}
    >
      <DataGrid
        autoHeight
        columns={cols}
        rows={list || []}
        selectionModel={selectVersionRateSheet ? [selectVersionRateSheet] : []}
        pageSize={4}
        disableSelectionOnClick
      />
    </DialogGeneric>
  )
})

export default RateSheetVersionsModal

import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { getStateCache, State } from 'src/@http/address'
// import { logger } from 'src/utils/Logger'

interface AutocompleteStatesProps {
  onSelectState: (state: any) => void
  countryId: number
  disabled: boolean
  defaultValue: number
}

const valueDefault: State = {
  intCountryId: 0,
  description: '',
  id: 0,
  isActive: false,
  lastModifiedDate: null
}

const AutocompleteProps = ({ onSelectState, countryId, defaultValue, disabled }: AutocompleteStatesProps) => {
  // const [customerList, setCustomerList] = useState<CustomerModel[]>([])
  const [value, setValue] = useState<State>(valueDefault)
  const { t } = useTranslation()
  const [states, setStates] = useState<State[]>([])

  const handleChange = (event: any, value: any | null) => {
    onSelectState(value || '')
    setValue(value || '')
  }

  useEffect(() => {
    getStateCache(countryId.toString())
      .then(res => setStates(res))
      .catch(err => {
        toast.error('ERROR_STATES_GET')
        setStates([])
      })
  }, [])

  useEffect(() => {
    if (defaultValue !== 0 && states.length !== 0) {
      const defaultState = states.find(item => item.id === defaultValue)
      if (defaultState) {
        setValue(defaultState)
      }
    }
  }, [defaultValue, states])

  return (
    <Autocomplete
      fullWidth
      value={value}
      disabled={disabled}
      onChange={handleChange}
      options={states}
      getOptionLabel={option => option.description}
      id='autocomplete-disableCloseOnSelect'
      renderInput={params => <TextField {...params} label={t('STATE')} />}
    />
  )
}

export default AutocompleteProps

import JSEncrypt from 'jsencrypt'

/**
 * Encripta una contraseña utilizando RSA.
 * @param {string} password - La contraseña a encriptar.
 * @param {string} secret - La clave secreta para encriptar.
 * @returns {string} La contraseña encriptada o cadena vacia si la clave no se especifica.
 */
const jsencryptPasswordRSA = (password: string, secret?: string): string => {
  if (secret === undefined) {
    return ''
  }

  const crypt = new JSEncrypt()
  crypt.setKey(secret)

  return crypt.encrypt(password) || ''
}

export { jsencryptPasswordRSA }

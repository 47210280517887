import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { createPermissionsToObjectSecurity,SchemaBodyPermissionsObject } from "src/@http/profiles";
import { ObjectSecurityByModule, ProfilePermission } from "src/types/models/ProfileModel";
import { ProfileModel } from "src/types/models/ProfileModel";
import { logger } from "src/utils/Logger";
import profileStore from "src/zustand/profile";

import GenericButton from "../generic/GenericButton";

const ListingProfiles = ({ objectSecurity, permissionsObject }: { objectSecurity: ObjectSecurityByModule, permissionsObject: ProfilePermission[] }) => {
  const [permissionsSelected, setPermissionsSelected] = useState<SchemaBodyPermissionsObject[]>([])
  const [configuredPermList, setConfiguredPermList] = useState<ProfilePermission[]>([])
  const [isModified, setIsModified] = useState(false)
  const { t } = useTranslation()
  const { profilesByModule } = profileStore()

  const generateSchemaPermissions = () => {
    const permissions: SchemaBodyPermissionsObject[] = []
    profilesByModule.map(({ intProfileId }: ProfileModel) => {
      const permConfigured = permissionsObject?.find(p => p.intProfileId === intProfileId) || null
      // console.log('Permissions already configured', permConfigured)

      permissions.push({ 
        intProfileId, 
        intSecurityObjectId: objectSecurity.id, 
        tinPermissionType: !permConfigured ? 0 : permConfigured?.tinPermissionType, // Defaults 0 if the object don't have permission already configured.
        intStatusId: 1
      })
    })
    setPermissionsSelected(permissions)
  }

  useEffect(() => {
    setConfiguredPermList(permissionsObject)
    generateSchemaPermissions()
  }, [permissionsObject])
  

  if (profilesByModule.length === 0) return null

  const CellsProfile = () => {
    return (
      <>
        {
          profilesByModule.map((col: ProfileModel, i: number) => (
            <TableCell key={i} align="center">{col.vchName}</TableCell>
          ))
        }
      </>
    )
  }

  const handleChecked = ({target}: any, { intProfileId }: ProfileModel) => {
    logger.log('Check', target.checked)
    setIsModified(true)
    const permissionsModified: SchemaBodyPermissionsObject[] = permissionsSelected.map(permission => {
      // If the permission is the one clicked, it sets the state opposite to what it currently has.
      return intProfileId === permission.intProfileId 
        ? { ...permission, tinPermissionType: permission.tinPermissionType === 0 ? 1 : 0 } 
        : permission
    })
    setPermissionsSelected(permissionsModified)
  }

  const handleSaveOrEditPermissions = async () => {
    logger.log('Payload', permissionsSelected)
    try {
      await createPermissionsToObjectSecurity(permissionsSelected)
      toast.success(t('SAVED_SUCCESSFULLY'))
      setIsModified(false)
    } catch (error: any) {
      logger.log(error)
      toast.error(error.message)
      setIsModified(false)
    }
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <CellsProfile />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {profilesByModule.map((profile: ProfileModel, colIndex: number) => (
                <TableCell align="center" key={colIndex}>
                  <Checkbox 
                    // Look for the permission that matches the column, and then check if tinPermissionType = 1, and the opposite if 0. 
                    checked={permissionsSelected.find(per => per.intProfileId === profile.intProfileId)?.tinPermissionType === 0 ? false : true || false}
                    onChange={(e) => handleChecked(e, profile)} 
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      { isModified && 
        <GenericButton
          sx={{ '&.MuiButton-contained': { backgroundColor: 'secondary.main' }, m: 3, width: '98%', maxWidth: '98%' }}
          onClick={handleSaveOrEditPermissions}
        >
          {t('SAVE_OBJECT_PERMISSIONS')}
        </GenericButton>
      }
    </>
  );
}

export default ListingProfiles;
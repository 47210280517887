import { getAsync, postAsync, putAsync } from 'src/@http'
import { JobModel } from 'src/types/models/JobModel'

/* Endpoints definition */
const vApi = '/v1.0/'
const EndpointJob = `${vApi}ControllerJob/`
const GetJobs = `${EndpointJob}?Status=Enabled`

const GetJobById = (jobId: number) => `${EndpointJob}${jobId}`

export const getJobById = (jobId: number): Promise<JobModel> => {
  return getAsync(GetJobById(jobId))
}

export const getJobs = (): Promise<JobModel[]> => {
  return getAsync(GetJobs)
}

export const postJob = (body: JobModel): Promise<boolean> => {
  return postAsync(EndpointJob, body)
}

export const putJob = (body: JobModel): Promise<boolean> => {
  return putAsync(EndpointJob, body)
}

export const deleteJob = (body: JobModel): Promise<boolean> => {
  return putAsync(EndpointJob, body)
}

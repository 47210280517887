// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'
import { SecurityFormatted } from 'src/types/models/ProfileModel'
import { logger } from 'src/utils/Logger'
import { getCurrentRoute } from 'src/utils/misc'
import appStore from 'src/zustand/app'
import companiesStore from 'src/zustand/companies'
import customerStore from 'src/zustand/customer'
import profileStore from 'src/zustand/profile'

const getUserType = (type: number) => {
  if (type === 1) return 'ship Ez'
  if (type === 2) return 'customersMenu'
  if (type === 3) return 'clientMenu'
}

/**
 * Looks for the keys that are equal to some security object. If that object is active (or does not find a comparison) it shows the navitem, and if not it does not show it
 *
 * @param {any[]} data
 * @param {SecurityFormatted[]} securityFormatted
 * @return {*}
 */
const filterByKey = (data: any[], securityFormatted: SecurityFormatted[]) => {
  const filteredData = []

  for (const item of data) {
    if (!item.hasOwnProperty("key")) {
      filteredData.push(item);
    } else {
      const relatedSecurity = securityFormatted.find((sec: SecurityFormatted) => sec.name === item.key);
      
      if (!relatedSecurity || (relatedSecurity && relatedSecurity.statusActive)) {
        const newItem = { ...item };

        // Recursively filter children if they exist
        if (item.children) {
          newItem.children = filterByKey(item.children, securityFormatted);
        }

        filteredData.push(newItem);
      }
    }
  }

  return filteredData
}

const filterByUserType = (data: any[], userType: number | undefined) => {
  let items
  if (userType === undefined) return data
  else {
    if (userType === 2) items = data.filter(obj => obj.key === getUserType(2) || obj.key === getUserType(3))
    else if (userType === 3) items = data.filter(obj => obj.key === getUserType(3))
    else if (userType === 1) items = data

    return items
  }
}

const navigation = (): VerticalNavItemsType => {
  logger.log('Security List Formatted: ', profileStore.getState().securityFormatted)
  logger.log('Tipo de usuario: ', profileStore.getState().profile?.tinUserType)

  const { securityFormatted } = profileStore.getState()

  profileStore.getState().securityFormatted

  const allNavItems: VerticalNavItemsType = [
    {
      title: 'Ship EZ',
      path: '',
      icon: '',
      key: 'ship Ez',
      children: [
        {
          title: 'Dashboard',
          path: '/admin-dashboard',
          icon: 'ic:outline-dashboard',
          key: 'dashboard admin z'
        },
        {
          title: 'Settings',
          path: '/setting',
          icon: 'tabler:settings-star',
          key: 'settings admin ez',
          children: [
            {
              title: 'Admin User',
              path: '/admin-user',
              key: 'admin user'
            },
            {
              title: 'Event',
              path: '/events',
              key: 'events page'
            },
            {
              title: 'Shop Integration',
              path: '/shopIntegration',
              key: 'shop integration page'
            },
            {
              title: 'Roles Editor',
              path: '/roles-editor',
              key: 'roles editor'
            },
            {
              title: 'Profiles Editor',
              path: '/profiles-editor',
              key: 'profile editor'
            },
            {
              title: 'Manage Security Editor',
              path: '/manage-object-security',
              key: 'object security editor'
            },
            {
              title: 'Job Scheduler',
              path: '/admin/job',
              key: 'Job Scheduler'
            },
            {
              title: 'Region Manager',
              path: '/RegionManager',
              key: 'region manager page'
            }
          ]
        },
        {
          title: 'Couriers',
          icon: 'mdi:courier',
          path: '/carriers',
          key: 'couriers page'
        },
        {
          title: 'Customers',
          icon: 'mdi:user-tie',
          path: '/company-editor',
          key: 'customer page'
        },
        {
          title: 'Label Server',
          path: '/admin-label-server',
          icon: 'bi:qr-code',
          key: 'label_server page'
        },
        {
          title: 'Reports',
          path: '/setting',
          icon: 'mdi:printer-outline',
          key: 'reportsEz',
          children: [
            {
              title: 'Customer services',
              path: 'reports/service/summary',
              key: 'serviceSummary'
            }
          ]
        }
      ]
    },
    {
      title: 'Customer Menu',
      path: '',
      icon: '',
      key: 'customersMenu',
      children: [
        // It is on hard in the Vertical Nav component setting title matchea against the name SelectorCompanies if it changes it will not be seen as such.
        /* { sectionTitle: 'SelectorCompanies', title: 'SelectorCompanies', key:'companies selector' }, */
        {
          title: 'Dashboard',
          path: '/dashboard',
          icon: 'ic:outline-dashboard',
          key: 'home',
        },
        {
          title: 'Clients',
          icon: 'ph:user',
          path: '/customer',
          key: 'client page',
        },
        {
          title: 'Rate Sheet',
          icon: 'lucide:sheet',
          path: '/rate-sheets',
          key: 'rate sheets customer',
        },
        {
          title: 'Order Editor',
          path: '/order-editor',
          icon: 'iconamoon:box-light',
          key: 'order editor page',
        },
        {
          title: 'Invoices',
          path: '/customer/batch',
          icon: 'mdi:receipt-text',
          key: 'invoices page',
        },
        {
          title: 'Label Server',
          icon: 'bi:qr-code',
          key: 'customer labels',
          children: [
            {
              title: 'Management',
              path: '/labels',
              key: 'ls management customer page'
            },
            {
              title: 'Label',
              path: '/shipp',
              key: 'customer packager'
            }
          ]
        },
        {
          title: 'Reports',
          icon: 'mdi:printer-outline',
          key: 'reports customers',
          children: [
            {
              title: 'Admin',
              path: 'reports/admin'
            },
            {
              title: 'Accounting',
              path: 'reports/accounting'
            },
            {
              title: 'Executive',
              path: 'reports/executive'
            },
            {
              title: 'Customer',
              path: 'reports/customer'
            },
            {
              title: 'Others',
              path: 'reports/others'
            },
            {
              title: 'Services version report',
              path: 'reports/service/version/summary'
            }
          ]
        },
        {
          title: 'Settings',
          icon: 'uil:setting',
          key: 'settings customers',
          children: [
            {
              title: 'Admin Users',
              path: '/users-admin',
              key: 'admin-user customer page'
            },
            {
              title: 'Managers Editor',
              path: '/managers-editor'
            },
            {
              title: 'Job Scheduler',
              path: '/task',
              key: 'job scheduler customer'
            },
            {
              title: 'Support',
              path: '/support'
            }
          ]
        }
      ]
    },
    {
      title: 'Client Menu',
      path: '',
      icon: '',
      key: 'clientMenu',
      children: [
        /* { sectionTitle: 'SelectorClient', title: 'SelectorClient', key:'client selector' }, */
        {
          title: 'Dashboard',
          path: '/c-dashboard',
          icon: 'ic:outline-dashboard',
          key: 'dashboard client page',
        },
        {
          title: 'Invoices',
          icon: 'mdi:receipt-text',
          path: '/invoices',
          key: 'client invoices',
        },
        {
          title: 'Label Server',
          icon: 'bi:qr-code',
          key: 'client labels',
          children: [
            {
              title: 'Management',
              path: '/client-labels',
              key: 'ls management page'
            },
            {
              title: 'Label',
              path: '/client-shipp',
              key: 'ship page client'
            }
          ]
        },
        {
          title: 'Orders',
          icon: 'iconamoon:box-light',
          path: '/orders',
          key: 'client orders',
        },
        {
          title: 'Rates-Sheets',
          icon: 'lucide:sheet',
          path: '/c-rate-sheets',
          key: 'client rate sheets',
        },
        {
          title: 'Reports',
          icon: 'mdi:printer-outline',
          path: 'client/reports',
          key: 'client reports',
        },
        {
          title: 'Settings',
          icon: 'uil:setting',
          key: 'settings clients',
          children: [
            {
              title: 'Admin Users',
              path: '/admin-user-client',
              key: 'admin user client page'
            }
          ]
        }
      ]
    }
  ]

  // Every time the route changes, it determines which level it belongs to and changes the parameters.
  const { setParamsV2 } = appStore.getState()
  const currentPath = location.pathname

  const level = getCurrentRoute(currentPath)
  if (level === 'ADMIN') setParamsV2({ CustomerId: 0, ShopId: 0 })
  else if (level === 'CUSTOMER') {
    setParamsV2({
      CustomerId: companiesStore.getState().companyGlobal?.id || 0,
      ShopId: 0
    })
  } else if (level === 'CLIENT') {
    setParamsV2({
      CustomerId: companiesStore.getState().companyGlobal?.id || 0,
      ShopId: customerStore.getState().shopGlobal?.id || 0
    })
  }

  const userType: number | undefined = profileStore.getState().profile?.tinUserType
  const listItems = filterByUserType(allNavItems, userType)
  let filterNavItems
  if (listItems) {
    filterNavItems = filterByKey(listItems, securityFormatted)
  } else {
    filterNavItems = filterByKey(allNavItems, securityFormatted)
  }

  return filterNavItems
}

export default navigation

import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Avatar, Box, Button, Card, CardContent, CardHeader, Chip,Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { deleteParent } from 'src/@http/customers'
import { ParentModel } from 'src/types/models/ParentModel'
import { RolModel } from 'src/types/models/RolModel'
import { logger } from 'src/utils/Logger'
import { showIfValidImage } from 'src/utils/misc'
import appStore from 'src/zustand/app'
import companyStore from 'src/zustand/companies'
import customerStore from 'src/zustand/customer'

import { DialogExpose, DialogGeneric } from '../generic/DialogGeneric'
import WrapperSecurity from '../generic/WrapperSeurityObject'
import { IconAction } from '../rateSheets/TableRateSheet'

const propertiesCommon = { flex: 1 }
const NoLogoIcon = () => {
  return (
    <svg style={{ height: 20, width: 20 }} fill={grey['500']} height="800" width="800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001"><path d="m160.235 276.571-144.5-159.983C6.352 122.166.001 132.299.001 144.002v256.005c0 17.672 14.328 31.994 32 31.994h268.625l-58.867-65.17c-44.766-6.676-79.352-44.169-81.524-90.26zM480.001 112.001h-112l-23.156-46.313c-5.422-10.84-16.5-17.687-28.625-17.687H195.782c-12.125 0-23.203 6.848-28.625 17.688l-10.836 21.672-68.539-76.767C75.985-2.539 55.735-3.61 42.594 8.211c-13.141 11.828-14.203 32.063-2.375 45.196l399.992 448c6.313 7.024 15.031 10.594 23.797 10.594 7.625 0 15.281-2.711 21.391-8.211 13.141-11.828 14.203-32.067 2.375-45.2L464.04 432h15.961c17.672 0 32-14.321 32-31.994V144.002c0-17.672-14.329-32.001-32-32.001zM237.196 177.94c6.086-1.219 12.359-1.938 18.805-1.938 53.016 0 96 42.981 96 96.002 0 10.223-1.758 19.996-4.727 29.227L237.196 177.94z"/></svg>
  )
}

const TableComapnies = () => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const { companies, getCompanies } = companyStore()

  useEffect(() => {
    setLoading(true)
    getCompanies()
      .then(() => setLoading(false))
      .catch((err: any) => {
        if (err) {
          logger.log('Error', err)
          // toast.error(err.hasOwnProperty('DescriptionError') ? err.DescriptionError : 'An unknown error occurred.');
        }
      })
  }, [])

  const refreshChanges = (refresh: boolean) => refresh && getCompanies()

  const columns: GridColumns = [
    { field: 'intCustomerId', headerName: 'Customer Id', ...propertiesCommon, maxWidth: 100 },
    { field: 'vchName', headerName: 'Name', ...propertiesCommon },
    {
      field: 'vchLogoPath',
      headerName: 'Logo',
      renderCell: params => (
        <Box width='100%' display='flex' justifyContent='center'>
          { showIfValidImage(params.value) 
          ? <img src={params.value} style={{ objectFit: 'contain', width: '100%', height: '45px' }} alt='' />
          : <Avatar variant="square" sx={{ bgcolor: grey['100'], borderRadius: '6px' }}><NoLogoIcon /></Avatar>
          } 
        </Box>
      )
    },
    { field: 'intCreatedUserId', headerName: 'Created by User', ...propertiesCommon, align: 'center' },
    {
      field: 'vchStatusName',
      headerName: 'Status',
      ...propertiesCommon,
      renderCell: params => (
        <Box width='100%' textAlign='center'>
          <Chip label={params.value} />
        </Box>
      )
    },
    {
      field: 'dtmCreatedDate',
      headerName: 'Create Date',
      ...propertiesCommon,
      renderCell: (params: any) => (
        <Typography variant='subtitle2'>
          {params.value ? format(new Date(params.value), 'd, MMM d, yyyy') : '-'}
        </Typography>
      )
    },
    {
      field: 'dtmUpdatedDate',
      headerName: 'Create Date',
      ...propertiesCommon,
      renderCell: (params: any) => (
        <Typography variant='subtitle2'>
          {params.value ? format(new Date(params.value), 'd, MMM d, yyyy') : '-'}
        </Typography>
      )
    },
    {
      field: '',
      headerName: 'Action',
      flex: 1,
      minWidth: 250,
      maxWidth: 250,
      headerAlign: 'center',
      renderCell: ({ row }: GridCellParams<RolModel>) => <RowOptions row={row} refreshChanges={refreshChanges} />
    }
  ]

  return (
    <Card>
      <CardContent>
        <Stack flexDirection='row' justifyContent='space-between' alignItems='center'>
          <CardHeader title={t('CUSTOMERS_LIST')} />
          <WrapperSecurity securityKey='add_new_customer_btn'>
            <Link to={'add'}>
              <Button variant='contained'>
                <Typography color={'white'}>{t('ADD_NEW_CUSTOMER')}</Typography>
              </Button>
            </Link>
          </WrapperSecurity>
        </Stack>
        <DataGrid
          checkboxSelection
          rows={companies}
          columns={columns}
          autoHeight
          getRowId={roles => roles.id}
          loading={loading}
          pageSize={15}
        />
      </CardContent>
    </Card>
  )
}

type RowProps = {
  row: ParentModel
  refreshChanges: (refresh: boolean) => void
}

const RowOptions = ({ row, refreshChanges }: RowProps) => {
  const { t } = useTranslation()
  const saveText = t('DELETE')
  const modalDeleteParent = useRef<DialogExpose>(null)
  const navigate = useNavigate()
  const { companies, setCompanyGlobal } = companyStore()
  const { setShops } = customerStore()
  const { setParamsV2, paramsV2 } = appStore()

  const handleClick = (id: number) => {
    if (id === -1) {
      modalDeleteParent.current?.open()
    } else {
      navigate(id.toString())
    }
  }

  const handleInvoice = (companyId: number) => {
    const company = companies.find(comp => comp.id === companyId);
    if(company){
      setCompanyGlobal(company || null)
      setParamsV2({
        ...paramsV2,
        CustomerId: company.id ? company.id: 0
      })
      setShops()
      navigate('/customer/batch', { replace: true })
    }
    else{
      toast.error('Error redirecting to invoice')
    }
  }

  const handleDeleteParent = async () => {
    modalDeleteParent.current?.close()
    try {
      await deleteParent(row.intCustomerId)
        .then(() => {
          toast.success(t('DELETED_SUCCESSFULLY'))
          refreshChanges(true)
        })
        .catch(err => toast.error(err.message))
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  return (
    <>
      <Box width='100%' display='flex' justifyContent='center'>
        <WrapperSecurity securityKey='invoice_customer_btn'>
          <IconAction title={t('INVOICE')} icon={'fa6-solid:file-invoice-dollar'} onClick={() => handleInvoice(row.id)} />
        </WrapperSecurity>
        <WrapperSecurity securityKey='rate_sheet_customer_btn'>
          <IconAction
            title={t('RATE_SHEETS')}
            icon={'lucide:sheet'}
            onClick={() => {
              navigate(`/company-editor/rate-sheets/${row.id}`, { state: { data: row } })
            }}
          />
        </WrapperSecurity>
        <WrapperSecurity securityKey='see_customer_btn'>
          <IconAction title={t('SEE')} icon={'mdi:eye'} datacy="view_customer_btn" onClick={() => handleClick(row.id)} />
        </WrapperSecurity>
        <WrapperSecurity securityKey='delete_customer_btn'>
          <IconAction title={t('DELETE')} icon='ic:outline-delete' color='error' datacy="delete_customer_btn" onClick={() => handleClick(-1)} />
        </WrapperSecurity>
      </Box>
      <DialogGeneric ref={modalDeleteParent} title={'DELETE'} confirmText={saveText} confirmFunc={handleDeleteParent}>
        {t('CONFIRM_DELETE_TEXT')}
      </DialogGeneric>
    </>
  )
}

export default TableComapnies

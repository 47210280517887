// ** React Imports
import { ForwardedRef, forwardRef, SetStateAction, SyntheticEvent, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Checkbox, MenuItem } from '@mui/material'
import { CircularProgress } from '@mui/material'
import Box, { BoxProps } from '@mui/material/Box'
import Button from '@mui/material/Button'
// ** MUI Imports
import Card from '@mui/material/Card'
import CardContent, { CardContentProps } from '@mui/material/CardContent'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Grid, { GridProps } from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { TableCellBaseProps } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
// ** Icon Imports
import Icon from 'src/@core/components/icon'
// ** Custom Component Imports
import Repeater from 'src/@core/components/repeater'
import { getOrderDetailId, getOrderId } from 'src/@http/orders'
import { getShops } from 'src/@http/shop'
// ** Types
import { DateType } from 'src/types/forms/reactDatepickerTypes'
import { OrderDto, OrderProductsDto } from 'src/types/models/OrderModel'
import { ShopModel } from 'src/types/models/ShopModels'
import appStore from 'src/zustand/app'
import orderStore from 'src/zustand/order'

interface PickerProps {
  label?: ''
}

const CustomInput = forwardRef(({ ...props }: PickerProps, ref: ForwardedRef<HTMLElement>) => {
  return <TextField size='small' inputRef={ref} {...props} sx={{ width: { sm: '250px', xs: '170px' } }} />
})

const options = ['Active', 'Inactive']

const optionsToday = ['ON', 'OFF']

const MUITableCell = styled(TableCell)<TableCellBaseProps>(({ theme }) => ({
  borderBottom: 0,
  paddingLeft: '0 !important',
  paddingRight: '0 !important',
  paddingTop: `${theme.spacing(1)} !important`,
  paddingBottom: `${theme.spacing(1)} !important`
}))

const CalcWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(2)
  }
}))

const RepeatingContent = styled(Grid)<GridProps>(({ theme }) => ({
  paddingRight: 0,
  display: 'flex',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  '& .col-title': {
    top: '-1.5rem',
    position: 'absolute'
  },
  [theme.breakpoints.down('lg')]: {
    '& .col-title': {
      top: '0',
      position: 'relative'
    }
  }
}))

const RepeaterWrapper = styled(CardContent)<CardContentProps>(({ theme }) => ({
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(12),
  '& .repeater-wrapper + .repeater-wrapper': {
    marginTop: theme.spacing(8)
  }
}))

const InvoiceAction = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(2, 0),
  borderLeft: `1px solid ${theme.palette.divider}`
}))

const initialOrderData: OrderDto = {
  id: 0,
  bigOrderId: 0,
  dtmOrderDate: new Date(),
  dtmHoldDate: new Date(),
  vchBatch: '',
  vchCustomerOrderId: '',
  intCustomerId: 0,
  intOrderBatch: 0,
  sinOrderTypeId: 0,
  vch3rdPartyPO: '',
  decOrderTotal: 0,
  vchEliteId: '',
  intServiceId: 0,
  intStatusId: 0,
  sinShipType: 0,
  tinSameDay: 0,
  vchShippingAccount: '',
  sinWarehouseId: 0,
  vchContact: '',
  vchCompany: '',
  vchTelephone: '',
  vchMail: '',
  vchAddress: '',
  vchPostCode: '',
  vchComments: '',
  vchWarehouseRemarks: '',
  vchLogisticsRemarks: '',
  intCreatedUserId: 0,
  intUpdatedUserId: 0,
  dtmUpdatedDate: new Date(),
  dtmCreatedDate: new Date(),
  sinOrderAddressStatusId: 0,
  tinUnitMeasureId: 0,
  vchWeight: '',
  vchInventory: '',
  vchStatusName: '',
  intCarrierId: 0,
  vchCarrierName: '',
  vchCustomerName: '',
  intParentId: 0,
  vchParentName: '',
  vchNote: '',
  vchCountry: '',
  vchState: '',
  vchCity: '',
  vchStatusColor: '',
  details: null,
  vchCarrierCustomerName: ''
}

const EditOrderCard = () => {
  // ** States
  const { setOrderZustand } = orderStore()
  const [loading, setLoading] = useState(true)
  const [count, setCount] = useState<number>(1)
  const [orderData, setOrderData] = useState<OrderDto>(initialOrderData)
  const [orderDetailData, setOrderDetailData] = useState<null | OrderProductsDto[]>(null)
  const [totalPrice, setTotalPrice] = useState<number[]>([])
  const [issueDate, setIssueDate] = useState<DateType>(orderData ? new Date(orderData.dtmOrderDate) : new Date())
  const { orderId } = appStore(state => ({
    orderId: state.orderId
  }))
  // ** Hook
  const [customers, setCustomers] = useState<ShopModel[] | null>(null)
  const [status, setStatus] = useState(options[0])
  const [todayStatus, setTodayStatus] = useState(optionsToday[1])

  const updateOrderField = (field: string, value: any) => {
    setOrderData(prevOrderData => ({
      ...prevOrderData,
      [field]: value
    }))

    setOrderZustand(orderData)
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        const customersData = await getShops()
        await setCustomers(customersData)
        const Orddata = await getOrderId(orderId)
        await setOrderData(Orddata)
        const dataOrdDetail = await getOrderDetailId(orderId)
        await setOrderDetailData(dataOrdDetail)
        await setOrderData(prevOrderData => ({
          ...prevOrderData,
          details: dataOrdDetail
        }))
        await setOrderZustand(orderData)
        if (dataOrdDetail) {
          const initialTotalPrice = dataOrdDetail.map(item => item.decPrice || 0)
          setTotalPrice(initialTotalPrice)
        }
        setLoading(false)
      } catch (error) {
        console.error('Error fetching order data:', error)
        setLoading(false)
      }
    }

    fetchData()
  }, [orderId])

  useEffect(() => {
    if (orderData?.intStatusId === 1) {
      setStatus(options[0])
    } else {
      setStatus(options[1])
    }
  }, [orderData.intStatusId])

  useEffect(() => {
    const updatedDetails = (orderData.details || []).map((detail, index) => {
      if (index < totalPrice.length) {
        return {
          ...detail,
          decPrice: totalPrice[index]
        }
      }

      return detail
    })

    const decOrderTotal = totalPrice.reduce((total, price) => total + price, 0)

    setOrderData(prevOrderData => ({
      ...prevOrderData,
      decOrderTotal: decOrderTotal,
      details: updatedDetails
    }))

    setOrderZustand(orderData)
  }, [totalPrice])

  // ** Deletes form
  const deleteForm = (e: SyntheticEvent, productName: string, index: number) => {
    const updatedTotalPrice = [...totalPrice]
    updatedTotalPrice[index] = 0
    setTotalPrice(updatedTotalPrice)
    const updatedOrderDetailData = orderData.details?.filter(detail => detail?.productName !== productName)
    setOrderData(prevOrderData => ({
      ...prevOrderData,
      details: updatedOrderDetailData !== undefined ? updatedOrderDetailData : null
    }))

    setOrderZustand(orderData)
    e.preventDefault()

    // @ts-ignore
    e.target.closest('.repeater-wrapper').remove()
  }

  const addProduct = () => {
    setCount(count + 1)
    const newOrderDetail: OrderProductsDto = {
      id: 0,
      bigOrderShopProductId: 0,
      intParentId: 0,
      intQuantity: 0,
      bigOrderId: 0,
      bigShopProductId: 0,
      vchLotId: '',
      decPrice: 0,
      intStatusId: 0,
      vchStatusName: '',
      sinOrder: 0,
      intProductStatusId: 0,
      vchProductStatusName: '',
      productLegacyNumber: '',
      productName: '',
      vchProductPartNumber: '',
      decProductWeight: 0,
      decProductPrice: 0,
      vchProductDescription: ''
    }
    setOrderDetailData(prevOrderDetailData =>
      prevOrderDetailData ? [...prevOrderDetailData, newOrderDetail] : [newOrderDetail]
    )
    setOrderData(prevOrderData => {
      if (prevOrderData.details === null) {
        return {
          ...prevOrderData,
          details: [newOrderDetail]
        }
      } else {
        return {
          ...prevOrderData,
          details: [...prevOrderData.details, newOrderDetail]
        }
      }
    })
    setOrderZustand(orderData)

    const unitPrice = newOrderDetail.decProductPrice || 0
    const weightQty = newOrderDetail.decProductWeight || 0
    const newTotalPrice = unitPrice * weightQty

    setTotalPrice(prevTotalPrice => [...prevTotalPrice, newTotalPrice])
  }

  const handleWeightQtyChange = (index: number, value: string, inputType: 'unitPrice' | 'Qty') => {
    if (orderDetailData) {
      const parsedValue = value ? parseFloat(value) : 0

      const updatedOrderDetailData = [...orderDetailData]

      if (inputType === 'unitPrice') {
        updatedOrderDetailData[index].decProductPrice = parsedValue

        const updatedDetails = (orderData.details || []).map((detail, innerindex) => {
          if (innerindex === index) {
            return {
              ...detail,
              decProductPrice: parsedValue
            }
          }

          return detail
        })

        setOrderData(prevOrderData => ({
          ...prevOrderData,
          details: updatedDetails
        }))

        setOrderZustand(orderData)
      } else if (inputType === 'Qty') {
        updatedOrderDetailData[index].intQuantity = parsedValue

        const updatedDetails = (orderData.details || []).map((detail, innerindex) => {
          if (innerindex === index) {
            return {
              ...detail,
              intQuantity: parsedValue
            }
          }

          return detail
        })

        setOrderData(prevOrderData => ({
          ...prevOrderData,
          details: updatedDetails
        }))

        setOrderZustand(orderData)
      }

      const unitPrice = updatedOrderDetailData[index].decProductPrice
      const weightQty = updatedOrderDetailData[index].intQuantity
      updatedOrderDetailData[index].decPrice = unitPrice * weightQty

      const newTotalPriceArray = updatedOrderDetailData.map(item => item.decPrice || 0)

      setTotalPrice(newTotalPriceArray)
      setOrderDetailData(updatedOrderDetailData)
    }
  }

  const handleChange = (event: { target: { value: SetStateAction<string> } }) => {
    setStatus(event.target.value)
    if (status == 'active') {
      updateOrderField('intStatusId', 1)
    } else {
      updateOrderField('intStatusId', 2)
    }
  }

  const handleChangeToday = (event: { target: { value: SetStateAction<string> } }) => {
    if (event.target.value == 'ON') {
      updateOrderField('tinSameDay', 1)
    } else {
      updateOrderField('tinSameDay', 0)
    }
    setTodayStatus(event.target.value)
  }

  const handleDate = (date: Date) => {
    updateOrderField('dtmOrderDate', date.toISOString().slice(0, 19))
    setIssueDate(date)
  }

  const handleHoldDate = (date: Date) => {
    updateOrderField('dtmHoldDate', date.toISOString().slice(0, 19))
    setIssueDate(date)
  }
  const calculateTotalPriceSum = () => {
    let sum = 0
    totalPrice.forEach(price => {
      sum += price || 0
    })

    return sum
  }

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xl={6} xs={12} sx={{ mb: { xl: 0, xs: 4 } }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ mb: 6, display: 'flex', alignItems: 'center' }}>
                    <img
                      src={`http://${window.location.hostname}:${window.location.port}/images/logo.png`}
                      alt='Logo'
                      style={{ maxWidth: '50px', maxHeight: '50px' }}
                    />
                    <Typography
                      variant='h6'
                      sx={{ ml: 2.5, fontWeight: 600, lineHeight: 'normal', textTransform: 'uppercase' }}
                    >
                      Ship Ez
                    </Typography>
                  </Box>
                  <div>
                    <Table sx={{ maxWidth: '250px' }}>
                      <TableBody>
                        <TableRow>
                          <MUITableCell style={{ width: '50%' }}>Order status:</MUITableCell>
                          <MUITableCell>
                            <TextField
                              size='small'
                              sx={{ maxWidth: '300px' }}
                              select
                              value={status}
                              onChange={handleChange}
                            >
                              {options.map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>
                          </MUITableCell>
                        </TableRow>
                        <TableRow>
                          <MUITableCell style={{ width: '50%' }}>Order type:</MUITableCell>
                          <MUITableCell>
                            <TextField
                              size='small'
                              sx={{ maxWidth: '300px' }}
                              defaultValue={'Not working' /*orderData?.vchOrderType*/}
                              InputProps={{
                                disabled: true
                              }}
                            />
                          </MUITableCell>
                        </TableRow>
                        <TableRow>
                          <MUITableCell style={{ width: '50%' }}>3rd Party PO:</MUITableCell>
                          <MUITableCell>
                            <TextField
                              size='small'
                              sx={{ maxWidth: '300px' }}
                              // defaultValue={orderData?.vch3rdPartyPO}
                              value={orderData?.vch3rdPartyPO}
                              onChange={e => {
                                const newValue = e.target.value
                                updateOrderField('vch3rdPartyPO', newValue)
                                setOrderData((prevOrderData: any) => ({
                                  ...prevOrderData,
                                  vch3rdPartyPO: newValue
                                }))
                              }}
                              onBlur={e => {
                                const newValue = e.target.value
                                updateOrderField('vch3rdPartyPO', newValue)
                                setOrderData((prevOrderData: any) => ({
                                  ...prevOrderData,
                                  vch3rdPartyPO: newValue
                                }))
                              }}
                            />
                          </MUITableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Box>
              </Grid>
              <Grid item xl={6} xs={12}>
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: { xl: 'flex-end', xs: 'flex-start' } }}
                >
                  <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h6' sx={{ mr: 2, width: '105px' }}>
                      Order
                    </Typography>
                    <TextField
                      size='small'
                      value={orderData?.bigOrderId}
                      sx={{ width: { sm: '250px', xs: '170px' } }}
                      InputProps={{
                        disabled: true,
                        startAdornment: <InputAdornment position='start'>#</InputAdornment>
                      }}
                    />
                  </Box>
                  <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
                    <Typography variant='body2' sx={{ mr: 3, width: '100px' }}>
                      Date Issued:
                    </Typography>
                    <DatePicker
                      id='issue-date'
                      disabled
                      selected={orderData ? new Date(orderData.dtmOrderDate) : issueDate}
                      showDisabledMonthNavigation
                      customInput={<CustomInput />}
                      onChange={(date: Date) => handleDate(date)}
                    />
                  </Box>
                  <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
                    <Typography variant='body2' sx={{ mr: 3, width: '100px' }}>
                      Hold Date:
                    </Typography>
                    <DatePicker
                      id='issue-date'
                      selected={orderData ? new Date(orderData.dtmHoldDate) : issueDate}
                      showDisabledMonthNavigation
                      customInput={<CustomInput />}
                      onChange={(date: Date) => handleHoldDate(date)}
                    />
                  </Box>
                  <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
                    <Typography variant='body2' sx={{ mr: 3, width: '100px' }}>
                      Elite Id:
                    </Typography>
                    <TextField
                      size='small'
                      value={orderData?.vchEliteId}
                      onChange={e => {
                        const newValue = e.target.value
                        updateOrderField('vchEliteId', newValue)
                        setOrderData((prevOrderData: any) => ({
                          ...prevOrderData,
                          vchEliteId: newValue
                        }))
                      }}
                      onBlur={e => {
                        const newValue = e.target.value
                        updateOrderField('vchEliteId', newValue)
                        setOrderData((prevOrderData: any) => ({
                          ...prevOrderData,
                          vchEliteId: newValue
                        }))
                      }}
                      sx={{ width: { sm: '250px', xs: '170px' } }}
                      InputProps={{
                        disabled: false,
                        startAdornment: <InputAdornment position='start'>#</InputAdornment>
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>

          <Divider />

          <CardContent>
            <Grid container>
              <Grid item xs={12} sm={6} sx={{ mb: { lg: 0, xs: 4 } }}>
                <Typography variant='body2' sx={{ mb: 3.5, fontWeight: 600 }}>
                  Customer Data:
                </Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <MUITableCell style={{ width: '50%' }}>Customer Id:</MUITableCell>
                        <MUITableCell>
                          <TextField
                            size='small'
                            sx={{ maxWidth: '300px' }}
                            value={orderData?.intCustomerId}
                            onChange={e => {
                              const newValue = e.target.value
                              updateOrderField('intCustomerId', newValue)
                              setOrderData((prevOrderData: any) => ({
                                ...prevOrderData,
                                intCustomerId: Number(newValue)
                              }))
                            }}
                            onBlur={e => {
                              const newValue = e.target.value
                              updateOrderField('intCustomerId', newValue)
                              setOrderData((prevOrderData: any) => ({
                                ...prevOrderData,
                                intCustomerId: Number(newValue)
                              }))
                            }}
                          />
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell style={{ width: '50%' }}>Customer Name:</MUITableCell>
                        <MUITableCell>
                          <TextField
                            size='small'
                            sx={{ maxWidth: '300px' }}
                            value={orderData?.vchCustomerName}
                            onChange={e => {
                              const newValue = e.target.value
                              updateOrderField('vchCustomerName', newValue)
                              setOrderData((prevOrderData: any) => ({
                                ...prevOrderData,
                                vchCustomerName: newValue
                              }))
                            }}
                            onBlur={e => {
                              const newValue = e.target.value
                              updateOrderField('vchCustomerName', newValue)
                              setOrderData((prevOrderData: any) => ({
                                ...prevOrderData,
                                vchCustomerName: newValue
                              }))
                            }}
                          />
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell style={{ width: '50%' }}>Order Id:</MUITableCell>
                        <MUITableCell>
                          <TextField
                            size='small'
                            sx={{ maxWidth: '300px' }}
                            value={orderData?.intOrderBatch}
                            onChange={e => {
                              const newValue = e.target.value
                              updateOrderField('intOrderBatch', newValue)
                              setOrderData((prevOrderData: any) => ({
                                ...prevOrderData,
                                intOrderBatch: Number(newValue)
                              }))
                            }}
                            onBlur={e => {
                              const newValue = e.target.value
                              updateOrderField('intOrderBatch', newValue)
                              setOrderData((prevOrderData: any) => ({
                                ...prevOrderData,
                                intOrderBatch: Number(newValue)
                              }))
                            }}
                          />
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell style={{ width: '50%' }}>Batch:</MUITableCell>
                        <MUITableCell>
                          <TextField
                            size='small'
                            sx={{ maxWidth: '300px' }}
                            value={orderData?.vchBatch}
                            onChange={e => {
                              const newValue = e.target.value
                              updateOrderField('vchBatch', newValue)
                              setOrderData((prevOrderData: any) => ({
                                ...prevOrderData,
                                vchBatch: newValue
                              }))
                            }}
                            onBlur={e => {
                              const newValue = e.target.value
                              updateOrderField('vchBatch', newValue)
                              setOrderData((prevOrderData: any) => ({
                                ...prevOrderData,
                                vchBatch: newValue
                              }))
                            }}
                          />
                        </MUITableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: ['flex-start', 'flex-end'] }}>
                <div>
                  <Typography variant='body2' sx={{ mb: 3.5, color: 'text.primary', fontWeight: 600 }}>
                    &nbsp;
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <MUITableCell>Contact:</MUITableCell>
                          <MUITableCell>
                            <TextField
                              size='small'
                              style={{ marginLeft: '10%' }}
                              sx={{ maxWidth: '300px' }}
                              value={orderData?.vchContact}
                              onChange={e => {
                                const newValue = e.target.value
                                updateOrderField('vchContact', newValue)
                                setOrderData((prevOrderData: any) => ({
                                  ...prevOrderData,
                                  vchContact: newValue
                                }))
                              }}
                              onBlur={e => {
                                const newValue = e.target.value
                                updateOrderField('vchContact', newValue)
                                setOrderData((prevOrderData: any) => ({
                                  ...prevOrderData,
                                  vchContact: newValue
                                }))
                              }}
                            />
                          </MUITableCell>
                        </TableRow>
                        <TableRow>
                          <MUITableCell>Company:</MUITableCell>
                          <MUITableCell>
                            <TextField
                              size='small'
                              style={{ marginLeft: '10%' }}
                              sx={{ maxWidth: '300px' }}
                              value={orderData?.vchCompany}
                              onChange={e => {
                                const newValue = e.target.value
                                updateOrderField('vchCompany', newValue)
                                setOrderData((prevOrderData: any) => ({
                                  ...prevOrderData,
                                  vchCompany: newValue
                                }))
                              }}
                              onBlur={e => {
                                const newValue = e.target.value
                                updateOrderField('vchCompany', newValue)
                                setOrderData((prevOrderData: any) => ({
                                  ...prevOrderData,
                                  vchCompany: newValue
                                }))
                              }}
                            />
                          </MUITableCell>
                        </TableRow>
                        <TableRow>
                          <MUITableCell>Telephone:</MUITableCell>
                          <MUITableCell>
                            <TextField
                              size='small'
                              style={{ marginLeft: '10%' }}
                              sx={{ maxWidth: '300px' }}
                              value={orderData?.vchTelephone}
                              onChange={e => {
                                const newValue = e.target.value
                                updateOrderField('vchTelephone', newValue)
                                setOrderData((prevOrderData: any) => ({
                                  ...prevOrderData,
                                  vchTelephone: newValue
                                }))
                              }}
                              onBlur={e => {
                                const newValue = e.target.value
                                updateOrderField('vchTelephone', newValue)
                                setOrderData((prevOrderData: any) => ({
                                  ...prevOrderData,
                                  vchTelephone: newValue
                                }))
                              }}
                            />
                          </MUITableCell>
                        </TableRow>
                        <TableRow>
                          <MUITableCell>Email:</MUITableCell>
                          <MUITableCell>
                            <TextField
                              size='small'
                              style={{ marginLeft: '10%' }}
                              sx={{ maxWidth: '300px' }}
                              value={orderData?.vchMail}
                              onChange={e => {
                                const newValue = e.target.value
                                updateOrderField('vchMail', newValue)
                                setOrderData((prevOrderData: any) => ({
                                  ...prevOrderData,
                                  vchMail: newValue
                                }))
                              }}
                              onBlur={e => {
                                const newValue = e.target.value
                                updateOrderField('vchMail', newValue)
                                setOrderData((prevOrderData: any) => ({
                                  ...prevOrderData,
                                  vchMail: newValue
                                }))
                              }}
                            />
                          </MUITableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </CardContent>

          <Divider />

          <CardContent>
            <Grid container>
              <Grid item xs={12} sm={6} sx={{ mb: { lg: 0, xs: 4 } }}>
                <Typography variant='body2' sx={{ mb: 3.5, fontWeight: 600 }}>
                  Ship Data:
                </Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <MUITableCell style={{ width: '50%' }}>Original Courier</MUITableCell>
                        <MUITableCell>
                          <TextField
                            size='small'
                            sx={{ maxWidth: '300px' }}
                            value={'Not working' /*orderData.vchShipCode*/}
                            InputProps={{
                              disabled: true
                            }}
                          />
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell style={{ width: '50%' }}>Original Service</MUITableCell>
                        <MUITableCell>
                          <TextField
                            size='small'
                            sx={{ maxWidth: '300px' }}
                            value={'Not Working' /*orderData.vchShipMethod*/}
                            InputProps={{
                              disabled: true
                            }}
                          />
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell style={{ width: '50%' }}>Actual Courier</MUITableCell>
                        <MUITableCell>
                          <TextField
                            size='small'
                            sx={{ maxWidth: '300px' }}
                            value={'Not Working' /*orderData.vchShipComplete*/}
                            InputProps={{
                              disabled: true
                            }}
                          />
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell style={{ width: '50%' }}>Actual Service</MUITableCell>
                        <MUITableCell>
                          <TextField
                            size='small'
                            sx={{ maxWidth: '300px' }}
                            value={'Not Working' /*orderData.vchIntlShipType*/}
                            InputProps={{
                              disabled: true
                            }}
                          />
                        </MUITableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: ['flex-start', 'flex-end'] }}>
                <div>
                  <Typography variant='body2' sx={{ mb: 3.5, fontWeight: 600 }}>
                    &nbsp;
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <MUITableCell>Ship Same Day:</MUITableCell>
                          <MUITableCell>
                            <Checkbox
                              size='small'
                              style={{ marginLeft: '10%' }}
                              sx={{ maxWidth: '300px' }}
                              defaultChecked
                              onChange={handleChangeToday}
                            />
                            {/* <TextField
                          size='small'
                          sx={{ maxWidth: '300px' }}
                          style={{ marginLeft: '10%' }}
                          defaultValue={'' /*orderData.shipSameDay*/}
                          </MUITableCell>
                        </TableRow>
                        <TableRow>
                          <MUITableCell>Shipping Account:</MUITableCell>
                          <MUITableCell>
                            <TextField
                              size='small'
                              style={{ marginLeft: '10%' }}
                              sx={{ maxWidth: '300px' }}
                              value={orderData?.vchShippingAccount}
                              onChange={e => {
                                const newValue = e.target.value
                                updateOrderField('vchShippingAccount', newValue)
                                setOrderData((prevOrderData: any) => ({
                                  ...prevOrderData,
                                  vchShippingAccount: newValue
                                }))
                              }}
                              onBlur={e => {
                                const newValue = e.target.value
                                updateOrderField('vchShippingAccount', newValue)
                                setOrderData((prevOrderData: any) => ({
                                  ...prevOrderData,
                                  vchShippingAccount: newValue
                                }))
                              }}
                            />
                          </MUITableCell>
                        </TableRow>
                        <TableRow>
                          <MUITableCell>Warehouse Code:</MUITableCell>
                          <MUITableCell>
                            <TextField
                              size='small'
                              style={{ marginLeft: '10%' }}
                              sx={{ maxWidth: '300px' }}
                              value={orderData.sinWarehouseId}
                              onChange={e => {
                                const newValue = e.target.value
                                updateOrderField('sinWarehouseId', newValue)
                                setOrderData((prevOrderData: any) => ({
                                  ...prevOrderData,
                                  sinWarehouseId: newValue
                                }))
                              }}
                              onBlur={e => {
                                const newValue = e.target.value
                                updateOrderField('sinWarehouseId', newValue)
                                setOrderData((prevOrderData: any) => ({
                                  ...prevOrderData,
                                  sinWarehouseId: newValue
                                }))
                              }}
                            />
                          </MUITableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </CardContent>

          <Divider />

          <RepeaterWrapper>
            <Repeater count={orderDetailData?.length || 0}>
              {(i: 0) => {
                const Tag = i === 0 ? Box : Collapse
                const orderDetail = orderDetailData ? orderDetailData[i] : null

                return (
                  <Tag key={i} className='repeater-wrapper' {...(i !== 0 ? { in: true } : {})}>
                    <Grid container>
                      <RepeatingContent item xs={12}>
                        <Grid container sx={{ py: 4, width: '100%', pr: { lg: 0, xs: 4 } }}>
                          <Grid item lg={6} md={6} xs={12} sx={{ px: 4, my: 2 }}>
                            <Typography variant='body2' className='col-title' sx={{ fontWeight: '600', mb: 1 }}>
                              Product
                            </Typography>
                            <TextField
                              fullWidth
                              placeholder='Product name...'
                              size='small'
                              defaultValue={orderDetail?.productName || ''}
                              onChange={e => {
                                const newValue = e.target.value
                                const updatedDetails = (orderData.details || []).map((detail, index) => {
                                  if (index === i) {
                                    return {
                                      ...detail,
                                      productName: newValue
                                    }
                                  }

                                  return detail
                                })

                                setOrderData(prevOrderData => ({
                                  ...prevOrderData,
                                  details: updatedDetails
                                }))
                                setOrderZustand(orderData)
                              }}
                              onBlur={e => {
                                const newValue = e.target.value
                                const updatedDetails = (orderData.details || []).map((detail, index) => {
                                  if (index === i) {
                                    return {
                                      ...detail,
                                      productName: newValue
                                    }
                                  }

                                  return detail
                                })

                                setOrderData(prevOrderData => ({
                                  ...prevOrderData,
                                  details: updatedDetails
                                }))
                                setOrderZustand(orderData)
                              }}
                            />
                            <Box sx={{ height: '16px' }}></Box>
                            <TextField
                              rows={2}
                              fullWidth
                              placeholder='Product description...'
                              multiline
                              size='small'
                              defaultValue={orderDetail?.vchProductDescription || ''}
                              onChange={e => {
                                const newValue = e.target.value
                                const updatedDetails = (orderData.details || []).map((detail, index) => {
                                  if (index === i) {
                                    return {
                                      ...detail,
                                      vchProductDescription: newValue
                                    }
                                  }

                                  return detail
                                })

                                setOrderData(prevOrderData => ({
                                  ...prevOrderData,
                                  details: updatedDetails
                                }))
                                setOrderZustand(orderData)
                              }}
                              onBlur={e => {
                                const newValue = e.target.value
                                const updatedDetails = (orderData.details || []).map((detail, index) => {
                                  if (index === i) {
                                    return {
                                      ...detail,
                                      vchProductDescription: newValue
                                    }
                                  }

                                  return detail
                                })

                                setOrderData(prevOrderData => ({
                                  ...prevOrderData,
                                  details: updatedDetails
                                }))
                                setOrderZustand(orderData)
                              }}
                            />
                          </Grid>
                          <Grid item lg={2} md={3} xs={12} sx={{ px: 4, my: 2 }}>
                            <Typography variant='body2' className='col-title' sx={{ fontWeight: '600', mb: 1 }}>
                              Unit Price
                            </Typography>
                            <TextField
                              size='small'
                              type='number'
                              placeholder='0'
                              onChange={e => handleWeightQtyChange(i, e.target.value, 'unitPrice')} // Pass 'unitPrice' as the inputType
                              defaultValue={orderDetail?.decProductPrice}
                              InputProps={{ inputProps: { min: 0 } }}
                            />
                          </Grid>
                          <Grid item lg={2} md={2} xs={12} sx={{ px: 4, my: 2 }}>
                            <Typography variant='body2' className='col-title' sx={{ fontWeight: '600', mb: 1 }}>
                              Qty
                            </Typography>
                            <TextField
                              size='small'
                              type='number'
                              placeholder='0'
                              onChange={e => handleWeightQtyChange(i, e.target.value, 'Qty')}
                              defaultValue={orderDetail?.intQuantity || 0}
                              InputProps={{ inputProps: { min: 0 } }}
                            />
                          </Grid>
                          <Grid item lg={2} md={1} xs={12} sx={{ px: 4, my: 2 }}>
                            <Typography variant='body2' className='col-title' sx={{ fontWeight: '600', mb: 1 }}>
                              Total per product
                            </Typography>
                            <Typography>${(totalPrice[i] || 0).toFixed(2)}</Typography>
                          </Grid>
                        </Grid>
                        <InvoiceAction>
                          <IconButton
                            size='small'
                            onClick={event => deleteForm(event, orderDetail?.productName ?? '', i)}
                          >
                            <Icon icon='mdi:close' fontSize={20} />
                          </IconButton>
                        </InvoiceAction>
                      </RepeatingContent>
                    </Grid>
                  </Tag>
                )
              }}
            </Repeater>

            <Grid container sx={{ mt: 4 }}>
              <Grid item xs={12} sx={{ px: 0 }}>
                <Button
                  size='small'
                  variant='contained'
                  onClick={addProduct}
                  startIcon={<Icon icon='mdi:plus' fontSize={20} />}
                >
                  Add Product
                </Button>
              </Grid>
            </Grid>
          </RepeaterWrapper>

          <Divider />

          <CardContent>
            <Grid container>
              <Grid item xs={12} sm={9} sx={{ order: { sm: 1, xs: 2 } }}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <MUITableCell style={{ width: '20%' }}>Warehouse remarks:</MUITableCell>
                        <MUITableCell>
                          <TextField
                            size='small'
                            sx={{ maxWidth: '300px' }}
                            value={orderData?.vchWarehouseRemarks}
                            onChange={e => {
                              const newValue = e.target.value
                              updateOrderField('vchWarehouseRemarks', newValue)
                              setOrderData((prevOrderData: any) => ({
                                ...prevOrderData,
                                vchWarehouseRemarks: newValue
                              }))
                            }}
                            onBlur={e => {
                              const newValue = e.target.value
                              updateOrderField('vchWarehouseRemarks', newValue)
                              setOrderData((prevOrderData: any) => ({
                                ...prevOrderData,
                                vchWarehouseRemarks: newValue
                              }))
                            }}
                          />
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell style={{ width: '20%' }}>Logistics remarks:</MUITableCell>
                        <MUITableCell>
                          <TextField
                            size='small'
                            sx={{ maxWidth: '300px' }}
                            value={orderData?.vchLogisticsRemarks}
                            onChange={e => {
                              const newValue = e.target.value
                              updateOrderField('vchLogisticsRemarks', newValue)
                              setOrderData((prevOrderData: any) => ({
                                ...prevOrderData,
                                vchLogisticsRemarks: newValue
                              }))
                            }}
                            onBlur={e => {
                              const newValue = e.target.value
                              updateOrderField('vchLogisticsRemarks', newValue)
                              setOrderData((prevOrderData: any) => ({
                                ...prevOrderData,
                                vchLogisticsRemarks: newValue
                              }))
                            }}
                          />
                        </MUITableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={3} sx={{ mb: { sm: 0, xs: 4 }, order: { sm: 2, xs: 1 } }}>
                <CalcWrapper>
                  <Typography variant='body2'>Subtotal:</Typography>
                  <Typography variant='body2' sx={{ fontWeight: 600 }}>
                    ${calculateTotalPriceSum().toFixed(2)}
                  </Typography>
                </CalcWrapper>
                <CalcWrapper>
                  <Typography variant='body2'>Discount:</Typography>
                  <Typography variant='body2' sx={{ fontWeight: 600 }}>
                    $0.00
                  </Typography>
                </CalcWrapper>
                <CalcWrapper>
                  <Typography variant='body2'>Tax:</Typography>
                  <Typography variant='body2' sx={{ fontWeight: 600 }}>
                    $0.00
                  </Typography>
                </CalcWrapper>
                <Divider />
                <CalcWrapper>
                  <Typography variant='body2'>Total:</Typography>
                  <Typography variant='body2' sx={{ fontWeight: 600 }}>
                    ${calculateTotalPriceSum().toFixed(2)}
                  </Typography>
                </CalcWrapper>
              </Grid>
            </Grid>
          </CardContent>

          <Divider />

          <CardContent>
            <InputLabel htmlFor='invoice-note' sx={{ mb: 2 }}>
              Comments:
            </InputLabel>
            <TextField
              rows={2}
              fullWidth
              multiline
              id='invoice-note'
              defaultValue={orderData?.vchComments !== 'null' ? orderData?.vchComments : 'No comments'}
              onChange={e => {
                const newValue = e.target.value
                updateOrderField('vchComments', newValue)
                setOrderData((prevOrderData: any) => ({
                  ...prevOrderData,
                  vchComments: newValue
                }))
              }}
              onBlur={e => {
                const newValue = e.target.value
                updateOrderField('vchComments', newValue)
                setOrderData((prevOrderData: any) => ({
                  ...prevOrderData,
                  vchComments: newValue
                }))
              }}
            />
          </CardContent>
        </Card>
      )}
    </>
  )
}

export default EditOrderCard

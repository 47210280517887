import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Icon } from '@iconify/react'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Buffer } from 'buffer';
import { format } from 'date-fns'
import { setCancelLabel, setServerLabelView } from 'src/@http/labels-server'
// import { setLabelReprint } from "src/@http/labels-server";
import { logger } from 'src/utils/Logger'
import { lookup } from 'src/utils/mimeTypes'
import labelStore from 'src/zustand/label'

import PrinterSelectModal from './PrinterSelectModal'

export const columnsShipp: GridColDef[] = [
  {
    flex: 0.1,
    minWidth: 90,
    sortable: false,
    field: 'actions',
    headerName: 'Actions',
    renderCell: ({ row }: any) => <RowOptions row={row} />
  },
  {
    field: 'bigLSShippId',
    headerName: 'Shipp Id',
    flex: 1,
    minWidth: 100,
    maxWidth: 120
  },
  {
    field: 'vchFromContactPersonName',
    headerName: 'From Contact Person Name',
    flex: 1,
    minWidth: 150,
    maxWidth: 150,
    renderCell: (params: GridRenderCellParams<any>) => {
      return (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          <p>{params.value || '-'}</p>
        </div>
      )
    }
  },
  {
    field: 'vchFromContactCompanyName',
    headerName: 'From Contact Company Name',
    flex: 1,
    minWidth: 190,
    maxWidth: 190,
    renderCell: (params: GridRenderCellParams<any>) => {
      return (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          <p>{params.value}</p>
        </div>
      )
    }
  },
  {
    field: 'vchToContactPersonName',
    headerName: 'To Contact Person Name',
    flex: 1,
    minWidth: 150,
    maxWidth: 150,
    renderCell: (params: GridRenderCellParams<any>) => {
      return (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          <p>{params.value}</p>
        </div>
      )
    }
  },
  {
    field: 'vchToContactCompanyName',
    headerName: 'To Contact Company Name',
    flex: 1,
    minWidth: 150,
    maxWidth: 150,
    renderCell: (params: GridRenderCellParams<any>) => {
      return (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          <p>{params.value}</p>
        </div>
      )
    }
  },
  {
    field: 'vchToAddressStreetLines',
    headerName: 'To Address Street Lines',
    flex: 1,
    minWidth: 200,
    maxWidth: 200,
    renderCell: (params: GridRenderCellParams<any>) => {
      return (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          <p>{params.value}</p>
        </div>
      )
    }
  },
  {
    field: 'vchToAddressCity',
    headerName: 'To Address City',
    flex: 1,
    minWidth: 140,
    maxWidth: 140
  },
  {
    field: 'vchToAddressStateOrProvinceCode',
    headerName: 'To Address State/Province Code',
    flex: 1,
    minWidth: 140,
    maxWidth: 140
  },
  {
    field: 'vchToAddressPostalCode',
    headerName: 'To Address Postal Code',
    flex: 1,
    minWidth: 140,
    maxWidth: 140
  },
  {
    field: 'vchToAddressCountryCode',
    headerName: 'To Address Country Code',
    flex: 1,
    minWidth: 140,
    maxWidth: 140
  },
  {
    field: 'vchPackages',
    headerName: 'Packages',
    flex: 1,
    minWidth: 100,
    maxWidth: 100,
    valueFormatter: (params: any) => (params.value ? params.value : '-')
  },
  {
    field: 'vchPackageType',
    headerName: 'Package Type',
    flex: 1,
    minWidth: 100,
    maxWidth: 100,
    valueFormatter: (params: any) => (params.value ? params.value : '-')
  },
  {
    field: 'decTotalWeight',
    headerName: 'Total Weight',
    flex: 1,
    minWidth: 100,
    maxWidth: 100
  },
  {
    field: 'decTotalValue',
    headerName: 'Total Value',
    flex: 1,
    minWidth: 100,
    maxWidth: 100
  },
  {
    field: 'dtmShipDate',
    headerName: 'Ship Date',
    flex: 1,
    minWidth: 110,
    maxWidth: 110,
    valueFormatter: (params: any) => {
      return params.value ? format(new Date(params.value), 'MM/dd/yyyy') : '-'
    }
  },
  {
    field: 'vchShipCode',
    headerName: 'Ship Code',
    flex: 1,
    minWidth: 120,
    maxWidth: 120
  },
  {
    field: 'vchCustomerCode',
    headerName: 'Customer Code',
    flex: 1,
    minWidth: 100,
    maxWidth: 100
  },
  {
    field: 'vchOrderNumber',
    headerName: 'Order Number',
    flex: 1,
    minWidth: 100,
    maxWidth: 100
  },
  {
    field: 'vchOrderSource',
    headerName: 'Order Source',
    flex: 1,
    minWidth: 100,
    maxWidth: 100
  },
  {
    field: 'vchShipFreight',
    headerName: 'Ship Freight',
    flex: 1,
    minWidth: 100,
    maxWidth: 100
  },
  {
    field: 'vchServiceName',
    headerName: 'Service',
    flex: 1,
    minWidth: 100,
    maxWidth: 100
  },
  {
    field: 'vchCarrierName',
    headerName: 'Carrier',
    flex: 1,
    minWidth: 100,
    maxWidth: 100
  },
  {
    field: 'vchShopName',
    headerName: 'Shop',
    flex: 1,
    minWidth: 100,
    maxWidth: 100
  },
  {
    field: 'vchCustomerName',
    headerName: 'Customer',
    flex: 1,
    minWidth: 100,
    maxWidth: 100
  },
  {
    field: 'decPackageDimensionLength',
    headerName: 'Package Dimension Length',
    flex: 1,
    minWidth: 100,
    maxWidth: 100
  },
  {
    field: 'decPackageDimensionWidth',
    headerName: 'Package Dimension Width',
    flex: 1,
    minWidth: 100,
    maxWidth: 100
  },
  {
    field: 'decPackageDimensionHeight',
    headerName: 'Package Dimension Height',
    flex: 1,
    minWidth: 100,
    maxWidth: 100
  },
  {
    field: 'decPackageWeight',
    headerName: 'Package Weight',
    flex: 1,
    minWidth: 100,
    maxWidth: 100
  },
  {
    field: 'vchResultTrackingNumber',
    headerName: 'Tracking Number',
    flex: 1,
    minWidth: 130,
    maxWidth: 130,
    valueFormatter: (params: any) => (params.value ? params.value : '-')
  }
]

const RowOptions = ({ row }: any) => {
  const { vchResultTrackingNumber } = row
  const { t } = useTranslation()
  const { setRefreshShippGridDate } = labelStore()
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOptions] = useState([t('REPRINT'), t('RELABEL'), t('CANCEL')])
  const [file, setFile] = useState<{
    fileState: File | null
    isZebra: boolean
  }>({
    fileState: null,
    isZebra: false
  })
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCancel = async () => {
    try {
      await setCancelLabel(row.vchResultTrackingNumber)
      toast.success(t('CANCELED_SUCCESSFULLY'))
      setRefreshShippGridDate()
    } catch (e: any) {
      logger.log('[Error]', e.DescriptionError)
      toast.error(e.DescriptionError)
    }
  }

  const handleRelabel = async () => {
    try {
      // await setServerLabelRelabel(row.bigLSShippId)
      toast.success(t('RELABEL_SUCCESSFULLY'))
      setRefreshShippGridDate()
    } catch (e: any) {
      logger.log('[Error]', e.DescriptionError)
      toast.error(e.DescriptionError)
    }
  }

  const handleReprint = async () => {
    try {
      const resp = await setServerLabelView(row.bigLSShippId)
      // Transformar base64 a PDF (manera correcta en el navegador)
      const base64Data = resp.base64LabelImage
      let mimeType = lookup(resp.originalExtensionFile.toLowerCase())?.type
      if (mimeType == '' && resp.originalExtensionFile == 'ZPL') {
        mimeType = 'text/plain'
      }
      const byteArray = new Uint8Array(Buffer.from(base64Data, 'base64'))
      navigator.clipboard.writeText(byteArray.toString())
      const newBlob = new Blob([byteArray], { type: mimeType })
      logger.log('[blob]', newBlob)
      const file = new File([newBlob], resp.nameFile, { type: mimeType })
      logger.log('[file]', file)
      setFile({
        fileState: file,
        isZebra: resp.originalExtensionFile == 'ZPL'
      })
    } catch (e) {
      logger.log('[Error]', e)
      toast.error('[Error]')
    } finally {
      setRefreshShippGridDate()
    }
  }

  const hActionsAccordingOptionMenu = async (option: string) => {
    if (option == t('REPRINT')) {
      handleReprint()
    } else if (option === t('CANCEL')) {
      handleCancel()
    } else if (option === t('RELABEL')) {
      handleRelabel()
    }
  }

  const handleView = async () => {
    await setServerLabelView(row.bigLSShippId)
      .then(resp => {
        window.open(resp.urlFile)
      })
      .catch(e => {
        logger.log('[Error]', e.DescriptionError)
        toast.error(e.DescriptionError)
      })
  }

  const handleClose = (option: string) => {
    hActionsAccordingOptionMenu(option)
    setAnchorEl(null)
  }

  const disabled = (option: string) => {
    if (option === t('CANCEL') && vchResultTrackingNumber === '') return true
    if (option === t('REPRINT') && vchResultTrackingNumber === '') return true
    if (option === t('RELABEL') && vchResultTrackingNumber === '') return false
    else return false
  }

  return (
    <>
      <PrinterSelectModal
        open={!!file.fileState}
        onClose={() =>
          setFile({
            fileState: null,
            isZebra: false
          })
        }
        file={file.fileState!}
        isZebra={file.isZebra}
      />
      <Box width='100%' display='flex' justifyContent='center'>
        <IconButton aria-label='more' onClick={handleClick}>
          <Icon icon='mdi:menu' />
        </IconButton>
        <IconButton aria-label='more' onClick={handleView}>
          <Icon icon='mdi:eye' />
        </IconButton>
        <Menu id='menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {menuOptions.map((option, index) => (
            <MenuItem key={index} onClick={() => handleClose(option)} disabled={disabled(option)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  )
}

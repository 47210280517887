import { useEffect, useState } from 'react'
// import { useLocation } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem, SxProps, Theme } from '@mui/material'
import Select from '@mui/material/Select'
import { getCarriers } from 'src/@http/carriers'
import { CarrierModel } from 'src/types/models/CarrierModel'
import { logger } from 'src/utils/Logger'

interface CourierSelectorProps {
  onlyActive?: boolean
  handleSelected: (sm: any) => any
  valueDefault?: number
  sx?: SxProps<Theme>
  size?: "small" | "medium"
  label?: string
  disabled?: boolean
  defaultCourier?: number
}

const SelectCouriers = ({ ...props }: CourierSelectorProps) => {
  const [couriers, setCouriers] = useState<CarrierModel[]>([]);
  const [courierSelected, setCourierSelected] = useState<CarrierModel | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    logger.log('Use Effect getCarriers');
    getCarriers()
      .then(res => setCouriers(res.list))
      .catch(e => toast(e));
  }, []);

  useEffect(() => {
    if (props.defaultCourier) {
      const courier = couriers.find(cour => cour.intCarrierId === props.defaultCourier);
      logger.log(courier);
      if (courier) {
        setCourierSelected(courier);
      }
    }
  }, [props.defaultCourier, couriers]);

  const handleCourier = (e: any) => {
    setCourierSelected(e.target.value);
    props.handleSelected(e.target.value);
  };

  return (
    <>
      <FormControl fullWidth sx={props.sx} size={props.size}>
        <InputLabel id='select-carrier-label'>{props.label || t('COURIER')}</InputLabel>
        <Select
          label={props.label || t('COURIER')}
          disabled={props.disabled}
          value={courierSelected?.intCarrierId || ''}
          labelId='select-carrier-label'
          onChange={handleCourier}
        >
          {props.valueDefault !== undefined && 
            (<MenuItem value={props.valueDefault}>
              {t('NOT_SELECTED')}
            </MenuItem>)
          }
          {couriers.map(item => (
            <MenuItem key={item.intCarrierId} value={item.id}>
              {item.vchName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectCouriers;

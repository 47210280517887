import { mountStoreDevtool } from 'simple-zustand-devtools'
import { getAccountManager } from 'src/@http/accountManager'
import { getUsers } from 'src/@http/user'
import { TypeTable } from 'src/pages/components/rateSheets/FilterCarrierRateSheet'
import { ParamV2Type } from 'src/types/apps/paramV2Types'
import { AccountManagerGetModel, UserGetModel /*, UserModel */ } from 'src/types/models/userModels'
import { formatedData } from 'src/utils/actionsHandsontable'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import carrierStore from './carrier'
import companiesStore from './companies'
import customerStore from './customer'
import invoiceStore from './invoice'
import orderStore from './order'
import profileStore from './profile'
import reportsStore from './reports'
import servicesStore from './services'
import userStore from './user'

interface State {
  paramsV2: ParamV2Type
  users: UserGetModel[]
  showPaymentDialog: boolean
  // refreshCount: number
  accountManagers: AccountManagerGetModel[]
  token: string
  customerRateSheet: any[]
  loading: boolean
  orderFilters: {
    customerName: string | undefined
    orderId: number | null
    startDate: string | undefined
    endDate: string | undefined
  }
  orderId: number | null
  invoiceId: number | null
  serviceEditorCarrierId: number | null
  typeTableRateSheet: string
  showTableCarriers: boolean
  clearDataRateSheet: boolean
  headerBaseRateDetails: any
  isSaveAsModalOpen: boolean
  textSave: string
  configBtnSave: {
    showBtn: boolean
    isSaveAs: boolean
    loadingBtn: boolean
    textSave: string
    disableBtn: boolean
  }
  showBtnSave: boolean
  // list:any
  cellListCourier: any[]
  cellListShop: any[]
  // formatedCells:any[][]
  weights: any[]
  headers: any[]
  ratesId: any | null
  listRateSheetVersion: any[]
  versionSelected: string
  typeTableValue: string[]
  // Customer
  cellListCustomer: any[]
  formatedCourierList: any[]
  courierRateSheetType: string
  isRenderTableError: boolean
}

export interface cellCustomer {
  bigCustomerRateDetailId: number
  bigCustomerRateId: number
  intZoneId: number
  vchZoneName: string
  intServiceWeigthId: number
  vchWeigthName: string
  decWeigth: number
  decCostBase: number
  decCostDiscount: number
  decDiscountPercent: number
  decMarkupPercent: number
  decSetRatePrice: number
  decFinalPrice: number
  decReturnPrice: number
  decReturnPercent: number
  intStatusId: number
  vchStatusName: string | null
  dtmDateInsert: string // Consider using a Date type if the string represents a date
  intCreatedUserId: number
  dtmUpdatedDate: string // Consider using a Date type if the string represents a date
  intUpdatedUserId: number
  tinUnitMeasureId: number
  vchUnitMeasure: string
  id: number
  isActive: boolean
  lastModifiedDate: string | null // Consider using a Date type if the string represents a date
}

interface Actions {
  setToken: () => void
  setInitialSetup: () => void
  setCustomerRateSheet: (rateSheets: any[]) => void
  setLoading: (loading: boolean) => void
  setParamsV2: (paramsV2: ParamV2Type) => void
  setServiceEditorCarrierId: (serviceEditorCarrierId: number) => void
  setInvoiceId: (invoiceId: number) => void
  setOrderId: (invoiceId: number) => void
  setOrderFilterCustomerName: (customerName: string | undefined) => void
  setOrderFilterOrderId: (orderId: number | null) => void
  setOrderFilterStartDate: (startDate: string | undefined) => void
  setOrderFilterEndDate: (endDate: string | undefined) => void
  setIsSaveAsModalOpen: (isSaveAsModalOpen: boolean) => void
  subtractPercentCourier: (table: string) => void
  addPercentCurrier: (table: string) => void
  setOrderFilters: (orderFilters: {
    customerName: string | undefined
    orderId: number | null
    startDate: string | undefined
    endDate: string | undefined
  }) => void
  logout: () => void
  setTypeTableRateSheet: (type: string) => void
  setShowTableCarriers: (show: boolean, confirmChange?: boolean) => void
  setHeaderBaseRateDetails: (data: any) => void
  setHeaderBaseShopRateDetails: (data: any) => void
  setUsers: () => void
  // setRefreshCount: (refreshCount: number) => void
  setAccountManagers: () => void
  setConfigBtnSave: (config: any) => void
  // setListZ: (list: any) => void
  setCellListCourier: (cell: any[]) => void
  setCellListShop: (cell: any[]) => void
  changeValueCell: (cellChanged: any, prop: string) => void
  changeValueCellCustomer: (cellChanged: any, prop: string) => void
  setRowsAndCols: (cellList: any[]) => void
  // setFormatedCells: (data: any) => void
  clearTable: () => void
  setVersionSelected: (version: string) => void
  setTextSave: (text: string) => void
  addPercentCustomer: (table: string) => void
  subtractPercentCustomer: (table: string) => void
  operationToPercentageSelectedCells: (cells: any, prop: string, operation: string, table: string) => void
  operationToPercentageSelectedCourierCells: (cells: any, prop: string, operation: string, table: string) => void
  hideBtnSave: () => void
  clearListVersions: () => void

  // Customer
  setHeaderBaseCustomerRateDetails: (data: any) => void
  setCellListCustomer: (cell: any[]) => void
  setHeaderBaseCustomerCouriersRateDetails: (data: any) => void
  changeFormatedListMeasure: () => void
  setShowPaymentDialog: (show: boolean) => void
  setIsRenderTableError: (isRenderTableError: boolean) => void
  setShowBtnSave: (show: boolean) => void
  // filterWeightsByMeasure: (measureName:string) => void
}

const initialState: State = {
  users: [],
  cellListCourier: [],
  cellListShop: [],
  accountManagers: [],
  token: '',
  courierRateSheetType: '',
  paramsV2: { CustomerId: 496, ShopId: 0 },
  customerRateSheet: [],
  loading: false,
  isSaveAsModalOpen: false,
  orderFilters: {
    customerName: '',
    orderId: null,
    startDate: '',
    endDate: ''
  },
  showBtnSave: false,
  textSave: '',
  orderId: null,
  invoiceId: null,
  serviceEditorCarrierId: null,
  typeTableRateSheet: TypeTable.MARKUP,
  showTableCarriers: false,
  clearDataRateSheet: false,
  headerBaseRateDetails: {},
  configBtnSave: {
    showBtn: false,
    isSaveAs: false,
    loadingBtn: false,
    textSave: 'CHANGES_UNSAVED',
    disableBtn: false
  },
  weights: [],
  headers: [],
  ratesId: null,
  listRateSheetVersion: [],
  versionSelected: '',
  showPaymentDialog: false,
  // Customer
  cellListCustomer: [],
  formatedCourierList: [],
  typeTableValue: ['Discount', 'Markup', 'Set Rate'],
  isRenderTableError: false
}

type DictionaryMap = {
  discountPrice: string
  basePrice: string
  discountCustomer: string
  markupCustomer: string
  setRateCustomer: string
  [key: string]: string // Agregar firma de índice
}

const dictionaryProp: DictionaryMap = {
  discountPrice: 'decDiscount',
  basePrice: 'decPrice',
  discountCustomer: 'decDiscountPercent',
  markupCustomer: 'decMarkupPercent',
  setRateCustomer: 'decSetRatePrice',
  discountCourier: 'decDiscount',
  setRateCourier: 'decPrice'
}

const appStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      setToken: () => {
        set({ token: '' })
      },
      setInitialSetup: () => {
        customerStore.getState().setShopPlatformsIntegration()
        userStore.getState().setUserTypes()

        profileStore.getState().setSecurityObjectsTypes()
        profileStore.getState().setProfiles()
        profileStore.getState().setModules()
      },
      setIsSaveAsModalOpen: (isSaveAsModalOpen: boolean) => {
        set({ isSaveAsModalOpen: isSaveAsModalOpen })
      },
      setParamsV2: (paramsV2: ParamV2Type) => {
        set({ paramsV2: paramsV2 })
      },
      setShowPaymentDialog: (show: boolean) => {
        set({ showPaymentDialog: show })
      },
      setShowBtnSave: (show: boolean) => {
        set({ showBtnSave: show })
      },
      setCustomerRateSheet: (rateSheets: any[]) => {
        set({ customerRateSheet: rateSheets })
      },
      setServiceEditorCarrierId: (serviceEditorCarrierId: number) => {
        set({ serviceEditorCarrierId: serviceEditorCarrierId })
      },
      setInvoiceId: (invoiceId: number) => {
        set({ invoiceId: invoiceId })
      },
      setOrderId: (orderId: number) => {
        set({ orderId: orderId })
      },
      setOrderFilterCustomerName: (customerName: string | undefined) => {
        set({ orderFilters: { ...get().orderFilters, customerName } })
      },
      setOrderFilterOrderId: (orderId: number | null) => {
        set({ orderFilters: { ...get().orderFilters, orderId } })
      },
      setOrderFilterStartDate: (startDate: string | undefined) => {
        set({ orderFilters: { ...get().orderFilters, startDate } })
      },
      setOrderFilterEndDate: (endDate: string | undefined) => {
        set({ orderFilters: { ...get().orderFilters, endDate } })
      },
      setOrderFilters: orderFilters => {
        set({ orderFilters: { ...get().orderFilters, ...orderFilters } })
      },
      setLoading: (loading: boolean) => {
        set({ loading: loading })
      },
      logout: () => {
        set(initialState)
        carrierStore.getState().reset()
        companiesStore.getState().reset()
        customerStore.getState().reset()
        invoiceStore.getState().reset()
        orderStore.getState().reset()
        profileStore.getState().reset()
        reportsStore.getState().reset()
        servicesStore.getState().reset()
        userStore.getState().reset()
      },
      setTypeTableRateSheet: (type: string) => {
        console.log('Type : ', type)
        set({ showBtnSave: true })
        set({ typeTableRateSheet: type })
      },
      setShowTableCarriers: (show: boolean, confirmChange?: boolean) => {
        confirmChange !== undefined && set({ clearDataRateSheet: confirmChange })
        set({ showTableCarriers: show })
      },
      setHeaderBaseRateDetails: (data: any) => {
        const { lstCarrierRateDetail } = data
        if (lstCarrierRateDetail) {
          set({ courierRateSheetType: 'courier' })
          set({ showBtnSave: false })
          // set({textSave: 'CHANGES_SAVED'})
          get().setRowsAndCols(lstCarrierRateDetail)
          set({ cellListCourier: lstCarrierRateDetail })

          const cellListCourier =
            servicesStore.getState().measure === '-1'
              ? formatedData(lstCarrierRateDetail)
              : formatedData(
                  lstCarrierRateDetail.filter((cell: any) => cell.tinUnitMeasureId === servicesStore.getState().measure)
                )
          console.log('Cell list Courier : ', cellListCourier)
          set({ formatedCourierList: cellListCourier })
          data.lstCarrierRateDetail = null
        }
        set({ headerBaseRateDetails: data })
      },
      clearTable: () => {
        set({ headers: [] })
        set({ weights: [] })
        set({ cellListCourier: [] })
        set({ cellListCustomer: [] })
        // set({formatedCells:[]})
      },

      setHeaderBaseShopRateDetails: (data: any) => {
        const { lstShopRateDetail } = data
        // console.log('Data : ', data)
        if (lstShopRateDetail) {
          set({ showBtnSave: false })
          // set({textSave: 'CHANGES_SAVED'})
          const cellList =
            servicesStore.getState().measure === '-1'
              ? lstShopRateDetail
              : lstShopRateDetail.filter((cell: any) => cell.tinUnitMeasureId === servicesStore.getState().measure)
          get().setRowsAndCols(lstShopRateDetail)
          // get().setFormatedCells(data.lstCustomerRateDetail)
          set({ cellListShop: cellList })
          data.lstShopRateDetail = null
        }
        set({ headerBaseRateDetails: data })
      },
      setUsers: async () => {
        set({ users: (await getUsers()).list })
      },
      setAccountManagers: async () => {
        set({ accountManagers: await getAccountManager() })
      },
      setConfigBtnSave: (config: any) => {
        set({ configBtnSave: config })
      },
      setCellListCourier: (list: any[]) => {
        set({ cellListCourier: list })
      },
      setCellListShop: (list: any[]) => {
        set({ cellListShop: list })
      },
      setTextSave: (text: string) => {
        set({ textSave: text })
      },
      setRowsAndCols: (cellList: any[]) => {
        const lHeaders = [...new Set(cellList.map(obj => obj.vchZoneName))]
        set({ headers: ['Weight', ...lHeaders] })
        set({ weights: [...new Set(cellList.map((obj: any) => `${obj.vchWeigthName}  ${obj.vchUnitMeasure}`))] })
      },
      changeValueCell: (cellChanged: any, prop: string) => {
        set({ showBtnSave: true })
        const newList = get().cellListCourier.map(cell => {
          if (cell.intServiceWeigthId === cellChanged.intServiceWeigthId && cell.intZoneId === cellChanged.intZoneId) {
            const updatedCell = { ...cell }
            updatedCell[dictionaryProp[prop]] = cellChanged[prop]

            return updatedCell
          } else return cell
        })
        // logger.log('New List cell list: ', newList)
        set({ cellListCourier: newList })
      },
      changeValueCellCustomer: (cellChanged: any, prop: string) => {
        set({ showBtnSave: true })
        const newList = get().cellListShop.map(cell => {
          if (cell.intServiceWeigthId === cellChanged.intServiceWeigthId && cell.intZoneId === cellChanged.intZoneId) {
            const updatedCell = { ...cell }
            updatedCell[dictionaryProp[prop]] = cellChanged[prop]

            return updatedCell
          } else return cell
        })
        // logger.log('New List cell list: ', newList)
        set({ cellListShop: newList })
      },
      addPercentCurrier: (table: string) => {
        set({ showBtnSave: true })
        const prop = table === 'Markup' ? 'markupCurrier' : 'discountCurrier'
        const newList = get().cellListCourier.map(cell => {
          const updatedCell = { ...cell }
          updatedCell[dictionaryProp[prop]] =
            (Number(cell[dictionaryProp[prop]]) * Number(customerStore.getState().addSubstract)) / 100 +
            Number(cell[dictionaryProp[prop]])
          updatedCell.editionMode = { editedCell: true, table: table === 'Markup' ? 'markup' : 'discount' }

          return updatedCell
        })
        set({ cellListCourier: newList })
        customerStore.setState({ addSubstract: 0 })
      },
      subtractPercentCourier: (table: string) => {
        set({ showBtnSave: true })
        const prop = table === 'Markup' ? 'markupCourier' : 'discountCourier'
        const newList = get().cellListCourier.map(cell => {
          const updatedCell = { ...cell }
          updatedCell[dictionaryProp[prop]] =
            Number(cell[dictionaryProp[prop]]) -
            (Number(cell[dictionaryProp[prop]]) * Number(customerStore.getState().addSubstract)) / 100
          updatedCell.editionMode = { editedCell: true, table: table === 'Markup' ? 'markup' : 'discount' }

          return updatedCell
        })
        set({ cellListCourier: newList })
        customerStore.setState({ addSubstract: 0 })
      },
      addPercentCustomer: (table: string) => {
        set({ showBtnSave: true })
        const prop = table === 'Markup' ? 'markupCourier' : 'typeTableRateSheet'
        const newList = get().cellListShop.map(cell => {
          const updatedCell = { ...cell }
          updatedCell[dictionaryProp[prop]] =
            Number(cell[dictionaryProp[prop]]) + Number(customerStore.getState().addSubstract)
          updatedCell.editionMode = { editedCell: true, table: table === 'Markup' ? 'markup' : 'discount' }

          return updatedCell
        })
        set({ cellListShop: newList })
        customerStore.setState({ addSubstract: 0 })
      },
      hideBtnSave: () => {
        set({ showBtnSave: false })
      },
      subtractPercentCustomer: (table: string) => {
        set({ showBtnSave: true })
        const prop = table === 'Markup' ? 'markupCustomer' : 'discountCustomer'
        const newList = get().cellListShop.map(cell => {
          const updatedCell = { ...cell }
          updatedCell[dictionaryProp[prop]] =
            Number(cell[dictionaryProp[prop]]) - Number(customerStore.getState().addSubstract)
          updatedCell.editionMode = { editedCell: true, table: table === 'Markup' ? 'markup' : 'discount' }

          return updatedCell
        })
        set({ cellListShop: newList })
        customerStore.setState({ addSubstract: 0 })
      },
      operationToPercentageSelectedCourierCells: (cells: any, prop: string, operation: string) => {
        // console.log("cells", cells)
        set({ showBtnSave: true })
        const newList = get().cellListCourier.map(cell => {
          const matchingObj = cells.find(
            (obj2: any) => obj2.intServiceWeigthId === cell.intServiceWeigthId && obj2.intZoneId === cell.intZoneId
          )

          console.log({ v: cell[dictionaryProp[prop]], prop: dictionaryProp[prop] })

          if (matchingObj) {
            return {
              ...cell,
              [dictionaryProp[prop]]:
                operation === 'add'
                  ? Number(cell[dictionaryProp[prop]]) +
                    (Number(cell[dictionaryProp[prop]]) * Number(customerStore.getState().addSubstract)) / 100
                  : Number(cell[dictionaryProp[prop]]) -
                    (Number(cell[dictionaryProp[prop]]) * Number(customerStore.getState().addSubstract)) / 100,
              editionMode: { editedCell: true, table: 'discount' }
            }
          } else {
            return cell
          }
        })
        set({ cellListCourier: newList })
        set({ formatedCourierList: formatedData(newList) })
        customerStore.setState({ addSubstract: 0 })
        customerStore.setState({ selectedCellCoordCustomer: null })
        customerStore.setState({ showBtnAddSubstract: false })
      },
      operationToPercentageSelectedCells: (cells: any, prop: string, operation: string, table: string) => {
        // console.log("cells", cells)
        set({ showBtnSave: true })
        const newList = get().cellListShop.map(cell => {
          const matchingObj = cells.find(
            (obj2: any) => obj2.intServiceWeigthId === cell.intServiceWeigthId && obj2.intZoneId === cell.intZoneId
          )

          if (matchingObj) {
            return {
              ...cell,
              [dictionaryProp[prop]]:
                operation === 'add'
                  ? Number(cell[dictionaryProp[prop]]) + Number(customerStore.getState().addSubstract)
                  : Number(cell[dictionaryProp[prop]]) - Number(customerStore.getState().addSubstract),
              editionMode: { editedCell: true, table }
            }
          } else {
            return cell
          }
        })
        set({ cellListShop: newList })
        customerStore.setState({ addSubstract: 0 })
        customerStore.setState({ selectedCellCoordCustomer: null })
        customerStore.setState({ showBtnAddSubstract: false })
      },
      setVersionSelected: (version: string) => {
        set({ versionSelected: version })
      },
      clearListVersions: () => {
        set({ listRateSheetVersion: [] })
      },

      // Customer
      setHeaderBaseCustomerRateDetails: (data: any) => {
        const { lstCustomerRateDetail } = data
        if (lstCustomerRateDetail) {
          set({ showBtnSave: false })
          const cellList =
            servicesStore.getState().measure === '-1'
              ? lstCustomerRateDetail
              : lstCustomerRateDetail.filter((cell: any) => cell.tinUnitMeasureId === servicesStore.getState().measure)
          // set({textSave: 'CHANGES_SAVED'})
          get().setRowsAndCols(cellList)
          // get().setFormatedCells(data.lstCustomerRateDetail)
          set({ cellListShop: lstCustomerRateDetail })
          // data.lstCustomerRateDetail = null
        }
        set({ headerBaseRateDetails: data })
      },
      setHeaderBaseCustomerCouriersRateDetails: (data: any) => {
        const { lstCustomerRateDetail } = data
        if (lstCustomerRateDetail) {
          set({ courierRateSheetType: 'customer' })
          set({ showBtnSave: false })
          const cellList =
            servicesStore.getState().measure === '-1'
              ? lstCustomerRateDetail
              : lstCustomerRateDetail.filter((cell: any) => cell.tinUnitMeasureId === servicesStore.getState().measure)
          // set({textSave: 'CHANGES_SAVED'})
          set({ cellListCourier: cellList.map((cell: any) => ({ ...cell, decCostDiscount: cell.decFinalPrice })) })
          set({
            formatedCourierList: formatedData(
              cellList.map((cell: any) => ({ ...cell, decCostDiscount: cell.decFinalPrice })),
              'customer'
            )
          })
          get().setRowsAndCols(lstCustomerRateDetail)
          // get().setFormatedCells(data.lstCustomerRateDetail)
          // data.lstCustomerRateDetail = null
        }
        set({ headerBaseRateDetails: data })
      },
      changeFormatedListMeasure: () => {
        const cellList =
          servicesStore.getState().measure === '-1'
            ? get().cellListCourier
            : get().cellListCourier.filter((cell: any) => cell.tinUnitMeasureId === servicesStore.getState().measure)
        if (get().courierRateSheetType === 'customer') set({ formatedCourierList: formatedData(cellList, 'customer') })
        // if((get().courierRateSheetType === 'courier'))
        else set({ formatedCourierList: formatedData(cellList) })
      },
      setCellListCustomer: (list: any[]) => {
        set({ cellListShop: list })
      },
      setIsRenderTableError: (isRenderTableError: boolean) => {
        set({ isRenderTableError: isRenderTableError })
      }
    }),
    {
      name: 'app-store'
    }
  )
)

console.log('process.env.NODE_ENV', process.env.NODE_ENV)

if (process.env.NODE_ENV === 'development') mountStoreDevtool('app-store', appStore)

export default appStore

import { useTranslation } from "react-i18next"
import { Card, CardContent, CardHeader, Chip } from "@mui/material"
import { format } from "date-fns"
import { getListEventPaginate } from "src/@http/event"

import ServerDataGrid, { ServiceDataGridProps } from "../components/generic/ServerDataGrid"

const columns = [
	{ field: "bigEvent", headerName: "ID", flex: 1, maxWidth: 50 },
	{ field: "bigSourceId", headerName: "Source Id", flex: 1, maxWidth: 90 },
	{
		field: "dtmDate", 
		headerName: "Date",
		flex: 1,
		maxWidth: 110, 
		type: 'date',
		valueFormatter: ({ value }: { value: string }) => {
			return value ? format(new Date(value), 'MM/dd/yyyy') : '-'
		}
	},
	{ field: "intUserId", headerName: "User Id", flex: 1, maxWidth: 90 },
	{
		field: "isActive", 
		headerName: "Status",
		flex: 1,
		maxWidth: 110, 
		type: 'date',
		renderCell: ({ row }: any) => {
			return <Chip label={row.isActive ? 'Active' : 'Inactive'} color={row.isActive ? 'success' : 'default'} />
		}
	},
	{ field: "sinEventType", headerName: "Event Type", flex: 1, maxWidth: 110 },
	{ field: "vchDetail", headerName: "Detail", flex: 1},
	{ field: "vchFullName", headerName: "FullName", flex: 1 },
]

const EventPage = () => {
	const { t } = useTranslation()
	const service = async ({ currectPage: currentPage, pageSize }: ServiceDataGridProps) => {
		return await getListEventPaginate(pageSize, currentPage, 1) // TODO: Know which type to show by default
	}

	return (
		<Card>
			<CardHeader title={t('EVENT')} />
			<CardContent>
				<ServerDataGrid columns={columns} service={service} />
			</CardContent>
		</Card>
	)
}

export default EventPage
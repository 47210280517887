import { useRef, useState } from "react"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Icon } from "@iconify/react"
import { Grid, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material"
import { GridCellParams, GridColumns } from "@mui/x-data-grid"
import CustomAvatar from 'src/@core/components/mui/avatar'
import { deleteShop } from "src/@http/shop"
import { CarrierModel } from "src/types/models/CarrierModel"
import { ShopModel } from "src/types/models/ShopModels"
import { handleVisibilityAccordingKey, showIfValidImage } from "src/utils/misc"
import appStore from "src/zustand/app"
import carrierStore from "src/zustand/carrier"
import customerStore from "src/zustand/customer"
import invoiceStore from "src/zustand/invoice"

import { DialogExpose, DialogGeneric } from "../generic/DialogGeneric"
import WrapperSecurity from "../generic/WrapperSeurityObject"
import { IconAction } from "../rateSheets/TableRateSheet"

interface RowCustomer {
  id: number
  name: {
    avatar: string
    name: string
    email: string | null
  }
  lastDate: string
  status: {
    id: number
    text: string
  }
  intShopId: string
}

type UnionCustomer = RowCustomer & ShopModel;

export const colsShop: GridColumns = [
  {
    field: 'intShopId',
    headerName: 'Client Id',
    flex: 1,
    renderCell: ({ row }: GridCellParams<ShopModel>) => {
      return (
        <Typography variant='subtitle1' color='primary'>
          {row.intShopId}
        </Typography>
      )
    }
  },
  {
    field: 'vchName',
    headerName: 'Name',
    flex: 2,
    // minWidth: 300,
    // maxWidth: 900,
    renderCell: ({ row }: GridCellParams<ShopModel>) => {
      return (
        <Grid container columnGap={3}>
          <Grid
            sx={{ display: { xs: 'none', md: 'flex', lg: 'flex' }, maxWidth: '40px !important' }}
            item
            xs={6}
            lg={2}
            justifyContent='center'
            alignItems='center'
          >
            <CustomAvatar /*src={row.avatar || ''}*/ alt='avatar' sx={{ width: 30, height: 30 }}>
              <Icon icon='mdi:truck' />
            </CustomAvatar>
          </Grid>
          <Grid item xs={6} lg={8} display='flex' flexDirection='column' justifyContent='center'>
            <Typography variant='subtitle2' fontWeight={600}>
              {row.vchName}
            </Typography>
            {row.vchEmail ? (
              <Typography variant='subtitle2' fontWeight={300}>
                {row.vchEmail}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      )
    }
  },
  {
    field: 'vchLogoPath',
    headerName: 'Logo',
    renderCell: params => (
      <Grid container display='flex' justifyContent='center'>
        {showIfValidImage(params.value) && (
          <img src={params.value} style={{ objectFit: 'contain', width: '100%', height: '45px' }} alt='' />
        )}
      </Grid>
    )
  },
  {
    field: 'vchLegacyNumber',
    headerName: 'Legacy Id',
    flex: 1
  },
  {
    field: 'dtmCreatedDate',
    headerName: 'Created Date',
    flex: 1,
    minWidth: 150,
    maxWidth: 150,
    renderCell: ({ row }: GridCellParams<CarrierModel>) => (
      <Typography variant='subtitle2' fontWeight={300}>
        {new Date(row.dtmCreatedDate).toLocaleDateString('en-us', {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }) || '-'}
      </Typography>
    )
  },
  {
    field: '',
    headerName: 'Action',
    headerAlign: 'center',
    flex: 1,
    minWidth: 250,
    maxWidth: 250,
    renderCell: ({ row }: GridCellParams<RowCustomer>) => <RowOptionsShop row={row} />
  }
]

const RowOptionsShop = ({ row }: { row: UnionCustomer }) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const dialogRef = useRef<DialogExpose>(null)

  const { setInvoiceFilter } = invoiceStore()
  const { setCustomerId, setCustomerName } = customerStore()
  const { setServiceEditorCarrierId } = appStore(state => ({
    setServiceEditorCarrierId: state.setServiceEditorCarrierId
  }))
  const { setRefreshCarriersDate } = carrierStore()
  const { paramsV2, setParamsV2 } = appStore()

  // Navigation
  const navigate = useNavigate()
  const url = `/customer/rate-sheets/${row.intShopId || 0}`
  const urlView = '/customer/view'

  // Menu más acciones
  const optionsCustomer = [
    { title: t('DELETE'), show: handleVisibilityAccordingKey('delete_menu_option_client') },
    { title: t('PAUSE'), show: true },
    { title: t('RESUME'), show: true },
    { title: t('CLONE'), show: handleVisibilityAccordingKey('clone_menu_option_client') },
    { title: t('COURIERS'), show: true }
  ]
  
  const handleClick = (event: any) => setAnchorEl(event.currentTarget)
  const handleClose = (option: string) => {
    setAnchorEl(null)
  }

  const handleInvoiceShop = () => {
    const urlInvoice = '/customer/invoice'

    const customerId = row.intShopId || (row.name && row.name.name) || null
    const customerName = row.vchName || null
    setCustomerId(Number(customerId) ?? 0)
    setCustomerName(customerName ?? '')
    // setShopName(customerName ?? '')
    setParamsV2({
      CustomerId: paramsV2.CustomerId,
      ShopId: Number(customerId) || 0
    })
    setInvoiceFilter({
      isSearching: false,
      isProp: false,
      tabValue: '1',
      intStatusId: 1,
      customerName: customerName,
      carrierName: null
    })
    navigate(urlInvoice)
  }

  const handleDelete = () => {
    dialogRef.current?.open().then(async confirm => {
      if (confirm)
        try {
          await deleteShop(row.id)
          toast.success(t('DELETED_SUCCESS') + '! 👌', { duration: 4000 })
          setRefreshCarriersDate()
        } catch (err: any) {
          toast.error(err.message + '🙄', { duration: 4000 })
        }
    })
  }

  const setOptionMenu = (option: string) => {
    if (option === t('COURIERS')) navigate('/customer/couriers')
    if (option === t('DELETE')) handleDelete()
  }

  return (
    <Stack display='flex' flexDirection='row' width='100%' justifyContent='space-around'>
      <WrapperSecurity securityKey="invoice_client_btn">
        <IconAction title={t('INVOICE')} icon={'fa6-solid:file-invoice-dollar'} onClick={handleInvoiceShop} />
      </WrapperSecurity>
      <WrapperSecurity securityKey="rate_sheet_client_btn">
        <IconAction
          title={t('RATE_SHEET')}
          icon={'lucide:sheet'}
          onClick={() => {
            navigate(url, { state: { data: row || null , type: 'shop' }}) 
          }}
        />
      </WrapperSecurity>
      <WrapperSecurity securityKey="products_editor_client_btn">
        <IconAction
          title={'Product editor'}
          icon={'fluent-mdl2:product-variant'}
          onClick={() => navigate('/customer/products-editor/' + row.id, { state: { data: row } })}
        />
      </WrapperSecurity>
      <WrapperSecurity securityKey="see_client_btn">
        <IconAction
          title={t('SEE')}
          icon={'mdi:eye'}
          onClick={() => {
            setServiceEditorCarrierId(row.id)
            navigate(urlView, { state: { data: row, mode: 'view' } })
          }}
        />
      </WrapperSecurity>
      <IconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true' onClick={handleClick}>
        <Icon icon='mdi:dots-vertical' />
      </IconButton>
      <Menu
        keepMounted
        id='long-menu'
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        PaperProps={{ style: { maxHeight: 200, maxWidth: 150 } }}
      >
        {
          optionsCustomer.map(({ title, show }: any) => (
            show ? <MenuItem key={title} onClick={() => setOptionMenu(title)}>
              {title}
            </MenuItem> : null
          ))
        }
      </Menu>
      <DialogGeneric
        title={`DELETE_CUSTOMER`}
        ref={dialogRef}
        confirmText='CONFIRM'
        cancelText='CANCEL'
      >
        {t(`CONFIRM_DELETE_CUSTOMER`)}
      </DialogGeneric>
    </Stack >
  )
}
import { format } from 'date-fns'

import { Pagination } from '../user'
import { getAsync, postAsync, postAsyncShippForm } from '..'

const EndpointLsShipp = (pageSize = 10, currentPage = 1) =>
  `/v1.0/ControllerLsShipp/Paginate?PageSize=${pageSize}&CurrentPage=${currentPage}`
const EndpointLabelReprint = (trackingNumber: string) => `api/v1.0/ControllerServerLabelInternal/${trackingNumber}/rePrintLabel`
const EndpointLabelCancel = (trackingNumber: string) => `api/v1.0/ControllerServerLabelInternal/${trackingNumber}/cancelLabel`
const EndpointSLPdf = (shippId: number) => `api/v1.0/ControllerServerLabel/getPdfLabel/${shippId}`
/* DEFINITIONS */
export type SLReprintResponse = {
  urlFile: string | URL | undefined
  shippId: 0
  trackingNumber: string  
  base64LabelImage: string
  nameFile: string
  statusCode: string
  statusMessage: string
  extensionFile: string
  originalExtensionFile: string
  errors: string
  data?: any
}

/* CALLS */
export const getLSShipp = (params: any ): Promise<{ list: any[]; pagination: Pagination }> => {
  const body = {
    ...params,
    startDate: params.startDate != null ? format(new Date(params.startDate), 'yyyy-MM-dd') : null,
    endDate: params.endDate != null ? format(new Date(params.endDate), 'yyyy-MM-dd') : null,
    moduleId: params.moduleId,
    StatusId: -1
  }
  console.log('body getLSShipp', body)

  return postAsync(EndpointLsShipp(params.pageSize, params.currectPage), body)
}

export const setServerLevelInternal = (data: any): Promise<any> => {
  return postAsyncShippForm('api/v1.0/ControllerServerLabelInternal/getLabel', data)
}

export const setLabelReprint = (trackingNumber: string): Promise<SLReprintResponse> => {
  return postAsyncShippForm(EndpointLabelReprint(trackingNumber), {})
}

export const setServerLabelView = (shippId: number): Promise<SLReprintResponse> => {
  return postAsyncShippForm(EndpointSLPdf(shippId), {})
}

export const setCancelLabel = (trackingNumber: string): Promise<SLReprintResponse> => {
  return postAsyncShippForm(EndpointLabelCancel(trackingNumber), {})
}

export const setServerLabelRelabel = (): Promise<any> => {
  return postAsyncShippForm('api/v1.0/ControllerServerLabelInternal/reLabel', {})
}

export const setPackageTypes = (carrierId: number, serviceId: number ): Promise<any> => {
  return getAsync(`/v1.0/ControllerServicePackageType?Status=${1}&carrierId=${carrierId}&packageTypeId=${-1}&serviceId=${serviceId}`)
}
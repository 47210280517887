import { ForwardedRef, forwardRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Checkbox,FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import Icon from 'src/@core/components/icon'

import { DialogExpose, DialogGeneric } from "../generic/DialogGeneric"

type OptionAccountProp = {
  title: string
  optionSelected: (option: string, amount?: string) => void
  setRechargeAmount: (amount: string) => void
}

const ModalOptionAccount = forwardRef(
  ({ title, optionSelected, setRechargeAmount }: OptionAccountProp, ref: ForwardedRef<DialogExpose>) => {
    const { t } = useTranslation()
    const [confirmButtonText, setConfirmButtonText] = useState('Continue')
    const [selectedOption, setSelectedOption] = useState('Credit Card')
    const [amount, setAmount] = useState('')
    const [minimunAmount, setMinimunAmount] = useState('')
    const [check, setCheck] = useState<boolean>(false)

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedOption(event.target.value)
      setConfirmButtonText('Continue')
    }

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setAmount(event.target.value)
      setRechargeAmount(event.target.value)
      setConfirmButtonText('Continue')
    }

    const handleSelection = () => {
      if (selectedOption === 'PayPal') {
        optionSelected('Own Account')
      } else {
        optionSelected('Ez Account')
      }
    }

    return (
      <DialogGeneric
        ref={ref}
        title={title}
        confirmDisabled={selectedOption === '' || (selectedOption === 'Own Account' && amount === '')}
        styleBoxContent={{ mb: 0, py: 0 }}
        confirmText={confirmButtonText}
        confirmFunc={handleSelection}
      >
        <Box>
          <Typography variant='caption' fontSize={14} sx={{ mb: 2 }}>
            Choose one of the following options:
          </Typography>
        </Box>

        <FormControl sx={{ mb: 2 }}>
          <RadioGroup value={selectedOption} onChange={handleRadioChange} row>
            <FormControlLabel
              value='Credit Card'
              control={<Radio />}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Icon icon={'mdi:credit-card'} fontSize={24} />
                  <Typography sx={{ marginLeft: '1px' }}>Credit Card</Typography>
                </Box>
              }
            />
            {false && (
              <>
                <FormControlLabel
                  value='Debit Card'
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Icon icon={'mdi:credit-card-outline'} fontSize={24} />
                      <Typography sx={{ marginLeft: '1px' }}>Debit Card</Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  value='PayPal'
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Icon icon={'mdi:paypal'} fontSize={24} />
                      <Typography sx={{ marginLeft: '1px' }}>Paypal</Typography>
                    </Box>
                  }
                />
              </>
            )}
          </RadioGroup>
          <TextField
            label='Amount'
            variant='outlined'
            fullWidth
            value={amount}
            type='number'
            onChange={handleAmountChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => {
                  setCheck(!check)
                }}
              />
            }
            label={t('AUTOMATIC_RECHARGE')}
          />
          {check && (
            <TextField
              label={t('MINIMUN_AMOUNT')}
              variant='outlined'
              fullWidth
              value={minimunAmount}
              type='number'
              onChange={event => {
                setMinimunAmount(event.target.value)
              }}
            />
          )}
        </FormControl>
      </DialogGeneric>
    )
  }
)

export default ModalOptionAccount
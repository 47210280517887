import { deleteAsync, getAsync, getAsyncRaw, postAsync, putAsync } from 'src/@http'
import { RateSheetVersionsGetModel } from 'src/types/models/CarrierModel'
import {
  BaseRateDetailsCustomer,
  CustomerModel,
  CustomerModelPost,
  RateSheetVersionsCustomerModel
} from 'src/types/models/CustomerModels'
import { IGenericResponse } from 'src/types/response/response'

import { Pagination } from '../user'

export interface CustomerWPagination {
  list: CustomerModel[]
  pagination: Pagination
}

/* Endpoints definition */
const vApi = '/v1.0/'
const EndpointCustomers = `${vApi}ControllerCustomer/`
const GetCustomers = `${EndpointCustomers}?Status=Enabled`

const GetCustomerById = (customerId: number) => `${EndpointCustomers}${customerId}`
const Customer = (pageSize = 10, currentPage = 1) =>
  `${EndpointCustomers}Paginate?PageSize=${pageSize}&CurrentPage=${currentPage}&Status=Enabled`
const RateSheetVersionCustomer = (params: RateSheetVersionsCustomerParams) =>
  `${vApi}ControllerCustomerRate/Customer/${params.customerId}/Carrier/${params.carrierId}/Service/${params.serviceId}/Warehouse/${params.warehouseId}/measure/${params.measureId}`
const BaseRatesDetailsCustomer = (customerRateId: number, measureId: number | string) =>
  `${vApi}ControllerCustomerRate/${customerRateId}/measure/${measureId}`
const BaseRateDetails = `${vApi}ControllerCustomerRate`

const NewCustomerRate = ({ serviceId, measureId }: { serviceId: number; measureId: number | string }) =>
  `${vApi}ControllerCustomerRate/new/Service/${serviceId}/measure/${measureId}`

export const getCustomers = (): Promise<CustomerModel[]> => {
  return getAsync(GetCustomers)
}

export const postCustomers = (body: CustomerModelPost): Promise<null> => {
  return postAsync(EndpointCustomers, body)
}

export const putCustomers = (body: CustomerModelPost): Promise<null> => {
  return putAsync(EndpointCustomers, body)
}

export const deleteCustomer = (id: number) => {
  return deleteAsync(EndpointCustomers + id)
}
export const getCustomersPaginate = (pageSize: number, currentPage: number): Promise<CustomerWPagination> => {
  return getAsync(Customer(pageSize, currentPage))
}
export const getCustomerById = (customerId: string | number): Promise<CustomerModel> => {
  const id = typeof customerId === 'string' ? Number(customerId) : customerId

  return getAsync(GetCustomerById(id))
}

type RateSheetVersionsCustomerParams = { [key: string]: number | string }
export const getRateSheetVersionsCustomer = ({
  customerId,
  carrierId,
  serviceId,
  measureId,
  warehouseId = 3
}: RateSheetVersionsCustomerParams): Promise<RateSheetVersionsCustomerModel[]> => {
  return getAsync(RateSheetVersionCustomer({ customerId, carrierId, serviceId, warehouseId, measureId }))
}

export const getBaseRateCustomerDetails = (
  CarrierRateId: number,
  measureId: number | string
): Promise<BaseRateDetailsCustomer> => {
  return getAsync(BaseRatesDetailsCustomer(CarrierRateId, measureId))
}

export const addBaseRateDetailsCustomer = (newData: any) => {
  return postAsync(BaseRateDetails, newData)
}

export const createCustomerRate = (params: {
  serviceId: number
  measureId: number | string
  customerRateId?: number
}) => {
  return getAsyncRaw<IGenericResponse<RateSheetVersionsGetModel>>(NewCustomerRate(params), {
    customerRateId: params.customerRateId
  })
}

import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { RateSheetVersionsGetModel } from 'src/types/models/CarrierModel'
import { IGenericResponse } from 'src/types/response/response'

import { createShopRate, getVerifyCarrierRate, getVerifyCustomerRate } from '.'

const useVerifyCustomerRateQueryKey = (params: unknown) => {
  return ['verifyCustomerRate', params]
}

export const useVerifyCustomerRate = (
  params: {
    carrierId: number
    start: string
    end: string
    customerId?: number
    shop?: number
    serviceId: number
  },
  options?: Partial<UseQueryOptions>
) => {
  return useQuery({
    queryKey: useVerifyCustomerRateQueryKey(params),
    queryFn: () => getVerifyCustomerRate(params),
    ...options,
    retry: 0
  })
}

const useVerifyCarrierRateQueryKey = (params: unknown) => {
  return ['verifyCarrierRate', params]
}

export const useVerifyCarrierRate = (
  params: { carrierId: number; start: string; end: string; serviceId: number },
  options?: Partial<UseQueryOptions>
) => {
  return useQuery({
    queryKey: useVerifyCarrierRateQueryKey(params),
    queryFn: () => getVerifyCarrierRate(params),
    ...options,
    retry: 0
  })
}

interface IParams {
  serviceId: number
  measureId: number | string
  shopRateId?: number
}

export const useCreateShopRate = (
  options?: Partial<UseMutationOptions<AxiosResponse<IGenericResponse<RateSheetVersionsGetModel>>, unknown, IParams>>
) => {
  return useMutation({
    mutationFn: (params: IParams) => createShopRate(params),
    ...options
  })
}

import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import { t } from 'i18next'
import { createProfile, editProfile, getObjectSecurity } from 'src/@http/profiles'
import { CrudView } from 'src/types/forms/CrudView'
import { ProfileModel, ProfilePermission, SecurityObjectModel } from 'src/types/models/ProfileModel'
import profileStore from 'src/zustand/profile'
import userStore from 'src/zustand/user'

const STATUS = {
  Invisible: 3, // Deleted
  Enabled: 1, // Active
  Disabled: 2 // Inactive
}

const defaultValues: ProfileModel = {
  vchName: '',
  intStatusId: 0,
  vchStatusName: '',
  intCreatedUserId: 0,
  intUpdatedUserId: 0,
  dtmUpdatedDate: '',
  dtmCreatedDate: '',
  intProfileId: 0,
  profilePermissions: []
}

/* function toPermissionModel(list: any, mode: string) {
  return list.map((object: any) => {
    return {
      id: object.intSecurityObjectId,
      bigProfilePermissionId: object.intSecurityObjectId,
      intProfileId: mode !== CrudView.ADD ? 0 : object.intProfileId,
      intSecurityObjectId: object.intSecurityObjectId,
      tinPermissionType: mode !== CrudView.ADD ? 3 : object.tinPermissionType,
      intStatusId: mode !== CrudView.ADD ? 3 : object.intStatusId,
      vchStatusName: mode !== CrudView.ADD ? null : object.vchStatusName,
      vchStatusColor: mode !== CrudView.ADD ? null : object.vchStatusColor,
      vchName: object.vchName || object.vchSecurityObjectName || ''
    }
  })
} */

const ProfileAddOrEdit = () => {
  const { setSecurityObjects, securityObjects, setProfilesById, profileSelected } = profileStore()
  const { dataUserLogged } = userStore()
  const [loading, setLoading] = useState(false)
  const [newListObjectSecurity, setNewListObjectSecurity] = useState<ProfilePermission[] | []>([])
  
  const { state } = useLocation()
  const [editable, setEditable] = useState(CrudView.ADD === state?.mode ? true : false)
  const navigation = useNavigate()
  const title = getTitleByMode(state?.mode, state.data?.intProfileId)

  const form = useForm<ProfileModel>({ defaultValues: state.mode === CrudView.ADD ? defaultValues : state.data })
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty }
  } = form
  const btnSaveDisabled = Object.keys(errors).length > 0 || !isDirty

  const getObjectsSecurity = async (mode?: string) => {
    try {
      setLoading(true)
      const res = await getObjectSecurity()
      setSecurityObjects(res.list)
      if (mode === CrudView.EDIT) {
        getObjectSecurityAccordingMode(res.list, CrudView.EDIT)
      } else {
        getObjectSecurityAccordingMode(res.list)
      }
      setLoading(false)
    } catch (error: any) {
      toast.error(error.DescriptionError)
    }
  }

  const getObjectSecurityAccordingMode = (list: SecurityObjectModel[], mode?: string) => {
    /* if (mode === CrudView.EDIT) {
      const prevObject = newListObjectSecurity
      const updatedData = list.map(item => {
        const matchingItem = prevObject.find(obj => obj.id === item.id)

        if (matchingItem) {
          return { ...item, intStatusId: matchingItem.intStatusId, vchStatusName: matchingItem.vchStatusName }
        } else {
          return item
        }
      })

      updatedData.forEach(newItem => {
        if (!list.find(obj => obj.id === newItem.id)) {
          updatedData.push(newItem)
        }
      })

      setNewListObjectSecurity(toPermissionModel(updatedData, CrudView.EDIT))
    } else*/ if (CrudView.ADD === state?.mode) {
      const objectsInvisibleByDefault = list.map(object => {
        return {
          id: object.intSecurityObjectId,
          bigProfilePermissionId: object.intSecurityObjectId,
          intProfileId: 0,
          intSecurityObjectId: object.intSecurityObjectId,
          tinPermissionType: 3,
          intStatusId: object.intStatusId,
          vchStatusName: null,
          vchStatusColor: null,
          vchName: object.vchName || ''
        }
      })

      setNewListObjectSecurity(objectsInvisibleByDefault)
    } else if (CrudView.VIEW === state?.mode) {
      if (profileSelected === null || profileSelected?.intProfileId !== state?.data.intProfileId) {
        setProfilesById(state?.data.intProfileId)
      } else profileSelected && setNewListObjectSecurity(profileSelected.profilePermissions)
    }
  }

  const handleStatusChange = (event: any, objectId: number) => {
    console.log('Handle Status change : ', event.target.value, objectId)
    setNewListObjectSecurity(prevObj => {
      return prevObj.map((obj: ProfilePermission) => {
        if (obj.id === objectId) {
          return { ...obj, intStatusId: event.target.value }
        }

        return obj
      })
    })
  }

  const onSubmit = async () => {
    setValue('profilePermissions', newListObjectSecurity)
    console.log('New List object security : ', newListObjectSecurity)
    setDefaultValues()
    const data = getValues()

    if (state?.mode === CrudView.ADD) {
      await createProfile(data)
        .then(() => navigation('/profiles-editor', { state: { refresh: true } }))
        .catch(error => toast.error(error.message))
    } else if (state?.mode === CrudView.VIEW) {
      console.log('Data de preview', data)
      await editProfile(data)
        .then(() => navigation('/profiles-editor', { state: { refresh: true } }))
        .catch(error => toast.error(error.message))
    }
  }

  const setDefaultValues = () => {
    const values = {
      vchStatusName: 'Active',
      intStatusId: 1,
      intCreatedUserId: Number(dataUserLogged.idUser),
      intUpdatedUserId: Number(dataUserLogged.idUser),
      dtmUpdatedDate: new Date().toISOString(),
      dtmCreatedDate: new Date().toISOString()
    }
    Object.entries(values).forEach(([key, value]: [string, any]) => setValue(key as keyof ProfileModel, value))
  }

  useEffect(() => {
    securityObjects.length === 0 ? getObjectsSecurity() : getObjectSecurityAccordingMode(securityObjects)
  }, [])

  useEffect(() => {
    if (profileSelected && CrudView.ADD !== state?.mode) {
      setNewListObjectSecurity(profileSelected.profilePermissions)
    }
  }, [profileSelected])

  return (
    <Card>
      <CardHeader
        title={
          editable && state?.mode !== CrudView.ADD ? getTitleByMode(CrudView.EDIT, state.data?.intProfileId) : title
        }
      />
      <CardContent>
        {!editable && CrudView.ADD !== state?.mode && (
          <Button
            variant='contained'
            startIcon={<Icon icon={'mdi:pencil'} />}
            fullWidth
            sx={{ mb: 5 }}
            onClick={() => {
              setEditable(true)
              // List all security objects. If they exist update status
              getObjectsSecurity(CrudView.EDIT)
            }}
          >
            {t('EDIT')}
          </Button>
        )}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container rowGap={3}>
            <Grid item xs={12} md={12}>
              <TextField
                label={t('NAME')}
                fullWidth
                disabled={!editable}
                {...register('vchName', { required: 'Name is required' })}
                error={!!errors.vchName}
                helperText={errors.vchName?.message}
              />
            </Grid>
            <Grid container justifyContent={'center'}>
              {loading && <CircularProgress color='secondary' sx={{ mt: 2 }} />}
              {newListObjectSecurity.length !== 0 &&
                !loading &&
                newListObjectSecurity.map((object: ProfilePermission, index: number) => (
                  <Grid item xs={12} md={6} lg={6} xl={4} key={object.id} px={2}>
                    <Card
                      sx={{
                        p: '1rem .8rem',
                        my: 3,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span
                        style={{
                          alignSelf: 'center',
                          marginRight: '1rem',
                          fontWeight: '500',
                          textTransform: 'uppercase'
                        }}
                      >
                        {object.vchName || object.vchSecurityObjectName}
                      </span>
                      <FormControl variant='outlined'>
                        <InputLabel id='status-select-label'>{t('STATUS')}</InputLabel>
                        <Select
                          label={t('STATUS')}
                          labelId='status-select-label'
                          id='status-select'
                          disabled={!editable}
                          defaultValue={object.intStatusId}
                          onChange={status => handleStatusChange(status, object.intSecurityObjectId)}
                        >
                          <MenuItem value={STATUS.Invisible}>{t('INVISIBLE')}</MenuItem>
                          <MenuItem value={STATUS.Enabled}>{t('ENABLED_STATUS')}</MenuItem>
                          <MenuItem value={STATUS.Disabled}>{t('DISABLED_STATUS')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Card>
                  </Grid>
                ))}
            </Grid>
            {editable && (
              <Grid item xs={12} sm={12} sx={{ alignItems: 'center' }}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  sx={{ minWidth: '194px', maxWidth: '194px' }}
                  disabled={btnSaveDisabled && !editable}
                >
                  {t('SAVE')}
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

const getTitleByMode = (mode: string, id?: number) => {
  if (mode === CrudView.ADD && id === undefined) return 'Add New Profile'
  if (mode === CrudView.EDIT) return `Edit Profile: ${id}`
  if (mode === CrudView.VIEW) return `View Profile: ${id}`
}

export default ProfileAddOrEdit

import { postAsync, postFormAsync } from 'src/@http'
/* Endpoints definition */
const EndpointUploadFile = (uploadFileType: number) =>
  `/v1.0/ControllerUploadFile/UploadFile?uploadFileType=${uploadFileType}`

const EndpointImportInvoice = '/v1.0/ControllerImportRawInvoice/ImportRaw'

const ImportInvoice = (filePath: string, carrierid: number | null, dateInvoice: string, userId: number | null) =>
  `${EndpointImportInvoice}?fileName=${filePath}&carrierId=${carrierid}&userId=${userId}&invoiceNumber=9826722&dateInvoice=${dateInvoice}`

// export const uploadFile = (file: File): Promise<boolean> => {
//   const formData = new FormData();
//   formData.append('file', file, file.name);
// }

export enum UploadFileType {
  'Invoice' = 1,
  'Customer' = 2,
  'Shop' = 3,
  'Couriers' = 4,
  'LabelSAccount' = 5
}

export type RtaUploadFile = {
  pathFile: string
  nameFile: string
  virtualPathFile: string
}
export const uploadFile = (file: File, uploadFileType = UploadFileType.Invoice): Promise<boolean | RtaUploadFile> => {
  const formData = new FormData()
  formData.append('file', file, file.name)

  return postFormAsync(EndpointUploadFile(uploadFileType), formData)
}

export const importRawInvoice = (
  filePath: string,
  carrierid: number | null,
  dateInvoice: string,
  userId: number | null
): Promise<boolean> => {
  const url = ImportInvoice(filePath, carrierid, dateInvoice, userId)

  return postAsync(url, {})
}

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, Stack, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import { getUsers } from 'src/@http/user'
import ServerDataGrid, { ServiceDataGridProps } from 'src/pages/components/generic/ServerDataGrid'
import WrapperSeurityObject from 'src/pages/components/generic/WrapperSeurityObject'
import { UserGetModel } from 'src/types/models/userModels'
import userStore from 'src/zustand/user'

import columns from './columns'

export const fakeData: UserGetModel[] = []

const AdminUserList = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { refreshUserGridDate } = userStore()
  const service = async ({ currectPage, pageSize }: ServiceDataGridProps) => {
    return await getUsers(pageSize, currectPage)
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Stack flexDirection='row' justifyContent='space-between'>
              <CardHeader title={t('USER_LIST')} subheader={t('FIND_ALL_THE_USERS_REGISTERED_IN_THE_PLATFORM')} />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <WrapperSeurityObject securityKey='export_button_adm_user'>
                  <Button
                    sx={{ mr: 4, mb: 2 }}
                    color='secondary'
                    variant='outlined'
                    startIcon={<Icon icon='mdi:export-variant' fontSize={20} />}
                  >
                    {t('EXPORT')}
                  </Button>
                </WrapperSeurityObject>
                <WrapperSeurityObject securityKey='add_button_adm_user'>
                  <Button sx={{ mb: 2 }} onClick={() => navigate('/admin-user/new')} variant='contained'>
                    <Typography color={'white'}>{t('ADD_USER')}</Typography>
                  </Button>
                </WrapperSeurityObject>
              </Box>
            </Stack>
            <Grid container spacing={6}></Grid>
          </CardContent>

          <Divider sx={{ mb: 2 }} />

          <ServerDataGrid columns={columns} service={service} refreshDate={refreshUserGridDate} />
        </Card>
      </Grid>
    </Grid>
  )
}

export default AdminUserList

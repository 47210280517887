import {
  deleteAsync,
  deleteAsyncAdmin,
  getAsync,
  getAsyncAdmin,
  postAsync,
  postAsyncAdmin,
  putAsync,
  putAsyncAdmin
} from 'src/@http'
import { SecurityObjectModel } from 'src/types/models/ProfileModel'
import { UserGetModel, UserModel } from 'src/types/models/userModels'

const userId = 5 /* userStorage.getUserId() */
const EndpointUser = '/v1.0/ControllerUser'

/* Endpoints definition */
const Users = (pageSize = 10, currentPage = 1) =>
  `${EndpointUser}?PageSize=${pageSize}&CurrentPage=${currentPage}&Status=Enabled`
// const  UsersCustomers = (ageSize, currentPage) => `${EndpointUser}?PageSize=${pageSize}&CurrentPage=${currentPage}&Status=Enabled`
const ChangeUserPassword = `${userId}/change-password`
const ChangeActualPassword = `${EndpointUser}/UpdateActualPassword`
const GetDetail = 'users/me/detail'
const UpdateDetail = '/users'
const RecoveryPassword = `${EndpointUser}/RecoveryPassword`
const UpdatePasswordByRecovery = `${EndpointUser}/UpdatePasswordByRecovery`
const PermissionList = (userId: number) => `${EndpointUser}/PermissionList?userId=${userId}`
const UserType = '/v1.0/ControllerCacheUserType/types'

export interface Pagination {
  numberOfPages: number
  actualPage: number
  numberOfRecords: number
  pageSize: number
}

interface UserPost {
  vchName: string
  vchLastName: string
  tinUserType: number
  intCustomerId: number
  vchMail: string
  intShopId?: number
  vchPassword: string
  vchFullName: string
  listUserRole: { sinRoleId: number; intCustomerId: number | null }[]
  bitIsAccountManager: number
}

interface UserPut {
  id: number
  intUserId: number
  intCustomerId?: number
  vchName?: string
  vchLastName?: string
  tinUserType?: number
  intShopId?: number
  vchMail?: string
  vchPassword?: string
  vchFullName?: string
  listUserRole?: { sinRoleId: number; intCustomerId: number | null }[]
  bitIsAccountManager: number
}

export interface User {
  intUserId: number
  intCustomerId?: number
  intParentId: number
  vchParentName: string
  vchName: string
  intShopId?: number
  vchLastName: string
  tinUserType: number
  userTypeName: string
  vchMail: string
  vchPassword: string
  vchFullName: string
  intStatusId: number
  vchStatusName: string
  dtmCreatedDate: string
  intCreatedUserId: number
  dtmUpdatedDate: string
  intUpdatedUserId: number
  listUserRole: UserRole[]
  id: number
  bitIsAccountManager: number
}

export interface UserRole {
  userRoleId: number
  intUserId: number
  vchFullNameUser: string
  sinRoleId: number
  vchNameRole: string
  intCustomerId: number
  intParentId: number
  vchNameCustomer: string
  vchNameParent: string
  intStatusId: number
  intCreatedUserId: number
  dtmCreatedDate: string
  intUpdatedUserId: number
  dtmUpdatedDate: string
  id: number
}

export interface UserType {
  id: number
  isActive: boolean
  lastModifiedDate: string
  description: string
}

export const createUser = (user: UserPost): Promise<void> => {
  return postAsync(EndpointUser, user)
}

export const createUserAdmin = (user: UserPost): Promise<void> => {
  return postAsyncAdmin(EndpointUser, user)
}

export const getUsers = (ageSize = 10, currentPage = 1): Promise<{ list: UserGetModel[]; pagination: Pagination }> => {
  return getAsyncAdmin(Users(ageSize, currentPage))
}

export const getUsersByAdmin = (
  ageSize = 10,
  currentPage = 1
): Promise<{ list: UserGetModel[]; pagination: Pagination }> => {
  return getAsync(Users(ageSize, currentPage))
}

// export const getUsersCustomers = (ageSize = 10, currentPage = 1): Promise<{ list: UserGetModel[]; pagination: Pagination }> => {
//   return getAsync(UsersCustomers(ageSize, currentPage))
// }

export const getUser = (id: number): Promise<User> => {
  return getAsync(EndpointUser + '/' + id)
}

export const getUserAdmin = (id: number): Promise<User> => {
  return getAsyncAdmin(EndpointUser + '/' + id)
}

export const updateUser = (user: UserPut) => {
  return putAsync(EndpointUser, user)
}

export const updateUserAdmin = (user: UserPut) => {
  return putAsyncAdmin(EndpointUser, user)
}

export const deleteUser = (id: string) => {
  return deleteAsync(EndpointUser + '/' + id)
}

export const deleteUserAdmin = (id: string) => {
  return deleteAsyncAdmin(EndpointUser + '/' + id)
}

export const changePassword = (oldPassword: string, newPassword: string): Promise<void> => {
  return postAsync(ChangeUserPassword, {
    oldPassword: oldPassword,
    newPassword: newPassword
  })
}

export const getUserDetail = (): Promise<UserModel> => {
  return getAsync(GetDetail)
}
export const getPermisionList = (userId: number): Promise<SecurityObjectModel[]> => {
  return getAsync(PermissionList(userId))
}

export const updateUserDetail = (userData: any): Promise<UserModel> => {
  return putAsync(UpdateDetail, userData)
}

export const recoveryPassword = (email: string): Promise<void> => {
  return postAsync(RecoveryPassword, {
    email: email,
    ipAddress: '1'
  })
}

export const resetPassword = (codeVerification: string, token: string, password: string): Promise<any> => {
  return postAsync(UpdatePasswordByRecovery, { codeVerification, token, password })
}

export const getUserType = (): Promise<UserType[]> => {
  return getAsync(UserType)
}

export const updateUserActualPassword = (oldPassword: string, newPassword: string): Promise<void> => {
  return postAsync(ChangeActualPassword, {
    actualPassword: oldPassword,
    newPassword: newPassword
  })
}

export const updateUserInfo = (user: any) => {
  return putAsync(EndpointUser, user)
}

import { useEffect, useState } from 'react'
import { Autocomplete, FormControl, SelectProps, TextField } from '@mui/material'
import { getAllCarriers, getCarriersByCustomer, getCarriersByModuleId, getCarriersByShop } from 'src/@http/carriers'
import appStore from 'src/zustand/app'

interface SelectorProps extends SelectProps {
  title?: string
  onHandleSelectedOptions: (options: number[]) => void
  courierSelected?: number[] | null
  actualLevel?: 'ez' | 'customer' | 'client'
}

const SelectorCourierMultiple = (props: SelectorProps) => {
  const [options, setOptions] = useState<any[]>([])
  const [selected, setSelected] = useState<number[]>(props.courierSelected || [])

  const { paramsV2 } = appStore()
  const pathname = window.location.pathname

  // console.log('SelectorCourier: ', options)

  useEffect(() => {
    const fetch = async () => {
      let list = []

      if (pathname === '/client-labels/shipp/new' || pathname === '/client-shipp') {
        list = await getCarriersByShop().catch(() => {
          return []
        })
        console.log('Getting carriers by shop', list)
      } 
      
      else if (pathname.includes('/admin-label-server/account')) {
        list = await getAllCarriers(1).catch(() => {
          return []
        })
        console.log('Getting all carriers', list)
      } 
      
      else if( pathname.includes('/client-labels/account') || pathname.includes('/labels/account') || pathname.includes('/admin-label-server/account') ) {  
          list = await getCarriersByModuleId({
            CustomerId: paramsV2.CustomerId,
            moduleId: props.actualLevel || 'ez',
            ShopId: paramsV2.ShopId
          })
        } 
        
      else {
        list = await getCarriersByCustomer().catch(() => {
          return []
        })
        console.log('Getting carriers by customer', list)
      }
      setOptions(list)
    }
    fetch()
  }, [paramsV2.ShopId, paramsV2.CustomerId])

  return (
    <FormControl fullWidth>
      <Autocomplete
        multiple
        value={selected.map(id => options.find(opt => opt.intCarrierId === id) || null)}
        onChange={(e: any, newValues) => {
          const newValueIds = newValues.map((newValue: any) => newValue.intCarrierId)
          setSelected(newValueIds)
          props.onHandleSelectedOptions(newValueIds)
        }}
        options={options}
        getOptionLabel={opt => opt.vchName}
        renderInput={params => <TextField {...params} label={props.title} />}
      />
    </FormControl>
  )
}

export default SelectorCourierMultiple
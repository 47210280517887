import { deleteAsync, getAsync, postAsync, putAsync } from 'src/@http'
import { ParentModel } from 'src/types/models/ParentModel'

/* Endpoints definition */
const GetParent = '/v1.0/ControllerCustomer'
const ParentById = (parentId: number) => `${GetParent}/${parentId}`

export const getParents = (status = 1): Promise<ParentModel[]> => {
  return getAsync(GetParent + `?Status=${status}`)
}

export const getParentById = (parentId: number): Promise<ParentModel> => {
  return getAsync(ParentById(parentId))
}

export const addNewParent = (data: ParentModel): Promise<any> => {
  return postAsync(GetParent, data)
}

export const editParent = (data: ParentModel): Promise<any> => {
  return putAsync(GetParent, data)
}

export const deleteParent = (parentId: number): Promise<any> => {
  return deleteAsync(ParentById(parentId))
}

import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Card, CardActions, CardHeader, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { getCarrierRegionPostalCodes, putCarrierRegion } from "src/@http/carriers";
import { logger } from "src/utils/Logger";

interface LocationState {
  data: {
    intCarrierRegionId: number;
    intRegionId?: number;
    vchName?: string;
    lstPostCodes: Array<PostCode>;
  };
}

interface PostCode {
  id: number;
  isActive: boolean;
  vchRegionName: string;
  intStartZipCode: number;
  intEndZipCode: number;
  intStatusId: number;
}

const CarrierRegions: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { data } = location.state as LocationState || { data: null };

  const [regionRangeCP, setRegionRangeCP] = useState<PostCode[]>([]);

  useEffect(() => {
    if (!data || data.intCarrierRegionId === 0) {
      toast.error('Carrier Region not found', { duration: 4000 });
      navigate('/rate-sheets/carriers');
    } else {
      logger.log('CarrierRegions data: ', data);
      setRegionRangeCP(data.lstPostCodes || []);

      const fetchRegionPostalCodes = async () => {
        if (data.intRegionId) {
          const postalCodes = await getCarrierRegionPostalCodes(data.intRegionId);

          // logger.log('CarrierRegions postalCodes: ', postalCodes);
          setRegionRangeCP(postalCodes || []);
        }
      };
      fetchRegionPostalCodes();
    }
  }, [data, navigate]);

  const handleAddRange = () => {
    const newRange: PostCode = {
      id: 0,
      isActive: true,
      vchRegionName: '',
      intStartZipCode: 0,
      intEndZipCode: 0,
      intStatusId: 1
    };
    setRegionRangeCP([...regionRangeCP, newRange]);
  };

  const handleSave = async () => {
    const list = regionRangeCP.map((item) => {
      return {
        id: item.id,
        bigRegionPostCodeId: item.id,
        isActive: item.isActive,
        vchRegionName: item.vchRegionName,
        intStartZipCode: item.intStartZipCode,
        intEndZipCode: item.intEndZipCode,
        intStatusId: item.intStatusId
      }
    });
    logger.log('CarrierRegions list: ', list);
    await putCarrierRegion({ ...data, lstPostCodes: list });
  };

  const handleDeleteRegionRange = (index: number) => {
    const newRange = [...regionRangeCP];
    newRange[index].intStatusId = 2; // 2 es delete
    if (newRange[index].id !== 0)
      try {
        // await deleteRegionRange(newRange[index].intCarrierPackageTypeId)
      }
      catch (error: any) {
        toast.error(error.title, { duration: 4000 })
      }
    setRegionRangeCP(newRange);
  };

  const handleRangeChange = (index: number, field: keyof PostCode, value: string) => {
    const newRegionRangeCP = regionRangeCP.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setRegionRangeCP(newRegionRangeCP);
  };

  if (!data) {
    return null;
  }

  return (
    <Card>
      <CardHeader title={`Carrier Region: ${data.vchName || 'New Region'}`} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>ID</TableCell>
              <TableCell>Range</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {regionRangeCP.map((regionRange, index) => (
              (regionRange.intStatusId !== 2) && (
                <TableRow key={regionRange.id}>
                  <TableCell align='center'>{regionRange.id}</TableCell>
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item>
                        <TextField
                          type='number'
                          size="small"
                          label="Start"
                          value={regionRange.intStartZipCode}
                          onChange={(e) => handleRangeChange(index, 'intStartZipCode', e.target.value)}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">-</Typography>
                      </Grid>
                      <Grid item>
                        <TextField
                          type='number'
                          size="small"
                          label="End"
                          value={regionRange.intEndZipCode}
                          onChange={(e) => handleRangeChange(index, 'intEndZipCode', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDeleteRegionRange(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CardActions>
        <Button onClick={handleSave}>Save</Button>
        <Button sx={{ marginLeft: 'auto' }} onClick={handleAddRange}>Add Range</Button>
      </CardActions>
    </Card>
  );
};

export default CarrierRegions;

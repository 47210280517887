import { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import {
  Autocomplete,
  Button,
  Card,
  CardHeader,
  Grid,
  Tab,
  TextField,
  Typography
} from '@mui/material'
import DatePickerWrapper from 'src/@core/styles/libs/react-datepicker'
import { editServices, getServices } from 'src/@http/services'
import ModalZoneManagement from 'src/pages/components/services-editor/ModalZoneManagement'
import PackageTypes from 'src/pages/components/services-editor/PackageTypes'
import WeigthManagment from 'src/pages/components/services-editor/WeigthManagment'
import { ServicesCarrierModel } from 'src/types/models/ServicesCarrierModel'
import { WeigthModel, ZoneModel } from 'src/types/models/SheetModels'
import { logger } from 'src/utils/Logger'
import appStore from 'src/zustand/app'
import servicesStore from 'src/zustand/services'

const initialForm = {
  id: 0,
  vchSourceId: '',
  intServiceId: 0,
  vchName: '',
  vchShortName: null,
  intServiceClasificationId: 2,
  serviceClasificationName: null,
  intCarrierId: 0,
  carrierName: null,
  intStatusId: 1,
  vchStatusName: null,
  dtmCreatedDate: '',
  intCreatedUserId: 1,
  dtmUpdatedDate: '',
  intUpdatedUserId: 0,
  bitStatusDefault: 0,
  vchStatusColor: null,
  zoneListDtos: [],
  weigthListDtos: [],
  packageTypeListDtos: [],
  vchCarrierShipCode: ''
}

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const EditServices = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const { serviceEditorCarrierId } = appStore(state => ({
    serviceEditorCarrierId: state.serviceEditorCarrierId
  }))
  const { zoneDtos, weightDtos, reset, setResetZonesAndWeigths, resetZonesAndWeigths } = servicesStore()
  const query = useQuery()
  const edit: boolean = query.get('edit') === 'true' ? true : false
  const [form, setForm] = useState<ServicesCarrierModel>(initialForm)
  const [isSaveClicked, setIsSaveClicked] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [resetCalled, setResetCalled] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [filledOnce, setFilledOnce] = useState(false)
  const [value, setValue] = useState<string>('1')
  const [pkgTypes, setPkgTypes] = useState<any[]>([])
  const [service, setService] = useState<ServicesCarrierModel>()

  const opt = ['Internacional', 'Domestic']

  const fetchZoneData = async () => {
    try {
      if (Array.isArray(zoneDtos)) {
        form.zoneListDtos = zoneDtos as unknown as ZoneModel[]
      }
    } catch (error) {
      console.error('Error al obtener datos de zona:', error)
    }
  }

  useEffect(() => {
    const getServicesData = async () => {
      try {
        const a = (await getServices(serviceEditorCarrierId)).filter((service: ServicesCarrierModel) => service.intServiceId === state.intServiceId)
        setService(a[0])
        setForm(
          {
            ...form,
            vchName: a[0].vchName,
            vchShortName: a[0].vchShortName,
            vchSourceId: a[0].vchSourceId,
            intServiceClasificationId: a[0].intServiceClasificationId,
            vchCarrierShipCode: a[0].vchCarrierShipCode
          }
        )
      }
      catch (error) {
        console.error('Error al obtener datos de servicios:', error)
      }
    }
    getServicesData()
  }, [serviceEditorCarrierId])

  const [selectedValue, setSelectedValue] = useState<string | null>(state.intServiceClasificationId === 3 ? opt[0] : opt[1])

  const fetchWeightData = async () => {
    try {
      if (Array.isArray(weightDtos)) {
        form.weigthListDtos = weightDtos as unknown as WeigthModel[]
      }
    } catch (error) {
      console.error('Error al obtener datos de peso:', error)
    }
  }

  if (!resetCalled) {
    reset()
    setResetCalled(true)
  }

  useEffect(() => {
    if (state.intServiceId && state.intServiceId !== 0) {
      initialForm.intServiceId = state.intServiceId
    }
  }, [state.intServiceId])


  useEffect(() => {
    console.log('state', state)
    fetchZoneData()
    fetchWeightData()
    if (!filledOnce && state.from !== 'ratesSheet') {
      setFilledOnce(true)
    } else if (!filledOnce && state.from === 'ratesSheet') {
      setForm(state.dataService)
      setFilledOnce(true)
    }
  }, [zoneDtos, weightDtos])

  useEffect(() => {
    form.intServiceClasificationId === 3;
  }, [])

  const navigate = useNavigate()

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const handleEdit = async () => {
    try {
      setIsSaving(true)
      const send = {
        ...form,
        intCarrierId: serviceEditorCarrierId,
        zoneListDtos: zoneDtos,
        weigthListDtos: weightDtos,
        packageTypeListDtos: pkgTypes,
        intServiceClasificationId: form.intServiceClasificationId,
      }
      // logger.log('form', send)
      await editServices(send)
      setShowSuccessAlert(true)
      toast.success('Successfully save!', { duration: 4000 })
      setResetZonesAndWeigths(!resetZonesAndWeigths)
    } catch (error) {
      toast.error(`Error saving data, ${error}`, { duration: 4000 })
    } finally {
      setIsSaving(false)
      setIsSaveClicked(true)
    }
  }

  const handleCheckboxChange = (type: any[]) => {
    setPkgTypes(type)
  }


  return (
    <Card>
      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Grid container direction='column'>
                <CardHeader title={t('EDIT_SERVICE')} subheader={'Service ID: ' + state.intServiceId || '-'} sx={{ pb: 0 }} />
                <Typography variant='subtitle2' pl={5}>
                  Courier: {state?.data.vchName}
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid item sx={{ marginRight: '10px' }}>
              <Button
                style={{ color: 'white' }}
                variant='contained'
                onClick={() =>
                  navigate(`/RegionManager`)
                }
              >
                {t('REGION_MANAGER')}{' '}
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12} m={3}>
          <TabContext value={value}>
            <TabList onChange={handleChange} aria-label='simple tabs example'>
              <Tab value='1' label='Details' />
              <Tab value='2' label='Weigth Management' />
              <Tab value='3' label='Zone Management' />
              <Tab value='4' label='Package Types' />
            </TabList>
            <TabPanel value='1'>
              <Grid container>
                {edit && (
                  <Grid item xs={12} md={4} p={2}>
                    <TextField fullWidth value={form.intCarrierId} label='Product Id' disabled />
                  </Grid>
                )}
                <Grid item xs={12} md={4} p={2}>
                  <TextField
                    fullWidth
                    value={form.vchName}
                    label='Name'
                    onChange={e => setForm({ ...form, vchName: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} md={4} p={2}>
                  <TextField
                    fullWidth
                    value={form.vchShortName}
                    label='Short Name'
                    id='short_name_service'
                    onChange={e => setForm({ ...form, vchShortName: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} md={4} p={2}>
                  <Autocomplete
                    options={opt}
                    value={selectedValue}
                    onChange={(event, newValue) => {
                      setSelectedValue(newValue);
                      const selectedId = newValue === 'Internacional' ? 3 : 2;
                      setForm({ ...form, intServiceClasificationId: selectedId });
                    }}
                    renderInput={params => <TextField {...params} label='Service Clasification' />}
                    defaultValue={selectedValue}
                  />
                </Grid>
                <Grid item xs={12} md={4} p={2}>
                  <TextField
                    fullWidth
                    value={form.vchSourceId}
                    label='Source Id'
                    onChange={e => setForm({ ...form, vchSourceId: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} md={4} p={2}>
                  <TextField
                    fullWidth
                    value={form.vchCarrierShipCode}
                    label='Carrier Ship Code'
                    onChange={e => setForm({ ...form, vchCarrierShipCode: e.target.value })}
                  />
                </Grid>

                {edit && (
                  <>
                    <Grid item xs={12} md={4} p={2}>
                      <DatePickerWrapper sx={{ width: '100%' }}>
                        <DatePicker
                          selected={null}
                          id='basic-input'
                          dateFormat='yyyy-MM-dd'
                          disabled
                          onChange={(date: Date) => logger.log('date', date)}
                          customInput={<TextField fullWidth label='Created Date' />}
                        />
                      </DatePickerWrapper>
                    </Grid>
                    <Grid item xs={12} md={4} p={2}>
                      <DatePickerWrapper sx={{ width: '100%' }}>
                        <DatePicker
                          selected={null}
                          id='basic-input'
                          dateFormat='yyyy-MM-dd'
                          disabled
                          onChange={(date: Date) => logger.log('date', date)}
                          customInput={<TextField fullWidth label='Updated Date' />}
                        />
                      </DatePickerWrapper>
                    </Grid>
                  </>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value='2'>
              <WeigthManagment serviceId={Number(state.intServiceId || 0)} />
            </TabPanel>
            <TabPanel value='3'>
              <ModalZoneManagement serviceId={Number(state.intServiceId || 0)} />
            </TabPanel>
          </TabContext>
          <TabContext value={value}>
            <TabPanel value='4'>
              <PackageTypes serviceEditorCarrierId={serviceEditorCarrierId} form={form} handleCheckboxChange={handleCheckboxChange} />
            </TabPanel>
          </TabContext>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={3}>
              <Button
                variant='contained'
                style={{ color: 'white' }}
                sx={{ width: '100%' }}
                onClick={() => window.history.back()}
              >
                <Icon icon='formkit:arrowleft' fontSize={12} /> {t('BACK')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant='contained' sx={{ width: '100%' }} style={{ color: 'white' }} onClick={() => handleEdit()} disabled={isSaving}>
                {isSaving ? 'Saving...' : t('SAVE')}
              </Button>
            </Grid>

            <Grid item xs={3}>
              <Button
                variant='contained'
                style={{ color: 'white' }}
                sx={{ width: '100%' }}
                onClick={() =>
                  navigate(`/carriers/base-rate/${serviceEditorCarrierId}`, {
                    state: { data: 'vchName' in state.data ? state.data : state.data.vchName, type: state.type }
                  })
                }
                disabled={!isSaveClicked}
              >
                {'Go to Rate Sheets'} <Icon icon='formkit:arrowright' fontSize={12} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default EditServices

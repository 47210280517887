import React, { useState } from 'react'

type ColorSelected = Record<string, string | undefined>

interface ColorPickerProps extends React.HTMLAttributes<HTMLInputElement> {
  colorNames: string[]
  onChangeColor?: (selectedColors: ColorSelected) => void
  orientation?: 'vertical' | 'horizontal'
  colorsSelected?: ColorSelected | null
  disabled?: boolean
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  colorNames,
  onChangeColor,
  orientation,
  colorsSelected,
  disabled,
  ...inputProps
}) => {
  const [selectedColors, setSelectedColors] = useState<ColorSelected>(colorsSelected || {})
  const inputWidth = !orientation || orientation === 'horizontal' ? `${100 / colorNames.length}%` : 'auto'

  const handleColorChange = (colorName: string, event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedColors(prevColors => ({
      ...prevColors,
      [colorName]: event.target.value
    }))
    onChangeColor &&
      onChangeColor({
        ...selectedColors,
        [colorName]: event.target.value
      })
  }

  return (
    <div style={{ display: 'flex', flexDirection: orientation === 'vertical' ? 'column' : 'row', padding: '.5rem 0' }}>
      {colorNames.map((colorName, i) => (
        <div
        key={colorName}
        style={{ width: inputWidth, marginRight: orientation === 'horizontal' ? '10px' : '0', marginBottom: '10px' }}
        >
          <input
            type='color'
            id={`${colorName.toLowerCase()}-${i + 1}`}
            value={selectedColors[colorName] || ''}
            onChange={event => handleColorChange(colorName, event)}
            disabled={disabled}
            {...inputProps}
          />
          <label style={{ margin: '0 1rem' }} htmlFor={`${colorName.toLowerCase()}-${i + 1}`}>{`${colorName}`}</label>
        </div>
      ))}
    </div>
  )
}

export default ColorPicker

// ** React Imports
import { useState } from 'react'
// ** MUI Imports
import Grid from '@mui/material/Grid'
// ** Third Party Components
// ** Next Import
// ** Types
import { SingleInvoiceType } from 'src/types/apps/invoiceTypes'
import appStore from 'src/zustand/app'

import EditOrderActions from '../editActions'
// ** Demo Components Imports
import EditOrderCard from '../editCard'

const OrderEdit = () => {
  // ** State
  const { orderId } = appStore(state => ({
    orderId: state.orderId
  }))
  const [error, setError] = useState<boolean>(false)
  const [data, setData] = useState<null | SingleInvoiceType>(null)
  const [addPaymentOpen, setAddPaymentOpen] = useState<boolean>(false)
  const [sendInvoiceOpen, setSendInvoiceOpen] = useState<boolean>(false)

  const toggleSendInvoiceDrawer = () => setSendInvoiceOpen(!sendInvoiceOpen)
  const toggleAddPaymentDrawer = () => setAddPaymentOpen(!addPaymentOpen)

  // useEffect(() => {
  //   axios
  //     .get('/apps/invoice/single-invoice', { params: { id } })
  //     .then(res => {
  //       setData(res.data)
  //       setError(false)
  //     })
  //     .catch(() => {
  //       setData(null)
  //       setError(true)
  //     })
  // }, [orderId])

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xl={9} md={8} xs={12}>
          <EditOrderCard />
        </Grid>
        <Grid item xl={3} md={4} xs={12}>
          <EditOrderActions
            id={orderId?.toString()}
            toggleSendInvoiceDrawer={toggleSendInvoiceDrawer}
            toggleAddPaymentDrawer={toggleAddPaymentDrawer}
          />
        </Grid>
      </Grid>
    </>
  )
}
export default OrderEdit

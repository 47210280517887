import { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { getCarriers } from 'src/@http/carriers'
import { CarrierModel } from 'src/types/models/CarrierModel'
import { logger } from 'src/utils/Logger'
import reportsStore from 'src/zustand/reports'

interface AutocompletecarrierProps {
  onSelectCarrier: (carrierId: string) => void
}

const CarrierSelectorReport = ({ onSelectCarrier }: AutocompletecarrierProps) => {
  const [carrierList, setCarrierList] = useState<CarrierModel[]>([])
  const [value, setValue] = useState('')
  const { setCarrierName } = reportsStore()

  const handleChange = (event: any, value: string | null) => {
    logger.log('Value en Hijo:', value)
    logger.log('Event en Hijo:', event.target.value)

    setCarrierName(value || '')

    onSelectCarrier(value || '')
    setValue(value || '')
  }

  useEffect(() => {
    getCarriers(999, 1)
      .then(data => {
        setCarrierList(data.list)
      })
      .catch(error => {
        console.error('Error fetching carrier data:', error)
      })
  }, [])

  return (
    <Autocomplete
      fullWidth
      onChange={handleChange}
      value={value}
      options={carrierList.map(carrier => carrier.vchName)}
      id='autocomplete-disableCloseOnSelect'
      renderInput={params => <TextField {...params} label='Courier' />}
    />
  )
}

export default CarrierSelectorReport

import { ThemeOptions } from '@mui/material/styles'
import { Settings } from 'src/@core/context/settingsContext'
import { shadeColor } from 'src/utils/misc'

const UserThemeOptions = (settings: Settings): ThemeOptions => {
  return {
    palette: {
      primary: {
        light: settings.vchPrimaryColor,
        main: settings.vchPrimaryColor,
        dark: settings.vchPrimaryColor,
        contrastText: '#000'
      },
      secondary: {
        light: settings.vchSecondaryColor,
        main: settings.vchSecondaryColor,
        dark: '#1f1d30',
        contrastText: '#FFF'
      },
      customColors: {
        primaryGradient: shadeColor(settings.vchPrimaryColor, -10),
        terciary: shadeColor(settings.vchPrimaryColor, -20),
        changesBg: shadeColor(settings.vchPrimaryColor, 40),
        main: settings.vchSecondaryColor
      }
    }
  }
}

export default UserThemeOptions

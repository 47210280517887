import { useState } from 'react'
import { Icon } from '@iconify/react'
import { Box, Button, Grid, IconButton, TextField, Typography } from '@mui/material'

interface PropsItemBlock {
  product: { id: number }
  removeProduct: (id: number) => void
  showBoxDetails: boolean
}

const ItemBlock = ({ product, removeProduct, showBoxDetails }: PropsItemBlock) => {
  const [listProductDetails, setListProductDetails] = useState([{ id: 1 }])

  const addListDetail = (id: number) => {
    setListProductDetails([...listProductDetails, { id }])
  }

  const removeListDetails = (id: number) => {
    const aux = listProductDetails.filter(detail => detail.id !== id)
    setListProductDetails(aux)
  }

  const handleClickItemBlock = (e: any) => {
    removeProduct(product.id)
  }

  return (
    <Grid
      container
      columnGap={1}
      justifyContent='space-between'
      alignContent='center'
      sx={{ my: 4, p: 3, backgroundColor: '#33313201', borderRadius: 2 }}
    >
      <Grid item xs={12} md={2}>
        <Typography variant='body2'>Product Part #:</Typography>
        <TextField fullWidth defaultValue='' size='small' />
      </Grid>
      <Grid item xs={12} md={1}>
        <Typography variant='body2'>Qty</Typography>
        <TextField fullWidth defaultValue='' size='small' type='number' InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item xs={12} md={5}>
        <Typography variant='body2'>Description</Typography>
        <TextField fullWidth defaultValue='' size='small' />
      </Grid>
      <Grid item xs={12} md={1}>
        <Typography variant='body2'>Lot Id</Typography>
        <TextField fullWidth defaultValue='' size='small' />
      </Grid>
      <Grid item xs={12} md={1}>
        <Typography variant='body2'>Price</Typography>
        <TextField
          fullWidth
          size='small'
          type='number'
          InputLabelProps={{ shrink: true }}
          inputProps={{ inputMode: 'numeric', pattern: '/^(d+)(.d+)?$/' }}
        />
      </Grid>
      <Grid item md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <IconButton color='error' size='small' onClick={handleClickItemBlock}>
          <Icon icon='material-symbols:close' />
        </IconButton>
      </Grid>
      {showBoxDetails ? (
        <Box sx={{ backgroundColor: '#12121210', mt: 3, p: 3, width: '100%' }}>
          {listProductDetails.map((detail, index) => (
            <Grid key={index} item display='flex' flexDirection='row' alignItems='center' my={3}>
              <TextField size='small' />
              <Icon icon='mdi:equal' />
              <TextField size='small' />
              <IconButton color='error' size='small' onClick={e => removeListDetails(detail.id)}>
                <Icon icon='iconamoon:close' />
              </IconButton>
            </Grid>
          ))}
          <Box marginTop={2}>
            <Button
              variant='contained'
              size='small'
              color='secondary'
              onClick={e => addListDetail(listProductDetails.length + 1)}
            >
              ADD DETAIL
            </Button>
          </Box>
        </Box>
      ) : null}
    </Grid>
  )
}

const ListDinamicProduct = () => {
  const [listProducts, setListProduct] = useState([{ id: 1 }])
  const [showBoxDetails, setShowBoxDetails] = useState(false)

  const addToList = (id: number) => {
    setListProduct([...listProducts, { id }])
  }

  const handleBoxListDetails = () => {
    setShowBoxDetails(true)
  }

  const handleRemove = (id: number) => {
    const aux = listProducts.filter(prod => prod.id !== id)
    setListProduct(aux)
  }

  return (
    <Grid container columnGap={1} rowGap={3}>
      <Grid item xs={12} columnGap={3}>
        <Button color='secondary' variant='contained' onClick={e => addToList(listProducts.length + 1)}>
          ADD PART
        </Button>
        <Button color='secondary' variant='contained' onClick={handleBoxListDetails} sx={{ ml: 2 }}>
          PRODUCT DETAIL
        </Button>
      </Grid>
      <Box sx={{ width: '100%' }}>
        {listProducts.map((product, index) => (
          <ItemBlock key={index} product={product} removeProduct={handleRemove} showBoxDetails={showBoxDetails} />
        ))}
      </Box>
    </Grid>
  )
}

export default ListDinamicProduct

import { useNavigate } from 'react-router-dom'
// import { Icon } from '@iconify/react'
import { Grid } from '@mui/material'

import CarrierInvoiceTab from '../components/carrier-invoice-tabs'
import SummaryWidget from '../components/SummaryWidget'

const Invoice = () => {

  const navigate = useNavigate()

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <SummaryWidget></SummaryWidget>
        <Grid item xs={12}>
          <CarrierInvoiceTab />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Invoice

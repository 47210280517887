import { Box } from '@mui/material'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid'

const CustomToolbarAdminGrid = (props: any) => {
  return (
    <GridToolbarContainer {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </Box>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  )
}

export default CustomToolbarAdminGrid

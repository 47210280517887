import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { CountryGet, getCountry } from 'src/@http/CountryCode'
// import { UserGetModel /*, UserModel */ } from 'src/types/models/userModels/'
// import { logger } from 'src/utils/Logger'
// import appStore from 'src/zustand/app'

interface AutocompleteCountryProps {
  onSelectCountry: (country: any) => void
  disabled: boolean
  defaultValue: number
  sx?: any
}

const valueInitial: CountryGet = {
  vchShortName: '',
  vchName: '',
  id: 0,
}

const AutocompleteCountryCode = ({ onSelectCountry, defaultValue, disabled, sx }: AutocompleteCountryProps) => {
  // const [customerList, setCustomerList] = useState<CustomerModel[]>([])
  const [value, setValue] = useState<CountryGet>(valueInitial)
  const [countries, setCountries] = useState<CountryGet[]>([])
  const { t } = useTranslation()
  const handleChange = (event: any, value: any | null) => {
    setValue(value || '')
    onSelectCountry(value || '')
  }

  useEffect(() => {
    getCountry()
      .then(res => {
        setCountries(res)
      })
      .catch(err => {
        setCountries([])
      })
  }, [])

  useEffect(() => {
    handleSelectCountry()
  }, [defaultValue, countries])

  const handleSelectCountry = () => {
    if (defaultValue !== 0 && countries.length !== 0) {
      const defaultCountry = countries.find(item => item.id === defaultValue)
      if (defaultCountry) {
        setValue(defaultCountry)
      }
    }
  }

  return (
    <Autocomplete
      fullWidth
      value={value}
      disabled={disabled}
      onChange={handleChange}
      options={countries}
      getOptionLabel={option => option.vchName}
      
      id='autocomplete-disableCloseOnSelect'
      renderInput={params => <TextField {...params} label={t('COUNTRY')} />}
      sx={sx}
    />
  )
}

export default AutocompleteCountryCode

import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SxProps, Theme } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import Icon from 'src/@core/components/icon'
import appStore from 'src/zustand/app'

interface State {
  searchQuery: string
  showPassword: boolean
}

const TextFieldInputAdornment = ({ sx }: { sx?: SxProps<Theme> }) => {
  const { t } = useTranslation()
  const [values, setValues] = useState<State>({
    searchQuery: '',
    showPassword: true
  })
  const { setOrderFilterOrderId } = appStore(state => ({
    setOrderFilterOrderId: state.setOrderFilterOrderId
  }))

  useEffect(() => {
    setOrderFilterOrderId(null)
  }, [setOrderFilterOrderId])

  const handleChange = (prop: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, '')
    setValues({ ...values, [prop]: numericValue })
  }

  const handleClickSearch = () => {
    setOrderFilterOrderId(Number(values.searchQuery))
  }

  return (
    <FormControl fullWidth sx={sx}>
      <OutlinedInput
        placeholder={t('SEARCH_ORDER').toString()}
        value={values.searchQuery}
        id='search-adornment'
        onChange={handleChange('searchQuery')}
        type={'text'}
        size='small'
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              edge='end'
              onClick={handleClickSearch}
              onMouseDown={e => e.preventDefault()}
              aria-label='toggle password visibility'
            >
              <Icon fontSize={20} icon={'mdi:search'} />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  )
}

export default TextFieldInputAdornment

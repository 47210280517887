import Grid from '@mui/material/Grid'
import { AccountCarrierModel } from 'src/types/models/AccountCarriersModels'
import { logger } from 'src/utils/Logger'
import profileStore from 'src/zustand/profile'

import CardStatisticsHorizontal from './CardLabelAccount'

interface Props {
  data: AccountCarrierModel[]
  accountSelected: (carrier: AccountCarrierModel) => void
  level: 'customer' | 'client'
  setAmount: (amount: number) => void
}

const CardStatsHorizontal = (props: Props) => {
  const userType: number | undefined = profileStore.getState().profile?.tinUserType
  logger.log('props.data', props.data)
  if (props.data) {
    return (
      <Grid container spacing={6}>
        {props.data.map((item: any, index: number) => {
          if (userType !== 1 && item.bitEnabled == 0) {
            return null
          } else {
            return (
              <Grid item xs={12} md={4} sm={6} key={index}>
                <CardStatisticsHorizontal
                  onClick={(data: any) => props.accountSelected(data)}
                  item={item}
                  level={props.level}
                  setAmount={props.setAmount}
                />
              </Grid>
            )
          }
        })}
      </Grid>
    )
  } else {
    return null
  }
}

export default CardStatsHorizontal

import { useEffect, useState } from 'react'
import { DataGrid, GridColumns } from '@mui/x-data-grid'

// import CustomToolbarAdminGrid from './CustomToolbarAdminGrid'

interface Props {
  columns: GridColumns
  service: (props: ServiceDataGridProps) => Promise<Response>
  store?: {
    getRows: any
    setRows: any
  }
  refreshDate?: Date
  checkboxSelection?: boolean,
  rowHeight?: number
  sx?: any
}
export interface ServiceDataGridProps {
  pageSize: number
  currectPage: number
}

interface Response {
  list: any[]
  pagination: {
    numberOfPages: number
    actualPage: number
    numberOfRecords: number
    pageSize: number
  }
}

const ServerDataGrid = ({ columns, service, store, refreshDate, checkboxSelection, sx, rowHeight }: Props) => {
  const [dataPaginationGrid, setDataPaginationGrid] = useState({
    loading: true,
    rows: [],
    rowCount: 0,
    rowsPerPageOptions: [5, 10, 15],
    pageSize: 15,
    page: 1
  })

  const updateDataPaginationGrid = (k: any, v: any) => setDataPaginationGrid(prev => ({ ...prev, [k]: v }))

  useEffect(() => {
    const fetch = async () => {
      updateDataPaginationGrid('loading', true)
      try {
        const response = await service({ currectPage: dataPaginationGrid.page, pageSize: dataPaginationGrid.pageSize })

        if (response) {
          updateDataPaginationGrid('rows', response.list)
          updateDataPaginationGrid('rowCount', response.pagination.numberOfRecords)
          if (store) {
            store.setRows(response.list)
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        updateDataPaginationGrid('loading', false)
      }
    }
    fetch()
  }, [dataPaginationGrid.page, dataPaginationGrid.pageSize, refreshDate])

  return (
    <DataGrid
      sx={{ my: 2, ...sx }}
      paginationMode='server'
      autoHeight
      pagination
      columns={columns}
      checkboxSelection={checkboxSelection === undefined ? true : checkboxSelection}
      pageSize={dataPaginationGrid.pageSize}
      loading={dataPaginationGrid.loading}
      rowCount={dataPaginationGrid.rowCount}
      page={dataPaginationGrid.page - 1}
      rows={store ? store.getRows : dataPaginationGrid.rows}
      disableSelectionOnClick
      rowsPerPageOptions={dataPaginationGrid.rowsPerPageOptions}
      onPageChange={(page: any) => {
        updateDataPaginationGrid('page', page + 1)
      }}
      onPageSizeChange={(pageSize: any) => {
        updateDataPaginationGrid('page', 1)
        updateDataPaginationGrid('pageSize', pageSize)
      }}
    />
  )
}

ServerDataGrid.defaultProps = {
  refreshDate: new Date()
}

export default ServerDataGrid

// ** Next Import
import { useTranslation } from 'react-i18next'
import { Link } from '@mui/material'
import Button from '@mui/material/Button'
// ** MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
// ** Icon Imports
import Icon from 'src/@core/components/icon'
import WrapperSecurity from 'src/pages/components/generic/WrapperSeurityObject'

interface Props {
  id: string | undefined
  toggleAddPaymentDrawer: () => void
  toggleSendInvoiceDrawer: () => void
}

const PreviewActions = ({ id, toggleSendInvoiceDrawer, toggleAddPaymentDrawer }: Props) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardContent>
        <Button
          fullWidth
          sx={{ mb: 3.5 }}
          variant='contained'
          onClick={toggleSendInvoiceDrawer}
          startIcon={<Icon icon='mdi:send-outline' color={'white'} />}
        >
          <Typography color={'white'}>{t('DOWNLOAD')}</Typography>
        </Button>
        {/* <Button fullWidth sx={{ mb: 3.5 }} color='secondary' variant='outlined'>
          Download
        </Button> */}
        <Button
          fullWidth
          target='_blank'
          sx={{ mb: 3.5 }}
          component={Link}
          color='secondary'
          variant='outlined'
          href={`/apps/invoice/print/${id}`}
        >
          {t('PRINT')}
        </Button>
        <Button
          fullWidth
          sx={{ mb: 3.5 }}
          component={Link}
          color='secondary'
          variant='outlined'
          href={`/order-editor/edit`}
        >
          {t('EDIT_ORDER')}
        </Button>
        <WrapperSecurity securityKey='button_create_label_op'>
          <Button 
            fullWidth
            sx={{ mb: 3.5 }}
            component={Link}
            color='secondary'
            variant='outlined'
            href={`/client-labels/shipp/new?orderId=${id}`}
          >
            {t('CREATE_LABEL')}
          </Button>
        </WrapperSecurity>
        <Button
          fullWidth
          color='success'
          variant='contained'
          onClick={toggleAddPaymentDrawer}
          //startIcon={<Icon icon='mdi:currency-usd' />}
        >
          {t('INVOICE')}
        </Button>
      </CardContent>
    </Card>
  )
}

export default PreviewActions

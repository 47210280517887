import { MouseEvent, useState } from 'react'
import { Icon } from '@iconify/react'
import { Box, CardContent, Fab, Typography, useMediaQuery } from '@mui/material'
import Card from '@mui/material/Card'
import { useTheme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import DateFilter, { IDate } from './DateFilter'

interface PropsFilterPanel {
  handleDates: (dates: IDate) => any
  handleConfirm: (confirmed: boolean | null) => any
  sx?: SxProps
}

interface BtnFilterProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
  textBtn: string
  mdAndDown: boolean
  disabled: boolean
}

const BtnConfirmFilter = ({ onClick, textBtn, mdAndDown, disabled }: BtnFilterProps) => {
  return (
    <Fab
      key='apply-filter'
      onClick={onClick}
      color='secondary'
      aria-label='filter'
      variant='extended'
      size='small'
      disabled={disabled}
      sx={{ margin: '1rem 0', padding: '0 1rem' }}
    >
      {textBtn}
      {!mdAndDown && <Icon icon='mdi:filter-outline' />}
    </Fab>
  )
}

const CustomFilterPanel = ({ handleDates, handleConfirm, sx }: PropsFilterPanel) => {
  const [disableBtn, setDisableBtn] = useState<boolean>(false)

  //** Style Configuration
  const theme = useTheme()
  const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const styleBoxItem = {
    display: 'flex',
    flexDirection: matchesDownMd ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 .5rem'
  }
  const styleTitleFilters = {
    mb: matchesDownMd ? 4 : 0,
    mr: !matchesDownMd ? 4 : 0
  }

  const handleConfirmFilter = (event?: MouseEvent<HTMLButtonElement>) => {
    if (event) {
      handleConfirm(null)
    }
  }

  function setDateSelected(dates: IDate) {
    handleDates(dates)
    setDisableBtn(true)
  }

  return (
    <Card color='primary' sx={{ ...{ my: matchesDownMd ? 5 : 1 }, ...sx }}>
      <CardContent sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row-reverse' } }}>
        <Box sx={styleBoxItem}>
          <BtnConfirmFilter
            disabled={!disableBtn}
            onClick={handleConfirmFilter}
            textBtn={'Apply filter'}
            mdAndDown={matchesDownMd}
          />
        </Box>
        <Box sx={styleBoxItem}>
          <Typography variant='body1' sx={styleTitleFilters}>
            By date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateFilter onData={setDateSelected} width={matchesDownMd ? '100%' : '400px'} />
          </LocalizationProvider>
        </Box>
      </CardContent>
    </Card>
  )
}

export default CustomFilterPanel

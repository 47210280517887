import { deleteAsync, getAsync, postAsync, putAsync } from 'src/@http'
import { RolModel } from 'src/types/models/RolModel'

/* Endpoints definition */
const GetRoles = '/v1.0/ControllerRole'
const GetCacheRoles = '/v1.0/ControllerCacheRole/cacheRoles'

const RoleById = (roleId: number) => `${GetRoles}/${roleId}`

export const getRoles = (status = 1): Promise<RolModel[]> => {
  return getAsync(GetRoles + `?Status=${status}`)
}

export const getRolesByModule = (status = 1, moduleId: number): Promise<RolModel[]> => {
  return getAsync(GetRoles + `/byModule?moduleId=${moduleId}&Status=${status}`)
}

export const getCacheRoles = (status = 1): Promise<RolModel[]> => {
  return getAsync(GetCacheRoles + `/${status}`)
}

export const addNewRole = (data: RolModel): Promise<any> => {
  return postAsync(GetRoles, data)
}

export const editRole = (data: RolModel): Promise<any> => {
  return putAsync(GetRoles, data)
}

export const deleteRole = (roleId: number): Promise<any> => {
  return deleteAsync(RoleById(roleId))
}

// ** React Imports
import type { ReactNode } from 'react'
// import { logger } from 'src/utils/Logger'
import profileStore from 'src/zustand/profile'

interface Props {
  securityKey: string
  children: ReactNode
}

const WrapperSecurity = (props: Props) => {
  const { securityFormatted } = profileStore.getState()
  const { children, securityKey } = props

  const itemSecurity = securityFormatted.find(so => securityKey === so.name)

  // logger.log(itemSecurity, securityKey)
  if (!itemSecurity) return <> {children} </>
  if (itemSecurity.statusActive === false) return <></>
  if (itemSecurity.statusActive) return <> {children} </>
  else return <></>
}

export default WrapperSecurity

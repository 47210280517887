import { Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import CustomAvatar from 'src/@core/components/mui/avatar'

const colors = ['#6E68F2', '#70B2FA', '#DD4233', '#ECCF25']

const rows = [
  {
    id: 1,
    image: 'https://i.pravatar.cc/80',
    text: 'LUC-Luc Hackman',
    date: '23 Sep 2019',
    number1: 252,
    amount: '$130.00'
  },
  {
    id: 2,
    image: 'https://i.pravatar.cc/80',
    text: 'KIND-Kindred',
    date: '22 Sep 2019',
    number1: 564,
    amount: '$367.00'
  },
  {
    id: 3,
    image: 'https://i.pravatar.cc/80',
    text: 'Hug- Hug and Grace',
    date: '22 Oct 2019',
    number1: 69,
    amount: '$29.99'
  },
  {
    id: 4,
    image: 'https://i.pravatar.cc/80',
    text: 'MOM-Mommys Bliss',
    date: '16 May 2019',
    number1: 368,
    amount: '$1240.29'
  }
]

const columns = [
  {
    field: 'image',
    headerName: '',
    renderCell: (params: any) => (
      <CustomAvatar src={params.value} alt='avatar' sx={{ width: 35, height: 35, borderRadius: '50%' }} />
    )
  },
  {
    field: 'text',
    headerName: '',
    width: 200
  },
  { field: 'date', headerName: '', width: 280 },
  { field: 'number1', headerName: '', width: 300 },
  {
    field: 'amount',
    headerName: '',
    renderCell: (params: any) => (
      <Typography variant='h6' color={colors[params.id - 1]}>
        {params.value}
      </Typography>
    ),
    width: 180
  }
]

const CustomerTopTable = () => {
  return (
    <>
      <DataGrid hideFooter autoHeight rows={rows} columns={columns} getRowHeight={() => 55} />
    </>
  )
}

export default CustomerTopTable

import { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { getParents } from 'src/@http/customers'
import { ParentModel } from 'src/types/models/ParentModel'
import { logger } from 'src/utils/Logger'
import invoiceStore from 'src/zustand/invoice'

interface AutocompleteCustomerProps {
  onSelectCustomer: (customerId: string) => void
}

const CustomerSelector = ({ onSelectCustomer }: AutocompleteCustomerProps) => {
  const [customerList, setCustomerList] = useState<ParentModel[]>([])
  const [value, setValue] = useState('')
  const { invoiceFilter, setCustomerName, setIsProp } = invoiceStore()

  const handleChange = (event: any, value: string | null) => {
    logger.log('Value en Hijo:', value)
    logger.log('Event en Hijo:', event.target.value)

    onSelectCustomer(value || '')
    setCustomerName(value || '')
    setValue(value || '')
  }

  useEffect(() => {
    getParents()
      .then(data => {
        setCustomerList(data)
      })
      .catch(error => {
        console.error('Error fetching customer data:', error)
      })
  }, [])

  useEffect(() => {
    if (invoiceFilter.isProp) {
      setValue(invoiceFilter.customerName ? invoiceFilter.customerName : '')
      setIsProp(false)
    }
  }, [invoiceFilter.isProp])

  return (
    <Autocomplete
      fullWidth
      size='small'
      onChange={handleChange}
      value={value}
      options={customerList.map(customer => customer.vchName)}
      id='autocomplete-disableCloseOnSelect'
      renderInput={params => <TextField {...params} label='Customer' />}
    />
  )
}

export default CustomerSelector

import { FormEvent, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { setServerLevelInternal } from 'src/@http/labels-server'
import { getOrdersForLS } from 'src/@http/orders'
import SelectorCourier from 'src/pages/components/carrier/SelectorCourier'
import SelectorService from 'src/pages/components/carrier/SelectorService'
import AutocompleteCountryCode from 'src/pages/components/labels-server/AutocompleteCountryCode'
import { logger } from 'src/utils/Logger'
import appStore from 'src/zustand/app'
import SweetAlert from 'sweetalert2'

import SelectorPkgTypes from './SelectorPkgTypes'
import { LineItem, Package, PackageType, ShippForm } from './types'

const Shipp = () => {
  const { state } = useLocation()
  const level = state?.level || 'client'
  const [lineItems, setLineItems] = useState<LineItem[]>([])
  const [totalValue, setTotalValue] = useState(0)
  const [totalWeight, setTotalWeight] = useState(0)
  const [loading, setLoading] = useState<boolean>(false)
  const paramsV2 = appStore(store => {
    return store.paramsV2
  })

  const [toStreetLines1, setToStreetLines1] = useState<string>('')
  const [toStreetLines2, setToStreetLines2] = useState<string>('')
  const [fromStreetLines1, setFromStreetLines1] = useState<string>('')
  const [fromStreetLines2, setFromStreetLines2] = useState<string>('')
  const [order, setOrder] = useState<any>(null)

  const orderId = new URLSearchParams(window.location.search).get('orderId')

  
  const [pkg, setPkg] = useState<Package>({
    dimensionLength: 0,
    dimensionWidth: 0,
    dimensionHeight: 0,
    lineItems: lineItems,
    packageWeight: 0
  })
  const [shippData, setShippData] = useState<ShippForm>({
    carrierId: 0,
    serviceId: 0,
    fromAddress: {
      contact: {
        phoneNumber: '',
        personName: '',
        companyName: ''
      },
      address: {
        streetLines: ['', ''],
        city: '',
        stateOrProvinceCode: '',
        postalCode: '',
        countryCode: '' //combo
      }
    },
    toAddress: {
      contact: {
        phoneNumber: '',
        personName: '',
        companyName: ''
      },
      address: {
        streetLines: ['', ''],
        city: '',
        stateOrProvinceCode: '',
        postalCode: '',
        countryCode: '' //combo
      }
    },
    package: pkg,
    packageType: '',
    contentDescription: '',
    internationalTerms: '',
    totalWeight: 0,
    totalValue: 0,
    shipDate: '',
    shopId: paramsV2.ShopId,
    orderNumber: '',
    orderSource: '1',
    shipFreight: '',
    referenceOrder: ''
  })

  useEffect(() => {
    const fetchOrder = async () => {
      if (orderId) {
        const order = await getOrdersForLS(orderId)
        setOrder(order)
        logger.log('order: ', order)
      }
    }
    fetchOrder()
  }, [orderId])

  useEffect(() => {
    if (order) {
      setShippData({
        carrierId: 0,
        serviceId: 0,
        fromAddress: {
          contact: {
            phoneNumber: order.fromAddress.contact.phoneNumber,
            personName: order.fromAddress.contact.personName,
            companyName: order.fromAddress.contact.companyName || ''
          },
          address: {
            streetLines: order.fromAddress.address.streetLines,
            city: order.fromAddress.address.city,
            stateOrProvinceCode: order.fromAddress.address.stateOrProvinceCode,
            postalCode: order.fromAddress.address.postalCode,
            countryCode: order.fromAddress.address.country
          }
        },
        toAddress: {
          contact: {
            phoneNumber: order.toAddress.contact.phoneNumber,
            personName: order.toAddress.contact.personName,
            companyName: order.toAddress.contact.companyName || ''
          },
          address: {
            streetLines: order.toAddress.address.streetLines,
            city: order.toAddress.address.city,
            stateOrProvinceCode: order.toAddress.address.stateOrProvinceCode,
            postalCode: order.toAddress.address.postalCode,
            countryCode: order.toAddress.address.country
          }
        },
        package: pkg,
        packageType: '',
        contentDescription: '',
        internationalTerms: '',
        totalWeight: 0,
        totalValue: 0,
        shipDate: '',
        shopId: paramsV2.ShopId,
        orderNumber: order.orderNumber,
        orderSource: '1',
        shipFreight: '',
        referenceOrder: ''
      })
    }
    setToStreetLines1(order?.toAddress.address.streetLines[0] || '')
    setToStreetLines2(order?.toAddress.address.streetLines[1] || '')
    setFromStreetLines1(order?.fromAddress.address.streetLines[0] || '')
    setFromStreetLines2(order?.fromAddress.address.streetLines[1] || '')
  }, [order])
  
  const [serviceEnabled, setService] = useState<number>(0)
  const [isInternational, setIsInternational] = useState<boolean>(false)
  const [courierSelected, setCourierSelected] = useState<number | null>(null)

  const handleAddLineItem = () => {
    setLineItems(prevForms => [
      ...prevForms,
      {
        qty: 0,
        unitWeight: 0,
        unitValue: 0,
        description: '',
        tariffCode: '',
        countryOfOrigin: ''
      }
    ])
  }

  const handleDeleteLineItem = (index: number) => {
    setLineItems(prevForms => {
      const newForms = [...prevForms]
      newForms.splice(index, 1)

      return newForms
    })
  }



  const handleSubmit = async (e: FormEvent) => {
    setLoading(true)
    const pkgUpdated = {
      ...pkg,
      lineItems: lineItems
    }
    if (lineItems.length > 0) {
      setTotalValue(lineItems.reduce((acc, item) => acc + item.unitValue, 0))
      setTotalWeight(lineItems.reduce((acc, item) => acc + item.unitWeight, 0))
    }

    const updatedShippData = {
      ...shippData,
      carrierId: courierSelected || 0,
      serviceId: serviceEnabled || 0,
      fromAddress: {
        ...shippData.fromAddress,
        address: {
          ...shippData.fromAddress.address,
          streetLines: [fromStreetLines1, fromStreetLines2],
          countryCode: isInternational ? shippData.fromAddress.address.countryCode : 'US'
        }
      },
      toAddress: {
        ...shippData.toAddress,
        address: {
          ...shippData.toAddress.address,
          streetLines: [toStreetLines1, toStreetLines2],
          countryCode: isInternational ? shippData.toAddress.address.countryCode : 'US'
        }
      },
      package: pkgUpdated,
      totalValue: totalValue.toString(),
      totalWeight: totalWeight.toString()
    }
    try {
      const { shippId, trackingNumber } = await setServerLevelInternal(updatedShippData)
      SweetAlert.fire({
        title: 'Shipp created',
        text: `Shipp ID: ${shippId} - Tracking Number: ${trackingNumber}`,
        icon: 'success',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#d6a51d'
      })
      setLoading(false)
    } catch (error: any) {
      SweetAlert.fire({
        title: 'Error',
        text: 'Error creating shipp',
        icon: 'error',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#d6a51d'
      })
      setLoading(false)
    }

    logger.log('updatedShippData: ', updatedShippData)
  }

  return (
    <Card>
      <CardContent>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='h4' gutterBottom>
            Manual Label Print Request
          </Typography>
          <Box
            sx={{
              mb: 6
            }}
          />
          <FormControl component='fieldset'>
            <RadioGroup
              aria-label='shipping-type'
              name='shippingType'
              value={isInternational}
              onChange={event => setIsInternational(event.target.value === 'true')}
              row
            >
              <FormControlLabel value='true' control={<Radio />} label='International' disabled />
              <FormControlLabel value='false' control={<Radio />} label='Domestic' disabled />
            </RadioGroup>
          </FormControl>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <SelectorCourier
                title='Carrier'
                onHandleSelectedOption={(value: any) => setCourierSelected(value)}
                courierSelected={courierSelected}
                actualLevel={level}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectorService
                onChange={(value: any) => {
                  setService(value.value)
                }}
                value={serviceEnabled}
                carrierId={courierSelected || null}
                title='Services'
                sx={{ mb: 3 }}
              />
            </Grid>
          </Grid>
          <Box sx={{ mb: 6 }} />
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box>
                  {/* From Address Fields */}
                  <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
                    <Box sx={{ mb: 6 }} />
                    <Typography variant='h5' gutterBottom>
                      From Address
                    </Typography>
                    <TextField
                      label='Phone Number'
                      name='phoneNumber'
                      value={shippData.fromAddress.contact.phoneNumber}
                      onChange={(e: any) =>
                        setShippData({
                          ...shippData,
                          fromAddress: {
                            ...shippData.fromAddress,
                            contact: { ...shippData.fromAddress.contact, phoneNumber: e.target.value }
                          }
                        })
                      }
                      sx={{ mt: 2, width: '100%' }}
                    />
                    <TextField
                      label='Person Name'
                      name='personName'
                      value={shippData.fromAddress.contact.personName}
                      onChange={(e: any) =>
                        setShippData({
                          ...shippData,
                          fromAddress: {
                            ...shippData.fromAddress,
                            contact: { ...shippData.fromAddress.contact, personName: e.target.value }
                          }
                        })
                      }
                      sx={{ mt: 2, width: '100%' }}
                    />
                    <TextField
                      label='Company Name'
                      name='companyName'
                      value={shippData.fromAddress.contact.companyName}
                      onChange={(e: any) =>
                        setShippData({
                          ...shippData,
                          fromAddress: {
                            ...shippData.fromAddress,
                            contact: { ...shippData.fromAddress.contact, companyName: e.target.value }
                          }
                        })
                      }
                      sx={{ mt: 2, width: '100%' }}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={2}>
                        {/* Postal Code */}
                        <TextField
                          label='Postal Code'
                          name='postalCode'
                          value={shippData.fromAddress.address.postalCode}
                          onChange={(e: any) =>
                            setShippData({
                              ...shippData,
                              fromAddress: {
                                ...shippData.fromAddress,
                                address: { ...shippData.fromAddress.address, postalCode: e.target.value }
                              }
                            })
                          }
                          sx={{ mt: 2, width: '100%' }}
                        />
                        {/* State or Province Code */}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <AutocompleteCountryCode
                          onSelectCountry={country => {
                            setShippData({
                              ...shippData,
                              fromAddress: {
                                ...shippData.fromAddress,
                                address: { ...shippData.fromAddress.address, countryCode: country.vchShortName }
                              }
                            })
                          }}
                          disabled={isInternational === false}
                          defaultValue={1}
                          sx={{ mt: 2, width: '100%' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* City */}
                        <TextField
                          label='City'
                          name='city'
                          value={shippData.fromAddress.address.city}
                          onChange={(e: any) =>
                            setShippData({
                              ...shippData,
                              fromAddress: {
                                ...shippData.fromAddress,
                                address: { ...shippData.fromAddress.address, city: e.target.value }
                              }
                            })
                          }
                          sx={{ mt: 2, width: '100%' }}
                        />
                      </Grid>
                    </Grid>
                    <TextField
                      label='State or Province Code'
                      name='stateOrProvinceCode'
                      value={shippData.fromAddress.address.stateOrProvinceCode}
                      onChange={(e: any) =>
                        setShippData({
                          ...shippData,
                          fromAddress: {
                            ...shippData.fromAddress,
                            address: { ...shippData.fromAddress.address, stateOrProvinceCode: e.target.value }
                          }
                        })
                      }
                      sx={{ mt: 2, width: '100%' }}
                    />
                    <TextField
                      label='Street Line 1'
                      name='streetLine1'
                      value={fromStreetLines1}
                      onChange={(e: any) => setFromStreetLines1(e.target.value)}
                      sx={{ mt: 2, width: '100%' }}
                    />
                    <TextField
                      label='Street Line 2'
                      name='streetLine2'
                      value={fromStreetLines2}
                      onChange={(e: any) => setFromStreetLines2(e.target.value)}
                      sx={{ mt: 2, width: '100%' }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  {/* To Address Fields */}
                  <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
                    <Box sx={{ mb: 6 }} />
                    <Typography variant='h5' gutterBottom>
                      To Address
                    </Typography>
                    <TextField
                      label='Phone Number'
                      name='phoneNumber'
                      value={shippData.toAddress.contact.phoneNumber}
                      onChange={(e: any) =>
                        setShippData({
                          ...shippData,
                          toAddress: {
                            ...shippData.toAddress,
                            contact: { ...shippData.toAddress.contact, phoneNumber: e.target.value }
                          }
                        })
                      }
                      sx={{ mt: 2, width: '100%' }}
                    />
                    <TextField
                      label='Person Name'
                      name='personName'
                      value={shippData.toAddress.contact.personName}
                      onChange={(e: any) =>
                        setShippData({
                          ...shippData,
                          toAddress: {
                            ...shippData.toAddress,
                            contact: { ...shippData.toAddress.contact, personName: e.target.value }
                          }
                        })
                      }
                      sx={{ mt: 2, width: '100%' }}
                    />
                    <TextField
                      label='Company Name'
                      name='companyName'
                      value={shippData.toAddress.contact.companyName}
                      onChange={(e: any) =>
                        setShippData({
                          ...shippData,
                          toAddress: {
                            ...shippData.toAddress,
                            contact: { ...shippData.toAddress.contact, companyName: e.target.value }
                          }
                        })
                      }
                      sx={{ mt: 2, width: '100%' }}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={2}>
                        {/* Postal Code */}
                        <TextField
                          label='Postal Code'
                          name='postalCode'
                          value={shippData.toAddress.address.postalCode}
                          onChange={(e: any) =>
                            setShippData({
                              ...shippData,
                              toAddress: {
                                ...shippData.toAddress,
                                address: { ...shippData.toAddress.address, postalCode: e.target.value }
                              }
                            })
                          }
                          sx={{ mt: 2, width: '100%' }}
                        />
                        {/* State or Province Code */}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <AutocompleteCountryCode
                          onSelectCountry={country => {
                            setShippData({
                              ...shippData,
                              toAddress: {
                                ...shippData.toAddress,
                                address: { ...shippData.toAddress.address, countryCode: country.vchShortName }
                              }
                            })
                          }}
                          disabled={isInternational === false}
                          defaultValue={1}
                          sx={{ mt: 2, width: '100%' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* City */}
                        <TextField
                          label='City'
                          name='city'
                          value={shippData.toAddress.address.city}
                          onChange={(e: any) =>
                            setShippData({
                              ...shippData,
                              toAddress: {
                                ...shippData.toAddress,
                                address: { ...shippData.toAddress.address, city: e.target.value }
                              }
                            })
                          }
                          sx={{ mt: 2, width: '100%' }}
                        />
                      </Grid>
                    </Grid>
                    <TextField
                      label='State or Province Code'
                      name='stateOrProvinceCode'
                      value={shippData.toAddress.address.stateOrProvinceCode}
                      onChange={(e: any) =>
                        setShippData({
                          ...shippData,
                          toAddress: {
                            ...shippData.toAddress,
                            address: { ...shippData.toAddress.address, stateOrProvinceCode: e.target.value }
                          }
                        })
                      }
                      sx={{ mt: 2, width: '100%' }}
                    />
                    <TextField
                      label='Street Line 1'
                      name='streetLine1'
                      value={toStreetLines1}
                      onChange={(e: any) => setToStreetLines1(e.target.value)}
                      sx={{ mt: 2, width: '100%' }}
                    />
                    <TextField
                      label='Street Line 2'
                      name='streetLine2'
                      value={toStreetLines2}
                      onChange={(e: any) => setToStreetLines2(e.target.value)}
                      sx={{ mt: 2, width: '100%' }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box>
                  {/* Data Shipp Fields */}
                  <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
                    <Typography variant='h5' gutterBottom>
                      Data Shipp
                    </Typography>
                    <TextField
                      label='Reference Order'
                      value={shippData.referenceOrder}
                      onChange={(e: any) => setShippData({ ...shippData, referenceOrder: e.target.value })}
                      sx={{ mt: 2, width: '100%' }}
                    />

                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label='Ship Date'
                      value={shippData.shipDate}
                      onChange={(newValue: any) => setShippData({ ...shippData, shipDate: newValue })}
                      renderInput={params => <TextField {...params} sx={{ mt: 2, width: '100%' }} />}
                    />
                  </LocalizationProvider> */}

                    <TextField
                      label='Ship Freight'
                      value={shippData.shipFreight}
                      onChange={(e: any) => setShippData({ ...shippData, shipFreight: e.target.value })}
                      sx={{ mt: 2, width: '100%' }}
                    />

                    <TextField
                      label='Order Number'
                      value={shippData.orderNumber}
                      onChange={(e: any) => setShippData({ ...shippData, orderNumber: e.target.value })}
                      sx={{ mt: 2, width: '100%' }}
                    />

                    <TextField
                      label='International Terms'
                      value={shippData.internationalTerms}
                      onChange={(e: any) => setShippData({ ...shippData, internationalTerms: e.target.value })}
                      sx={{ mt: 2, width: '100%' }}
                    />

                    <TextField
                      label='Content Description'
                      name='contentDescription'
                      value={shippData.contentDescription}
                      onChange={(e: any) => setShippData({ ...shippData, contentDescription: e.target.value })}
                      multiline
                      rows={4} // Adjust the number of rows as needed
                      sx={{ mt: 2, width: '100%' }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
                    <Typography variant='h5' gutterBottom>
                      Package
                    </Typography>
                    <SelectorPkgTypes
                      carrierId={courierSelected || 0}
                      serviceId={serviceEnabled || 0}
                      title='Package Type'
                      onChange={
                        (value: PackageType | null) => {
                          setShippData({ ...shippData, packageType: value?.vchName || '' })
                        }
                      }
                      pkgTypeSelected={1}
                    />
                    <TextField
                      label='Dimension Length'
                      value={pkg.dimensionLength}
                      onChange={(e: any) => setPkg({ ...pkg, dimensionLength: parseFloat(e.target.value) })}
                      type='number'
                      sx={{ mt: 2, width: '100%' }}
                    />
                    <TextField
                      label='Dimension Width'
                      value={pkg.dimensionWidth}
                      onChange={(e: any) => setPkg({ ...pkg, dimensionWidth: parseFloat(e.target.value) })}
                      type='number'
                      sx={{ mt: 2, width: '100%' }}
                    />
                    <TextField
                      label='Dimension Height'
                      value={pkg.dimensionHeight}
                      onChange={(e: any) => setPkg({ ...pkg, dimensionHeight: parseFloat(e.target.value) })}
                      type='number'
                      sx={{ mt: 2, width: '100%' }}
                    />
                    <TextField
                      label='Package Weight'
                      value={pkg.packageWeight}
                      onChange={(e: any) => setPkg({ ...pkg, packageWeight: parseFloat(e.target.value) })}
                      type='number'
                      sx={{ mt: 2, width: '100%' }}
                    />
                  </Box>
                </Box>
              </Grid>
              {isInternational && (
                <>
                  <Box sx={{ margin: 'auto', padding: 2 }}>
                    <Typography variant='h5' gutterBottom>
                      Line Items
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align='center'>Description</TableCell>
                            <TableCell align='center'>Tariff Code</TableCell>
                            <TableCell align='center'>Country of Origin</TableCell>
                            <TableCell align='center'>Quantity</TableCell>
                            <TableCell align='center'>Unit Weight</TableCell>
                            <TableCell align='center'>Unit Value</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {lineItems.map((lineItem, index) => (
                            <TableRow key={index}>
                              <TableCell style={{ width: '30%' }}>
                                <TextField
                                  label='Description'
                                  value={lineItem.description || ''}
                                  onChange={e =>
                                    setLineItems(prevForms => {
                                      const newForms = [...prevForms]
                                      newForms[index].description = e.target.value

                                      return newForms
                                    })
                                  }
                                  fullWidth
                                  multiline
                                  maxRows={3}
                                />
                              </TableCell>

                              <TableCell>
                                <TextField
                                  label='Tariff Code'
                                  value={lineItem.tariffCode || ''}
                                  onChange={e =>
                                    setLineItems(prevForms => {
                                      const newForms = [...prevForms]
                                      newForms[index].tariffCode = e.target.value

                                      return newForms
                                    })
                                  }
                                  fullWidth
                                />
                              </TableCell>
                              <TableCell
                                style={{
                                  width: '20%'
                                }}
                              >
                                <AutocompleteCountryCode
                                  onSelectCountry={country => {
                                    setLineItems(prevForms => {
                                      const newForms = [...prevForms]
                                      newForms[index].countryOfOrigin = country.vchShortName

                                      return newForms
                                    })
                                  }}
                                  disabled={false}
                                  defaultValue={0}
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  label='Quantity'
                                  type='number'
                                  value={lineItem.qty || 0}
                                  onChange={e =>
                                    setLineItems(prevForms => {
                                      const newForms = [...prevForms]
                                      newForms[index].qty = parseInt(e.target.value)

                                      return newForms
                                    })
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  label='Unit Weight'
                                  type='number'
                                  value={lineItem.unitWeight || 0}
                                  onChange={e =>
                                    setLineItems(prevForms => {
                                      const newForms = [...prevForms]
                                      newForms[index].unitWeight = parseFloat(e.target.value)

                                      return newForms
                                    })
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  label='Unit Value'
                                  type='number'
                                  value={lineItem.unitValue || 0}
                                  onChange={e =>
                                    setLineItems(prevForms => {
                                      const newForms = [...prevForms]
                                      newForms[index].unitValue = parseFloat(e.target.value)

                                      return newForms
                                    })
                                  }
                                  fullWidth
                                />
                              </TableCell>
                              <TableCell align='center'>
                                <IconButton onClick={() => handleDeleteLineItem(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Button variant='outlined' color='primary' sx={{ mt: 2 }} onClick={handleAddLineItem}>
                      <Typography variant='button'>Add Line Item</Typography>
                    </Button>
                  </Box>
                </>
              )}
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 2
              }}
            >
              <TextField
                label='Total Weight'
                value={totalWeight}
                onChange={
                  (e: any) => {
                    setTotalWeight(e.target.value)
                  }
                }
                sx={{
                  mt: 2,
                  width: '20%'
                }}
              />

              <LoadingButton
                loading={loading}
                style={{
                  color: 'white'
                }}
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                sx={{
                  margin: 'auto'
                }}
              >
                Submit
              </LoadingButton>

              <TextField
                label='Total Value'
                value={totalValue}
                onChange={
                  (e: any) => {
                    setTotalValue(e.target.value)
                  }
                }
                sx={{
                  mt: 2,
                  width: '20%'
                }}
              />
            </Box>
          </form>
        </Box>
      </CardContent>
    </Card>
  )
}
export default Shipp
import { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { getMeasures } from 'src/@http/measures'
import { MeasureModel } from 'src/types/models/MeasureModels'
import { logger } from 'src/utils/Logger'
import servicesStore from 'src/zustand/services'

interface MeasureListProps {
  onSelectMeasure: (measureId: string) => void
  useAllValue?: boolean
  disabled?: boolean
}

const MeasureList = ({ onSelectMeasure, useAllValue, disabled }: MeasureListProps) => {
  const [measureList, setMeasureList] = useState<MeasureModel[]>([])
  const [value, setValue] = useState('')
  const { setMeasure, setMeasureName } = servicesStore(state => ({
    setMeasure: state.setMeasure,
    setMeasureName: state.setMeasureName
  }))
  const optionAll = {
    tinUnitMeasureId: '-1',
    vchName: 'All',
    id: '-1'
  }

  const handleChange = (event: any, value: string | null) => {
    logger.log('Value en Hijo:', value)
    logger.log('Event en Hijo:', event.target.value)

    const selectedMeasure = measureList.find(measure => measure.vchName === value)

    if (selectedMeasure) {
      const tinUnitMeasureId = selectedMeasure.tinUnitMeasureId
      logger.log('tinUnitMeasureId:', tinUnitMeasureId)

      onSelectMeasure(value || '')
      setValue(value || '')
      setMeasure(tinUnitMeasureId)
      setMeasureName(value || '')
    } else {
      console.error('El objeto con vchName no se encontró en measureList.')
    }
  }

  useEffect(() => {
    getMeasures()
      .then(data => {
        logger.log('Mesaure List data', data)
        if (useAllValue) data.unshift(optionAll)
        logger.log('Mesaure List data', data)
        setMeasureList(data)
        setValue(data[0].vchName)
      })
      .catch(error => {
        console.error('Error fetching customer data:', error)
      })
  }, [setMeasureList])

  return (
    <Autocomplete
      disabled = {disabled}
      fullWidth
      onChange={handleChange}
      value={value}
      options={measureList.map(measure => measure.vchName)}
      id='autocomplete-disableCloseOnSelect'
      renderInput={params => <TextField {...params} label='Measure' />}
    />
  )
}

export default MeasureList

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@iconify/react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import OptionsMenu from 'src/@core/components/option-menu'
import { desiredRoutes } from 'src/types/enums/PagesByLevel'
import { ParentModel } from 'src/types/models/ParentModel'
import appStore from 'src/zustand/app'
import companiesStore from 'src/zustand/companies'
import customerStore from 'src/zustand/customer'

type AutocompleteCompanyProps = {
  size?: 'small' | 'medium'
  width?: number | string
  addViewMobile?: boolean
  readOnly?: boolean
  disabled?: boolean
}

const AutocompleteProps = (props: AutocompleteCompanyProps) => {
  const { t } = useTranslation()
  const [viewMobile, setViewModal] = useState<boolean | undefined>(props.addViewMobile)
  const { paramsV2, setParamsV2 } = appStore()
  const { companies, getCompanies, setCompanyGlobal, companyGlobal } = companiesStore()
  const { setShops } = customerStore()

  const handleChange = (event: any, value: any | null) => {
    setCompanyGlobal(value || null)
    setParamsV2({
      ...paramsV2,
      CustomerId: value && value.intCustomerId ? value.intCustomerId : -1
    })
    setShops()
  }

  useEffect(() => {
    if (companies.length === 0) getCompanies()
  }, [])

  useEffect(() => {
    if (props.addViewMobile !== undefined) {
      setViewModal(props.addViewMobile)
    }
  }, [props.addViewMobile])

  useEffect(() => {
    if (companyGlobal === null && companies.length !== 0) {
      setCompanyGlobal(companies[0])
      setParamsV2({
        ...paramsV2,
        CustomerId: companies[0].intCustomerId ? companies[0].intCustomerId : 0
      })
    }
  }, [companies])

  const options = companies.map((company: ParentModel) => {
    return {
      text: t(company.vchName),
      menuItemProps: {
        sx: { py: 2 },
        onClick: () => handleChange(null, company)
      }
    }
  })

  const isVisible =
    desiredRoutes.CUSTOMER.includes(location.pathname) || desiredRoutes.CLIENT.includes(location.pathname)

  if (!isVisible) {
    return null
  }

  return (
    <>
      {!viewMobile ? (
        <Autocomplete
          size={props.size || 'medium'}
          onChange={handleChange}
          options={companies}
          disabled={props.disabled || false}
          readOnly={props.readOnly || false}
          value={companyGlobal}
          clearIcon={null}
          getOptionLabel={option => option.vchName}
          id='autocomplete-disableCloseOnSelect'
          renderInput={params => <TextField sx={{ width: props.width || '180px' }} {...params} label={'Customers'} />}
        />
      ) : (
        <>
          <OptionsMenu
            icon={<Icon icon='mdi:user-tie' color='inherit' />}
            menuProps={{ sx: { '& .MuiMenu-paper': { mt: 4, minWidth: 130, maxHeight: 400 } } }}
            options={options}
            addTitle={{
              title: 'Customers'
            }}
          />
        </>
      )}
    </>
  )
}

export default AutocompleteProps

import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type State = {
  file: File[]
  closeFileModal: boolean
}

type Actions = {
  setFile: (file: File[]) => void
  setCloseFileModal: (closeFileModal: boolean) => void
}

const initialState: State = {
  file: [],
  closeFileModal: false
}

const filesStore = create<State & Actions>()(
  persist(
    (set) => ({
      ...initialState,
      setFile: (file: File[]) => {
        set({ file: file })
      },
      setCloseFileModal: (closeFileModal: boolean) => {
        set({ closeFileModal: closeFileModal })
      }
    }),
    {
      name: 'files-store'
    }
  )
)

if (process.env.NODE_ENV === 'development') mountStoreDevtool('files-store', filesStore)

export default filesStore

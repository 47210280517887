// ** MUI Imports
import React, { useEffect, useState } from 'react'
import {
  Box,
  BoxProps,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableCellBaseProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { getOrderDetailId, getOrderId } from 'src/@http/orders'
import { OrderDto, OrderProductsDto } from 'src/types/models/OrderModel'
// ** Configs
//import themeConfig from 'src/configs/themeConfig'
// ** Types
import { formatOrderDate } from 'src/utils/formatDate'
import appStore from 'src/zustand/app'

const OrderEditorPreview = () => {
  const MUITableCell = styled(TableCell)<TableCellBaseProps>(({ theme }) => ({
    borderBottom: 0,
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    paddingTop: `${theme.spacing(1)} !important`,
    paddingBottom: `${theme.spacing(1)} !important`
  }))

  const CalcWrapper = styled(Box)<BoxProps>(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(2)
    }
  }))

  const { orderId, paramsV2 } = appStore()
  const [loading, setLoading] = useState(true)
  const [orderData, setOrderData] = useState<null | OrderDto>(null)
  const [orderDetailData, setOrderDetailData] = useState<null | OrderProductsDto[]>(null)
  const [isEditing, setIsEditing] = useState(false)
  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const data = await getOrderId(orderId)
        await setOrderData(data)
        const dataDetail = await getOrderDetailId(orderId)
        await setOrderDetailData(dataDetail)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching order data:', error)
        setLoading(false)
      }
    }
    setLoading(true)
    fetchOrderData()
  }, [orderId, paramsV2])

  if (!orderData) {
    return null
  }

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <Card>
          <CardContent>
            <Grid container>
              <Grid item sm={6} xs={12} sx={{ mb: { sm: 0, xs: 4 } }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ mb: 6, display: 'flex', alignItems: 'center' }}>
                    <img
                      src={`http://${window.location.hostname}:${window.location.port}/images/logo.png`}
                      alt='Logo'
                      style={{ maxWidth: '50px', maxHeight: '50px' }}
                    />
                    <Typography
                      variant='h6'
                      sx={{ ml: 2.5, fontWeight: 600, lineHeight: 'normal', textTransform: 'uppercase' }}
                    >
                      Ship Ez
                    </Typography>
                  </Box>
                  <div>
                    <Table sx={{ maxWidth: '200px' }}>
                      <TableBody>
                        <TableRow>
                          <MUITableCell style={{ width: '50%' }}>Order status:</MUITableCell>
                          <MUITableCell>
                            <Typography>{orderData.vchStatusName}</Typography>
                          </MUITableCell>
                        </TableRow>
                        <TableRow>
                          <MUITableCell style={{ width: '50%' }}>Order type:</MUITableCell>
                          <MUITableCell>
                            <Typography>{/*orderData.vchOrderType*/}</Typography>
                          </MUITableCell>
                        </TableRow>
                        <TableRow>
                          <MUITableCell style={{ width: '50%' }}>3rd Party PO:</MUITableCell>
                          <MUITableCell>
                            <Typography>{orderData.vch3rdPartyPO}</Typography>
                          </MUITableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}>
                  <Table sx={{ maxWidth: '200px' }}>
                    <TableBody>
                      <TableRow>
                        <MUITableCell>
                          <Typography variant='h6'>Order</Typography>
                        </MUITableCell>
                        <MUITableCell>
                          <Typography variant='h6'>{`#${orderData.bigOrderId}`}</Typography>
                        </MUITableCell>
                        <MUITableCell></MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell>
                          <Typography variant='body2'>Date Issued:</Typography>
                        </MUITableCell>
                        <MUITableCell>
                          <Typography variant='body2'>{formatOrderDate(orderData.dtmOrderDate)}</Typography>
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell>
                          <Typography variant='body2'>Hold Date:</Typography>
                        </MUITableCell>
                        <MUITableCell>
                          <Typography variant='body2'>{formatOrderDate(orderData.dtmHoldDate)}</Typography>
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell>
                          <Typography variant='body2'>Elite Id:</Typography>
                        </MUITableCell>
                        <MUITableCell>
                          <Typography variant='body2'>{orderData.vchEliteId}</Typography>
                        </MUITableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            </Grid>
          </CardContent>

          <Divider />

          <CardContent>
            <Grid container>
              <Grid item xs={12} sm={6} sx={{ mb: { lg: 0, xs: 4 } }}>
                <Typography variant='body2' sx={{ mb: 3.5, fontWeight: 600 }}>
                  Customer Data:
                </Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <MUITableCell style={{ width: '50%' }}>Customer Id:</MUITableCell>
                        <MUITableCell>
                          <Typography>{orderData.intCustomerId}</Typography>
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell style={{ width: '50%' }}>Customer Name:</MUITableCell>
                        <MUITableCell>
                          <Typography>{orderData.vchCustomerName}</Typography>
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell style={{ width: '50%' }}>Order Id:</MUITableCell>
                        <MUITableCell>
                          <Typography>{orderData.intOrderBatch}</Typography>
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell style={{ width: '50%' }}>Batch:</MUITableCell>
                        <MUITableCell>
                          <Typography>{orderData.vchBatch}</Typography>
                        </MUITableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: ['flex-start', 'flex-end'] }}>
                <div>
                  <Typography variant='body2' sx={{ mb: 3.5, color: 'text.primary', fontWeight: 600 }}>
                    &nbsp;
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <MUITableCell>Contact:</MUITableCell>
                          <MUITableCell>
                            <Typography style={{ marginLeft: '10px' }}>{orderData?.vchContact}</Typography>
                          </MUITableCell>
                        </TableRow>
                        <TableRow>
                          <MUITableCell>Company:</MUITableCell>
                          <MUITableCell>
                            <Typography style={{ marginLeft: '10px' }}>{orderData?.vchCompany}</Typography>
                          </MUITableCell>
                        </TableRow>
                        <TableRow>
                          <MUITableCell>Telephone:</MUITableCell>
                          <MUITableCell>
                            <Typography style={{ marginLeft: '10px' }}>{orderData?.vchTelephone}</Typography>
                          </MUITableCell>
                        </TableRow>
                        <TableRow>
                          <MUITableCell>Email:</MUITableCell>
                          <MUITableCell>
                            <Typography style={{ marginLeft: '10px' }}>{orderData?.vchMail}</Typography>
                          </MUITableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </CardContent>

          <Divider />

          <CardContent>
            <Grid container>
              <Grid item xs={12} sm={6} sx={{ mb: { lg: 0, xs: 4 } }}>
                <Typography variant='body2' sx={{ mb: 3.5, fontWeight: 600 }}>
                  Ship Data:
                </Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <MUITableCell style={{ width: '50%' }}>Original Courier:</MUITableCell>
                        <MUITableCell>
                          <Typography>{/*orderData.vchShipCode*/}</Typography>
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell style={{ width: '50%' }}>Origial Service:</MUITableCell>
                        <MUITableCell>
                          <Typography>{/*orderData.vchShipMethod*/}</Typography>
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell style={{ width: '50%' }}>Actual Courier:</MUITableCell>
                        <MUITableCell>
                          <Typography>{/*orderData.vchShipComplete*/}</Typography>
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell style={{ width: '50%' }}>Actual Service:</MUITableCell>
                        <MUITableCell>
                          <Typography>{/*orderData.vchIntlShipType*/}</Typography>
                        </MUITableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: ['flex-start', 'flex-end'] }}>
                <div>
                  <Typography variant='body2' sx={{ mb: 3.5, fontWeight: 600 }}>
                    &nbsp;
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <MUITableCell>Ship Same Day:</MUITableCell>
                          <MUITableCell>
                            <Typography style={{ marginLeft: '10px' }}>
                              {orderData.tinSameDay === 1 ? 'Yes' : 'No'}
                            </Typography>
                          </MUITableCell>
                        </TableRow>
                        <TableRow>
                          <MUITableCell>Shipping Account:</MUITableCell>
                          <MUITableCell>
                            <Typography style={{ marginLeft: '10px' }}>{orderData.vchShippingAccount}</Typography>
                          </MUITableCell>
                        </TableRow>
                        <TableRow>
                          <MUITableCell>Warehouse Code:</MUITableCell>
                          <MUITableCell>
                            <Typography style={{ marginLeft: '10px' }}>{orderData.sinWarehouseId}</Typography>
                          </MUITableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </CardContent>

          <Divider />

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: 'center' }}>Product name</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>Description</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>Unit price</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>Qty</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderDetailData ? (
                  orderDetailData.map((detail, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Typography>{detail.productName}</Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Typography>{detail.vchProductDescription}</Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Typography>
                          {detail.decProductPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Typography>{detail.intQuantity}</Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Typography>
                          {detail.decPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                      No order details available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <CardContent>
            <Grid container>
              <Grid item xs={12} sm={7} lg={9} sx={{ order: { sm: 1, xs: 2 } }}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <MUITableCell style={{ width: '20%' }}>Warehouse remarks:</MUITableCell>
                        <MUITableCell>
                          <Typography style={{ marginLeft: '10px' }}>{orderData.vchWarehouseRemarks}</Typography>
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell style={{ width: '20%' }}>Logistics remarks:</MUITableCell>
                        <MUITableCell>
                          <Typography style={{ marginLeft: '10px' }}>{orderData.vchLogisticsRemarks}</Typography>
                        </MUITableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={5} lg={3} sx={{ mb: { sm: 0, xs: 4 }, order: { sm: 2, xs: 1 } }}>
                <CalcWrapper>
                  <Typography>Subtotal:</Typography>
                  <Typography>
                    {orderData.decOrderTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </Typography>
                </CalcWrapper>
                <CalcWrapper>
                  <Typography>Discount:</Typography>
                  <Typography>$0.00</Typography>
                </CalcWrapper>
                <CalcWrapper>
                  <Typography>Tax:</Typography>
                  <Typography>$0.00</Typography>
                </CalcWrapper>
                <Divider />
                <CalcWrapper>
                  <Typography>Total:</Typography>
                  <Typography>
                    {orderData.decOrderTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </Typography>
                </CalcWrapper>
              </Grid>
            </Grid>
          </CardContent>

          <Divider />

          <CardContent>
            <Typography variant='body2'>
              <strong>Comments:</strong> {orderData?.vchComments !== 'null' ? orderData?.vchComments : 'No comments'}
            </Typography>
          </CardContent>
        </Card>
      )}
    </>
  )
}

export default OrderEditorPreview

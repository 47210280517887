import { MouseEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@iconify/react'
import { Box, Fab, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { IDate } from 'src/views/filters/DateFilter'
import appStore from 'src/zustand/app'

import OrderDateFilter from './DatefilterOrder'

interface PropsFilterPanel {
  handleDates: (dates: IDate) => any
  handleConfirm: (confirmed: boolean | null) => any
  sx?: SxProps
}

interface BtnFilterProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
  textBtn: string
  mdAndDown: boolean
  disabled: boolean
}

const BtnConfirmFilter = ({ onClick, textBtn, mdAndDown, disabled }: BtnFilterProps) => (
  <Fab
    key='apply-filter'
    onClick={onClick}
    color='secondary'
    aria-label='filter'
    variant='extended'
    size='small'
    disabled={disabled}
    sx={{ margin: '1rem 0', padding: '0 1rem' }}
  >
    {textBtn}
    {!mdAndDown && <Icon icon='mdi:filter-outline' />}
  </Fab>
)

const CustomOrdersFiltersPanel = ({ handleDates, handleConfirm, sx }: PropsFilterPanel) => {
  const { t } = useTranslation()
  const [disableBtn, setDisableBtn] = useState<boolean>(false)
  const { setOrderFilterStartDate, setOrderFilterEndDate } = appStore(state => ({
    setOrderFilterStartDate: state.setOrderFilterStartDate,
    setOrderFilterEndDate: state.setOrderFilterEndDate
  }))
  useEffect(() => {
    setOrderFilterStartDate(
      (() => {
        const currentDate = new Date()
        currentDate.setDate(currentDate.getDate() - 30)

        return currentDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
      })()
    )
    setOrderFilterEndDate(new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }))
  }, [setOrderFilterStartDate, setOrderFilterEndDate])
  const [selectedDates, setSelectedDates] = useState<IDate>({
    dateFrom: null,
    dateTo: null
  })
  // Style Configuration
  const theme = useTheme()
  const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const styleBoxItem = {
    display: 'flex',
    flexDirection: matchesDownMd ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 .5rem'
  }
  const styleTitleFilters = {
    mb: matchesDownMd ? 4 : 0,
    mr: !matchesDownMd ? 4 : 0
  }

  const formatDate = (date: Date) => {
    const mm = String(date.getMonth() + 1).padStart(2, '0')
    const dd = String(date.getDate()).padStart(2, '0')
    const yyyy = date.getFullYear()

    return `${mm}/${dd}/${yyyy}`
  }

  const handleConfirmFilter = (event?: MouseEvent<HTMLButtonElement>, dates?: IDate) => {
    if (selectedDates && selectedDates.dateFrom && selectedDates.dateTo) {
      const formattedDateFrom = formatDate(selectedDates.dateFrom)
      const formattedDateTo = formatDate(selectedDates.dateTo)

      setOrderFilterStartDate(formattedDateFrom)
      setOrderFilterEndDate(formattedDateTo)
      handleConfirm(null)
    } else {
      console.error('dateFrom and dateTo must be set')
    }
  }

  function setDateSelected(dates: IDate) {
    handleDates(dates)
    setDisableBtn(true)
    setSelectedDates(dates)
  }

  return (
    <Box sx={{ ...{ my: matchesDownMd ? 5 : 1 }, ...sx }}>
      <Box sx={styleBoxItem}>
        <Typography variant='body1' sx={styleTitleFilters}>
          By date
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <OrderDateFilter onData={setDateSelected} width={matchesDownMd ? '100%' : '420px'} />
        </LocalizationProvider>
      </Box>
      <Box sx={styleBoxItem}>
        <BtnConfirmFilter
          disabled={!disableBtn}
          onClick={handleConfirmFilter}
          textBtn={'Apply filter'}
          mdAndDown={matchesDownMd}
        />
      </Box>
    </Box>
  )
}

export default CustomOrdersFiltersPanel

import { useEffect, useRef, useState } from 'react'
import { isEqual } from 'lodash-es'
import { GetCarrierAccount, getCarrierAccount } from 'src/@http/carrier-account'
import { AccountCarrierModel } from 'src/types/models/AccountCarriersModels'
import { logger } from 'src/utils/Logger'

interface AccountAccessData {
  UrlApi: string
  RequesterId: string
  AccountNumber: string
  Password: string
}

interface Props {
  params: GetCarrierAccount
  path?: string
}

export const useCarrierAccount = ({ params, path = '' }: Props): AccountCarrierModel[] => {
  const [carriersAccounts, setCarriersAccounts] = useState<AccountCarrierModel[]>([])
  const prevData = useRef({})

  useEffect(() => {
    const fecthData = async () => {

      const accountAccessDataParser = (data: string) => {
        try {
          return data !== null ? JSON.parse(data) : null
        } catch (error) {
          return {}
        }
      }

      try {
        const response = await getCarrierAccount(params)
        logger.log('CARRIERS: ', response.list)

        const carriersList = response.list

        const carriersMap = carriersList.map(carrier => {
          const accountAccessData: AccountAccessData | null = accountAccessDataParser(carrier.vchAccountAccessData)
         
          let image = ''
          switch (carrier.vchCarrierName) {
            case 'USPS':
              image = '/images/usps1.png'
              break
            case 'UPS':
              image = '/images/ups1.png'
              break
            case 'FEDEX':
              image = '/images/fedex1.png'
              break
            case 'GLS':
              image = '/images/gls1.png'
              break
            default:
              image = '/images/img-notfound.png'
              break
          }
          const aux: any = {
            // : AccountCarrierModel
            balance: carrier.decBalance,
            carrierName: carrier.vchCarrierName,
            courierId: carrier.intCarrierId,
            minBalance: carrier.decBalanceMin || 1,
            accountNumber: accountAccessData !== null ? accountAccessData.AccountNumber : 'N°: XXX-XXXXX-XX',
            url: carrier.vchUrl,
            image: carrier.vchLogoPath || image,
            status: carrier.bitOwnAccount,
            accountType: path === '' ? undefined : path === '/client-labels' ? 'Customer Account' : 'EZ Account',
            ...carrier
          }

          return aux
        })
        setCarriersAccounts(carriersMap)
      } catch (error) {
        setCarriersAccounts([])
      }
    }

    if (!isEqual(prevData.current, params)) {
      fecthData()
    }

    prevData.current = params
  }, [params])

  return carriersAccounts
}

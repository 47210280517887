import { ReactNode } from 'react'
import { Icon } from '@iconify/react'
import { Card, Chip, Grid, IconButton } from '@mui/material'

type FilterGridProps = {
  children: ReactNode
  gapStyle?: number
  filterApplied?: { applied: boolean; data: { [key: string]: any } | null }
  handleClose?: (close: boolean) => void
}

const formatProperty = (property: string): string => {
  const regex = /([a-z])([A-Z])/g

  return property.replace(regex, '$1 $2').replace(/^./, str => str.toUpperCase())
}

const FilterGridWithChips = ({ children, gapStyle, filterApplied, handleClose }: FilterGridProps) => {
  const closeFilterChips = () => {
    if (handleClose) {
      handleClose(true)
    }
  }

  return (
    <Grid item xs={12}>
      {!filterApplied?.applied ? (
        <Card sx={{ padding: { xs: '1rem', md: '2rem' } }}>
          <Grid container gap={gapStyle} rowGap={3}>
            {children}
          </Grid>
        </Card>
      ) : (
        <Card sx={{ padding: { xs: '1rem', md: '2rem' } }}>
          <Grid container flexDirection={{ md: 'row', xs: 'column' }}>
            <Grid item xs={12} lg={11} flexWrap='wrap'>
              {filterApplied?.data
                ? Object.entries(filterApplied?.data).map(([key, value]) => (
                    <Chip
                      key={key}
                      label={`${formatProperty(key)} - ${
                        typeof value === 'object' ? value.toLocaleDateString('en-US') : value.toString()
                      }`}
                      color='secondary'
                      sx={{ mr: 2 }}
                    />
                  ))
                : null}
            </Grid>
            <Grid item xs={12} lg={1} display='flex' justifyContent='center' alignItems='center'>
              <IconButton aria-label='close' color='primary' onClick={closeFilterChips}>
                <Icon icon='material-symbols:close' />
              </IconButton>
            </Grid>
          </Grid>
        </Card>
      )}
    </Grid>
  )
}

export default FilterGridWithChips

import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Card, FormControl, Grid, IconButton, Paper, Stack, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material'
import { deleteCarrierPackageType, deleteCarrierRegion, getCarrierRegions, getPackagesTypesByCarrier, postCarrier, putCarrier } from 'src/@http/carriers'
import { RtaUploadFile, uploadFile, UploadFileType } from 'src/@http/uploadFile'
import AutocompleteCountry from 'src/pages/components/customer/AutocompleteCountry'
import BoxPreviewImage from 'src/pages/components/generic/BoxPreviewImage'
import { DialogExpose, DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import GenericButton from 'src/pages/components/generic/GenericButton'
import { logger } from 'src/utils/Logger'
import { showIfValidImage } from 'src/utils/misc'
import SelectCouriers from 'src/views/filters/selectors/SelectCourier'
import FileUploadImageSingle from 'src/views/forms/FileUploadImageSingle'
import * as yup from 'yup'

type Mode = 'view' | 'add' | 'edit'

type CarrierForm = {
  vchName: string
  vchLegacyNumber: string
  intStatusId: number
  vchStatusName: string
  id: number
  status: boolean
  intCarrierId: number
  intCarrierParentId: number
  isActive: boolean
  accountvchAccountDefaultData?: null
  vchLogoPath?: string | null
  vchUrlAccessDataAccount?: string
  vchAccountDefaultData?: string
  packageTypes?: {
    intCarrierPackageTypeId: number
    vchName: string
    vchSourceId?: string
    intStatusId?: number
  }[]
}

type Region = {
  id: number
  intRegionId: number
  vchName: string
  intStatusId: number
  intCountryId: number
  intCarrierId: number
  vchCountryName: string
}

const CarrierAddOrEdit = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const { mode, data } = state
  const [parentCarrier, setParentCarrier] = useState<number | null>(null)
  const [fileLogo, setFileLogo] = useState<string | File | null>(null)
  const [currentMode, setCurrentMode] = useState<Mode>(mode)
  const navigate = useNavigate()
  const modalLogoUploader = useRef<DialogExpose>(null)
  const [currentTab, setCurrentTab] = useState('packageTypes');

  const [regions, setRegions] = useState<Region[]>([])

  let initialForm: CarrierForm = {
    vchName: '',
    vchLegacyNumber: '',
    intStatusId: 0,
    vchStatusName: '',
    id: 0,
    status: false,
    intCarrierId: 0,
    isActive: true,
    accountvchAccountDefaultData: null,
    intCarrierParentId: 0,
    vchLogoPath: null,
    packageTypes: []
  }

  if (data) {
    initialForm = { ...data }
    initialForm.status = data.intStatusId === 1
  }

  useEffect(() => {
    data && setFileLogo(initialForm.vchLogoPath || null)
  }, [])
  const validations = {
    vchName: yup.string(),
    vchLegacyNumber: yup.string()
  }
  validations.vchName = validations.vchName.required()
  validations.vchLegacyNumber = validations.vchLegacyNumber.required()
  const schemaValidations = yup.object().shape(validations)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [packageTypes, setPackageTypes] = useState<{
    id: number;
    vchSourceId?: string;
    intCarrierPackageTypeId: number;
    vchName: string;
    intStatusId?: number;
  }[]>([])
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm<any>({ resolver: yupResolver(schemaValidations), defaultValues: initialForm })

  const parentSelected = (courier: number) => setParentCarrier(courier)

  const onSubmit = async (form: CarrierForm) => {
    let uploadedLogo = getValues('vchLogoPath') || null
    setIsLoading(true)

    logger.log('form', form)
    logger.log(parentCarrier)

    if (!(typeof fileLogo === 'string' && fileLogo === uploadedLogo))
      uploadedLogo = await uploadLogo()

    try {
      if (currentMode === 'add') {
        const payload: any = {
          vchName: form.vchName,
          vchLegacyNumber: form.vchLegacyNumber,
          vchLogoPath: uploadedLogo || null,
          vchUrlAccessDataAccount: form.vchUrlAccessDataAccount,
          vchAccountDefaultData: form.vchAccountDefaultData,
          lstCarrierPackageType: packageTypes,
          intCarrierParentId: parentCarrier ? parentCarrier : form.intCarrierParentId,
          lstRegions: regions
        }
        await postCarrier(payload)
        toast.success(t('CREATED_SUCCESS'), { duration: 4000 })
        navigate('/carriers')
      } else {
        const payload: any = {
          id: form.intCarrierId,
          vchName: form.vchName,
          vchLegacyNumber: form.vchLegacyNumber,
          intCarrierId: form.intCarrierId,
          vchLogoPath: uploadedLogo || null,
          vchUrlAccessDataAccount: form.vchUrlAccessDataAccount,
          vchAccountDefaultData: form.vchAccountDefaultData,
          lstCarrierPackageType: packageTypes,
          intCarrierParentId: parentCarrier ? parentCarrier : form.intCarrierParentId,
          lstRegions: regions
        }
        await putCarrier(payload)
        toast.success(t('UPDATED_SUCCESS'), { duration: 4000 })
        navigate('/carriers')
      }
    } catch (error: any) {
      toast.error(error.title, { duration: 4000 })
    }
    setIsLoading(false)
  }

  async function uploadLogo() {
    if (fileLogo) {
      const res = (await uploadFile(fileLogo as File, UploadFileType.Couriers)) as RtaUploadFile

      return res.virtualPathFile
    }

    return null
  }

  const handleAddPackageType = () => {
    setPackageTypes([...packageTypes, { id: 0, intCarrierPackageTypeId: 0, vchName: '', vchSourceId: '', intStatusId: 1 }]);
  }

  const handleAddRegion = () => {
    setRegions([...regions, { id: 0, intRegionId: 0, vchName: '', intStatusId: 1, intCountryId: 0, intCarrierId: initialForm.intCarrierId, vchCountryName: '' }]);
  }

  const handleTabChange = (event: any, newValue: string) => {
    setCurrentTab(newValue);
  }

  const handleDeletePackageType = (index: any) => {
    const newTypes = [...packageTypes];
    newTypes[index].intStatusId = 2; // 2 es delete
    if (newTypes[index].intCarrierPackageTypeId !== 0)
      try {
        deleteCarrierPackageType(newTypes[index].intCarrierPackageTypeId)
      }
      catch (error: any) {
        toast.error(error.title, { duration: 4000 })
      }
    setPackageTypes(newTypes);
  }

  const handleDeleteRegion = (index: number) => {
    const newRegions = [...regions];
    newRegions[index].intStatusId = 2; // 2 es delete
    if (newRegions[index].intRegionId !== 0) {
      try {
        deleteCarrierRegion(newRegions[index].intRegionId)
      }
      catch (error: any) {
        toast.error(error.title, { duration: 4000 })
      }
    }
    setRegions(newRegions);
  }

  const handleSeeRegion = (index: number) => {
    navigate('/carriers/regions', { state: { data: regions[index] } })
  }

  useEffect(() => {
    const getPkgTypes = async (carrierId: number) => {
      if (currentMode !== 'add') {
        const packages = await getPackagesTypesByCarrier(carrierId)
        logger.log('packages', packages)
        setPackageTypes(packages)
      } else {
        setPackageTypes([])
      }
    }
    getPkgTypes(initialForm.intCarrierId)
  }, [initialForm.intCarrierId])

  useEffect(() => {
    const getRegions = async (carrierId: number) => {
      if (currentMode !== 'add') {
        const regions = await getCarrierRegions(carrierId)
        logger.log('regions', regions)
        setRegions(regions)
      } else {
        setRegions([])
      }
    }
    getRegions(initialForm.intCarrierId)
  }
    , [initialForm.intCarrierId])

  return (
    <>
      <Card sx={{ p: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 6 }}>
          <Typography variant='h6'>
            {currentMode === 'view' ? t('VIEW_CARRIER') : data ? t('EDIT_CARRIER') : t('ADD_CARRIER')}
          </Typography>
        </Box>
        <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} mt={1} rowGap={2}>
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth sx={{}} data-cy="name-courier">
                <Controller
                  name='vchName'
                  control={control}
                  render={({ field: { ...field }, fieldState }) => (
                    <TextField
                      variant='outlined'
                      {...field}
                      InputProps={{ readOnly: currentMode === 'view' }}
                      label={t('NAME')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth sx={{}} data-cy="legacy-number-courier">
                <Controller
                  name='vchLegacyNumber'
                  control={control}
                  render={({ field: { ...field }, fieldState }) => (
                    <TextField
                      variant='outlined'
                      InputProps={{ readOnly: currentMode === 'view' }}
                      {...field}
                      label={t('LEGACY_NUMBER')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SelectCouriers
                disabled={currentMode === 'view'}
                label="Courier Parent"
                defaultCourier={parentCarrier ? parentCarrier : initialForm.intCarrierParentId}
                handleSelected={parentSelected} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth sx={{}} data-cy="json-account-courier">
                <Controller
                  name='vchAccountDefaultData'
                  control={control}
                  render={({ field: { ...field }, fieldState }) => (
                    <TextField
                      multiline
                      rows={5}
                      variant='outlined'
                      InputProps={{ readOnly: currentMode === 'view' }}
                      {...field}
                      label={'Json Account Data'}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth sx={{}} data-cy="url-data-account-courier">
                <Controller
                  name='vchUrlAccessDataAccount'
                  control={control}
                  render={({ field: { ...field }, fieldState }) => (
                    <TextField
                      variant='outlined'
                      InputProps={{ readOnly: currentMode === 'view' }}
                      {...field}
                      label={'Url Access Data Account'}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <GenericButton
                disabled={currentMode === 'view'}
                onClick={() => modalLogoUploader.current?.open()}
              >
                <Typography color={'white'}>{t('UPLOAD_LOGO')}</Typography>
              </GenericButton>
              {fileLogo && showIfValidImage(fileLogo) && (
                <BoxPreviewImage
                  image={fileLogo}
                  heightBox={80}
                  showActions={currentMode === 'view' ? false : true}
                  handleIconAction={(action: string) => {
                    if (action === 'IconDelete') setFileLogo(null)
                  }}
                />

              )}
            </Grid>
            {currentMode !== 'add' && (
              <Grid item xs={12} lg={4} display='flex' alignItems='center'>
                <Typography variant='body2'>{t('STATUS')}</Typography>
                <Controller
                  name='status'
                  control={control}
                  render={({ field: { ...field } }) => (
                    <Switch
                      defaultChecked={initialForm.intStatusId === 1}
                      {...field}
                      disabled={currentMode === 'view'}
                    />
                  )}
                />
              </Grid>
            )}

            {false && (
              <Grid item xs={12} lg={1}>
                <Typography variant='body2'>{t('IS_ACTIVE')}</Typography>
                <Controller
                  name='isActive'
                  control={control}
                  render={({ field: { ...field } }) => (
                    <Switch
                      defaultChecked={initialForm.intStatusId === 1}
                      {...field}
                      readOnly={currentMode === 'view'}
                    />
                  )}
                />
              </Grid>
            )}

          </Grid>          <Box sx={{ margin: 'auto', padding: 2 }}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Tabs value={currentTab} onChange={handleTabChange} aria-label="Tabs">
                <Tab value="packageTypes" label="Package Types" />
                <Tab value="regions" label="Regions" />
              </Tabs>

              <Grid item xs={12}>
                {(currentMode === 'view') ?
                  (currentTab === 'packageTypes') ? (
                    <Grid container justifyContent='space-between' alignItems='center'>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align='center'>Package Type ID</TableCell>
                              <TableCell align='center'>Name</TableCell>
                              <TableCell align='center'>Source ID</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {packageTypes.map((type, index) => (
                              (type.intStatusId !== 2) &&
                              <TableRow key={index}>
                                <TableCell align='center'>
                                  <Typography>{type.intCarrierPackageTypeId}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                  <Typography>{type.vchName}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                  <Typography>{type.vchSourceId}</Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  ) : (
                    <Grid container justifyContent='space-between' alignItems='center'>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align='center'>Region ID</TableCell>
                              <TableCell align='center'>Name</TableCell>
                              <TableCell align='center'>Country</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {regions.map((region, index) => (
                              <TableRow key={index}>
                                <TableCell align='center'>
                                  <Typography>{region.intRegionId}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                  <Typography>{region.vchName}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                  <Typography>{region.vchCountryName}</Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  ) :
                  (currentTab === 'packageTypes') ? (
                    <Grid container justifyContent='space-between' alignItems='center'>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align='center'>Package Type ID</TableCell>
                              <TableCell align='center'>Name</TableCell>
                              <TableCell align='center'>Source ID</TableCell>
                              <TableCell>Delete</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {packageTypes.map((type, index) => (
                              (type.intStatusId !== 2) &&
                              <TableRow key={index}>
                                <TableCell align='center'>
                                  <Typography>{type.intCarrierPackageTypeId}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                  <TextField
                                    value={type.vchName}
                                    onChange={(e) => {
                                      const newTypes = [...packageTypes];
                                      newTypes[index].vchName = e.target.value;
                                      setPackageTypes(newTypes);
                                    }}
                                  />
                                </TableCell>
                                <TableCell align='center'>
                                  <TextField
                                    value={type.vchSourceId || ''}
                                    onChange={(e) => {
                                      const newTypes = [...packageTypes];
                                      newTypes[index].vchSourceId = e.target.value;
                                      setPackageTypes(newTypes);
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <IconButton onClick={() => handleDeletePackageType(index)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Button
                        variant='outlined'
                        color='primary'
                        sx={{
                          mt: 2,
                          marginLeft: 'auto',
                          marginRight: 'auto'
                        }}
                        onClick={handleAddPackageType}
                      >
                        <Typography variant='button'>Add Package Type</Typography>
                      </Button>
                    </Grid>
                  ) : (
                    <Grid container justifyContent='space-between' alignItems='center'>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align='center'>Region ID</TableCell>
                              <TableCell align='center'>Name</TableCell>
                              <TableCell align='center'>Country</TableCell>
                              <TableCell align='center'>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {regions.map((region, index) => (
                              (region.intStatusId !== 2) &&
                              <TableRow key={index}>
                                <TableCell align='center'>
                                  <Typography>{region.intRegionId}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                  <TextField
                                    label="Name"
                                    value={region.vchName}
                                    onChange={(e) => {
                                      const newRegions = [...regions];
                                      newRegions[index].vchName = e.target.value;
                                      setRegions(newRegions);
                                    }}
                                  />
                                </TableCell>
                                <TableCell align='center'>
                                  <AutocompleteCountry
                                    onSelectCountry={(country: any) => {
                                      const newRegions = [...regions];
                                      newRegions[index].intCountryId = country.id;
                                      setRegions(newRegions);
                                    }}
                                    disabled={false}
                                    defaultValue={region.intCountryId}
                                  />
                                </TableCell>
                                <TableCell align='center'>
                                  {(regions[index].intRegionId !== 0) ? (
                                    <IconButton onClick={() => handleSeeRegion(index)}>
                                      <VisibilityIcon />
                                    </IconButton>
                                  ) : (
                                    <IconButton disabled>
                                      <VisibilityIcon />
                                    </IconButton>
                                  )}
                                  <IconButton onClick={() => handleDeleteRegion(index)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Button
                        variant='outlined'
                        color='primary'
                        sx={{
                          mt: 2,
                          marginLeft: 'auto',
                          marginRight: 'auto'
                        }}
                        onClick={handleAddRegion}
                      >
                        <Typography variant='button'>Add Region</Typography>
                      </Button>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Box>
          <Stack flexDirection='row' justifyContent='flex-start' mt={3} columnGap={5}>
            {currentMode !== 'add' && (
              <GenericButton
                onClick={() => {
                  if (currentMode === 'edit') setCurrentMode('view')
                  else setCurrentMode('edit')
                }}
              >
                {currentMode === 'view' ? t('Edit courier') : t('View courier')}
              </GenericButton>)
            }
            {currentMode === 'view' && (
              <GenericButton
                onClick={() => navigate('/carriers/services-editor', { state: { data: data } })}
              >
                {t('SERVICE_EDITOR')}
              </GenericButton>
            )}
            {currentMode !== 'view' && (
              <LoadingButton
                fullWidth
                data-cy="save-btn-courier"
                variant='contained'
                type='submit'
                sx={{ width: '208px', color: 'white' }}
                loading={isLoading}
              >
                {t('SAVE')}
              </LoadingButton>
            )}
          </Stack>
        </form>

        <DialogGeneric ref={modalLogoUploader} title={t('UPLOAD_LOGO')} styleBoxContent={{}}>
          <FileUploadImageSingle
            selectedImage={fileLogo as File}
            handleFileImageUploaded={(file: File | null) => setFileLogo(file)}
          />
        </DialogGeneric>
      </Card>
    </>
  )
}

export default CarrierAddOrEdit

import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type State = {
  carrierId: number | null
  customerId: number | null
  carrierName: string | null
  customerName: string | null
}

type Actions = {
  reset: () => void
  setCarrierId: (carrierId: number | null) => void
  setCustomerId: (customerId: number | null) => void
  setCustomerName: (customerName: string | null) => void
  setCarrierName: (carrierName: string | null) => void
}

const initialState: State = {
  carrierId: null,
  customerId: null,
  carrierName: null,
  customerName: null
}

const reportsStore = create<State & Actions>()(
  persist(
    (set) => ({
      ...initialState,
      setCarrierId: (carrierId: number | null) => {
        set({ carrierId: carrierId })
      },
      setCustomerId: (customerId: number | null) => {
        set({ customerId: customerId })
      },
      setCustomerName: (customerName: string | null) => {
        set({ customerName: customerName })
      },
      setCarrierName: (carrierName: string | null) => {
        set({ carrierName: carrierName })
      },
      reset: () => {
        set(initialState)
      }
    }),

    {
      name: 'reports-store'
    }
  )
)

if (process.env.NODE_ENV === 'development') mountStoreDevtool('reports-store', reportsStore)

export default reportsStore

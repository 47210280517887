import React, { useState } from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import format from 'date-fns/format'
import carrierStore from 'src/zustand/carrier'

import 'react-datepicker/dist/react-datepicker.css' // Importa los estilos de react-datepicker

interface PickerProps {
  label?: string
  selectedDate: Date | null
}

const RateDatePicker = ({
  popperPlacement,
  startEndDate,
  setSelectedDateStart,
  selectedDateStart
}: {
  popperPlacement: ReactDatePickerProps['popperPlacement']
  startEndDate: string
  setSelectedDateStart?: (date: Date) => void
  selectedDateStart?: Date | null
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const { setstartDateRateSheet, setendDateRateSheet } = carrierStore(state => ({
    setstartDateRateSheet: state.setstartDateRateSheet,
    setendDateRateSheet: state.setendDateRateSheet,
    setCarrierRateSheet: state.setCarrierRateSheet
  }))

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 19)

      if (startEndDate === 'start') {
        setstartDateRateSheet(formattedDate)
        setSelectedDate(date)
        if (setSelectedDateStart) setSelectedDateStart(date)
      } else {
        setendDateRateSheet(formattedDate)
        setSelectedDate(date)
        if (setSelectedDateStart) setSelectedDateStart(date)
      }
    }
  }

  const CustomInput = React.forwardRef((props: PickerProps, ref) => {
    const value = selectedDate ? format(selectedDate, 'MM/dd/yyyy') : ''

    return (
      <TextField
        style={{ margin: '0', width: '100%' }}
        inputRef={ref}
        label={props.label || ''}
        {...props}
        value={value}
      />
    )
  })

  return (
    <Box sx={{ minWidth: '100%', width: '100%' }} className='demo-space-x'>
      <DatePicker
        wrapperClassName={'w-100'}
        popperProps={{ strategy: 'fixed' }}
        selected={selectedDate}
        id='single-date-picker'
        minDate={selectedDateStart}
        onChange={handleDateChange}
        popperPlacement={popperPlacement}
        customInput={<CustomInput selectedDate={selectedDate} />}
      />
    </Box>
  )
}

export default RateDatePicker

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { RateSheetVersionsGetModel } from 'src/types/models/CarrierModel'
import { IGenericResponse } from 'src/types/response/response'

import { createCarrierRate } from '.'

export const useCreateShopRateMutationKey = (params: unknown) => {
  return ['createShopRate', params]
}

interface IParams {
  serviceId: number
  measureId: number | string
  carrierRateId?: number
}

export const useCreateCarrierRate = (
  options?: Partial<UseMutationOptions<AxiosResponse<IGenericResponse<RateSheetVersionsGetModel>>, unknown, IParams>>
) => {
  return useMutation({
    mutationFn: (params: IParams) => createCarrierRate(params),
    ...options
  })
}

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { CountryCacheGet, getCountryCache } from 'src/@http/address'
// import { UserGetModel /*, UserModel */ } from 'src/types/models/userModels/'
// import { logger } from 'src/utils/Logger'
// import appStore from 'src/zustand/app'

interface AutocompleteCountryProps {
  onSelectCountry: (country: any) => void
  disabled: boolean
  defaultValue: number
}

const valueInitial: CountryCacheGet = {
  description: '',
  id: 0,
  isActive: false,
  lastModifiedDate: 'null'
}

const AutocompleteCountry = ({ onSelectCountry, defaultValue, disabled }: AutocompleteCountryProps) => {
  // const [customerList, setCustomerList] = useState<CustomerModel[]>([])
  const [value, setValue] = useState<CountryCacheGet>(valueInitial)
  const [countries, setCountries] = useState<CountryCacheGet[]>([])
  const { t } = useTranslation()
  const handleChange = (event: any, value: any | null) => {
    setValue(value || '')
    onSelectCountry(value || '')
  }

  useEffect(() => {
    getCountryCache()
      .then(res => {
        setCountries(res)
      })
      .catch(err => {
        setCountries([])
      })
  }, [])

  useEffect(() => {
    if (defaultValue !== 0 && countries.length !== 0) {
      const defaultCountry = countries.find(item => item.id === defaultValue)
      if (defaultCountry) {
        setValue(defaultCountry)
      }
    }
  }, [defaultValue, countries])

  return (
    <Autocomplete
      fullWidth
      value={value}
      disabled={disabled}
      onChange={handleChange}
      options={countries}
      getOptionLabel={option => option.description}
      id='autocomplete-disableCloseOnSelect'
      renderInput={params => <TextField {...params} label={t('COUNTRY')} />}
    />
  )
}

export default AutocompleteCountry

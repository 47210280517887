import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, Chip, Typography } from '@mui/material'
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { deleteProfileById } from 'src/@http/profiles'
import { CrudView } from 'src/types/forms/CrudView'
import { ProfileModel } from 'src/types/models/ProfileModel'
import profileStore from 'src/zustand/profile'

import CustomToolbarAdminGrid from '../generic/CustomToolbarAdminGrid'
import { DialogExpose, DialogGeneric } from '../generic/DialogGeneric'
import WrapperSecurity from '../generic/WrapperSeurityObject'
import { IconAction } from '../rateSheets/TableRateSheet'

const propertiesCommon = { flex: 1 }

const TableProfiles = () => {
  const { setProfiles, profiles } = profileStore()
  const { state } = useLocation()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (profiles.length === 0) setProfiles()
  }, [])

  const refreshChanges = (refresh: boolean) => refresh && setProfiles()

  const columns: GridColumns = [
    { field: 'intProfileId', headerName: 'Profile Id', ...propertiesCommon, maxWidth: 100 },
    { field: 'vchName', headerName: 'Name', ...propertiesCommon },
    { field: 'intCreatedUserId', headerName: 'Created by User', ...propertiesCommon, align: 'center' },
    {
      field: 'vchStatusName',
      headerAlign: 'center',
      headerName: 'Status',
      ...propertiesCommon,
      renderCell: params => (
        <Box width='100%' textAlign='center'>
          <Chip label={params.value} />
        </Box>
      )
    },
    {
      field: 'dtmCreatedDate',
      headerName: 'Create Date',
      ...propertiesCommon,
      renderCell: (params: any) => (
        <Typography variant='subtitle2'>
          {params.value ? format(new Date(params.value), 'd, MMM d, yyyy') : '-'}
        </Typography>
      )
    },
    {
      field: 'dtmUpdatedDate',
      headerName: 'Create Date',
      ...propertiesCommon,
      renderCell: (params: any) => (
        <Typography variant='subtitle2'>
          {params.value ? format(new Date(params.value), 'd, MMM d, yyyy') : '-'}
        </Typography>
      )
    },
    {
      field: '',
      headerName: 'Action',
      flex: 1,
      minWidth: 250,
      maxWidth: 250,
      headerAlign: 'center',
      renderCell: ({ row }: GridCellParams<ProfileModel>) => <RowOptions row={row} refreshChanges={refreshChanges} />
    }
  ]

  useEffect(() => {
    if (state?.refresh) {
      setLoading(true)
      refreshChanges(true)
      setLoading(false)
    }
  }, [state?.refresh])

  return (
    <Box>
      <Box textAlign='end' my={2}>
        <WrapperSecurity securityKey='add_profile_button'>
          <Link to={'add'} state={{ mode: CrudView.ADD }}>
            <Button variant='contained'>
              <Typography color={'white'}>{t('ADD_NEW_PROFILE')}</Typography>
            </Button>
          </Link>
        </WrapperSecurity>
      </Box>
      <DataGrid
        checkboxSelection
        rows={profiles === null ? [] : profiles}
        columns={columns}
        autoHeight
        getRowId={role => role.id}
        loading={loading}
        components={{ Toolbar: CustomToolbarAdminGrid }}
        pageSize={5}
      />
    </Box>
  )
}

type RowProps = {
  row: ProfileModel
  refreshChanges: (refresh: boolean) => void
}

const RowOptions = ({ row, refreshChanges }: RowProps) => {
  const { t } = useTranslation()
  const saveText = t('DELETE')
  const modalDeleteRole = useRef<DialogExpose>(null)
  const navigate = useNavigate()

  const handleClick = (mode: string) => {
    if (mode === 'Edit') {
      navigate('edit', { state: { mode: CrudView.VIEW, data: row } })
    } else if (mode === 'Delete') {
      modalDeleteRole.current?.open()
    }
  }

  const handleDeleteRole = async () => {
    modalDeleteRole.current?.close()
    await deleteProfileById(row.intProfileId)
      .then(() => {
        toast.success(t('DELETED_SUCCESSFULLY'))
        refreshChanges(true)
      })
      .catch(error => toast.error(error.DescriptionError))
  }

  return (
    <>
      <Box width='100%' display='flex' justifyContent='center'>
        <WrapperSecurity securityKey='edit_profile_button'>
          <IconAction title={t('EDIT')} icon='mdi:pencil' onClick={() => handleClick('Edit')} />
        </WrapperSecurity>
        <WrapperSecurity securityKey='delete_profile_button'>
          <IconAction title={t('DELETE')} icon='ic:outline-delete' color='error' onClick={() => handleClick('Delete')} />
        </WrapperSecurity>
      </Box>
      <DialogGeneric ref={modalDeleteRole} title={'DELETE'} confirmText={saveText} confirmFunc={handleDeleteRole}>
        {t('CONFIRM_DELETE_TEXT')}
      </DialogGeneric>
    </>
  )
}

export default TableProfiles

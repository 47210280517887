// ** MUI Import
import Grid from '@mui/material/Grid'

// ** Types Imports
// ** Icon Imports
// import { AccountCarrierModel } from 'src/types/models/AccountCarriersModels'
// ** Custom Components Imports
import CardStatisticsHorizontal from './CardLabelAccountServer'

interface Props {
  data: any[]
}

const CardStatsHorizontalServer = ({ data }: Props) => {
  if (data) {
    return (
      <Grid container spacing={6}>
        {data.map((item: any, index: number) => {
          return (
            <Grid item xs={12} md={4} sm={6} key={index}>
              <CardStatisticsHorizontal
                {...item}
              />
            </Grid>
          )
        })}
      </Grid>
    )
  } else {
    return null
  }
}

export default CardStatsHorizontalServer

import { Component } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5map from '@amcharts/amcharts5/map'
// import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5geodata_usaLow from '@amcharts/amcharts5-geodata/usaLow'
// import { Card } from '@mui/material/'
// import am4geodata_usaLow from "@amcharts/amcharts4-geodata/usaLow";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

interface AmchartProps {
  color: string
}

class AmchartComponent extends Component<AmchartProps> {
  root: any
  regionalSeries: any
  currentSeries: any
  polygonSeries: any
  chart: any
  zoomOut: any

  constructor(props: any) {
    super(props)
    // this.root = null
    this.regionalSeries = {}
    // this.currentSeries= null
    // this.chart= null
    // this.zoomOut = null
  }

  componentDidMount() {
    const root = am5.Root.new('chartdiv')
    am5.net.load('https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/TargetStores.json').then(result => {
      const stores = am5.JSONParser.parse(result.response !== undefined ? result.response : '')
      this.setupStores(stores)
    })

    this.chart = root.container.children.push(am5map.MapChart.new(root, {}))

    this.polygonSeries = this.chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_usaLow
      })
    )

    this.polygonSeries.mapPolygons.template.setAll({
      tooltipText: '{name}'
    })

    this.polygonSeries.mapPolygons.template.states.create('hover', {
      fill: am5.color(0x297373)
    })
    this.polygonSeries.mapPolygons.template.set('fill', am5.color(this.props.color ?? '#000000'))
    this.zoomOut = root.tooltipContainer.children.push(
      am5.Button.new(root, {
        x: am5.p100,
        y: 0,
        centerX: am5.p100,
        centerY: 0,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 12,
        paddingRight: 12,
        dx: -20,
        dy: 20,
        themeTags: ['zoom'],
        icon: am5.Graphics.new(root, {
          themeTags: ['button', 'icon'],
          strokeOpacity: 0.7,
          draw: function (display) {
            display.moveTo(0, 0)
            display.lineTo(12, 0)
          }
        })
      })
    )
    this.zoomOut.get('background')?.setAll({
      cornerRadiusBL: 40,
      cornerRadiusBR: 40,
      cornerRadiusTL: 40,
      cornerRadiusTR: 40
    })
    this.zoomOut.events.on('click', () => {
      if (this.currentSeries) {
        this.currentSeries.hide()
      }
      this.chart.goHome()
      this.zoomOut.hide()
      this.currentSeries = this.regionalSeries.US.series
      this.currentSeries.show()
    })
    this.zoomOut.hide()

    // chart.setData(am5geodata_usaLow);

    // ... chart code goes here ...

    this.root = root
  }

  // createSeries (heatfield:any) {
  //   console.log('HeatField', heatfield)

  // }

  createSeries(heatfield: any) {
    // Create point series
    const pointSeries = this.chart.series.push(
      am5map.MapPointSeries.new(this.root, {
        valueField: heatfield,
        calculateAggregates: true
      })
    )

    // Add store bullet
    const circleTemplate: any = am5.Template.new(this.root)
    pointSeries.bullets.push(() => {
      const container = am5.Container.new(this.root, {})

      const circle = container.children.push(
        am5.Circle.new(
          this.root,
          {
            radius: 10,
            fill: am5.color(0x000000),
            fillOpacity: 0.7,
            cursorOverStyle: 'pointer',
            tooltipText: '{name}:\n[bold]{stores} shipments[/]'
          },
          circleTemplate
        )
      )

      const label = container.children.push(
        am5.Label.new(this.root, {
          text: '{stores}',
          fill: am5.color(0xffffff),
          populateText: true,
          centerX: am5.p50,
          centerY: am5.p50,
          textAlign: 'center'
        })
      )

      // Set up drill-down
      circle.events.on('click', (ev: any) => {
        // Determine what we've clicked on
        const data = ev.target.dataItem.dataContext

        // No id? Individual store - nothing to drill down to further
        if (!data.target) {
          return
        }

        // Create actual series if it hasn't been yet created
        if (!this.regionalSeries[data.target].series) {
          this.regionalSeries[data.target].series = this.createSeries('count')
          this.regionalSeries[data.target].series.data.setAll(data.markerData)
        }

        // Hide current series
        if (this.currentSeries) {
          this.currentSeries.hide()
        }

        // Control zoom
        if (data.type == 'state') {
          const statePolygon: any = this.getPolygon('US-' + data.state)
          this.polygonSeries.zoomToDataItem(statePolygon.dataItem)
        } else if (data.type == 'city') {
          this.chart.zoomToGeoPoint(
            {
              latitude: data.geometry.coordinates[1],
              longitude: data.geometry.coordinates[0]
            },
            64,
            true
          )
        }
        this.zoomOut.show()

        // Show new targert series
        this.currentSeries = this.regionalSeries[data.target].series
        this.currentSeries.show()
      })

      return am5.Bullet.new(this.root, {
        sprite: container
      })
    })

    // Add heat rule for circles
    pointSeries.set('heatRules', [
      {
        target: circleTemplate,
        dataField: 'value',
        min: 10,
        max: 30,
        key: 'radius'
      }
    ])

    // Set up drill-down
    // TODO

    return pointSeries
  }

  getPolygon(id: any) {
    let found
    this.polygonSeries.mapPolygons.each((polygon: any) => {
      if (polygon.dataItem.get('id') == id) {
        found = polygon
      }
    })

    return found
  }

  setupStores(data: any) {
    console.log('Data store', data)

    // Init country-level series
    this.regionalSeries.US = {
      markerData: [],
      series: this.createSeries('stores')
    }

    // Set current series
    this.currentSeries = this.regionalSeries.US.series

    // Process data
    am5.array.each(data.query_results, (storeData: any) => {
      // Get store data

      const store: any = {
        state: storeData.MAIL_ST_PROV_C,
        long: am5.type.toNumber(storeData.LNGTD_I),
        lat: am5.type.toNumber(storeData.LATTD_I),
        location: storeData.co_loc_n,
        city: storeData.mail_city_n,
        count: am5.type.toNumber(storeData.count)
      }

      // Process state-level data
      if (this.regionalSeries[store.state] == undefined) {
        const statePolygon: any = this.getPolygon('US-' + store.state)
        if (statePolygon) {
          const centroid = statePolygon.visualCentroid()

          // Add state data
          this.regionalSeries[store.state] = {
            target: store.state,
            type: 'state',
            name: statePolygon.dataItem.dataContext.name,
            count: store.count,
            stores: 1,
            state: store.state,
            markerData: [],
            geometry: {
              type: 'Point',
              coordinates: [centroid.longitude, centroid.latitude]
            }
          }
          this.regionalSeries.US.markerData.push(this.regionalSeries[store.state])
        } else {
          // State not found
          return
        }
      } else {
        this.regionalSeries[store.state].stores++
        this.regionalSeries[store.state].count += store.count
      }

      // Process city-level data
      if (this.regionalSeries[store.city] == undefined) {
        this.regionalSeries[store.city] = {
          target: store.city,
          type: 'city',
          name: store.city,
          count: store.count,
          stores: 1,
          state: store.state,
          markerData: [],
          geometry: {
            type: 'Point',
            coordinates: [store.long, store.lat]
          }
        }
        this.regionalSeries[store.state].markerData.push(this.regionalSeries[store.city])
      } else {
        this.regionalSeries[store.city].stores++
        this.regionalSeries[store.city].count += store.count
      }

      // Process individual store
      this.regionalSeries[store.city].markerData.push({
        name: store.location,
        count: store.count,
        stores: 1,
        state: store.state,
        geometry: {
          type: 'Point',
          coordinates: [store.long, store.lat]
        }
      })
    })
    console.log(this.regionalSeries.US.markerData)
    this.regionalSeries.US.series.data.setAll(this.regionalSeries.US.markerData)
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose()
    }
  }

  render() {
    return <div id='chartdiv' style={{ width: '100%', height: '500px' }}></div>
  }
}

export default AmchartComponent

import { useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid'
import CustomChip from 'src/@core/components/mui/chip'
import PageHeader from 'src/@core/components/page-header'
import { deleteShopProduct, getShopProduct } from 'src/@http/product'
import { CrudView } from 'src/types/forms/CrudView'
import { ProductShopResponse } from 'src/types/models/ShopModels'
import customerStore from 'src/zustand/customer'

import { DialogExpose, DialogGeneric } from '../components/generic/DialogGeneric'
import ServerDataGrid, { ServiceDataGridProps } from '../components/generic/ServerDataGrid'
import ShopProductForm from '../components/products-editor/ShopProductForm'
import { IconAction } from '../components/rateSheets/TableRateSheet'

const columns: GridColumns = [
  {
    field: 'intShopProductId',
    headerName: 'Product Id',
    minWidth: 120
  },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 350,
    maxWidth: 350
  },
  {
    field: 'vchShopProductTypeName',
    headerName: 'Product Type',
    minWidth: 150
  },
  {
    field: 'vchCustomerName',
    headerName: 'Customer',
    minWidth: 100
  },
  {
    field: 'vchStatusName',
    headerName: 'Status',
    minWidth: 150,
    renderCell: params => (
      <Stack width='100%' display='flex' flexDirection='row' justifyContent='center'>
        <CustomChip label={params.value} skin='light' color={params.value === 'Active' ? 'success' : 'error'} />
      </Stack>
    )
  },
  {
    field: 'vchSourceId',
    headerName: 'Source',
    minWidth: 100
  },
  {
    field: 'vchPartNumber',
    headerName: 'Part Number',
    minWidth: 120
  },
  {
    field: 'decWeight',
    headerName: 'Weight',
    minWidth: 120
  },
  /*
  {
    field: 'Price',
    headerName: 'Price',
    minWidth: 120
  },
  */
  {
    field: '',
    headerName: 'Action',
    headerAlign: 'center',
    flex: 1,
    minWidth: 250,
    maxWidth: 250,
    renderCell: params => <RowOptions row={params.row} />
  }
]

const RowOptions = ({ row }: { row: ProductShopResponse }) => {
  const { t } = useTranslation()
  const dialogRef = useRef<DialogExpose>(null)
  const [show, setShow] = useState<boolean>(false)
  const { refreshShopProduct } = customerStore()

  const handleDelete = () => {
    dialogRef.current?.open().then(async confirm => {
      if (confirm)
        try {
          await deleteShopProduct(row.intShopProductId)
          toast.success(t('DELETED_SUCCESS') + '! 👌', { duration: 4000 })
          refreshShopProduct()
        } catch (err: any) {
          toast.error(err.message + '🙄', { duration: 4000 })
        }
    })
  }

  return (
    <Stack display='flex' flexDirection='row' width='100%' justifyContent='center'>
      <IconAction
        title={t('EDIT')}
        icon='ep:edit'
        color='success'
        onClick={() => {
          setShow(true)
        }}
      />
      <IconAction title={t('DELETE')} icon='ic:outline-delete' color='error' onClick={handleDelete} />

      <DialogGeneric title='DELETE' ref={dialogRef} confirmText='CONFIRM' cancelText='CANCEL'>
        {t('PRODUCT_CONFIRMATION_DELETION_MESSAGE')}
      </DialogGeneric>
      <DialogGeneric
        show={show}
        hideActions={true}
        closeFunc={() => {
          setShow(false)
        }}
        maxWidth={912}
        title={t('EDIT_PRODUCT')}
      >
        <Box sx={{ pt: 4 }}>
          <ShopProductForm
            data={row}
            mode={CrudView.EDIT}
            closeDialog={() => {
              setShow(false)
            }}
            successFunc={() => {
              refreshShopProduct()
            }}
          />
        </Box>
      </DialogGeneric>
    </Stack>
  )
}

const ProductEditor = () => {
  const { customerId } = useParams()
  const { t } = useTranslation()
  const { state } = useLocation()
  const [show, setShow] = useState<boolean>(false)
  const { setShopProductsProps, customerProducts, setShopProducts } = customerStore()
  const store = {
    getRows: customerProducts,
    setRows: setShopProducts
  }

  const service = async ({ currectPage, pageSize }: ServiceDataGridProps) => {
    const customerIdd = customerId && /^-?\d+$/.test(customerId) ? parseInt(customerId) : 0
    const response = await getShopProduct({
      customerId: customerIdd,
      PageSize: pageSize,
      CurrentPage: currectPage
    })
    setShopProductsProps({ currectPage, pageSize, customerId: customerIdd })

    return response
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <PageHeader
          title={
            <Typography variant='h5' mb={5}>
              {t('PRODUCT_EDITOR')}
            </Typography>
          }
        />
        <Stack flexDirection='row' justifyContent='space-between' alignItems='center' mb={3}>
          <Typography variant='body2'>
            <b>Customer</b>: {state ? state.data.vchName : '-'}
          </Typography>
          <Button
            variant='contained'
            onClick={() => {
              setShow(true)
            }}
          >
            {t('ADD_PRODUCT')}
          </Button>
        </Stack>
        <Card>
          <ServerDataGrid columns={columns} service={service} store={store} />
        </Card>
        <DialogGeneric
          show={show}
          hideActions={true}
          closeFunc={() => {
            setShow(false)
          }}
          maxWidth={912}
          title={t('ADD_PRODUCT')}
        >
          <Box sx={{ pt: 4 }}>
            <ShopProductForm
              customerId={customerId && /^-?\d+$/.test(customerId) ? parseInt(customerId) : 0}
              mode={CrudView.ADD}
              closeDialog={() => {
                setShow(false)
              }}
            />
          </Box>
        </DialogGeneric>
      </Grid>
    </Grid>
  )
}

export default ProductEditor

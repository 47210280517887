/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, Ref, useEffect, useImperativeHandle, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Card, Grid, SxProps, TextField, Theme } from '@mui/material'
import AutocompleteCity from 'src/pages/components/customer/AutocompleteCity'
import AutocompleteCountry from 'src/pages/components/customer/AutocompleteCountry'
import AutocompleteState from 'src/pages/components/customer/AutocompleteState'
import { ShopModel } from 'src/types/models/ShopModels'

interface AccountInformationFormProps {
  onChange: (addresses: any) => void
  fieldsToShow?: string[]
  widthAddressForm?: number
  defaultValues?: ShopModel | null
  disabled: boolean
  styleCard?: SxProps<Theme>
}

interface FormValues {
  contact: string
  company: string
  telephone: string
  email: string
  city: string
  country: string
  state: string
  postCode: string
  address1: string
  address2: string
}

const init = {
  contact: '',
  company: '',
  telephone: '',
  email: '',
  city: '',
  country: '',
  state: '',
  postCode: '',
  address1: '',
  address2: ''
}

const AccountInformationForm = forwardRef(
  (
    { defaultValues, disabled, onChange, fieldsToShow = Object.keys(init), styleCard }: AccountInformationFormProps,
    ref: Ref<{
      validate: () => Promise<boolean>
    }>
  ) => {
    const { t } = useTranslation()

    const {
      register,
      control,
      watch,
      getValues,
      formState: { errors },
      trigger
    } = useForm<FormValues>({
      defaultValues: defaultValues
        ? {
            contact: defaultValues.vchTelephone,
            telephone: defaultValues.vchTelephone,
            email: defaultValues.vchEmail,
            city: defaultValues.addressObj.intCityId?.toString(),
            country: defaultValues.addressObj.intCountryId?.toString(),
            state: defaultValues.addressObj.intStateId?.toString(),
            postCode: defaultValues.addressObj.vchPostCode,
            address1: defaultValues.addressObj.vchAddress,
            address2: defaultValues.addressObj.vchAdditionalInfoAddress
          }
        : init
    })

    const emailValue = watch('email')
    const telephoneValue = watch('telephone')

    const atLeastOneFieldIsFilled = useMemo(() => {
      return emailValue !== '' || telephoneValue !== ''
    }, [emailValue, telephoneValue])

    useEffect(() => {
      onChange(getValues())
    }, [
      watch('contact'),
      watch('company'),
      watch('telephone'),
      watch('email'),
      watch('city'),
      watch('country'),
      watch('state'),
      watch('postCode'),
      watch('address1'),
      watch('address2')
    ])

    const validate = async () => {
      const validContact = await trigger('telephone')
      const validEmail = await trigger('email')

      return validContact && validEmail
    }

    useImperativeHandle(ref, () => ({
      validate
    }))

    return (
      <form>
        <Card sx={styleCard ? styleCard : { mt: 5, p: 2 }}>
          <Grid container>
            {fieldsToShow.includes('contact') && (
              <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                <TextField fullWidth disabled={disabled} id='contact' label='Contact' {...register('contact')} />
              </Grid>
            )}
            {fieldsToShow.includes('company') && (
              <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                <TextField fullWidth disabled={disabled} id='company' label='Company' {...register('company')} />
              </Grid>
            )}
            {fieldsToShow.includes('telephone') && (
              <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                <TextField
                  fullWidth
                  disabled={disabled}
                  id='telephone'
                  label='Telephone'
                  error={!!errors.telephone}
                  helperText={errors?.telephone?.message?.toString()}
                  {...register('telephone', {
                    required: atLeastOneFieldIsFilled || (t('REQUIRED_FIELD') as string)
                  })}
                />
              </Grid>
            )}
            {fieldsToShow.includes('email') && (
              <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                <TextField
                  fullWidth
                  id='email'
                  disabled={disabled}
                  label='Email'
                  error={!!errors.email}
                  helperText={errors?.email?.message?.toString()}
                  {...register('email', {
                    required: atLeastOneFieldIsFilled || (t('REQUIRED_FIELD') as string)
                  })}
                />
              </Grid>
            )}
            {fieldsToShow.includes('country') && (
              <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                <Controller
                  name='country'
                  control={control}
                  render={({ field }) => (
                    <AutocompleteCountry
                      defaultValue={Number(field.value)}
                      disabled={disabled}
                      onSelectCountry={country => field.onChange(country.id) /* console.log('Country', countryId) */}
                    />
                  )}
                />
              </Grid>
            )}

            {fieldsToShow.includes('state') && (
              <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                <Controller
                  name='state'
                  control={control}
                  render={({ field }) => (
                    <AutocompleteState
                      countryId={1}
                      defaultValue={Number(field.value)}
                      disabled={disabled}
                      onSelectState={state => field.onChange(state.id) /*console.log('State', stateId)}*/}
                    />
                  )}
                />
              </Grid>
            )}
            {fieldsToShow.includes('city') && (
              <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                <Controller
                  name='city'
                  control={control}
                  render={({ field }) => (
                    <AutocompleteCity
                      countryId={1}
                      disabled={disabled}
                      stateId={1}
                      defaultValue={Number(field.value)}
                      onSelectCity={city => field.onChange(city.id) /*console.log('City', cityId)} */}
                    />
                  )}
                />
              </Grid>
            )}
            {fieldsToShow.includes('state') && (
              <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                <TextField fullWidth disabled={disabled} id='postcode' label='Postcode' {...register('postCode')} />
              </Grid>
            )}
            <Grid item xs={12} lg={6} sx={{ p: 2 }}>
              <TextField fullWidth disabled={disabled} id='address1' label='Address1' {...register('address1')} />
            </Grid>
            <Grid item xs={12} lg={6} sx={{ p: 2 }}>
              <TextField fullWidth disabled={disabled} id='address2' label='Address2' {...register('address2')} />
            </Grid>
          </Grid>
        </Card>
      </form>
    )
  }
)

export default AccountInformationForm

// ** React Imports
import { useState } from 'react'
import { Fab } from '@mui/material'
import Box, { BoxProps } from '@mui/material/Box'
import { blueGrey, grey } from '@mui/material/colors'
// ** MUI Imports
import { styled } from '@mui/material/styles'
import Customizer from 'src/@core/components/customizer'
// ** Icon Imports
import Icon from 'src/@core/components/icon'
import ScrollToTop from 'src/@core/components/scroll-to-top'
// ** Type Import
import { LayoutProps } from 'src/@core/layouts/types'
// ** Theme Config Import
import themeConfig from 'src/configs/themeConfig'

// import { shadeColor } from 'src/utils/misc';
import Footer from './components/shared-components/footer'
// ** Components
import AppBar from './components/vertical/appBar'
import Navigation from './components/vertical/navigation'

console.log('Them config', themeConfig)

const MainContentWrapper = styled(Box)<BoxProps>({
  flexGrow: 1,
  minWidth: 0,
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column'
})

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

const VerticalLayout = (props: LayoutProps) => {
  // ** Props
  const { hidden, settings, children, scrollToTop, footerProps, contentHeightFixed, verticalLayoutProps } = props

  // ** Vars
  const { skin, navHidden, contentWidth } = settings
  const { navigationSize, disableCustomizer, collapsedNavigationSize } = themeConfig
  const navWidth = navigationSize
  const navigationBorderWidth = skin === 'bordered' ? 1 : 0
  const collapsedNavWidth = collapsedNavigationSize

  const VerticalLayoutWrapper = styled('div')({
    height: '100%',
    display: 'flex'
  })
  // const style = {
  //   backgroundImage: settings.mode === 'dark' ? 'url("/images/fondo/fondo2.jpg")' : 'url("/images/fondo/fondo1.png")',
  //   backgroundSize: 'cover'
  // }

  // ** States
  const [navVisible, setNavVisible] = useState<boolean>(false)

  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible)
  const backgroundApplication = () => {
    return {
      background: `linear-gradient(150deg, ${
        settings.mode === 'dark' ? grey[900] : '#F2F3F4' // '#FEF5E7' // shadeColor(vchPrimaryColor, 30)
      } 50%, ${settings.mode === 'dark' ? blueGrey[900] : grey['A200']} 50%)`
    }
  }
  
  return (
    <>
      <VerticalLayoutWrapper sx={backgroundApplication}>
        {/* Navigation Menu */}
        {navHidden && !(navHidden && settings.lastLayout === 'horizontal') ? null : (
          <Navigation
            navWidth={navWidth}
            navVisible={navVisible}
            setNavVisible={setNavVisible}
            collapsedNavWidth={collapsedNavWidth}
            toggleNavVisibility={toggleNavVisibility}
            navigationBorderWidth={navigationBorderWidth}
            navMenuContent={verticalLayoutProps.navMenu.content}
            navMenuBranding={verticalLayoutProps.navMenu.branding}
            menuLockedIcon={verticalLayoutProps.navMenu.lockedIcon}
            verticalNavItems={verticalLayoutProps.navMenu.navItems}
            navMenuProps={verticalLayoutProps.navMenu.componentProps}
            menuUnlockedIcon={verticalLayoutProps.navMenu.unlockedIcon}
            afterNavMenuContent={verticalLayoutProps.navMenu.afterContent}
            beforeNavMenuContent={verticalLayoutProps.navMenu.beforeContent}
            {...props}
          />
        )}
        <MainContentWrapper
          className='layout-content-wrapper'
          sx={{ ...(contentHeightFixed && { maxHeight: '100vh' }) }}
        >
          {/* AppBar Component */}
          <AppBar
            toggleNavVisibility={toggleNavVisibility}
            appBarContent={verticalLayoutProps.appBar?.content}
            appBarProps={verticalLayoutProps.appBar?.componentProps}
            {...props}
          />

          {/* Content */}
          <ContentWrapper
            className='layout-page-content'
            sx={{
              paddingTop: '.5rem',
              ...(contentHeightFixed && {
                overflow: 'hidden',
                '& > :first-of-type': { height: '100%' }
              }),
              ...(contentWidth === 'boxed' && {
                mx: 'auto',
                '@media (min-width:1440px)': { maxWidth: 1440 },
                '@media (min-width:1200px)': { maxWidth: '100%' }
              })
            }}
          >
            {children}
          </ContentWrapper>

          {/* Footer Component */}
          <Footer footerStyles={footerProps?.sx} footerContent={footerProps?.content} {...props} />
        </MainContentWrapper>
      </VerticalLayoutWrapper>

      {/* Customizer */}
      {disableCustomizer || hidden ? null : <Customizer />}

      {/* Scroll to top button */}
      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className='mui-fixed'>
          <Fab style={{color: 'white'}} color='primary' size='small' aria-label='scroll back to top'>
            <Icon icon='mdi:arrow-up' />
          </Fab>
        </ScrollToTop>
      )}
    </>
  )
}

export default VerticalLayout

import { useEffect, useState } from 'react'
import { Theme } from '@emotion/react'
import { SxProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import appStore from 'src/zustand/app'

interface AutocompleteUsersProps {
  onSelectCustomer: (user: any) => void
  label: string
  disabled?: boolean
  userSelected?: any
  sx?: SxProps<Theme>
  size?: 'small' | 'medium'
}

const AutocompleteProps = ({ ...props }: AutocompleteUsersProps) => {
  const { onSelectCustomer, label, userSelected, disabled } = props
  const [value, setValue] = useState<any | null>(null)

  const { setAccountManagers, accountManagers } = appStore()

  const handleChange = (event: any, value: any | null) => {
    onSelectCustomer(value || '')
    setValue(value || null)
  }

  useEffect(() => {
    if (accountManagers.length === 0) setAccountManagers()
  }, [])

  useEffect(() => {
    const user = accountManagers.find(u => u.intAccountManager === userSelected)
    if (user) {
      setValue(user)
    }
  }, [userSelected, accountManagers])

  return (
    <>
      <Autocomplete
        sx={props.sx || undefined}
        size={props.size || 'medium'}
        fullWidth
        onChange={handleChange}
        disabled={disabled}
        options={accountManagers}
        value={value}
        getOptionLabel={option => option.vchFullName}
        id='autocomplete-disableCloseOnSelect'
        renderInput={params => <TextField {...params} label={label} />}
      />
    </>
  )
}

export default AutocompleteProps

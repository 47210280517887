import { postAsync } from 'src/@http'
import type { GetTokenModel } from 'src/types/models/getTokenModel'
import { jsencryptPasswordRSA } from 'src/utils/JSEncryptPassword'

/* Endpoints definition */
const Login = '/v1.0/ControllerAuth/GetToken'
const ForgotPassword = '/forgot-password'
const ValidateCode = '/auth/verifyCode'
const SendCode = '/auth/sendCode'

interface Auth {
  access_token: string
  token_type: string
}

export const validateCode = (code: string): Promise<Auth> => {
  return postAsync(ValidateCode, {
    code: code
  })
}

export const login = (email: string, password: string): Promise<GetTokenModel> => {
  const passwordEncryptedRSA = jsencryptPasswordRSA(password, process.env.REACT_APP_ENCRYPT_PUBLIC_KEY)

  return postAsync(Login, {
    username: email,
    password: passwordEncryptedRSA
  })
}

export const forgotPassword = (email: string, ip: string): Promise<void> => {
  return postAsync(ForgotPassword, {
    email: email,
    ip: ip
  })
}

export const sendCode = (): Promise<void> => {
  return postAsync(SendCode, {})
}

import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Icon } from '@iconify/react'
import { Button, Card, IconButton, Stack } from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { postCustomerInvoicePaginate, postProcessCustomerInvoice, postTotalsByBatch } from 'src/@http/customer-invoice'
import companiesStore from "src/zustand/companies"
import invoiceStore from 'src/zustand/invoice'

import CustomToolbarAdminGrid from '../generic/CustomToolbarAdminGrid'
import WrapperSecurity from '../generic/WrapperSeurityObject'

const columns: GridColumns = [
  {
    flex: 1,
    field: 'intCustomerInvoiceBatchId',
    headerName: 'Batch Id',
    minWidth: 100
  },
  {
    flex: 1,
    field: 'intCountInvoice',
    headerName: 'Invoice count',
    minWidth: 250
  },
  {
    flex: 1,
    field: 'decTotalWeight',
    headerName: 'Total Weight',
    minWidth: 150,
    renderCell: ({ row }) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {parseFloat(row.decTotalWeight).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </div>
    )
  },
  {
    flex: 1,
    field: 'decTotalShipPrice',
    headerName: 'Total Ship Price',
    minWidth: 200,
    renderCell: ({ row }) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {parseFloat(row.decTotalShipCarrierPrice).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </div>
    )
  },
  {
    flex: 1,
    field: 'decTotalShipCarrierPrice',
    headerName: 'Total Ship Courier Price',
    minWidth: 250,
    renderCell: ({ row }) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {parseFloat(row.decTotalShipPrice).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </div>
    )
  },
  {
    flex: 1,
    field: 'decTotalShipPriceAdicional',
    headerName: 'Additional Price',
    minWidth: 150,
    renderCell: ({ row }) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {parseFloat(row.decTotalShipPriceAdicional).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </div>
    )
  },
  {
    field: '',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    maxWidth: 250,
    headerAlign: 'center',
    renderCell: ({ row }: CellType) => (
      <RowOptions
        id={row.id}
        carrierName={row.vchCarrierName}
        intCustomerInvoiceBatchId={row.intCustomerInvoiceBatchId}
      />
    )
  }
]

interface CellType {
  row: any
}

const RowOptions = ({
  intCustomerInvoiceBatchId
}: {
  id: number
  carrierName: string
  intCustomerInvoiceBatchId: number
}) => {
  const handleEyeClick = () => {
    setTabValue('3')
    setBatchId(intCustomerInvoiceBatchId)
    setIsProp(true)
  }

  const { setTabValue, setIsProp, setBatchId } = invoiceStore()

  return (
    <Stack display='flex' flexDirection='row' width='100%' justifyContent='space-between'>
      <IconButton size='small'>
        <Icon icon='material-symbols:delete-outline' />
      </IconButton>
      <IconButton size='small' onClick={handleEyeClick}>
        <Icon icon='mdi:eye' />
      </IconButton>
      <IconButton size='small'>
        <Icon icon='mdi:dots-vertical' />
      </IconButton>
    </Stack>
  )
}

const TableRatesGroupByBatchCompany = () => {
  const { t } = useTranslation()
  const { invoiceFilter, setIsSearching } = invoiceStore()
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const { companyGlobal } = companiesStore()
  const [processing, setProcessing] = useState(false)
  const [rows, setRows] = useState<any[]>([
    {
      intCustomerInvoiceResumeId: 0,
      vchCarrierName: '',
      decTotalWeight: 0,
      intCountInvoice: 0,
      decTotalShipPrice: 0,
      decTotalShipPriceAdicional: 0,
      id: 0
    }
  ])
  const [isLoading, setIsLoading] = useState(false)
  const [cols, setCols] = useState<GridColumns | null>(null)

  const handleSelectionModelChange = (newSelection: any) => {
    setSelectedRows(newSelection)
  }

  const handleProcessInvoices = async () => {
    try {
      setProcessing(true)
      for (const row of selectedRows) {
      const response = await postCustomerInvoicePaginate({ customerFilters: {
        id: -1,
        intId: row,
        customerName: companyGlobal?.vchName ?? null,
        vchCarrierName: null,
        startDate: null,
        endDate: null,
        intStatusId: 1
      },
        PageSize: 9999,
        CurrentPage: 1 }
      )
        const response2 = await postProcessCustomerInvoice(response.list)
        if(response2 == null){
          toast.error('There was an error processing invoices', { duration: 4000 }) 
        }
      } 
      toast.success('Successfully processed invoices', { duration: 4000 })
    } catch (error) {
      toast.error('There was an error processing invoices', { duration: 4000 })
    } finally {
      setProcessing(false)
    }
  }


  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      try {
        const response = await postTotalsByBatch({
          customerName: companyGlobal?.vchName ?? null,
          vchCarrierName: invoiceFilter.carrierName ?? null,
          startDate: invoiceFilter.startDate ?? null,
          endDate: invoiceFilter.endDate ?? null,
          intStatusId: invoiceFilter.intStatusId,
          id: -1,
          intId:
            invoiceFilter.batchId !== null && invoiceFilter.batchId !== undefined && invoiceFilter.batchId !== 0
              ? invoiceFilter.batchId
              : -1
        })
        if (response) {
          setRows(response)
        }
      } catch (error) {
        // console.log(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetch()
    setCols(columns)
    setIsSearching(false)
  }, [invoiceFilter.isSearching])

  return (
    <Card sx={{ mt: 3 }}>
      <Stack
        sx={{ p: 4, flexDirection: { xs: 'column', md: 'row', lg: 'row' } }}
        display='flex'
        justifyContent='flex-end'
      >
        <WrapperSecurity securityKey='process_invoices_btn'>
          <Button
            variant='contained'
            color='primary'
            style={{ color: 'white' }}
            size='small'
            sx={{ marginRight: '10px' }}
            onClick={handleProcessInvoices}
            disabled={processing}
          >
            {processing ? t('PROCESSING') : t('PROCESS_INVOICES')}
          </Button>
        </WrapperSecurity>
      </Stack>
      <DataGrid
        checkboxSelection
        onSelectionModelChange={handleSelectionModelChange}
        selectionModel={selectedRows}
        disableSelectionOnClick
        autoHeight
        loading={isLoading}
        rowsPerPageOptions={[10, 25, 50]}
        rows={rows}
        columns={cols || []}
        getRowHeight={() => 51}
        components={{ Toolbar: CustomToolbarAdminGrid }}
      />
    </Card>
  )
}

export default TableRatesGroupByBatchCompany

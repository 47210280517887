import { useState } from 'react'
import { Icon } from '@iconify/react'
import { Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import FileInput from 'src/pages/components/generic/FileInput'
import TableImportInvoice from 'src/pages/components/invoice/TableImportInvoice'

const Import = () => {
  const [fileSelected, setFileSelected] = useState<File | null>(null)

  return (
    <Grid container>
      <Grid item xs={12} lg={12}>
        <Card sx={{ px: 4, py: 7 }}>
          <Grid container rowGap={3}>
            <Grid sx={{ px: 1 }} item xs={12} lg={4}>
              <FormControl fullWidth>
                <InputLabel id='carrier-select'>Carrier</InputLabel>
                <Select label='Courier' defaultValue='FedEx' labelId='carrier-select'>
                  <MenuItem value='FedEx'>FedEx</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid sx={{ px: 1 }} item xs={12} lg={8}>
              <TextField
                fullWidth
                id='description'
                label='Description'
                value='Meter_Transaction_Detail_Report (24).csv'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid sx={{ px: 1 }} item xs={12} lg={4}>
              <FileInput hiddenNameFile label='Choose File' fullWidth onChange={file => setFileSelected(file)} />
            </Grid>
            <Grid sx={{ px: 1, display: 'flex', alignItems: 'center' }} item xs={12} lg={8}>
              <Typography variant='body2'>{fileSelected?.name || ''}</Typography>
            </Grid>
            <Grid item xs={12} display='flex' justifyContent='center'>
              <Button variant='contained' color='primary' sx={{ '& svg': { mr: 2 } }}>
                <Icon icon='formkit:arrowright' />
                Upload
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ mt: 5, p: 3 }}>
          <Typography variant='h5' mb={4} mt={2} ml={2}>
            Import Invoices Carriers
          </Typography>
          <TableImportInvoice />
        </Card>
      </Grid>
    </Grid>
  )
}

export default Import

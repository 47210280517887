import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { Box, Button, Chip, Typography } from '@mui/material'
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { deleteRole, getRoles } from 'src/@http/role'
import { CrudView } from 'src/types/forms/CrudView'
import { RolModel } from 'src/types/models/RolModel'
import { logger } from 'src/utils/Logger'

import CustomToolbarAdminGrid from '../generic/CustomToolbarAdminGrid'
import { DialogExpose, DialogGeneric } from '../generic/DialogGeneric'
import WrapperSeurityObject from '../generic/WrapperSeurityObject'
import { IconAction } from '../rateSheets/TableRateSheet'

const propertiesCommon = { flex: 1 }

const TableRoles = () => {
  const [roles, setRoles] = useState<RolModel[] | []>([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const getRolesList = async () => {
    setLoading(true)
    try {
      const res: RolModel[] = await getRoles()
      setRoles(res)
      setLoading(false)
      logger.log('res', res)
    } catch (error) {
      setLoading(false)
      // logger.log(error)
    }
  }

  useEffect(() => {
    getRolesList()
  }, [])

  const refreshChanges = (refresh: boolean) => refresh && getRolesList()

  const columns: GridColumns = [
    { field: 'sinRoleId', headerName: 'Role Id', ...propertiesCommon, maxWidth: 100 },
    { field: 'vchName', headerName: 'Name', ...propertiesCommon },
    { field: 'profileName', headerName: 'Profile Name', ...propertiesCommon },
    { field: 'intCreatedUserId', headerName: 'Created by User', ...propertiesCommon, align: 'center' },
    {
      field: 'vchStatusName',
      headerName: 'Status',
      ...propertiesCommon,
      renderCell: params => (
        <Box width='100%' textAlign='center'>
          <Chip label={params.value} />
        </Box>
      )
    },
    {
      field: 'dtmCreatedDate',
      headerName: 'Create Date',
      ...propertiesCommon,
      renderCell: (params: any) => (
        <Typography variant='subtitle2'>
          {params.value ? format(new Date(params.value), 'd, MMM d, yyyy') : '-'}
        </Typography>
      )
    },
    {
      field: 'dtmUpdatedDate',
      headerName: 'Create Date',
      ...propertiesCommon,
      renderCell: (params: any) => (
        <Typography variant='subtitle2'>
          {params.value ? format(new Date(params.value), 'd, MMM d, yyyy') : '-'}
        </Typography>
      )
    },
    {
      field: 'tinParentRol',
      headerName: 'Ship Ez',
      flex: 1,
      headerAlign: 'center',
      renderCell: (params: any) => (
        <Typography variant='subtitle2'>
          {params.value === 1 ? <Icon color='success' icon='mdi:check-bold' /> : ''}
        </Typography>
      )
    },
    {
      field: '',
      headerName: 'Action',
      flex: 1,
      minWidth: 250,
      maxWidth: 250,
      headerAlign: 'center',
      renderCell: ({ row }: GridCellParams<RolModel>) => <RowOptions row={row} refreshChanges={refreshChanges} />
    }
  ]

  return (
    <Box>
      <Box textAlign='end' my={2}>
        <WrapperSeurityObject securityKey={'add role button'}>
          <Link to={'add'} state={{ mode: CrudView.ADD }}>
            <Button variant='contained'>
              <Typography color={'white'}>{t('ADD_NEW_ROLE')}</Typography>
            </Button>
          </Link>
        </WrapperSeurityObject>
      </Box>
      <DataGrid
        checkboxSelection
        rows={roles}
        columns={columns}
        autoHeight
        getRowId={roles => roles.id}
        loading={loading}
        components={{ Toolbar: CustomToolbarAdminGrid }}
        pageSize={15}
      />
    </Box>
  )
}

type RowProps = {
  row: RolModel
  refreshChanges: (refresh: boolean) => void
}

const RowOptions = ({ row, refreshChanges }: RowProps) => {
  const { t } = useTranslation()
  const saveText = t('DELETE')
  const modalDeleteRole = useRef<DialogExpose>(null)
  const navigate = useNavigate()

  const handleClick = (mode: string) => {
    if (mode === 'Edit') {
      navigate('edit', { state: { mode: CrudView.EDIT, data: row } })
    } else if (mode === 'Delete') {
      modalDeleteRole.current?.open()
    } else if (mode === 'View') {
      navigate('view', { state: { mode: CrudView.VIEW, data: row } })
    }
  }

  const handleDeleteRole = async () => {
    modalDeleteRole.current?.close()
    try {
      await deleteRole(row.sinRoleId)
        .then(() => {
          toast.success(t('DELETED_SUCCESSFULLY'))
          refreshChanges(true)
        })
        .catch(err => toast.error(err.message))
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  return (
    <>
      <Box width='100%' display='flex' justifyContent='center'>
        <IconAction title={t('SEE')} icon={'mdi:eye'} onClick={() => handleClick('View')} />
        <WrapperSeurityObject securityKey='edit role button'><IconAction title={t('EDIT')} icon='mdi:pencil' onClick={() => handleClick('Edit')} /></WrapperSeurityObject>
        <WrapperSeurityObject securityKey='delete role button'><IconAction title={t('DELETE')} icon='ic:outline-delete' color='error' onClick={() => handleClick('Delete')} /></WrapperSeurityObject>
      </Box>
      <DialogGeneric ref={modalDeleteRole} title={'DELETE'} confirmText={saveText} confirmFunc={handleDeleteRole}>
        {t('CONFIRM_DELETE_TEXT')}
      </DialogGeneric>
    </>
  )
}

export default TableRoles

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Card, CardContent, Grid, Switch, Typography } from '@mui/material'
import Icon from 'src/@core/components/icon'
import profileStore from 'src/zustand/profile'

interface Platform {
  id: number
  image: string
  platformName: string
  status: boolean
  disabled?: boolean
}

const companies = [
  {
    companyName: 'Company 1',
    id: 1
  },
  {
    companyName: 'Company 2',
    id: 2
  },
  {
    companyName: 'Company 3',
    id: 3
  },
  {
    companyName: 'Company 4',
    id: 4
  },
  {
    companyName: 'Company 5',
    id: 5
  }
]

const ShopIntegration = () => {
  const [value, setValue] = useState<any>(0)
  const { profile } = profileStore()
  const [shopPlatforms, setshopPlatforms] = useState<Array<Platform>>([
    {
      id: 1,
      image: 'https://upload.wikimedia.org/wikipedia/commons/5/55/Magento_Logo.svg',
      platformName: 'Magento',
      status: false,
      disabled: true
    },
    {
      id: 2,
      image: 'https://upload.wikimedia.org/wikipedia/commons/1/1b/EBay_logo.svg',
      platformName: 'Ebay',
      status: false,
      disabled: true
    },
    {
      id: 3,
      image: 'https://upload.wikimedia.org/wikipedia/en/5/5f/IHub_logo.png',
      platformName: 'IHub',
      status: true,
      disabled: false
    },
    {
      id: 4,
      image: 'https://1000logos.net/wp-content/uploads/2020/08/Shopify-Logo.png',
      platformName: 'Shopify',
      status: true,
      disabled: false
    },
    {
      id: 5,
      image: 'https://www.sap.com/dam/application/shared/logos/sap-logo-svg.svg/sap-logo-svg.svg',
      platformName: 'Sap',
      status: false,
      disabled: true
    },
    {
      id: 6,
      image: 'https://www.logo.wine/a/logo/WooCommerce/WooCommerce-Logo.wine.svg',
      platformName: 'WooCommerce',
      status: false,
      disabled: true
    },
    {
      id: 7,
      image: 'https://www.shipstation.com/wp-content/uploads/2020/09/ShipStation-Logo-Color-Logo-1.png',
      platformName: 'ShipStation',
      status: false,
      disabled: true
    }
  ])
  const togglePlatformStatus = (platformId: number) => {
    const updatedPlatforms = [...shopPlatforms]

    const platformIndex = updatedPlatforms.findIndex(platform => platform.id === platformId)

    if (platformIndex !== -1) {
      updatedPlatforms[platformIndex].status = !updatedPlatforms[platformIndex].status
      setshopPlatforms(updatedPlatforms)
    }
  }
  const { t } = useTranslation()

  interface PlatformItemProps {
    platforms: Array<Platform>
  }

  const PlatformItem = ({ platforms }: PlatformItemProps) => {
    return (
      <Grid container spacing={2}>
        {platforms.map((platform: Platform, key: number) => (
          <Grid item xs={12} sm={6} md={6} lg={6} key={key}>
            <Card
              style={{
                backgroundColor: 'background.default',
                display: 'flex'
              }}
              sx={{
                flexDirection: 'column'
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'center', sm: 'space-between' },
                    alignItems: 'center'
                  }}
                >
                  <Avatar
                    src={platform.image}
                    sx={{
                      width: { xs: 260, sm: 190 },
                      height: { xs: 60, sm: 60 },
                      filter: !platform.status ? 'grayscale(1)' : ''
                    }}
                    variant='square'
                  />
                  <Switch checked={platform.status} disabled={platform.disabled} onChange={() => togglePlatformStatus(platform.id)} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <>
      <Card>
        <CardContent>
          <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
            <Avatar sx={{ bgcolor: 'primary.main', width: 40, height: 40 }} variant='rounded'>
              <Icon icon='mdi:shop' style={{ fontSize: 24 }} color='white' />
            </Avatar>
            <Typography sx={{ m: 3 }} variant='h5' component='div'>
              {t('Shop Integration')}
            </Typography>
            {/* <Select
                value={value}
                fullWidth
                displayEmpty
                placeholder='Select a company...'
                onChange={e => setValue(e.target.value)}
                labelId='select-company'
                sx={{
                  '& .MuiSelect-select': {
                    color: value === 0 ? 'rgba(0, 0, 0, 0.5)' : 'inherit' 
                  },
                  '& .MuiInputBase-root::before': {
                    borderBottom: value === 0 ? '1px solid rgba(0, 0, 0, 0.42)' : 'inherit' 
                  }
                }}
              >
                <MenuItem key={0} value={0}>
                  {'Select a company...'}
                </MenuItem>
                {companies.map((company, i) => (
                  <MenuItem key={i} value={company.id}>
                    {company.companyName}
                  </MenuItem>
                ))}
              </Select> */}
          </Box>
          <Card sx={{ mt: 2 }} variant='outlined' elevation={0}>
            <CardContent>
              <Box>
                <PlatformItem platforms={shopPlatforms} />
              </Box>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    </>
  )
}

export default ShopIntegration

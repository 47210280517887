import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { deleteZone, getServicesByServiceId, updateZone } from 'src/@http/carriers'
import { deleteZoneHTTP } from 'src/@http/zone'
import { CrudView } from 'src/types/forms/CrudView'
import { ServicesCarrierModel } from 'src/types/models/ServicesCarrierModel'
import { logger } from 'src/utils/Logger'
import servicesStore from 'src/zustand/services'

import { ZoneModel } from '../../../types/models/SheetModels'
import CustomToolbarAdminGrid from '../generic/CustomToolbarAdminGrid'
import { DialogExpose, DialogGeneric } from '../generic/DialogGeneric'
import { IconAction } from '../rateSheets/TableRateSheet'

import { colorAccordingStatus, Status } from './ColorStatus'
import SelectorRegion from './SelectorRegion'

const initialRows: ZoneModel = {
  id: 0,
  intServiceZoneId: 0,
  vchName: null,
  vchShortName: null,
  vchSourceId: null,
  intCarrierId: 0,
  vchCarrierName: null,
  intCountryId: 0,
  vchCountryName: null,
  intServiceId: 0,
  vchServiceName: null,
  intStatusId: 0,
  vchStatusName: null,
  intCreatedUserId: 0,
  intUpdatedUserId: 0,
  dtmCreatedDate: '',
  dtmUpdatedDate: '',
  intRegionId: 0
}

const RowOptions = (props: {
  row: any
  editZone: (editedData: ZoneModel) => void
  handleDeleteRow: (rowid: number) => void
  handleData: (data: ZoneModel, mode: string) => void
}) => {
  const { row, handleDeleteRow, editZone } = props
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const color = colorAccordingStatus(row.vchStatusName)

  return (
    <>
      <IconAction
        title={t('EDIT')}
        icon={'ep:edit'}
        sizeIcon={22}
        onClick={() => setOpen(true)}
        color={color}
        disabled={row.intStatusId !== Status.ACTIVE}
      />
      <IconAction
        title={t('DELETE')}
        icon={'ic:round-delete'}
        disabled={row.intStatusId === Status.DELETED}
        sizeIcon={22}
        onClick={() => handleDeleteRow(row.id)}
        color='error'
      />
      <ModalAddEdit
        open={open}
        setOpen={open => setOpen(open)}
        handleZone={data => editZone(data)}
        data={row}
        view={CrudView.EDIT}
      />
    </>
  )
}

interface ModalAddEditProps {
  handleZone: (data: any, moreThanOne?: boolean) => void
  data?: ZoneModel
  view?: string
  open: boolean
  setOpen: (open: boolean) => void
}

interface ModalAddEditProps {
  handleZone: (data: any, moreThanOne?: boolean) => void
  data?: ZoneModel
  view?: string
  open: boolean
  setOpen: (open: boolean) => void
}

const ModalAddEdit = (props: ModalAddEditProps) => {
  const { state } = useLocation()
  const { t } = useTranslation()
  const { handleZone, view, data, open, setOpen } = props
  const [typeZone, setTypeZone] = useState<boolean>(false)
  const formRangeZones = useForm({
    defaultValues: { initialValue: 0, finalValue: 0, increase: 1 }
  })
  const form = useForm<ZoneModel>({
    defaultValues: view === CrudView.ADD ? initialRows : data
  })

  // logger.log('data: ', data)

  const { handleSubmit: handleSubmitRange, register: registerRange } = formRangeZones
  const {
    watch,
    setValue,
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty }
  } = form

  const valueAsNumber = { valueAsNumber: true }
  const rules = {
    validate: { greaterThanMin: (value: number | string) => Number(value) > Number(getValues('vchName')) }
  }

  const isSaveButtonDisabled = Object.keys(errors).length > 0 || !isDirty
  const close = () => setOpen(false)

  const onSubmit = (data: any) => {
    data.decZoneMax = Number(data.decZoneMax)
    data.decZoneMin = Number(data.decZoneMin)
    data.intStatusId = Status.ACTIVE
    data.vchStatusName = 'Active'
    handleZone(data, false)
    setOpen(false)
  }

  const onSubmitRange = (data: any) => {
    const { initialValue = 0, finalValue = 0 } = data
    const dataArray = []
    let currentValue = Number(initialValue)

    while (currentValue <= finalValue) {
      const dataweight = {
        id: Math.random().toString(36).substring(2) + Date.now().toString(36),
        intServiceZoneId: 0,
        vchName: 'Zone ' + currentValue,
        vchShortName: null,
        vchSourceId: null,
        intCarrierId: undefined,
        vchCarrierName: undefined,
        intCountryId: 0,
        vchCountryName: null,
        intServiceId: 0,
        vchServiceName: null,
        intStatusId: 1,
        vchStatusName: 'Active',
        intCreatedUserId: undefined,
        intUpdatedUserId: 0,
        dtmCreatedDate: null,
        dtmUpdatedDate: null,
        decZoneMax: NaN,
        decZoneMin: NaN
      }

      dataArray.push(dataweight)

      currentValue += 1
    }

    handleZone(dataArray, true)

    setOpen(false)
    setTypeZone(false)
  }

  logger.log('state carrier: ', state.data.intCarrierId)

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title' display='flex' flexDirection='row' justifyContent='space-between'>
        <Typography variant='h6'>{view === CrudView.ADD ? t(`${CrudView.ADD}`) : t(`${CrudView.EDIT}`)}</Typography>
        {view !== CrudView.EDIT && (
          <Stack display='flex' flexDirection='row' alignItems='center'>
            <Typography variant='body2'>{t('VALUE')}</Typography>
            <Switch color='primary' onChange={e => setTypeZone(e.target.checked)} />
            <Typography variant='body2'>{t('RANGE')}</Typography>
          </Stack>
        )}
      </DialogTitle>
      <form>
        <DialogContent>
          <Grid container rowGap={5} py={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                sx={{ pr: 2 }} 
                label={t('NAME')}
                {...register('vchName', { required: true })} 
                id="name_zone"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl sx={{ width: '250px' }}>
                <Autocomplete
                  fullWidth
                  options={[{ id: 1, vchName: 'United States' }]}
                  getOptionLabel={option => option.vchName}
                  renderInput={params => <TextField {...params} label={t('COUNTRY')} />}
                  onChange={(e, option) => setValue('intCountryId', option?.id || 1)}
                  defaultValue={{ id: 1, vchName: 'United States' }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container rowGap={5}>
            {!typeZone ? (
              <Grid item xs={12} md={6}>
                {state &&
                  <FormControl sx={{ width: '270px' }}>
                    <Controller
                      name='intRegionId'
                      control={control}
                      render={({ field, fieldState }) => (
                        <SelectorRegion
                          title={t('Region')}
                          carrierId={state.data.intCarrierId}
                          selectedRegion={field.value}
                          onChange={field.onChange}
                          disabled={typeZone}
                        />
                      )}
                    />
                  </FormControl>
                }
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    sx={{ pr: 2 }}
                    label={t('Min Range')}
                    defaultValue={0}
                    {...registerRange('initialValue', valueAsNumber)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    sx={{ pr: 2 }}
                    label={t('Max Range')}
                    defaultValue={0}
                    {...registerRange('finalValue', valueAsNumber)}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                sx={{ pr: 2 }}
                label={t('Source Id')}
                {...register('vchSourceId', { required: false })}
                id="source_id_zone"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
            {!typeZone ? (
              <Button disabled={isSaveButtonDisabled} onClick={handleSubmit(onSubmit)}>
                {t('SAVE')}
              </Button>
            ) : (
              <Button onClick={handleSubmitRange(onSubmitRange)}>{t('SAVE')}</Button>
            )}
          <Button color='secondary' onClick={close} autoFocus>
            {t('CANCEL')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

type propModalZone = { typeUser?: string; serviceId: number }

const ModalZoneManagement = (props: propModalZone) => {
  const { isEditMode, setZoneDtos, zoneDtos, resetZonesAndWeigths } = servicesStore()
  const { serviceId } = props
  const [loading, setLoading] = useState(false)
  const dialogRef = useRef<DialogExpose>(null)
  const [rows, setRows] = useState<ZoneModel[]>([])
  const [open, setOpen] = useState(false)
  const { state } = useLocation()

  const { t } = useTranslation()

  const columns: GridColumns = [
    { field: 'vchName', headerName: 'Zone', flex: 1, maxWidth: 300 },
    {
      field: 'vchStatusName',
      align: 'center',
      headerName: 'Status',
      headerAlign: 'center',
      flex: 1,
      renderCell: ({ row }: { row: any }) => {
        const color = colorAccordingStatus(row.vchStatusName)

        return <Chip label={row.vchStatusName} color={color} />
      }
    },
    {
      field: '',
      headerName: 'Action',
      align: 'center',
      flex: 1,
      minWidth: 180,
      maxWidth: 180,
      headerAlign: 'center',
      renderCell: ({ row }: { row: any }) => (
        <RowOptions row={row} handleData={handleRows} editZone={editZone} handleDeleteRow={handleDeleteRow} />
      )
    }
  ]

  const editZone = (editedData: ZoneModel) => {
    const rowIndex = rows.findIndex(item => item.id === editedData.id)
    if (rowIndex !== -1) {
      const updatedRows = [...rows]
      updatedRows[rowIndex] = editedData
      setRows(updatedRows)

      const weightIndex = zoneDtos.findIndex(item => item.id === editedData.id)
      if (weightIndex !== -1) {
        const updatedZoneDto = [...zoneDtos]
        updatedZoneDto[weightIndex] = editedData
        setZoneDtos(updatedZoneDto)
      }
    }
    setOpen(false)
  }

  const handleDeleteRow = async (rowId: number) => {
    dialogRef.current?.open().then(async confirm => {
      if (confirm) {
        const updatedRows = rows.filter(row => row.id !== rowId)
        setRows(updatedRows)

        const updatedZoneDtos = zoneDtos.filter(zone => zone.id !== rowId)
        setZoneDtos(updatedZoneDtos)
        try {
          await deleteZoneHTTP(rowId)
        } catch (err: any) { }
      }
    })
  }

  const generateUniqueId = () => {
    return Math.random().toString(36).substring(2) + Date.now().toString(36)
  }

  const setExtraData = (row: any) => {
    const { data } = state.data
      ; (row.id = data?.id), (row.intServiceZoneId = data?.intServiceZoneId), (row.intCarrierId = data?.intCarrierId)
    row.vchCarrierName = data?.Carrier
    row.intCreatedUserId = data?.intCreatedUserId
    row.intServiceId = serviceId
    row.intCountryId = 1

    return row
  }

  const setExtraDataNew = (row: any) => {
    const { data } = state.data
    row.id = generateUniqueId()
    row.intCarrierId = data?.intCarrierId
    row.vchCarrierName = data?.Carrier
    row.intCreatedUserId = data?.intCreatedUserId
    row.intServiceId = serviceId
    row.intCountryId = 1
    row.intRegionId = data?.intRegionId

    return row
  }
  const handleDialog = () => setOpen(true)

  const addZone = async (newData: any, moreThanOne?: boolean) => {
    if (moreThanOne) {
      const updatedRows = newData.map((zone: ZoneModel) => setExtraDataNew(zone))
      setRows([...rows, ...updatedRows])
      setZoneDtos([...rows, ...updatedRows])
    } else {
      const data = setExtraDataNew(newData)
      setRows([...rows, data])
      setZoneDtos([...rows, data])
    }
  }
  const handleRows = (row: ZoneModel, mode: string) => {
    if (mode === CrudView.DELETE) {
      dialogRef.current?.open().then(async confirm => {
        if (confirm) {
          deleteZoneSelected(row.id)
        }
      })
    }
    if (mode === CrudView.EDIT) {
      updateZoneSelected(setExtraData(row))
    } else if (mode === 'StatusRow') {
      const data = setExtraData(row)
      updateZoneSelected({
        ...data,
        intStatusId: row.intStatusId === Status.ACTIVE ? Status.INACTIVE : Status.ACTIVE,
        vchStatusName: row.intStatusId === Status.ACTIVE ? 'Inactive' : 'Active',
        intRegionId: row.intRegionId,
        intCarrierId: row.intCarrierId,
        dtmUpdatedDate: new Date().toISOString().toString()
      })
    }
  }

  const getListZone = async () => {
    setLoading(true)

    if (serviceId !== 0) {
      try {
        const response = (await getServicesByServiceId(serviceId)) as unknown as ServicesCarrierModel
        if (response.zoneListDtos) {
          setZoneDtos(response.zoneListDtos)
          setRows(response.zoneListDtos)
        }
        setLoading(false)
      } catch (err: any) {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }

  const updateZoneSelected = async (body: ZoneModel) => {
    try {
      await updateZone(body).then(async () => {
        await getListZone()
      })
    } catch (error) {
      // console.log('[Error]', error)
    }
  }

  const deleteZoneSelected = async (zoneId: number) => {
    try {
      await deleteZone(zoneId).then(async () => {
        await getListZone()
      })
      toast.success(t('DELETED_SUCCESSFULLY') + '! 👌', { duration: 4000 })
    } catch (err: any) {
      toast.error(err.DescriptionError + '🙄', { duration: 4000 })
    }
  }

  useEffect(() => {
    setRows(zoneDtos || [])
  }, [])

  useEffect(() => {
    getListZone()
  }, [resetZonesAndWeigths])


  return (
    <>
      <Stack display='flex'>
        <Card sx={{ width: '100%', boxShadow: 'none' }}>
          <CardContent>
            <Stack
              sx={{ mb: 4 }}
              spacing={2}
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              width='100%'
            >
              <Button style={{ color: 'white' }} variant='contained' size='small' onClick={handleDialog}>
                {t('ADD')}
              </Button>
            </Stack>
            <DataGrid
              autoHeight
              columns={columns}
              style={{ width: '100%' }}
              rows={rows}
              getRowClassName={rows => (rows.row ? 'disabled-row' : '')}
              pageSize={15}
              loading={loading}
              components={{ Toolbar: CustomToolbarAdminGrid }}
            />
          </CardContent>
        </Card>
      </Stack>
      <ModalAddEdit
        open={open}
        // data={state.data}
        setOpen={open => setOpen(open)}
        handleZone={(data, moreThanOne) => addZone(data, moreThanOne)}
        view={CrudView.ADD}
      />
      <DialogGeneric title='DELETE' ref={dialogRef} confirmText='CONFIRM' maxWidth={500}>
        {t('CONFIRM_DELETE')}
      </DialogGeneric>
    </>
  )
}

export default ModalZoneManagement

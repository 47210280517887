import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { getCacheRoles } from 'src/@http/role'
import { getShops } from 'src/@http/shop'
import { createUserAdmin, getUserAdmin, updateUserAdmin } from 'src/@http/user'
import { RolModel } from 'src/types/models/RolModel'
import { ShopModel } from 'src/types/models/ShopModels'
import { logger } from 'src/utils/Logger'
import * as yup from 'yup'

interface UserForm {
  email: string
  firstName: string
  lastName: string
  userType: number
  roles: number[]
  customer: {
    id: number
    vchName: string
  }
  isAccountManager: boolean
  tinParentRol?: boolean
}

interface Customer {
  id: number
  vchName: string
}

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const AdminUserNewOrEdit = () => {
  const params = useParams()

  const userId = params.id ?? ''
  const query = useQuery()
  const view: boolean = query.get('view') === 'true' ? true : false

  const initForm: UserForm = {
    email: '',
    firstName: '',
    lastName: '',
    userType: 1,
    roles: [],
    customer: {
      id: 0,
      vchName: ''
    },
    isAccountManager: false,
    tinParentRol: false
  }

  const validations = {
    email: yup.string().email(),
    firstName: yup.string(),
    lastName: yup.string()
  }

  if (!view && userId === '') {
    validations.email = validations.email.required()
    validations.firstName = validations.firstName.required()
    validations.lastName = validations.lastName.required()
  }

  const schemaValidations = yup.object().shape(validations)

  // ** State
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [customers, setCustomers] = useState<Customer[]>([])
  const [roles, setRoles] = useState<RolModel[]>([])
  const [isCustomer, setIsCustomer] = useState<boolean>(false)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { control, handleSubmit, setValue } = useForm<UserForm>({
    resolver: yupResolver(schemaValidations),
    defaultValues: initForm
  })

  useEffect(() => {
    const setInitialState = async () => {
      let customers: Customer[] = []
      setIsLoading(true)
      try {
        const response = (await getShops()) as ShopModel[]
        if (response) {
          customers = response.map(item => ({
            id: item.id,
            vchName: item.vchName
          }))
          setCustomers(customers)
          // if (userId === '') setValue('customer', { id: response[0].id, vchName: response[0].vchName })
        }
      } catch (err: any) {
        logger.error('Error', err)
      }
      if (userId !== '') await loadUserById(userId, customers)
      setIsLoading(false)
    }

    setInitialState()
  }, [userId])

  const loadUserById = async (userId: string, customerss: Customer[]) => {
    try {
      const user = await getUserAdmin(parseInt(userId))
      console.log('User', user)
      if (user) {
        setValue('email', user.vchMail)
        setValue('firstName', user.vchName)
        setValue('lastName', user.vchLastName)
        setValue('userType', user.tinUserType)
        /* setValue('isAccountManager', user.bitIsAccountManager === 1) */
        setValue(
          'roles',
          user.listUserRole.map(role => role.sinRoleId)
        )
        const customer = customerss.find((customer: Customer) => {
          return customer.id === user.listUserRole[0].intCustomerId
        })
        if (customer) setValue('customer', customer)
      }
    } catch (err: any) {
      logger.error(err)
    }
  }

  const onSubmit = async (form: UserForm) => {
    console.log(form)

    setIsSubmitting(true)

    setIsSubmitting(true)
    if (userId == '') {
      try {
        await createUserAdmin({
          vchName: form.firstName,
          vchLastName: form.lastName,
          tinUserType: 1,
          intCustomerId: 0,
          vchMail: form.email,
          vchPassword: '',
          vchFullName: form.firstName + ' ' + form.lastName,
          bitIsAccountManager: 0,
          listUserRole: form.roles.map((role: number) => {
            return {
              sinRoleId: role,
              intCustomerId: isCustomer ? form.customer.id : 0
            }
          })
        })
        toast.success(t('CREATE_SUCCESS'), { duration: 4000 })
        navigate('/admin-user')
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      }
    } else {
      try {
        await updateUserAdmin({
          id: parseInt(userId),
          intUserId: parseInt(userId),
          tinUserType: 1,
          vchName: form.firstName,
          vchLastName: form.lastName,
          vchMail: form.email,
          vchFullName: form.firstName + ' ' + form.lastName,
          bitIsAccountManager: form.isAccountManager ? 1 : 0,
          listUserRole: form.roles.map((role: number) => {
            return {
              sinRoleId: role,
              intCustomerId: isCustomer ? form.customer.id : 0
            }
          })
        })
        toast.success(t('UPDATE_SUCCESS'), { duration: 4000 })
        navigate('/admin-user')
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      }
    }
    setIsSubmitting(false)
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await getCacheRoles()
        if (response) {
          const mappedRoles = response.map((rol: any) => ({
            ...rol,
            vchName: rol.description
          })) as RolModel[]
          setRoles(mappedRoles)
        }
      } catch (err: any) {
        // console.log('Error', err)
        //arreglar
      }
    }
    fetch()
  }, [])

  if (isLoading) {
    return (
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            {userId == '' && <CardHeader title={t('NEW_USER')} />}
            {userId != '' && !view && <CardHeader title={t('EDIT_USER')} subheader={'Id: ' + userId} />}
            {userId != '' && view && <CardHeader title={t('VIEW_USER')} subheader={'Id: ' + userId} />}

            <Divider sx={{ m: '0 !important' }} />

            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <Box sx={{ mt: 6, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress sx={{ mb: 5 }} />
                    <Typography>{t('LOADING')}...</Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          {userId == '' && <CardHeader title={t('NEW_USER')} />}
          {userId != '' && !view && <CardHeader title={t('EDIT_USER')} subheader={'Id: ' + userId} />}
          {userId != '' && view && <CardHeader title={t('VIEW_USER')} subheader={'Id: ' + userId} />}

          <Divider sx={{ m: '0 !important' }} />
          <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant='body2' sx={{ fontWeight: 600 }}>
                    1. {t('ACCOUNT_DETAILS')}
                  </Typography>
                  {userId == '' && (
                    <Typography variant='caption' sx={{ marginLeft: 5 }}>
                      {t('PLEASE_ENTER_EMAIL_SEND_PASSWORD')}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth sx={{}}>
                    <Controller
                      name='email'
                      control={control}
                      render={({ field: { ...field }, fieldState }) => (
                        <TextField
                          variant='outlined'
                          type='email'
                          disabled={view}
                          {...field}
                          label={t('EMAIL')}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ mb: '0 !important' }} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2' sx={{ fontWeight: 600 }}>
                    2. {t('PERSONAL_INFO')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth sx={{}}>
                    <Controller
                      name='firstName'
                      control={control}
                      render={({ field: { ...field }, fieldState }) => (
                        <TextField
                          variant='outlined'
                          disabled={view}
                          {...field}
                          label={t('FIRST_NAME')}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth sx={{}}>
                    <Controller
                      name='lastName'
                      control={control}
                      render={({ field: { ...field }, fieldState }) => (
                        <TextField
                          variant='outlined'
                          disabled={view}
                          {...field}
                          label={t('LAST_NAME')}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ mb: '0 !important' }} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2' sx={{ fontWeight: 600 }}>
                    3. {t('PERMISSION')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>{t('ROLE')}</InputLabel>
                    <Controller
                      name='roles'
                      control={control}
                      render={({ field: { name, value, onChange } }) => (
                        <Select
                          label={t('ROLE')}
                          required
                          fullWidth
                          name={name}
                          value={value}
                          onChange={onChange}
                          disabled={view}
                          multiple
                          renderValue={selected => {
                            const selectedValue = selected as unknown as number[]
                            const visibleSelected = Array.isArray(selectedValue)
                              ? selectedValue.slice(0, 2)
                              : [selectedValue]
                            const remainingCount = selectedValue.length - visibleSelected.length

                            return (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                {visibleSelected.map(value => (
                                  <Chip
                                    size='small'
                                    key={value}
                                    label={roles.find(option => option.id === Number(value))?.vchName}
                                    sx={{ m: 0.75 }}
                                  />
                                ))}
                                {remainingCount > 0 && <Chip label={`+${remainingCount} more`} />}
                              </Box>
                            )
                          }}
                        >
                          {roles.map((role: { id: number; vchName: string }, i: number) => {
                            return (
                              <MenuItem key={`menu-item-${i}`} value={role.id}>
                                {role.vchName}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                {!view && (
                  <Grid container item xs={12} justifyContent='flex-end'>
                    <Button onClick={() => navigate('/admin-user')} variant='outlined' sx={{ mr: 4 }}>
                      {t('CANCEL')}
                    </Button>
                    <LoadingButton
                      loading={isSubmitting}
                      type='submit'
                      variant='contained'
                      sx={{ mr: 4 }}
                      disabled={false}
                    >
                      {t('SAVE_CHANGES')}
                    </LoadingButton>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </form>
        </Card>
      </Grid>
    </Grid>
  )
}

export default AdminUserNewOrEdit

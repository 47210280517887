import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { useTheme } from '@mui/system'
import Icon from 'src/@core/components/icon'
import CustomAvatar from 'src/@core/components/mui/avatar'
import CustomChip from 'src/@core/components/mui/chip'
import { ThemeColor } from 'src/@core/layouts/types'
import { getInitials } from 'src/@core/utils/get-initials'
import { updateUserActualPassword, updateUserInfo } from 'src/@http/user'
import { RootState } from 'src/store'
import { UserModel } from 'src/types/models/userModels'
import profileStore from 'src/zustand/profile'
import * as yup from 'yup'

import UserSubscriptionDialog from './UserSubscriptionDialog'
import UserSuspendDialog from './UserSuspendDialog'

interface ColorsType {
  [key: string]: ThemeColor
}

const roleColors: ColorsType = {
  admin: 'error',
  editor: 'info',
  author: 'warning',
  maintainer: 'success',
  subscriber: 'primary'
}

const schema = yup.object().shape({
  username: yup.string(),
  email_address: yup.string(),
  first_name: yup.string(),
  middle_name: yup.string(),
  last_name: yup.string(),
  telephone: yup.string(),
  country: yup.string(),
  preferred_languague: yup.string(),
  old_password: yup.string(),
  repeat_old_password: yup.string(),
  new_password: yup.string(),
  repeat_new_password: yup.string()
})

interface FormData {
  username: string
  last_name: string
  first_name: string
  middle_name: string
  my_picture: string
  country: string
  role: string
  preferred_languague: string
  email_address: string
  telephone: string
  birthday: Date
  old_password: string
  repeat_old_password: string
  new_password: string
  repeat_new_password: string
  print_token: string
  print_service_url: string
}

console.log('userEdit')

const UserProfile = () => {
  const userEdit: UserModel = useSelector((state: RootState) => state.profile.profile)
  const date = new Date(userEdit.birthday)
  const [openEdit, setOpenEdit] = useState<boolean>(false)
  const [openPassword, setOpenPassword] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [suspendDialogOpen, setSuspendDialogOpen] = useState<boolean>(false)
  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
  const [showRepeatNewPassword, setRepeatNewShowPassword] = useState<boolean>(false)
  const { profile } = profileStore()

  const theme = useTheme()
  const mode = theme.palette.mode

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  })

  const handleEditClickOpen = () => setOpenEdit(true)
  const handleEditClose = () => {
    // Reset form
    reset()
    // Close dialog
    setOpenEdit(false)
  }

  const handlePasswordClickOpen = () => setOpenPassword(true)
  const handlePasswordClose = () => {
    // Reset form
    reset()
    // Close dialog
    setOpenPassword(false)
  }

  const onSubmit = async (data: FormData, event: any) => {
    event.preventDefault()

    const settings = {
      print_service_url: data.print_service_url,
      print_token: data.print_token
    }
    
    sessionStorage.setItem("print_settings", JSON.stringify(settings))

    if (openPassword) {
      // Check if new passwords match
      if (data.new_password !== data.repeat_new_password) {
        setError('repeat_new_password', { message: 'New passwords do not match' })

        return
      }

      setIsLoading(true)

      try {
        const res = await updateUserActualPassword(data.old_password, data.new_password)

        console.log(res)
        setIsLoading(false)
        handlePasswordClose()
      } catch (error: any) {
        console.error(error)
        setError('repeat_old_password', { message: 'Wrong Password' })
      } finally {
        setIsLoading(false)
      }
    }

    if (openEdit) {
      setIsLoading(true)

      try {
        const res = await updateUserInfo({
          id: profile?.intUserId,
          vchName: data.first_name,
          vchLastName: data.last_name
        })

        console.log(res)

        handleEditClose()
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  if (userEdit && profile) {
    return (
      <Grid container spacing={6} sx={{ height: '100%' }}>
        <Grid item xs={12}>
          <Card>
            <CardContent sx={{ pt: 15, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              {userEdit.my_picture ? (
                <CustomAvatar
                  src={userEdit.my_picture}
                  variant='rounded'
                  alt={`${profile.vchFullName}`}
                  sx={{ width: 120, height: 120, fontWeight: 600, mb: 4 }}
                />
              ) : (
                <CustomAvatar
                  skin='light'
                  variant='rounded'
                  color={'primary' as ThemeColor}
                  sx={{ width: 120, height: 120, fontWeight: 600, mb: 4, fontSize: '3rem' }}
                >
                  {getInitials(`${profile.vchName} ${profile.vchLastName}`)}
                </CustomAvatar>
              )}
              <Typography variant='h6' sx={{ mb: 4 }}>
                {profile.vchFullName}
              </Typography>
              {profile.listUserRole &&
                profile.listUserRole.map(role => (
                  <CustomChip
                    key={role.id}
                    skin='light'
                    size='small'
                    label={role.vchNameRole}
                    color={'success'}
                    sx={{ textTransform: 'capitalize', m: 1 }}
                  />
                ))}
            </CardContent>

            <CardContent>
              <Typography variant='h6'>Details</Typography>
              <Divider sx={{ my: theme => theme.spacing(4) + ' !important' }} />
              <Box sx={{ pb: 1 }}>
                <Box sx={{ display: 'flex', mb: 2 }}>
                  <Typography sx={{ mr: 2, fontWeight: 500, fontSize: '0.875rem' }}>Username:</Typography>
                  <Typography variant='body2'>{profile.vchMail}</Typography>
                </Box>
                <Box sx={{ display: 'flex', mb: 2 }}>
                  <Typography sx={{ mr: 2, fontWeight: 500, fontSize: '0.875rem' }}>Email:</Typography>
                  <Typography variant='body2'>{profile.vchMail}</Typography>
                </Box>
                <Box sx={{ display: 'flex', mb: 2 }}>
                  <Typography sx={{ mr: 2, fontWeight: 500, fontSize: '0.875rem' }}>User Type:</Typography>
                  <Typography variant='body2' sx={{ textTransform: 'capitalize' }}>
                    {profile.userTypeName}
                  </Typography>
                </Box>
              </Box>
            </CardContent>

            <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button style={{ color: 'white' }} variant='contained' sx={{ mr: 2 }} onClick={handleEditClickOpen}>
                Edit Info
              </Button>
              <Button style={{ color: 'white' }} variant='contained' sx={{ mr: 2 }} onClick={handlePasswordClickOpen}>
                Change Password
              </Button>
              {/* <Button color='error' variant='outlined' onClick={() => setSuspendDialogOpen(true)}>
                Suspend
              </Button> */}
            </CardActions>

            {/* Edit user information dialog: */}
            <Dialog
              open={openEdit}
              onClose={handleEditClose}
              aria-labelledby='user-view-edit'
              aria-describedby='user-view-edit-description'
              sx={{ '& .MuiPaper-root': { width: '100%', maxWidth: 650 } }}
            >
              <DialogTitle
                id='user-view-edit'
                sx={{
                  textAlign: 'center',
                  fontSize: '1.5rem !important',
                  px: theme => [`${theme.spacing(5)} !important, ${theme.spacing(15)} !important`],
                  pt: theme => [`${theme.spacing(8)} !important, ${theme.spacing(12.5)} !important`]
                }}
              >
                Edit User Information
              </DialogTitle>
              <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent
                  sx={{
                    pb: theme => `${theme.spacing(8)} !important`,
                    px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`]
                  }}
                >
                  <DialogContentText
                    variant='body2'
                    id='user-view-edit-description'
                    sx={{ textAlign: 'center', mb: 7 }}
                  >
                    Updating user details will receive a privacy audit.
                  </DialogContentText>
                  <Grid container spacing={6}>
                      <FormControl fullWidth>
                        <Controller
                          name='username'
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                              disabled
                              fullWidth
                              label='Username'
                              value={value}
                              defaultValue={profile.vchMail}
                              onBlur={onBlur}
                              onChange={onChange}
                            />
                          )}
                        />
                      </FormControl>
                    <Grid item xs={12} sm={6} />

                    <Grid container spacing={6}>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth sx={{ mb: 4 }}>
                          <Controller
                            name='first_name'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange, onBlur } }) => (
                              <TextField
                                fullWidth
                                label='First Name'
                                value={value}
                                defaultValue={profile.vchName}
                                onBlur={onBlur}
                                onChange={onChange}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth sx={{ mb: 4 }}>
                          <Controller
                            name='last_name'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange, onBlur } }) => (
                              <TextField
                                autoFocus
                                fullWidth
                                label='Last Name'
                                value={value}
                                defaultValue={profile.vchLastName}
                                onBlur={onBlur}
                                onChange={onChange}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container spacing={6}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <FormControl fullWidth sx={{ mb: 4 }}>
                          <Controller
                            name='print_service_url'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange, onBlur } }) => (
                              <TextField
                                autoFocus
                                label='Print Service Url'
                                value={value}
                                defaultValue={userEdit.print_service_url}
                                onBlur={onBlur}
                                onChange={onChange}
                                error={Boolean(errors.print_service_url)}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <FormControl fullWidth sx={{ mb: 4 }}>
                          <Controller
                            name='print_token'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange, onBlur } }) => (
                              <TextField
                                autoFocus
                                label='Print Token'
                                value={value}
                                defaultValue={userEdit.print_token}
                                onBlur={onBlur}
                                onChange={onChange}
                                error={Boolean(errors.print_token)}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    {false && (
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth sx={{ mb: 4 }}>
                          <Controller
                            name='middle_name'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange, onBlur } }) => (
                              <TextField
                                disabled
                                fullWidth
                                label='Middle Name'
                                value={value}
                                defaultValue={userEdit.middle_name}
                                onBlur={onBlur}
                                onChange={onChange}
                                error={Boolean(errors.middle_name)}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    )}

                    {false && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth sx={{ mb: 4 }}>
                            <Controller
                              name='telephone'
                              control={control}
                              rules={{ required: true }}
                              render={({ field: { value, onChange, onBlur } }) => (
                                <TextField
                                  autoFocus
                                  label='Telephone'
                                  value={value}
                                  defaultValue={userEdit.telephone}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  error={Boolean(errors.telephone)}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth sx={{ mb: 4 }}>
                            <InputLabel id='user-view-language-label'>Language</InputLabel>
                            <Controller
                              name='preferred_languague'
                              control={control}
                              rules={{ required: true }}
                              render={({ field: { value, onChange, onBlur } }) => (
                                <Select
                                  value={userEdit.preferred_languague}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  label='Language'
                                  defaultValue={userEdit.preferred_languague}
                                  id='user-view-language'
                                  labelId='user-view-language-label'
                                >
                                  <MenuItem value='English'>English</MenuItem>
                                  <MenuItem value='Spanish'>Spanish</MenuItem>
                                </Select>
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth sx={{ mb: 4 }}>
                            <InputLabel id='user-view-language-label'>Country</InputLabel>
                            <Controller
                              name='country'
                              control={control}
                              rules={{ required: true }}
                              render={({ field: { value, onChange, onBlur } }) => (
                                <Select
                                  value={value}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  label='Language'
                                  defaultValue={userEdit.country}
                                  id='user-view-language'
                                  labelId='user-view-language-label'
                                >
                                  <MenuItem value='USA'>USA</MenuItem>
                                  <MenuItem value='Spain'>Spain</MenuItem>
                                </Select>
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </DialogContent>

                <DialogActions
                  sx={{
                    justifyContent: 'center',
                    px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`],
                    pb: theme => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`]
                  }}
                >
                  <LoadingButton style={{ color: 'white' }} variant='contained' sx={{ mr: 2 }} type='submit' loading={isLoading}>
                    Save
                  </LoadingButton>
                  <Button variant='outlined' color={mode === 'light' ? 'secondary' : 'info'} onClick={handleEditClose}>
                    Cancel
                  </Button>
                </DialogActions>
              </form>
            </Dialog>

            {/* Change password dialog: */}
            <Dialog
              open={openPassword}
              onClose={handlePasswordClose}
              aria-labelledby='user-view-edit'
              aria-describedby='user-view-edit-description'
              sx={{ '& .MuiPaper-root': { width: '100%', maxWidth: 650 } }}
            >
              <DialogTitle
                id='user-view-edit'
                sx={{
                  textAlign: 'center',
                  fontSize: '1.5rem !important',
                  px: theme => [`${theme.spacing(5)} !important, ${theme.spacing(15)} !important`],
                  pt: theme => [`${theme.spacing(8)} !important, ${theme.spacing(12.5)} !important`]
                }}
              >
                Edit User Password
              </DialogTitle>

              <DialogContentText sx={{ textAlign: 'center', mb: 7 }}>
                {errors.repeat_old_password && (
                  <Typography variant='body2' color='error'>
                    {errors.repeat_old_password.message}
                  </Typography>
                )}
                {errors.repeat_new_password && (
                  <Typography variant='body2' color='error'>
                    {errors.repeat_new_password.message}
                  </Typography>
                )}
              </DialogContentText>

              <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent
                  sx={{
                    pb: theme => `${theme.spacing(8)} !important`,
                    px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`]
                  }}
                >
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <FormControl fullWidth sx={{ mb: 4 }}>
                        <InputLabel>Password</InputLabel>
                        <Controller
                          name='old_password'
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <OutlinedInput
                              type={showPassword ? 'text' : 'password'}
                              fullWidth
                              label='Password'
                              value={value}
                              onBlur={onBlur}
                              onChange={onChange}
                              endAdornment={
                                <InputAdornment position='end'>
                                  <IconButton
                                    edge='end'
                                    onMouseDown={e => e.preventDefault()}
                                    onClick={() => setShowPassword(!showPassword)}
                                  >
                                    <Icon
                                      icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'}
                                      fontSize={20}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth sx={{ mb: 4 }}>
                        <InputLabel>New Password</InputLabel>
                        <Controller
                          name='new_password'
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <OutlinedInput
                              type={showNewPassword ? 'text' : 'password'}
                              fullWidth
                              label='New Password'
                              value={value}
                              onBlur={onBlur}
                              onChange={onChange}
                              endAdornment={
                                <InputAdornment position='end'>
                                  <IconButton
                                    edge='end'
                                    onMouseDown={e => e.preventDefault()}
                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                  >
                                    <Icon
                                      icon={showNewPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'}
                                      fontSize={20}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth sx={{ mb: 4 }}>
                        <InputLabel>Repeat New Password</InputLabel>
                        <Controller
                          name='repeat_new_password'
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <OutlinedInput
                              type={showRepeatNewPassword ? 'text' : 'password'}
                              fullWidth
                              label='Repeat New Password'
                              value={value}
                              onBlur={onBlur}
                              onChange={onChange}
                              endAdornment={
                                <InputAdornment position='end'>
                                  <IconButton
                                    edge='end'
                                    onMouseDown={e => e.preventDefault()}
                                    onClick={() => setRepeatNewShowPassword(!showRepeatNewPassword)}
                                  >
                                    <Icon
                                      icon={showRepeatNewPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'}
                                      fontSize={20}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  sx={{
                    justifyContent: 'center',
                    px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`],
                    pb: theme => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`]
                  }}
                >
                  <LoadingButton variant='contained' sx={{ mr: 2 }} type='submit' loading={isLoading}>
                    Save
                  </LoadingButton>
                  <Button
                    variant='outlined'
                    color={mode === 'light' ? 'secondary' : 'info'}
                    onClick={handlePasswordClose}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </form>
            </Dialog>

            <UserSuspendDialog open={suspendDialogOpen} setOpen={setSuspendDialogOpen} />
            <UserSubscriptionDialog open={subscriptionDialogOpen} setOpen={setSubscriptionDialogOpen} />
          </Card>
        </Grid>
      </Grid >
    )
  } else {
    return null
  }
}

export default UserProfile

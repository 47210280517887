import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { BaseTextFieldProps, IconButton, InputAdornment,TextField } from "@mui/material";

interface SearchProps extends BaseTextFieldProps {
  onChange: (value: string) => void;
  placeholder?: string
}

const InputSearch: React.FC<SearchProps> = ({ onChange, ...props }) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputChange = () => {
    onChange(inputValue);
  };

  return (
    <TextField
      fullWidth
      size={props.size}
      variant="outlined"
      placeholder={`${t('SEARCH').toString()} ${props.placeholder || ''}...`}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleInputChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <Icon icon="material-symbols:search" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default InputSearch;

import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Chip, Typography } from '@mui/material'
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { getJobById, getJobs, putJob } from 'src/@http/job'
import CustomToolbarAdminGrid from 'src/pages/components/generic/CustomToolbarAdminGrid'
import { DialogExpose, DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import WrapperSecurity from 'src/pages/components/generic/WrapperSeurityObject'
import { IconAction } from 'src/pages/components/rateSheets/TableRateSheet'
import { JobModel } from 'src/types/models/JobModel'
import appStore from 'src/zustand/app'
import jobStore from 'src/zustand/Job'

const propertiesCommon = { flex: 1 }

const TableSchedulerCustomer = () => {
  const [loading, setLoading] = useState(false)
  const { refresh } = jobStore()
  const { paramsV2 } = appStore()
  const { t } = useTranslation()
  const [rows, setRows] = useState<any[]>([
    {
      intJobId: 0,
      intCustomertId: 0,
      vchName: 'NaN',
      intStatusId: 0,
      dtmCreatedDate: null,
      dtmUpdatedDate: null
    }
  ])
  const navigate = useNavigate()
  const handleAdd = () => {
    navigate('Add')
  }
  const refreshChanges = (refresh: boolean) => refresh
  const columns: GridColumns = [
    { field: 'intCustomerId', headerName: 'Customer Id', ...propertiesCommon, maxWidth: 120 },
    { field: 'intJobId', headerName: 'Job Id', ...propertiesCommon, maxWidth: 100 },
    { field: 'vchName', headerName: 'Job Name', ...propertiesCommon },
    {
      field: 'intStatusId',
      headerName: 'Status',
      headerAlign: 'center',
      ...propertiesCommon,
      align: 'center',
      renderCell: params => (
        <Box width='100%' textAlign='center'>
          <Chip
            label={params.value === 1 ? 'Active' : 'Inactive'}
            style={{
              backgroundColor: params.value === 1 ? 'green' : 'red',
              color: 'white'
            }}
          />
        </Box>
      )
    },
    {
      field: 'dtmCreatedDate',
      headerName: 'Create Date',
      ...propertiesCommon,
      renderCell: (params: any) => (
        <Typography variant='subtitle2'>
          {params.value ? format(new Date(params.value), 'd, MMM d, yyyy') : '-'}
        </Typography>
      )
    },
    {
      field: 'dtmUpdatedDate',
      headerName: 'Update Date',
      ...propertiesCommon,
      renderCell: (params: any) => (
        <Typography variant='subtitle2'>
          {params.value ? format(new Date(params.value), 'd, MMM d, yyyy') : '-'}
        </Typography>
      )
    },
    {
      field: '',
      headerName: 'Action',
      flex: 1,
      minWidth: 250,
      maxWidth: 250,
      headerAlign: 'center',
      renderCell: ({ row }: GridCellParams<JobModel>) => <RowOptions row={row} refreshChanges={refreshChanges} />
    }
  ]

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      try {
        const response = await getJobs()
        if (response) {
          setRows(response)
        }
      } catch (error) {
        // console.log(error)
      } finally {
        setLoading(false)
      }
    }
    fetch()
  }, [refresh, paramsV2])

  return (
    <Box>
      <Box textAlign='end' my={2}>
        <WrapperSecurity securityKey={'add_job_btn_customer'}>
          <Button variant='contained' onClick={handleAdd}>
            <Typography color={'white'}>{t('ADD_NEW_TASK')}</Typography>
          </Button>
        </WrapperSecurity>
      </Box>
      <DataGrid
        // checkboxSelection
        rows={rows}
        columns={columns}
        autoHeight
        getRowId={row => row.intJobId}
        components={{ Toolbar: CustomToolbarAdminGrid }}
        pageSize={5}
      />
    </Box>
  )
}

type RowProps = {
  row: JobModel
  refreshChanges: (refresh: boolean) => void
}

const RowOptions = ({ row }: RowProps) => {
  const { t } = useTranslation()
  const saveText = t('DELETE')
  const modalDeleteTask = useRef<DialogExpose>(null)
  const navigate = useNavigate()
  const { setTabValue, setTaskId, taskId, setRefresh, refresh } = jobStore()

  const handleClick = (mode: string, row: any) => {
    if (mode === 'Edit') {
      setTaskId(row.intJobId)
      navigate('Edit')
    } else if (mode === 'Delete') {
      setTaskId(row.intJobId)
      modalDeleteTask.current?.open()
    } else if (mode === 'View') {
      setTabValue('1')
      setTaskId(row.intJobId)
      navigate('View')
    }
  }

  const handleDeleteTask = async () => {
    modalDeleteTask.current?.close()
    const jobData = await getJobById(row.intJobId ?? taskId)
    jobData.intStatusId = 2
    await putJob(jobData)
      .then(() => {
        toast.success(t('DELETED_SUCCESSFULLY'))
        setRefresh(refresh + 1)
      })
      .catch(error => toast.error(error.DescriptionError))
  }

  return (
    <>
      <Box width='100%' display='flex' justifyContent='center'>
        <IconAction title={t('VIEW')} icon='mdi:eye' onClick={() => handleClick('View', row)} />
        <WrapperSecurity securityKey='edit_job_btn_customer'>
          <IconAction title={t('EDIT')} icon='mdi:pencil' onClick={() => handleClick('Edit', row)} />
        </WrapperSecurity>
        <WrapperSecurity securityKey='delete_job_btn_customer'>
          <IconAction
            title={t('DELETE')}
            icon='ic:outline-delete'
            color='error'
            onClick={() => handleClick('Delete', row)}
          />  
        </WrapperSecurity>
      </Box>
      <DialogGeneric ref={modalDeleteTask} title={'DELETE'} confirmText={saveText} confirmFunc={handleDeleteTask}>
        {t('CONFIRM_DELETE_TEXT')}
      </DialogGeneric>
    </>
  )
}

export default TableSchedulerCustomer

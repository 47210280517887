import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { postShopProduct, putShopProduct, ShopProductPut } from 'src/@http/product'
import { CrudView } from 'src/types/forms/CrudView'
import { ProductShopResponse } from 'src/types/models/ShopModels'
import * as yup from 'yup'

interface Props {
  mode?: string
  data?: ProductShopResponse
  closeDialog: () => void
  customerId?: number
  successFunc?: () => void
}

interface ShopProductForm {
  id: number
  intShopProductId: number // for Edit
  legacyNumber: string
  name: string
  intShopProductType: number
  //vchShopProductTypeName: string
  intShopId: number
  intStatusId: number // for Edit
  //vchStatusName: string
  vchSourceId: string
  vchPartNumber: string
  decWeight: number
  decPrice: number
  vchTariffCode: string
  decReserveQty: number
  decReserveQty2: number
  decReserveQty3: number
  vchDescription: string
  sinPackType: number
  //vchPackTypeName: string
  //lastSyncDate: string
  decWidth: number
  decLength: number
  decHeight: number
  vchR4ShopId: string
  dtmCreatedDate: string
  dtmUpdatedDate: string
}

const ShopProductForm = ({ data, mode, closeDialog, customerId, successFunc }: Props) => {
  const { t } = useTranslation()
  const view = mode || CrudView.ADD

  let defaultValues: ShopProductForm = {
    id: 0,
    intShopProductId: 0,
    legacyNumber: '',
    name: '',
    intShopProductType: 1,
    intShopId: customerId ? customerId : 0,
    intStatusId: 1,
    vchSourceId: '',
    vchPartNumber: '',
    decWeight: 0,
    decPrice: 0,
    vchTariffCode: '',
    decReserveQty: 0,
    decReserveQty2: 0,
    decReserveQty3: 0,
    vchDescription: '',
    sinPackType: 1,
    decWidth: 0,
    decLength: 0,
    decHeight: 0,
    vchR4ShopId: '',
    dtmCreatedDate: '',
    dtmUpdatedDate: ''
  }

  if (view === CrudView.EDIT && data) {
    defaultValues = {
      id: data.id,
      intShopProductId: data.intShopProductId,
      legacyNumber: '',
      name: data.name,
      intShopProductType: data.intShopProductType,
      intShopId: data.intShopId,
      intStatusId: data.intStatusId,
      vchSourceId: data.vchSourceId,
      vchPartNumber: data.vchPartNumber,
      decWeight: data.decWeight,
      decPrice: 0,
      vchTariffCode: data.vchTariffCode,
      decReserveQty: data.decReserveQty,
      decReserveQty2: data.decReserveQty2,
      decReserveQty3: data.decReserveQty3,
      vchDescription: data.vchDescription,
      sinPackType: data.sinPackType,
      //lastSyncDate: data.lastSyncDate,
      decWidth: data.decWidth,
      decLength: data.decLength,
      decHeight: data.decHeight,
      vchR4ShopId: data.vchR4ShopId,
      dtmCreatedDate: data.dtmCreatedDate.slice(0, 10),
      dtmUpdatedDate: data.dtmUpdatedDate.slice(0, 10)
    }
  }

  const validations = {
    legacyNumber: yup.string(),
    name: yup.string(),
    intShopProductType: yup.number(),
    vchShopProductTypeName: yup.string(),
    intShopId: yup.number(),
    vchShopName: yup.string(),
    intStatusId: yup.number(),
    vchStatusName: yup.string(),
    vchSourceId: yup.string(),
    vchPartNumber: yup.string(),
    decWeight: yup.number(),
    decPrice: yup.number(),
    vchTariffCode: yup.string(),
    decReserveQty: yup.number(),
    decReserveQty2: yup.number(),
    decReserveQty3: yup.number(),
    vchDescription: yup.string(),
    sinPackType: yup.number(),
    vchPackTypeName: yup.string(),
    lastSyncDate: yup.string(),
    decWidth: yup.number(),
    decLength: yup.number(),
    decHeight: yup.number(),
    vchR4ShopId: yup.string(),
    intCreatedUserId: yup.number(),
    dtmCreatedDate: yup.string(),
    dtmUpdatedDate: yup.string()
  }

  if (view === CrudView.ADD) {
    validations.name = validations.name.required()
    validations.vchDescription = validations.vchDescription.required()
    validations.vchSourceId = validations.vchSourceId.required()
    validations.vchTariffCode = validations.vchTariffCode.required()
    validations.legacyNumber = validations.legacyNumber.required()
    validations.vchPartNumber = validations.vchPartNumber.required()
    validations.vchR4ShopId = validations.vchR4ShopId.required()
  }

  const schemaValidations = yup.object().shape(validations)

  const { control, handleSubmit } = useForm<ShopProductForm>({
    resolver: yupResolver(schemaValidations),
    defaultValues: defaultValues
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onSubmit = async (form: ShopProductForm) => {
    setIsLoading(true)
    if (view === CrudView.ADD) {
      try {
        await postShopProduct({
          name: form.name,
          decHeight: form.decHeight,
          decLength: form.decLength,
          decPrice: form.decPrice,
          decReserveQty: form.decReserveQty,
          decReserveQty2: form.decReserveQty2,
          decReserveQty3: form.decReserveQty3,
          decWeight: form.decWeight,
          decWidth: form.decWidth,
          intShopId: form.intShopId,
          legacyNumber: form.legacyNumber,
          vchSourceId: form.vchSourceId,
          vchR4ShopId: form.vchR4ShopId,
          vchDescription: form.vchDescription,
          vchTariffCode: form.vchTariffCode,
          intShopProductType: form.intShopProductType,
          sinPackType: form.sinPackType,
          vchPartNumber: form.vchPartNumber
        })
        toast.success(t('CREATED_SUCCESS'), { duration: 4000 })
        closeDialog()
        if (successFunc) {
          successFunc()
        }
      } catch (error: any) {
        console.log(error)
        toast.error(error.title, { duration: 4000 })
      }
    } else {
      try {
        await putShopProduct(form as ShopProductPut)
        toast.success(t('UPDATED_SUCCESS'), { duration: 4000 })
        closeDialog()
        if (successFunc) {
          successFunc()
        }
      } catch (error: any) {
        toast.error(error.title, { duration: 4000 })
      }
    }
    setIsLoading(false)
  }

  return (
    <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      <Grid py={2} container rowGap={5} spacing={2}>
        {view === CrudView.EDIT && (
          <>
            <Grid item xs={12} md={4}>
              <TextField fullWidth size='small' value={defaultValues.id} label='Product Id' disabled />
            </Grid>
            <Grid item xs={12} md={4} sx={{ px: {} }}>
              <FormControl fullWidth>
                <Controller
                  name='dtmCreatedDate'
                  control={control}
                  render={({ field: { ...field }, fieldState }) => (
                    <TextField
                      size='small'
                      disabled={true}
                      {...field}
                      label={t('CREATE_DATE')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} sx={{ px: {} }}>
              <FormControl fullWidth>
                <Controller
                  name='dtmUpdatedDate'
                  control={control}
                  render={({ field: { ...field }, fieldState }) => (
                    <TextField
                      size='small'
                      disabled={true}
                      {...field}
                      label={t('UPDATE_DATE')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </>
        )}

        <Grid item xs={12} md={8}>
          <FormControl fullWidth sx={{}}>
            <Controller
              name='name'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  size='small'
                  disabled={CrudView.VIEW === view}
                  {...field}
                  label={t('NAME')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth sx={{}}>
            <Controller
              name='intShopProductType'
              control={control}
              render={({ field, fieldState }: any) => (
                <FormControl error={!!fieldState.error}>
                  <InputLabel>{t('PRODUCT_TYPE')}</InputLabel>
                  <Select
                    {...field}
                    size='small'
                    error={!!fieldState.error}
                    value={field.value}
                    input={<OutlinedInput label={t('PRODUCT_TYPE')} />}
                  >
                    <MenuItem value={1}>Type 1</MenuItem>
                  </Select>

                  <FormHelperText>
                    <Typography color='error' sx={{ fontSize: 12 }}>
                      {fieldState.error?.message}
                    </Typography>{' '}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ px: { xs: 0, md: 1 } }}>
          <FormControl fullWidth>
            <Controller
              name='vchDescription'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  size='small'
                  disabled={CrudView.VIEW === view}
                  {...field}
                  label={t('DESCRIPTION')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Controller
              name='decPrice'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  size='small'
                  type='number'
                  disabled={CrudView.VIEW === view}
                  {...field}
                  label={t('PRICE')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Controller
              name='decWeight'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  size='small'
                  type='number'
                  disabled={CrudView.VIEW === view}
                  {...field}
                  label={t('WEIGHT')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Controller
              name='vchSourceId'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  size='small'
                  disabled={CrudView.VIEW === view}
                  {...field}
                  label={t('SOURCE_ID')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Controller
              name='decReserveQty'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  type='number'
                  size='small'
                  disabled={CrudView.VIEW === view}
                  {...field}
                  label={t('RESERVE_QTY')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Controller
              name='decReserveQty2'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  type='number'
                  size='small'
                  disabled={CrudView.VIEW === view}
                  {...field}
                  label={t('RESERVE_QTY2')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Controller
              name='decReserveQty3'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  type='number'
                  size='small'
                  disabled={CrudView.VIEW === view}
                  {...field}
                  label={t('RESERVE_QTY3')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Controller
              name='vchTariffCode'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  size='small'
                  disabled={CrudView.VIEW === view}
                  {...field}
                  label={t('TARIFF_CODE')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth sx={{}}>
            <Controller
              name='sinPackType'
              control={control}
              render={({ field, fieldState }: any) => (
                <FormControl error={!!fieldState.error}>
                  <InputLabel>{t('PACK_TYPE_NAME')}</InputLabel>
                  <Select
                    {...field}
                    size='small'
                    error={!!fieldState.error}
                    value={field.value}
                    input={<OutlinedInput label={t('PACK_TYPE_NAME')} />}
                  >
                    <MenuItem value={1}>Type 1</MenuItem>
                  </Select>

                  <FormHelperText>
                    <Typography color='error' sx={{ fontSize: 12 }}>
                      {fieldState.error?.message}
                    </Typography>{' '}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Controller
              name='decWidth'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  type='number'
                  size='small'
                  disabled={CrudView.VIEW === view}
                  {...field}
                  label={t('WIDTH')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Controller
              name='decLength'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  type='number'
                  size='small'
                  disabled={CrudView.VIEW === view}
                  {...field}
                  label={t('LENGTH')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Controller
              name='decHeight'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  type='number'
                  size='small'
                  disabled={CrudView.VIEW === view}
                  {...field}
                  label={t('HEIGHT')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth sx={{}}>
            <Controller
              name='intStatusId'
              control={control}
              render={({ field, fieldState }: any) => (
                <FormControl error={!!fieldState.error}>
                  <InputLabel>{t('STATUS')}</InputLabel>
                  <Select
                    {...field}
                    size='small'
                    error={!!fieldState.error}
                    value={field.value}
                    input={<OutlinedInput label={t('STATUS')} />}
                  >
                    <MenuItem value={1}>{t('ENABLED')}</MenuItem>
                    <MenuItem value={2}>{t('DISABLED')}</MenuItem>
                    <MenuItem value={3}>{t('DELETED')}</MenuItem>
                  </Select>

                  <FormHelperText>
                    <Typography color='error' sx={{ fontSize: 12 }}>
                      {fieldState.error?.message}
                    </Typography>{' '}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Controller
              name='legacyNumber'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  size='small'
                  disabled={CrudView.VIEW === view}
                  {...field}
                  label={t('LEGACY_NUMBER')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Controller
              name='vchPartNumber'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  size='small'
                  disabled={CrudView.VIEW === view}
                  {...field}
                  label={t('PART_NUMBER')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Controller
              name='vchR4ShopId'
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <TextField
                  size='small'
                  disabled={CrudView.VIEW === view}
                  {...field}
                  label={t('R4_CUSTOMER_ID')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <LoadingButton variant='contained' sx={{ mx: 4 }} type='submit' loading={isLoading}>
          {CrudView.EDIT === view ? t('EDIT') : t('SAVE')}
        </LoadingButton>
        <Button
          variant='outlined'
          sx={{ mx: 4 }}
          onClick={() => {
            closeDialog()
          }}
        >
          {t('CANCEL')}
        </Button>
      </Box>
    </form>
  )
}

export default ShopProductForm

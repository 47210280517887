import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Card, Grid } from '@mui/material'
import { CrudView } from 'src/types/forms/CrudView'

import AutocompleteCarrierMulti from '../components/carrier/AutocompleteCarrierMulti'
import AutocompletePropsMulti from '../components/companies/AutocompleteCompanyMulti'
import GenericButton from '../components/generic/GenericButton'
import WrapperSecurity from '../components/generic/WrapperSeurityObject'
import CardStatsHorizontalServer from '../components/labels-server/CardLabelAccountListServer'
import DialogAddCardServer from '../components/labels-server/DialogAddCardServer'
import { useCarrierAccount } from '../components/labels-server/label-server-hooks'
import FiltersOrders from '../components/orderEditor/FiltersOrders'
import TextFieldInputAdornment from '../components/orderEditor/TextFieldInputAdornment'

import BalanceAccountTableServer from './balance-account-details'

const LabelsAccountServer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const carriersAccountSummary = useCarrierAccount({
    params: { moduleId: 1, intCarrierId: 0, intCustomerId: 0, intShopId: 0, PageSize: 50, CurrentPage: 1 }
  })

  const [balanceAccountFilterDates, setBalanceAccountFilterDates] = useState<{
    StartDate: string
    EndDate: string
  }>({
    StartDate: '',
    EndDate: ''
  })
  const [courierIdsFilter, setCourierIdsFilter] = useState<number[]>([])
  const [customerIdsFilter, setCustomerIdsFilter] = useState<number[]>([])

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} width={'100%'} textAlign='right'>
        <WrapperSecurity securityKey='add_newaccount_ls_btn'>
          <GenericButton
            onClick={() => navigate('account/new', { state: { mode: CrudView.ADD, couriers: carriersAccountSummary, level: 'ez' } })}
          >
            {t('ADD_NEW_ACCOUNT')}
          </GenericButton>
        </WrapperSecurity>
      </Grid>
      <Grid item xs={12}>
        <CardStatsHorizontalServer data={carriersAccountSummary} />
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ padding: '1rem' }}>
          <Box
            display='flex'
            maxWidth={1000}
            mx='auto'
            alignItems='center'
            columnGap={2}
            my={2}
            justifyContent='flex-start'
          >
            <AutocompletePropsMulti
              size={'small'}
              onSelectClient={client => setCustomerIdsFilter(client)}
              label={t('CUSTOMER')}
            ></AutocompletePropsMulti>
            {/* <AutocompleteClientMulti
              size={'small'}
              onSelectClient={client => logger.log('Client en padre:', client)}
              label={t('CLIENT')}
            /> */}
            <AutocompleteCarrierMulti
              size={'small'}
              onSelectClient={courier => setCourierIdsFilter(courier)}
              label={t('Courier')}
            ></AutocompleteCarrierMulti>
            <TextFieldInputAdornment sx={{ minWidth: 160 }} />
          </Box>
          <FiltersOrders setFilter={filterLimits => setBalanceAccountFilterDates(filterLimits)} />
          <Grid padding={3} container></Grid>
          <Grid pt={4}>
            <BalanceAccountTableServer
              carrierIds={courierIdsFilter}
              customerIds={customerIdsFilter}
              filterDateLimits={balanceAccountFilterDates}
            />
          </Grid>
        </Card>
      </Grid>
      <DialogAddCardServer />
    </Grid>
  )
}

export default LabelsAccountServer

import { useTranslation } from 'react-i18next'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import PageHeader from 'src/@core/components/page-header'

import TableProfiles from '../components/profile/TableProfile'

const ProfilesEditor = () => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <PageHeader title={<Typography variant='h5'>{t('PROFILES_EDITOR')}</Typography>} />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <TableProfiles />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default ProfilesEditor

import { useEffect, useState } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme
} from '@mui/material'
import companiesStore from 'src/zustand/companies'

interface AutocompleteUsersProps {
  onSelectClient: (user: any) => void
  label: string
  disabled?: boolean
  shopSelected?: any
  size?: 'small' | 'medium'
  width?: number | string
  addViewMobile?: boolean
}

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  }
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 160
    }
  }
}

const AutocompletePropsMulti = (props: AutocompleteUsersProps) => {
  const { t } = useTranslation()
  const [viewMobile, setViewModal] = useState<boolean | undefined>(props.addViewMobile)
  const { companies } = companiesStore()
  const theme = useTheme()
  const [personName, setPersonName] = React.useState<any[]>([])

  const getSelectedCompaniesList = (selected: string[]) => {
    const names = selected.map(id => {
      const company = companies.find(c => c.id === parseInt(id))

      return company ? company.vchName : ''
    })

    return names.join(', ')
  }

  useEffect(() => {
    if (props.addViewMobile !== undefined) {
      setViewModal(props.addViewMobile)
    }
  }, [props.addViewMobile])

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value }
    } = event
    setPersonName(typeof value === 'string' ? value.split(',') : value)

    props.onSelectClient(value)
  }

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 350 }}>
        <InputLabel>Customer</InputLabel>
        <Select
          multiple
          size='small'
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label='Customer' />}
          renderValue={selected => getSelectedCompaniesList(selected as string[])}
          sx={{ width: '350px' }}
          MenuProps={MenuProps}
        >
          {companies.map(company => (
            <MenuItem key={company.id} value={company.id} style={getStyles(company.id.toString(), personName, theme)}>
              <Checkbox checked={personName.indexOf(company.id) > -1} />
              {company.vchName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default AutocompletePropsMulti

import { mountStoreDevtool } from 'simple-zustand-devtools'
import { JobModel, JobScheduleModel, JobStepModel } from 'src/types/models/JobModel'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type State = {
  tabValue: string
  taskId: number
  taskScheduleId: number
  taskName: string
  taskObj: JobModel | null
  taskSchedule: JobScheduleModel[] | null
  taskStep: JobStepModel[][] | null
  refresh: number
}

type Actions = {
  setTabValue: (tabValue: string) => void
  setTaskId: (taskId: number) => void
  setRefresh: (refresh: number) => void
  setTaskScheduleId: (taskScheduleId: number) => void
  setTaskName: (taskName: string) => void
  setTaskScheduleModel: (taskSchedule: JobScheduleModel[] | null) => void
  setTaskStepModel: (taskStep: JobStepModel[][] | null) => void
  setTaskModel: (taskObj: JobModel | null) => void
}

const initialState: State = {
  refresh: 0,
  tabValue: '1',
  taskId: 0,
  taskScheduleId: 0,
  taskName: '',
  taskObj: null,
  taskSchedule: null,
  taskStep: null
}

const jobStore = create<State & Actions>()(
  persist(
    set => ({
      ...initialState,
      setTabValue: (tabValue: string) => {
        set({ tabValue: tabValue })
      },
      setTaskId: (taskId: number) => {
        set({ taskId: taskId })
      },
      setRefresh: (refresh: number) => {
        set({ refresh: refresh })
      },
      setTaskScheduleId: (taskScheduleId: number) => {
        set({ taskScheduleId: taskScheduleId })
      },
      setTaskName: (taskName: string) => {
        set({ taskName: taskName })
      },
      setTaskModel: (taskObj: JobModel | null) => {
        set({ taskObj: taskObj })
      },
      setTaskScheduleModel: (taskSchedule: JobScheduleModel[] | null) => {
        set({ taskSchedule: taskSchedule })
      },
      setTaskStepModel: (taskStep: JobStepModel[][] | null) => {
        set({ taskStep: taskStep })
      }
    }),
    {
      name: 'job-store'
    }
  )
)

if (process.env.NODE_ENV === 'development') mountStoreDevtool('job-store', jobStore)

export default jobStore

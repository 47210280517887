import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@iconify/react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import OptionsMenu from 'src/@core/components/option-menu'
import { desiredRoutes } from 'src/types/enums/PagesByLevel'
import { ShopModel } from 'src/types/models/ShopModels'
import appStore from 'src/zustand/app'
// import companiesStore from 'src/zustand/companies'
import customerStore from 'src/zustand/customer'
import profileStore from 'src/zustand/profile'

interface AutocompleteUsersProps {
  onSelectClient: (user: any) => void
  label: string
  readOnly?: boolean
  disabled?: boolean
  shopSelected?: any
  size?: 'small' | 'medium'
  width?: number | string
  addViewMobile?: boolean
}

const AutocompleteClient = (props: AutocompleteUsersProps) => {
  const { t } = useTranslation()
  const [viewMobile, setViewModal] = useState<boolean | undefined>(props.addViewMobile)
  const { onSelectClient, label, shopSelected, readOnly, disabled } = props
  const [value, setValue] = useState<any | null>(null)
  const updateShopInfo = (shop: ShopModel | null) => {
    onSelectClient(shop)
    setShopGlobal(shop)
    setValue(shop || null)

    setParamsV2({
      CustomerId: paramsV2.CustomerId,
      ShopId: shop?.intShopId || -1
    })
  }
  const { setShops, shops, shopGlobal, setShopGlobal } = customerStore()
  const { profile } = profileStore()
  const { setParamsV2, paramsV2 } = appStore()
  const handleChange = (event: any, value: any | null) => {
    updateShopInfo(value)
  }

  useEffect(() => {
    if (shops.length === 0) setShops(profile?.tinUserType)
  }, [])

  useEffect(() => {
    if (props.addViewMobile !== undefined) {
      setViewModal(props.addViewMobile)
    }
  }, [props.addViewMobile])

  const options = shops.map((shop: ShopModel) => {
    return {
      text: t(shop.vchName),
      menuItemProps: {
        sx: { py: 2 },
        onClick: () => handleChange(null, shop)
      }
    }
  })

  const isVisible = desiredRoutes.CLIENT.includes(location.pathname)

  if (!isVisible) {
    return null
  }

  return (
    <>
      {!viewMobile ? (
        <Autocomplete
          size={props.size || 'medium'}
          onChange={handleChange}
          disabled={disabled}
          readOnly={readOnly}
          options={shops}
          value={shopGlobal}
          clearIcon={null}
          getOptionLabel={option => option.vchName}
          id='autocomplete-disableCloseOnSelect'
          renderInput={params => <TextField {...params} sx={{ width: props.width || '180px' }} label={label} />}
        />
      ) : (
        <>
          <OptionsMenu
            icon={<Icon icon='ph:user' color='inherit' />}
            menuProps={{ sx: { '& .MuiMenu-paper': { mt: 4, minWidth: 130, maxHeight: 400 } } }}
            options={options}
            addTitle={{
              title: label
            }}
          />
        </>
      )}
    </>
  )
}

export default AutocompleteClient

import { SvgIcon,Tooltip } from "@mui/material"

const IconRecharge = (props: any) => {
  return (
    <Tooltip title={props.title}>
      <span>
        <SvgIcon onClick={props.onClick} sx={{mb: '-10px', mx: 1, '&:hover': { cursor: 'pointer' }}}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" xmlSpace="preserve">
            <g fill={props.color}>
              <path d="M128.3 113.2c-4.8 11.2-5 28-26.6 38.6 9.4 1.7 12 1.4 19.3-2.7v97h14.2V149c9.2 4.8 12.3 5.5 18.6 1.7-20.9-11.4-20.2-26.5-25.5-37.5zm44-9.8c-5.9 0-10.7 4.3-10.7 9.6s4.8 9.6 10.7 9.6c5.9 0 10.7-4.3 10.7-9.6s-4.7-9.6-10.7-9.6zM46.7 191.9c-2-2.7-3.7-5.6-4.9-8.8 1.2 3.5 2.8 6.4 4.9 8.8z"/>
              <path d="M186.2 58V38c0-21.4-14.2-33.1-31.8-26L71.7 45.3c-12.4 5-23.2 18.1-28.4 32.9 6.4-12.5 19.3-21 34.3-21h.3c-21.3 0-38.5 17.2-38.5 38.5V169c0 21.3 17.2 38.5 38.5 38.5h20.8v-13H84c-17.6 0-29-11.5-29-29v-64.8c0-17.6 9.7-30.5 27.2-30.5h90.3c17.6 0 29 11.7 29 29.3V166c0 17.6-11.6 28.6-29.2 28.6h-14.9v13h20.5c21.3 0 38.5-17.2 38.5-38.5V95.7c.1-18.5-12.8-33.9-30.2-37.7zm-15.8-.8H82.7L153.4 29c13.8-5.6 17-7.1 17 9.8v18.4z"/>
            </g>
          </svg>
        </SvgIcon>
      </span>
    </Tooltip>
  )
}

export default IconRecharge;
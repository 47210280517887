import { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { setPackageTypes } from 'src/@http/labels-server';
import { logger } from 'src/utils/Logger';

interface PackageType {
  intPackageTypeId: number;
  vchName: string;
}

interface SelectorProps {
  title: string;
  onChange: (option: PackageType | null) => void; 
  pkgTypeSelected: number;
  carrierId: number;
  serviceId: number;
}

const SelectorPkgTypes = ({ title, onChange, pkgTypeSelected, carrierId, serviceId }: SelectorProps ) => {
  const [pkgTypes, setPkgTypes] = useState<PackageType[]>([]);
  const [selectedPackageType, setSelectedPackageType] = useState<PackageType | null>(null);

  useEffect(() => {
    const fetchPackageTypes = async () => {
      try {
        const response = await setPackageTypes(carrierId, serviceId);
        setPkgTypes(response);
        const selected = response.find((pt: PackageType) => pt.intPackageTypeId === pkgTypeSelected);
        logger.log("Selected:", selected)
        setSelectedPackageType(selected || null);
      } catch (error) {
        console.error('Error fetching package types:', error);
        setPkgTypes([]);
      }
    };
    fetchPackageTypes();
  }, [carrierId, serviceId, pkgTypeSelected]);

  return (
    <FormControl fullWidth>
      <Autocomplete
        value={selectedPackageType}
        onChange={(event, newValue) => {
          setSelectedPackageType(newValue);
          onChange(newValue);
        }}
        options={pkgTypes}
        getOptionLabel={(option: PackageType) => option.vchName}
        renderInput={(params) => <TextField {...params} label={title} />}
        renderOption={(props, option) => <li {...props}>{option.vchName}</li>}
      />
    </FormControl>
  );
};

export default SelectorPkgTypes;

import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button, Link } from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { t } from 'i18next'

const Img = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(15.75)
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4)
  },
  [theme.breakpoints.down('sm')]: {
    width: 160
  }
}))

const HeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4)
  }
}))

type PropsFileUploadImageSingle = {
  handleFileImageUploaded: (file: File | null) => void
  selectedImage?: File | null
}

const FileUploadImageSingle = (props: PropsFileUploadImageSingle) => {
  const [files, setFiles] = useState<File[]>(props.selectedImage ? [props.selectedImage] : [])

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: { 'image/*': ['.png', '.jpg', '.jpeg', '.gif'] },
    onDrop: (acceptedFiles: File[]) => {
      setFiles(acceptedFiles.map((file: File) => Object.assign(file)))
    }
  })

  const setImagePreview = (image: string | File) => {
    if (image instanceof File) {
      return URL.createObjectURL(image as File)
    } else {
      return image
    }
  }

  const ImageLoaded = () => {
    const file = files[0]

    return (
      <Img
        key={file.name}
        alt={file.name}
        className='single-file-image'
        src={setImagePreview(file)}
        style={{ objectFit: 'cover', width: '90%', cursor: 'pointer' }}
      />
    )
  }

  useEffect(() => {
    if (files.length !== 0) props.handleFileImageUploaded(files[0])
    else props.handleFileImageUploaded(null)
  }, [files])

  return (
    <>
      <Box
        {...getRootProps({ className: 'dropzone' })}
        sx={files.length ? { height: 'fit-content', maxHeight: 920 } : {}}
      >
        <input {...getInputProps()} />
        {files.length ? (
          <ImageLoaded />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: ['column', 'column', 'row'],
              alignItems: 'center',
              cursor: 'pointer'
            }}
          >
            <Img src='/images/cards/upload_image_vector.svg' style={{ width: 200, height: 100 }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: ['center', 'center', 'inherit'] }}>
              <HeadingTypography variant='h5'>{t('TITLE_DROP_FILE')}</HeadingTypography>
              <Typography color='textSecondary' sx={{ '& a': { color: 'primary.main', textDecoration: 'none' } }}>
                Drop file here or{' '}
                <Link href='/' onClick={e => e.preventDefault()}>
                  browse through <br/>
                </Link>{' '}
                your machine
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      {files.length !== 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: '2rem' }}>
          <Button onClick={() => setFiles([])} color='error' variant='outlined'>
            {t('DELETE')}
          </Button>
        </Box>
      )}
    </>
  )
}

export default FileUploadImageSingle

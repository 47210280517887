import * as React from 'react'
import { BaseEditorComponent } from '@handsontable/react'

interface EditorComponentProps {
  onValueChange: (infoRow: InfoRow, newValue: any) => void
}

export interface InfoRow {
  row: number
  col: number
}
export class EditorComponent extends BaseEditorComponent<EditorComponentProps> {
  refCustomEditor: any
  containerStyle: any
  infoRow: InfoRow

  constructor(props: any) {
    super(props)

    this.refCustomEditor = React.createRef()
    this.containerStyle = {
      display: 'none',
      position: 'absolute',
      left: 0,
      top: 0,
      background: '#fff',
      border: '1px solid #000',
      zIndex: 999,
      resize: 'none',
      height: '65px',
      width: '130px'
    }
    this.infoRow = {
      row: 0,
      col: 0
    }

    this.state = {
      value: ''
    }
  }

  setValue(value: any, callback: (() => void) | undefined) {
    this.setState((state, props) => {
      return { value: value.collectionPercentage }
    }, callback)
  }

  getValue() {
    return this.state.value
  }

  open() {
    const { row, col } = this.cellProperties
    const value = this.hotInstance.getDataAtCell(row, col)
    if (value) {
      this.setValue(value, undefined)
    }

    this.refCustomEditor.current.style.display = 'block'
  }

  close(value: number) {
    // const newValue = value ? value : 0
    if (value !== undefined) {
      console.log('Entro al close')
      const newValue = value
      this.setState({ value: newValue })
      this.props.onValueChange(this.infoRow, value)
    }

    this.refCustomEditor.current.style.display = 'none'
  }

  prepare(
    row: number,
    col: number,
    prop: number | string,
    td: { getBoundingClientRect: () => any },
    originalValue: any,
    cellProperties: object
  ) {
    super.prepare(row, col, prop, td, originalValue, cellProperties)

    const { left, top, width, height } = td.getBoundingClientRect()

    this.infoRow = { row, col }

    const editorStyle = this.refCustomEditor.current.style
    const scrollX = window.scrollX
    const scrollY = window.scrollY

    editorStyle.left = `${left + scrollX}px`
    editorStyle.top = `${top + scrollY}px`
    editorStyle.width = `${width}px`
    editorStyle.height = `${height}px`
  }

  stopMousedownPropagation = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  handleChange = (e: any) => {
    this.close(e.target.value)
  }

  onKeyDown(e: any) {
    if (e.keyCode === 13) {
      this.close(Number(e.target.value))
    }
  }

  onBlurChangeValue(e: any) {
    this.close(Number(e.target.value))
  }

  render() {
    return (
      <input
        type='number'
        ref={this.refCustomEditor}
        onMouseDown={this.stopMousedownPropagation}
        style={this.containerStyle}
        placeholder={this.state.value}
        onKeyDown={this.onKeyDown.bind(this)}
        onBlur={this.onBlurChangeValue.bind(this)}
      />
    )
  }
}

import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Icon } from '@iconify/react'
import {
  Button,
  /* Box, Button, */ Card,
  /*Fab, Grid,*/ IconButton,
  Stack /* TextField , Typography*/
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { getTotalsInvoicesByPeriods, postExportInvoicesCarrier } from 'src/@http/carriers'
import { getProcessInvoiceShop } from 'src/@http/shop'
import appStore from 'src/zustand/app'
import invoiceStore from 'src/zustand/invoice'

import CustomToolbarAdminGrid from '../generic/CustomToolbarAdminGrid'
// import CustomAvatar from 'src/@core/components/mui/avatar'
// import CustomChip from 'src/@core/components/mui/chip'

interface Row {
  id: number
  icon: { icon: string; color: string }
  client: { avatar: string; name: string; email: string }
  total: string
  issuedDate: string
  balance: { paid: boolean; amount: string }
}

const columns: GridColumns = [
  {
    flex: 1,
    field: 'intCarrierInvoiceResumeId',
    headerName: 'Resume id',
    minWidth: 100
  },
  {
    flex: 1,
    field: 'vchPeriodName',
    headerName: 'Period',
    minWidth: 150
  },
  {
    flex: 1,
    field: 'intCountInvoice',
    headerName: 'Invoice count',
    minWidth: 250
  },
  // {
  //   flex: 1,
  //   field: 'decTotalShipPrice',
  //   headerName: 'Total ship price',
  //   minWidth: 200,
  //   renderCell: ({ row }) => (
  //     <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
  //       {parseFloat(row.decTotalShipPrice).toLocaleString('en-US', {
  //         style: 'currency',
  //         currency: 'USD',
  //         minimumFractionDigits: 2,
  //         maximumFractionDigits: 2
  //       })}
  //     </div>
  //   )
  // },
  // {
  //   flex: 1,
  //   field: 'decTotalShipPrice',
  //   headerName: 'Total ship price',
  //   minWidth: 200,
  //   renderCell: ({ row }) => (
  //     <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
  //       {parseFloat(row.decTotalShipPrice).toLocaleString('en-US', {
  //         style: 'currency',
  //         currency: 'USD',
  //         minimumFractionDigits: 2,
  //         maximumFractionDigits: 2
  //       })}
  //     </div>
  //   )
  // },
  // {
  //   flex: 1,
  //   field: 'decTotalShipPriceAdicional',
  //   headerName: 'Total ship price Adicional',
  //   minWidth: 200,
  //   renderCell: ({ row }) => (
  //     <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
  //       {parseFloat(row.decTotalShipPriceAdicional).toLocaleString('en-US', {
  //         style: 'currency',
  //         currency: 'USD',
  //         minimumFractionDigits: 2,
  //         maximumFractionDigits: 2
  //       })}
  //     </div>
  //   )
  // },
  {
    flex: 1,
    field: 'decTotalCharge',
    headerName: 'Total charge',
    minWidth: 150,
    renderCell: ({ row }) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {parseFloat(row.decTotalCharge).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </div>
    )
  },
  {
    field: '',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    maxWidth: 250,
    headerAlign: 'center',
    renderCell: ({ row }: CellType) => <RowOptions id={row.id} />
  }
]

interface CellType {
  row: Row
}

const RowOptions = ({ id }: { id: number }) => {
  const handleEyeClick = () => {
    setTabValue('2')
  }

  const { setTabValue } = invoiceStore(state => ({
    setTabValue: state.setTabValue
  }))

  return (
    <Stack display='flex' flexDirection='row' width='100%' justifyContent='space-between'>
      <IconButton size='small'>
        <Icon icon='material-symbols:delete-outline' />
      </IconButton>
      <IconButton size='small' onClick={handleEyeClick}>
        <Icon icon='mdi:eye' />
      </IconButton>
      <IconButton size='small'>
        <Icon icon='mdi:dots-vertical' />
      </IconButton>
    </Stack>
  )
}

const TableRatesGroupByPeriods = () => {
  const { invoiceId } = appStore(state => ({
    invoiceId: state.invoiceId
  }))
  const { invoiceFilter, setIsSearching } = invoiceStore()
  const [processing, setProcessing] = useState(false)
  const [rows, setRows] = useState<any[]>([
    {
      intCarrierInvoiceResumeId: 0,
      vchPeriodName: '',
      decTotalCharge: 0,
      intCountInvoice: 0,
      decTotalShipPrice: 0,
      decTotalShipPriceAdicional: 0,
      id: 0
    }
  ])
  const [isLoading, setIsLoading] = useState(false)
  const [cols, setCols] = useState<GridColumns | null>(null)

  const handleExportInvoices = async () => {
    try {
      setProcessing(true)
      const response = await postExportInvoicesCarrier(
        invoiceId ?? 0,
        invoiceFilter.customerName ?? null,
        invoiceFilter.trackingNumber ?? null,
        invoiceFilter.startDate ?? null,
        invoiceFilter.endDate ?? null,
        1
      )

      const url = window.URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'CourierInvoices.xlsx')
      document.body.appendChild(link)
      link.click()

      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)

      toast.success('File downloaded successfully', { duration: 4000 })
    } catch (error) {
      toast.error('There was an error exporting invoices', { duration: 4000 })
    } finally {
      setProcessing(false)
    }
  }

  const handleProcessInvoices = async () => {
    try {
      setProcessing(true)
      const response = await getProcessInvoiceShop(invoiceId ?? 0)
      if (response) {
        toast.success('Successfully processed invoices', { duration: 4000 })
      } else {
        toast.error('There was an error processing invoices', { duration: 4000 })
      }
    } catch (error) {
      toast.error('There was an error processing invoices', { duration: 4000 })
    } finally {
      setProcessing(false)
    }
  }

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      try {
        const response = await getTotalsInvoicesByPeriods(
          invoiceId ?? 0,
          invoiceFilter.customerName ?? null,
          invoiceFilter.trackingNumber ?? null,
          invoiceFilter.startDate ?? null,
          invoiceFilter.endDate ?? null,
          1
        )
        if (response) {
          setRows(response)
        }
      } catch (error) {
        // console.log(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetch()
    setCols(columns)
    setIsSearching(false)
  }, [invoiceId, invoiceFilter.isSearching])

  return (
    <Card sx={{ mt: 3 }}>
      <Stack
        sx={{ p: 4, flexDirection: { xs: 'column', md: 'row', lg: 'row' } }}
        display='flex'
        justifyContent='flex-end'
      >
        <Button
          variant='contained'
          color='secondary'
          size='small'
          sx={{ marginRight: '10px' }}
          onClick={handleExportInvoices}
          disabled={processing}
        >
          <Icon icon='material-symbols:upload' />
          {processing ? 'Processing' : 'Export'}
        </Button>
        <Button
          variant='contained'
          color='primary'
          size='small'
          sx={{ marginRight: '10px' }}
          onClick={handleProcessInvoices}
          disabled={processing}
        >
          {processing ? 'Processing' : 'Process invoices'}
        </Button>
      </Stack>
      <DataGrid
        checkboxSelection
        disableSelectionOnClick
        autoHeight
        loading={isLoading}
        rowsPerPageOptions={[10, 25, 50]}
        rows={rows}
        columns={cols || []}
        getRowHeight={() => 51}
        components={{ Toolbar: CustomToolbarAdminGrid }}
      />
    </Card>
  )
}

export default TableRatesGroupByPeriods

import { useEffect, useRef, useState } from 'react'
import toast, { Renderable, Toast, ValueFunction } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import PageHeader from 'src/@core/components/page-header'
import { deleteJob, getJobById } from 'src/@http/job'
import CustomToolbarAdminGrid from 'src/pages/components/generic/CustomToolbarAdminGrid'
import { DialogExpose, DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { JobModel } from 'src/types/models/JobModel'
import jobStore from 'src/zustand/Job'

const propertiesCommon = { flex: 1 }

const columns: GridColumns = [
  { field: 'intStepId', headerName: 'Step Id', ...propertiesCommon, minWidth: 100 },
  { field: 'vchName', headerName: 'Step Name', ...propertiesCommon, minWidth: 250 },
  { field: 'sinExecOrder', headerName: 'Exec Order', ...propertiesCommon, minWidth: 150 },
  { field: 'vchExecURL', headerName: 'Exec Order URL', ...propertiesCommon, minWidth: 150 },
  { field: 'vchParameters', headerName: 'Parameters', ...propertiesCommon, minWidth: 150 }
  // { field: 'intCustomerId', headerName: 'Customer Id', ...propertiesCommon, minWidth: 150 },
  // { field: 'intCarrierId', headerName: 'Courier Id', ...propertiesCommon, minWidth: 150 }
  // {
  //   field: '',
  //   headerName: 'Action',
  //   flex: 1,
  //   minWidth: 250,
  //   maxWidth: 250,
  //   headerAlign: 'center',
  //   renderCell: () => <RowOptions />
  // }
]

/* const RowOptions = () => {
  const { t } = useTranslation()
  const { setTabValue } = jobStore()

  const handleClick = (mode: string) => {
    if (mode === 'Detail') {
      setTabValue('1')
    } else if (mode === 'Step') {
      setTabValue('2')
    }
  }

  return (
    <Box width='100%' display='flex' justifyContent='center'>
      <IconAction title={'Task Schedule'} icon='mdi:calendar-check' onClick={() => handleClick('Detail')} />
      <IconAction title={'Task Steps'} icon='mdi:receipt-text-clock' onClick={() => handleClick('Schedule')} />
    </Box>
  )
} */

const TaskStepTabAdmin = () => {
  const [processing, setProcessing] = useState(false)
  const { taskObj, setTaskModel, taskScheduleId, taskId } = jobStore()
  const { t } = useTranslation()
  const saveText = t('DELETE')
  const modalDeleteTask = useRef<DialogExpose>(null)
  const navigate = useNavigate()
  const [rows, setRows] = useState<any[] | undefined | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [cols, setCols] = useState<GridColumns | null>(null)

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      try {
        const response = await getJobById(taskId)
        if (response.lstJobSchedule) {
          const jobSchedule = response.lstJobSchedule.find(
            (jobSchedule: any) => jobSchedule.intJobScheduleId === taskScheduleId
          )?.lstJobStep
          setCols(columns)
          setRows(jobSchedule)
        }
      } catch (error) {
        // console.log(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetch()
  }, [taskId, taskScheduleId])

  const handleClick = (mode: string) => {
    if (mode === 'Edit') {
      navigate('/admin/task/Edit')
    } else if (mode === 'Delete') {
      if (taskObj != null) {
        const updatedTaskObj: JobModel = {
          ...taskObj,
          intStatusId: 2,
          intJobId: taskObj.intJobId || 0
        }
        setTaskModel(updatedTaskObj)
        modalDeleteTask.current?.open()
      } else {
        toast.error('taskObj is null')
      }
    }
  }

  const handleDeleteTask = async () => {
    modalDeleteTask.current?.close()
    if (taskObj != null) {
      await deleteJob(taskObj)
        .then(() => {
          toast.success(t('DELETED_SUCCESSFULLY'))
          navigate('Task')
        })
        .catch((error: { DescriptionError: Renderable | ValueFunction<Renderable, Toast> }) =>
          toast.error(error.DescriptionError)
        )
    } else {
      toast.error('taskObj is null')
    }
  }

  const handleAdd = () => {
    navigate('/admin/job/Add')
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <PageHeader
          title={<Typography variant='h5'>Job Steps</Typography>}
          subtitle={
            <>
              <Typography variant='body2'>Job ID: {taskId}</Typography>
              <Typography variant='body2'>Job Schedule ID: {taskScheduleId}</Typography>
            </>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Stack
              sx={{ p: 4, flexDirection: { xs: 'column', md: 'row', lg: 'row' } }}
              display='flex'
              justifyContent='flex-end'
            >
              <Button variant='contained' onClick={handleAdd} sx={{ marginRight: '10px' }}>
                <Typography color={'white'}>{t('ADD_NEW_TASK')}</Typography>
              </Button>
              <Button
                variant='contained'
                color='secondary'
                size='small'
                sx={{ marginRight: '10px' }}
                onClick={() => handleClick('Edit')}
                disabled={processing}
              >
                {/* <Icon icon='material-symbols:upload' /> */}
                {processing ? 'Processing' : 'Edit Job'}
              </Button>
              <Button
                variant='contained'
                color='primary'
                size='small'
                sx={{ marginRight: '10px' }}
                onClick={() => handleClick('Delete')}
                disabled={processing}
              >
                {processing ? 'Processing' : 'Delete Job'}
              </Button>
              <DialogGeneric
                ref={modalDeleteTask}
                title={'DELETE'}
                confirmText={saveText}
                confirmFunc={handleDeleteTask}
              >
                {t('CONFIRM_DELETE_TEXT')}
              </DialogGeneric>
            </Stack>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Card sx={{ mt: 3 }}>
                      <DataGrid
                        checkboxSelection
                        disableSelectionOnClick
                        autoHeight
                        loading={isLoading}
                        rowsPerPageOptions={[10, 25, 50]}
                        rows={
                          rows ?? [
                            {
                              id: 0,
                              isActive: true,
                              lastModifiedDate: '',
                              intJobStepId: 0,
                              intJobId: 0,
                              intJobScheduleId: 0,
                              vchName: '',
                              sinExecOrder: 0,
                              vchExecURL: '',
                              vchParameters: '',
                              intCustomerId: 0,
                              intCarrierId: 0,
                              intStepId: 0
                            }
                          ]
                        }
                        columns={cols || []}
                        getRowHeight={() => 51}
                        components={{ Toolbar: CustomToolbarAdminGrid }}
                      />
                    </Card>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default TaskStepTabAdmin

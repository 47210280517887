import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { Button, Card, CardContent, CardHeader, Grid, TextField } from '@mui/material'
import jwt_decode from 'jwt-decode'
import { addNewRole, editRole } from 'src/@http/role'
import ProfileListSelector from 'src/pages/components/roles/ProfileListSelector'
import { CrudView } from 'src/types/forms/CrudView'
import { RolModel } from 'src/types/models/RolModel'
import SelectUserTypes from 'src/views/filters/selectors/SelectUserTypes'
// import { logger } from 'src/utils/Logger'

const defaultValues: RolModel = {
  sinRoleId: 0,
  vchName: '',
  // TODO: Agregar selector de perfil para enviar dinamicamente estos 2 datos
  tinUserType: 1,
  intProfileId: 0,
  intStatusId: 0,
  vchStatusName: '',
  intCreatedUserId: 0,
  intUpdatedUserId: 0,
  tinParentRol: 0,
  id: 0,
  dtmCreatedDate: new Date().toISOString(),
  dtmUpdatedDate: new Date().toISOString()
}

const RoleAddOrEdit = () => {
  const [extraValues, setExtraValues] = useState({
    intStatusId: 0,
    vchStatusName: '',
    intCreatedUserId: 0,
    intUpdatedUserId: 0
  })
  const [previewEditMode, setPreviewEditMode] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state } = useLocation()
  const title = state.mode === 'ADD' ? getTitleByMode(state.mode) : getTitleByMode(state.mode, state.data?.sinRoleId)
  const form = useForm<RolModel>({ defaultValues: state.mode === CrudView.ADD ? defaultValues : state.data })

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty }
  } = form
  const btnSaveDisabled = Object.keys(errors).length > 0 || !isDirty

  const getDataActualUser = () => {
    const token = window.localStorage.getItem('token')
    const decodedToken: any = token && jwt_decode(token)
    if (decodedToken) {
      setExtraValues({
        ...extraValues,
        intCreatedUserId: Number(decodedToken.IdUsuario),
        intUpdatedUserId: Number(decodedToken.IdUsuario)
      })
    }
    reset()
  }

  const changeEditMode = () => setPreviewEditMode(false)

  useEffect(() => {
    if (state.mode === CrudView.ADD) {
      extraValues.intStatusId = 1
      extraValues.vchStatusName = 'Active'
      getDataActualUser()
    } else if (state.mode === CrudView.EDIT) {
      setPreviewEditMode(true)
      defaultValues.intProfileId = state.data.intProfileId
      extraValues.intStatusId = state.data.intStatusId
      extraValues.vchStatusName = state.data.vchStatusName
    }
  }, [state.mode])

  const onSubmit = (data: any) => {
    delete data.tinParentRol // TODO: Add send to service
    data.intStatusId = extraValues.intStatusId
    data.vchStatusName = extraValues.vchStatusName
    data.intCreatedUserId = extraValues.intCreatedUserId
    data.intUpdatedUserId = extraValues.intUpdatedUserId

    if (state.mode === CrudView.ADD) {
      delete data.id
      delete data.sinRoleId
      addNewRole(data)
        .then(() => navigate('/roles-editor'))
        .catch(err => toast.error(err.DescriptionError))
    } else if (state.mode === CrudView.EDIT) {
      editRole(data)
        .then(() => navigate('/roles-editor'))
        .catch(err => toast.error(err.DescriptionError))
    }
  }

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        {previewEditMode && (
          <Button
            variant='contained'
            startIcon={<Icon icon={'mdi:pencil'} />}
            fullWidth
            sx={{ mb: 5 }}
            onClick={changeEditMode}
          >
            {t('EDIT')}
          </Button>
        )}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container rowGap={3} spacing={2}>
            {state.mode === CrudView.VIEW && (
              <Grid item xs={12} sm={4}>
                <TextField type='number' label={t('ROLE_ID')} fullWidth {...register('sinRoleId')} disabled={true} />
              </Grid>
            )}
            <Grid item xs={12} sm={state.mode === CrudView.VIEW ? 4 : 6}>
              <TextField
                label={t('NAME')}
                fullWidth
                disabled={state.mode === CrudView.VIEW || previewEditMode}
                {...register('vchName', { required: 'Name is required' })}
                error={!!errors.vchName}
                helperText={errors.vchName?.message}
              />
            </Grid>
            <Grid item xs={12} sm={state.mode === CrudView.VIEW ? 4 : 6}>
              <ProfileListSelector
                disabled={state.mode === CrudView.VIEW || previewEditMode}
                defaultProfile={defaultValues.intProfileId}
                onSelectProfile={
                  profileId => setValue('intProfileId', profileId)
                }
              />
            </Grid>
            <Grid item xs={12} sm={state.mode === CrudView.VIEW ? 4 : 6}>
              <SelectUserTypes
                defaultValue={getValues('tinUserType')}
                disabled={state.mode === CrudView.VIEW || previewEditMode}
                handleSelected={(ut: any) => {
                  setValue('tinUserType', ut)
                }}
              />
            </Grid>
            {state.mode === CrudView.VIEW && (
              <Grid item xs={12} sm={4}>
                <TextField defaultValue={getValues('vchStatusName')} label={t('STATUS')} fullWidth disabled={true} />
              </Grid>
            )}
            {state.mode === CrudView.VIEW && (
              <Grid item xs={12} sm={4}>
                <TextField
                  defaultValue={extraValues.intCreatedUserId}
                  type='number'
                  label={t('CREATED_USER_ID')}
                  fullWidth
                  disabled={true}
                />
              </Grid>
            )}
            {state.mode === CrudView.VIEW && (
              <Grid item xs={12} sm={4}>
                <TextField
                  defaultValue={extraValues.intUpdatedUserId}
                  type='number'
                  label={t('UPDATED_USER_ID')}
                  fullWidth
                  disabled={true}
                />
              </Grid>
            )}
            {state.mode === CrudView.VIEW && (
              <>
                <Grid item xs={12} sm={2}>
                  <DatePicker
                    className='full-width'
                    readOnly
                    disabled
                    selected={new Date(getValues('dtmCreatedDate')) || null}
                    onChange={(date: Date) => console.log(date)}
                    id='read-only-field-date'
                    customInput={<TextField disabled label={t('CREATED_DATE')} />}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <DatePicker
                    readOnly
                    disabled
                    selected={new Date(getValues('dtmUpdatedDate')) || null}
                    onChange={(date: Date) => console.log(date)}
                    id='read-only-field-date'
                    customInput={<TextField disabled label={t('UPDATED_DATE')} />}
                  />
                </Grid>
              </>
            )}
            {state.mode !== CrudView.VIEW && !previewEditMode && (
              <Grid item xs={12} sm={12} sx={{ alignItems: 'center' }}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  sx={{ minWidth: '194px', maxWidth: '194px' }}
                  disabled={btnSaveDisabled}
                >
                  {t('SAVE')}
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

const getTitleByMode = (mode: string, id?: number) => {
  if (mode === CrudView.ADD) return 'Add New Role'
  if (mode === CrudView.EDIT) return `Edit Role: ${id}`
  if (mode === CrudView.VIEW) return `View Role: ${id}`
}

export default RoleAddOrEdit

import { getAsync } from 'src/@http'

// const countryCache = () => `/v1.0/ControllerCacheCountry/cacheCountries/`
// // const countryCache = (customerId: string) => `/v1.0/ControllerCountry/${customerId}`
// const stateCache = (intCountryId: string) => `/v1.0/ControllerCacheState/cacheStates/${intCountryId}`
// const cityCache = (intCountryId: string, intStateId: string) =>
//   `/v1.0/ControllerCacheCity/cacheCity?intCountryId=${intCountryId}&intStateId=${intStateId}`

const country = () => `/v1.0/ControllerCountry?Status=1`

export interface CountryGet {
  id: number
  vchShortName: string
  vchName: string
}



export const getCountry = (): Promise<CountryGet[]> => {
  return getAsync(country())
}
// ** React Imports
import { forwardRef, SyntheticEvent, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Icon } from '@iconify/react'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material'
// ** MUI Imports
import Tab from '@mui/material/Tab'
import { format } from 'date-fns'
import PageHeader from 'src/@core/components/page-header'
import { DateType } from 'src/types/forms/reactDatepickerTypes'
import { logger } from 'src/utils/Logger'
import invoiceStore from 'src/zustand/invoice'
import orderStore from 'src/zustand/order'

import CustomerSelector from '../invoice/CustomerSelector'
import TableRatesGroupByCustomer from '../invoice/TableRatesGroupByCustomer'
import TableRatesGroupByPeriods from '../invoice/TableRatesGroupByPeriods'
import TableRatesInvoice from '../invoice/TableRatesInvoice'

interface CustomInputProps {
  dates: Date[]
  label: string
  end: number | Date
  start: number | Date
  setDates?: (value: Date[]) => void
}

const CustomInput = forwardRef((props: CustomInputProps, ref) => {
  const startDate = props.start !== null ? format(props.start, 'MM/dd/yyyy') : ''
  const endDate = props.end !== null ? ` - ${format(props.end, 'MM/dd/yyyy')}` : null

  const value = `${startDate}${endDate !== null ? endDate : ''}`
  props.start === null && props.dates.length && props.setDates ? props.setDates([]) : null
  const updatedProps = { ...props }
  delete updatedProps.setDates

  return <TextField size='small' fullWidth inputRef={ref} {...updatedProps} label={props.label || ''} value={value} />
})

const CarrierInvoiceTab = () => {
  // ** State
  const { carrierName } = orderStore(state => ({
    carrierName: state.carrierName
  }))
  const [dates, setDates] = useState<Date[]>([])
  const [hasExecutedEffect, setHasExecutedEffect] = useState(false)
  const [endDateRange, setEndDateRange] = useState<DateType>(null)
  const [startDateRange, setStartDateRange] = useState<DateType>(null)
  const { setTrackingNumber, setStartDate, setEndDate, setIsSearching, setInvoiceFilter, setTabValue, invoiceFilter } =
    invoiceStore()
  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }
  const handleSearchClick = () => {
    setIsSearching(true)
  }

  const getTwoMonthsAgo = () => {
    const today = new Date()
    const twoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, 1)

    return twoMonthsAgo
  }

  useEffect(() => {
    if (invoiceFilter.tabValue === '2' && invoiceFilter.customerName != null && !hasExecutedEffect) {
      setStartDateRange(null)
      setEndDateRange(null)
      setHasExecutedEffect(true)
    }
  }, [invoiceFilter.tabValue, invoiceFilter.customerName, hasExecutedEffect])

  useEffect(() => {
    setStartDate(getTwoMonthsAgo().toISOString().slice(0, 19))
    setEndDate(new Date().toISOString().slice(0, 19))
    setStartDateRange(getTwoMonthsAgo())
    setEndDateRange(new Date())
  }, [])

  const handleOnChangeRange = (dates: any) => {
    const [start, end] = dates
    if (start !== null && end !== null) {
      setDates(dates)
      setEndDate(end.toISOString().slice(0, 19))
      setStartDate(start.toISOString().slice(0, 19))
    } else {
      setEndDate(null)
      setStartDate(null)
    }
    setStartDateRange(start)
    setEndDateRange(end)
  }

  useEffect(() => {
    setInvoiceFilter({
      startDate: getTwoMonthsAgo().toISOString().slice(0, 19),
      endDate: new Date().toISOString().slice(0, 19),
      isSearching: false,
      isProp: false,
      intStatusId: 1,
      tabValue: '1'
    })
  }, [])

  return (
    <Card style={{ marginTop: '10px' }}>
      <CardContent>
        <TabContext value={invoiceFilter.tabValue}>
          <PageHeader
            title={<Typography variant='h5'>{carrierName} invoice list</Typography>}
            // subtitle={<Typography variant='body2'>{carrierName} </Typography>}
          />
          <Grid container spacing={4}>
            <Grid item xs={12} lg={12} sm={4}>
              <TabList onChange={handleChange} aria-label='simple tabs example'>
                <Tab value='1' label='Customer' />
                <Tab value='2' label='Detail' />
                <Tab value='3' label='Period' />
              </TabList>
            </Grid>
            <Grid item xs={12} lg={4} sm={4}>
              <CustomerSelector onSelectCustomer={customer => logger.log('Customer en padre:', customer)} />
            </Grid>
            <Grid item xs={12} lg={4} sm={4}>
              <TextField
                size='small'
                fullWidth
                label='Traking Number'
                onChange={e => setTrackingNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} lg={3} sm={3}>
              <DatePicker
                isClearable
                selectsRange
                monthsShown={1}
                endDate={endDateRange}
                selected={startDateRange}
                startDate={startDateRange}
                shouldCloseOnSelect={false}
                id='date-range-picker-months'
                onChange={handleOnChangeRange}
                customInput={
                  <CustomInput
                    dates={dates}
                    setDates={setDates}
                    label='Date Range'
                    end={endDateRange as number | Date}
                    start={startDateRange as number | Date}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} lg={1} sm={1} display='flex' sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
              <Button size='small' variant='contained' onClick={handleSearchClick}>
                <Icon fontSize={25} color='white' icon='bi:search' />
              </Button>
            </Grid>
          </Grid>
          <TabPanel sx={{ p: 0, width: '100%' }} value='1'>
            <TableRatesGroupByCustomer />
          </TabPanel>
          <TabPanel sx={{ p: 0, width: '100%' }} value='2'>
            <TableRatesInvoice />
          </TabPanel>
          <TabPanel sx={{ p: 0, width: '100%' }} value='3'>
            <TableRatesGroupByPeriods />
          </TabPanel>
        </TabContext>
      </CardContent>
    </Card>
  )
}

export default CarrierInvoiceTab

import { RefObject, useEffect, useRef, useState } from 'react'
import { HotColumn, HotTable } from '@handsontable/react'
import { CircularProgress, Grid, Stack, useTheme } from '@mui/material'
import Handsontable from 'handsontable'
import { initialConfigBtnSave } from 'src/pages/rate-sheets/customers/carriers'
import { RateSheet } from 'src/types/models/RateSheetModel'
import { formatedData } from 'src/utils/actionsHandsontable'
import appStore from 'src/zustand/app'
import customerStore from 'src/zustand/customer'
import serviceStore from 'src/zustand/services'

import { EditorComponent, InfoRow } from './customComponents/CustomEditor'
import { changeEditedCellStyle } from './customComponents/CustomRenderer'
import { cellRendererRateSheet } from './customComponents/CustomRenderer'
import { TypeTable } from './FilterCarrierRateSheet'
import CardCreate from './NotFoundCardCreate'

const configurationColWeight = (instance: Handsontable.Core, td: HTMLTableCellElement, row: number, weights: any[]) => {
  const rowIndex = instance.toPhysicalRow(row)
  let val
  if (weights) {
    val = weights[rowIndex]
    td.innerHTML = val
  }
  td.style.backgroundColor = '#f0f0f0'
  td.style.display = 'flex'
  td.style.justifyContent = 'center'
  td.style.alignItems = 'center'
  td.style.minHeight = '79px'
  td.style.maxHeight = '79px'

  return { td, val }
}

const TableRateSheetCustomer = (props: { [key: string]: any }) => {
  const theme = useTheme()
  const [clipboard, setClipboard] = useState(null)
  const [lastKey, setLastKey] = useState('')
  const { setConfigBtnSave, configBtnSave, dataUser } = props
  const [topLoading, setTopLoading] = useState(false)
  const {
    changeValueCellCustomer,
    loading,
    headers,
    weights,
    typeTableRateSheet,
    cellListShop,
    operationToPercentageSelectedCells,
    setRowsAndCols,
    setLoading,
    isRenderTableError
  } = appStore()

  const width = 85

  const { addSubstract, setShowBtnAddSubstract, setSelectedCellCoordinates, selectedCellCoordCustomer } =
    customerStore()

  const { measure } = serviceStore()

  const [list, setList] = useState<RateSheet[]>([])
  // const [weights, setWeights] = useState<any[]>([])
  // const [headers, setHeaders] = useState<any[]>([])
  const tablesRef: Record<string, RefObject<HotTable>> = {
    discount: useRef<HotTable>(null),
    markup: useRef<HotTable>(null),
    setrate: useRef<HotTable>(null)
  }
  const [changesCells, setChangesCells] = useState<any[]>([])

  let autoFillRecorder: any

  const beforeAutofillByTable = (tableName: string, targetRange: any) => {
    const { from, to } = targetRange
    const dataMap: any[] = []
    for (let j = from.col; j <= to.col; j++) {
      for (let i = from.row; i <= to.row; i++) {
        const cell = tablesRef[tableName].current?.hotInstance?.getDataAtCell(i, j)
        const record = { cell, row: i, col: j }
        dataMap.push(record)
      }
    }
    autoFillRecorder = dataMap
  }

  const afterAutofill = (tableName: string, prop: string) => {
    autoFillRecorder.forEach((cellRecord: any) => {
      const table = tablesRef[tableName].current?.hotInstance
      const cell = table?.getDataAtCell(cellRecord.row, cellRecord.col)

      table?.setDataAtCell(cellRecord.row, cellRecord.col, {
        ...cellRecord.cell,
        [prop]: cell[prop]
      })
      handleCellValueChange({ row: cellRecord.row, col: cellRecord.col }, cell[prop], tableName, prop)
      autoFillRecorder = []
    })
  }

  const configTables = {
    licenseKey: 'non-commercial-and-evaluation',
    rowHeights: 80,
    width: '100%',
    rowHeaders: true,
    height: (screen.height - 300 || 0) - 50
  }

  const getTypeTableSelected = (type: string): boolean => typeTableRateSheet === type

  useEffect(() => {
    if (headers.length === 0 && weights.length === 0) {
      clearTableData()
    } else {
      // setRowsAndCols( cellListShop.filter(cell => cell.tinUnitMeasureId === measure ))
      measure === '-1'
        ? setList(formatedData(cellListShop, 'customer'))
        : setList(
            formatedData(
              cellListShop.filter(cell => cell.tinUnitMeasureId === measure),
              'customer'
            )
          )
    }
  }, [headers, weights])

  useEffect(() => {
    measure === '-1'
      ? setRowsAndCols(cellListShop)
      : setRowsAndCols(cellListShop.filter(cell => cell.tinUnitMeasureId === measure))
  }, [measure])

  useEffect(() => {
    if (addSubstract === 0) {
      setList(formatedData(cellListShop, 'customer'))
    }
  }, [addSubstract])

  const getPropAccordingTable = (table: string) => {
    if (table === 'markup') return 'markupCustomer'
    if (table === 'discount') return 'discountCustomer'
    if (table === 'setrate') return 'setRateCustomer'

    return ''
  }

  const handleOperationOnPercentageSelectedCells = () => {
    const tableName = selectedCellCoordCustomer?.table || ''
    if (selectedCellCoordCustomer?.coords && tableName !== '') {
      const prop = getPropAccordingTable(tableName)
      const { columnEnd, columnStart, rowEnd, rowStart } = selectedCellCoordCustomer?.coords
      const table = tablesRef[tableName].current?.hotInstance
      if (table) {
        const cells = []
        for (let r = rowStart; r <= rowEnd; r++) {
          for (let c = columnStart; c <= columnEnd; c++) {
            const cell = table?.getDataAtCell(r, c)
            cells.push(cell)
          }
        }
        operationToPercentageSelectedCells(cells, prop, selectedCellCoordCustomer.operation, tableName)
      }
    }
  }

  useEffect(() => {
    if (selectedCellCoordCustomer !== null && selectedCellCoordCustomer?.applyOperation) {
      handleOperationOnPercentageSelectedCells()
    }
  }, [selectedCellCoordCustomer])

  useEffect(() => {
    clearTableData()
  }, [])

  const clearTableData = () => {
    for (const ref in tablesRef) {
      const ht = tablesRef[ref].current?.hotInstance
      ht?.clear()
      ht?.loadData([])
      ht?.destroy()
    }
    setList([])
    setConfigBtnSave(initialConfigBtnSave)
    setChangesCells([])
    setSelectedCellCoordinates(null)
  }

  const handleCellValueChange = (infoRow: InfoRow, newValue: any, table: string, prop: string) => {
    if (newValue !== undefined) {
      let hotInstance
      if (tablesRef[table]?.current) {
        hotInstance = tablesRef[table].current?.hotInstance
        if (hotInstance) {
          const currentValue = hotInstance.getDataAtCell(infoRow.row, infoRow.col)
          const newCellValue = {
            ...currentValue,
            [prop]: Number(newValue),
            editionMode: {
              editedCell: true,
              table
            }
          }
          // logger.log('New cell value:', newCellValue)
          hotInstance.setDataAtCell(infoRow.row, infoRow.col, newCellValue)
          changeValueCellCustomer(newCellValue, prop)
        }
      }
    }
    setConfigBtnSave((config: any) => {
      return { ...config, showBtn: true }
    })
  }

  const handlePasteCustom = (data: any, coords: any, propToModify: string, tableRef: string) => {
    const { startRow, startCol } = coords[0]
    const res = data
    const formattedTable = res
      .map((row: any, rowIndex: number) =>
        row.map((cell: any, colIndex: number) => {
          const valueParse = typeof cell === 'number' ? cell : parseFloat(cell.replace(/,/g, ''))
          const destRow = startRow + rowIndex
          const destCol = startCol + colIndex
          const currentValue = tablesRef[tableRef].current?.hotInstance?.getDataAtCell(destRow, destCol)
          const newCellValue = {
            ...currentValue,
            [propToModify]: valueParse,
            editionMode: {
              editedCell: true,
              table: tableRef
            }
          }

          changeValueCellCustomer(newCellValue, propToModify)

          return [destRow, destCol, newCellValue]
        })
      )
      .flat()

    tablesRef[tableRef].current?.hotInstance?.setDataAtCell(formattedTable)
    setConfigBtnSave((config: any) => {
      return { ...config, showBtn: true }
    })
  }

  const createDataRange = (table: any, value: any) => {
    const m1: any = []
    const selected = table.getSelectedRange() || []
    const { from, to } = selected[0]
    const array = new Array(to.col - from.col + 1).fill(value)
    for (let j = 0; j <= to.row - from.row; j++) {
      m1.push(array)
    }

    return m1
    // const value = clipboard ? clipboard[0][0]: ''
  }

  const handlePasteCells = async (data: any, coords: any, table: any, tableName: string, prop: string) => {
    setTopLoading(true)

    const selected = table.getSelectedRange() || []
    const { from, to } = selected[0]

    const m = await navigator.clipboard.readText()

    const dataSelected = m.includes('[object Object]') ? clipboard : data
    const value = dataSelected ? dataSelected[0][0] : ''
    if (from.row === to.row && from.col === to.col) {
      handlePasteCustom(dataSelected, coords, prop, tableName)
    } else {
      const rangeCopied = createDataRange(table, value)
      handlePasteCustom(rangeCopied, coords, prop, tableName)
    }

    setTopLoading(false)
  }

  const handleCopyCells = (data: any, prop: string) => {
    const modifiedData = data.map((row: any) =>
      row.map((cell: any) => String(cell[prop])).filter((cell: any) => cell !== 'undefined')
    )

    navigator.clipboard.writeText(modifiedData.map((row: any) => row.join('\t')).join('\n'))
    // data = data.map((row: any) => row.map((cell: any) => String(cell.markupCustomer)))

    setClipboard(modifiedData)
  }

  return (
    <>
      {loading ? (
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <CircularProgress />
        </Grid>
      ) : isRenderTableError ? (
        <CardCreate />
      ) : (
        <Stack position={'relative'}>
          {/* Top Loader */}
          {topLoading && (
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              position={'absolute'}
              top={0}
              left={0}
              right={0}
              bottom={0}
              zIndex={100}
              style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
            >
              <CircularProgress />
            </Grid>
          )}
          {/* Discount */}
          {getTypeTableSelected(TypeTable.DISCOUNT) && (
            <HotTable
              ref={tablesRef['discount']}
              {...configTables}
              data={list}
              colHeaders={headers}
              stretchH={'all'}
              beforeCopy={(data: any) => {
                handleCopyCells(data, 'discountCustomer')
              }}
              numericFormat={{
                pattern: '0,0.00'
              }}
              beforeKeyDown={e => {
                if (['Control', 'Command', 'Meta'].includes(lastKey) && e.key === 'a') {
                  e.stopPropagation()
                  e.preventDefault()
                  console.log('select all')
                  tablesRef['discount'].current?.hotInstance?.selectCell(
                    0,
                    0,
                    tablesRef['discount'].current?.hotInstance?.countRows() - 1,
                    tablesRef['discount'].current?.hotInstance?.countCols() - 1
                  )
                }
                setLastKey(e.key)
              }}
              beforePaste={(data: any, coords: any) => {
                setLoading(true)
                handlePasteCells(
                  data,
                  coords,
                  tablesRef['discount'].current?.hotInstance,
                  'discount',
                  'discountCustomer'
                )
              }}
              afterSelectionEnd={(row: number, column: number, row2: number, column2: number) => {
                if (row !== -1 && column !== -1 && row2 !== -1 && column2 !== -1) {
                  setShowBtnAddSubstract(true)
                  const coords = { rowStart: row, columnStart: column, rowEnd: row2, columnEnd: column2 }
                  setSelectedCellCoordinates(coords, false, 'discount')
                }
              }}
              afterChange={(changes, source) => {
                changes?.forEach(([row, col, oldValue, newValue]) => {
                  if (newValue === null) {
                    const hotInstance = tablesRef['discount'].current?.hotInstance
                    const newCellValue = {
                      ...oldValue,
                      discountCustomer: 0,
                      editionMode: {
                        editedCell: true,
                        table: 'discount'
                      }
                    }
                    hotInstance?.setDataAtCell(row, Number(col), newCellValue)
                  }
                })
              }}
              beforeAutofill={(data: any, sourceRange: any, targetRange: any, direction: string) => {
                beforeAutofillByTable('discount', targetRange)
              }}
              autoColumnSize
              colWidths={150}
              afterAutofill={(data: any, sourceRange: any, targetRange: any) => {
                afterAutofill('discount', 'discountCustomer')
              }}
              afterPaste={() => {
                setLoading(false)
              }}
            >
              {headers.map((header: any, index: number) =>
                header === 'Weight' ? (
                  <HotColumn
                    key={index}
                    width={width}
                    height={79}
                    title={'Weight'}
                    readOnly
                    renderer={(instance, td, row, col, prop, value, cellProperties) => {
                      if (col === 0) {
                        const conf = configurationColWeight(instance, td, row, weights)
                        Handsontable.renderers.TextRenderer(instance, conf.td, row, col, prop, conf.val, cellProperties)
                      }
                    }}
                  />
                ) : (
                  <HotColumn
                    key={index}
                    width={150}
                    title={header}
                    renderer={(instance, td, row, col, prop, value, cellProperties) => {
                      const cell = changeEditedCellStyle(value, theme, td, 'discount')
                      cellRendererRateSheet(instance, cell, row, col, prop, value, cellProperties, 'discount')
                    }}
                  >
                    <EditorComponent
                      hot-editor
                      editorColumnScope={0}
                      onValueChange={(infoRow, newValue) =>
                        handleCellValueChange(infoRow, newValue, 'discount', 'discountCustomer')
                      }
                    />
                  </HotColumn>
                )
              )}
            </HotTable>
          )}

          {/* Markup */}
          {getTypeTableSelected(TypeTable.MARKUP) && (
            <HotTable
              ref={tablesRef['markup']}
              {...configTables}
              data={list}
              colHeaders={headers}
              stretchH={'all'}
              contextMenu={['copy', 'cut']}
              selectionMode='multiple'
              copyPaste
              numericFormat={{
                pattern: '0,0.00'
              }}
              beforeCopy={(data: any) => {
                handleCopyCells(data, 'markupCustomer')
              }}
              beforePaste={(data: any, coords: any) => {
                setLoading(true)
                handlePasteCells(data, coords, tablesRef['markup'].current?.hotInstance, 'markup', 'markupCustomer')
              }}
              afterPaste={() => {
                setLoading(false)
              }}
              beforeAutofill={(data: any, sourceRange: any, targetRange: any, direction: string) => {
                beforeAutofillByTable('markup', targetRange)
              }}
              afterSelectionEnd={(row: number, column: number, row2: number, column2: number) => {
                if (row !== -1 && column !== -1 && row2 !== -1 && column2 !== -1) {
                  setShowBtnAddSubstract(true)
                  const coords = { rowStart: row, columnStart: column, rowEnd: row2, columnEnd: column2 }
                  setSelectedCellCoordinates(coords, false, 'markup')
                }
              }}
              afterChange={(changes, source) => {
                changes?.forEach(([row, col, oldValue, newValue]) => {
                  if (newValue === null) {
                    const hotInstance = tablesRef['markup'].current?.hotInstance
                    const newCellValue = {
                      ...oldValue,
                      discountCustomer: 0,
                      editionMode: {
                        editedCell: true,
                        table: 'markup'
                      }
                    }
                    hotInstance?.setDataAtCell(row, Number(col), newCellValue)
                  }
                })
              }}
              afterAutofill={(data: any, sourceRange: any, targetRange: any) => {
                afterAutofill('markup', 'markupCustomer')
              }}
            >
              {headers.map((header: any, index: number) =>
                header === 'Weight' ? (
                  <HotColumn
                    key={index}
                    width={width}
                    height={79}
                    title={'Weight'}
                    readOnly
                    disableVisualSelection
                    renderer={(instance, td, row, col, prop, value, cellProperties) => {
                      if (col === 0) {
                        const conf = configurationColWeight(instance, td, row, weights)
                        Handsontable.renderers.TextRenderer(instance, conf.td, row, col, prop, conf.val, cellProperties)
                      }
                    }}
                  />
                ) : (
                  <HotColumn
                    key={index}
                    width={150}
                    title={header}
                    renderer={(instance, td, row, col, prop, value, cellProperties) => {
                      const cell = changeEditedCellStyle(value, theme, td, 'markup')
                      cellRendererRateSheet(instance, cell, row, col, prop, value, cellProperties, 'markup')
                    }}
                  >
                    <EditorComponent
                      hot-editor
                      editorColumnScope={0}
                      onValueChange={(infoRow, newValue) =>
                        handleCellValueChange(infoRow, newValue, 'markup', 'markupCustomer')
                      }
                    />
                  </HotColumn>
                )
              )}
            </HotTable>
          )}

          {/* Set Rate */}
          {getTypeTableSelected(TypeTable.SETRATE) && (
            <HotTable
              ref={tablesRef['setrate']}
              {...configTables}
              data={list}
              colHeaders={headers}
              stretchH={'all'}
              beforeCopy={(data: any) => {
                handleCopyCells(data, 'setRateCustomer')
              }}
              beforePaste={(data: any, coords: any) => {
                setLoading(true)
                handlePasteCells(data, coords, tablesRef['setrate'].current?.hotInstance, 'setrate', 'setRateCustomer')
              }}
              afterPaste={() => {
                setLoading(false)
              }}
              numericFormat={{
                pattern: '0,0.00'
              }}
              beforeAutofill={(data: any, sourceRange: any, targetRange: any, direction: string) => {
                beforeAutofillByTable('setrate', targetRange)
              }}
              afterChange={(changes, source) => {
                changes?.forEach(([row, col, oldValue, newValue]) => {
                  if (newValue === null) {
                    const hotInstance = tablesRef['setrate'].current?.hotInstance
                    const newCellValue = {
                      ...oldValue,
                      discountCustomer: 0,
                      editionMode: {
                        editedCell: true,
                        table: 'setrate'
                      }
                    }
                    hotInstance?.setDataAtCell(row, Number(col), newCellValue)
                  }
                })
              }}
              afterAutofill={() => {
                afterAutofill('setrate', 'setRateCustomer')
              }}
            >
              {headers.map((header: any, index: number) =>
                header === 'Weight' ? (
                  <HotColumn
                    key={index}
                    width={width}
                    height={79}
                    title={'Weight'}
                    readOnly
                    renderer={(instance, td, row, col, prop, value, cellProperties) => {
                      if (col === 0) {
                        const conf = configurationColWeight(instance, td, row, weights)
                        Handsontable.renderers.TextRenderer(instance, conf.td, row, col, prop, conf.val, cellProperties)
                      }
                    }}
                  />
                ) : (
                  <HotColumn
                    key={index}
                    width={150}
                    title={header}
                    renderer={(instance, td, row, col, prop, value, cellProperties) => {
                      const cell = changeEditedCellStyle(value, theme, td, 'setrate')
                      cellRendererRateSheet(instance, cell, row, col, prop, value, cellProperties, 'setrate')
                    }}
                  >
                    <EditorComponent
                      hot-editor
                      editorColumnScope={0}
                      onValueChange={(infoRow, newValue) =>
                        handleCellValueChange(infoRow, newValue, 'setrate', 'setRateCustomer')
                      }
                    />
                  </HotColumn>
                )
              )}
            </HotTable>
          )}
        </Stack>
      )}
    </>
  )
}

export default TableRateSheetCustomer

import { getAsync } from 'src/@http'

const countryCache = () => `/v1.0/ControllerCacheCountry/cacheCountries/`
// const countryCache = (customerId: string) => `/v1.0/ControllerCountry/${customerId}`
const stateCache = (intCountryId: string) => `/v1.0/ControllerCacheState/cacheStates/${intCountryId}`
const cityCache = (intCountryId: string, intStateId: string) =>
  `/v1.0/ControllerCacheCity/cacheCity?intCountryId=${intCountryId}&intStateId=${intStateId}`

export interface CountryCacheGet {
  description: string
  id: number
  isActive: boolean
  lastModifiedDate: string
}

export interface State {
  intCountryId: number
  description: string
  id: number
  isActive: boolean
  lastModifiedDate: null
}

export const getCountryCache = (): Promise<CountryCacheGet[]> => {
  return getAsync(countryCache())
}

export const getStateCache = (intCountryId: string): Promise<any> => {
  return getAsync(stateCache(intCountryId))
}

export const getCityCache = (intCountryId: string, intStateId: string): Promise<any> => {
  return getAsync(cityCache(intCountryId, intStateId))
}
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import {
  Button,
  /* Box, Button, */ Card,
  /*Fab, Grid,*/ IconButton,
  Stack /* TextField , Typography*/
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { format } from 'date-fns'
// import CustomAvatar from 'src/@core/components/mui/avatar'
import CustomChip from 'src/@core/components/mui/chip'
import { postCarrierCustomerInvoices, postExportInvoicesCarrier } from 'src/@http/carriers'
import { getProcessInvoiceShop } from 'src/@http/shop'
import { CarrierCustomerInvoiceModel } from 'src/types/models/CarrierModel'
import appStore from 'src/zustand/app'
import invoiceStore from 'src/zustand/invoice'

import CustomToolbarAdminGrid from '../generic/CustomToolbarAdminGrid'

interface Row {
  id: number
  icon: { icon: string; color: string }
  client: { avatar: string; name: string; email: string }
  total: string
  issuedDate: string
  balance: { paid: boolean; amount: string }
}

const columns: GridColumns = [
  {
    flex: 1,
    field: 'vchCustomerName',
    headerName: 'Customer',
    minWidth: 100
  },
  {
    flex: 1,
    field: 'vchTrackingNumber',
    headerName: 'Tracking Number',
    minWidth: 250
  },
  {
    flex: 1,
    field: 'vchRef1',
    headerName: 'Ref1',
    minWidth: 150
  },
  {
    flex: 1,
    field: 'vchRef2',
    headerName: 'Ref2',
    minWidth: 100
  },
  {
    flex: 1,
    field: 'vchRef3',
    headerName: 'Ref3',
    minWidth: 90
  },
  {
    flex: 3,
    field: 'WIP1',
    headerName: 'Shipped Weight',
    minWidth: 150
  },
  {
    flex: 1,
    field: 'decWeight',
    headerName: 'Weight',
    minWidth: 100
  },
  {
    flex: 1,
    field: 'WIP2',
    headerName: 'Zone',
    minWidth: 100
  },
  {
    flex: 1,
    field: 'WIP3',
    headerName: 'Ship Method',
    minWidth: 150
  },
  {
    flex: 1,
    field: 'WIP4',
    headerName: 'Ship Method 2',
    minWidth: 150
  },
  {
    flex: 1,
    field: 'dtmShipDate',
    headerName: 'Ship Date',
    minWidth: 100,
    renderCell: ({ value }) => {
      const formattedDate = value ? format(new Date(value), 'MM/dd/yyyy') : ''

      return <div>{formattedDate}</div>
    }
  },
  {
    flex: 1,
    field: 'WIP5',
    headerName: 'Tier',
    minWidth: 90
  },
  {
    align: 'center',
    flex: 1,
    field: 'vchStatusName',
    headerName: 'Status',
    headerAlign: 'center',
    minWidth: 100,
    renderCell: ({ row }) => (
      <Stack display='flex' flexDirection='row' justifyContent='center'>
        <CustomChip
          label={row.vchStatusName}
          skin='light'
          color={row.vchStatusName === 'Active' ? 'success' : 'error'}
        />
      </Stack>
    )
  },
  {
    flex: 1,
    field: 'WIP6',
    headerName: 'Prediscount Freight',
    minWidth: 170
  },
  {
    flex: 1,
    field: 'WIP7',
    headerName: 'Elite Freight',
    minWidth: 120
  },
  {
    flex: 1,
    field: 'WIP8',
    headerName: 'Customer Freight',
    minWidth: 150
  },
  {
    flex: 1,
    field: 'vchCharge',
    headerName: 'Total Customer',
    minWidth: 140,
    renderCell: ({ row }) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {parseFloat(row.vchCharge).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </div>
    )
  },
  {
    field: '',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    maxWidth: 250,
    headerAlign: 'center',
    renderCell: ({ row }: CellType) => <RowOptions id={row.id} rowData={row} />
  }
]

interface CellType {
  row: CarrierCustomerInvoiceModel
}
interface RowOptionsProps {
  id: number
  rowData: CarrierCustomerInvoiceModel
}

const RowOptions = ({ id, rowData }: RowOptionsProps) => {
  const { setInvoiceData } = invoiceStore()
  const navigate = useNavigate()
  const handlePreviewClick = () => {
    setInvoiceData(rowData)
    navigate(`/invoice/preview`)
  }

  return (
    <Stack display='flex' flexDirection='row' width='100%' justifyContent='space-between'>
      <IconButton size='small'>
        <Icon icon='material-symbols:delete-outline' />
      </IconButton>
      <IconButton size='small' onClick={handlePreviewClick}>
        <Icon icon='mdi:eye' />
      </IconButton>
      {/* <IconButton size='small'>
        <Icon icon='mdi:dots-vertical' />
      </IconButton> */}
    </Stack>
  )
}

const TableRatesInvoice = () => {
  const { invoiceId } = appStore(state => ({
    invoiceId: state.invoiceId
  }))
  const { invoiceFilter, setIsSearching } = invoiceStore()
  const [processing, setProcessing] = useState(false)
  const [cols, setCols] = useState<GridColumns | null>(columns)
  const [dataPaginationGrid, setDataPaginationGrid] = useState({
    loading: true,
    rows: [],
    rowCount: 0,
    rowsPerPageOptions: [5, 10, 15],
    pageSize: 5,
    page: 1
  })
  const handleProcessInvoices = async () => {
    try {
      setProcessing(true)
      const response = await getProcessInvoiceShop(invoiceId ?? 0)
      if (response) {
        toast.success('Successfully processed invoices', { duration: 4000 })
      } else {
        toast.error('There was an error processing invoices', { duration: 4000 })
      }
    } catch (error) {
      toast.error('There was an error processing invoices', { duration: 4000 })
    } finally {
      setProcessing(false)
    }
  }

  const handleExportInvoices = async () => {
    try {
      setProcessing(true)
      const response = await postExportInvoicesCarrier(
        invoiceId ?? 0,
        invoiceFilter.customerName ?? null,
        invoiceFilter.trackingNumber ?? null,
        invoiceFilter.startDate ?? null,
        invoiceFilter.endDate ?? null,
        1
      )

      const url = window.URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'CourierInvoice.xlsx')
      document.body.appendChild(link)
      link.click()

      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)

      toast.success('File downloaded successfully', { duration: 4000 })
    } catch (error) {
      toast.error('There was an error exporting invoices', { duration: 4000 })
    } finally {
      setProcessing(false)
    }
  }

  const updateDataPaginationGrid = (k: any, v: any) => setDataPaginationGrid(prev => ({ ...prev, [k]: v }))
  useEffect(() => {
    const fetch = async () => {
      updateDataPaginationGrid('loading', true)
      try {
        const response = await postCarrierCustomerInvoices({
          batchId: invoiceId,
          PageSize: dataPaginationGrid.pageSize,
          CurrentPage: dataPaginationGrid.page,
          status: -1,
          CustomerName: invoiceFilter.customerName ?? null,
          TrackingNumber: invoiceFilter.trackingNumber ?? null,
          StartDate: invoiceFilter.startDate ?? null,
          EndDate: invoiceFilter.endDate ?? null
        })
        if (response) {
          const mappedRows: CarrierCustomerInvoiceModel[] = response.list.map(invoice => ({
            id: invoice.id !== null ? invoice.id : 0,
            isActive: invoice.isActive || false,
            lastModifiedDate: invoice.lastModifiedDate || null,
            bigCarrierInvoiceId: invoice.bigCarrierInvoiceId || null,
            intCarrierInvoiceBatchId: invoice.intCarrierInvoiceBatchId || 0,
            vchInvoiceNumber: invoice.vchInvoiceNumber || null,
            vchTrackingNumber: invoice.vchTrackingNumber || null,
            decWeight: invoice.decWeight || null,
            vchCharge: invoice.vchCharge || null,
            vchRef1: invoice.vchRef1 || null,
            vchRef2: invoice.vchRef2 || null,
            vchRef3: invoice.vchRef3 || null,
            vchOriginCompany: invoice.vchOriginCompany || null,
            vchOriginAddress: invoice.vchOriginAddress || null,
            vchOriginCity: invoice.vchOriginCity || null,
            vchOriginState: invoice.vchOriginState || null,
            vchOriginCountry: invoice.vchOriginCountry || null,
            vchOriginZip: invoice.vchOriginZip || null,
            vchDestinationCompany: invoice.vchDestinationCompany || null,
            vchDestinationAddress: invoice.vchDestinationAddress || null,
            vchDestinationCity: invoice.vchDestinationCity || null,
            vchDestinationState: invoice.vchDestinationState || null,
            vchDestinationZip: invoice.vchDestinationZip || null,
            intUserId: invoice.intUserId || null,
            intCarrierId: invoice.intCarrierId || null,
            intParentId: invoice.intParentId || null,
            vchParentName: invoice.vchParentName || null,
            vchCarrierName: invoice.vchCarrierName || null,
            intStatusId: invoice.intStatusId || null,
            vchStatusName: invoice.vchStatusName || null,
            dtmShipDate: invoice.dtmShipDate || null,
            dtmDateInvoice: invoice.dtmDateInvoice || null,
            dtmCreatedDate: invoice.dtmCreatedDate || null,
            intCreatedUserId: invoice.intCreatedUserId !== null ? invoice.intCreatedUserId : 0, // handle null case
            dtmUpdatedDate: invoice.dtmUpdatedDate || null,
            intUpdatedUserId: invoice.intUpdatedUserId !== null ? invoice.intUpdatedUserId : 0, // handle null case
            vchCustomerName: invoice.vchCustomerName || ''
          }))

          updateDataPaginationGrid('rows', mappedRows)
          updateDataPaginationGrid('rowCount', response.pagination.numberOfRecords)
        }
      } catch (error) {
        // console.log(error)
      } finally {
        updateDataPaginationGrid('loading', false)
      }
    }
    fetch()
    setIsSearching(false)
  }, [invoiceId, dataPaginationGrid.page, dataPaginationGrid.pageSize, invoiceFilter.isSearching])

  return (
    <Card sx={{ mt: 3 }}>
      <Stack
        sx={{ p: 4, flexDirection: { xs: 'column', md: 'row', lg: 'row' } }}
        display='flex'
        justifyContent='flex-end'
      >
        <Button
          variant='contained'
          color='secondary'
          size='small'
          sx={{ marginRight: '10px' }}
          onClick={handleExportInvoices}
          disabled={processing}
        >
          <Icon icon='material-symbols:upload' />
          {processing ? 'Processing' : 'Export'}
        </Button>
        <Button
          variant='contained'
          color='primary'
          size='small'
          sx={{ marginRight: '10px' }}
          onClick={handleProcessInvoices}
          disabled={processing}
        >
          {processing ? 'Processing' : 'Process invoices'}
        </Button>
      </Stack>
      <DataGrid
        checkboxSelection
        disableSelectionOnClick
        autoHeight
        pagination
        paginationMode='server'
        loading={dataPaginationGrid.loading}
        rowCount={dataPaginationGrid.rowCount}
        page={dataPaginationGrid.page - 1}
        pageSize={dataPaginationGrid.pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        rows={dataPaginationGrid.rows}
        columns={cols || []}
        getRowHeight={() => 51}
        onPageChange={(page: any) => {
          updateDataPaginationGrid('page', page + 1)
        }}
        onPageSizeChange={(pageSize: any) => {
          updateDataPaginationGrid('page', 1)
          updateDataPaginationGrid('pageSize', pageSize)
        }}
        components={{ Toolbar: CustomToolbarAdminGrid }}
      />
    </Card>
  )
}

export default TableRatesInvoice

import { useTranslation } from 'react-i18next'
import { Box, Card, Grid, Typography } from '@mui/material'
import AutocompleteUserManager from 'src/pages/components/customer/AutocompleteUserManager'
import GenericButton from 'src/pages/components/generic/GenericButton'
import WrapperSecurity from 'src/pages/components/generic/WrapperSeurityObject'
import TableRateSheet from 'src/pages/components/rateSheets/TableRateSheet'

const ShopPage = () => {
  const { t } = useTranslation()
  const styleActionsHeader = {
    flexDirection: 'row',
    justifyContent: { md: 'flex-start', xs: 'space-between' },
    alignItems: { md: 'center' },
    columnGap: 2,
    rowGap: { md: 0, xs: 2 }
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card sx={{ p: 4 }}>
          <Grid
            container
            flexDirection='row'
            justifyContent='space-between'
            alignContent='center'
            sx={{ minHeight: 100 }}
          >
            <Grid item xs={12} md={4} pr={{ xs: 0, md: 3 }}>
              <Typography variant='h5' sx={{ m: 2 }}>
                {t('LIST_CLIENT')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} display='flex' sx={styleActionsHeader}>
              <AutocompleteUserManager
                size='small'
                sx={{ width: '75%' }}
                label={t('ACCOUNT_MANAGER')}
                onSelectCustomer={(customer: any) => console.log('Manager selected:', customer)}
              />
              <WrapperSecurity securityKey='add_client_button'>
                <GenericButton to='/customer/add' state={{ customer: null, mode: 'add' }} sx={{ width: 'fit-content' }}>
                  {t('ADD_CLIENT')}
                </GenericButton>
              </WrapperSecurity>
            </Grid>
          </Grid>
          <Box sx={{ my: 3 }}>
            <TableRateSheet type='shop' />
          </Box>
        </Card>
      </Grid>
    </Grid>
  )
}

export default ShopPage  

// ** React ImportsPreviewCard
import { useState } from 'react'
// ** MUI Imports
import Grid from '@mui/material/Grid'
// ** Third Party Components
import { SingleInvoiceType } from 'src/types/apps/invoiceTypes'
import appStore from 'src/zustand/app'

import CardPreviewInvoice from '../cardPreviewInvoice'
import PreviewActionsInvoice from '../previewActionsInvoice'

const InvoicePreview = () => {
  // ** State
  const { orderId } = appStore(state => ({
    orderId: state.orderId
  }))
  const [error, setError] = useState<boolean>(false)
  const [data, setData] = useState<null | SingleInvoiceType>(null)
  const [addPaymentOpen, setAddPaymentOpen] = useState<boolean>(false)
  const [sendInvoiceOpen, setSendInvoiceOpen] = useState<boolean>(false)

  //   useEffect(() => {
  //     axios
  //       .get('/apps/invoice/single-invoice', { params: { orderId } })
  //       .then(res => {
  //         setData(res.data)
  //         setError(false)
  //       })
  //       .catch(() => {
  //         setData(null)
  //         setError(true)
  //       })
  //   }, [orderId])

  const toggleSendInvoiceDrawer = () => setSendInvoiceOpen(!sendInvoiceOpen)
  const toggleAddPaymentDrawer = () => setAddPaymentOpen(!addPaymentOpen)

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xl={9} md={8} xs={12}>
          <CardPreviewInvoice />
        </Grid>
        <Grid item xl={3} md={4} xs={12}>
          <PreviewActionsInvoice
            id={orderId?.toString()}
            toggleAddPaymentDrawer={toggleAddPaymentDrawer}
            toggleSendInvoiceDrawer={toggleSendInvoiceDrawer}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default InvoicePreview

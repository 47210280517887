import { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { logger } from 'src/utils/Logger'
import profileStore from 'src/zustand/profile'

interface ProfileListProps {
  disabled?: boolean
  defaultProfile?: number
  onSelectProfile: (profileId: number) => void
}

const ProfileList = ({ onSelectProfile, defaultProfile, disabled }: ProfileListProps) => {
  // const [profileList, setProfileList] = useState<ProfileModel[]>([])
  const [value, setValue] = useState('')

  const { setProfileSelectorSelectedId, setProfiles, profiles } = profileStore()

  const handleChange = (event: any, value: string | null) => {
    logger.log('Value en Hijo:', value)
    logger.log('Event en Hijo:', event.target.value)

    const selectedProfile = profiles.find(profile => profile.vchName === value)

    if (selectedProfile) {
      const profileId = selectedProfile.id

      setValue(value || '')
      setProfileSelectorSelectedId(profileId || 0)
      onSelectProfile(profileId || 0)
    } else {
      console.error('El objeto con vchName no se encontró en profileList.')
    }
  }

  useEffect(() => {
    if (profiles.length === 0) setProfiles()
  }, [profiles])

  useEffect(() => {
    if (defaultProfile) {
      const selectedProfile = profiles.find(profile => profile.id === defaultProfile)
      if (selectedProfile) {
        setValue(selectedProfile.vchName)
      }
    }
  }, [defaultProfile])

  return (
    <Autocomplete
      fullWidth
      onChange={handleChange}
      value={value}
      disabled={disabled}
      options={profiles.map(profile => profile.vchName)}
      id='autocomplete-disableCloseOnSelect'
      renderInput={params => <TextField {...params} label='Profile' />}
    />
  )
}

export default ProfileList

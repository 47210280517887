import { useState } from 'react'
import Grid from '@mui/material/Grid'
import appStore from 'src/zustand/app'

import OrderEditorPreview from '../preview'
import PreviewActions from '../previewActions'

const OrderPreview = () => {
  // ** State
  const { orderId } = appStore(state => ({
    orderId: state.orderId
  }))

  const [addPaymentOpen, setAddPaymentOpen] = useState<boolean>(false)
  const [sendInvoiceOpen, setSendInvoiceOpen] = useState<boolean>(false)

  const toggleSendInvoiceDrawer = () => setSendInvoiceOpen(!sendInvoiceOpen)
  const toggleAddPaymentDrawer = () => setAddPaymentOpen(!addPaymentOpen)

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xl={9} md={8} xs={12}>
          <OrderEditorPreview />
        </Grid>
        <Grid item xl={3} md={4} xs={12}>
          <PreviewActions
            id={orderId?.toString()}
            toggleAddPaymentDrawer={toggleAddPaymentDrawer}
            toggleSendInvoiceDrawer={toggleSendInvoiceDrawer}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default OrderPreview

// ** React Import
import { useEffect } from 'react'
// ** Third Party Import
import { useTranslation } from 'react-i18next'
// ** Icon Imports
import Icon from 'src/@core/components/icon'
// ** Custom Components Imports
import OptionsMenu from 'src/@core/components/option-menu'
// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'

interface Props {
  settings: Settings
  saveSettings: (values: Settings) => void
}

enum Language {
  SPANISH = 'es',
  ENGLISH = 'en'
}

const languages: { key: string; lang: Language }[] = [
  { key: 'INGLES', lang: Language.ENGLISH },
  { key: 'ESPANOL', lang: Language.SPANISH }
]

const LanguageDropdown = ({ settings, saveSettings }: Props) => {
  // ** Hook
  const { t, i18n } = useTranslation()

  // ** Vars
  const { layout } = settings

  const handleLangItemClick = (lang: Language.SPANISH | Language.ENGLISH) => {
    i18n.changeLanguage(lang).then()
  }

  // ** Change html `lang` attribute when changing locale
  useEffect(() => {
    document.documentElement.setAttribute('lang', i18n.language)
  }, [i18n.language])

  const options = languages.map(language => {
    return {
      text: t(language.key),
      menuItemProps: {
        sx: { py: 2 },
        selected: i18n.language === language.lang,
        onClick: () => {
          handleLangItemClick(language.lang)
          saveSettings({ ...settings, direction: 'ltr' })
        }
      }
    }
  })

  return (
    <OptionsMenu
      icon={<Icon icon='mdi:translate' />}
      menuProps={{ sx: { '& .MuiMenu-paper': { mt: 4, minWidth: 130 } } }}
      iconButtonProps={{ color: 'inherit', sx: { ...(layout === 'vertical' ? { mr: 0.75 } : { mx: 0.75 }) } }}
      options={options}
    />
  )
}

export default LanguageDropdown

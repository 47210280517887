import { logger } from './Logger'

/**
 * Mueve una columna dentro de un array de objetos (tableData) desde el índice fromIndex al índice toIndex.
 * @summary El array de array de objetos representa una tabla donde cada fila es un array de objetos, y cada objeto representa una celda en la tabla..
 * @param {any[]} tableData El array que representa la tabla.
 * @param {number} fromIndex El índice de la columna que se va a mover.
 * @param {number} toIndex  El índice al cual se va a mover la columna.
 * @return {any[][]} Un nuevo array de array de objetos con la columna reordenada.
 */
const moveColumnTable = (tableData: any[], fromIndex: number, toIndex: number) => {
  if (toIndex === fromIndex || toIndex < 0 || fromIndex < 0) {
    // Si el índice inicial es igual al índice final o son valores inválidos, no hay cambios.
    console.log('Table column:', tableData)

    return tableData
  }

  const reorderedTableData = tableData.map(row => {
    const newRow = [...row]
    const movedItem = newRow.splice(toIndex, 1)[0]
    newRow.splice(fromIndex, 0, movedItem)

    return newRow
  })

  return reorderedTableData
}
/**
 * Restricciones a cumplir antes de mover una columna
 * @param {number[]} movedColumns - Listado de las columnas que se seleccionaron para mover
 * @return {boolean} Si se puede mover devuelve true, sino false
 */
const restrictionsBeforeMovingColumn = (movedColumns: number[]) => {
  const columnNoMovable = 0 // Col 0 - Weights column
  if (movedColumns.indexOf(columnNoMovable) !== -1 || movedColumns.length > 1) return false
}

/**
 * Formatear data JSON, para que lo tome la tabla handsontable. Formato: [[{}], [{}]]
 * @param {any} data
 * @return {any[][]}
 */
const formatedData = (data: any, page?: string) => {
  const isCustomerOrShop = page === 'customer' || page === 'shop'
  const zones = [...new Set(data.map((obj: any) => obj.intZoneId))] // vchZoneName
  const rows = [...new Set(data.map((obj: any) => obj.intServiceWeigthId))].length
  const result = []
  // debugger

  for (let i = 0; i < rows; i++) {
    const row = []
    for (let j = i * zones.length; j < (i + 1) * zones.length; j++) {
      const value = {
        discountPrice: page && isCustomerOrShop ? data[j].decCostDiscount : data[j].decDiscount,
        basePrice: page && isCustomerOrShop ? data[j].decCostBase : data[j].decPrice,
        discountCustomer: data[j].decDiscountPercent,
        markupCustomer: data[j].decMarkupPercent,
        setRateCustomer: data[j].decSetRatePrice,
        intZoneId: data[j].intZoneId,
        intServiceWeigthId: data[j].intServiceWeigthId,
        editionMode: data[j].editionMode
          ? data[j].editionMode
          : {
              editedCell: false,
              table: ''
            }
      }
      row.push(value)
    }
    row.unshift(i)

    result.push(row)
  }

  return result
}

/**
 * Formatear texto copiado, ej. de Excel,
 * @param {string} text Data que devuelve el portapapeles.
 * @return {number[][]} Formato compatible con handsontable: [[], []]
 */

const convertDataToJSONArray = (newData: any) => {
  const cellsData: any[] = []
  newData.forEach((rows: any) => {
    rows.forEach((cells: number | object) => {
      if (typeof cells === 'object') {
        cellsData.push(cells)
      }
    })
  })

  return cellsData
}

/**
 * Copy from other program, ex. Excel to cell in table
 * @summary
 * @param {any} data - Data
 * @param {any} coords - Coords
 * @param {Handsontable | any} table - Handsontable instance
 * @param {string} propToModify - Property to modify its value to the new one
 * @return data
 */
const copyOnTableCell = async (
  data: any,
  coords: any,
  table: any,
  propToModify: string,
  tableRef?: string
  // handleCellValueChange?: any
) => {
  try {
    logger.log('Copy on table cell', propToModify, tableRef)
    const { startRow, startCol /* , endRow, endCol*/ } = coords[0]
    const res = data
    // logger.log('Table ref : ', tableRef)
    for (let row = 0; row < res.length; row++) {
      for (let col = 0; col < res[row].length; col++) {
        const destRow = startRow + row
        const destCol = startCol + col
        logger.log('Coords of res or data', row, col)
        const currentValue = table.getDataAtCell(destRow, destCol)
        logger.log('Current value:', currentValue)
        table.setDataAtCell(destRow, destCol, {
          ...currentValue,
          [propToModify]: res[row][col].replace(/[^0-9.]/g, ''),
          editionMode: {
            editedCell: true,
            table: tableRef
          }
        })
      }
    }
  } catch (error) {
    logger.error('Error reading from clipboard:', error)
  }

  return data
}

export { convertDataToJSONArray, copyOnTableCell, formatedData, moveColumnTable, restrictionsBeforeMovingColumn }

import { useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Card,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material'
import Icon from 'src/@core/components/icon'
import DatePickerWrapper from 'src/@core/styles/libs/react-datepicker'
import { DateType } from 'src/types/forms/reactDatepickerTypes'
import { logger } from 'src/utils/Logger'
import FileUploaderMultiple from 'src/views/forms/FileUploaderMultiple'

import AutocompleteClient from '../components/clients/AutocompleteClient'
import { DialogExpose, DialogGeneric } from '../components/generic/DialogGeneric'
import FilterGridWithChips from '../components/generic/FilterGridWithChips'
// import GenericButton from '../components/generic/GenericButton'
import FiltersOrders from '../components/orderEditor/FiltersOrders'
import TableOrderEditor from '../components/orderEditor/TableOrderEditor'
import TextFieldInputAdornment from '../components/orderEditor/TextFieldInputAdornment'

import OrderTotals from './OrderTotals'

export interface FormFilter {
  orderId?: number
  custId?: number | string
  show?: number | string
  itemCount?: number | string
  shipCode?: number
  shipMethod?: string
  contact?: string
  company?: string
  state?: string
  country?: string
  part: string
  dateFrom?: DateType
  dateTo?: DateType
  typeFilterByDate?: number | string
  partDesc?: string
  view: string
  cancelled: string
}

const filterObject = (obj: { [key: string]: any }): { [key: string]: any } => {
  const filteredObj: { [key: string]: any } = {}

  Object.entries(obj).forEach(([key, value]) => {
    if (value !== null && value !== '' && value !== 0 && value !== undefined) {
      filteredObj[key] = value
    }
  })

  return filteredObj
}

const OrderEditor = () => {
  const { t } = useTranslation()
  const [showFilterCard, setShowFilterCard] = useState(false)
  const dialogRef = useRef<DialogExpose>(null)
  const [filterApplied, setHandlerFilterApplied] = useState<{ applied: boolean; data: { [key: string]: any } | null }>({
    applied: false,
    data: null
  })
  const [formFilter, setFormFilter] = useState<FormFilter>({
    orderId: 1000,
    custId: '',
    show: '',
    itemCount: '',
    shipCode: 10,
    shipMethod: '',
    contact: '',
    company: '',
    state: '',
    country: '',
    part: '',
    dateFrom: new Date(),
    dateTo: new Date(),
    typeFilterByDate: '',
    partDesc: '',
    view: 'full',
    cancelled: 'hide'
  })
  const data = [
    {
      id: 1,
      title: 'Daily Order Received',
      subtitle: '1200',
      amount: '$3,234.77',
      increase: true
    },
    {
      id: 2,
      title: 'Daily Order Exporter',
      subtitle: '500',
      amount: '$1,000.44',
      increase: true
    },
    {
      id: 3,
      title: 'Total Order  Shipped',
      subtitle: '700',
      amount: '$2,234.33',
      increase: false
    }
  ]

  const handleFilter = () => {
    setHandlerFilterApplied({ applied: true, data: filterObject(formFilter) })
  }

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Card sx={{ padding: '1rem' }}>
          <Box display='flex' maxWidth={900} mx='auto' alignItems='center' columnGap={2} my={2}>
            <AutocompleteClient
              size={'small'}
              onSelectClient={client => logger.log('Client en padre:', client)}
              label={t('CLIENT')}
            />
            <TextFieldInputAdornment sx={{ maxWidth: 500 }} />
            {/* <GenericButton
              iconSize={12}
              icon='formkit:arrowleft'
              onClick={() => dialogRef.current?.open()}
              sx={{ width: '100%' }}
            >
              {' '}
              {t('IMPORT_ORDER')}
            </GenericButton> */}
          </Box>
          <Grid padding={3} container>
            <FiltersOrders />
          </Grid>
        </Card>
      </Grid>

      <OrderTotals styleBox={{ mt: 4, ml: { xs: 5 } }} />

      {showFilterCard ? (
        <FilterGridWithChips
          gapStyle={0}
          filterApplied={filterApplied}
          handleClose={(close: boolean) => setHandlerFilterApplied({ applied: !close, data: null })}
        >
          <Grid item xs={12} md={2} sx={{ px: 2 }}>
            <TextField
              fullWidth
              id='order-id'
              label='Order Id'
              value={formFilter.orderId}
              onChange={e => setFormFilter({ ...formFilter, orderId: Number(e.target.value) })}
            />
          </Grid>
          <Grid item xs={12} md={2} sx={{ px: 2 }}>
            <FormControl fullWidth>
              <InputLabel id='cust-id-select'>Cust ID</InputLabel>
              <Select
                label='Cust ID'
                defaultValue=''
                value={formFilter.custId}
                onChange={e => setFormFilter({ ...formFilter, custId: e.target.value })}
                id='cust-id-select'
                labelId='cust-id-select'
              >
                <MenuItem value='LUC'>LUC</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} sx={{ px: 2 }}>
            <FormControl fullWidth>
              <InputLabel id='show-select'>Show</InputLabel>
              <Select
                label='Show'
                defaultValue=''
                value={formFilter.show}
                onChange={e => setFormFilter({ ...formFilter, show: e.target.value })}
                labelId='show-select'
              >
                <MenuItem value='All New'>All New</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} sx={{ px: 2 }}>
            <FormControl fullWidth>
              <InputLabel id='item-count-select'>Item Count</InputLabel>
              <Select
                label='Item Count'
                defaultValue=''
                value={formFilter.itemCount}
                onChange={e => setFormFilter({ ...formFilter, itemCount: e.target.value })}
                id='item-count-select-s'
                labelId='item-count-select'
              >
                <MenuItem value='Both'>Both</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} sx={{ px: 2 }}>
            <TextField
              fullWidth
              id='ship-code-id'
              label='Ship Code'
              value={formFilter.shipCode}
              onChange={e => setFormFilter({ ...formFilter, shipCode: Number(e.target.value) })}
            />
          </Grid>
          <Grid item xs={12} md={2} sx={{ px: 2 }}>
            <TextField
              fullWidth
              id='ship-method-id'
              label='Ship Method'
              value={formFilter.shipMethod}
              onChange={e => setFormFilter({ ...formFilter, shipMethod: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2 }}>
            <TextField
              fullWidth
              id='contact-id'
              label='Contact'
              value={formFilter.contact}
              onChange={e => setFormFilter({ ...formFilter, contact: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2 }}>
            <TextField
              fullWidth
              id='company-id'
              label='Company'
              value={formFilter.company}
              onChange={e => setFormFilter({ ...formFilter, company: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2 }}>
            <FormControl fullWidth>
              <InputLabel id='state-select'>State</InputLabel>
              <Select
                label='State'
                defaultValue=''
                id='state-select-s'
                labelId='state-select'
                value={formFilter.state}
                onChange={e => setFormFilter({ ...formFilter, state: e.target.value })}
              >
                <MenuItem value='Utah'>Utah</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2 }}>
            <TextField
              fullWidth
              id='part-id'
              label='Part #'
              value={formFilter.part}
              onChange={e => setFormFilter({ ...formFilter, part: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={2} sx={{ px: 2 }}>
            <DatePickerWrapper sx={{ width: '100%' }}>
              <DatePicker
                showTimeSelect
                timeFormat='HH:mm'
                timeIntervals={15}
                selected={formFilter.dateFrom}
                id='date-time-from-picker'
                dateFormat='MM/dd/yyyy h:mm aa'
                popperPlacement='bottom-end'
                onChange={(date: Date) => setFormFilter({ ...formFilter, dateFrom: date })}
                customInput={<TextField fullWidth label='Date From' />}
              />
            </DatePickerWrapper>
          </Grid>
          <Grid item xs={12} md={2} sx={{ px: 2 }}>
            <DatePickerWrapper>
              <DatePicker
                showTimeSelect
                timeFormat='HH:mm'
                timeIntervals={15}
                selected={formFilter.dateTo}
                id='date-time-to-picker'
                dateFormat='MM/dd/yyyy h:mm aa'
                popperPlacement='bottom-end'
                onChange={(date: Date) => setFormFilter({ ...formFilter, dateTo: date })}
                customInput={<TextField fullWidth label='Date To' />}
              />
            </DatePickerWrapper>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2 }}>
            <FormControl fullWidth>
              <InputLabel id='filter-type-date-select' sx={{ fontSize: 14 }}>
                Type of Filter by Date
              </InputLabel>
              <Select
                label='Type of Filter by Date'
                defaultValue=''
                id='type-filter-select-s'
                labelId='filter-type-date-select'
                value={formFilter.typeFilterByDate}
                onChange={e => setFormFilter({ ...formFilter, typeFilterByDate: e.target.value })}
              >
                <MenuItem value='Exporter'>Exporter</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2 }}>
            <TextField
              fullWidth
              id='part-desc-id'
              label='Part Desc.'
              value={formFilter.partDesc}
              onChange={e => setFormFilter({ ...formFilter, partDesc: e.target.value })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ px: 2 }}
            display='flex'
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems='center'
          >
            <Typography variant='subtitle1' fontWeight={600} mr={3}>
              View
            </Typography>
            <RadioGroup
              row
              aria-label='view'
              name='view'
              value={formFilter.view}
              onChange={e => setFormFilter({ ...formFilter, view: e.target.value })}
            >
              <FormControlLabel value='full' control={<Radio />} label='Full' />
              <FormControlLabel value='undelivered' control={<Radio />} label='Undelivered' />
            </RadioGroup>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ px: 2 }}
            display='flex'
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems='center'
          >
            <Typography variant='subtitle1' fontWeight={600} mr={3}>
              Cancelled
            </Typography>
            <RadioGroup
              row
              aria-label='Cancelled'
              name='Cancelled'
              value={formFilter.cancelled}
              onChange={e => setFormFilter({ ...formFilter, cancelled: e.target.value })}
            >
              <FormControlLabel value='hide' control={<Radio />} label='Hide' />
              <FormControlLabel value='show' control={<Radio />} label='Show' />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} display='flex' justifyContent='center'>
            <Fab
              color='primary'
              variant='extended'
              sx={{ height: 40, '& svg': { ml: 1 }, borderRadius: 1 }}
              onClick={handleFilter}
            >
              APPLY
              <Icon icon='formkit:arrowright' fontSize={12} />
            </Fab>
          </Grid>
        </FilterGridWithChips>
      ) : null}

      <Grid item xs={12}>
        <TableOrderEditor />
      </Grid>

      <DialogGeneric
        title='Import Order'
        ref={dialogRef}
        confirmText='Confirm'
        confirmFunc={() => console.log('Confirm imported order')}
        maxWidth={800}
      >
        <FileUploaderMultiple />
      </DialogGeneric>
    </Grid>
  )
}

export default OrderEditor

import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Icon } from '@iconify/react'
import { /* Box, Button, */ Card, /*Fab, Grid,*/ IconButton, Stack /* TextField , Typography*/ } from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import CustomChip from 'src/@core/components/mui/chip'
import { postCustomerInvoiceExport, postCustomerInvoicePaginate } from 'src/@http/customer-invoice'
import { CustomerInvoiceDto } from 'src/types/models/CustomerInvoiceModel'
import appStore from 'src/zustand/app'
import companiesStore from 'src/zustand/companies'
import customerStore from 'src/zustand/customer'
import invoiceStore from 'src/zustand/invoice'

import CustomToolbarAdminGrid from '../generic/CustomToolbarAdminGrid'
// import CustomAvatar from 'src/@core/components/mui/avatar'
// import CustomChip from 'src/@core/components/mui/chip'

interface Row {
  id: number
  icon: { icon: string; color: string }
  client: { avatar: string; name: string; email: string }
  total: string
  issuedDate: string
  balance: { paid: boolean; amount: string }
}

const columns: GridColumns = [
  {
    flex: 1,
    field: 'bigCustomerInvoiceId',
    headerName: 'Invoice Id',
    minWidth: 100
  },
  {
    flex: 1,
    field: 'vchShopName',
    headerName: 'Client',
    minWidth: 150
  },
  {
    flex: 1,
    field: 'vchCarrierName',
    headerName: 'Courier',
    minWidth: 150
  },
  {
    flex: 1,
    field: 'vchServiceName',
    headerName: 'Service Name',
    minWidth: 200
  },
  {
    flex: 1,
    field: 'vchWarehouseName',
    headerName: 'Warehouse',
    minWidth: 200
  },
  {
    flex: 1,
    field: 'vchCustomerRateSheetName',
    headerName: 'Rate Sheet Code',
    minWidth: 250
  },
  {
    flex: 1,
    field: 'vchCustomerRateName',
    headerName: 'Rate Sheet Name',
    minWidth: 150
  },
  {
    flex: 1,
    field: 'vchZoneName',
    headerName: 'Zone',
    minWidth: 150
  },
  {
    flex: 1,
    field: 'decWeigth',
    headerName: 'Weight',
    minWidth: 100,
    renderCell: ({ row }) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {parseFloat(row.decWeigth).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </div>
    )
  },
  // {
  //   flex: 1,
  //   field: 'vchUniMeasureName',
  //   headerName: 'Weight Measure',
  //   minWidth: 150
  // },
  {
    align: 'center',
    flex: 1,
    field: 'vchStatusName',
    headerName: 'Status',
    headerAlign: 'center',
    minWidth: 100,
    renderCell: ({ row }) => (
      <Stack display='flex' flexDirection='row' justifyContent='center'>
        <CustomChip
          label={row.vchStatusName}
          skin='light'
          color={row.vchStatusName === 'Active' ? 'success' : 'error'}
        />
      </Stack>
    )
  },
  {
    flex: 1,
    field: 'decShipPrice',
    headerName: 'Ship Price',
    minWidth: 200,
    renderCell: ({ row }) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {parseFloat(row.decShipPrice).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </div>
    )
  },
  {
    flex: 1,
    field: 'decShipPriceAditional',
    headerName: 'Aditional Ship Price',
    minWidth: 200,
    renderCell: ({ row }) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {parseFloat(row.decShipPriceAditional).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </div>
    )
  },
  {
    flex: 1,
    field: 'decShipCarrierPrice',
    headerName: 'Courier Price',
    minWidth: 200,
    renderCell: ({ row }) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {parseFloat(row.decShipCarrierPrice).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </div>
    )
  },
  {
    field: '',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    maxWidth: 250,
    headerAlign: 'center',
    renderCell: ({ row }: CellType) => <RowOptions id={row.id} />
  }
]

interface CellType {
  row: Row
}

const RowOptions = ({ id }: { id: number }) => {
  return (
    <Stack display='flex' flexDirection='row' width='100%' justifyContent='space-between'>
      <IconButton size='small'>
        <Icon icon='material-symbols:delete-outline' />
      </IconButton>
      <IconButton size='small'>
        <Icon icon='mdi:eye' />
      </IconButton>
      {/* <IconButton size='small'>
        <Icon icon='mdi:dots-vertical' />
      </IconButton> */}
    </Stack>
  )
}

const TableRatesInvoiceCustomerCompany = () => {
  const { invoiceId } = appStore(state => ({
    invoiceId: state.invoiceId
  }))
  const { companyGlobal } = companiesStore()
  const [processing, setProcessing] = useState(false)
  const [cols, setCols] = useState<GridColumns | null>(null)
  const [dataPaginationGrid, setDataPaginationGrid] = useState({
    loading: true,
    rows: [],
    rowCount: 0,
    rowsPerPageOptions: [5, 10, 15],
    pageSize: 5,
    page: 1
  })
  const { invoiceFilter } = invoiceStore()
  const { customerName } = customerStore()
  const handleExportInvoices = async () => {
    try {
      setProcessing(true)
      const response = await postCustomerInvoiceExport({
        customerName: companyGlobal?.vchName ?? null,
        vchCarrierName: invoiceFilter.carrierName ?? null,
        startDate: invoiceFilter.startDate ?? null,
        endDate: invoiceFilter.endDate ?? null,
        intStatusId: 1,
        id: null,
        intId: null
      })

      const url = window.URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'ShopInvoice.xlsx')
      document.body.appendChild(link)
      link.click()

      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)

      toast.success('File downloaded successfully', { duration: 4000 })
    } catch (error) {
      toast.error('There was an error exporting invoices', { duration: 4000 })
    } finally {
      setProcessing(false)
    }
  }
  const updateDataPaginationGrid = (k: any, v: any) => setDataPaginationGrid(prev => ({ ...prev, [k]: v }))
  useEffect(() => {
    const fetch = async () => {
      updateDataPaginationGrid('loading', true)
      try {
        const response = await postCustomerInvoicePaginate({
          customerFilters: {
            id: -1,
            intId:
              invoiceFilter.batchId !== null && invoiceFilter.batchId !== undefined && invoiceFilter.batchId !== 0
                ? invoiceFilter.batchId
                : -1,
            customerName: companyGlobal?.vchName ?? null,
            vchCarrierName: invoiceFilter.carrierName ?? null,
            startDate: invoiceFilter.startDate ?? null,
            endDate: invoiceFilter.endDate ?? null,
            intStatusId: 1
          },
          PageSize: dataPaginationGrid.pageSize,
          CurrentPage: dataPaginationGrid.page
        })
        if (response) {
          const mappedRows: CustomerInvoiceDto[] = response.list.map((invoice: any) => ({
            id: invoice.id,
            bigCustomerInvoiceId: invoice.bigCustomerInvoiceId || null,
            intCustomerId: invoice.intCustomerId || null,
            vchCustomerName: invoice.vchCustomerName || null,
            intCarrierId: invoice.intCarrierId || null,
            vchCarrierName: invoice.vchCarrierName || null,
            intShopId: invoice.intShopId || null,
            vchShopName: invoice.vchShopName || null,
            bigCustomerRateId: invoice.bigCustomerRateId || null,
            vchCustomerRateName: invoice.vchCustomerRateName || null,
            vchCustomerRateSheetName: invoice.vchCustomerRateSheetName || null,
            bigOrderId: invoice.bigOrderId || null,
            vchCustomerOrderId: invoice.vchCustomerOrderId || null,
            intCustomerInvoiceBatchId: invoice.intCustomerInvoiceBatchId || null,
            sinWarehouseId: invoice.sinWarehouseId || null,
            vchWarehouseName: invoice.vchWarehouseName || null,
            intServiceId: invoice.intServiceId || null,
            vchServiceName: invoice.vchServiceName || null,
            intServiceZoneId: invoice.intServiceZoneId || null,
            vchZoneName: invoice.vchZoneName || null,
            intServiceWeigthId: invoice.intServiceWeigthId || null,
            vchWeightName: invoice.vchWeightName || null,
            sinCurrencyId: invoice.sinCurrencyId || null,
            vchCurrencyName: invoice.vchCurrencyName || null,
            tinUnitMeasureId: invoice.tinUnitMeasureId || null,
            vchUniMeasureName: invoice.vchUniMeasureName || null,
            decWeigth: invoice.decWeigth || null,
            dtmDateInvoice: invoice.dtmDateInvoice || null,
            vchDetail: invoice.vchDetail || null,
            vchInvoiceNumber: invoice.vchInvoiceNumber || null,
            dtmShipDate: invoice.dtmShipDate || null,
            decShipPrice: invoice.decShipPrice,
            decShipCarrierPrice: invoice.decShipCarrierPrice,
            decShipPriceAditional: invoice.decShipPriceAditional,
            intCreatedUserId: invoice.intCreatedUserId || null,
            dtmCreatedDate: invoice.dtmCreatedDate || null,
            intUpdatedUserId: invoice.intUpdatedUserId || null,
            dtmUpdatedDate: invoice.dtmUpdatedDate || null,
            intStatusId: invoice.intStatusId || null,
            vchStatusName: invoice.vchStatusName || null,
            vchStatusColor: invoice.vchStatusColor || null,
        }))
          updateDataPaginationGrid('rows', mappedRows)
          updateDataPaginationGrid('rowCount', response.pagination.numberOfRecords)
        }
      } catch (error) {
        // console.log(error)
      } finally {
        updateDataPaginationGrid('loading', false)
      }
    }
    fetch()
    setCols(columns)
  }, [invoiceId, dataPaginationGrid.page, dataPaginationGrid.pageSize, invoiceFilter.isSearching])

  return (
    <Card sx={{ mt: 3 }}>
      <Stack
        sx={{ p: 4, flexDirection: { xs: 'column', md: 'row', lg: 'row' } }}
        display='flex'
        justifyContent='flex-end'
      ></Stack>
      <DataGrid
        checkboxSelection
        disableSelectionOnClick
        autoHeight
        pagination
        paginationMode='server'
        loading={dataPaginationGrid.loading}
        rowCount={dataPaginationGrid.rowCount}
        page={dataPaginationGrid.page - 1}
        pageSize={dataPaginationGrid.pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        rows={dataPaginationGrid.rows}
        getRowId={row => row.id}
        columns={cols || []}
        getRowHeight={() => 51}
        onPageChange={(page: any) => {
          updateDataPaginationGrid('page', page + 1)
        }}
        onPageSizeChange={(pageSize: any) => {
          updateDataPaginationGrid('page', 1)
          updateDataPaginationGrid('pageSize', pageSize)
        }}
        components={{ Toolbar: CustomToolbarAdminGrid }}
      />
    </Card>
  )
}

export default TableRatesInvoiceCustomerCompany

import { useTranslation } from "react-i18next"
import { Icon } from "@iconify/react"
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material"

import AccordionLevelsPages from "../components/object-security/AccordionLevelPages"

const ManageObjectEditor = () => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader title={t('SECURITY_OBJECT_EDITOR')} />
      <CardContent>
        <Box display='flex' flexDirection='row' gap={3} alignItems='center' mb={3}>
          <Icon icon="material-symbols:info-outline" />
          <Typography variant="caption">{t('INFORMATION_ADD_OBJECT_EDITOR')}</Typography>
        </Box>
        
        <AccordionLevelsPages />
      </CardContent>
    </Card>
  )
}

export default ManageObjectEditor
// import * as React from 'react';
import { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { getLSShipp } from 'src/@http/labels-server'
import { logger } from 'src/utils/Logger'
import appStore from 'src/zustand/app'

const columns: GridColDef[] = [
  {
    field: 'dtmShipDate',
    headerName: 'Shipment Date',
    minWidth: 150,
    flex: 1,
    type: 'date',
    valueFormatter: (params: any) => {
      return params.value ? format(new Date(params.value), 'MM/dd/yyyy') : '-'
    }
  },
  {
    field: 'vchOrderNumber',
    minWidth: 150,
    headerName: 'Order Number',
    flex: 3
  },
  {
    field: 'vchCarrierName',
    minWidth: 150,
    headerName: 'Courier',
    flex: 3
  },
  {
    field: 'vchResultTrackingNumber',
    minWidth: 150,
    headerName: 'Tracking Number',
    flex: 3
  },
  {
    field: 'operationNumber',
    minWidth: 150,
    headerName: 'Operation Number',
    flex: 3
  },
  {
    field: 'vchToAddressStreetLines',
    minWidth: 250,
    headerName: 'Shipping Adress',
    flex: 3
  },
  {
    field: 'vchToAddressStateOrProvinceCode',
    minWidth: 120,
    headerName: 'State',
    flex: 1
  },
  {
    field: 'vchToAddressCity',
    minWidth: 120,
    headerName: 'City',
    flex: 1
  },
  {
    field: 'shippingFee',
    minWidth: 140,
    headerName: 'Shipping Fee',
    flex: 3,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row.shippingFee) return "-"
      else params.row.movementType === 'income' && `$${params.row.shippingFee}`
    }
      
  },
  {
    field: 'decTotalValue',
    minWidth: 150,
    headerName: 'Amount',
    flex: 3,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row.amount) return "-"
      else params.row.movementType === 'income' && `$${params.row.amount}`
    }
  }
]

const BalanceAccountTable = ({
  filterDateLimits,
  carrierIds
}: {
  filterDateLimits?: {
    StartDate: string
    EndDate: string
  }
  carrierIds?: number[]
}) => {
  const getDateXDaysAgo = (daysAgo: number) => {
    const today = new Date()
    const result = new Date(today)
    result.setDate(today.getDate() - daysAgo)

    const year = result.getFullYear()
    const month = (result.getMonth() + 1).toString().padStart(2, '0')
    const day = result.getDate().toString().padStart(2, '0')

    return `${month}/${day}/${year}`
  }

  const { paramsV2 } = appStore()

  const [dataPaginationGrid, setDataPaginationGrid] = useState<{
    loading: boolean
    rows: any[]
    rowCount: number
    rowsPerPageOptions: number[]
    pageSize: number
    page: number
  }>({
    loading: false,
    rows: [],
    rowCount: 0,
    rowsPerPageOptions: [10, 25, 50],
    pageSize: 50,
    page: 1
  })

  const changePageSize = (pageSize: number) => {
    setDataPaginationGrid({ ...dataPaginationGrid, pageSize })
  }

  const getLSShippData = async () => {
    try {
      setDataPaginationGrid({ ...dataPaginationGrid, loading: true })
      const { list } = await getLSShipp({
        dates: {
          dateFrom: filterDateLimits?.StartDate
            ? new Date(filterDateLimits.StartDate)
            : new Date(getDateXDaysAgo(30).toString()),
          dateTo: filterDateLimits?.EndDate
            ? new Date(filterDateLimits.EndDate)
            : new Date(getDateXDaysAgo(0).toString())
        },
        pageSize: dataPaginationGrid.pageSize,
        currectPage: dataPaginationGrid.page,
        carrierIds: carrierIds || [],
        customerIds: [paramsV2.CustomerId],
      })

      setDataPaginationGrid({ ...dataPaginationGrid, rows: list, loading: false, rowCount: list.length })
    } catch (error) {
      logger.error(error)
      setDataPaginationGrid({ ...dataPaginationGrid, loading: false, rowCount: 0 })
    }
  }

  useEffect(() => {
    getLSShippData()
  }, [filterDateLimits, dataPaginationGrid.pageSize, dataPaginationGrid.page, carrierIds, paramsV2])


  return (
    <Card sx={{}}>
      <DataGrid
        rows={dataPaginationGrid.rows}
        autoHeight
        columns={columns}
        loading={dataPaginationGrid.loading}
        rowCount={dataPaginationGrid.rowCount}
        page={dataPaginationGrid.page - 1}
        pageSize={dataPaginationGrid.pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={newPageSize => changePageSize(newPageSize)}
      />
    </Card>
  )
}

export default BalanceAccountTable

// ** React Imports
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
// ** Toaster
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
// ** Next Imports
import { Link, useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  BoxProps,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  TypographyProps
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import useMediaQuery from '@mui/material/useMediaQuery'
// ** Icon Imports
import Icon from 'src/@core/components/icon'
// ** Hooks
// import useBgColor from 'src/@core/hooks/useBgColor'
import { useSettings } from 'src/@core/hooks/useSettings'
// ** Axios functions
import { recoveryPassword, resetPassword } from 'src/@http/user'
import { logger } from 'src/utils/Logger'
// ** Demo Imports
import FooterIllustrationsV2 from 'src/views/pages/auth/FooterIllustrationsV2'
// ** Third Party Imports
import * as yup from 'yup'

import InitialLayout from '../components/layouts/InitialLayout'

const RightWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: 450
  }
}))

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    width: '100%'
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: 400
  }
}))

const TypographyStyled = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: { mt: theme.spacing(8) }
}))

const LinkStyled = styled(Link)(({ theme }) => ({
  fontSize: '0.875rem',
  textDecoration: 'none',
  color: theme.palette.primary.main
}))

const schema = yup.object().shape({
  email: yup.string().email().required()
})

const schemaSendNewPassword = yup.object().shape({
  code: yup.string().min(3).required(),
  newPassword: yup.string().min(3).required(),
  newPasswordRepeat: yup
    .string()
    .oneOf([yup.ref('newPassword'), undefined], 'Las contraseñas deben coincidir')
    .required()
})

interface FormDataSendRecovery {
  email: string
}
interface FormDataSendNewPassword {
  code: string
  newPassword: string
  newPasswordRepeat: string
}

const useQuery = () => {
  const { search } = useLocation()
  const searchParams = search.split('=')[1]

  return searchParams
}

const ForgotPasswordPage = () => {
  const { t } = useTranslation()
  const tokenEmail = useQuery()
  const [emailSent, setEmailSent] = useState<string>('')
  const [actualStep, setActualStep] = useState(1)
  const [completeFlow, setCompleteFlow] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showPasswordRepeat, setShowPasswordRepeat] = useState<boolean>(false)

  // ** Hooks
  const theme = useTheme()
  const { settings } = useSettings()

  const hidden = useMediaQuery(theme.breakpoints.down('md'))

  // ** Vars
  const { skin } = settings

  const {
    control: controlSendRecovery,
    setError: setErrorRecovery,
    handleSubmit: handleSubmitSendRecovery,
    formState: { errors: errorsSendRecovery }
  } = useForm<FormDataSendRecovery>({
    resolver: yupResolver(schema)
  })

  const {
    control: controlSendNewPassword,
    setError: setErrorSendNewPassword,
    handleSubmit: handleSubmitSendNewPassword,

    formState: { errors: errorsNewPasswordRecovery }
  } = useForm<FormDataSendNewPassword>({
    resolver: yupResolver(schemaSendNewPassword)
  })

  useEffect(() => {
    if (tokenEmail) {
      setActualStep(2)
    }
  }, [])

  const onSubmit = (data: FormDataSendRecovery) => {
    setIsLoading(true)
    recoveryPassword(data.email)
      .then(() => {
        setEmailSent(data.email)
      })
      .catch(err => {
        toast.error(err.message + '🙄', { duration: 4000 })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onSubmitRecovery = (data: FormDataSendNewPassword) => {
    const { code, newPassword, newPasswordRepeat } = data
    if (newPassword === newPasswordRepeat && tokenEmail) {
      setIsLoading(true)
      resetPassword(code, tokenEmail, newPassword)
        .then(() => {
          setCompleteFlow(true)
          setActualStep(3)
        })
        .catch(err => {
          toast.error(err.message)
        })
        .finally(() => setIsLoading(false))
    }
    logger.log('Form recovery : ', code, newPassword, newPasswordRepeat)
  }

  return (
    <InitialLayout>
      <Box
        className=''
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {!hidden ? (
          <Box sx={{ flex: 1, display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
            <FooterIllustrationsV2 />
          </Box>
        ) : null}
        {
          <RightWrapper sx={skin === 'bordered' && !hidden ? { borderLeft: `1px solid ${theme.palette.divider}` } : {}}>
            <Box
              sx={{
                p: 12,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'background.paper',
                opacity: 0.98,
                mr: 7
              }}
            >
              <BoxWrapper>
                {!tokenEmail && actualStep === 1 && !completeFlow && (
                  <div>
                    {/* 18462 */}
                    <Box sx={{ mb: 6 }}>
                      <TypographyStyled variant='h5'>{t('FORGOT_PASSWORD')} 🔒</TypographyStyled>
                      <Typography variant='body2'>{t('DONT_WORRY_PASS')}</Typography>
                    </Box>
                    <form noValidate autoComplete='off' onSubmit={handleSubmitSendRecovery(onSubmit)}>
                      <FormControl fullWidth sx={{ mb: 4 }}>
                        <Controller
                          name='email'
                          control={controlSendRecovery}
                          rules={{ required: true }}
                          defaultValue=''
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                              autoFocus
                              disabled={emailSent !== ''}
                              label='Email'
                              value={value}
                              onBlur={onBlur}
                              onChange={onChange}
                              error={Boolean(errorsSendRecovery.email)}
                              placeholder='youremail@gmail.com'
                            />
                          )}
                        />
                        {errorsSendRecovery.email && (
                          <FormHelperText sx={{ color: 'error.main' }}>
                            {errorsSendRecovery.email.message}
                          </FormHelperText>
                        )}
                      </FormControl>

                      <LoadingButton
                        loading={isLoading}
                        fullWidth
                        size='large'
                        type='submit'
                        disabled={emailSent !== ''}
                        variant='contained'
                        sx={{ mb: 5.25, color: '#FFFFFF' }}
                      >
                        {t('RECOVER_PASSWORD')}
                      </LoadingButton>
                    </form>

                    {emailSent && (
                      <Stack display={'flex'} flexDirection='row' alignItems={'center'}>
                        <Icon fontSize={22} icon={'gg:check-o'} color='#41b52d' />
                        <Typography sx={{ ml: 2 }}>{t('MAIL_SENT')}</Typography>
                      </Stack>
                    )}
                  </div>
                )}

                {tokenEmail && actualStep === 2 && (
                  <>
                    <Box sx={{ mb: 8 }}>
                      <Typography variant='h5' sx={{ mb: 4 }}>
                        {t('VERIFY_YOUR_EMAIL')}✉️
                      </Typography>
                      <Typography sx={{ color: 'text.secondary', mb: 6 }}>
                        {t('TEXT_SENT_CODE_GENERATE_NEW_PASSWORD')}
                      </Typography>
                      <form noValidate autoComplete='off' onSubmit={handleSubmitSendNewPassword(onSubmitRecovery)}>
                        <FormControl fullWidth sx={{ mb: 4 }}>
                          <Controller
                            name='code'
                            control={controlSendNewPassword}
                            rules={{ required: true }}
                            defaultValue=''
                            render={({ field: { value, onChange, onBlur } }) => (
                              <TextField
                                autoFocus
                                label={t('VERIFICATION_CODE')}
                                value={value}
                                onBlur={onBlur}
                                onChange={onChange}
                                error={Boolean(errorsNewPasswordRecovery.code)}
                                placeholder='youremail@gmail.com'
                              />
                            )}
                          />
                          {errorsNewPasswordRecovery.code && (
                            <FormHelperText sx={{ color: 'error.main' }}>
                              {errorsNewPasswordRecovery.code.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 4 }}>
                          <InputLabel
                            htmlFor='auth-login-v2-password'
                            error={Boolean(errorsNewPasswordRecovery.newPassword)}
                          >
                            {t('NEW_PASSWORD')}
                          </InputLabel>
                          <Controller
                            name='newPassword'
                            control={controlSendNewPassword}
                            rules={{ required: true }}
                            defaultValue=''
                            render={({ field: { value, onChange, onBlur } }) => (
                              <OutlinedInput
                                value={value}
                                onBlur={onBlur}
                                label={t('NEW_PASSWORD')}
                                onChange={onChange}
                                id='auth-login-v2-password'
                                error={Boolean(errorsNewPasswordRecovery.newPassword)}
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                  <InputAdornment position='end'>
                                    <IconButton
                                      edge='end'
                                      onMouseDown={e => e.preventDefault()}
                                      onClick={() => setShowPassword(!showPassword)}
                                    >
                                      <Icon
                                        icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'}
                                        fontSize={20}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            )}
                          />
                          {errorsNewPasswordRecovery.newPassword && (
                            <FormHelperText sx={{ color: 'error.main' }}>
                              {errorsNewPasswordRecovery.newPassword.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 4 }}>
                          <InputLabel
                            htmlFor='auth-login-v2-password'
                            error={Boolean(errorsNewPasswordRecovery.newPasswordRepeat)}
                          >
                            {t('REPEAT_PASSWORD')}
                          </InputLabel>
                          <Controller
                            name='newPasswordRepeat'
                            control={controlSendNewPassword}
                            rules={{ required: true }}
                            defaultValue=''
                            render={({ field: { value, onChange, onBlur } }) => (
                              <OutlinedInput
                                value={value}
                                onBlur={onBlur}
                                label='Repetir contraseña'
                                onChange={onChange}
                                id='auth-login-v2-password'
                                error={Boolean(errorsNewPasswordRecovery.newPasswordRepeat)}
                                type={showPasswordRepeat ? 'text' : 'password'}
                                endAdornment={
                                  <InputAdornment position='end'>
                                    <IconButton
                                      edge='end'
                                      onMouseDown={e => e.preventDefault()}
                                      onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                                    >
                                      <Icon
                                        icon={showPasswordRepeat ? 'mdi:eye-outline' : 'mdi:eye-off-outline'}
                                        fontSize={20}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            )}
                          />
                          {errorsNewPasswordRecovery.newPasswordRepeat && (
                            <FormHelperText sx={{ color: 'error.main' }}>
                              {errorsNewPasswordRecovery.newPasswordRepeat.message}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <LoadingButton
                          loading={isLoading}
                          fullWidth
                          size='large'
                          type='submit'
                          variant='contained'
                          sx={{ mb: 5.25 }}
                        >
                          {t('PASSWORD_RESET')}
                        </LoadingButton>
                      </form>
                    </Box>
                  </>
                )}
                {tokenEmail && actualStep === 3 && completeFlow && (
                  <Box sx={{ mb: 8 }} display='flex' flexDirection='column' alignItems='center'>
                    <Stack display='flex' flexDirection='row'>
                      <span style={{ fontSize: 25, marginTop: '1rem' }}>✔</span>
                      <Typography variant='h5' sx={{ mb: 4, ml: 2 }}>
                        {t('YOUR_PASSWORD_HAS_BEEN_UPDATED_CORRECTLY')}.
                      </Typography>
                    </Stack>
                  </Box>
                )}

                <Typography
                  variant='body2'
                  sx={{ mt: 12, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <LinkStyled to='/login'>
                    <span>{t('BACK_TO_LOGIN')}</span>
                  </LinkStyled>
                </Typography>
              </BoxWrapper>
            </Box>
          </RightWrapper>
        }
      </Box>
    </InitialLayout>
  )
}

export default ForgotPasswordPage

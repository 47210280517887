/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable unused-imports/no-unused-imports */
import { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { red } from '@mui/material/colors'
import {
  deleteCarrierAccount,
  getCarrierAccountById,
  postCarrierAccount,
  putCarrierAccount
} from 'src/@http/carrier-account'
import { useGetCarrierJson } from 'src/@http/carrier-account/hooks'
import { getRolesByModule } from 'src/@http/role'
import { RtaUploadFile, uploadFile, UploadFileType } from 'src/@http/uploadFile'
import SelectorCourier from 'src/pages/components/carrier/SelectorCourier'
import SelectorServicesMultiple from 'src/pages/components/carrier/SelectorServicesMultiple'
import BoxPreviewImage from 'src/pages/components/generic/BoxPreviewImage'
import { DialogExpose, DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import GenericButton from 'src/pages/components/generic/GenericButton'
import { RolModel } from 'src/types/models/RolModel'
import { logger } from 'src/utils/Logger'
import { showIfValidImage } from 'src/utils/misc'
import FileUploadImageSingle from 'src/views/forms/FileUploadImageSingle'
import appStore from 'src/zustand/app'
import Swal from 'sweetalert2'
import { match } from 'ts-pattern'

interface AccountForm {
  carrierName: string
  courierId: number
  vchAccountAccessData: Record<string, string>
  services: Array<any>
  bitOwnAccount: number
  bitEnabled: number
  bitDefault: number
  decBalanceMin: number | null
  sinRoleIdBalanceAlert: number | null
  vchLogoPath: string | null
  vchName: string
}

const AddOrEditAccount = () => {
  const { paramsV2 } = appStore()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [roles, setRoles] = useState<RolModel[]>([])
  // const [couriers, setCouriers] = useState<any[]>([]) // Agregado para almacenar los couriers previamente cargados
  const [courierSelected, setCourierSelected] = useState<number | null>(null)
  const [roleSelected, setRoleSelected] = useState<number | string | null>(null)
  const [services, setServices] = useState<any[] | null>([])
  const [servicesEnabled, setServicesEnabled] = useState<any[] | null>([])

  // queries

  const dynamicFieldsQuery = useGetCarrierJson({ id: courierSelected }, { enabled: !!courierSelected })

  // Logo
  const [fileLogo, setFileLogo] = useState<string | File | null>(null)
  const modalLogoUploader = useRef<DialogExpose>(null)

  const { t } = useTranslation()
  const { state, pathname } = useLocation()
  const { mode, data, level } = state

  let initialForm: AccountForm = {
    carrierName: '',
    courierId: 0,
    vchAccountAccessData: {
    },
    vchName: '',
    services: [],
    bitOwnAccount: 1,
    bitEnabled: 1,
    bitDefault: 0,
    decBalanceMin: null,
    sinRoleIdBalanceAlert: null,
    vchLogoPath: null
  }

  const getModuleId = useCallback(() => {
    if (level === 'ez') {
      return 1
    } else if (level === 'customer') {
      return 2
    } else if (level === 'client') {
      return 3
    } else {
      return 0
    }
  }, [level])

  const getAccountData = (obj1: any, returnString: boolean) => {
    return returnString ? JSON.stringify(obj1) : obj1
  }

  const safeParseJSon = (json?: string) => {
    try {
      if (json) return JSON.parse(json)
      else return {}
    } catch (e) {
      return {}
    }
  }

  async function uploadLogo() {
    try {
      if (fileLogo) {
        const res = (await uploadFile(fileLogo as File, UploadFileType.LabelSAccount)) as RtaUploadFile

        return res.virtualPathFile
      } else {
        throw new Error('File not exist')
      }
    } catch (err: any) {
      console.log('Error update file:', err)
    }
  }

  const fetchOperation = async (mode: string, body: any) => {
    try {
      if (mode === 'Add') {
        await postCarrierAccount(body)
      } else if (mode === 'Edit') {
        await putCarrierAccount(body)
      }
      setIsLoading(false)
      reset()
      window.history.back()
    } catch (e: any) {
      console.error('[Error]', e.DescriptionError)
      toast.error('Error saving account')
      setIsLoading(false)
    }
  }

  const onDeleteAccount = async () => {
    Swal.fire({
      title: t('DELETE_ACCOUNT').toString(),
      text: t('TEXT_DELETE_ACCOUNT').toString(),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: red['A700'],
      confirmButtonText: t('YES').toString(),
      cancelButtonText: 'No',
    }).then(async result => {
      if (result.isConfirmed) {
        await deleteCarrierAccount(data.id)
        reset()
        window.history.back()
      }
    })
  }

  const onSubmit: SubmitHandler<any> = async data => {
    let uploadedLogo = getValues('vchLogoPath') || null
    setIsLoading(true)
    if (!(typeof fileLogo === 'string' && fileLogo === uploadedLogo)) {
      uploadedLogo = await uploadLogo().catch((err: any) => {
        console.error('[No Logo]:', err)
      })
    }

    const isLevelEz = pathname.includes('/admin-label-server')
    const info = !!data.bitOwnAccount ? getAccountData(data.vchAccountAccessData, mode === 'edit' ? false : true) : ''
    console.log('initialForm.vchAccountAccessData', info)

    const payload: any = {
      intCarrierId: courierSelected || initialForm.courierId,
      vchAccountAccessData: info,
      bitOwnAccount: data.bitOwnAccount,
      bitEnabled: data.bitEnabled ? 1 : 0,
      intCustomerId: isLevelEz ? 0 : paramsV2.CustomerId,
      intShopId: paramsV2.ShopId,
      intStatusId: 1,
      intJsonVersionId: 1,
      vchLogoPath: uploadedLogo || null,
      vchName: data.AccountName,
      decBalanceMin: Number(data.decBalanceMin) || null,
      sinRoleIdBalanceAlert: data.sinRoleIdBalanceAlert || null
    }

    if (mode === 'Edit') {
      payload.intLSCarrierAccount = data.intLSCarrierAccount
      payload.id = data.id
      payload.decBalance = data.balance
      payload.bitEnabled = data.bitEnabled
    }

    if (isLevelEz === false) {
      // Is Level Customer or Client
      payload.bitDefault = data.bitDefault
    }

    if (services) {
      payload.services = services.map(e => {
        return {
          intServiceId: e.value,
          vchServiceName: e.label,
          intStatusId: 1,
          vchStatusColor: 'success',
          vchStatusName: 'Enabled'
        }
      })
    }

    fetchOperation(mode, payload)
    logger.log('Account Payload', payload)
  }

  const getServicesEnabled = async (id: number) => {
    const res = await getCarrierAccountById(id)
    if (res && res.services.length !== 0) setServicesEnabled(res.services)
    else setServicesEnabled([])
  }

  if (data) {
    initialForm = { ...data }
    initialForm.vchAccountAccessData = data.vchAccountAccessData ? JSON.parse(data.vchAccountAccessData) : null
  }

  useEffect(() => {
    if (data) {
      getServicesEnabled(data.id)
      setFileLogo(initialForm.vchLogoPath || null)
    }

    const getExtraData = async () => {
      const roles = await getRolesByModule(1, getModuleId())
        .then(roles => roles)
        .catch(() => [])
      setRoles(roles)
    }

    getExtraData()
  }, [data, getModuleId, initialForm.vchLogoPath])

  useEffect(() => {
    const getSelectedCourier = () => {
      if (mode === 'Edit') {
        setCourierSelected(initialForm.courierId)
      }
    }

    getSelectedCourier()
  }, [initialForm.courierId, mode])

  const handleSelectChange = (e: any) => {
    const newValue = e.target.value
    setRoleSelected(newValue)
    setValue('sinRoleIdBalanceAlert', newValue)
  }

  const {
    control,
    handleSubmit,
    reset,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors }
  } = useForm<any>({
    defaultValues: initialForm,
    mode: 'onBlur'
  })

  return (
    <Card>
      <CardHeader title={getTitleByMode(data, t)} />
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} md={6} sx={{ p: { xs: 0, md: 0 } }}>
              <SelectorCourier
                disabled={mode === 'Edit'}
                courierSelected={initialForm.courierId}
                onHandleSelectedOption={val => setCourierSelected(val)}
                actualLevel={level}
                title='Courier'
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ p: { xs: 0, md: '0 0 10px 10px' } }}>
              <SelectorServicesMultiple
                onHandleSelectedOptions={(options: any) => {
                  setServices(options)
                }}
                servicesSelected={servicesEnabled}
                carrierId={courierSelected}
                title='Services'
                mode={mode}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ p: { xs: 0, md: 0 } }}>
              <TextField
                fullWidth
                data-cy="alert_input"
                label={t('ALERT')}
                {...register('decBalanceMin', { required: true })}
                error={!!errors?.decBalanceMin}
                defaultValue={initialForm.decBalanceMin}
              />
              {!!errors['decBalanceMin'] && <FormHelperText error>This field is required.</FormHelperText>}
            </Grid>
            <Grid item xs={12} md={6} sx={{ p: { xs: 0, md: '0 0 10px 10px' } }}>
              <FormControl fullWidth>
                <InputLabel id='select-role-alert'>{t('ROLE_TO_ALERT')}</InputLabel>
                <Select
                  labelId='select-role-alert'
                  id='select-role-alert'
                  data-cy="select_role_alert"
                  value={roleSelected || initialForm.sinRoleIdBalanceAlert || ''}
                  label={t('ROLE_TO_ALERT')}
                  onChange={handleSelectChange}
                >
                  {roles.map(({ sinRoleId, vchName }, index) => {
                    return (
                      <MenuItem key={index} value={sinRoleId}>
                        {vchName}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} mb={3}>
              <Controller
                name='bitOwnAccount'
                control={control}
                defaultValue={1}
                render={({ field }: any) => (
                  <FormControlLabel
                    label="It's own account"
                    control={
                      <Checkbox checked={field.value === 1} onChange={e => field.onChange(e.target.checked ? 1 : 0)} />
                    }
                  />
                )}
              />
              <Controller
                name='bitEnabled'
                control={control}
                defaultValue={1}
                render={({ field }: any) => (
                  <FormControlLabel
                    label='Enabled'
                    control={
                      <Checkbox checked={field.value == 1} onChange={e => field.onChange(e.target.checked ? 1 : 0)} />
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} mb={3} gap={3}>
              <TextField
                fullWidth
                data-cy="account_name_input"
                label='Account Name'
                {...register('AccountName', { required: true })}
                error={!!errors?.vchName}
                defaultValue={initialForm.vchName || ''}
              />
              {!!errors['vchAccountAccessData.AccountName'] && (
                <FormHelperText error>This field is required.</FormHelperText>
              )}
              <Box mb={3} />
              {match(dynamicFieldsQuery)
                .with({ data: { data: null }, status: 'success' }, () => {
                  return <></>
                })
                .with({ status: 'success' }, ({ data: { data } }) => {
                  return (
                    <>
                      {!!watch('bitOwnAccount') &&
                        Object.keys(safeParseJSon(data?.vchJson!))
                          .map((key: string) => ({ label: key, value: '' }))
                          .map(df => {
                            return (
                              <>
                                <TextField
                                  data-cy={df.label}
                                  fullWidth
                                  label={df.label}
                                  {...register(`vchAccountAccessData.${df.label}`, { required: true })}
                                  error={!!errors?.[`vchAccountAccessData.${df.label}`]}
                                  defaultValue={df.value}
                                />
                                {!!errors['vchAccountAccessData.' + df.label] && (
                                  <FormHelperText error>This field is required.</FormHelperText>
                                )}
                                <Box mb={3} />
                              </>
                            )
                          })}
                    </>
                  )
                })
                .with({ status: 'pending' }, () => {
                  return <></>
                })
                .otherwise(() => {
                  return <></>
                })}
            </Grid>
            <Grid item xs={12} md={6} pl={data ? 0 : 3} pb={data ? 3 : 0}>
              <GenericButton onClick={() => modalLogoUploader.current?.open()}>
                <Typography color={'white'}>{t('UPLOAD_LOGO')}</Typography>
              </GenericButton>
              {fileLogo && showIfValidImage(fileLogo) && (
                <BoxPreviewImage
                  image={fileLogo}
                  heightBox={80}
                  handleIconAction={(action: string) => {
                    if (action === 'IconDelete') setFileLogo(null)
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Box display='flex' flexDirection='row' gap={3}>
            <LoadingButton variant='contained' type='submit' sx={{ width: '208px', color: 'white' }} loading={isLoading}>
              {t('SAVE')}
            </LoadingButton>
            {mode === 'Edit' && (
              <LoadingButton
                color="error"
                onClick={onDeleteAccount}
                variant='contained'
                sx={[
                  { '&:hover': { backgroundColor: red['A700'] }},
                  { width: '208px', color: 'white' }
                ]}
                loading={isLoading}
              >
                {t('DELETE')}
              </LoadingButton>
            )}
          </Box>
        </form>
      </CardContent>
      <DialogGeneric ref={modalLogoUploader} title={t('UPLOAD_LOGO')} styleBoxContent={{}}>
        <FileUploadImageSingle
          selectedImage={fileLogo as File}
          handleFileImageUploaded={(file: File | null) => setFileLogo(file)}
        />
      </DialogGeneric>
    </Card>
  )
}

const getTitleByMode = (data: { accountNumber: string }, t: any) => {
  if (!data) return t('ADD_NEW_ACCOUNT')
  else return `${t('ACCOUNT')}: ${data.accountNumber || '-'}`
}

export default AddOrEditAccount

import { useState } from 'react'
import { FormControl, InputLabel, MenuItem } from '@mui/material'
import Select from '@mui/material/Select'
import { useQuery } from '@tanstack/react-query'
import { getWarehouses } from 'src/@http/carriers'
import { WarehouseGetModel } from 'src/types/models/CarrierModel'
import { match, P } from 'ts-pattern'

interface WarehouseSelectorProps {
  onlyActive?: boolean
  handleSelected: (wh: WarehouseGetModel) => any
}

const SelectWarehouses = ({ handleSelected }: WarehouseSelectorProps) => {
  const [warehouseSelected, setWarehouseSelected] = useState<WarehouseGetModel | ''>('')

  const warehousesQuery = useQuery<WarehouseGetModel[]>({
    queryKey: ['warehouses'],
    queryFn: getWarehouses
  })

  const handleWarehouse = (warehouses : WarehouseGetModel[]) => (e: any) => {
    const selectedWarehouse = warehouses.find((wh: WarehouseGetModel) => wh.id === e.target.value)
    if (selectedWarehouse) {
      setWarehouseSelected(selectedWarehouse)
      handleSelected(selectedWarehouse)
    }
  }

  return (
    <FormControl fullWidth>
      <InputLabel id='wh-label'>Warehouse</InputLabel>
      {match(warehousesQuery)
        .with(
          {
            isLoading: true
          },
          () => (
            <Select size='small' label='Warehouse' disabled={true} value={'Loading warehouses'} labelId='wh-label'>
              <MenuItem value=''>Loading...</MenuItem>
            </Select>
          )
        )
        .with(
          {
            isSuccess: true,
            data: [P.any, ...P.array()]
          },
          ({ data: warehouses }) => (
            <Select
              size='small'
              label='Warehouse'
              value={warehouseSelected ? warehouseSelected.id : ''}
              labelId='wh-label'
              onChange={handleWarehouse(warehouses)}
            >
              {warehouses?.map((warehouse: WarehouseGetModel) => (
                <MenuItem key={warehouse.id} value={warehouse.id}>
                  {warehouse.description}
                </MenuItem>
              ))}
            </Select>
          )
        )
        .otherwise(() => (
          <Select size='small' label='Warehouse' disabled={true} value={'Error loading warehouses'} labelId='wh-label'>
            <MenuItem>Error</MenuItem>
          </Select>
        ))}
    </FormControl>
  )
}

export default SelectWarehouses

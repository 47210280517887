import { Grid, Typography } from '@mui/material'
import navigation from 'src/navigation/vertical'

type ChildrenItemNav = { title: string; path: string; icon?: string }

type ObjectNav = {
  title: string
  path: string
  icon?: string
  children?: ChildrenItemNav[]
  sectionTitle?: string
}

const itemsWithPath: ObjectNav[] = navigation().reduce((acc: any, objeto: any) => {
  const childConPath = objeto.children?.filter((child: ChildrenItemNav) => child.path !== undefined)
  if (childConPath) {
    childConPath.forEach((item: ChildrenItemNav) => acc.push(item))

    return acc
  } else if (objeto.path !== undefined) {
    return acc.concat([objeto])
  } else {
    return acc
  }
}, [])

const format = (path: string) => (path[0] === '/' ? path.replace('/', '') : path)

const UnderConstruction = ({ titlePage }: { titlePage?: string }) => {
  const text = itemsWithPath.filter(
    (item: ChildrenItemNav) => format(item.path) === window.location.pathname.slice(1)
  )[0]?.title

  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center' p={3} sx={{ height: '100%' }}>
      <Grid item xs={12} sm={4} md={6}>
        <Typography fontWeight={'bold'} variant='h3' gutterBottom color='primary'>
          {text || titlePage || ''}
        </Typography>
        <Typography variant='body1' gutterBottom>
          This page is under construction. Please check back later.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} md={6}>
        <img src='/images/pages/UnderConstruction.svg' alt='En construcción' width={'90%'} />
      </Grid>
    </Grid>
  )
}

export default UnderConstruction

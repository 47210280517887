import { useEffect, useState } from 'react'
import React from 'react'
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme
} from '@mui/material'
import { getCarriers } from 'src/@http/carriers'
import { CarrierModel } from 'src/types/models/CarrierModel'

interface AutocompleteUsersProps {
  onSelectClient: (user: any) => void
  label: string
  disabled?: boolean
  shopSelected?: any
  size?: 'small' | 'medium'
  width?: number | string
  addViewMobile?: boolean
}

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  }
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 160
    }
  }
}

const AutocompleteCarrierMulti = (props: AutocompleteUsersProps) => {
  const theme = useTheme()
  const [carrierList, setCarrierList] = useState<CarrierModel[]>([])
  const [personName, setPersonName] = React.useState<any[]>([])

  const getSelectedCarrierList = (selected: string[]) => {
    const names = selected.map(id => {
      const company = carrierList.find(c => c.id === parseInt(id))

      return company ? company.vchName : ''
    })

    return names.join(', ')
  }

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value }
    } = event
    setPersonName(typeof value === 'string' ? value.split(',') : value)

    props.onSelectClient(value)
  }

  useEffect(() => {
    getCarriers(999, 1)
      .then(data => {
        setCarrierList(data.list)
      })
      .catch(error => {
        console.error('Error fetching carrier data:', error)
      })
  }, [])

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 350 }}>
        <InputLabel>Courier</InputLabel>
        <Select
          multiple
          size='small'
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label='Courier' />}
          renderValue={selected => getSelectedCarrierList(selected as string[])}
          sx={{ width: '350px' }}
          MenuProps={MenuProps}
        >
          {carrierList.map(company => (
            <MenuItem key={company.id} value={company.id} style={getStyles(company.id.toString(), personName, theme)}>
              <Checkbox checked={personName.indexOf(company.id) > -1} />
              {company.vchName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default AutocompleteCarrierMulti

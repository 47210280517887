import { getAsync, postAsync, putAsync } from 'src/@http'
import { OrderDto, OrderProductsDto } from 'src/types/models/OrderModel'

import { Pagination } from '../user'

export interface CustomerWPagination {
  list: OrderDto[]
  pagination: Pagination
}

/* Endpoints definition */
const EndpointOrders = '/v1.0/ControllerOrder/Paginate'
const EndpointOrderId = '/v1.0/ControllerOrder'
const Orders = (pageSize = 10, currentPage = 1) => `${EndpointOrders}?PageSize=${pageSize}&CurrentPage=${currentPage}`
const OrderId = (orderId: number | null = null) => `${EndpointOrderId}/${orderId}`
const OrderDetailId = (orderDetailId: number | null = null) => `${EndpointOrderId}/OrderDetails/${orderDetailId}`
const OrderDetail = (orderId: string | null = null) => `${EndpointOrderId}/LabelDetails/${orderId}`
export const getOrders = (
  pageSize = 10,
  currentPage = 1,
  customerName = '',
  orderId: number | null = null,
  startDate = (() => {
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() - 30)

    return currentDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
  })(),
  endDate = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
): Promise<{ list: OrderDto[]; pagination: Pagination }> => {
  return postAsync(Orders(pageSize, currentPage), {
    bigOrderId: orderId,
    vchCustomerName: customerName,
    StartDateStr: startDate,
    EndDateStr: endDate,
    intStatusId: 1
  })
}

export const getOrdersForLS = (orderId: string | null = null): Promise<any> => {
  return getAsync(OrderDetail(orderId))
}

export const getOrderId = (orderId: number | null = null): Promise<OrderDto> => {
  return getAsync(OrderId(orderId))
}

export const getOrderDetailId = (orderId: number | null = null): Promise<OrderProductsDto[]> => {
  return getAsync(OrderDetailId(orderId))
}

export const updateOrder = (order: OrderDto): Promise<boolean> => {
  return putAsync(EndpointOrderId, order)
}

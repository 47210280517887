import React, { useEffect, useState } from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Autocomplete, Checkbox, FormControl, TextField } from '@mui/material'
import { getServicesForCarrier, getServicesForCarrierByShop } from 'src/@http/carriers'
import { logger } from 'src/utils/Logger'
import appStore from 'src/zustand/app'

import { Option } from './types'

const SelectorServicesMultiple = (props: any) => {
  const { paramsV2 } = appStore();
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [options, setOptions] = useState<Option[]>([]);

  const pathname = window.location.pathname;

  useEffect(() => {
    setOptions([]);
  }, [paramsV2.CustomerId]);

  useEffect(() => {
    const fetchServices = async () => {
      if (props.carrierId) {
        let services = [];
        if (pathname === '/client-labels/shipp/new') {
          services = await getServicesForCarrierByShop({
            intCarrierId: props.carrierId,
            status: 'Enabled',
            intShopId: paramsV2.ShopId
          });
          logger.log('Getting services by shop', services);
        } else {
          services = await getServicesForCarrier({ intCarrierId: props.carrierId, status: 'Enabled' });
          logger.log('Getting services by customer', services);
        }
        setOptions(services.map(service => ({ value: service.intServiceId, label: service.vchName })));
      }
    };
    fetchServices();
  }, [props.carrierId, pathname, paramsV2.ShopId]);

  useEffect(() => {
    if (props.servicesSelected) {
      const lst = props.servicesSelected.map((service: any) => ({ value: service.intServiceId, label: service.vchServiceName }))
      setSelectedOptions(options.filter(option => {
        return lst.some((incomingOption: any) => incomingOption.value === option.value)
      }));
    }
  }, [props.servicesSelected, options]);


  const handleChange = (event: React.ChangeEvent<{}>, value: Option[]) => {
    setSelectedOptions(value);
    props.onHandleSelectedOptions(value);
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;

  return (
    <FormControl fullWidth>
      <Autocomplete
        multiple
        id='autocomplete-service'
        options={options}
        value={selectedOptions}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} label='Services' placeholder='Services' />}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        onChange={handleChange}
      />
    </FormControl>
  );
};

export default SelectorServicesMultiple;

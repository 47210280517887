import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Button, Card, CardHeader, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { getCarriers } from 'src/@http/carriers'
import NotFoundCard from 'src/pages/components/rateSheets/NotFoundCard'
// import { cellRendererBaseRates } from 'src/pages/components/rateSheets/customComponents/CustomRenderer'
// import Filter from 'src/pages/components/rateSheets/FilterCarrierRateSheet'
import TableRateSheetCarriers from 'src/pages/components/rateSheets/TableRateSheetCarriers'
import MeasureListRateSheet from 'src/pages/components/services-editor/MeasureListRateSheet'
import { CarrierModel } from 'src/types/models/CarrierModel'
import { WarehouseGetModel } from 'src/types/models/CarrierModel'
import { logger } from 'src/utils/Logger'
import SelectShipMethods from 'src/views/filters/selectors/SelectShipMethods'
import SelectWarehouses from 'src/views/filters/selectors/SelectWarehouses'
import appStore from 'src/zustand/app'
import carrierStore from 'src/zustand/carrier'
import customerStore from 'src/zustand/customer'

// import GenericButton from '../components/generic/GenericButton'

interface bodyRateSheetVesions {
  // customerId: number
  carrierId: number
  serviceId: number
  warehouseId: number
}

const initialConfigBtnSave = {
  showBtn: false,
  isSaveAs: false,
  loadingBtn: false,
  textSave: 'CHANGES_UNSAVED',
  disableBtn: false,
  refresh: 0
}

function ClientRateSheet() {
  // const hotTableComponent: RefObject<HotTable> = useRef(null)
  const [couriers, setCouriers] = useState<CarrierModel[]>([])
  const [courierId, setCourierId] = useState(0)
  const [warehouseId, setWarehouseId] = useState<number>(6)
  const [service, setService] = useState<any>(null)
  const [configBtnSave, setConfigBtnSave] = useState(initialConfigBtnSave)
  // const { headers, weights } = appStore()
  const { getRateSheetVersionsClientCouriersAndSheet, notFoundCustomerCourierRate } = customerStore()
  const { setServiceId } = carrierStore()
  const { state } = useLocation()
  const userData = {
    user: state?.data || null,
    type: state?.type || 'carrier'
  }
  // const [list, setList] = useState<any[]>([])
  const { headers } = appStore()
  const handleCourier = (e: any) => {
    setCourierId(e.target.value)
    console.log('Courier Selected is :', e.target.value)
  }
  const { t } = useTranslation()

  useEffect(() => {
    getCarriers()
      .then(res => {
        setCouriers(res.list)
        setCourierId(res.list[0].id)
      })
      .catch(e => toast(e))
  }, [])
  const warehouseChange = (warehouse: WarehouseGetModel) => {
    setWarehouseId(warehouse.id)
    // setForm({...form, warehouseId :warehouse.id}  )
  }
  useEffect(() => {
    if (courierId !== 0 && service !== null && warehouseId !== 0) getRateSheets()
  }, [courierId, service, warehouseId])

  const getRateSheets = () => {
    console.log('Service', service)
    setServiceId(service.intServiceId)
    const data: bodyRateSheetVesions = {
      carrierId: courierId,
      serviceId: service.intServiceId,
      warehouseId: warehouseId
    }
    getRateSheetVersionsClientCouriersAndSheet(data)
  }

  return (
    <>
      <Card sx={{ p: 2, pb: 0 }}>
        <CardHeader title={'Rate Sheets'} />
        <Grid container sx={{ m: 2 }}>
          <Grid item xs={12} md={5} sx={{ p: 2, pl: 3 }}>
            <FormControl fullWidth>
              <InputLabel id='select-carrier-label'>{t('COURIER')}</InputLabel>
              <Select
                size='small'
                label='Courier'
                value={courierId.toString()}
                defaultValue=''
                labelId='select-carrier-label'
                onChange={handleCourier}
              >
                {couriers.map(item => (
                  <MenuItem key={item.intCarrierId} value={item.id}>
                    {item.vchName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={5} sx={{ p: 2 }}>
            <SelectShipMethods
              onlyActive
              carrierId={courierId}
              handleSelected={shipMethods => setService(shipMethods)}
            />
          </Grid>
          <Grid item xs={12} md={2} sx={{ p: 2, pr: 3 }}>
            <Button
              size='small'
              variant='contained'
              fullWidth
              sx={{ mb: { xs: 3, md: 0 } }}
              onClick={() => getRateSheets()}
            >
              <Typography color={'white'}>{t('LOAD')}</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} md={5} sx={{ p: 2, pl: 3 }}>
            <SelectWarehouses onlyActive handleSelected={wh => warehouseChange(wh)} />
          </Grid>
          {headers.length !== 0 && (
            <Grid item xs={12} md={5} sx={{ pr: 3, p: 2 }}>
              <MeasureListRateSheet
                useAllValue={true}
                onSelectMeasure={(measure: any) => logger.log('Measure en padre:', measure)}
              />
            </Grid>
          )}
        </Grid>
      </Card>
      <Card sx={{ p: 3, mt: 2 }}>
        {notFoundCustomerCourierRate ? (
          <NotFoundCard />
        ) : (
          <TableRateSheetCarriers
            dataUser={{
              data: userData.user,
              type: userData.type
            }}
            setConfigBtnSave={setConfigBtnSave}
            configBtnSave={configBtnSave}
          />
        )}
      </Card>
      {/* <HotTable
          rowHeaderWidth={50}
          columnHeaderHeight={20}
          data={formatedCourierList}
          // rowHeaders={weights}
          // colHeaders={headers}
          rowHeaders={true}
          colHeaders={true}
          height="auto"
          autoWrapRow={true}
          autoWrapCol={true}
          licenseKey="non-commercial-and-evaluation" // for non-commercial use only
        >
          <HotColumn
            key={0}
            width={150}
            title={'Weights'}
            readOnly
            renderer={(instance, td, row, col, prop, value, cellProperties) => {
              if (col === 0) cellRendererBaseRates(instance, td, row, col, prop, value)
            }}
          />
          {headers.map((header: any, index: number) =>
            (<HotColumn
                      key={index}
                      width={150}
                      title={header}
                      readOnly
                      renderer={(instance, td, row, col, prop, value, cellProperties) => {
                        if (col !== 0) cellRendererBaseRates(instance, td, row, col, prop, value)
                      }}
                    />))
                  }
        </HotTable> */}
    </>
  )
}

export default ClientRateSheet

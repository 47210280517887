import { getAsync, getAsyncRaw, getWithQueryParamsAsync, postAsync, putAsync } from 'src/@http'
import { ResponseCarrierTransaction } from 'src/types/models/AccountCarriersModels'
import { IGenericResponse } from 'src/types/response/response'

import { Pagination } from '../user'

const root = '/v1.0/ControllerCarrierAccount'
const paginateRoot = '/v1.0/ControllerCarrierAccount/byUser/Paginate'
const carrierTransactionEndpoint = '/v1.0/ControllerCarrierTransaction'
const accountById = (carrierAccountId: number) => `${root}/${carrierAccountId}`
const carrierJson = (carrierAccountId: number | null) => `${root}/Json/${carrierAccountId || ''}`
const getTransactionPagination = (pageSize = 10, currentPage = 1) =>
  `${carrierTransactionEndpoint}/Paginate?PageSize=${pageSize}&CurrentPage=${currentPage}`

export interface CarrierTransaction {
  intLSCarrierAccount: number
  decAmount: number
  vchDescription: string
  bigOrderId: number
  vchTrakingNumber: string
  vchSource: string
  dtmCreatedDate: string
  intStatusId: number
  vchStatusName: string
  vchStatusColor: string
  sinLSTransactionTypeId: number
  vchTransactionTypeName: string
  intCreatedUserId: number
  vchCustomerName: string
  vchShopName: string
  vchName: string
  vchLegacyNumber: string
}

export const postCarrierTransaction = (body: CarrierTransaction): Promise<any> => {
  return postAsync(carrierTransactionEndpoint, body)
}

export interface GetCarrierAccount {
  intCarrierId?: number
  intCustomerId?: number
  intShopId?: number
  intEnabled?: number
  PageSize: number
  CurrentPage?: number
  moduleId?: number
  Status?: number
}

export interface CarrierAccount {
  list: CarrierAccount[]
  intCarrierId: number
  vchCarrierName: string
  intCustomerId: number
  vchCustomerName?: any
  intShopId: number
  vchShopName?: any
  intStatusId: number
  vchStatusName?: any
  vchStatusColor?: any
  dtmCreatedDate?: string
  intCreatedUserId?: number
  dtmUpdatedDate?: string
  intUpdatedUserId?: number
  vchAccountAccessData: string
  decBalance: number
  intJsonVersionId: number
  bitOwnAccount: number
  bitDefault: number
  servicesJson?: any
  decBalanceMin: any
  sinRoleIdBalanceAlert: any
  vchUrl?: string
  vchRoleNameBalanceAlert: any
  bitEnabled: number
  vchLogoPath: any
  id: number
  isActive: boolean
  lastModifiedDate: any
  intLSCarrierAccount?: number
}

export interface ICarrierJsonResponse {
  id: number
  intCarrierId: number | null
  vchUrlAccessDataAccount?: string
  vchJson?: string | null
}

// export const getCarrierAccounts = (params: GetCarrierAccount): Promise<CarrierAccount[]> => {
//   return getWithQueryParamsAsync(root, params)
// }

export const getCarrierJson = (id: number | null) => {
  return getAsyncRaw<IGenericResponse<ICarrierJsonResponse>>(carrierJson(id))
}

export const getCarrierAccount = (params: GetCarrierAccount): Promise<CarrierAccount> => {
  params.PageSize = 50
  params.CurrentPage = 1

  return getWithQueryParamsAsync(paginateRoot, params)
}

export const postCarrierAccount = (body: CarrierAccount): Promise<any> => {
  return postAsync(root, body)
}

export const putCarrierAccount = (body: CarrierAccount): Promise<any> => {
  return putAsync(root, body)
}

export const deleteCarrierAccount = (id: number): Promise<any> => {
  return postAsync(`${root}/${id}/Deactivate`, {})
}

export const getCarrierAccountById = (id: number): Promise<any> => {
  return getAsync(accountById(id))
}

export interface BodySchema {
  status?: number
  intShop: number
  intCustomer: number
  intCarrier: number
  sinLSTransactionTypeId: number
  dtmStartDate?: Date
  dtmEndDate?: Date
}
export const carrierTransactionPaginate = (
  pageSize = 10,
  currentPage = 1,
  body: BodySchema
): Promise<{ list: ResponseCarrierTransaction[]; pagination: Pagination }> => {
  body.status = -1

  return postAsync(getTransactionPagination(pageSize, currentPage), body)
}

// import * as React from 'react';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import Card from '@mui/material/Card'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { getLSShipp } from 'src/@http/labels-server'
import jobStore from 'src/zustand/Job'

import { IconAction } from '../components/rateSheets/TableRateSheet'

const RowOptions = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setTabValue } = jobStore()

  const handleClick = (mode: string) => {
    if (mode === 'Detail') {
      setTabValue('1')
    } else if (mode === 'Step') {
      setTabValue('2')
    }
  }

  return (
    <Box width='100%' display='flex' justifyContent='center'>
      <IconAction title={'Order detail'} icon='mdi:eye' onClick={() => handleClick('Detail')} />
    </Box>
  )
}

const columns: GridColDef[] = [
  { field: 'dtmShipDate', headerName: 'Shipment Date', minWidth: 150, flex: 3 },
  {
    field: 'vchOrderNumber',
    minWidth: 150,
    headerName: 'Order Number',
    flex: 3
  },
  {
    field: 'vchCustomerName',
    minWidth: 150,
    headerName: 'Customer',
    flex: 3
  },
  {
    field: 'vchCarrierName',
    minWidth: 150,
    headerName: 'Courier',
    flex: 3
  },
  {
    field: 'vchResultTrackingNumber',
    minWidth: 150,
    headerName: 'Tracking Number',
    flex: 3
  },
  {
    //FALTA
    field: 'operationNumber',
    minWidth: 150,
    headerName: 'Operation Number',
    flex: 3
  },
  {
    field: 'vchToAddressStreetLines',
    minWidth: 150,
    headerName: 'Shipping Adress',
    flex: 3
  },
  {
    field: 'vchToAddressStateOrProvinceCode',
    minWidth: 150,
    headerName: 'State',
    flex: 3
  },
  {
    field: 'vchToAddressCity',
    minWidth: 150,
    headerName: 'City',
    flex: 3
  },
  {
    //FALTA
    field: 'shippingFee',
    minWidth: 150,
    headerName: 'Shipping Fee',
    flex: 3,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.movementType === 'income' ? `$${params.row.shippingFee}` : `$${params.row.shippingFee}`
  },
  {
    field: 'decTotalValue',
    minWidth: 150,
    headerName: 'Amount',
    flex: 3,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.movementType === 'income' ? `$${params.row.amount}` : `$${params.row.amount}`
  },
  {
    field: '',
    minWidth: 150,
    headerName: 'Action',
    headerAlign: 'center',
    flex: 3,
    renderCell: () => <RowOptions />
  }
]

// const rows = [
//   {
//     id: 1,
//     shipmentDate: '2024-01-23',
//     customer: 'Elite 1',
//     orderNumber: 'Order123',
//     carrier: 'UPS',
//     trackingNumber: 'TRK001',
//     shippingFee: 10.5,
//     operationNumber: 'OP123',
//     shippingAddress: '123 Main St',
//     state: 'CA',
//     city: 'Los Angeles',
//     amount: 100
//   },
//   {
//     id: 2,
//     shipmentDate: '2024-01-24',
//     orderNumber: 'Order456',
//     customer: 'Elite 2',
//     carrier: 'FedEx',
//     trackingNumber: 'TRK002',
//     shippingFee: 8.2,
//     operationNumber: 'OP456',
//     shippingAddress: '456 Elm St',
//     state: 'NY',
//     city: 'New York',
//     amount: 50
//   },
//   {
//     id: 3,
//     shipmentDate: '2024-01-25',
//     orderNumber: 'Order789',
//     customer: 'Elite 1',
//     carrier: 'DHL',
//     trackingNumber: 'TRK003',
//     shippingFee: 12.75,
//     operationNumber: 'OP789',
//     shippingAddress: '789 Oak St',
//     state: 'TX',
//     city: 'Dallas',
//     amount: 75
//   },
//   {
//     id: 4,
//     shipmentDate: '2024-01-26',
//     orderNumber: 'Order101',
//     customer: 'Elite 2',
//     carrier: 'USPS',
//     trackingNumber: 'TRK004',
//     shippingFee: 6.8,
//     operationNumber: 'OP101',
//     shippingAddress: '101 Pine St',
//     state: 'FL',
//     city: 'Miami',
//     amount: 120
//   },
//   {
//     id: 5,
//     shipmentDate: '2024-01-27',
//     orderNumber: 'Order131',
//     customer: 'Elite 2',
//     carrier: 'Amazon Logistics',
//     trackingNumber: 'TRK005',
//     shippingFee: 15.3,
//     operationNumber: 'OP131',
//     shippingAddress: '131 Maple St',
//     state: 'WA',
//     city: 'Seattle',
//     amount: 90
//   },
//   // Nuevas filas
//   {
//     id: 6,
//     shipmentDate: '2024-01-28',
//     orderNumber: 'Order162',
//     customer: 'Elite 2',
//     carrier: 'DHL',
//     trackingNumber: 'TRK006',
//     shippingFee: 9.5,
//     operationNumber: 'OP162',
//     shippingAddress: '162 Birch St',
//     state: 'IL',
//     city: 'Chicago',
//     amount: 80
//   },
//   {
//     id: 7,
//     shipmentDate: '2024-01-29',
//     orderNumber: 'Order193',
//     customer: 'Elite 2',
//     carrier: 'UPS',
//     trackingNumber: 'TRK007',
//     shippingFee: 11.2,
//     operationNumber: 'OP193',
//     shippingAddress: '193 Cedar St',
//     state: 'OH',
//     city: 'Columbus',
//     amount: 110
//   },
//   {
//     id: 8,
//     shipmentDate: '2024-01-30',
//     orderNumber: 'Order224',
//     customer: 'Elite 2',
//     carrier: 'FedEx',
//     trackingNumber: 'TRK008',
//     shippingFee: 7.9,
//     operationNumber: 'OP224',
//     shippingAddress: '224 Walnut St',
//     state: 'PA',
//     city: 'Philadelphia',
//     amount: 60
//   },
//   {
//     id: 9,
//     shipmentDate: '2024-01-31',
//     orderNumber: 'Order255',
//     customer: 'Elite 2',
//     carrier: 'USPS',
//     trackingNumber: 'TRK009',
//     shippingFee: 13.4,
//     operationNumber: 'OP255',
//     shippingAddress: '255 Spruce St',
//     state: 'MI',
//     city: 'Detroit',
//     amount: 95
//   },
//   {
//     id: 10,
//     shipmentDate: '2024-02-01',
//     orderNumber: 'Order286',
//     customer: 'Elite 1',
//     carrier: 'Amazon Logistics',
//     trackingNumber: 'TRK010',
//     shippingFee: 14.8,
//     operationNumber: 'OP286',
//     shippingAddress: '286 Pine St',
//     state: 'CO',
//     city: 'Denver',
//     amount: 70
//   },
//   {
//     id: 11,
//     shipmentDate: '2024-02-02',
//     orderNumber: 'Order317',
//     customer: 'Elite 2',
//     carrier: 'DHL',
//     trackingNumber: 'TRK011',
//     shippingFee: 8.1,
//     operationNumber: 'OP317',
//     shippingAddress: '317 Oak St',
//     state: 'AZ',
//     city: 'Phoenix',
//     amount: 85
//   },
//   {
//     id: 12,
//     shipmentDate: '2024-02-03',
//     orderNumber: 'Order348',
//     customer: 'Elite 2',
//     carrier: 'UPS',
//     trackingNumber: 'TRK012',
//     shippingFee: 11.9,
//     operationNumber: 'OP348',
//     shippingAddress: '348 Elm St',
//     state: 'NC',
//     city: 'Charlotte',
//     amount: 105
//   },
//   {
//     id: 13,
//     shipmentDate: '2024-02-04',
//     orderNumber: 'Order379',
//     customer: 'Elite 1',
//     carrier: 'FedEx',
//     trackingNumber: 'TRK013',
//     shippingFee: 9.6,
//     operationNumber: 'OP379',
//     shippingAddress: '379 Main St',
//     state: 'GA',
//     city: 'Atlanta',
//     amount: 65
//   },
//   {
//     id: 14,
//     shipmentDate: '2024-02-05',
//     orderNumber: 'Order410',
//     customer: 'Elite 1',
//     carrier: 'USPS',
//     trackingNumber: 'TRK014',
//     shippingFee: 12.2,
//     operationNumber: 'OP410',
//     shippingAddress: '410 Maple St',
//     state: 'WA',
//     city: 'Seattle',
//     amount: 115
//   },
//   {
//     id: 15,
//     shipmentDate: '2024-02-06',
//     orderNumber: 'Order441',
//     customer: 'Elite 1',
//     carrier: 'Amazon Logistics',
//     trackingNumber: 'TRK015',
//     shippingFee: 10.7,
//     operationNumber: 'OP441',
//     shippingAddress: '441 Oak St',
//     state: 'TX',
//     city: 'Dallas',
//     amount: 75
//   },
//   // Filas adicionales hasta la 20
//   {
//     id: 16,
//     shipmentDate: '2024-02-07',
//     orderNumber: 'Order472',
//     customer: 'Elite 1',
//     carrier: 'DHL',
//     trackingNumber: 'TRK016',
//     shippingFee: 14.5,
//     operationNumber: 'OP472',
//     shippingAddress: '472 Cedar St',
//     state: 'FL',
//     city: 'Miami',
//     amount: 90
//   },
//   {
//     id: 17,
//     shipmentDate: '2024-02-08',
//     orderNumber: 'Order503',
//     customer: 'Elite 1',
//     carrier: 'UPS',
//     trackingNumber: 'TRK017',
//     shippingFee: 8.9,
//     operationNumber: 'OP503',
//     shippingAddress: '503 Walnut St',
//     state: 'CA',
//     city: 'Los Angeles',
//     amount: 130
//   },
//   {
//     id: 18,
//     shipmentDate: '2024-02-09',
//     orderNumber: 'Order534',
//     customer: 'Elite 1',
//     carrier: 'FedEx',
//     trackingNumber: 'TRK018',
//     shippingFee: 11.3,
//     operationNumber: 'OP534',
//     shippingAddress: '534 Spruce St',
//     state: 'NY',
//     city: 'New York',
//     amount: 70
//   },
//   {
//     id: 19,
//     shipmentDate: '2024-02-10',
//     orderNumber: 'Order565',
//     customer: 'Elite 2',
//     carrier: 'USPS',
//     trackingNumber: 'TRK019',
//     shippingFee: 9.8,
//     operationNumber: 'OP565',
//     shippingAddress: '565 Pine St',
//     state: 'WA',
//     city: 'Seattle',
//     amount: 110
//   },
//   {
//     id: 20,
//     shipmentDate: '2024-02-11',
//     orderNumber: 'Order596',
//     customer: 'Elite 1',
//     carrier: 'Amazon Logistics',
//     trackingNumber: 'TRK020',
//     shippingFee: 13.1,
//     operationNumber: 'OP596',
//     shippingAddress: '596 Main St',
//     state: 'TX',
//     city: 'Dallas',
//     amount: 85
//   }
// ]

const BalanceAccountTableServer = ({
  filterDateLimits,
  carrierIds,
  customerIds
}: {
  filterDateLimits: {
    StartDate: string
    EndDate: string
  }
  carrierIds?: number[]
  customerIds?: number[]
}) => {
  const getDateXDaysAgo = (daysAgo: number) => {
    const today = new Date()
    const result = new Date(today)
    result.setDate(today.getDate() - daysAgo)

    const year = result.getFullYear()
    const month = (result.getMonth() + 1).toString().padStart(2, '0')
    const day = result.getDate().toString().padStart(2, '0')

    return `${month}/${day}/${year}`
  }

  const [rows, setRows] = useState<any[]>([]) // rows: any[
  const [dataPaginationGrid, setDataPaginationGrid] = useState<{
    loading: boolean
    rows: any[]
    rowCount: number
    rowsPerPageOptions: number[]
    pageSize: number
    page: number
  }>({
    loading: false,
    rows: [],
    rowCount: 0,
    rowsPerPageOptions: [10, 25, 50],
    pageSize: 50,
    page: 1
  })

  const changePageSize = (pageSize: number) => {
    setDataPaginationGrid({ ...dataPaginationGrid, pageSize })
  }

  useEffect(() => {
    const getLSShippData = async () => {
      try {
        const { list } = await getLSShipp({
          dates: {
            dateFrom: filterDateLimits.StartDate
              ? new Date(filterDateLimits.StartDate)
              : new Date(getDateXDaysAgo(30).toString()),
            dateTo: filterDateLimits.EndDate
              ? new Date(filterDateLimits.EndDate)
              : new Date(getDateXDaysAgo(0).toString())
          },
          pageSize: dataPaginationGrid.pageSize,
          currectPage: dataPaginationGrid.page,
          carrierIds: carrierIds || [],
          customerIds: customerIds || []
        })

        // setRows(list)
        // Set the rows in dataPaginationGrid
        setDataPaginationGrid({ ...dataPaginationGrid, rows: list })
      } catch (error) {
        console.error(error)
      }
    }

    getLSShippData()
  }, [filterDateLimits, dataPaginationGrid.pageSize, dataPaginationGrid.page, carrierIds, customerIds])

  return (
    <Card sx={{}}>
      <DataGrid
        rows={dataPaginationGrid.rows}
        autoHeight
        columns={columns}
        loading={dataPaginationGrid.loading}
        rowCount={dataPaginationGrid.rowCount}
        page={dataPaginationGrid.page - 1}
        pageSize={dataPaginationGrid.pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={newPageSize => changePageSize(newPageSize)}
      />
    </Card>
  )
}

export default BalanceAccountTableServer

import * as React from 'react'
import { useState } from 'react'
import Cards, { Focused } from 'react-credit-cards'
import { Grid, SelectChangeEvent, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stepper from '@mui/material/Stepper'
import Payment from 'payment'
import CardWrapper from 'src/@core/styles/libs/react-credit-cards'
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from 'src/@core/utils/format'
import { postCarrierTransaction } from 'src/@http/carrier-account'
import appStore from 'src/zustand/app'

// ** Styles Import
import 'react-credit-cards/es/styles-compiled.css'

const steps = ['Add', 'Summary']

export default function StepperPayMethod(props: any) {
  const { setShowPaymentDialog } = appStore()
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set<number>())
  const [paymentMethod, setPaymentMethod] = useState('Credit')
  const [amount, setAmount] = useState<number>(0)
  const [minAmount, setMinAmount] = useState(0)
  const [error, setError] = useState<string | null>(null)

  const { carrierSelected, introducedAmount } = props

  // ** Card Info states
  const [cardInfoError, setCardInfoError] = useState<{
    name: boolean
    cardNumber: boolean
    expiry: boolean
    cvc: boolean
  }>({
    name: false,
    cardNumber: false,
    expiry: false,
    cvc: false
  })
  const [data, setData] = useState<any>({
    name: '',
    cardNumber: '',
    expiry: '',
    cvc: '',
    cardType: ''
  })

  const handleChange = (event: SelectChangeEvent) => {
    setPaymentMethod(event.target.value as string)
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = async () => {
    let newSkipped = skipped

    const errors = verifyCardData(data)

    if (errors.name || errors.cardNumber || errors.expiry || errors.cvc) {
      setCardInfoError(errors)

      return
    }

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    if (activeStep === steps.length - 1) {
      const transaction = {
        intLSCarrierAccount: carrierSelected.intLSCarrierAccount,
        decAmount: introducedAmount,
        vchDescription: `Recarga de ${introducedAmount}`,
        dtmCreatedDate: new Date().toISOString(),
        intCreatedUserId: 1,
        bigOrderId: 0,
        vchTrakingNumber: 'TRGN9437476',
        vchSource: 'NOSE',
        intStatusId: 1,
        vchStatusName: 'Active',
        vchStatusColor: 'success',
        sinLSTransactionTypeId: 1,
        vchTransactionTypeName: 'Credit',
        vchCustomerName: '',
        vchShopName: '',
        vchName: '',
        vchLegacyNumber: ''
      }

      console.log('transaction', transaction)

      try {
        await postCarrierTransaction(transaction)
      } catch (error: any) {
        console.error(error)
      }
      setShowPaymentDialog(false)
      props.onFinished(true)
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const verifyCardData = (data: any) => {
    const errors = {
      name: data?.name ? false : true,
      cardNumber: data?.cardNumber ? false : true,
      expiry: data?.expiry ? false : true,
      cvc: data?.cvc ? false : true
    }

    if (data) {
      if (
        (data.cardType === 'amex' && data.cardNumber.length < 15) ||
        (data.cardType !== 'amex' && data.cardNumber.length < 16) ||
        data.cardType === null
      ) {
        errors.cardNumber = true
      }

      if (data.name.length < 3) {
        errors.name = true
      }

      if ((data.cardType === 'amex' && data.cvc.length < 4) || (data.cardType !== 'amex' && data.cvc.length < 3)) {
        errors.cvc = true
      }

      if (data.expiry.length < 5) {
        errors.expiry = true
      }

      const date = data.expiry.split('/')
      const today = new Date()

      //Get the current year and month in two digits
      const year = today.getFullYear().toString().substr(-2)
      const month = (today.getMonth() + 1).toString().padStart(2, '0')

      if (
        parseInt(date[1]) < parseInt(year) ||
        (parseInt(date[1]) === parseInt(year) && parseInt(date[0]) < parseInt(month))
      ) {
        errors.expiry = true
      }
    }

    return errors
  }

  // const handleBack = () => {
  //   setActiveStep(prevActiveStep => prevActiveStep - 1)
  // }

  const handleBack = () => {
    setShowPaymentDialog(false)
  }

  const handleBlur = () => {
    if (amount < minAmount) {
      setError('It is less than the minimum')
    } else {
      setError(null)
    }
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {activeStep === 0 && (
          <Grid container display='flex' justifyContent='center' sx={{ mb: 6 }}>
            {/* <Grid item xs={12} md={4} sx={{ pr: { md: 3, xs: 0 } }}>
              <FormControl fullWidth>
                <InputLabel htmlFor='outlined-adornment-amount'>Amount</InputLabel>
                <OutlinedInput
                  id='outlined-adornment-amount'
                  fullWidth
                  name='amount'
                  label='Amount'
                  value={amount}
                  autoComplete='off'
                  onBlur={handleBlur}
                  onChange={(e) => setAmount(Number(e.target.value))}
                  startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                  error={error === null ? false : true}
                />
              </FormControl>
              {error && <span style={{ color: "red" }}>{error}</span>}
            </Grid> */}
          </Grid>
        )}
        {paymentMethod && paymentMethod !== 'select-method' && (
          <>
            <Stepper activeStep={activeStep}>{/* ... (otros elementos del stepper) */}</Stepper>
            {activeStep !== steps.length && (
              <React.Fragment>
                {activeStep === 0 && (
                  <Box>
                    <FormAdd error={cardInfoError} getData={data => setData(data)} />
                  </Box>
                )}
                {activeStep === 1 && <Box py={4}>{/* ... (contenido del paso 1) */}</Box>}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  {/* <Button color='inherit' disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                    Back
                  </Button> */}
                  <Button color='inherit' onClick={handleBack} sx={{ mr: 1 }}>
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleNext}>{activeStep === steps.length - 1 ? 'Finish' : 'Next'}</Button>
                </Box>
              </React.Fragment>
            )}
          </>
        )}
      </Box>
    </>
  )
}
type FormAddProp = {
  getData: (data: any) => void
  error: { name: boolean; cardNumber: boolean; expiry: boolean; cvc: boolean }
}

const FormAdd = (props: FormAddProp) => {
  const { getData, error } = props

  // ** States
  const [form, setForm] = useState({
    name: '',
    cvc: '',
    cardNumber: '',
    expiry: '',
    cardType: ''
  })
  const [focus, setFocus] = React.useState<Focused | undefined>()

  const handleBlur = () => {
    setFocus(undefined)
  }

  const handleInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value, Payment)
      setForm({ ...form, cardNumber: target.value, cardType: Payment.fns.cardType(target.value) })
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value)
      setForm({ ...form, expiry: target.value })
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value, form.cardNumber, Payment)
      setForm({ ...form, cvc: target.value })
    } else if (target.name === 'name') {
      setForm({ ...form, name: target.value })
    }
  }

  // ** Get data on form change, for the data to be correctly updated when verified
  React.useEffect(() => {
    getData(form)
  }, [form])

  return (
    <Grid container spacing={6} mt={4}>
      <Grid item xs={12}>
        <CardWrapper sx={{ '& .rccs': { m: '0 auto', display: { xs: 'none', sm: 'block' } } }}>
          <Cards cvc={form.cvc} focused={focus} expiry={form.expiry} name={form.name} number={form.cardNumber} />
        </CardWrapper>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={6} mb={3}>
          <Grid item xs={12}>
            <TextField
              error={error.cardNumber}
              fullWidth
              name='number'
              value={form.cardNumber}
              autoComplete='off'
              label='Card Number'
              onBlur={handleBlur}
              onChange={handleInputChange}
              placeholder='0000 0000 0000 0000'
              onFocus={e => setFocus(e.target.name as Focused)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={error.name}
              fullWidth
              name='name'
              value={form.name}
              autoComplete='off'
              onBlur={handleBlur}
              label='Name on Card'
              placeholder='John Doe'
              onChange={handleInputChange}
              onFocus={e => setFocus(e.target.name as Focused)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              error={error.expiry}
              fullWidth
              name='expiry'
              label='Expiry'
              value={form.expiry}
              onBlur={handleBlur}
              placeholder='MM/YY'
              onChange={handleInputChange}
              inputProps={{ maxLength: '5' }}
              onFocus={e => setFocus(e.target.name as Focused)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              error={error.cvc}
              fullWidth
              name='cvc'
              label='CVC'
              value={form.cvc}
              autoComplete='off'
              onBlur={handleBlur}
              onChange={handleInputChange}
              onFocus={e => setFocus(e.target.name as Focused)}
              placeholder={Payment.fns.cardType(form.cardNumber) === 'amex' ? '1234' : '123'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

import { FC, useState } from 'react'
import { Grid, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
// ** MUI Imports
import { DatePicker } from '@mui/x-date-pickers'

// ** Types

export interface IDate {
  dateFrom: Date | null
  dateTo: Date | null
}

interface DateFilterProps {
  onData: (date: IDate) => void
  width?: number | string
}

export type IIcon = {
  width: number
  height: number
}

const IconArrowRight: FC<IIcon> = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='currentColor'
      className='bi bi-arrow-right'
      viewBox='0 0 16 16'
    >
      <path
        fillRule='evenodd'
        d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'
      />
    </svg>
  )
}

const DateFilter = ({ onData, width }: DateFilterProps) => {
  const [dataForm, setDataForm] = useState<IDate>({
    dateFrom: new Date(),
    dateTo: new Date()
  })
  const theme = useTheme()
  const matchesLG = useMediaQuery(theme.breakpoints.only('lg'))
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))
  const dynamicStyles = [{ minWidth: { lg: '120px' }, margin: { lg: '.5rem 0' } }, { width: { lg: '170px' } }]

  function handleDateFilter(newValue: Date | null): void {
    setDataForm({ ...dataForm, dateTo: newValue })
    onData(dataForm)
  }

  return (
    <Grid width={width} display='flex' flexDirection={{ xs: 'column', lg: 'row' }} alignItems='center'>
      <Grid item sx={{ marginRight: { lg: '1rem' } }}>
        <DatePicker
          label={'From'}
          value={dataForm.dateFrom}
          onChange={newValue => {
            setDataForm({ ...dataForm, dateFrom: newValue })
          }}
          renderInput={params => (
            <TextField {...params} sx={{ ...dynamicStyles }} fullWidth={!matchesXS} size='small' />
          )}
        />
      </Grid>
      {!matchesXS && (
        <Grid item sx={{ pr: 4 }}>
          {!matchesLG && <IconArrowRight width={24} height={24} />}
        </Grid>
      )}
      <Grid item sx={{ margin: { xs: '1rem 0' } }}>
        <DatePicker
          label={'To'}
          value={dataForm.dateTo}
          minDate={dataForm.dateFrom}
          onChange={newValue => handleDateFilter(newValue)}
          renderInput={params => <TextField {...params} sx={{ ...dynamicStyles }} size='small' />}
        />
      </Grid>
    </Grid>
  )
}

export default DateFilter

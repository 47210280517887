import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { getShops } from 'src/@http/shop'
import { createUser, getUser, updateUser } from 'src/@http/user'
import { RolModel } from 'src/types/models/RolModel'
import { ShopModel } from 'src/types/models/ShopModels'
import { logger } from 'src/utils/Logger'
import companiesStore from 'src/zustand/companies'
import * as yup from 'yup'

interface UserForm {
  email: string
  countryName: string
  regionName: string
  userType: number
  postalCodes: number[]
  customer: {
    id: number
    vchName: string
  }
  isAccountManager: boolean
  tinParentRol?: boolean
}

interface Customer {
  id: number
  vchName: string
}

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const RegionManagerAddOrEdit = () => {
  const { companyGlobal } = companiesStore()
  const params = useParams()

  const userId = params.id ?? ''
  const query = useQuery()
  const view: boolean = query.get('view') === 'true' ? true : false

  const initForm: UserForm = {
    email: '',
    countryName: '',
    regionName: '',
    userType: 1,
    postalCodes: [],
    customer: {
      id: 0,
      vchName: ''
    },
    isAccountManager: false,
    tinParentRol: false
  }

  const validations = {
    email: yup.string().email(),
    firstName: yup.string(),
    lastName: yup.string()
  }

  if (!view && userId === '') {
    validations.email = validations.email.required()
    validations.firstName = validations.firstName.required()
    validations.lastName = validations.lastName.required()
  }

  const schemaValidations = yup.object().shape(validations)

  // ** State
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [customers, setCustomers] = useState<Customer[]>([])
  const [chipValues, setChipValues] = useState<string[]>([])
  const [postalCodes, setpostalCodes] = useState<RolModel[]>([])
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { control, handleSubmit, setValue } = useForm<UserForm>({
    resolver: yupResolver(schemaValidations),
    defaultValues: initForm
  })

  useEffect(() => {
    const setInitialState = async () => {
      let customers: Customer[] = []
      setIsLoading(true)
      try {
        const response = (await getShops()) as ShopModel[]
        if (response) {
          customers = response.map(item => ({
            id: item.id,
            vchName: item.vchName
          }))
          setCustomers(customers)
          // if (userId === '') setValue('customer', { id: response[0].id, vchName: response[0].vchName })
        }
      } catch (err: any) {
        logger.error('Error', err)
      }
      if (userId !== '') await loadUserById(userId, customers)
      setIsLoading(false)
    }

    setInitialState()
  }, [userId])

  const loadUserById = async (userId: string, customerss: Customer[]) => {
    try {
      const user = await getUser(parseInt(userId))
      if (user) {
        setValue('email', user.vchMail)
        setValue('countryName', user.vchName)
        setValue('regionName', user.vchLastName)
        setValue('userType', user.tinUserType)
        setValue('isAccountManager', user.bitIsAccountManager === 1)
        setValue(
          'postalCodes',
          user.listUserRole.map(role => role.sinRoleId)
        )
        const customer = customerss.find((customer: Customer) => {
          return customer.id === user.listUserRole[0].intCustomerId
        })
        if (customer) setValue('customer', customer)
      }
    } catch (err: any) {
      logger.error(err)
    }
  }

  const onSubmit = async (form: UserForm) => {

    setIsSubmitting(true)

    setIsSubmitting(true)
    if (userId == '') {
      try {
        await createUser({
          vchName: form.countryName,
          vchLastName: form.regionName,
          tinUserType: 2,
          intCustomerId: companyGlobal ? companyGlobal.intCustomerId : 0, //  3,
          vchMail: form.email,
          vchPassword: '',
          vchFullName: form.countryName + ' ' + form.regionName,
          bitIsAccountManager: form.isAccountManager ? 1 : 0,
          listUserRole: form.postalCodes.map((role: number) => {
            return {
              sinRoleId: role,
              intCustomerId: companyGlobal ? companyGlobal.intCustomerId : 0
            }
          })
        })

        toast.success(t('CREATE_SUCCESS'), { duration: 4000 })
        navigate('/admin-users')
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      }
    } else {
      try {
        await updateUser({
          id: parseInt(userId),
          intUserId: parseInt(userId),
          tinUserType: 2,
          intCustomerId: companyGlobal ? companyGlobal.intCustomerId : 0,
          vchName: form.countryName,
          vchLastName: form.regionName,
          vchMail: form.email,
          vchFullName: form.countryName + ' ' + form.regionName,
          bitIsAccountManager: form.isAccountManager ? 1 : 0,
          listUserRole: form.postalCodes.map((role: number) => {
            return {
              sinRoleId: role,
              intCustomerId: companyGlobal ? companyGlobal.intCustomerId : 0
            }
          })
        })
        toast.success(t('UPDATE_SUCCESS'), { duration: 4000 })
        navigate('/admin-users')
      } catch (error: any) {
        toast.error(error.message, { duration: 4000 })
      }
    }
    setIsSubmitting(false)
  }

  //   useEffect(() => {
  //     const fetch = async () => {
  //       try {
  //         const response = await getpostalCodes()
  //         if (response) {
  //           setpostalCodes(response)
  //         }
  //       } catch (err: any) {
  //         // console.log('Error', err)
  //       }
  //     }
  //     fetch()
  //   }, [])

  if (isLoading) {
    return (
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            {userId == '' && <CardHeader title={t('NEW_REGION')} />}
            {userId != '' && !view && <CardHeader title={t('EDIT_REGION')} subheader={'Id: ' + userId} />}
            {userId != '' && view && <CardHeader title={t('VIEW_REGION')} subheader={'Id: ' + userId} />}

            <Divider sx={{ m: '0 !important' }} />

            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <Box sx={{ mt: 6, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress sx={{ mb: 5 }} />
                    <Typography>{t('LOADING')}...</Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          {userId == '' && <CardHeader title={t('NEW_REGION')} />}
          {userId != '' && !view && <CardHeader title={t('EDIT_REGION')} subheader={'Id: ' + userId} />}
          {userId != '' && view && <CardHeader title={t('VIEW_REGION')} subheader={'Id: ' + userId} />}

          <Divider sx={{ m: '0 !important' }} />
          <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <Grid container spacing={5}>
                {/* <Grid item xs={12}>
                  <Divider sx={{ mb: '0 !important' }} />
                </Grid> */}
                <Grid item xs={12}>
                  <Typography variant='body2' sx={{ fontWeight: 600 }}>
                    1. {t('REGION_INFO')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth sx={{}}>
                    <Controller
                      name='countryName'
                      control={control}
                      render={({ field: { ...field }, fieldState }) => (
                        <TextField
                          variant='outlined'
                          disabled={view}
                          {...field}
                          label={t('COUNTRY_NAME')}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth sx={{}}>
                    <Controller
                      name='regionName'
                      control={control}
                      render={({ field: { ...field }, fieldState }) => (
                        <TextField
                          variant='outlined'
                          disabled={view}
                          {...field}
                          label={t('REGION_NAME')}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ mb: '0 !important' }} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2' sx={{ fontWeight: 600 }}>
                    2. {t('POSTAL_CODES')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <Controller
                      name='postalCodes'
                      control={control}
                      render={({ field: { name, value, onChange } }) => (
                        <>
                          <TextField
                            label={t('POSTAL_CODES')}
                            fullWidth
                            InputLabelProps={{
                              shrink: true
                            }}
                            name={name}
                            value={value}
                            onChange={e => onChange(e)}
                            disabled={view}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                              if (e.key === 'Enter') {
                                e.preventDefault()
                                const inputValue = (e.target as HTMLInputElement).value.trim()
                                if (inputValue !== '') {
                                  onChange([...postalCodes, inputValue])
                                  setChipValues([...chipValues, inputValue]) // Update chipValues
                                  setValue('postalCodes', []) // Clear the input field by setting it to an empty array
                                }
                              }
                            }}
                          />
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', mt: 1 }}>
                            {chipValues.map((role, index) => (
                              <Chip
                                key={index}
                                size='small'
                                label={role}
                                onDelete={() => {
                                  const newChipValues = [...chipValues]
                                  newChipValues.splice(index, 1)
                                  setChipValues(newChipValues)
                                  // You may also want to update postalCodes based on your logic
                                }}
                                sx={{ m: 0.75 }}
                              />
                            ))}
                          </Box>
                        </>
                      )}
                    />
                  </FormControl>
                </Grid>

                {!view && (
                  <Grid container item xs={12} justifyContent='flex-end'>
                    <Button onClick={() => navigate('/admin-users')} variant='outlined' sx={{ mr: 4 }}>
                      {t('CANCEL')}
                    </Button>
                    <LoadingButton
                      loading={isSubmitting}
                      type='submit'
                      variant='contained'
                      sx={{ mr: 4 }}
                      disabled={false}
                    >
                      {t('SAVE_CHANGES')}
                    </LoadingButton>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </form>
        </Card>
      </Grid>
    </Grid>
  )
}

export default RegionManagerAddOrEdit

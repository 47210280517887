import { forwardRef } from 'react'
import { Typography } from '@mui/material'

import { DialogGeneric } from './DialogGeneric'

interface UnsavedChangesModal {
  texts: { title: string; text: string; confirmText?: string }
}

const UnsavedChangesModal = forwardRef((props: UnsavedChangesModal, ref) => {
  const { texts } = props

  return (
    <DialogGeneric
      title={texts.title}
      ref={ref}
      confirmText={texts.confirmText}
      colorTitle='warning'
      colorConfirmBtn={'error'}
    >
      <Typography variant='subtitle1'>{texts.text}</Typography>
    </DialogGeneric>
  )
})

export default UnsavedChangesModal

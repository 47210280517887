// ** MUI Imports
import React, { useState } from 'react'
import {
  Box,
  BoxProps,
  Card,
  CardContent,
  Divider,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableCellBaseProps,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material'
import { OrderDto, OrderProductsDto } from 'src/types/models/OrderModel'
// ** Configs
//import themeConfig from 'src/configs/themeConfig'
// ** Types
import { formatOrderDate } from 'src/utils/formatDate'
import invoiceStore from 'src/zustand/invoice'

const CardPreviewInvoice = () => {
  const MUITableCell = styled(TableCell)<TableCellBaseProps>(({ theme }) => ({
    borderBottom: 0,
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    paddingTop: `${theme.spacing(1)} !important`,
    paddingBottom: `${theme.spacing(1)} !important`
  }))
  const { invoiceData } = invoiceStore()
  const CalcWrapper = styled(Box)<BoxProps>(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(2)
    }
  }))

  const [orderData, setOrderData] = useState<null | OrderDto>(null)
  const [orderDetailData, setOrderDetailData] = useState<null | OrderProductsDto[]>(null)
  const [isEditing, setIsEditing] = useState(false)
  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item sm={6} xs={12} sx={{ mb: { sm: 0, xs: 4 } }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ mb: 6, display: 'flex', alignItems: 'center' }}>
                <img
                  src={`http://${window.location.hostname}:${window.location.port}/images/logo.png`}
                  alt='Logo'
                  style={{ maxWidth: '50px', maxHeight: '50px' }}
                />
                <Typography
                  variant='h6'
                  sx={{ ml: 2.5, fontWeight: 600, lineHeight: 'normal', textTransform: 'uppercase' }}
                >
                  elite OPS
                </Typography>
              </Box>
              <div>
                <Table sx={{ maxWidth: '200px' }}>
                  <TableBody>
                    <TableRow>
                      <MUITableCell style={{ width: '50%' }}>Status:</MUITableCell>
                      <MUITableCell>
                        <Typography>{invoiceData?.vchStatusName}</Typography>
                      </MUITableCell>
                    </TableRow>
                    <TableRow>
                      <MUITableCell style={{ width: '50%' }}>Batch id:</MUITableCell>
                      <MUITableCell>
                        <Typography>{invoiceData?.intCarrierInvoiceBatchId}</Typography>
                      </MUITableCell>
                    </TableRow>
                    <TableRow>
                      <MUITableCell style={{ width: '50%' }}>Customer:</MUITableCell>
                      <MUITableCell>
                        <Typography>{invoiceData?.vchCustomerName}</Typography>
                      </MUITableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}>
              <Table sx={{ maxWidth: '300px' }}>
                <TableBody>
                  <TableRow>
                    <MUITableCell>
                      <Typography variant='h6'>Invoice</Typography>
                    </MUITableCell>
                    <MUITableCell>
                      <Typography variant='h6'>{`#${invoiceData?.vchInvoiceNumber}`}</Typography>
                    </MUITableCell>
                    <MUITableCell></MUITableCell>
                  </TableRow>
                  <TableRow>
                    <MUITableCell>
                      <Typography variant='body2'>Tracking N°:</Typography>
                    </MUITableCell>
                    <MUITableCell>
                      <Typography variant='body2'>{invoiceData?.vchTrackingNumber}</Typography>
                    </MUITableCell>
                  </TableRow>
                  <TableRow>
                    <MUITableCell>
                      <Typography variant='body2'>Created Date:</Typography>
                    </MUITableCell>
                    <MUITableCell>
                      <Typography variant='body2'>
                        {formatOrderDate(invoiceData?.dtmCreatedDate ?? '00/00/00')}
                      </Typography>
                    </MUITableCell>
                  </TableRow>
                  <TableRow>
                    <MUITableCell>
                      <Typography variant='body2'>Last update:</Typography>
                    </MUITableCell>
                    <MUITableCell>
                      <Typography variant='body2'>
                        {formatOrderDate(invoiceData?.lastModifiedDate ?? '00/00/00')}
                      </Typography>
                    </MUITableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Grid>
        </Grid>
      </CardContent>

      <Divider />

      <CardContent>
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ mb: { lg: 0, xs: 4 } }}>
            <Typography variant='body2' sx={{ mb: 3.5, fontWeight: 600 }}>
              Origin Data:
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <MUITableCell>Company:</MUITableCell>
                    <MUITableCell>
                      <Typography style={{ marginLeft: '10px' }}>{invoiceData?.vchOriginCompany}</Typography>
                    </MUITableCell>
                  </TableRow>
                  <TableRow>
                    <MUITableCell>Adress:</MUITableCell>
                    <MUITableCell>
                      <Typography style={{ marginLeft: '10px' }}>{invoiceData?.vchOriginAddress}</Typography>
                    </MUITableCell>
                  </TableRow>
                  <TableRow>
                    <MUITableCell>City:</MUITableCell>
                    <MUITableCell>
                      <Typography style={{ marginLeft: '10px' }}>{invoiceData?.vchOriginCity}</Typography>
                    </MUITableCell>
                  </TableRow>
                  <TableRow>
                    <MUITableCell>State:</MUITableCell>
                    <MUITableCell>
                      <Typography style={{ marginLeft: '10px' }}>{invoiceData?.vchOriginState}</Typography>
                    </MUITableCell>
                  </TableRow>
                  <TableRow>
                    <MUITableCell>Zip:</MUITableCell>
                    <MUITableCell>
                      <Typography style={{ marginLeft: '10px' }}>{invoiceData?.vchOriginZip}</Typography>
                    </MUITableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: ['flex-start', 'flex-end'] }}>
            <div>
              <Typography variant='body2' sx={{ mb: 3.5, color: 'text.primary', fontWeight: 600 }}>
                Destination Data:
              </Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <MUITableCell>Company:</MUITableCell>
                      <MUITableCell>
                        <Typography style={{ marginLeft: '10px' }}>{invoiceData?.vchDestinationCompany}</Typography>
                      </MUITableCell>
                    </TableRow>
                    <TableRow>
                      <MUITableCell>Adress:</MUITableCell>
                      <MUITableCell>
                        <Typography style={{ marginLeft: '10px' }}>{invoiceData?.vchDestinationAddress}</Typography>
                      </MUITableCell>
                    </TableRow>
                    <TableRow>
                      <MUITableCell>City:</MUITableCell>
                      <MUITableCell>
                        <Typography style={{ marginLeft: '10px' }}>{invoiceData?.vchDestinationCity}</Typography>
                      </MUITableCell>
                    </TableRow>
                    <TableRow>
                      <MUITableCell>State:</MUITableCell>
                      <MUITableCell>
                        <Typography style={{ marginLeft: '10px' }}>{invoiceData?.vchDestinationState}</Typography>
                      </MUITableCell>
                    </TableRow>
                    <TableRow>
                      <MUITableCell>Zip:</MUITableCell>
                      <MUITableCell>
                        <Typography style={{ marginLeft: '10px' }}>{invoiceData?.vchDestinationZip}</Typography>
                      </MUITableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      </CardContent>

      <Divider />

      <CardContent>
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ mb: { lg: 0, xs: 4 } }}>
            <Typography variant='body2' sx={{ mb: 3.5, fontWeight: 600 }}>
              Ship Data:
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <MUITableCell style={{ width: '50%' }}>Ref1:</MUITableCell>
                    <MUITableCell>
                      <Typography>{invoiceData?.vchRef1}</Typography>
                    </MUITableCell>
                  </TableRow>
                  <TableRow>
                    <MUITableCell style={{ width: '50%' }}>Ref2:</MUITableCell>
                    <MUITableCell>
                      <Typography>{invoiceData?.vchRef2}</Typography>
                    </MUITableCell>
                  </TableRow>
                  <TableRow>
                    <MUITableCell style={{ width: '50%' }}>Ref3:</MUITableCell>
                    <MUITableCell>
                      <Typography>{invoiceData?.vchRef3}</Typography>
                    </MUITableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: ['flex-start', 'flex-end'] }}>
            <div>
              <Typography variant='body2' sx={{ mb: 3.5, fontWeight: 600 }}>
                Other Data:
              </Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <MUITableCell>Weight:</MUITableCell>
                      <MUITableCell>
                        <Typography style={{ marginLeft: '10px' }}>{invoiceData?.decWeight}</Typography>
                      </MUITableCell>
                    </TableRow>
                    <TableRow>
                      <MUITableCell>Parent Name:</MUITableCell>
                      <MUITableCell>
                        <Typography style={{ marginLeft: '10px' }}>{invoiceData?.vchParentName}</Typography>
                      </MUITableCell>
                    </TableRow>
                    <TableRow>
                      <MUITableCell>Courier Name:</MUITableCell>
                      <MUITableCell>
                        <Typography style={{ marginLeft: '10px' }}>{invoiceData?.vchCarrierName}</Typography>
                      </MUITableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      </CardContent>

      <Divider />

      <CardContent>
        <Grid container>
          <Grid item xs={12} sm={7} lg={9} sx={{ order: { sm: 1, xs: 2 } }}>
            <TableContainer>
              <Table>
                <TableBody></TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={5} lg={3} sx={{ mb: { sm: 0, xs: 4 }, order: { sm: 2, xs: 1 } }}>
            <CalcWrapper>
              <Typography>Total charge:</Typography>
              <Typography>${invoiceData?.vchCharge ? invoiceData.vchCharge : '$0.00'}</Typography>
            </CalcWrapper>
          </Grid>
        </Grid>
      </CardContent>

      <Divider />

      <CardContent>
        <Typography variant='body2'>
          <strong>Comments:</strong> {'No comments'}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default CardPreviewInvoice

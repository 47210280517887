import { mountStoreDevtool } from 'simple-zustand-devtools'
import { OrderDto } from 'src/types/models/OrderModel'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type State = {
  carrierName: string
  orderZustand: OrderDto | null
}

type Actions = {
  reset: () => void
  setCarrierName: (carrierName: string) => void
  setOrderZustand: (orderZustand: OrderDto) => void
}

const initialState: State = {
  carrierName: '',
  orderZustand: null
}

const orderStore = create<State & Actions>()(
  persist(
    set => ({
      ...initialState,
      setCarrierName: (carrierName: string) => {
        set({ carrierName: carrierName })
      },
      setOrderZustand: (orderZustand: OrderDto) => {
        set({ orderZustand: orderZustand })
      },
      reset: () => {
        set(initialState)
      }
    }),
    {
      name: 'order-store'
    }
  )
)

if (process.env.NODE_ENV === 'development') mountStoreDevtool('order-store', orderStore)

export default orderStore

import { useEffect, useState } from 'react'
import { Autocomplete, FormControl, SelectProps, TextField } from '@mui/material'
import { getAllCarriers, getCarriersByCustomer, getCarriersByModuleId, getCarriersByShop } from 'src/@http/carriers'
import appStore from 'src/zustand/app'

interface SelectorProps extends SelectProps {
  disabled?: boolean
  title?: string
  onHandleSelectedOption: (options: number) => void
  courierSelected?: number | null
  actualLevel?: 'ez' | 'customer' | 'client' // Used to call different services (Account Label)
}

const getActualModule = (actualLevel: string) => {
  if (actualLevel === 'ez') return 1
  if (actualLevel === 'customer') return 2
  if (actualLevel === 'client') return 3

  return 1
}

const SelectorCourier = (props: SelectorProps) => {
  const [options, setOptions] = useState<any[]>([])
  const [selected, setSelected] = useState<number | null>(props.courierSelected || null)
  const { paramsV2 } = appStore()
  const pathname = window.location.pathname
  useEffect(() => {
    const fetch = async () => {
      let list = []

      // alta de cuentas
      if (pathname === '/client-labels/shipp/new' || pathname === '/client-shipp') {
        list = await getCarriersByShop().catch(() => {
          return []
        })
        console.log('Getting carriers by shop', list)
      } else if (pathname.includes('/admin-label-server/account')) {
        list = await getAllCarriers(1).catch(() => {
          return []
        })
        console.log('Getting all carriers', list)
      } else if (
        pathname.includes('/client-labels/account') ||
        pathname.includes('/labels/account') ||
        pathname.includes('/admin-label-server/account')
        || pathname.includes('/carriers')
      ) {
        list = await getCarriersByModuleId({
          CustomerId: paramsV2.CustomerId,
          moduleId: getActualModule(props.actualLevel || 'ez'),
          ShopId: paramsV2.ShopId
        })
      } else {
        list = await getCarriersByCustomer().catch(() => {
          return []
        })
        console.log('Getting carriers by customer', list)
      }
      setOptions(list)
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsV2.ShopId, paramsV2.CustomerId, props.actualLevel])

  const getSelectedLabelByDefault = () => {
    if (props.courierSelected || options.length > 0) {
      const selectedOption = options.find(opt => opt.intCarrierId == props.courierSelected)
      if (selectedOption) return selectedOption.vchName
    }

    return null
  }

  const getSelectedLabel = () => {
    if (selected || options.length > 0) {
      const selectedOption = options.find(opt => opt.intCarrierId == selected)
      if (selectedOption) return selectedOption.vchName
    }

    return null
  }

  return (
    <FormControl fullWidth>
      <Autocomplete
        disabled={props.disabled}
        value={{
          vchName: getSelectedLabelByDefault() || getSelectedLabel() || ''
        }}
        onChange={(e: any, newValue) => {
          if (newValue) {
            setSelected(newValue.intCarrierId)
            props.onHandleSelectedOption(newValue.intCarrierId)
          }
        }}
        options={options}
        id='autocomplete-couriers'
        getOptionLabel={opt => opt.vchName}
        renderInput={params => <TextField {...params} label={props.title} />}
      />
    </FormControl>
  )
}

export default SelectorCourier

import ReactApexcharts from 'react-apexcharts'
import { Theme, useMediaQuery, useTheme } from '@mui/material'
import { ApexOptions } from 'apexcharts'

const BarShipmentCount = () => {
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'))
  const { palette } = useTheme()

  const dataLineApexChart = [
    { text: 'LUC-Luc Hackman', color: '#ECB225', textPorcent: 65.12, porcent: 70 },
    { text: 'KIND-Kindred Products', color: '#3B33EE', textPorcent: 12, porcent: 60 },
    { text: 'Hug- Hug and Grace', color: '#589FF9', textPorcent: 132, porcent: 90 },
    { text: 'MOM-Mommys Bliss', color: '#DD3423', textPorcent: 100, porcent: 60 }
  ]

  const options: ApexOptions = {
    chart: {
      stacked: true,
      type: 'bar',
      background: 'transparent',
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '20%',
        colors: {
          backgroundBarColors: ['#12121220']
        },
        borderRadius: 3
      }
    },
    colors: ['#fff'],
    stroke: {
      width: 0
    },
    title: {
      floating: true,
      offsetX: -10,
      offsetY: 5,
      text: '',
      style: {
        fontWeight: '400',
        fontSize: '18px'
      }
    },
    subtitle: {
      floating: true,
      align: 'right',
      offsetY: 0,
      text: '74%',
      style: {
        fontSize: '15px'
      }
    },
    tooltip: {
      enabled: false
    },
    theme: { mode: palette.mode === 'dark' ? 'dark' : 'light' }
  }

  return (
    <>
      {dataLineApexChart.map((line, i) => (
        <ReactApexcharts
          key={i}
          options={{
            ...options,
            colors: [line.color],
            title: { text: line.text }
          }}
          series={[{ name: line.text, data: [line.porcent] }]}
          type='bar'
          width={mobile ? '100%' : 320}
          height={50}
        />
      ))}
    </>
  )
}

export default BarShipmentCount

import { getAsync, postAsync, postBlobAsync } from 'src/@http'
import {
  CustomerInvoiceDto,
  CustomerInvoiceFilters,
  CustomerInvoiceResumeBatchModel,
  CustomerInvoiceResumeCarrierDto,
  CustomerInvoiceResumeDto,
  CustomerInvoiceResumePeriodDto
} from 'src/types/models/CustomerInvoiceModel'

import { Pagination } from '../user'

/* Endpoints definition */
const vApi = '/v1.0/'
const controlCustomerInvoice = `${vApi}ControllerCustomerInvoice`
const controlCustomerBatchInvoice = `${vApi}ControllerCustomerBatchInvoice`

export const postTotalsCustomerInvoice = (
  CustomerInvoiceFilters: CustomerInvoiceFilters
): Promise<CustomerInvoiceResumeDto> => {
  return postAsync(`${controlCustomerInvoice}/Totals`, CustomerInvoiceFilters)
}

export const postTotalsByCarrier = (
  CustomerInvoiceFilters: CustomerInvoiceFilters
): Promise<CustomerInvoiceResumeCarrierDto[]> => {
  return postAsync(`${controlCustomerInvoice}/TotalByCarrier`, CustomerInvoiceFilters)
}

export const postTotalsByBatch = (
  CustomerInvoiceFilters: CustomerInvoiceFilters
): Promise<CustomerInvoiceResumeBatchModel[]> => {
  return postAsync(`${controlCustomerInvoice}/TotalByBatch`, CustomerInvoiceFilters)
}

  export const postTotalsByPeriodsCustomerInvoice = (CustomerInvoiceFilters: CustomerInvoiceFilters): Promise<CustomerInvoiceResumePeriodDto[]> => {
    return postAsync(`${controlCustomerInvoice}/TotalByPeriods`,CustomerInvoiceFilters)
  } 
  
  export const postProcessCustomerInvoice = (CustomerInvoice: CustomerInvoiceDto[]): Promise<CustomerInvoiceResumePeriodDto[]> => {
    return postAsync(`${controlCustomerInvoice}/ProcessCustomerInvoice`,{ customerInvoiceList:CustomerInvoice})
  } 

// export const postProcessCustomerInvoice = (
//   CustomerInvoice: CustomerInvoiceDto[]
// ): Promise<CustomerInvoiceResumePeriodDto[]> => {
//   return postAsync(`${controlCustomerInvoice}/ProcessCustomerInvoice`, { CustomerInvoice })
// }

export const postCustomerInvoice = (CustomerInvoiceFilters: CustomerInvoiceFilters): Promise<any> => {
  return postAsync(`${controlCustomerInvoice}`, CustomerInvoiceFilters)
}

export const getEnableCustomerInvoice = (customerInvoiceId: number): Promise<boolean> => {
  return getAsync(`${controlCustomerInvoice}/Enable?customerInvoiceId=${customerInvoiceId}`)
}

export const getBachCustomer = (): Promise<boolean> => {
  return getAsync(`${controlCustomerBatchInvoice}?Status=1`)
}

export const getCancelCustomerInvoice = (customerInvoiceId: number): Promise<boolean> => {
  return getAsync(`${controlCustomerInvoice}/Cancel?customerInvoiceId=${customerInvoiceId}`)
}

type CustomerInvoicePaginateParams = {
  customerFilters: CustomerInvoiceFilters
  PageSize: number
  CurrentPage: number
}
export const postCustomerInvoicePaginate = ({
  customerFilters,
  PageSize = 10,
  CurrentPage = 1
}: CustomerInvoicePaginateParams): Promise<{ list: CustomerInvoiceDto[]; pagination: Pagination }> => {
  return postAsync(
    `${controlCustomerInvoice}/Paginate?PageSize=${PageSize}&CurrentPage=${CurrentPage}`,
    customerFilters
  )
}

export const postCustomerInvoiceExport = (CustomerInvoiceFilters: CustomerInvoiceFilters): Promise<any> => {
  return postBlobAsync(`${controlCustomerInvoice}/Export`, CustomerInvoiceFilters)
}

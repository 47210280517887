/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useNavigate } from 'react-router-dom'
import { Button, Card, CircularProgress, Dialog, DialogTitle, List, ListItem, Stack, Typography } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { logger } from 'src/utils/Logger'
import { match, P } from 'ts-pattern'

interface Printer {
  name: string
}

interface PrinterSelectModalProps {
  open: boolean
  onClose: () => void
  file: File
  isZebra: boolean
}

const settings = JSON.parse(sessionStorage.getItem('print_settings') || '{}')
logger.log('settings', settings)

const endpoint = settings.print_service_url

const headers = {
  headers: {
    Authorization: `Bearer ${settings?.print_token}`,
    "Bypass-Tunnel-Reminder": true,
  }
}

const fetchPrinters = async () => {
  const response = await axios.get<{
    genericPrinters: Printer[]
    zebraPrinters: Printer[]
  }>(`${endpoint}/api/printers`, {
    ...headers, timeout: 7000
  }
  )

  return response.data  
}

const setPrint = async ({ printer, file, isZebra }: { printer: string; file: File; isZebra: boolean }) => {
  const formData = new FormData()
  formData.append('file', file)
  const resp = await axios.post<{ success: boolean }>(`${endpoint}/api/printers/${printer}/print-file`, formData, {
    ...headers,
    params: {
      ...(isZebra ? { type: 'zebra' } : {})
    }
  })

  return resp.data
}

const PrinterSelectModal: React.FC<PrinterSelectModalProps> = ({ open, onClose, file, isZebra }) => {
  const getPrinters = useQuery({
    queryKey: ['printers'],
    queryFn: fetchPrinters,
    retry: 2,
  })

  const navigate = useNavigate()

  const printFile = useMutation({
    mutationFn: (printer: string) =>
      setPrint({
        printer,
        file,
        isZebra
      }),
    onSuccess: () => {
      onClose()
    }
  })

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Select a Printer</DialogTitle>
      <Card>
        {match(getPrinters)
          .with({ isLoading: true }, () => (
            <Stack alignItems="center" padding={2}>
              <CircularProgress />
              <Typography variant="body1">Loading...</Typography>
            </Stack>
          ))
          .with({ isError: true }, () => (
              <Stack alignItems="center" padding={2}>
                <Typography variant="body1">Error Loading Printers</Typography>
                <Typography variant="body2">Make sure the print service is configured correctly</Typography>
                <Button onClick={() => navigate("/profile")}>
                  Go to Profile
                </Button>
              </Stack>
          ))
          .with({ data: { genericPrinters: [], zebraPrinters: [] } }, () => (
            <Stack alignItems="center" padding={2}>
              <Typography variant="body1">There's no available printers</Typography>
            </Stack>
          ))
          .with({
            data: P.when((value): boolean => (value?.genericPrinters || []).length > 0 || (value?.zebraPrinters || []).length > 0)
          }, ({ data }) => (
            <>
              {isZebra ? (
                <Stack spacing={2} padding={2}>
                  <List>
                    {data?.zebraPrinters?.map((printer, index) => (
                      <ListItem button key={index} onClick={() => printFile.mutate(printer.name)}>
                        {printer.name}
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              ) : (
                <Stack spacing={2} padding={2}>
                  <List>
                    {data?.genericPrinters?.map((printer, index) => (
                      <ListItem button key={index} onClick={() => printFile.mutate(printer.name)}>
                        {printer.name}
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              )}
            </>
          ))
          .otherwise(() => (
            <Stack alignItems="center" padding={2}>
              <Typography variant="body1">Something went wrong</Typography>
            </Stack>
          ))}
      </Card>
    </Dialog>
  )
}

export default PrinterSelectModal

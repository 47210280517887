import { useTranslation } from 'react-i18next'
import { Card, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import FiltersByPeriods from '../components/dashboard/FiltersByPeriods'
import AmchartMap from '../components/dashboard/widgets/AmchartMap'
import AvgTrendPacks from '../components/dashboard/widgets/AvgTrendPacks'
import ServiceOverviewChart from '../components/dashboard/widgets/ServiceOverview'
import ShipmentAccesorialSummary from '../components/dashboard/widgets/ShipmentAccesorialSummary'
import SpendAmountByYearAndMonth from '../components/dashboard/widgets/SpendAmountByYearAndMonth'

const NewDashboard = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography variant='h5'>{t('SPEND_TREND_SUMMARY')}</Typography>
      </Grid>

      <Grid item xs={8}>
        <FiltersByPeriods />
      </Grid>
      <Grid item xs={12} md={6}>
        <SpendAmountByYearAndMonth />
      </Grid>

      <Grid item xs={12} md={6}>
        <ShipmentAccesorialSummary />
      </Grid>

      <Grid sx={{ maxHeight: 400 }} item xs={12} md={6}>
        <ServiceOverviewChart />
      </Grid>
      <Grid item xs={12} md={6}>
        <AvgTrendPacks />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <Grid container justifyContent='space-between' sx={{ p: 4 }}>
            <Grid item>
              <Typography variant='h6'>{t('SHIPMENT_MAP')}</Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>

                <Select label='Status' defaultValue='Active'>
                  <MenuItem value={'Active'}>Active</MenuItem>
                  <MenuItem value={'Pending'}>Pending</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <AmchartMap color={theme?.palette?.customColors?.primaryGradient} />
        </Card>
      </Grid>
    </Grid>
  )
}

export default NewDashboard

import jwt_decode from 'jwt-decode'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { getUserType, UserType } from 'src/@http/user'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type State = {
  refreshUserGridDate: Date
  dataUserLogged: any
  listUserTypes: UserType[]
}

type Actions = {
  reset: () => void
  setRefreshUserGridDate: () => void
  setDataUserLogged: () => void
  setUserTypes: () => void
}

const initialState: State = {
  refreshUserGridDate: new Date(),
  dataUserLogged: null,
  listUserTypes: []
}

const userStore = create<State & Actions>()(
  persist(
    (set) => ({
      ...initialState,
      setRefreshUserGridDate: () => {
        set({ refreshUserGridDate: new Date() })
      },
      setDataUserLogged: () => {
        const token = window.localStorage.getItem('token')
        const decodedToken: any = token && jwt_decode(token)
        if (decodedToken) {
          set({
            dataUserLogged: {
              idUser: decodedToken.IdUsuario,
              idParent: decodedToken.IdParent
            }
          })
        }
      },
      setUserTypes: async () => {
        try {
          set({ listUserTypes: await getUserType() })
        } catch (error) {
          console.log(error)
        }
      },
      reset: () => {
        set(initialState)
      }
    }),
    {
      name: 'user-store'
    }
  )
)

if (process.env.NODE_ENV === 'development') mountStoreDevtool('user-store', userStore)

export default userStore

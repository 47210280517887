import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { getServicesForCarrierByShop } from 'src/@http/carriers';
import appStore from 'src/zustand/app';

import { Option } from './types';

interface SelectorServicesProps {
  carrierId: number | null;
  servicesSelected?: Option;
  title: string;
  onChange: (option: Option | null) => void;
  value: number | null;
  sx?: object;  // Asumiendo que deseas pasar estilos directamente al FormControl
}

const SelectorServices: React.FC<SelectorServicesProps> = ({ carrierId, servicesSelected, title, onChange, value, sx }) => {
  const { CustomerId, ShopId } = appStore().paramsV2;
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    console.log('SelectorServices: ', CustomerId);
  }, [CustomerId]);

  useEffect(() => {
    const fetchServices = async () => {
      if (carrierId && ShopId) {
        const services = await getServicesForCarrierByShop({
          intCarrierId: carrierId,
          status: 'Enabled',
          intShopId: ShopId
        });
        setOptions(services.map(service => ({ value: service.intServiceId, label: service.vchName })));
      }
    };
    if (carrierId) {
      fetchServices();
    }
  }, [carrierId, ShopId]);

  return (
    <FormControl fullWidth sx={sx}>
      <Autocomplete
        value={options.find(option => option.value === value) || null}
        onChange={(event, newValue) => onChange(newValue)}
        options={options}
        id='autocomplete-service'
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} label={title} />}
        renderOption={(props, option) => <li {...props}>{option.label}</li>}
      />
    </FormControl>
  );  
};

export default SelectorServices;
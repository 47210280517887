const round = (num: number, decimales = 2): number => {
  const signo: number = num >= 0 ? 1 : -1
  num = num * signo

  if (decimales === 0) {
    return signo * Math.round(num)
  }

  let numStr: string[] = num.toString().split('e')
  num = Math.round(+(numStr[0] + 'e' + (numStr[1] ? +numStr[1] + decimales : decimales)))

  numStr = num.toString().split('e')

  return signo * +(numStr[0] + 'e' + (numStr[1] ? +numStr[1] - decimales : -decimales))
}

export { round }

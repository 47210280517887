import { redirect } from 'react-router-dom'
import axios, { AxiosResponse } from 'axios'
import { getCurrentRoute } from 'src/utils/misc'
import appStore from 'src/zustand/app'
import companiesStore from 'src/zustand/companies'
import customerStore from 'src/zustand/customer'

let axiosInstanceHeaders: any = {}

/*  TODO: Tomar el auth token, por ejemplo del localStorage. */
// console.log(window.localStorage.get('authTokenStorage'))
// const authToken = window.localStorage.getItem('token')
const authToken: string | null = window.localStorage.getItem('token')

if (authToken != null) {
  axiosInstanceHeaders = {
    common: { Authorization: 'Bearer ' + authToken },
    'Content-Type': 'application/json'
  }
}

const apiProtocol = process.env.REACT_APP_API_PROTOCOL
const apiDomain = process.env.REACT_APP_API_DOMAIN
const svcApiDomain = process.env.REACT_APP_SVC_API_DOMAIN
const apiTimeOut = 40000

export const axiosInstance = axios.create({
  baseURL: `${apiProtocol}://${apiDomain}/`,
  timeout: apiTimeOut,
  headers: axiosInstanceHeaders
})

export const axiosInstanceShippForm = axios.create({
  baseURL: `${apiProtocol}://${svcApiDomain}/`,
  timeout: apiTimeOut,
  headers: axiosInstanceHeaders
})

const catchAxiosError = (error: any) => {
  if (error.response) {
    const { status } = error.response

    if (status === 401) {
      goLogin()
      window.localStorage.removeItem('token')
    }
  }

  console.log('Handler error axios', error)

  return Promise.reject(error.response?.data)
}

const goLogin = () => {
  redirect('/login')
  // toast.error('Not authorized' + '🙄', { duration: 4000 })
}

//Esto, hay que cambiarlo. Lo puse asi para que no rompa el resto, pero hay que unificar que todas las cosas
//del back, devuelvan una GenericResponse
const mapResponseBody = (response: AxiosResponse) =>
  response.data.hasOwnProperty('data') ? response.data.data : response.data

export const getAsync = async <T>(url: string/* , params?: any */): Promise<T> => {
  const locationPathname = window.location.pathname
  const paramsToSend = appStore.getState().paramsV2

  if (getCurrentRoute(locationPathname) === 'ADMIN') {
    paramsToSend.CustomerId = 0
    paramsToSend.ShopId = 0
  } else if (getCurrentRoute(locationPathname) === 'CUSTOMER') {
    paramsToSend.CustomerId = companiesStore.getState().companyGlobal?.id || 0
    paramsToSend.ShopId = 0
  } else if (getCurrentRoute(locationPathname) === 'CLIENT') {
    paramsToSend.CustomerId = companiesStore.getState().companyGlobal?.id || 0
    paramsToSend.ShopId = customerStore.getState().shopGlobal?.intShopId || 0
  }

  return axiosInstance.get<T>(url, { params: paramsToSend }).then(mapResponseBody).catch(catchAxiosError)
}

export const getAsyncRaw = async <T, P = {}>(url: string, params?: P): Promise<AxiosResponse<T>> => {
  const locationPathname = window.location.pathname
  const paramsToSend = appStore.getState().paramsV2

  if (getCurrentRoute(locationPathname) === 'ADMIN') {
    paramsToSend.CustomerId = 0
    paramsToSend.ShopId = 0
  } else if (getCurrentRoute(locationPathname) === 'CUSTOMER') {
    paramsToSend.CustomerId = companiesStore.getState().companyGlobal?.id || 0
    paramsToSend.ShopId = 0
  } else if (getCurrentRoute(locationPathname) === 'CLIENT') {
    paramsToSend.CustomerId = companiesStore.getState().companyGlobal?.id || 0
    paramsToSend.ShopId = customerStore.getState().shopGlobal?.intShopId || 0
  }

  return axiosInstance.get<T>(url, { params: { ...paramsToSend, ...params } }).catch(catchAxiosError)
}

export const getBlobAsync = async (url: string) =>
  axiosInstance
    .get(url, { responseType: 'blob', params: appStore.getState().paramsV2 })
    .then(mapResponseBody)
    .catch(catchAxiosError)

export const postBlobAsync = async (url: string, body: {}) => {
  try {
    const response = await axiosInstance.post(url, body, { responseType: 'blob', params: appStore.getState().paramsV2 })
    const blobData = new Blob([response.data], { type: response.headers['content-type'] })

    return blobData
  } catch (error) {
    console.error('Error en postBlobAsync:', error)

    return null
  }
}

export const getWithQueryParamsAsync = async <T>(url: string, params: {}): Promise<T> =>
  axiosInstance
    .get<T>(url, { params: { ...params, ...appStore.getState().paramsV2 } })
    .then(mapResponseBody)
    .catch(catchAxiosError)

export const postFormAsync = async (url: string, body: {}) => {
  try {
    axiosInstance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    const response = await axiosInstance.post(url, body, { params: appStore.getState().paramsV2 })

    axiosInstance.defaults.headers['Content-Type'] = 'application/json'
    console.log('Res en index Form async', response)
    const data = mapResponseBody(response)

    return data
  } catch (error: any) {
    const data = catchAxiosError(error)

    return data
  }
}

export const postAsync = async <T>(url: string, body: {}): Promise<T> => {
  const paramsToSend = appStore.getState().paramsV2

  return axiosInstance.post<T>(url, body, { params: paramsToSend }).then(mapResponseBody).catch(catchAxiosError)
}

export const putAsync = async <T>(url: string, body: {}): Promise<T> => {
  const paramsToSend = appStore.getState().paramsV2

  return axiosInstance.put<T>(url, body, { params: paramsToSend }).then(mapResponseBody).catch(catchAxiosError)
}

export const deleteAsync = async <T>(url: string): Promise<T> => {
  const paramsToSend = appStore.getState().paramsV2

  return axiosInstance.delete<T>(url, { params: paramsToSend }).then(mapResponseBody).catch(catchAxiosError)
}

const paramsToSendAdmin = { CustomerId: 0, ShopId: 0 }

export const getAsyncAdmin = async <T>(url: string): Promise<T> => {
  return axiosInstance.get<T>(url, { params: paramsToSendAdmin }).then(mapResponseBody).catch(catchAxiosError)
}

export const postAsyncAdmin = async (url: string, body: {}) => {
  return axiosInstance.post(url, body, { params: paramsToSendAdmin }).then(mapResponseBody).catch(catchAxiosError)
}

export const putAsyncAdmin = async (url: string, body: {}) => {
  return axiosInstance.put(url, body, { params: paramsToSendAdmin }).then(mapResponseBody).catch(catchAxiosError)
}

export const deleteAsyncAdmin = async (url: string) => {
  return axiosInstance.delete(url, { params: paramsToSendAdmin }).then(mapResponseBody).catch(catchAxiosError)
}

export const postAsyncShippForm = async (url: string, body: {}) => {
  return axiosInstanceShippForm.post(url, body).then(mapResponseBody).catch(catchAxiosError)
}

import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Card, CardContent, CardMedia, Grid, SxProps, Theme, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { ShopIntegrationGet } from 'src/@http/shopIntegrations'
import { IntegrationDataObj } from 'src/types/models/ShopModels'
import customerStore from 'src/zustand/customer'

import { DialogExpose } from '../generic/DialogGeneric'
import GenericButton from '../generic/GenericButton'

import ModalConnectionFIntegrations from './ModalConnectionFIntegrations'
import ModalHandleSPlatfIntegration from './ModalHandleSPlatfIntegration'

interface ShopPlatfIntegrationsProps {
  onSavePlatform: (shopIntegrationForm: IntegrationDataObj[]) => void
  shopsSelected?: IntegrationDataObj[]
  viewMode?: string
  style?: SxProps<Theme>
}

interface CustomShops extends ShopIntegrationGet {
  index?: number // To differentiate each card at the front level
}

const ShopPlatformIntegration = (props: ShopPlatfIntegrationsProps) => {
  const { style, shopsSelected, viewMode, onSavePlatform } = props
  const { t } = useTranslation()
  const shopPlatformSelect = useRef<DialogExpose>(null)
  const [shopIntegrations, setShopIntegrations] = useState<CustomShops[] | null>(null)
  const { shopPlatformsIntegration } = customerStore()

  // Dialogs
  const handleShopPlatform = () => shopPlatformSelect.current?.open()
  const [openModalConfig, setOpenModalConfig] = useState<{ open: boolean, selectedShop: null | CustomShops, mode?: string }>({ open: false, selectedShop: null })

  useEffect(() => {
    let hasRun = false;
    if (!hasRun && shopsSelected) {
      const shops: CustomShops[] = shopsSelected.map((shop: any, index) => {
        const extraData = shopPlatformsIntegration.find((sh: any) => sh.id === shop.intTradingPlatformId)

        return {
          index,
          vchJsonFormat: shop.vchConnection || "",
          description: extraData?.description || "",
          image: extraData?.image || "",
          id: shop.intTradingPlatformId,
          isActive: shop.isActive,
          lastModifiedDate: shop.lastModifiedDate,
        }
      })
      if (shops) {
        setShopIntegrations(shops)
        hasRun = true
      }
    }
  }, [shopsSelected])

  return (
    <Box sx={style ? style : {}}>
      <Typography variant='h5' px={5}>
        {t('SHOP_INTEGRATIONS')}
      </Typography>

      <Card sx={{ my: 3 }}>
        <Box display='flex' mr={4} mt={2} justifyContent='flex-end'>
          <GenericButton disabled={viewMode === 'view'} onClick={handleShopPlatform}>Add integrations</GenericButton>
        </Box>
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: !shopIntegrations ? 'flex-start' : 'space-between',
            paddingTop: 0
          }}
        >
          {shopIntegrations === null || shopIntegrations.length === 0 ? (
            <Card sx={styleCard}>
              <Typography variant='subtitle1' mb={2}>
                {t('NOTHING_HAS_BEEN_SELECTED')}
              </Typography>
              <Button variant='outlined' color='secondary' onClick={handleShopPlatform}>
                {t('SELECT')}
              </Button>
            </Card>
          ) : (
            shopIntegrations.map((shop: CustomShops, i: number) => (
              <Card key={i} sx={styleCard}>
                <Grid container>
                  <Grid item xs={12} md={8} display='flex' flexDirection='column' justifyContent='center'>
                    <Typography variant='subtitle1' mb={2} textAlign='center'>
                      {shop.description || ''}
                    </Typography>
                    <CardMedia
                      image={shop.image}
                      component='img'
                      sx={{ width: { xs: 260, sm: 300 }, height: { xs: 60, sm: 120 }, mb: 4, objectFit: 'contain', mx: 'auto' }}
                    />
                    <Box display='flex' justifyContent='center'>
                      <Button
                        variant='outlined'
                        sx={{ mt: 3, mr: 2, maxWidth: '300px' }}
                        color='secondary'
                        onClick={() => setOpenModalConfig({ open: true, selectedShop: shop, mode: 'edit' })}
                        disabled={viewMode === 'view'}
                      >
                        {t('CHANGE')}
                      </Button>
                      <Button
                        variant='outlined'
                        sx={{ mt: 3, maxWidth: '300px' }}
                        color='error'
                        onClick={() => {
                          const newList = shopIntegrations.filter(e => e.index !== shop.index)
                          setShopIntegrations(newList)
                          const listIntegrationData = mappingToSendToService(newList)
                          onSavePlatform(listIntegrationData)
                        }}
                        disabled={viewMode === 'view'}
                      >
                        {t('DELETE')}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ wordBreak: 'break-all', pr: 2 }}>
                    <ListDataConnectionIntegration jsonString={shop.vchJsonFormat} />
                  </Grid>
                </Grid>
              </Card>
            ))
          )}
        </CardContent>
      </Card>
      <ModalHandleSPlatfIntegration
        ref={shopPlatformSelect}
        handleIntegrationSelected={(integration: any) => {
          shopPlatformSelect.current?.close()
          setOpenModalConfig({ open: true, selectedShop: integration, mode: 'add' })
        }}
      />
      <ModalConnectionFIntegrations
        open={openModalConfig.open}
        setOpen={(open) => setOpenModalConfig({ open, selectedShop: null })}
        integration={openModalConfig.selectedShop}
        handleActionFormConnection={(shopConfig: IntegrationDataObj) => {
          if (openModalConfig.selectedShop) {
            const newList: CustomShops[] = shopIntegrations || []
            if (openModalConfig.mode === 'edit') {
              const indexToDelete = newList.indexOf(openModalConfig.selectedShop)
              if (indexToDelete !== -1) {
                newList.splice(indexToDelete, 1);
              }
            }
            newList.push({
              ...openModalConfig.selectedShop,
              index: (shopIntegrations?.length || 0) + 1 ,
              vchJsonFormat: shopConfig.vchConnection
            } as CustomShops)
            // State with more properties than are sent to service - Used for cards 
            setShopIntegrations(newList)

            const listIntegrationData = mappingToSendToService(newList)
            onSavePlatform(listIntegrationData)
          }
        }}
        mode={openModalConfig?.mode}
      />
    </Box>
  )
}

const mappingToSendToService = (list: ShopIntegrationGet[]): IntegrationDataObj[] => {
  return list.map((el: ShopIntegrationGet) => {
    return {
      intTradingPlatformId: el.id,
      vchConnection: el.vchJsonFormat
    }
  })
}

const styleCard = () => {
  return {
    backgroundColor: grey['300'],
    height: '250px',
    minWidth: { xs: '100%', md: '48%' },
    maxWidth: '48%',
    maxHeight: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2,
    my: 3,
    transition: 'background-color 0.5s ease',
    '&:hover': { background: grey['400'], cursor: 'pointer' }
  }
}

const ListDataConnectionIntegration = (props: any) => {
  const { jsonString } = props
  try {
    const jsonData = JSON.parse(jsonString);
    const keyValuePairs = Object.entries(jsonData).map(([key, value]) => (
      <div key={key}>
        <Typography fontSize={14} component='span'><strong>{key}:</strong><br></br>{value as any}</Typography>
      </div>
    ));

    return (
      <div>{keyValuePairs}</div>
    );
  } catch (error) {
    console.error('Error al parsear el JSON:', error);

    return (
      <div><p>-</p></div>
    );
  }
};


export default ShopPlatformIntegration

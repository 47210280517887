import { useState } from 'react'
import DatePicker from 'react-datepicker'
import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  useMediaQuery,
  useTheme
} from '@mui/material'
import DatePickerWrapper from 'src/@core/styles/libs/react-datepicker'
import ListDinamicProduct from 'src/pages/components/orderEditor/ListDinamicProduct'
import AccountInformationForm from 'src/views/forms/AccountInformationForm'

const initialForm = {
  orderId: 449415,
  orderDate: '2023-03-14',
  holdDate: new Date(),
  customerId: 'UCE',
  batch: '20230315-test',
  customerOrderId: '',
  orderType: 'Box',
  thirdPartyPO: '',
  orderTotal: '',
  eliteId: 0,
  shipMethod: '',
  shipCode: '122 - DHL GLOBAL MAIL-EXPEDITED',
  shipComplete: 'YES',
  intlShipType: 'Merchandise',
  shipSameDay: 'OFF',
  shippingAccount: '',
  warehouseCode: 'UT',
  comments: '',
  warehouseRemarks: '',
  logisticsRemarks: ''
}

const OrderEditorAddOrEdit = () => {
  const theme = useTheme()
  const mdAndDown = useMediaQuery(theme.breakpoints.down('md'))

  const [form, setForm] = useState(initialForm)
  const handleAccountInformation = (accountInformation: any) => {
    console.log('handleAccountInformation', accountInformation)
  }

  return (
    <>
      <Card sx={{ padding: '2rem 1rem' }}>
        <Grid container rowGap={5} columnGap={3}>
          <Grid item xs={12} lg={1}>
            <TextField
              fullWidth
              id='order-id'
              label='Order Id'
              value={form.orderId}
              onChange={e => setForm({ ...form, orderId: Number(e.target.value) })}
              disabled
            />
          </Grid>
          <Grid item xs={12} lg={2} sx={{ maxWidth: { xs: '100%', md: '140px' } }}>
            <TextField
              fullWidth={mdAndDown}
              id='order-date'
              label='Order Date'
              value={form.orderDate}
              onChange={e => setForm({ ...form, orderDate: e.target.value })}
              disabled
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <DatePickerWrapper sx={{ width: '100%' }}>
              <DatePicker
                selected={form.holdDate}
                id='basic-input'
                dateFormat='yyyy-MM-dd'
                onChange={(date: Date) => setForm({ ...form, holdDate: date })}
                placeholderText='Click to select a date'
                customInput={<TextField fullWidth id='hold-date' label='Hold Date' />}
              />
            </DatePickerWrapper>
          </Grid>
          <Grid item xs={12} lg={1}>
            <TextField
              fullWidth
              id='customer-id'
              label='Customer Id'
              value={form.customerId}
              onChange={e => setForm({ ...form, customerId: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <TextField
              fullWidth
              id='batch'
              label='Batch'
              value={form.batch}
              onChange={e => setForm({ ...form, batch: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <TextField
              fullWidth
              id='customer-order-id'
              label='Customer Order Id'
              value={form.customerOrderId}
              onChange={e => setForm({ ...form, customerOrderId: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <FormControl fullWidth>
              <InputLabel id='order-type-select'>Order Type</InputLabel>
              <Select
                label='Order Type'
                defaultValue=''
                value={form.orderType}
                onChange={e => setForm({ ...form, orderType: e.target.value })}
                id='order-type-select'
                labelId='order-type-select'
              >
                <MenuItem value='BOX'>BOX</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={2}>
            <TextField
              fullWidth
              id='3er-party-po'
              label='3rd Party PO'
              value={form.thirdPartyPO}
              onChange={e => setForm({ ...form, thirdPartyPO: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <TextField
              fullWidth
              id='order-total'
              label='Order Total'
              value={form.orderTotal}
              onChange={e => setForm({ ...form, orderTotal: e.target.value })}
              disabled
            />
          </Grid>
          <Grid item xs={12} lg={1}>
            <TextField
              fullWidth
              id='elite-id'
              label='Elite Id'
              value={form.eliteId}
              onChange={e => setForm({ ...form, eliteId: Number(e.target.value) || 0 })}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <TextField
              fullWidth
              id='ship-method-select'
              label='Ship Method'
              value={form.shipMethod}
              onChange={e => setForm({ ...form, shipMethod: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <FormControl fullWidth>
              <InputLabel id='ship-code-select'>Ship Code</InputLabel>
              <Select
                label='Ship Code'
                defaultValue=''
                value={form.shipCode}
                onChange={e => setForm({ ...form, shipCode: e.target.value })}
                id='ship-code-select'
                labelId='ship-code-select'
              >
                <MenuItem value='122 - DHL GLOBAL MAIL-EXPEDITED'>122 - DHL GLOBAL MAIL-EXPEDITED</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={1}>
            <FormControl fullWidth>
              <InputLabel id='ship-complete-select'>Ship Complete</InputLabel>
              <Select
                label='Ship Complete'
                value={form.shipComplete}
                onChange={e => setForm({ ...form, shipComplete: e.target.value })}
                id='ship-complete-select'
                labelId='ship-complete-select'
              >
                <MenuItem value='YES'>YES</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={2}>
            <FormControl fullWidth>
              <InputLabel id='intl-ship-type-select'>Intl Ship Type</InputLabel>
              <Select
                label='Intl Ship Type'
                value={form.intlShipType}
                onChange={e => setForm({ ...form, intlShipType: e.target.value })}
                id='intl-ship-type-select'
                labelId='intl-ship-type-select'
              >
                <MenuItem value='Merchandise'>Merchandise</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={1}>
            <FormControl fullWidth>
              <InputLabel id='ship-same-day-select'>Ship Same Day</InputLabel>
              <Select
                label='Ship Same Day'
                value={form.shipSameDay}
                onChange={e => setForm({ ...form, shipSameDay: e.target.value })}
                id='ship-same-day-select'
                labelId='ship-same-day-select'
              >
                <MenuItem value='OFF'>OFF</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={2}>
            <TextField
              fullWidth
              id='shipping-account'
              label='Shipping Account'
              value={form.shippingAccount}
              onChange={e => setForm({ ...form, shippingAccount: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <FormControl fullWidth>
              <InputLabel id='warehouse-code-select'>Warehouse Code</InputLabel>
              <Select
                label='Warehouse Code'
                value={form.warehouseCode}
                onChange={e => setForm({ ...form, warehouseCode: e.target.value })}
                id='warehouse-code-select'
                labelId='warehouse-code-select'
              >
                <MenuItem value='UT'>UT</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Card>

      <AccountInformationForm disabled={false} onChange={handleAccountInformation} />

      <Card sx={{ padding: '2rem 1rem', mt: 5, display: 'flex', flexDirection: mdAndDown ? 'column' : 'row' }}>
        <TextField
          fullWidth
          rows={4}
          multiline
          variant='filled'
          label='Comments'
          defaultValue=''
          value={form.comments}
          onChange={e => setForm({ ...form, comments: e.target.value })}
          sx={{ mx: mdAndDown ? 0 : 2, my: mdAndDown ? 2 : 0 }}
        />
        <TextField
          fullWidth
          rows={4}
          multiline
          variant='filled'
          label='Warehouse Remarks'
          defaultValue=''
          value={form.warehouseRemarks}
          onChange={e => setForm({ ...form, warehouseRemarks: e.target.value })}
          sx={{ mx: mdAndDown ? 0 : 2, my: mdAndDown ? 2 : 0 }}
        />
        <TextField
          fullWidth
          rows={4}
          multiline
          variant='filled'
          label='Logistics Remarks'
          defaultValue=''
          value={form.logisticsRemarks}
          onChange={e => setForm({ ...form, logisticsRemarks: e.target.value })}
          sx={{ mx: mdAndDown ? 0 : 2, my: mdAndDown ? 2 : 0 }}
        />
      </Card>

      <Card sx={{ padding: '2rem 1rem', mt: 5 }}>
        <ListDinamicProduct />
      </Card>

      <Stack flexDirection='row' justifyContent='flex-end' mt={3}>
        <Button variant='contained'>Save Order</Button>
      </Stack>
    </>
  )
}

export default OrderEditorAddOrEdit

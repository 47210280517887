import { ReactNode, useEffect, useRef, useState } from 'react'
import { ReactDatePickerProps } from 'react-datepicker'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Icon } from '@iconify/react'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import moment from 'moment'
import { getCarriers } from 'src/@http/carriers'
import { useCreateCarrierRate } from 'src/@http/carriers/hooks'
import { useCreateCustomerRate } from 'src/@http/customer/hooks'
// import { getCustomerById } from 'src/@http/customer/index'
import { useCreateShopRate, useVerifyCarrierRate, useVerifyCustomerRate } from 'src/@http/shop/hooks'
import CloneSheetForm from 'src/pages/components/customer/CloneSheetForm'
import { WarehouseGetModel } from 'src/types/models/CarrierModel'
import { RateSheetVersionsGetModel } from 'src/types/models/CarrierModel'
import { CarrierModel } from 'src/types/models/CarrierModel'
// import { CustomerModel } from 'src/types/models/CustomerModels'
import { ServicesCarrierModel } from 'src/types/models/ServicesCarrierModel'
import { logger } from 'src/utils/Logger'
import { getCurrentRoute, handleVisibilityAccordingKey } from 'src/utils/misc'
import SelectShipMethods from 'src/views/filters/selectors/SelectShipMethods'
import SelectWarehouses from 'src/views/filters/selectors/SelectWarehouses'
import appStore from 'src/zustand/app'
import carrierStore from 'src/zustand/carrier'
import customerStore from 'src/zustand/customer'
import serviceStore from 'src/zustand/services'
import { match, P } from 'ts-pattern'

import ModalAddAndSubtractionHandle from '../customer/src/pages/components/customer/ModalAddAndSubtractionHandle'
import { DialogExpose } from '../generic/DialogGeneric'
import UnsavedChangesModal from '../generic/UnsavedChangesModal'
import MeasureListRateSheet from '../services-editor/MeasureListRateSheet'

import RateDatePicker from './customComponents/RateDatePicker'
import RateSheetVersionsModal from './dataManagersRateTables/RateSheetVersionsModal'
import CarrierRateRadioGroup from './CarrierRateRadioGroup'

type MenuOption = {
  title: string
  value?: string
  show: boolean
  onClick?: () => void
}

export enum TypeTable {
  DISCOUNT = 'Discount',
  MARKUP = 'Markup',
  SETRATE = 'Set Rate'
}

export type FilterForm = {
  intCarrierId: number
  intServiceId: number
  id: number
  checkboxSelected: number
  warehouseId: number
}

const initialValues: FilterForm = {
  intCarrierId: 0,
  intServiceId: 0,
  id: 0,
  checkboxSelected: 0,
  warehouseId: 0
}

interface PropCarrierRateSheet {
  children?: ReactNode
  dataUser: {
    type: any
    data: any
  }
  configBtnSave?: any
  setConfigBtnSave?: (config: any) => void
}

const FilterCarrierRateSheet = (props: PropCarrierRateSheet) => {
  const { setCarrierRateSheet, setCarrierIdZustand, setServiceId } = carrierStore(state => ({
    setCarrierRateSheet: state.setCarrierRateSheet,
    setCarrierIdZustand: state.setCarrierId,
    setServiceId: state.setServiceId,
    serviceId: state.serviceId
  }))

  const {
    updateLstCarrierRateDetail,
    getRateSheetVersionsCarrierAndSheet,
    createLstCarrierRateDetail,
    getRateCourierSheetByVersionId,
    setCarrierSelected
  } = carrierStore()

  const { setMeasure } = serviceStore()
  const [modalData, setModalData] = useState<{
    code: string
    startDate: Date
    endDate: Date
    measure?: string
  }>({
    code: '',
    startDate: new Date(),
    endDate: new Date(),
    measure: '-1'
  })
  const { dataUser, children, configBtnSave } = props
  const [pageType, setTypePage] = useState<string>(dataUser.type)
  const { t } = useTranslation()
  const [firstCall, setFirstCall] = useState<boolean>(true)
  const [couriers, setCouriers] = useState<CarrierModel[]>([])
  const [newVersion, setNewVersion] = useState<boolean>(false)
  const [addRestDialog, setAddRestDialog] = useState<boolean>(false)
  const [cloneSheetDialog, setCloneSheetDialog] = useState<boolean>(false)
  const [form, setForm] = useState<FilterForm>(initialValues)
  const [disabledActionButton, setDisabledActionButton] = useState(true)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showBtnGoToServiceEditor, setShowBtnGoToServiceEditor] = useState(false)
  const [carrierId, setCarrierId] = useState(0)
  const [warehouseId, setWarehouseId] = useState<number>(6)
  const [isSaveAsCreatingNewSheet, setIsSaveAsCreatingNewSheet] = useState<boolean>(false)
  const [useBaseRateSheet, setUseBaseRateSheet] = useState<boolean>(false)
  const [infoFiltersSelected, setInfoFiltersSelected] = useState({
    nameShipMethod: null,
    nameWarehouse: null
  })
  const [createModalCache, setCreateModalCache] = useState<{
    code: string
    startDate: Date
    endDate: Date
    measure?: string
    versionSelected?: number
    serviceId: number
    carrierId?: number
    shopId?: number
    customerId?: number
  } | null>(null)

  const [infoServiceSelected, setInfoServiceSelected] = useState<ServicesCarrierModel | null>(null) // Object to send to service editor

  const {
    setLoading,
    setTypeTableRateSheet,
    typeTableRateSheet,
    loading,
    setHeaderBaseRateDetails,
    headerBaseRateDetails,
    listRateSheetVersion,
    versionSelected,
    setVersionSelected,
    setShowBtnSave,
    showBtnSave,
    clearTable,
    hideBtnSave,
    clearListVersions,
    headers,
    typeTableValue,
    isSaveAsModalOpen,
    setIsSaveAsModalOpen,
    setIsRenderTableError
  } = appStore()

  const {
    getRateSheetVersionsShopAndSheet,
    createLstShopRateDetail,
    updateLstShopRateDetail,
    getRateShopSheetByVersionId,
    showBtnAddSubstract,
    setShowBtnAddSubstract,
    updateLstCustomerRateDetail,
    getRateCustomerSheetByVersionId,
    createLstCustomerRateDetail,
    getRateSheetVersionsCustomerAndSheet,
    getRateSheetVersionsCustomerCouriersAndSheet
  } = customerStore()

  const params = useParams()
  const isCustomerCourierRateSheet = dataUser.data === null
  const navigate = useNavigate()

  // const [customer, setCustomer] = useState<CustomerModel>()

  // useEffect(() => {
  //   const fetch = async () => setCustomer(await getCustomerById(dataUser.data.intCustomerId))
  //   fetch()
  //   console.log("customerData: ", customer)
  // }, [])

  useEffect(() => {
    getCarriers()
      .then(res => {
        const isCustomerOrShop = pageType === 'customer' || pageType === 'shop'
        if (isCustomerOrShop || dataUser.data === null) {
          const courierId = res.list[0].id
          setCarrierId(courierId)
          setCarrierSelected(res.list[0])
          setForm({ ...form, intCarrierId: Number(courierId) })
        }
        setCouriers(res.list)
      })
      .catch(e => toast(e))
  }, [])

  useEffect(() => {
    setMeasure('-1')
  }, [])
  useEffect(() => {
    setServiceId(0)
    clearTable()
    setShowBtnAddSubstract(false)
  }, [])

  useEffect(() => {
    if (headerBaseRateDetails.id === 0) setNewVersion(true)
  }, [headerBaseRateDetails])

  useEffect(() => {
    hideBtnSave()
    setVersionSelected('0.00')
    clearListVersions()
  }, [])

  useEffect(() => {
    if (firstCall && form.intServiceId !== 0 && form.intCarrierId !== 0 && warehouseId !== 0) {
      onSubmit()
      setFirstCall(false)
    }
  }, [form.intServiceId, warehouseId])

  useEffect(() => {
    setDisabledActionButton(true)
    const { courierId } = params
    if (courierId) {
      setCarrierId(Number(courierId))
      setCarrierIdZustand(Number(courierId))
    }
  }, [])

  const getVerifiedCustomerQuery = useVerifyCustomerRate(
    {
      start: moment(modalData.startDate).format('YYYY-MM-DD'),
      end: moment(modalData.endDate).format('YYYY-MM-DD'),
      carrierId: form.intCarrierId,
      customerId: dataUser?.data?.intCustomerId,
      serviceId: form.intServiceId,
      shop: dataUser?.data?.intShopId
    },
    {
      enabled: isSaveAsCreatingNewSheet && ['customer', 'shop'].includes(pageType)
    }
  )

  const getVerifiedCarrierQuery = useVerifyCarrierRate(
    {
      start: moment(modalData.startDate).format('YYYY-MM-DD'),
      end: moment(modalData.endDate).format('YYYY-MM-DD'),
      carrierId: form.intCarrierId,
      serviceId: form.intServiceId
    },
    {
      enabled: isSaveAsCreatingNewSheet && !['customer', 'shop'].includes(pageType)
    }
  )

  const getCreatedShopRateMutation = useCreateShopRate()
  const getCreatedCustomerRateMutation = useCreateCustomerRate()
  const getCreatedCarrierRateMutation = useCreateCarrierRate()

  const modalRefs: Record<string, React.RefObject<DialogExpose>> = {
    rateSheetVersions: useRef<DialogExpose>(null),
    unsavedChanges: useRef<DialogExpose>(null)
  }

  // Theme
  const theme = useTheme()
  const mode = theme.palette.mode
  const { direction } = theme
  const popperPlacement: ReactDatePickerProps['popperPlacement'] = direction === 'ltr' ? 'bottom-start' : 'bottom-end'
  const isCustomerLevel = getCurrentRoute(location.pathname) === 'CUSTOMER'
  const filtersListRSCourier: MenuOption[] = [
    // {
    //   title: 'Add New Sheet',
    //   value: 'addNewSheet',
    //   show: isCustomerLevel ? handleVisibilityAccordingKey('add_new_sheet_cust') : true
    // },
    { title: 'Export PDF', value: '', show: isCustomerLevel ? handleVisibilityAccordingKey('export_pdf_cust') : true },
    {
      title: 'Export Excel',
      value: '',
      show: isCustomerLevel ? handleVisibilityAccordingKey('export_excel_cust') : true
    },
    {
      title: 'Recalculate',
      value: '',
      show: isCustomerLevel ? handleVisibilityAccordingKey('recalculate_cust') : true
    },
    { title: 'Validate', value: '', show: isCustomerLevel ? handleVisibilityAccordingKey('validate_cust') : true },
    {
      title: 'Clone Sheet',
      value: '',
      show: isCustomerLevel ? handleVisibilityAccordingKey('clone_sheet_cust') : true
    },
    {
      title: 'Services editor',
      value: 'services',
      show: isCustomerLevel ? handleVisibilityAccordingKey('services_editor_cust') : true
    },
    {
      title: 'New Rate Sheet',
      value: 'newRateSheet',
      show: isCustomerLevel ? handleVisibilityAccordingKey('new_rate_sheet_cust') : true,
      onClick() {
        setIsSaveAsModalOpen(true)
        setIsSaveAsCreatingNewSheet(true)
      }
    }
  ]

  const filtersListRSCustomer: MenuOption[] = [
    // { title: 'Add New Sheet', value: 'addNewSheet', show: true },
    {
      title: 'New Rate Sheet',
      value: 'newRateSheet',
      show: true,
      onClick: () => {
        setIsSaveAsModalOpen(true)
        setIsSaveAsCreatingNewSheet(true)
      }
    },
    {
      title: 'Add or subtract percent to all + / -',
      value: 'addSubstract',
      show: ['Markup', 'Discount'].includes(typeTableRateSheet)
    },
    { title: 'Export PDF', value: '', show: true },
    { title: 'Export Excel', value: '', show: true },
    { title: 'Recalculate', value: '', show: true },
    { title: 'Validate', value: '', show: true },
    { title: 'Clone sheet', value: 'cloneSheet', show: true }
  ]

  const filtersListIndividualRS: MenuOption[] = [
    { title: 'Save Sheet', show: true },
    { title: 'Reset Sheet', show: isCustomerLevel ? handleVisibilityAccordingKey('reset_sheet_cust') : true },
    { title: 'Export to CSV', show: isCustomerLevel ? handleVisibilityAccordingKey('export_csv_cust') : true },
    { title: 'Export Markup to CSV', show: true },
    { title: 'Export to PDF', show: isCustomerLevel ? handleVisibilityAccordingKey('export_pdf_option_two') : true }
  ]

  const openDialog = (modal: string) => {
    const modalRef = modalRefs[modal]
    if (modal === 'saveAsModal') {
      setIsSaveAsModalOpen(true)
    } else {
      modalRef?.current?.open()
    }
  }
  const filterListRS: MenuOption[] =
    pageType === 'shop' || pageType === 'customer' ? filtersListRSCustomer : filtersListRSCourier

  const handleCourier = (e: any) => {
    const courier = couriers.find(courier => courier.id === e.target.value) || null
    if (courier) setCarrierSelected(courier)
    setCarrierId(e.target.value)
    setForm({ ...form, intCarrierId: Number(e.target.value) })
  }

  const handleOpenSaveAsModal = () => {
    setIsSaveAsModalOpen(true)
  }

  const handleCloseSaveAsModal = () => {
    setIsSaveAsModalOpen(false)
    setIsSaveAsCreatingNewSheet(false)
  }

  const handleVersionSelected = (version: RateSheetVersionsGetModel) => {
    if (pageType === 'shop') {
      getRateShopSheetByVersionId(version.bigShopRateId)
    } else if (pageType === 'customer') {
      getRateCustomerSheetByVersionId(version.bigCustomerRateId)
    } else {
      getRateCourierSheetByVersionId(version.bigCarrierRateId)
    }

    setVersionSelected(version.vchNombreHoja ? version.vchNombreHoja : '0.00')
  }

  const changeValuesSelectors = (selector: string, value: any, prop: string) => {
    if (selector === 'shipMethods') {
      setInfoFiltersSelected({ ...infoFiltersSelected, nameShipMethod: value.vchName })
      // initialValues.intServiceId = form.intServiceId
      setInfoServiceSelected(value)
      setDisabledActionButton(false)
      setServiceId(value[prop])
    } else {
      setInfoFiltersSelected({ ...infoFiltersSelected, nameWarehouse: value.description })
      // initialValues.id = form.id
    }

    setForm({ ...form, [prop]: value[prop] })
  }

  const warehouseChange = (warehouse: WarehouseGetModel) => {
    setWarehouseId(warehouse.id)
    // @ts-ignore
    // navigate(`?warehouseId=${warehouse.id}`)
    console.log('WarehouseId :', warehouse.id)

    // setForm({...form, warehouseId :warehouse.id}  )
  }

  const setOptionMenu = (menu: string) => {
    if (menu === 'services') {
      let courier
      if (!dataUser.data) {
        courier = couriers.find(courier => courier.intCarrierId === carrierId) || null
      } else {
        courier = dataUser.data
      }
      navigate(`/carriers/services-editor/edit`, {
        state: {
          data: courier,
          intServiceId: form.intServiceId,
          dataService: infoServiceSelected,
          from: 'ratesSheet'
        }
      })
    }
    if (menu === 'addSubstract') {
      setAddRestDialog(true)
    }
    logger.log('Menu:', menu)
    if (menu === 'cloneSheet') {
      setCloneSheetDialog(true)
    }
    if (menu === 'addNewSheet') {
      setNewVersion(true)
      openDialog('rateSheetVersions')
    }

    setAnchorEl(null)
  }

  const saveChangesTable = () => {
    if (pageType === 'shop') {
      updateLstShopRateDetail({
        warehouseId: warehouseId,
        serviceId: form.intServiceId,
        carrierId: form.intCarrierId,
        customerId: dataUser.data.intShopId,
        typeTableRateSheet: typeTableValue.indexOf(typeTableRateSheet) + 1
      })
    } else if (pageType === 'customer') {
      updateLstCustomerRateDetail({
        warehouseId: warehouseId,
        serviceId: form.intServiceId,
        carrierId: form.intCarrierId,
        customerId: dataUser.data.intCustomerId,
        typeTableRateSheet: typeTableValue.indexOf(typeTableRateSheet) + 1
      })
    } else {
      updateLstCarrierRateDetail()
    }

    setNewVersion(false)
  }

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setModalData({ ...modalData, code: newValue })
    setCarrierRateSheet(newValue)
  }

  const createRateSheet = async () => {
    setHeaderBaseRateDetails({
      ...headerBaseRateDetails,
      vchName: createModalCache?.code || modalData.code,
      dtmStartDate:
        createModalCache?.startDate.toISOString().replace(/\d{2}:\d{2}:\d{2}\.\d{3}Z/, '00:00:00') ||
        modalData.startDate.toISOString().replace(/\d{2}:\d{2}:\d{2}\.\d{3}Z/, '00:00:00'),
      dtmEndDate:
        createModalCache?.endDate.toISOString().replace(/\d{2}:\d{2}:\d{2}\.\d{3}Z/, '00:00:00') ||
        modalData.endDate.toISOString().replace(/\d{2}:\d{2}:\d{2}\.\d{3}Z/, '00:00:00')
    })
    if (pageType === 'shop') {
      console.log('Form un shop rate detail', form)
      await createLstShopRateDetail({
        warehouseId: warehouseId,
        serviceId: form.intServiceId,
        carrierId: form.intCarrierId,
        customerId: dataUser.data.intCustomerId,
        shopId: dataUser.data.intShopId || 520, // 520 para rates de customer
        typeTableRateSheet: typeTableValue.indexOf(typeTableRateSheet) + 1
      })
    } else if (pageType === 'customer') {
      await createLstCustomerRateDetail({
        warehouseId: warehouseId,
        serviceId: form.intServiceId,
        carrierId: form.intCarrierId,
        customerId: dataUser.data.intCustomerId,
        typeTableRateSheet: typeTableValue.indexOf(typeTableRateSheet) + 1
      })
    } else {
      createLstCarrierRateDetail({ warehouseId: warehouseId })
    }
    setCreateModalCache(null)
    setIsSaveAsCreatingNewSheet(false)
    setIsSaveAsModalOpen(false)
  }

  const successCallback = (data: RateSheetVersionsGetModel) => {
    setShowBtnSave(true)
    setLoading(false)
    setUseBaseRateSheet(false)
    setIsSaveAsModalOpen(false)
    setIsSaveAsCreatingNewSheet(false)

    const formattedData = {
      ...data,
      dtmStarDate: modalData?.startDate.toISOString(),
      dtmEndDate: modalData?.endDate.toISOString(),
      vchName: modalData?.code
    }

    console.log('Formatted data', formattedData)

    setHeaderBaseRateDetails(formattedData)
  }

  const saveAsChangesTable = async () => {
    setIsRenderTableError(false)
    setLoading(true)

    if (isSaveAsCreatingNewSheet) {
      setCreateModalCache({
        code: modalData.code,
        startDate: modalData.startDate,
        endDate: modalData.endDate,
        measure: modalData.measure,
        serviceId: form.intServiceId,
        carrierId: form.intCarrierId,
        shopId: dataUser.data.intShopId || 520, // 520 para rates de customer
        customerId: dataUser.data.intCustomerId
      })

      console.log({ createModalCache })

      match(pageType)
        .with('shop', () =>
          getCreatedShopRateMutation.mutate(
            {
              serviceId: form.intServiceId,
              measureId: -1,
              shopRateId: useBaseRateSheet ? createModalCache?.versionSelected : undefined
            },
            {
              onSuccess(data) {
                console.log('Success', data)
                successCallback(data.data.data!)
              }
            }
          )
        )
        .with('customer', () =>
          getCreatedCustomerRateMutation.mutate(
            {
              serviceId: form.intServiceId,
              measureId: -1,
              customerRateId: useBaseRateSheet ? createModalCache?.versionSelected : undefined
            },
            {
              onSuccess(data) {
                console.log('Success', data)
                successCallback(data.data.data!)
              }
            }
          )
        )
        .otherwise(() =>
          getCreatedCarrierRateMutation.mutate(
            {
              serviceId: form.intServiceId,
              measureId: -1,
              carrierRateId: useBaseRateSheet ? createModalCache?.versionSelected : undefined
            },
            {
              onSuccess(data) {
                console.log('Success', data)
                successCallback(data.data.data!)
              }
            }
          )
        )

      return
    }
    createRateSheet()
    setLoading(false)
    setNewVersion(false)
  }

  const onSubmit = () => {
    appStore.setState({ isRenderTableError: false })
    const fetch = async () => {
      try {
        setServiceId(form.intServiceId)
        setLoading(true)
        console.log('Form :', form)
        if (dataUser.type === 'shop') {
          await getRateSheetVersionsShopAndSheet({
            shopId: dataUser.data.intShopId, // || 520, // 520 para rates de customer
            carrierId: form.intCarrierId,
            serviceId: form.intServiceId,
            warehouseId: warehouseId
          })
        } else if (dataUser.type === 'customer') {
          await getRateSheetVersionsCustomerAndSheet({
            customerId: dataUser.data.intCustomerId,
            carrierId: form.intCarrierId,
            serviceId: form.intServiceId,
            warehouseId: warehouseId
          })
        } else {
          if (!isCustomerCourierRateSheet)
            await getRateSheetVersionsCarrierAndSheet({
              intCarrierId: dataUser.data.intCarrierId,
              intServiceId: form.intServiceId,
              sinWarehouseId: 6
            })
          else {
            await getRateSheetVersionsCustomerCouriersAndSheet({
              carrierId: carrierId,
              serviceId: form.intServiceId,
              warehouseId: 6
            })
            // logger.log('Es Customer Courier Rate Sheets', isCustomerCourierRateSheet)
          }
        }
      } catch (err: any) {
        logger.log({ err })
        if (err.message === 'SERVICE_NOT_HAVE_ZONE_WEIGTH') {
          setShowBtnGoToServiceEditor(true)
        }
        setLoading(false)
        setIsRenderTableError(true)
      }
    }

    fetch()
  }

  /* const getTitleCard = (typeUser: string) => {
    if (typeUser === 'customer') return 'Customer'
    if (typeUser === 'shop') return 'Client'

    return 'Courier'
  } */

  return (
    <>
      <Card>
        <CardHeader
          title={`${t('RATE_SHEETS')} ${pageType !== 'shop' && pageType !== 'customer'
              ? '- ' + (couriers.find(c => c.id === carrierId)?.vchName || '')
              : '- ' + (dataUser.data.vchName)
            }`}
        />
        <CardContent>
          <Grid container mb={4}>
            <Grid item xs={12} md={10} display='flex' alignItems='center'>
              <Grid container>
                {pageType === 'shop' || pageType === 'customer' || dataUser.data === null ? (
                  <Grid item xs={12} md={6} sx={{ pr: 3, p: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel id='select-carrier-label'>Courier</InputLabel>
                      <Select
                        size='small'
                        label='Courier'
                        defaultValue=''
                        value={carrierId.toString()}
                        labelId='select-carrier-label'
                        onChange={handleCourier}
                      >
                        {couriers.map(item => (
                          <MenuItem key={item.intCarrierId} value={item.id}>
                            {item.vchName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
                {/* md=   dataUser.type === 'shop' || dataUser.type === 'customers' ? 4 :  */}
                <Grid item xs={12} md={6} sx={{ pr: 3, p: 2 }}>
                  <SelectShipMethods
                    onlyActive
                    type={dataUser.type}
                    carrierId={carrierId}
                    handleSelected={sm => changeValuesSelectors('shipMethods', sm, 'intServiceId')}
                  />
                </Grid>
                {dataUser.type === 'shop' && (
                  <Grid item xs={12} md={6} sx={{ pr: 3, p: 2 }}>
                    <SelectWarehouses onlyActive handleSelected={wh => warehouseChange(wh)} />
                  </Grid>
                )}
                {headers.length !== 0 && (
                  <Grid item xs={12} md={6} sx={{ pr: 3, p: 2 }}>
                    <MeasureListRateSheet
                      useAllValue={true}
                      onSelectMeasure={measure => logger.log('Measure en padre:', measure)}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6} sx={{ p: 2 }}>
                  <Collapse in={headerBaseRateDetails && headerBaseRateDetails.tinUpdateRequired === 1}>
                    <Alert
                      severity='warning'
                      action={
                        <IconButton
                          size='small'
                          color='inherit'
                          aria-label='close'
                          onClick={() => console.log('Something!')}
                        >
                          <Icon icon='mdi:reload' fontSize='inherit' />
                        </IconButton>
                      }
                    >
                      <AlertTitle>{t('PENDING_UPDATE')}!</AlertTitle>
                    </Alert>
                  </Collapse>
                </Grid>
              </Grid>
            </Grid>
            <Grid item display='flex' flexDirection='column' justifyContent='flex-start' xs={12} md={2} sx={{ p: 2 }}>
              <Button
                disabled={disabledActionButton}
                size='small'
                variant='contained'
                onClick={e => setAnchorEl(e.currentTarget)}
                sx={{ '& svg': { ml: 1 }, mb: 2 }}
              >
                <Typography color={'white'}>
                  {t('ACTIONS')}
                  <Icon
                    color={'white'}
                    icon='streamline:interface-setting-slider-vertical-adjustment-adjust-controls-fader-vertical-settings-slider'
                  />
                </Typography>
              </Button>
              <Button
                size='small'
                variant='contained'
                sx={{ mb: { xs: 3, md: 0 } }}
                onClick={onSubmit}
                disabled={initialValues.intServiceId === form.intServiceId}
              >
                <Typography color={'white'}>{t('LOAD')}</Typography>
              </Button>
              <Menu
                keepMounted
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                open={Boolean(anchorEl)}
                TransitionComponent={Fade}
              >
                {filterListRS.map(({ value, title, show, onClick }, i) => {
                  return show === true ? (
                    <MenuItem key={i} onClick={() => (onClick && onClick()) || (value && setOptionMenu(value))}>
                      {title}
                    </MenuItem>
                  ) : null
                })}
                <Divider />
                {filtersListIndividualRS.map(({ title, value, show }, i) => {
                  return show === true ? (
                    <MenuItem key={i} onClick={() => setAnchorEl(null)}>
                      {i === 0 ? <b>{title}</b> : <span>{title}</span>}
                    </MenuItem>
                  ) : null
                })}
              </Menu>
            </Grid>
          </Grid>
          <Grid item xs={12} display='flex' flexDirection='row' justifyContent='flex-start'>
            {infoFiltersSelected.nameShipMethod !== null && infoFiltersSelected.nameWarehouse !== null && (
              <>
                <Typography variant='subtitle2'>{t('SELECTED')}</Typography>
                <Typography variant='subtitle2' color='primary' sx={{ mx: 2 }}>
                  {infoFiltersSelected.nameShipMethod}
                </Typography>
                <span>-</span>
                <Typography variant='subtitle2' color='primary' sx={{ mx: 2 }}>
                  {infoFiltersSelected.nameWarehouse}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12} display='flex' flexDirection='row' justifyContent='flex-start'>
            {headerBaseRateDetails && (
              <>
                <Typography variant='subtitle2' color='primary'>
                  <span style={{ color: 'rgba(58, 53, 65, 0.6)', marginRight: '10px' }}>{t('START_DATE')}</span>
                  {headerBaseRateDetails?.dtmStarDate
                    ? headerBaseRateDetails?.dtmStarDate?.toString().split('T')[0] || ''
                    : headerBaseRateDetails?.dtmStartDate?.toString().split('T')[0] || ''}
                </Typography>
                <span style={{ margin: '0 10px' }}>-</span>
                <Typography variant='subtitle2' color='primary'>
                  <span style={{ color: 'rgba(58, 53, 65, 0.6)', marginRight: '10px' }}>{t('END_DATE')}</span>
                  {headerBaseRateDetails?.dtmEndDate?.toString().split('T')[0] || ''}
                </Typography>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ p: 2 }}
            display='flex'
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent='space-between'
          >
            {dataUser.type === 'shop' || dataUser.type === 'customer' ? (
              <RadioGroup
                row
                value={typeTableRateSheet}
                onChange={e => {
                  setTypeTableRateSheet(e.target.value)
                }}
                sx={{ flex: 1 }}
              >
                <FormControlLabel
                  value={TypeTable.DISCOUNT}
                  control={<Radio color={mode === 'light' ? 'secondary' : 'primary'} size='small' />}
                  label={TypeTable.DISCOUNT}
                />
                <FormControlLabel
                  value={TypeTable.MARKUP}
                  control={<Radio color={mode === 'light' ? 'secondary' : 'primary'} size='small' />}
                  label={TypeTable.MARKUP}
                />
                <FormControlLabel
                  value={TypeTable.SETRATE}
                  control={<Radio color={mode === 'light' ? 'secondary' : 'primary'} size='small' />}
                  label={TypeTable.SETRATE}
                />
              </RadioGroup>
            ) : isCustomerCourierRateSheet ? (
              <Grid />
            ) : (
              <CarrierRateRadioGroup
                typeTableRateSheet={typeTableRateSheet}
                setTypeTableRateSheet={setTypeTableRateSheet}
                mode={mode}
              />
            )}
            {showBtnAddSubstract && (
              <Stack direction='row' justifyContent='flex-start' alignItems='center' mr={5}>
                <Button variant='outlined' size='small' onClick={e => setAddRestDialog(true)}>
                  {t('ADD_OR_SUBSCTRACT_PERCENTAGE')}
                </Button>
              </Stack>
            )}
            {(showBtnSave || !!createModalCache) && (
              <Stack direction='row' justifyContent='flex-start' alignItems='center' mr={5}>
                <Typography variant='subtitle2' sx={{ fontStyle: 'italic', mr: 2 }}>
                  {t(configBtnSave.textSave)}
                </Typography>
                <LoadingButton
                  loading={loading}
                  variant='contained'
                  sx={{ maxWidth: '130px', marginLeft: '10px' }}
                  color='error'
                  disabled={configBtnSave.disableBtn}
                  onClick={createModalCache ? createRateSheet : newVersion ? handleOpenSaveAsModal : saveChangesTable}
                >
                  {newVersion && !createModalCache ? t('SAVE AS') : t('SAVE')}
                </LoadingButton>
              </Stack>
            )}

            <Stack display='flex' flexDirection={{ xs: 'column', md: 'row' }}>
              <Typography fontWeight={500} variant='body1' sx={{ pt: 2, mr: { xs: 0, md: 3 } }}>
                {t('RATE_SHEET_VERSIONS')}
              </Typography>
              <Stack display='flex' flexDirection='row' alignItems='center'>
                <Typography variant='body2' mr={3}>
                  {versionSelected?.replace(/"/g, '')}
                </Typography>
                <IconButton
                  size='small'
                  onClick={() => {
                    setNewVersion(false)
                    openDialog('rateSheetVersions')
                  }}
                  disabled={listRateSheetVersion.length === 0}
                >
                  <Icon icon='bx:edit' color='primary' />
                </IconButton>
              </Stack>
            </Stack>
          </Grid>
          <CardContent sx={{ px: 0, pt: 1, p: 2 }}>
            <Grid container>
              {showBtnGoToServiceEditor ? (
                <Stack display='flex' flexDirection='row' alignItems='center'>
                  {t('WEIGHT_ZONES_NOT_CONFIGURED_TEXT')}
                  <Button
                    sx={{ ml: 3 }}
                    variant='text'
                    onClick={() =>
                      navigate(`/ carriers / services - editor / edit`, {
                        state: {
                          ...dataUser,
                          intServiceId: form.intServiceId,
                          dataService: infoServiceSelected,
                          from: 'ratesSheet'
                        }
                      })
                    }
                  >
                    {t('GO_SERVICE_EDITOR')}
                  </Button>
                </Stack>
              ) : (
                <Grid item xs={12}>
                  {children}
                </Grid>
              )}
            </Grid>
          </CardContent>
        </CardContent>
        <RateSheetVersionsModal
          ref={modalRefs['rateSheetVersions']}
          list={listRateSheetVersion}
          typeUser={dataUser.type}
          newSheet={newVersion}
          handleVersionSelected={(version: RateSheetVersionsGetModel) => {
            handleVersionSelected(version)
            if (isSaveAsCreatingNewSheet) {
              setCreateModalCache({
                ...(createModalCache as any),
                versionSelected: version.id
              })
            }
          }}
        />
        <UnsavedChangesModal
          ref={modalRefs['unsavedChanges']}
          texts={{ title: t('WARNING'), text: t('UNSAVED_CHANGE_TEXT') }}
        />
        <Dialog open={isSaveAsModalOpen || isSaveAsCreatingNewSheet} onClose={handleCloseSaveAsModal}>
          <DialogTitle>{isSaveAsCreatingNewSheet ? t('CREATE_RATE_SHEET') : t('SAVE_AS')}</DialogTitle>
          <DialogContent style={{ minWidth: '400px' }}>
            <Typography>{t('VERSION_CODE')}:</Typography>
            <TextField autoFocus margin='dense' id='code' type='text' fullWidth onChange={handleCodeChange} />
            <div>
              <Typography sx={{ mt: 2 }}>{t('START_DATE')}:</Typography>
              <RateDatePicker
                popperPlacement={popperPlacement}
                startEndDate='start'
                setSelectedDateStart={date => setModalData({ ...modalData, startDate: date })}
              />
            </div>
            <div>
              <Typography sx={{ mt: 2 }}>{t('END_DATE')}:</Typography>
              <RateDatePicker
                popperPlacement={popperPlacement}
                startEndDate='end'
                selectedDateStart={modalData.startDate}
                setSelectedDateStart={date => setModalData({ ...modalData, endDate: date })}
              />
            </div>
            {isSaveAsCreatingNewSheet && (
              <>
                {/* <div>
                  <Typography sx={{ mt: 2 }}>{t('MEASURE')}:</Typography>
                  <MeasureListRateSheet
                    onSelectMeasureRaw={measure => {
                      setModalData({ ...modalData, measure: measure.id })
                    }}
                    onLoadMeasureDefault={measure => {
                      setModalData({ ...modalData, measure: measure.id })
                    }}
                  />
                </div> */}
                <div>
                  <Stack
                    sx={{
                      mt: '16px'
                    }}
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                  >
                    <input
                      type='checkbox'
                      checked={useBaseRateSheet}
                      onChange={e => setUseBaseRateSheet(e.target.checked)}
                    />
                    <Typography>{t('USE_BASE_RATE_SHEET')}</Typography>
                  </Stack>
                  {useBaseRateSheet && (
                    <Stack display='flex' flexDirection={{ xs: 'column', md: 'row' }}>
                      <Stack display='flex' flexDirection='row' alignItems='center'>
                        <Typography variant='body2' mr={3}>
                          {versionSelected?.replace(/"/g, '')}
                        </Typography>
                        <IconButton
                          size='small'
                          onClick={() => {
                            setNewVersion(false)
                            openDialog('rateSheetVersions')
                          }}
                          disabled={listRateSheetVersion.length === 0}
                        >
                          <Icon icon='bx:edit' color='primary' />
                        </IconButton>
                      </Stack>
                    </Stack>
                  )}
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Stack justifyContent='flex-start' width={'100%'}>
              {match({ isSaveAsCreatingNewSheet, pageType, getVerifiedCarrierQuery, getVerifiedCustomerQuery })
                .with(
                  {
                    isSaveAsCreatingNewSheet: true,
                    pageType: P.when(v => ['shop', 'customer'].includes(v)),
                    getVerifiedCustomerQuery: {
                      data: {
                        data: {
                          data: [P.any, ...P.array()]
                        }
                      }
                    }
                  },
                  () => <p style={{ color: '#d7af21', maxWidth: '300px' }}>{t('ALREADY_EXISTS_SHEET')}</p>
                )
                .with(
                  {
                    isSaveAsCreatingNewSheet: true,
                    pageType: P.when(v => !['shop', 'customer'].includes(v)),
                    getVerifiedCarrierQuery: {
                      data: {
                        data: {
                          data: [P.any, ...P.array()]
                        }
                      }
                    }
                  },
                  () => <p style={{ color: '#d7af21', maxWidth: '300px' }}>{t('ALREADY_EXISTS_SHEET')}</p>
                )
                .otherwise(() => (
                  <></>
                ))}
              <Stack direction='row' justifyContent='flex-end' alignItems='center' gap={4}>
                <Button variant='contained' onClick={handleCloseSaveAsModal} color='secondary'>
                  {t('CANCEL')}
                </Button>
                <Button style={{ color: 'white' }} variant='contained' onClick={saveAsChangesTable}>
                  {isSaveAsCreatingNewSheet ? t('ACCEPT') : t('SAVE_AS')}
                </Button>
              </Stack>
            </Stack>
          </DialogActions>
        </Dialog>
        <ModalAddAndSubtractionHandle open={addRestDialog} showDialog={(show: boolean) => setAddRestDialog(show)} />
        <Dialog open={cloneSheetDialog} maxWidth={'lg'} fullWidth>
          <CloneSheetForm
            cancelClone={() => setCloneSheetDialog(false)}
            onSelectParams={params => console.log('Params : ', params)}
          />
        </Dialog>
      </Card>
    </>
  )
}

export default FilterCarrierRateSheet

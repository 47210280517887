// ** Toolkit imports
import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
// ** Redux persist imports
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import profileSlice from 'src/store/apps/profile'
// ** Reducers
import user from 'src/store/apps/user'

import rates from './apps/rates'

const persistConfig = {
  key: 'root',
  storage
}

const profile = persistReducer(persistConfig, profileSlice)

export const store = configureStore({
  reducer: {
    user,
    profile,
    rates
  },
  middleware: [thunk]

  // middleware: getDefaultMiddleware =>
  //   getDefaultMiddleware({
  //     serializableCheck: false
  //   })
})
export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, CardHeader, Grid, Typography } from '@mui/material'
// import IconButton from '@mui/material/IconButton'
// import Icon from 'src/@core/components/icon'
import AutocompleteProps from 'src/pages/components/orderEditor/AutoCompleteProp'
// import { logger } from 'src/utils/Logger'
import SelectCourier from 'src/views/filters/selectors/SelectCourier'
import SelectShipMethods from 'src/views/filters/selectors/SelectShipMethods'
import SelectWarehouses from 'src/views/filters/selectors/SelectWarehouses'

interface CloneSheetFormProps {
  onSelectParams: (params: object) => void
  cancelClone: () => void
}
enum Step {
  Customer = 1,
  Courier = 2,
  ShipMethod = 3,
  Warehouse = 4
}

const CloneSheetForm = ({ onSelectParams, cancelClone }: CloneSheetFormProps) => {
  // const [form, setForm] = useState()
  const [step, setStep] = useState<Step>(Step.Customer) // Clone by step
  const [customerName, setCustomerName] = useState<string>('')
  const [courierId, setCourierId] = useState<number>(0)
  const [shipMethodId, setShipMethodId] = useState<number>(0)

  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader
        title={<Typography variant='h5'>{t('CLONE_SHEET_BY_CUSTOMER')}</Typography>}
        // action={
        //   <IconButton onClick={cancelClone} color='error'>
        //     <Icon icon='material-symbols:cancel-outline' fontSize={30} />
        //   </IconButton>
        // }
      />
      <Grid container sx={{ m: 1 }} justifyContent='' alignItems='center'>
        <Grid item sx={{ p: 1 }} xs={12} lg={5}>
          <AutocompleteProps onSelectCustomer={(customer: any) => setCustomerName(customer)} />
        </Grid>
        <Grid item sx={{ p: 2 }} xs={12} lg={3}>
          <Button fullWidth disabled={customerName === ''} variant='contained'>
            <Typography color={'white'}>{t('CLONE_ALL_CUSTOMER')}</Typography>
          </Button>
        </Grid>
        <Grid item sx={{ p: 2 }} xs={12} lg={3}>
          <Button disabled={customerName === ''} onClick={() => setStep(Step.Courier)} fullWidth variant='contained'>
            <Typography color={'white'}>{t('CLONE_COURIER')}</Typography>
          </Button>
        </Grid>
        <Grid item sx={{ p: 2 }} xs={4} lg={1}>
          <Button onClick={cancelClone} color='error' fullWidth variant='contained'>
            {t('CANCEL')}
          </Button>
        </Grid>
      </Grid>

      {step > 1 && (
        <Grid container sx={{ m: 1 }} justifyContent='' alignItems='center'>
          <Grid item sx={{ p: 1 }} xs={12} lg={5}>
            <SelectCourier
              handleSelected={
                (courierId: number) => setCourierId(courierId) /*logger.log('Courier en padre:', courier)*/
              }
            />
          </Grid>
          <Grid item sx={{ p: 2 }} xs={4} lg={3}>
            <Button disabled={courierId === 0} fullWidth variant='contained'>
              <Typography color={'white'}>{t('CLONE_ALL_COURIER')}</Typography>
            </Button>
          </Grid>
          <Grid item sx={{ p: 2 }} xs={4} lg={3}>
            <Button disabled={courierId === 0} onClick={() => setStep(Step.ShipMethod)} fullWidth variant='contained'>
              <Typography color={'white'}>{t('CLONE_SHIP_METHOD')}</Typography>
            </Button>
          </Grid>
          <Grid item sx={{ p: 2 }} xs={4} lg={1}>
            <Button onClick={() => setStep(Step.Customer)} color='error' fullWidth variant='contained'>
              {t('BACK')}
            </Button>
          </Grid>
        </Grid>
      )}

      {step > 2 && (
        <Grid container sx={{ m: 1 }} justifyContent='space-between' alignItems='center'>
          <Grid item sx={{ p: 1 }} xs={12} lg={5}>
            <SelectShipMethods
              onlyActive
              carrierId={courierId}
              handleSelected={(smId: number) => setShipMethodId(smId) /* console.log('SM:', sm)*/}
            />
          </Grid>
          <Grid item sx={{ p: 2 }} xs={12} lg={3}>
            <Button disabled={shipMethodId === 0} fullWidth variant='contained'>
              <Typography color={'white'}>{t('CLONE_ALL_SHIP_METHOD')}</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ p: 2 }} lg={3}>
            <Button disabled={shipMethodId === 0} onClick={() => setStep(Step.Warehouse)} fullWidth variant='contained'>
              <Typography color={'white'}>{t('CLONE_WAREHOUSE')}</Typography>
            </Button>
          </Grid>
          <Grid item sx={{ p: 2 }} xs={4} lg={1}>
            <Button onClick={() => setStep(Step.Courier)} color='error' fullWidth variant='contained'>
              {t('BACK')}
            </Button>
          </Grid>
        </Grid>
      )}

      {step > 3 && (
        <Grid container justifyContent='space-between' alignItems='center' sx={{ m: 1 }}>
          <Grid item sx={{ p: 1 }} xs={12} lg={5}>
            <SelectWarehouses onlyActive handleSelected={wh => console.log('Warehouse selected : ', wh)} />
          </Grid>
          <Grid item sx={{ p: 2 }} xs={12} lg={6}>
            <Button fullWidth variant='contained'>
              <Typography color={'white'}>{t('CLONE_WAREHOUSE')}</Typography>
            </Button>
          </Grid>
          <Grid item sx={{ p: 2 }} xs={4} lg={1}>
            <Button onClick={() => setStep(Step.ShipMethod)} color='error' fullWidth variant='contained'>
              {t('BACK')}
            </Button>
          </Grid>
        </Grid>
      )}
    </Card>
  )
}

export default CloneSheetForm

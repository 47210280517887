// ** MUI Imports
import { ForwardedRef, forwardRef, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material'
import { grey } from '@mui/material/colors'
// ** Icon Imports
import Icon from 'src/@core/components/icon'
import { postCarrierTransaction } from 'src/@http/carrier-account'
import { CrudView } from 'src/types/forms/CrudView'
import { logger } from 'src/utils/Logger'
import { showIfValidImage } from 'src/utils/misc'
// ** Types Imports
import IconRecharge from 'src/views/icons/IconRecharge'
import appStore from 'src/zustand/app'

import { DialogExpose, DialogGeneric } from '../generic/DialogGeneric'
import WrapperSecurity from '../generic/WrapperSeurityObject'
import { IconAction } from '../rateSheets/TableRateSheet'

import ListCarrierTransaction from './ListCarrierTransaction'
import ModalDetailAccount from './ModalDetailAccountServer'

function formatNumberWithCommas(value: { toString: () => string }) {
  const parts = value.toString().split('.')
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const formattedNumber = parts.length > 1 ? `${integerPart}.${parts[1]}` : integerPart

  return formattedNumber
}

const CardStatsHorizontalServer = (props: any) => {
  const { carrierName, accountNumber, minBalance, balance, image, url, vchName } = props

  const filters = {
    intShop: -1,
    intCustomer: -1,
    intCarrier: props.intCarrierId,
    sinLSTransactionTypeId: -1 // 1 - Credit, 2 - Exchange
  }
  const [balanceState, setBalanceState] = useState(balance)
  const { setShowPaymentDialog } = appStore()
  const [rechargeAmount, setRechargeAmount] = useState('1.00')
  const [rechargeModalOpen, setRechargeModalOpen] = useState(false)

  const handleChangeRechargeAmount = (event: { target: { value: string } }) => {
    let value = event.target.value.replace(/[^0-9.]/g, '')
    value = formatNumberWithCommas(value)
    setRechargeAmount(value)
  }

  const [checkingAccountModalOpen, setcheckingAccountModalOpen] = useState(false)

  const navigate = useNavigate()
  const modalOptionAccount = useRef<DialogExpose>(null)
  const [handleDetailsAccount, setHandleDetailsAccount] = useState<{ open: boolean; data: null | any }>({
    open: false,
    data: null
  })

  const { t } = useTranslation()

  const colorStatus = (balance: number, minBalance: number) => {
    if (balance === 0) return 'error.main'
    if (balance <= minBalance) return 'warning.main'

    return 'success.main'
  }

  const handleOptionSelected = (option: string) => {
    if (option === 'Ez Account') {
      setShowPaymentDialog(true)
    } else if (option === 'Own Account') {
      setHandleDetailsAccount({
        open: true,
        data: props
      })
    }
  }

  const handlerechargeModalClose = () => {
    setRechargeModalOpen(false)
  }
  const handleCheckingAccountModalClose = () => {
    setcheckingAccountModalOpen(false)
  }
  const handleCheckingAccountModalOpen = async () => {
    setcheckingAccountModalOpen(true)
  }

  const handleRedirectPage = () => {
    window.open(url, '_blank')
  }

  const handleRechargeClose = async () => {
    const cleanedRechargeAmount = rechargeAmount.replace(/,/g, '')

    logger.log('Recharge amount:', cleanedRechargeAmount)

    const transaction = {
      intLSCarrierAccount: props.intLSCarrierAccount,
      decAmount: parseInt(cleanedRechargeAmount),
      vchDescription: `Recarga de ${cleanedRechargeAmount}`,
      dtmCreatedDate: new Date().toISOString(),
      intCreatedUserId: 1,
      bigOrderId: 0,
      vchTrakingNumber: 'TRGN9437476',
      vchSource: 'NOSE',
      intStatusId: 1,
      vchStatusName: 'Active',
      vchStatusColor: 'success',
      sinLSTransactionTypeId: 1,
      vchTransactionTypeName: 'Credit',
      vchCustomerName: '',
      vchShopName: '',
      vchName: '',
      vchLegacyNumber: ''
    }

    try {
      const res = await postCarrierTransaction(transaction)

      logger.log('Res:', res)
    } catch (error) {
      console.error('Error:', error)
    }

    setBalanceState(balanceState + Number(cleanedRechargeAmount))

    setRechargeModalOpen(false)
  }

  /* const RowOptions = () => {
    const { setTabValue } = jobStore()

    const handleClick = (mode: string) => {
      if (mode === 'Detail') {
        setTabValue('1')
      } else if (mode === 'Step') {
        setTabValue('2')
      }
    }

    return (
      <Box width='100%' display='flex' justifyContent='center'>
        <IconAction title={'Order detail'} icon='mdi:eye' onClick={() => handleClick('Detail')} />
      </Box>
    )
  } */

  const getAccountNumberFormat = (an: string) => {
    if (an && an.startsWith('N°:')) return an.slice(3)

    return an || '-'
  }

  return (
    <Card
      sx={{
        boxShadow: theme => `${theme.shadows[0]} !important`,
        border: theme => `1px solid ${theme.palette.divider}`
      }}
      data-cy="account-server-card"
    >
      <CardContent
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <Stack sx={{ flexDirection: 'row', width: '100%' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant='h6' sx={{ lineHeight: 0.8, display: 'flex', alignItems: 'center' }}>
              {carrierName}
              <Tooltip title={t('ENABLED_STATUS')}>
                <IconButton sx={{ height: '12px' }}>
                  <Icon icon={'lets-icons:check-fill'} style={{ marginLeft: 7 }} fontSize={22} color={'#177231'} />
                </IconButton>
              </Tooltip>
            </Typography>
            <Typography variant='caption' sx={{ lineHeight: 0.8, display: 'flex', alignItems: 'center', marginY: 1 }}>
              {vchName}
            </Typography>

            <Typography variant='caption'>
              {t('ACCOUNT_NUMBER')}: {getAccountNumberFormat(accountNumber)}
            </Typography>
            <Typography
              variant='h6'
              fontWeight={600}
              sx={{ my: 2, mr: 1, lineHeight: 1.05, color: colorStatus(balance, minBalance) }}
            >
              $ {balanceState}
            </Typography>
            <Box sx={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
              <WrapperSecurity securityKey='edit_ls_btn'>
                <IconAction
                  datacy='edit-account-server'
                  title={t('EDIT')}
                  icon='mdi:pencil-outline'
                  color='primary'
                  sx={{ pl: 0 }}
                  onClick={() => navigate('account/edit', { state: { data: props, mode: CrudView.EDIT } })}
                />
              </WrapperSecurity>
              <IconAction
                datacy='services-account-server'
                title={t('SERVICES')}
                icon='carbon:mobility-services'
                onClick={() => navigate('account/services-associates', { state: { data: props } })}
              />
              <WrapperSecurity securityKey='recharge_ls_btn'>
                <IconRecharge
                  color={props.bitOwnAccount === 1 ? '#908E95' : '#CCCBCE'}
                  disabled={props.bitOwnAccount === 0}
                  title={t('RECHARGE')}
                  onClick={() => props.bitOwnAccount === 1 && setRechargeModalOpen(!rechargeModalOpen)}
                />
              </WrapperSecurity>
              <WrapperSecurity securityKey='ledger_ls_btn'>
                <IconAction
                  title={t('LEDGER_TEXT')}
                  icon='mdi:invoice-list'
                  disabled={props.bitOwnAccount === 0}
                  onClick={handleCheckingAccountModalOpen}
                />
              </WrapperSecurity>
              <Chip
                label={t('OWN')}
                icon={<Icon icon={props.bitOwnAccount ? 'mdi:check-circle-outline' : 'mdi:close-circle-outline'} />}
                color={props.bitOwnAccount ? 'success' : 'error'}
                size={'small'}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '123px',
              textAlign: 'center'
            }}
          >
            { 
              showIfValidImage(image) 
              ? <img width={'85'} src={image} height={'70'} alt={carrierName} />
              : <Avatar 
                  variant="square"
                  sx={{ width: '120px', height: '130px', bgcolor: grey['100'], fontSize: '46px', borderRadius: '6px' }}
                >
                  {carrierName[0]}
                </Avatar>
            }
          </Box>
        </Stack>
      </CardContent>
      {/* Dialogs */}
      <Dialog open={rechargeModalOpen} onClose={handlerechargeModalClose}>
        <DialogTitle>{t('RECHARGE_ACCOUNT_BALANCE')}</DialogTitle>
        <DialogContent>
          <Typography>{t('RECHARGE_AMOUNT')}:</Typography>
          <TextField
            autoFocus
            margin='dense'
            id='rechargeAmount'
            type='text'
            fullWidth
            value={rechargeAmount}
            onChange={handleChangeRechargeAmount}
            InputProps={{
              startAdornment: <InputAdornment position='start'>$</InputAdornment>
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handlerechargeModalClose} color='secondary'>
            {t('CANCEL')}
          </Button>
          <Button variant='contained' onClick={handleRedirectPage} color='primary' disabled={!url}>
            {t('GO_CARRIER_PAGE')}
          </Button>
          <Button variant='contained' onClick={handleRechargeClose} color='secondary'>
            {t('CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Ledger - List transactions */}
      <Dialog
        open={checkingAccountModalOpen}
        onClose={handlerechargeModalClose}
        sx={{
          '& .MuiDialog-paper': {
            width: '80%',
            maxWidth: 'none'
          }
        }}
      >
        <DialogTitle>
          {carrierName} {t('LEDGER_TEXT')}
        </DialogTitle>
        <DialogContent>
          <ListCarrierTransaction filters={filters} />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleCheckingAccountModalClose} color='secondary'>
            {t('CLOSE')}
          </Button>
        </DialogActions>
      </Dialog>
      <ModalDetailAccount
        data={props}
        open={handleDetailsAccount.open}
        setClose={() => {
          setHandleDetailsAccount({ open: false, data: null })
        }}
      />
      <ModalOptionAccount ref={modalOptionAccount} title={props.carrierName} optionSelected={handleOptionSelected} />
    </Card>
  )
}

type OptionAccountProp = {
  title: string
  optionSelected: (option: string) => void
}

interface CheckboxState {
  ownAccount: boolean
  ezAccount: boolean
}

const ModalOptionAccount = forwardRef(
  ({ title, optionSelected }: OptionAccountProp, ref: ForwardedRef<DialogExpose>) => {
    const theme = useTheme()
    const primaryColor = theme.palette.primary.main
    const [checkboxState, setCheckboxState] = useState({
      ownAccount: false,
      ezAccount: false
    })

    const handleCheckboxChange = (event: any, name: keyof CheckboxState) => {
      setCheckboxState(prevState => {
        const updatedState: any = Object.fromEntries(
          Object.entries(prevState).map(([key, value]) => [
            key as keyof CheckboxState,
            key === name ? event.target.checked : false
          ])
        )

        return {
          ...updatedState,
          [name]: event.target.checked
        }
      })
    }

    const handleSelection = () => {
      if (checkboxState.ezAccount) return optionSelected('Ez Account')
      if (checkboxState.ownAccount) return optionSelected('Own Account')
    }

    return (
      <DialogGeneric
        ref={ref}
        title={title}
        confirmDisabled={checkboxState.ownAccount === false && checkboxState.ezAccount === false}
        styleBoxContent={{ mb: 0, py: 0, minHeight: 200 }}
        confirmText='Continue'
        confirmFunc={handleSelection}
      >
        <Typography variant='caption' fontSize={14}>
          Choose one of the following options:
        </Typography>
        <Grid container>
          <Grid
            item
            boxSizing='border-box'
            my={2}
            xs={12}
            minHeight='80px'
            borderRadius='8px'
            border={checkboxState.ownAccount ? `2px solid ${primaryColor}` : 'none'}
          >
            <label htmlFor='own-checkbox'>
              <Card elevation={0} sx={styleCard()}>
                <Avatar>
                  <Icon icon={'mdi:bank'} fontSize={30} color={checkboxState.ownAccount ? primaryColor : 'inherit'} />
                </Avatar>
                <Box width='100%' display='flex' justifyContent='space-between' alignItems='center'>
                  <Typography variant='subtitle1'>Use own account</Typography>
                  <Checkbox
                    id='own-checkbox'
                    name='own-checkbox'
                    checked={checkboxState.ownAccount}
                    onChange={(e: any) => handleCheckboxChange(e, 'ownAccount')}
                  />
                </Box>
              </Card>
            </label>
          </Grid>
          <Grid
            item
            boxSizing='border-box'
            mb={2}
            xs={12}
            minHeight='80px'
            borderRadius='8px'
            border={checkboxState.ezAccount ? `2px solid ${primaryColor}` : 'none'}
          >
            <label htmlFor='ez-account-checkbox'>
              <Card elevation={0} sx={styleCard()}>
                <Avatar>
                  <Icon icon={'ion:card'} fontSize={30} color={checkboxState.ezAccount ? primaryColor : 'inherit'} />
                </Avatar>
                <Box width='100%' display='flex' justifyContent='space-between' alignItems='center'>
                  <Typography variant='subtitle1'>Use Ez account</Typography>
                  <Checkbox
                    id='ez-account-checkbox'
                    name='ez-account-checkbox'
                    checked={checkboxState.ezAccount}
                    onChange={e => handleCheckboxChange(e, 'ezAccount')}
                  />
                </Box>
              </Card>
            </label>
          </Grid>
        </Grid>
      </DialogGeneric>
    )
  }
)

const styleCard = (): SxProps<Theme> => {
  return {
    display: 'flex',
    columnGap: '10px',
    height: '100%',
    alignItems: 'center',
    p: '1rem',
    background: grey[200],
    '&:hover': { background: grey['300'], cursor: 'pointer' }
  }
}

export default CardStatsHorizontalServer

import { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import { Avatar, Grid, styled, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

const StyledIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`
interface BoxPreviewImageProp extends React.HTMLAttributes<HTMLImageElement> {
  image: File | string | null
  heightBox?: number | string
  showActions?: boolean
  handleIconAction?: (action: string) => void
}

const BoxPreviewImage = (props: BoxPreviewImageProp) => {
  const [image, setImage] = useState<File | string | null>(null)

  const handleAction = (action: string) => {
    if (props.handleIconAction) {
      props.handleIconAction(action)
    }
  }

  useEffect(() => {
    setImage(props.image)
  }, [props.image])

  const setImagePreview = (image: string | File) => {
    if (image instanceof File) {
      return URL.createObjectURL(props.image as File)
    } else {
      return image
    }
  }

  return (
    <Grid style={props.style} container sx={styleBox(props)}>
      <Grid item sm={12} md={3} sx={{ pr: { md: 4, xs: 0 } }}>
        {props.image ? (
          <Avatar sx={{ bgcolor: grey[300], width: '100%', height: '100%', maxHeight: 105 }} variant='rounded'>
            <img
              alt='Not Found'
              src={image ? setImagePreview(image) : undefined}
              style={{ width: '100%', height: '100%', objectFit: 'contain', padding: '10px' }}
            />
          </Avatar>
        ) : (
          <Avatar sx={{ bgcolor: grey[300], width: '100%', height: '100%' }} variant='rounded'>
            <Icon icon='material-symbols:image' color={grey[100]} fontSize={50} />
          </Avatar>
        )}
      </Grid>
      <Grid
        item
        sm={props.showActions ? 10 : 12}
        md={props.showActions ? 7 : 9}
        display='flex'
        justifyContent='flex-start'
        alignItems='center'
        sx={{ maxWidth: 270 }}
      >
        <Typography variant='subtitle1' noWrap>
          {image instanceof File ? image.name : image?.substring(image.lastIndexOf('/') + 1) || '-'}
        </Typography>
      </Grid>
      {props.showActions && (
        <Grid item sm={2} md={2} display='flex' justifyContent='center' alignItems='center'>
          <StyledIcon icon='ic:outline-delete' color='red' fontSize={25} onClick={() => handleAction('IconDelete')} />
        </Grid>
      )}
    </Grid>
  )
}

const styleBox = (props: BoxPreviewImageProp) => {
  return {
    width: '100%',
    minHeight: props.heightBox || 120,
    backgroundColor: grey['100'],
    my: 2,
    p: 2,
    transition: 'background-color 0.5s ease',
    borderRadius: '10px',
    '&:hover': { background: grey['200'] }
  }
}

export default BoxPreviewImage

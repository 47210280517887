import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { RootState } from 'src/store'

interface Props {
  children: JSX.Element
}

const PrivateRoute = ({ children }: Props) => {
  const token: string = useSelector((state: RootState) => state.profile.token)
  const isLogged = token !== ''

  if (!isLogged) return <Navigate to='/login' />

  return children
}

export default PrivateRoute

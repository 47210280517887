// ** Next Import
import { useNavigate } from 'react-router-dom'
import { Link } from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
// ** MUI Imports
import Typography from '@mui/material/Typography'
// ** Icon Imports
import Icon from 'src/@core/components/icon'

interface Props {
  id: string | undefined
  toggleAddPaymentDrawer: () => void
  toggleSendInvoiceDrawer: () => void
}

const PreviewActionsInvoice = ({ id, toggleSendInvoiceDrawer, toggleAddPaymentDrawer }: Props) => {
  const navigate = useNavigate()

  const handlePrint = () => {
    window.print()
  }

  const handleGoBack = () => {
    navigate('/invoice')
  }

  return (
    <Card>
      <CardContent>
        <Button fullWidth sx={{ mb: 3.5 }} color='secondary' variant='outlined' onClick={handleGoBack}>
          Back
        </Button>
        <Button fullWidth sx={{ mb: 3.5 }} component={Link} color='secondary' variant='outlined' onClick={handlePrint}>
          Print
        </Button>
        <Button
          fullWidth
          sx={{ mb: 3.5 }}
          variant='contained'
          onClick={toggleSendInvoiceDrawer}
          startIcon={<Icon icon='mdi:send-outline' color={'white'} />}
        >
          <Typography color={'white'}>Download</Typography>
        </Button>
      </CardContent>
    </Card>
  )
}

export default PreviewActionsInvoice

import * as React from 'react'
import { useState } from 'react'
import Cards, { Focused } from 'react-credit-cards'
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
  TextField
} from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Payment from 'payment'
import CardWrapper from 'src/@core/styles/libs/react-credit-cards'
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from 'src/@core/utils/format'
import appStore from 'src/zustand/app'

// ** Styles Import
import 'react-credit-cards/es/styles-compiled.css'

const steps = ['Add', 'Summary']

export default function StepperPayMethodServer() {
  const { setShowPaymentDialog } = appStore()
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set<number>())
  const [paymentMethod, setPaymentMethod] = useState('select-method');
  const [amount, setAmount] = useState(0)
  const [minAmount, setMinAmount] = useState(0)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<any>(null)

  const handleChange = (event: SelectChangeEvent) => {
    setPaymentMethod(event.target.value as string);
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    if (activeStep === steps.length - 1) {
      setShowPaymentDialog(false)
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleBlur = () => {
    if (amount < minAmount) {
      setError('It is less than the minimum')
    } else {
      setError(null)
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      {activeStep === 0 && <Grid container display="flex" justifyContent='center' sx={{ mb: 6 }}>
        <Grid item xs={12} md={4} sx={{ pr: { md: 3, xs: 0 } }}>
          <FormControl fullWidth>
            <InputLabel htmlFor='outlined-adornment-amount'>Amount</InputLabel>
            <OutlinedInput
              id='outlined-adornment-amount'
              fullWidth
              name='amount'
              label='Amount'
              value={amount}
              autoComplete='off'
              onBlur={handleBlur}
              onChange={(e) => setAmount(Number(e.target.value))}
              startAdornment={<InputAdornment position='start'>$</InputAdornment>}
              error={error === null ? false : true}
              /* onFocus={e => setFocus(e.target.name as Focused)} */
            />
          </FormControl>
          {error && <span style={{color: "red"}}>{error}</span>}
        </Grid>
        <Grid item xs={12} md={4} sx={{ pr: { md: 3, xs: 0 } }}>
          <FormControl fullWidth>
            <InputLabel htmlFor='outlined-adornment-amount'>Minimum amount</InputLabel>
            <OutlinedInput
              fullWidth
              name='min_amount'
              label='Minimum amount'
              value={minAmount}
              autoComplete='off'
              onBlur={handleBlur}
              onChange={(e) => setMinAmount(Number(e.target.value))}
              startAdornment={<InputAdornment position='start'>$</InputAdornment>}
              /* onFocus={e => setFocus(e.target.name as Focused)} */
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel htmlFor='payment-method'>Payment Method</InputLabel>
              <Select
                onChange={handleChange}
                label='Payment Method'
                labelId='payment-method'
                id='payment-method-select'
                value={paymentMethod}
                disabled={error === null ? false : true}
              >
                <MenuItem value='select-method' disabled>
                  Select Payment Method
                </MenuItem>
                <MenuItem value='Cash'>Cash</MenuItem>
                <MenuItem value='Bank Transfer'>Bank Transfer</MenuItem>
                <MenuItem value='Credit'>Credit</MenuItem>
                <MenuItem value='Debit'>Debit</MenuItem>
                <MenuItem value='Paypal'>Paypal</MenuItem>
              </Select>
            </FormControl>
          </Grid>
      </Grid>}
      {paymentMethod && paymentMethod !== 'select-method' && (
        <>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {}
              const labelProps: {
                optional?: React.ReactNode
              } = {}
              if (isStepSkipped(index)) {
                stepProps.completed = false
              }

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          {activeStep !== steps.length && (
            <React.Fragment>
              {activeStep === 0 && (
                <Box>
                  <FormAdd getData={(data) => setData(data)} />
                </Box>
              )}
              {activeStep === 1 && (
                <Box py={4}>
                  <b>Amount</b>: ${amount}<br/>
                  <b>Min amount</b>: ${minAmount}<br/>
                  <b>Payment Method:</b> {paymentMethod}
                  {data && Object.keys(data).map((key: any) => (
                    <div key={key}>
                      <b style={{ textTransform: 'capitalize' }}>{key}:</b> {data[key]}
                    </div>
                  ))}
                </Box>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button color='inherit' disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleNext}>{activeStep === steps.length - 1 ? 'Finish' : 'Next'}</Button>
              </Box>
            </React.Fragment>
          )}
        </>
      )}
    </Box>
  )
}

type FormAddProp = {
  getData: (data: any) => void
}

const FormAdd = (props: FormAddProp) => {
  const { getData } = props
  // ** States
  const [form, setForm] = useState({
    name: '',
    cvc: '',
    cardNumber: '',
    expiry: ''
  })
  const [focus, setFocus] = React.useState<Focused | undefined>()

  const handleBlur = () => {
    setFocus(undefined)
  }

  const handleInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    getData(form)
    
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value, Payment)
      setForm({ ...form, cardNumber: target.value })
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value)
      setForm({ ...form, expiry: target.value })
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value, form.cardNumber, Payment)
      setForm({ ...form, cvc: target.value })
    } else if (target.name === 'name') {
      setForm({ ...form, name: target.value })
    }
  }

  return (
    <Grid container spacing={6} mt={4}>
      <Grid item xs={12}>
        <CardWrapper sx={{ '& .rccs': { m: '0 auto', display: { xs: 'none', sm: 'block' } } }}>
          <Cards cvc={form.cvc} focused={focus} expiry={form.expiry} name={form.name} number={form.cardNumber} />
        </CardWrapper>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name='number'
              value={form.cardNumber}
              autoComplete='off'
              label='Card Number'
              onBlur={handleBlur}
              onChange={handleInputChange}
              placeholder='0000 0000 0000 0000'
              onFocus={e => setFocus(e.target.name as Focused)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name='name'
              value={form.name}
              autoComplete='off'
              onBlur={handleBlur}
              label='Name on Card'
              placeholder='John Doe'
              onChange={handleInputChange}
              onFocus={e => setFocus(e.target.name as Focused)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              name='expiry'
              label='Expiry'
              value={form.expiry}
              onBlur={handleBlur}
              placeholder='MM/YY'
              onChange={handleInputChange}
              inputProps={{ maxLength: '5' }}
              onFocus={e => setFocus(e.target.name as Focused)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              name='cvc'
              label='CVC'
              value={form.cvc}
              autoComplete='off'
              onBlur={handleBlur}
              onChange={handleInputChange}
              onFocus={e => setFocus(e.target.name as Focused)}
              placeholder={Payment.fns.cardType(form.cardNumber) === 'amex' ? '1234' : '123'}
            />
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="column">
            <FormControlLabel
              control={<Switch defaultChecked />}
              label='Save Card for future billing?'
              sx={{ '& .MuiTypography-root': { color: 'text.secondary' } }}
            />
            <FormControlLabel
              control={<Switch defaultChecked />}
              label='Automatic recharge'
              sx={{ '& .MuiTypography-root': { color: 'text.secondary' } }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

import { RefObject, useEffect, useRef, useState } from 'react'
import { HotColumn, HotTable } from '@handsontable/react'
import { CircularProgress, Grid, Stack, useTheme } from '@mui/material'
import Handsontable from 'handsontable'
import { initialConfigBtnSave } from 'src/pages/rate-sheets/customers/carriers'
import { RateSheet } from 'src/types/models/RateSheetModel'
import { formatedData, moveColumnTable, restrictionsBeforeMovingColumn } from 'src/utils/actionsHandsontable'
import { logger } from 'src/utils/Logger'
import appStore from 'src/zustand/app'
import customerStore from 'src/zustand/customer'
import serviceStore from 'src/zustand/services'

import { EditorComponent, InfoRow } from './customComponents/CustomEditor'
import { cellRendererBaseRates, changeEditedCellStyle } from './customComponents/CustomRenderer'
import { TypeTable } from './FilterCarrierRateSheet'
import NotFoundCard from './NotFoundCard'
import CardCreate from './NotFoundCardCreate'

// Visual Configuration
const heigthContainerTable: number = screen.height - 300 || 0
const visualConfigurationHotTable = {
  licenseKey: 'non-commercial-and-evaluation',
  rowHeights: 60,
  width: '100%',
  rowHeaders: true,
  height: heigthContainerTable - 50
}

Handsontable.hooks.add('beforeCopy', (range: Array<any>, _options: Array<any>) => {
  navigator.clipboard.writeText(range.toString())
})

const TableRateSheetCarriers = (props: any) => {
  const theme = useTheme()
  const { setConfigBtnSave } = props
  const {
    cellListCourier,
    changeValueCell,
    headers,
    weights,
    setRowsAndCols,
    formatedCourierList,
    changeFormatedListMeasure,
    operationToPercentageSelectedCourierCells
  } = appStore()

  const { notFoundCustomerCourierRate, setShowBtnAddSubstract, setSelectedCellCoordinates, selectedCellCoordCustomer } =
    customerStore()

  const { measure } = serviceStore()

  const [readOnly, setReadOnly] = useState(false)
  const [list, setList] = useState<RateSheet[]>([])
  const [clipboard, setClipboard] = useState(null)
  const { typeTableRateSheet, loading, isRenderTableError } = appStore()
  const tablesRef: Record<string, RefObject<HotTable>> = {
    discount: useRef<HotTable>(null),
    markup: useRef<HotTable>(null),
    setrate: useRef<HotTable>(null)
  }
  useEffect(() => {
    measure === '-1'
      ? setRowsAndCols(cellListCourier)
      : setRowsAndCols(cellListCourier.filter(cell => cell.tinUnitMeasureId === measure))
    changeFormatedListMeasure()
  }, [measure])

  useEffect(() => {
    if (headers.length === 0 && weights.length === 0) {
      clearTableData()
    } else {
      logger.log('Formated Data couriers ', formatedData(cellListCourier))

      setList(formatedCourierList)
    }
  }, [headers, weights, cellListCourier, formatedCourierList])

  const clearTableData = () => {
    // For handsontable configuration
    for (const ref in tablesRef) {
      const ht = tablesRef[ref].current?.hotInstance
      ht?.clear()
      ht?.loadData([])
      ht?.destroy()
    }
    setList([])
    setConfigBtnSave(initialConfigBtnSave)
  }

  const headerManagment = {
    manualColumnMove: true,
    beforeColumnMove: (movedColumns: number[]) => restrictionsBeforeMovingColumn(movedColumns),
    afterColumnMove: (
      movedColumns: number[],
      finalIndex: number,
      dropIndex: number | undefined,
      movePossible: boolean,
      orderChanged: boolean
    ) => {
      if (orderChanged) {
        let ht
        if (tablesRef['markup'].current) ht = tablesRef['markup'].current.hotInstance
        if (ht) {
          const headers = ht.getColHeader()
          const colMoved = movedColumns[0]
          // setHeaders(prevHeaders => headers as string[])
          const newOrderData = moveColumnTable(list, finalIndex, colMoved)
          setList(prevData => newOrderData)
          ht.loadData(list)
        }
      }
    },
    colWidths: headers.map((h, index) => (index === 0 ? 10 : undefined))
  }

  const configurationColWeight = (instance: Handsontable.Core, td: HTMLTableCellElement, row: number) => {
    const rowIndex = instance.toPhysicalRow(row)
    let val
    if (weights) {
      val = weights[rowIndex]
      td.innerHTML = val
    }
    td.style.backgroundColor = '#f0f0f0'
    td.style.display = 'flex'
    td.style.justifyContent = 'center'
    td.style.alignItems = 'center'
    td.style.minHeight = '79px'
    td.style.maxHeight = '79px'

    return { td, val }
  }

  const handleCellValueChange = (infoRow: InfoRow, newValue: any, table: string, prop: string) => {
    // Change cell value in the table where it was changed
    if (newValue !== undefined) {
      let hotInstance
      if (tablesRef[table]?.current) {
        hotInstance = tablesRef[table].current?.hotInstance
        if (hotInstance) {
          const currentValue = hotInstance.getDataAtCell(infoRow.row, infoRow.col)
          const newCellValue = {
            ...currentValue,
            [prop]: Number(newValue),
            editionMode: {
              editedCell: true,
              table
            }
          }
          hotInstance.setDataAtCell(infoRow.row, infoRow.col, newCellValue)
          changeValueCell(newCellValue, prop)
        }
      }
      // Is replicated in the Markup table
      let instMarkupTable
      if (tablesRef['markup']?.current) {
        instMarkupTable = tablesRef['markup'].current?.hotInstance
        if (instMarkupTable) {
          const currentValue = instMarkupTable.getDataAtCell(infoRow.row, infoRow.col)
          instMarkupTable.setDataAtCell(infoRow.row, infoRow.col, { ...currentValue, [prop]: Number(newValue) })
        }
      }
    }

    setConfigBtnSave((config: any) => {
      return { ...config, showBtn: true }
    })
  }

  const handleCopyCells = (data: any, prop: string) => {
    const modifiedData = data.map((row: any) => row.map((cell: any) => String(cell[prop])))

    navigator.clipboard.writeText(modifiedData.map((row: any) => row.join('\t')).join('\n'))

    setClipboard(modifiedData)
  }

  const createDataRange = (table: any, value: any) => {
    const m1: any = []
    const selected = table.getSelectedRange() || []
    const { from, to } = selected[0]
    const array = new Array(to.col - from.col + 1).fill(value)
    for (let j = 0; j <= to.row - from.row; j++) {
      m1.push(array)
    }

    return m1
    // const value = clipboard ? clipboard[0][0]: ''
  }

  const handlePasteCells = async (data: any, coords: any, table: any, tableName: string, prop: string) => {
    const selected = table.getSelectedRange() || []
    const { from, to } = selected[0]

    const m = await navigator.clipboard.readText()

    const dataSelected = m.includes('[object Object]') ? clipboard : data
    const value = dataSelected ? dataSelected[0][0] : ''
    if (from.row === to.row && from.col === to.col) {
      handlePasteCustom(dataSelected, coords, prop, tableName)
    } else {
      const rangeCopied = createDataRange(table, value)
      handlePasteCustom(rangeCopied, coords, prop, tableName)
    }
  }

  const handlePasteCustom = (data: any, coords: any, propToModify: string, tableRef: string) => {
    const { startRow, startCol } = coords[0]
    const res = data
    const formattedTable = res
      .map((row: any, rowIndex: number) =>
        row.map((cell: any, colIndex: number) => {
          const valueParse = typeof cell === 'number' ? cell : parseFloat(cell.replace(/,/g, ''))
          const destRow = startRow + rowIndex
          const destCol = startCol + colIndex
          const currentValue = tablesRef[tableRef].current?.hotInstance?.getDataAtCell(destRow, destCol)
          const markupCurrentValue = tablesRef?.['markup']?.current?.hotInstance?.getDataAtCell(destRow, destCol)
          const newCellValue = {
            ...currentValue,
            [propToModify]: valueParse,
            editionMode: {
              editedCell: true,
              table: tableRef
            }
          }

          const newMarkupCellValue = {
            ...markupCurrentValue,
            [propToModify]: valueParse
          }

          changeValueCell(newCellValue, propToModify)

          return [destRow, destCol, newCellValue, newMarkupCellValue]
        })
      )
      .flat()

    const formattedCellTable = formattedTable.map((table: any) => table.filter((_: any, index: number) => index !== 3))
    const formattedMarkupTable = formattedTable.map((table: any) =>
      table.filter((_: any, index: number) => index !== 2)
    )

    tablesRef[tableRef].current?.hotInstance?.setDataAtCell(formattedCellTable)

    let instMarkupTable
    if (tablesRef['markup']?.current) {
      instMarkupTable = tablesRef['markup'].current?.hotInstance
      if (instMarkupTable) {
        instMarkupTable.setDataAtCell(formattedMarkupTable)
      }
    }

    setConfigBtnSave((config: any) => {
      return { ...config, showBtn: true }
    })
  }

  const getTypeTableSelected = (type: string): boolean => {
    console.log('Type table', type)
    console.log('Boolean ', typeTableRateSheet === type)

    return typeTableRateSheet === type
  }

  let autoFillRecorder: any
  const beforeAutofillByTable = (tableName: string, targetRange: any) => {
    const { from, to } = targetRange
    const dataMap: any[] = []
    for (let j = from.col; j <= to.col; j++) {
      for (let i = from.row; i <= to.row; i++) {
        const cell = tablesRef[tableName].current?.hotInstance?.getDataAtCell(i, j)
        const record = { cell, row: i, col: j }
        dataMap.push(record)
      }
    }
    autoFillRecorder = dataMap
    logger.log('Before auto fill recorder', autoFillRecorder)
  }

  const afterAutofill = (tableName: string, prop: string) => {
    // logger.log('Autofill recorder after: ', autoFillRecorder)
    logger.log('After auto fill recorder', autoFillRecorder)
    autoFillRecorder.forEach((cellRecord: any) => {
      const table = tablesRef[tableName].current?.hotInstance
      const cell = table?.getDataAtCell(cellRecord.row, cellRecord.col)
      const newCellValue = {
        ...cellRecord.cell,
        [prop]: cell[prop],
        editionMode: {
          editedCell: true,
          table: tableName
        }
      }
      table?.setDataAtCell(cellRecord.row, cellRecord.col, newCellValue)
      logger.log('Prop', prop)

      logger.log('New Value', cell[prop])
      changeValueCell(newCellValue, prop)
    })
    autoFillRecorder = []
  }

  const handleDeleteKeyPressed = (event: any, table: string, prop: string) => {
    const selected = tablesRef[table].current?.hotInstance?.getSelected()
    logger.log('Seleccionado', selected)
    if ((event.keyCode === 8 || event.keyCode === 46) && selected) {
      event.isImmediatePropagationEnabled = false
      event.preventDefault()
      selected.forEach(selectedRange => {
        const startRow = selectedRange[0]
        const startCol = selectedRange[1]
        const endRow = selectedRange[2]
        const endCol = selectedRange[3]

        for (let row = startRow; row <= endRow; row++) {
          for (let col = startCol; col <= endCol; col++) {
            // Establece el valor de la celda en blanco
            handleCellValueChange({ row, col: col }, 0, table, prop)
          }
        }
      })
    }
  }

  const getPropAccordingTable = (table: string) => {
    if (table === 'markup') return 'markupCourier'
    if (table === 'discount') return 'discountCourier'
    if (table === 'setrate') return 'setRateCourier'

    return ''
  }

  const handleOperationOnPercentageSelectedCells = () => {
    console.log({ selectedCellCoordCustomer })
    const tableName = selectedCellCoordCustomer?.table || ''
    if (selectedCellCoordCustomer?.coords && tableName !== '') {
      const prop = getPropAccordingTable(tableName)
      const { columnEnd, columnStart, rowEnd, rowStart } = selectedCellCoordCustomer?.coords
      const table = tablesRef[tableName].current?.hotInstance
      if (table) {
        const cells = []
        for (let r = rowStart; r <= rowEnd; r++) {
          for (let c = columnStart; c <= columnEnd; c++) {
            const cell = table?.getDataAtCell(r, c)
            cells.push(cell)
          }
        }
        operationToPercentageSelectedCourierCells(cells, prop, selectedCellCoordCustomer.operation, tableName)
      }
    }
  }

  useEffect(() => {
    console.log('Table Rate Sheet Carriers', selectedCellCoordCustomer)
    if (selectedCellCoordCustomer !== null && selectedCellCoordCustomer?.applyOperation) {
      handleOperationOnPercentageSelectedCells()
    }
  }, [selectedCellCoordCustomer])

  return (
    <Stack>
      {loading ? (
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <CircularProgress />
        </Grid>
      ) : isRenderTableError ? (
        <CardCreate />
      ) : (
        <>
          {getTypeTableSelected(TypeTable.DISCOUNT) && (
            <HotTable
              ref={tablesRef['discount']}
              data={list}
              {...visualConfigurationHotTable}
              colHeaders={headers}
              stretchH={'all'}
              selectionMode='multiple'
              copyPaste
              readOnly={readOnly}
              beforeKeyDown={event => handleDeleteKeyPressed(event, 'discount', 'discountPrice')}
              beforeCopy={(data: any) => {
                handleCopyCells(data, 'discountPrice')
              }}
              beforePaste={(data: any, coords: any) => {
                setReadOnly(true)
                handlePasteCells(data, coords, tablesRef['discount'].current?.hotInstance, 'discount', 'discountPrice')
                // copyOnTableCell(data, coords, tablesRef['discount'].current?.hotInstance, 'discountPrice')
                setConfigBtnSave((config: any) => {
                  return { ...config, showBtn: true }
                })
              }}
              afterSelectionEnd={(row: number, column: number, row2: number, column2: number) => {
                if (row !== -1 && column !== -1 && row2 !== -1 && column2 !== -1) {
                  setShowBtnAddSubstract(true)
                  const coords = { rowStart: row, columnStart: column, rowEnd: row2, columnEnd: column2 }
                  setSelectedCellCoordinates(coords, false, 'discount', undefined, 'courier')
                }
              }}
              afterChange={(changes, source) => {
                changes?.forEach(([row, col, oldValue, newValue]) => {
                  if (newValue === null) {
                    const infoRow: InfoRow = { row, col: Number(col) }
                    handleCellValueChange(infoRow, 0, 'discount', 'discountPrice')
                  }
                })
              }}
              afterPaste={() => {
                setReadOnly(false)
              }}
              beforeAutofill={(data: any, sourceRange: any, targetRange: any, direction: string) => {
                beforeAutofillByTable('discount', targetRange)
              }}
              afterAutofill={(data: any, sourceRange: any, targetRange: any) => {
                afterAutofill('discount', 'discountPrice')
              }}
            >
              {headers.map((header: any, index: number) =>
                header === 'Weight' ? (
                  <HotColumn
                    key={index}
                    width={80}
                    title={'Weight'}
                    readOnly
                    renderer={(instance, td, row, col, prop, value, cellProperties) => {
                      if (col === 0) {
                        const conf = configurationColWeight(instance, td, row)
                        Handsontable.renderers.TextRenderer(instance, conf.td, row, col, prop, conf.val, cellProperties)
                      }
                    }}
                  />
                ) : (
                  <HotColumn
                    key={index}
                    width={150}
                    title={header}
                    renderer={(instance, td, row, col, prop, value, cellProperties) => {
                      if (col !== 0) {
                        const cell = changeEditedCellStyle(value, theme, td, 'discount')
                        cell.style.textAlign = 'center'
                        cell.style.verticalAlign = 'middle'
                        Handsontable.renderers.TextRenderer(
                          instance,
                          cell,
                          row,
                          col,
                          prop,
                          value?.discountPrice,
                          cellProperties
                        )
                      }
                    }}
                  >
                    <EditorComponent
                      hot-editor
                      editorColumnScope={0}
                      onValueChange={(infoRow, newValue) => {
                        logger.log('New value discount :', newValue)
                        handleCellValueChange(infoRow, newValue, 'discount', 'discountPrice')
                      }}
                    />
                  </HotColumn>
                )
              )}
            </HotTable>
          )}

          {/* Markup */}
          {getTypeTableSelected(TypeTable.MARKUP) &&
            (notFoundCustomerCourierRate ? (
              <NotFoundCard />
            ) : (
              <HotTable
                ref={tablesRef['markup']}
                data={list}
                colHeaders={headers}
                stretchH={'all'}
                {...visualConfigurationHotTable}
                {...headerManagment}
              >
                {headers.map((header: any, index: number) =>
                  header === 'Weight' ? (
                    <HotColumn
                      key={index}
                      width={80}
                      title={'Weight'}
                      readOnly
                      // disableVisualSelection
                      renderer={(instance, td, row, col, prop, value, cellProperties) => {
                        if (col === 0) {
                          const conf = configurationColWeight(instance, td, row)
                          Handsontable.renderers.TextRenderer(
                            instance,
                            conf.td,
                            row,
                            col,
                            prop,
                            conf.val,
                            cellProperties
                          )
                        }
                      }}
                    />
                  ) : (
                    <HotColumn
                      key={index}
                      width={150}
                      title={header}
                      readOnly
                      renderer={(instance, td, row, col, prop, value, cellProperties) => {
                        if (col !== 0) cellRendererBaseRates(instance, td, row, col, prop, value)
                      }}
                    />
                  )
                )}
              </HotTable>
            ))}

          {/* Set Rate */}
          {getTypeTableSelected(TypeTable.SETRATE) && (
            <HotTable
              data={list}
              {...visualConfigurationHotTable}
              colHeaders={headers}
              stretchH={'all'}
              ref={tablesRef['setrate']}
              selectionMode='multiple'
              copyPaste
              beforeCopy={(data: any) => {
                handleCopyCells(data, 'basePrice')
              }}
              beforeKeyDown={event => handleDeleteKeyPressed(event, 'setrate', 'basePrice')}
              beforePaste={(data: any, coords: any) => {
                setReadOnly(true)
                handlePasteCells(data, coords, tablesRef['setrate'].current?.hotInstance, 'setrate', 'basePrice')
                // copyOnTableCell(data, coords, tablesRef['discount'].current?.hotInstance, 'discountPrice')
                setConfigBtnSave((config: any) => {
                  return { ...config, showBtn: true }
                })
              }}
              beforeAutofill={(data: any, sourceRange: any, targetRange: any, direction: string) => {
                beforeAutofillByTable('setrate', targetRange)
              }}
              afterChange={(changes, source) => {
                changes?.forEach(([row, col, oldValue, newValue]) => {
                  if (newValue === null) {
                    const infoRow: InfoRow = { row, col: Number(col) }
                    handleCellValueChange(infoRow, 0, 'discount', 'discountPrice')
                  }
                })
              }}
              afterAutofill={(data: any, sourceRange: any, targetRange: any) => {
                afterAutofill('setrate', 'basePrice')
              }}
            >
              {headers.map((header: any, index: number) =>
                header === 'Weight' ? (
                  <HotColumn
                    key={index}
                    width={80}
                    title={'Weight'}
                    readOnly
                    renderer={(instance, td, row, col, prop, value, cellProperties) => {
                      if (col === 0) {
                        const conf = configurationColWeight(instance, td, row)
                        Handsontable.renderers.TextRenderer(instance, conf.td, row, col, prop, conf.val, cellProperties)
                      }
                    }}
                  />
                ) : (
                  <HotColumn
                    key={index}
                    width={150}
                    title={header}
                    renderer={(instance, td, row, col, prop, value, cellProperties) => {
                      if (col !== 0) {
                        const cell = changeEditedCellStyle(value, theme, td, 'setrate')
                        cell.style.textAlign = 'center'
                        cell.style.verticalAlign = 'middle'
                        Handsontable.renderers.TextRenderer(
                          instance,
                          cell,
                          row,
                          col,
                          prop,
                          value?.basePrice || 0,
                          cellProperties
                        )
                      }
                    }}
                  >
                    <EditorComponent
                      hot-editor
                      editorColumnScope={0}
                      onValueChange={(infoRow, newValue) =>
                        handleCellValueChange(infoRow, newValue, 'setrate', 'basePrice')
                      }
                    />
                  </HotColumn>
                )
              )}
            </HotTable>
          )}
        </>
      )}
    </Stack>
  )
}

export default TableRateSheetCarriers

import { useEffect, useState } from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import CustomChip from 'src/@core/components/mui/chip'
import { postReportCustomerServices } from 'src/@http/reports'
import { CustomerRateReportDto } from 'src/types/models/ReportCustomerSerivces'
import { logger } from 'src/utils/Logger'
import reportsStore from 'src/zustand/reports'

import CustomToolbarAdminGrid from '../generic/CustomToolbarAdminGrid'

import CarrierSelectorReport from './CarrierSelectorReport'
import ReportServiceFilter from './ReportServiceFilter'

interface CellType {
  row: CustomerRateReportDto
}

const staticColumns = [
  {
    flex: 1,
    field: 'vchCustomerName',
    headerName: 'Customer',
    minWidth: 150
  },
  {
    flex: 1,
    field: 'vchCarrierName',
    headerName: 'Courier',
    minWidth: 150
  },
  {
    flex: 1,
    field: 'vchServiceName',
    headerName: 'Service',
    minWidth: 250
  },
  {
    flex: 1,
    field: 'rate',
    headerName: 'Rate',
    minWidth: 500,
    flexWrap: 'wrap',
    maxWidth: 500,
    display: 'flex',
    renderCell: ({ row }: CellType) => (
      <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', maxWidth: 500 }}>
        {row.rate
          ?.split(',')
          .reduce((acc: JSX.Element[][], rate, index) => {
            if (index % 2 === 0) {
              acc.push([])
            }
            acc[acc.length - 1].push(
              <CustomChip key={index} label={rate.trim()} style={{ margin: '4px', color: 'white' }} color='primary' />
            )

            return acc
          }, [])
          .map((chipGroup, groupIndex) => (
            <div key={groupIndex} style={{ display: 'flex', flexWrap: 'wrap', maxWidth: 500 }}>
              {chipGroup}
            </div>
          ))}
      </div>
    )
  }
]

const ReportServiceSummary = () => {
  const [processing, setProcessing] = useState(false)
  const [cols] = useState<GridColumns | null>(staticColumns)
  const [servicesReport, setServicesReport] = useState<CustomerRateReportDto[] | []>([])
  const { carrierName, customerName, setCarrierName, setCustomerName } = reportsStore()

  useEffect(() => {
    async function fetchData() {
      setProcessing(true)
      try {
        const reportData = await postReportCustomerServices(customerName, carrierName)
        setServicesReport(reportData)
        setProcessing(false)
      } catch (error) {
        setServicesReport([])
        setProcessing(false)
      }
    }

    fetchData()
  }, [carrierName, customerName])

  useEffect(() => {
    setCarrierName(null)
    setCustomerName(null)
  }, [])

  return (
    <Card sx={{ mt: 3 }}>
      <CardHeader title='Rate Summary' />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <CarrierSelectorReport onSelectCarrier={carrier => logger.log('Customer en padre:', carrier)} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReportServiceFilter onSelectCustomer={customer => logger.log('Customer en padre:', customer)} />
          </Grid>
        </Grid>
      </CardContent>
      <DataGrid
        checkboxSelection
        disableSelectionOnClick
        autoHeight
        pagination
        loading={processing}
        pageSize={10}
        getRowHeight={() => 'auto'}
        rows={servicesReport.map((row, index) => ({ ...row, id: index + 1 }))}
        columns={cols || []}
        components={{ Toolbar: CustomToolbarAdminGrid }}
      />
    </Card>
  )
}

export default ReportServiceSummary

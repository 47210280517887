import { useEffect } from 'react'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import { Box, Button, Card, Grid, Typography, useTheme } from '@mui/material'
// import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
// import { Card } from '@mui/material/'
// import am4geodata_usaLow from "@amcharts/amcharts4-geodata/usaLow";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const SpendAmountByYearAndMounth = () => {
  const theme = useTheme()

  /* Chart code */

  useEffect(() => {
    const data = [
      {
        date: '2012-01-01',
        distance: 227,
        townName: 'New York',
        townSize: 12,
        latitude: 40.71,
        duration: 408
      },
      {
        date: '2012-01-02',
        distance: 371,
        townName: 'Washington',
        townSize: 7,
        latitude: 38.89,
        duration: 482
      },
      {
        date: '2012-01-03',
        distance: 433,
        townName: 'Wilmington',
        townSize: 3,
        latitude: 34.22,
        duration: 562
      },
      {
        date: '2012-01-04',
        distance: 345,
        townName: 'Jacksonville',
        townSize: 3.5,
        latitude: 30.35,
        duration: 379
      },
      {
        date: '2012-01-05',
        distance: 480,
        townName: 'Miami',
        townSize: 5,
        latitude: 25.83,
        duration: 501
      },
      {
        date: '2012-01-06',
        distance: 386,
        townName: 'Tallahassee',
        townSize: 3.5,
        latitude: 30.46,
        duration: 443
      },
      {
        date: '2012-01-07',
        distance: 348,
        townName: 'New Orleans',
        townSize: 5,
        latitude: 29.94,
        duration: 405
      },
      {
        date: '2012-01-08',
        distance: 238,
        townName: 'Houston',
        townSize: 8,
        latitude: 29.76,
        duration: 309
      },
      {
        date: '2012-01-09',
        distance: 218,
        townName: 'Dalas',
        townSize: 8,
        latitude: 32.8,
        duration: 287
      },
      {
        date: '2012-01-10',
        distance: 349,
        townName: 'Oklahoma City',
        townSize: 5,
        latitude: 35.49,
        duration: 485
      },
      {
        date: '2012-01-11',
        distance: 603,
        townName: 'Kansas City',
        townSize: 5,
        latitude: 39.1,
        duration: 890
      },
      {
        date: '2012-01-12',
        distance: 534,
        townName: 'Denver',
        townSize: 9,
        latitude: 39.74,
        duration: 810
      },
      {
        date: '2012-01-13',
        townName: 'Salt Lake City',
        townSize: 6,
        distance: 425,
        duration: 670,
        latitude: 40.75,
        dashLength: 8,
        alpha: 0.4
      },
      {
        date: '2012-01-14',
        latitude: 36.1,
        duration: 470,
        townName: 'Las Vegas'
      },
      {
        date: '2012-01-15'
      },
      {
        date: '2012-01-16'
      },
      {
        date: '2012-01-17'
      }
    ]

    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    const root = am5.Root.new('chart-bar')

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)])

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart: any = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelY: 'none'
      })
    )

    chart.zoomOutButton.set('forceHidden', true)

    chart.get('colors').set('step', 2)

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'day', count: 1 },
        renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 50 }),
        tooltip: am5.Tooltip.new(root, {})
      })
    )

    const distanceAxisRenderer = am5xy.AxisRendererY.new(root, {})
    distanceAxisRenderer.grid.template.set('forceHidden', true)
    const distanceAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: distanceAxisRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    )

    const latitudeAxisRenderer = am5xy.AxisRendererY.new(root, {})
    latitudeAxisRenderer.grid.template.set('forceHidden', true)
    const latitudeAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: latitudeAxisRenderer,
        forceHidden: true
      })
    )

    const durationAxisRenderer = am5xy.AxisRendererY.new(root, {
      opposite: true
    })
    durationAxisRenderer.grid.template.set('forceHidden', true)
    const durationAxis = chart.yAxes.push(
      am5xy.DurationAxis.new(root, {
        baseUnit: 'minute',
        renderer: durationAxisRenderer,
        extraMax: 0.3
      })
    )
    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    const distanceSeries = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: distanceAxis,
        valueYField: 'distance',
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root, {
          labelText: '{valueY} miles'
        })
      })
    )
    distanceSeries.set('fill', am5.color(theme?.palette?.customColors?.terciary ?? '#000000'))
    distanceSeries.data.processor = am5.DataProcessor.new(root, {
      dateFields: ['date'],
      dateFormat: 'yyyy-MM-dd'
    })

    const latitudeSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        xAxis: xAxis,
        yAxis: latitudeAxis,
        valueYField: 'latitude',
        valueXField: 'date',
        stroke: am5.color(theme?.palette?.customColors?.main ?? '#000000'),
        tooltip: am5.Tooltip.new(root, {
          labelText: 'latitude: {valueY} ({townName})'
        })
      })
    )

    latitudeSeries.strokes.template.setAll({ strokeWidth: 2 })

    // Add circle bullet
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
    latitudeSeries.bullets.push(() => {
      const graphics = am5.Circle.new(root, {
        strokeWidth: 2,
        radius: 5,
        stroke: latitudeSeries.get('stroke'),
        fill: root.interfaceColors.get('background')
      })

      graphics.adapters.add('radius', (radius, target: any) => {
        return target.dataItem.dataContext.townSize
      })

      return am5.Bullet.new(root, {
        sprite: graphics
      })
    })

    const durationSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        xAxis: xAxis,
        yAxis: durationAxis,
        valueYField: 'duration',
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root, {
          labelText: 'duration: {valueY.formatDuration()}'
        })
      })
    )

    durationSeries.strokes.template.setAll({ strokeWidth: 2 })

    // Add circle bullet
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
    durationSeries.bullets.push(function () {
      const graphics = am5.Rectangle.new(root, {
        width: 10,
        height: 10,
        centerX: am5.p50,
        centerY: am5.p50,
        fill: durationSeries.get('stroke')
      })

      return am5.Bullet.new(root, {
        sprite: graphics
      })
    })

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        yAxis: distanceAxis
      })
    )

    distanceSeries.data.setAll(data)
    latitudeSeries.data.setAll(data)
    durationSeries.data.setAll(data)
    xAxis.data.setAll(data)

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    distanceSeries.appear(1000)
    chart.appear(1000, 100)

    return () => root.dispose()
  }, [])

  return (
    <Card>
      <Grid container direction='row' justifyContent='space-between' sx={{ p: 2 }}>
        <Grid item>
          <Typography variant='h6'>Spend Amount by Year and Month</Typography>
        </Grid>
        <Grid item>
          <Button sx={{ m: 1 }} variant='contained' size='small'>
          <Box sx={{ color: 'white' }}>2020</Box>
          </Button>
          <Button sx={{ m: 1 }} color='secondary' variant='contained' size='small'>
          <Box sx={{ color: 'white' }}>2021</Box>
          </Button>
          <Button sx={{ m: 1 }} color='secondary' variant='contained' size='small'>
          <Box sx={{ color: 'white' }}>2022</Box>
          </Button>
        </Grid>
      </Grid>

      <div id='chart-bar' style={{ width: '100%', height: '305px' }}></div>
    </Card>
  )
}

export default SpendAmountByYearAndMounth

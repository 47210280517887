import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
// import { useLocation } from 'react-router-dom'
import { Button, FormControl, Grid, InputLabel, MenuItem } from '@mui/material'
import Select from '@mui/material/Select'
import { getServicesForCarrier } from 'src/@http/carriers'
import { ServicesGetModel } from 'src/types/models/CarrierModel'
import { logger } from 'src/utils/Logger'
import appStore from 'src/zustand/app'
import carrierStore from 'src/zustand/carrier'

interface ShippingSelectorProps {
  onlyActive?: boolean
  handleSelected: (sm: any) => any
  carrierId: number
  type?: string
}

const SelectShipMethods = ({ onlyActive, handleSelected, carrierId }: ShippingSelectorProps) => {
  const [shippingMethod, setShippingMethods] = useState<ServicesGetModel[]>([])
  const [shippingMethodSelected, setShippingMethodsSelected] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hideSelectCourier, setHideSelectCourier] = useState<boolean>(false)
  const { carrierSelected } = carrierStore()
  const { setServiceEditorCarrierId } = appStore()

  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    setIsLoading(true)
    const fetch = async () => {
      try {
        const response = await getServicesForCarrier({
          intCarrierId: carrierId,
          status: onlyActive ? 'Enabled' : 'Todos'
        })
        if (response) setShippingMethods(response)
        if (response.length === 0) setHideSelectCourier(true)
        else {
          setHideSelectCourier(false)
          setShippingMethodsSelected(response[0])
          handleSelected(response[0])
        }
        setIsLoading(false)
      } catch (err: any) {
        logger.error(err)
        setIsLoading(false)
        // console.log('Error', err)
      }
    }
    if (carrierId !== 0) fetch()
  }, [carrierId])

  const handleShipMethod = (e: any) => {
    setShippingMethodsSelected(e.target.value)
    handleSelected(e.target.value)
  }

  return (
    <>
      {!hideSelectCourier ? (
        <FormControl fullWidth>
          <InputLabel id='sm-label'>{isLoading ? `${t('SEARCHING_SHIP_METHODS')}...` : `${t('SHIP_METHOD')}`}</InputLabel>
          <Select
            size='small'
            label={isLoading ? `${t('SEARCHING_SHIP_METHODS')}...` : `${t('SHIP_METHOD')}`}
            value={shippingMethodSelected || ''}
            labelId='sm-label'
            disabled={shippingMethod.length == 0}
            onChange={handleShipMethod}
            MenuProps={{ style: { maxHeight: 350 } }}
          >
            {shippingMethod.map((shipMethod: any, i: number) => (
              <MenuItem key={i} value={shipMethod}>
                {shipMethod.vchName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Grid sx={{ m: 2 }}>
          <Button
            fullWidth
            variant='contained'
            onClick={() => {
              setServiceEditorCarrierId(carrierSelected!.id)
              navigate('/carriers/services-editor', { state: { data: carrierSelected, type: 'carriers' } })
            }}
          >
            {t('ADD_SERVICE')}
          </Button>
        </Grid>
      )}
    </>
  )
}

export default SelectShipMethods

import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoxProps, Card, Grid, Stack, Typography } from '@mui/material'
import { getTotalsInvoices } from 'src/@http/carriers'
import { CarrierInvoiceResumeDto } from 'src/types/models/CarrierModel'
import appStore from 'src/zustand/app'

type InfoSection = {
  id?: number
  title: string
  subtitle?: string | number
  amount: number | string
  increase?: boolean
}

type PropSummaryWidget = {
  styleBox?: BoxProps['sx']
  children?: ReactNode
}

const SummaryWidget = ({ styleBox, children }: PropSummaryWidget) => {
  const { t } = useTranslation()

  const { invoiceId } = appStore(state => ({
    invoiceId: state.invoiceId
  }))
  const [summaryData, setSummaryData] = useState<CarrierInvoiceResumeDto>({
    intBatchId: 0,
    totalCharge: 0,
    totalInvoice: 0,
    totalChargePaid: 0,
    totalInvoicePaid: 0
  })
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getTotalsInvoices(invoiceId, 1)
        setSummaryData(response)
      } catch (error) {}
    }

    fetchData()
  }, [])

  const dataSummaryWidget = [
    {
      id: 1,
      title: 'Total Invoiced',
      subtitle: summaryData.totalInvoice.toString(),
      amount: summaryData.totalCharge.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
      increase: true
    },
    {
      id: 2,
      title: 'Total Paid',
      amount: summaryData.totalChargePaid.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
      subtitle: summaryData.totalInvoicePaid.toString(),
      increase: true
    },
    {
      id: 3,
      title: 'Total Pending',
      subtitle: (summaryData.totalInvoice - summaryData.totalInvoicePaid).toString(),
      amount: (summaryData.totalCharge - summaryData.totalChargePaid).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      }),
      increase: false
    }
  ]

  return (
    <Grid container sx={styleBox}>
      <Grid item xs={12} lg={12}>
        <Card sx={{ py: 5, borderRadius: 2, xs: { px: 2 } }}>
          <Grid container justifyContent='center' alignItems='center' sx={{ minHeight: '100%' }} rowGap={3}>
            {dataSummaryWidget.map((item, i) => (
              <Grid
                key={item.id || i}
                item
                flexDirection='column'
                xs={12}
                lg={(!children ? 12 : 10) / dataSummaryWidget.length}
                md={(!children ? 12 : 10) / dataSummaryWidget.length}
                sx={{
                  px: 10,
                  borderRight: {
                    lg: dataSummaryWidget.length !== item.id ? '1px solid #d9dbda' : '',
                    md: dataSummaryWidget.length !== item.id ? '1px solid #d9dbda' : ''
                  }
                }}
              >
                <Stack flexDirection='row' justifyContent='space-between'>
                  <Typography variant='body1'>{item.title}</Typography>
                  <Typography variant='body1' color={item.increase ? 'success.main' : 'error.main'}>
                    <span style={{ marginRight: '5px' }}>{t('ABBREVIATED_AMOUNT')}</span>
                    {item.subtitle}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant='h5'>{item.amount}</Typography>
                </Stack>
              </Grid>
            ))}
            {children ? <Grid item>{children}</Grid> : null}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default SummaryWidget

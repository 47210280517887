import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { Card, Grid, IconButton, Stack, Typography } from '@mui/material'
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid'
import CustomAvatar from 'src/@core/components/mui/avatar'
import CustomChip from 'src/@core/components/mui/chip'
import { getOrders } from 'src/@http/orders'
import { OrderDto } from 'src/types/models/OrderModel'
import { formatOrderDate } from 'src/utils/formatDate'
import appStore from 'src/zustand/app'

import WrapperSecurity from '../generic/WrapperSeurityObject'

interface Row {
  check: boolean
  id: number
  carrier: string
  client: {
    avatar: string
    name: string
    email: string
  }
  total: number
  issuedDate: Date
  status: {
    id: number
    text: string
  }
}

interface CellType {
  row: Row
}

const RowOptions = ({ id }: { id: number }) => {
  const { setOrderId } = appStore(state => ({
    setOrderId: state.setOrderId
  }))

  return (
    <Stack display='flex' flexDirection='row' width='100%' justifyContent='space-around'>
      <IconButton size='small'>
        <Icon icon='material-symbols:delete-outline' />
      </IconButton>
      <Link to='/order-editor/preview'>
        <IconButton size='small' onClick={() => setOrderId(id)}>
          <Icon icon='mdi:eye' />
        </IconButton>
      </Link>
      <WrapperSecurity securityKey='button_edit_order_lp'>
        <Link to={`/client-labels/shipp/new?orderId=${id}`}>
          <IconButton size='small'>
            <Icon icon='mdi:file-document-edit' />
          </IconButton>
        </Link>
      </WrapperSecurity>
      <IconButton size='small'>
        <Icon icon='mdi:dots-vertical' />
      </IconButton>
    </Stack>
  )
}
const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'Order Id',
    flex: 1,
    maxWidth: 90,
    renderCell: (params: CellType) => (
      <Typography sx={{ fontSize: { xs: '0.9rem', md: '1rem' } }} color='primary'>
        #{params.row.id}
      </Typography>
    )
  },
  {
    field: 'carrier',
    headerName: 'Courier',
    flex: 1,
    maxWidth: 120
  },
  {
    field: 'client',
    headerName: 'client',
    flex: 1,
    minWidth: 200,
    renderCell: (params: CellType) => (
      <Grid container columnGap={3} flexDirection='row' justifyContent='flex-start'>
        <Grid
          sx={{ display: { xs: 'none', md: 'flex', lg: 'flex' }, maxWidth: '40px !important' }}
          item
          xs={6}
          lg={2}
          justifyContent='center'
          alignItems='center'
        >
          <CustomAvatar src={params.row.client.avatar} alt='avatar' sx={{ width: 30, height: 30 }} />
        </Grid>
        <Grid item xs={6} lg={8} display='flex' flexDirection='column' justifyContent='flex-start'>
          <Typography variant='subtitle2' fontWeight={600}>
            {params.row.client.name}
          </Typography>
          <Typography variant='subtitle2' fontWeight={300}>
            {params.row.client.email}
          </Typography>
        </Grid>
      </Grid>
    )
  },
  {
    field: 'total',
    headerName: 'Total',
    flex: 1,
    width: 130,
    align: 'right',
    renderCell: (params: CellType) => (
      <Typography
        sx={{
          fontSize: { xs: '0.9rem', md: '1rem' },
          color: 'primary',
          textAlign: 'right'
        }}
      >
        {`$${params.row.total}`}
      </Typography>
    )
  },
  {
    field: 'issuedDate',
    headerName: 'Issued Date',
    flex: 1,
    width: 120
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    width: 130,
    renderCell: ({ row }: GridCellParams<OrderDto>) => (
      <Stack display='flex' flexDirection='row' justifyContent='center'>
        <CustomChip label={row.status.text} skin='light' color={row.status.color} />
      </Stack>
    )
  },
  {
    field: '',
    headerName: 'Action',
    maxWidth: 250,
    flex: 1,
    headerAlign: 'center',
    renderCell: ({ row }: CellType) => <RowOptions id={row.id} />
  }
]

function TableOrderEditor() {
  const [cols, setCols] = useState<GridColumns | null>(null)
  const [dataPaginationGrid, setDataPaginationGrid] = useState({
    loading: true,
    rows: [],
    rowCount: 0,
    rowsPerPageOptions: [5, 10, 15],
    pageSize: 50,
    page: 1
  })
  const updateDataPaginationGrid = (k: any, v: any) => setDataPaginationGrid(prev => ({ ...prev, [k]: v }))
  const { customerName, orderId, startDate, endDate } = appStore(state => ({
    customerName: state.orderFilters.customerName,
    orderId: state.orderFilters.orderId,
    startDate: state.orderFilters.startDate,
    endDate: state.orderFilters.endDate
  }))

  const paramsV2 = appStore((store: { paramsV2: any }) => {
    console.log(store.paramsV2)
    
    return store.paramsV2
  })

  useEffect(() => {
    const fetch = async () => {
      updateDataPaginationGrid('loading', true)
      try {
        const response = await getOrders(
          dataPaginationGrid.pageSize,
          dataPaginationGrid.page,
          customerName,
          orderId,
          startDate,
          endDate
        )
        if (response) {
          const mappedRows = response.list.map(order => ({
            check: false,
            id: order.bigOrderId,
            carrier: order.vchCarrierCustomerName,
            client: {
              avatar: '',
              name: order.vchCustomerName,
              email: order.vchMail
            },
            total: `${order.decOrderTotal.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}`,
            issuedDate: formatOrderDate(order.dtmOrderDate),
            status: {
              id: order.intStatusId,
              text: order.vchStatusName,
              color: order.vchStatusColor
            }
          }))
          updateDataPaginationGrid('rows', mappedRows)
          updateDataPaginationGrid('rowCount', response.pagination.numberOfRecords)
        }
      } catch (error) {
        // console.log(error)
      } finally {
        updateDataPaginationGrid('loading', false)
      }
    }
    fetch()
    setCols(columns)
  }, [dataPaginationGrid.page, dataPaginationGrid.pageSize, customerName, orderId, startDate, endDate, paramsV2.ShopId, paramsV2.CustomerId])

  return (
    <Card>
      <DataGrid
        checkboxSelection
        disableSelectionOnClick
        autoHeight
        pagination
        paginationMode='server'
        loading={dataPaginationGrid.loading}
        rowCount={dataPaginationGrid.rowCount}
        page={dataPaginationGrid.page - 1}
        pageSize={dataPaginationGrid.pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        rows={dataPaginationGrid.rows}
        columns={cols || []}
        getRowHeight={() => 51}
        onPageChange={(page: any) => {
          updateDataPaginationGrid('page', page + 1)
        }}
        onPageSizeChange={(pageSize: any) => {
          updateDataPaginationGrid('page', 1)
          updateDataPaginationGrid('pageSize', pageSize)
        }}
      />
    </Card>
  )
}

export default TableOrderEditor

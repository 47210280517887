import { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { getShops } from 'src/@http/shop'
import { ShopModel } from 'src/types/models/ShopModels'
import { logger } from 'src/utils/Logger'
import reportsStore from 'src/zustand/reports'

interface AutocompleteCustomerProps {
  onSelectCustomer: (customerId: string) => void
}

const ReportServiceFilter = ({ onSelectCustomer }: AutocompleteCustomerProps) => {
  const [customerList, setCustomerList] = useState<ShopModel[]>([])
  const [value, setValue] = useState('')

  const { setCustomerName } = reportsStore()

  const handleChange = (event: any, value: string | null) => {
    logger.log('Value en Hijo:', value)
    logger.log('Event en Hijo:', event.target.value)

    setCustomerName(value || '')

    onSelectCustomer(value || '')
    setValue(value || '')
  }

  useEffect(() => {
    setCustomerName('')
    getShops()
      .then((data: any) => {
        setCustomerList(data)
      })
      .catch((error: any) => {
        console.error('Error fetching customer data:', error)
      })
  }, [setCustomerName])

  return (
    <Autocomplete
      fullWidth
      onChange={handleChange}
      value={value}
      options={customerList.map(customer => customer.vchName)}
      id='autocomplete-disableCloseOnSelect'
      renderInput={params => <TextField {...params} label='Customer' />}
    />
  )
}

export default ReportServiceFilter

import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Icon } from '@iconify/react'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Card, CardContent, CardHeader, Grid, TextField, Typography } from '@mui/material'
import { addNewParent, editParent } from 'src/@http/customers'
import { RtaUploadFile, uploadFile, UploadFileType } from 'src/@http/uploadFile'
import { ParentModel } from 'src/types/models/ParentModel'
import { showIfValidImage } from 'src/utils/misc'
import FileUploadImageSingle from 'src/views/forms/FileUploadImageSingle'
import companiesStore from 'src/zustand/companies'
import profileStore from 'src/zustand/profile'

import BoxPreviewImage from '../components/generic/BoxPreviewImage'
import ColorPicker from '../components/generic/ColorPicker'
import { DialogExpose, DialogGeneric } from '../components/generic/DialogGeneric'

type ColorSelected = Record<string, string | undefined>

type ExtraValues = {
  [key: string]: any
}

const defaultValues: ParentModel = {
  intCustomerId: 0,
  vchName: '',
  vchSourceId: '',
  intStatusId: 0,
  vchStatusName: '',
  intCreatedUserId: 0,
  intUpdatedUserId: 0,
  id: 0,
  dtmCreatedDate: new Date().toISOString(),
  dtmUpdatedDate: new Date().toISOString(),
  vchLogoPath: null,
  vchPrimaryColor: null,
  vchSecondaryColor: null
}

const ParentAddOrEdit = () => {
  const modalLogoUploader = useRef<DialogExpose>(null)

  const [previewEditMode, setPreviewEditMode] = useState(true)
  const { companySelected, setCompanySelected, getCompanyById } = companiesStore()
  const [loading, setLoading] = useState<boolean>(false)
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const title = getTitleByMode(companySelected)
  const form = useForm<ParentModel & ExtraValues>({ defaultValues: defaultValues })
  const { profile } = profileStore()

  // Extra data
  const [fileLogo, setFileLogo] = useState<File | string | null>(null)
  const [themeCustomerSelected, setThemeCustomerSelected] = useState<ColorSelected | null>(null)
  const [extraValues] = useState({
    intStatusId: 0,
    vchStatusName: '',
    intCreatedUserId: profile?.id,
    intUpdatedUserId: profile?.id,
    themeColorsRequired: ['Primary', 'Secondary'],
    printServiceURL: '',
    printerToken: ''
  })

  useEffect(() => {
    if (id === 'add') setCompanySelected(null)
    else getCompanyById(Number(id))
  }, [getCompanyById, id, setCompanySelected])

  // FORM
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors }
  } = form

  const readOnlyInput = { readOnly: id !== 'add' && previewEditMode }
  const labelShrinkInput = { shrink: true }
  const changeEditMode = () => setPreviewEditMode(false)

  useEffect(() => {
    if (id === 'add') {
      setPreviewEditMode(false)
      extraValues.intStatusId = 1
      extraValues.vchStatusName = 'Enabled'
    } else if (companySelected) {
      setPreviewEditMode(true)
      setValue('intCustomerId', companySelected.intCustomerId)
      setValue('vchName', companySelected.vchName)
      setValue('vchSourceId', companySelected.vchSourceId)
      setValue('intCreatedUserId', companySelected.intCreatedUserId)
      setValue('intStatusId', companySelected.intStatusId)
      setValue('vchStatusName', companySelected.vchStatusName)
      extraValues.vchStatusName = companySelected.vchStatusName
      setFileLogo(companySelected.vchLogoPath)
      setValue('vchLogoPath', companySelected.vchLogoPath)
      setValue('vchPrimaryColor', companySelected.vchPrimaryColor)
      setValue('vchSecondaryColor', companySelected.vchSecondaryColor)

      // Show in input color
      extraValues.themeColorsRequired.forEach(color => {
        const selected = companySelected as any
        setThemeCustomerSelected(prev => {
          return {
            ...prev,
            [color]: selected[`vch${color}Color`]
          }
        })
      })
    }
  }, [companySelected, extraValues, id, setValue])

  async function uploadLogo() {
    try {
      if (fileLogo) {
        const res = (await uploadFile(fileLogo as File, UploadFileType.Customer)) as RtaUploadFile

        return res.virtualPathFile
      } else {
        throw new Error('File not exist')
      }
    } catch (err: any) {
      throw new Error(err)
    }
  }

  async function addParent(data: any) {
    try {
      delete data.id
      await addNewParent(data)
      setLoading(false)

      return 'Parent added successfully'
    } catch (err: any) {
      throw new Error(err)
    }
  }

  const onSubmit = async (data: any) => {
    setLoading(true)
    setPreviewEditMode(false)
    data.intStatusId = extraValues.intStatusId
    data.vchStatusName = extraValues.vchStatusName
    data.intCreatedUserId = extraValues.intCreatedUserId
    data.intUpdatedUserId = extraValues.intUpdatedUserId

    const settings = {
      printServiceURL: extraValues.printServiceURL,
      printerToken: extraValues.printerToken
    }

    if (id === 'add') {
      uploadLogo()
        .then(uploadedLogo => {
          data.vchLogoPath = uploadedLogo

          return addParent(data)
        })
        .then(() => navigate('/company-editor'))
        .catch(error => {
          toast.error(error.message)
          setLoading(false)
        })
    } else {
      data.id = data.intCustomerId
      console.log('Data :', data)

      // If the logo was not modified, the service is not called.
      if (typeof fileLogo === 'string' && fileLogo === getValues('vchLogoPath')) {
        editParent(data)
          .then(() => {
            setLoading(false)
            setTimeout(() => {
              navigate('/company-editor')
            }, 2000)
          })
          .catch(error => {
            toast.error(error.message)
            setLoading(false)
          })
      } else {
        uploadLogo()
          .then(uploadedLogo => {
            data.vchLogoPath = uploadedLogo

            return editParent(data)
          })
          .then(() => {
            setLoading(false)
            setTimeout(() => {
              navigate('/company-editor')
            }, 2000)
          })
          .catch(error => {
            toast.error(error.message)
            setLoading(false)
          })
      }
    }
  }

  // Components

  const ManageUploadLogo = () => {
    return (
      <>
        {fileLogo && showIfValidImage(fileLogo) && (
          <BoxPreviewImage
            image={fileLogo}
            heightBox={80}
            showActions={previewEditMode ? false : true}
            handleIconAction={(action: string) => {
              if (action === 'IconDelete') setFileLogo(null)
            }}
          />
        )}
        <Button
          fullWidth
          onClick={() => modalLogoUploader.current?.open()}
          variant='contained'
          disabled={id !== 'add' && previewEditMode}
        >
          <Typography color={'white'}>{t('UPLOAD_LOGO')}</Typography>
        </Button>
      </>
    )
  }

  const handleColorChange = (selectedColors: ColorSelected) => {
    extraValues.themeColorsRequired.forEach(color => {
      const prop = `vch${color}Color`
      setValue(prop, selectedColors[color])
    })
  }

  const SelectThemeColors = () => {
    return (
      <ColorPicker
        colorsSelected={themeCustomerSelected}
        colorNames={extraValues.themeColorsRequired}
        onChangeColor={handleColorChange}
        disabled={id !== 'add' && previewEditMode}
      />
    )
  }

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        {previewEditMode && (
          <Button
            variant='contained'
            style={{  color: 'white' }}
            startIcon={<Icon icon={'mdi:pencil'} />}
            fullWidth
            sx={{ mb: 5 }}
            data-cy="edit_form_customer"
            onClick={changeEditMode}
          >
            {t('EDIT')}
          </Button>
        )}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box>
            <Grid container spacing={2} sx={{ flexDirection: { xs: 'column', md: 'row' }, mb: { xs: 0, md: 2 } }}>
              {id !== 'add' && (
                <Grid item xs={12} md={4} sx={{ pb: { xs: 2, md: 0 } }}>
                  <TextField
                    type='number'
                    label={t('COMPANY_ID')}
                    fullWidth
                    {...register('intCustomerId')}
                    InputProps={readOnlyInput}
                    InputLabelProps={labelShrinkInput}
                    />
                </Grid>
              )}
              <Grid item xs={12} md={id !== 'add' ? 4 : 6} sx={{ pb: { xs: 2, md: 0 } }}>
                <TextField
                  label={t('NAME')}
                  data-cy="name_customer"
                  fullWidth
                  InputProps={readOnlyInput}
                  InputLabelProps={labelShrinkInput}
                  {...register('vchName', { required: 'Name is required' })}
                  error={!!errors.vchName}
                  helperText={errors.vchName?.message}
                />
              </Grid>
              <Grid item xs={12} md={id !== 'add' ? 4 : 6} sx={{ pb: { xs: 2, md: 0 } }}>
                <TextField
                  label={t('SOURCE_ID')}
                  data-cy="source_id_customer"
                  fullWidth
                  InputProps={readOnlyInput}
                  InputLabelProps={labelShrinkInput}
                  {...register('vchSourceId', { required: 'Source Id is required' })}
                  error={!!errors.vchName}
                  helperText={errors.vchName?.message}
                />
              </Grid>
              <Grid item xs={12} md={id !== 'add' ? 4 : 6} sx={{ pb: { xs: 2, md: 0 } }}>
                <TextField
                  label="Print Service URL"
                  fullWidth
                  data-cy="print_service_url_customer"
                  InputProps={readOnlyInput}
                  InputLabelProps={labelShrinkInput}
                  error={!!errors.vchName}
                  helperText={errors.vchName?.message}
                />
              </Grid>
              <Grid item xs={12} md={id !== 'add' ? 4 : 6} sx={{ pb: { xs: 2, md: 0 } }}>
                <TextField
                  label={"Printer Token"}
                  fullWidth
                  data-cy="print_token_url_customer"
                  InputProps={readOnlyInput}
                  InputLabelProps={labelShrinkInput}
                  error={!!errors.vchName}
                  helperText={errors.vchName?.message}
                />
              </Grid>

              {id === 'add' && (
                <>
                  <Grid item xs={12} md={id !== 'add' ? 4 : 6}>
                    <ManageUploadLogo />
                  </Grid>
                  <Grid item xs={12} md={id !== 'add' ? 4 : 6}>
                    <SelectThemeColors />
                  </Grid>
                </>
              )}
            </Grid>
            {id !== 'add' && (
              <Grid container sx={{ flex: { xs: 'column', md: 'row' } }}>
                <Grid item xs={12} md={4}>
                  <CustomGrid container>
                    <Grid item my={2} sx={{ pr: { xs: 0, md: 1 }, pb: 2 }}>
                      <TextField
                        label={t('STATUS')}
                        {...register('vchStatusName')}
                        fullWidth
                        InputProps={readOnlyInput}
                        InputLabelProps={labelShrinkInput}
                      />
                    </Grid>
                  </CustomGrid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomGrid container>
                    <Grid item my={2} sx={{ px: { xs: 0, md: 1 } }}>
                      <ManageUploadLogo />
                    </Grid>
                  </CustomGrid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomGrid container>
                    <Grid item my={2} sx={{ pl: { xs: 0, md: 1 }, pb: 2 }}>
                      <SelectThemeColors />
                    </Grid>
                  </CustomGrid>
                </Grid>
              </Grid>
            )}
          </Box>
          {!previewEditMode && (
            <Grid item xs={12} sm={12} sx={{ alignItems: 'center', mt: 4 }}>
              <LoadingButton
                type='submit'
                loading={loading}
                loadingPosition='start'
                startIcon={<Icon icon="material-symbols:save" color='transparent' />}
                variant='contained'
                color='primary'
                data-cy="confirm_form_customer"
                sx={{ minWidth: '194px', maxWidth: '194px', '& .MuiButton-startIcon': { display: 'none' } }}
                disabled={previewEditMode}
              >
                <Typography variant='body1' color='white'>
                  {t('SAVE')}
                </Typography>
              </LoadingButton>
            </Grid>
          )}
        </form>
      </CardContent>

      <DialogGeneric ref={modalLogoUploader} title={t('UPLOAD_LOGO')} styleBoxContent={{}}>
        <FileUploadImageSingle
          selectedImage={fileLogo as File}
          handleFileImageUploaded={(file: any) => setFileLogo(file)}
        />
      </DialogGeneric>
    </Card>
  )
}

// Created to reset any previously applied display styles
const CustomGrid = styled(Grid)(() => ({
  margin: 0,
  width: '100%',
  display: 'initial'
}))

const getTitleByMode = (companySelected: ParentModel | null) => {
  if (!companySelected) return 'Add New Customer'
  else return `Customer: ${companySelected.vchName}`
}

export default ParentAddOrEdit

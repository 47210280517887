// ** React Imports
import { ReactNode, useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
// ** MUI Imports
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { AxiosError } from 'axios'
import { Settings } from 'src/@core/context/settingsContext'
// ** Hook Import
import { useSettings } from 'src/@core/hooks/useSettings'
// ** Layout Imports
import Layout from 'src/@core/layouts/Layout'
import { axiosInstance } from 'src/@http/'
import HorizontalNavItems from 'src/navigation/horizontal'
// ** Navigation Imports
import VerticalNavItems from 'src/navigation/vertical'
// import { RootState } from 'src/store'
import { logoutClearProfile } from 'src/store/apps/profile'
import { logger } from 'src/utils/Logger'

// import appStore from 'src/zustand/app'
import HorizontalAppBarContent from './components/horizontal/AppBarContent'
// ** Component Import
import VerticalAppBarContent from './components/vertical/AppBarContent'

interface Props {
  children?: ReactNode
  contentHeightFixed?: boolean
}

const AppBrand = ({ settings }: { props: any; settings: Settings }) => {
  /* if(profile?.vchMail == 'admin@admin.com'){
    logo =  '/images/logo-elite.png'
  } else if(profile?.vchMail == 'giueomn@psnator.com') {
    logo  = '/images/logo-goat.png'
  } else {
    logo  = '/images/logo-test-2.png'
  } */

  const styleLogo = {
    maxHeight: '180px',
    minHeight: '20%',
    width: '80%',
    marginLeft: '0'
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
      <img src={settings.logoPath} alt='logo' style={styleLogo} />
    </Box>
  )
}

const UserLayout = ({ children, contentHeightFixed }: Props) => {
  // ** Hooks
  const { settings, saveSettings } = useSettings()
  const TOKEN = window.localStorage.getItem('token')
  // const theme = useTheme()

  // ** Vars for server side navigation
  // const { menuItems: verticalMenuItems } = ServerSideVerticalNavItems()
  // const { menuItems: horizontalMenuItems } = ServerSideHorizontalNavItems()

  /**
   *  The below variable will hide the current layout menu at given screen size.
   *  The menu will be accessible from the Hamburger icon only (Vertical Overlay Menu).
   *  You can change the screen size from which you want to hide the current layout menu.
   *  Please refer useMediaQuery() hook: https://mui.com/material-ui/react-use-media-query/,
   *  to know more about what values can be passed to this hook.
   *  ! Do not change this value unless you know what you are doing. It can break the template.
   */
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  if (hidden && settings.layout === 'horizontal') {
    settings.layout = 'vertical'
  }

  const navigate = useNavigate()

  useEffect(() => {
    if (!TOKEN) redirectToLogin()

    const interceptor = axiosInstance.interceptors.response.use(undefined, handleErrorResponse)

    return () => {
      axiosInstance.interceptors.response.eject(interceptor)
    }
  })

  useEffect(() => {
    console.log('set', settings)
  }, [settings])

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const redirectToLogin = () => {
    dispatch(logoutClearProfile())
    navigate('/login')
  }

  const handleErrorResponse = (error: AxiosError) => {
    if (error.response) {
      if (error.response.status === 401) {
        redirectToLogin()
      }
    } else if (error.request) {
      toast.error(t('ERROR_GET_RESPONSE'))
      logger.log('No se recibió respuesta del servidor:', error.request)
    } else {
      toast.error(t('ERROR_CONFIG_REQUEST'))
      logger.log('Error al configurar la solicitud:', error.message)
    }

    return Promise.reject(error)
  }

  // const onClick = () => window.history.back()

  return (
    <Layout
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      contentHeightFixed={contentHeightFixed}
      verticalLayoutProps={{
        navMenu: {
          navItems: VerticalNavItems(),
          branding: props => <AppBrand props={props} settings={settings} />
          // Uncomment the below line when using server-side menu in vertical layout and comment the above line
          // navItems: verticalMenuItems
        },
        appBar: {
          content: props => (
            <VerticalAppBarContent
              hidden={hidden}
              settings={settings}
              saveSettings={saveSettings}
              toggleNavVisibility={props.toggleNavVisibility}
            />
          )
        }
      }}
      {...(settings.layout === 'horizontal' && {
        horizontalLayoutProps: {
          navMenu: {
            navItems: HorizontalNavItems()

            // Uncomment the below line when using server-side menu in horizontal layout and comment the above line
            // navItems: horizontalMenuItems
          },
          appBar: {
            content: () => <HorizontalAppBarContent settings={settings} saveSettings={saveSettings} />
          }
        }
      })}
    >

      {children}

      <Outlet />
    </Layout>
  )
}

export default UserLayout

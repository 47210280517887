import { useTranslation } from 'react-i18next'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import PageHeader from 'src/@core/components/page-header'

import RegionList from './regionList'

const RegionManager = () => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <PageHeader
          title={<Typography variant='h5'>{t('REGION_MANAGER')}</Typography>}
          subtitle={<Typography variant='body2'>Regions list</Typography>}
        />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <RegionList />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default RegionManager

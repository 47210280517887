import { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { getShops } from 'src/@http/shop'
import { ShopModel } from 'src/types/models/ShopModels'
import { logger } from 'src/utils/Logger'
import appStore from 'src/zustand/app'

interface AutocompleteCustomerProps {
  onSelectCustomer: (customerId: string | { id: number; textName: string }) => void
  title?: string
  allInfoCustomer?: boolean
  size?: 'small' | 'medium'
}

const AutocompleteProps = ({ onSelectCustomer, title, allInfoCustomer, size }: AutocompleteCustomerProps) => {
  const [customerList, setCustomerList] = useState<ShopModel[]>([])
  const [value, setValue] = useState<ShopModel | null>(null)

  const { setCustomerName } = appStore(state => ({
    setCustomerName: state.setOrderFilterCustomerName
  }))

  const handleChange = (event: any, value: any | null) => {
    logger.log('Customer Selected:', value)
    if (!allInfoCustomer) {
      setCustomerName(value.vchName || '')
      onSelectCustomer(value.vchName || '')
      setValue(value.vchName || '')
    } else {
      setCustomerName(value || '')
      onSelectCustomer(value || '')
      setValue(value || '')
    }
  }

  useEffect(() => {
    setCustomerName('')
    getShops()
      .then(data => {
        setCustomerList(data)
      })
      .catch((error: any) => {
        console.error('Error fetching customer data:', error)
      })
  }, [setCustomerName])

  return (
    <Autocomplete
      fullWidth
      size={size || 'medium'}
      value={value}
      onChange={handleChange}
      options={customerList}
      getOptionLabel={(option: ShopModel) => option.vchName}
      id='autocomplete-disableCloseOnSelect'
      renderInput={params => <TextField {...params} label={title ? title : 'Client'} />}
    />
  )
}

export default AutocompleteProps

import { mountStoreDevtool } from 'simple-zustand-devtools'
import { getParentById, getParents } from 'src/@http/customers'
import { ParentModel } from 'src/types/models/ParentModel'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type State = {
  companies: ParentModel[]
  companySelected: ParentModel | null
  companyGlobal: ParentModel | null
}

type Actions = {
  reset: () => void
  getCompanyById: (id: number) => Promise<void>
  getCompanies: () => Promise<void>
  setCompanySelected: (payload: any) => void
  setCompanyGlobal: (company: ParentModel | null) => void
}

const initialState: State = {
  companies: [],
  companySelected: null,
  companyGlobal: null
}

const profileStore = create<State & Actions>()(
  persist(
    (set) => ({
      ...initialState,
      getCompanies: async () => {
        set({ companies: await getParents() })
      },
      setCompanies: (payload: any) => {
        set({ companies: payload })
      },
      setCompanySelected: (payload: any) => {
        set({ companySelected: payload })
      },
      setCompanyGlobal: (company: ParentModel | null) => {
        set({ companyGlobal: company })
      },
      getCompanyById: async (id: number) => {
        try {
          const res = await getParentById(id)
          set({ companySelected: res })
        } catch (error) {
          console.log('Error : ', error)
        }
      },
      reset: () => {
        set(initialState)
      }
    }),
    {
      name: 'company-store'
    }
  )
)

if (process.env.NODE_ENV === 'development') mountStoreDevtool('company-store', profileStore)

export default profileStore

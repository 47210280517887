import { desiredRoutes } from "src/types/enums/PagesByLevel"
import profileStore from "src/zustand/profile"

/**
 * Clona profunda una serie de objetos - De esta manera la copia permitirá la escritura
 *
 * @param {any[]} array - Array a clonar.
 * @returns {any[]} - Un clon profundo del array.
 */
const deepCloneArray = (array: any[]) => {
  return array.map(item => ({ ...item }))
}

/**
 * Capitaliza la primera letra de una cadena.
 * @param {string} str - La cadena de entrada.
 * @returns {string} - La cadena con la primera letra capitalizada.
 */
function capitaliseFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Ordena alfabeticamente un array de objetos a partir de una propiedad dada
 *
 * @param {{[key: string]: any}[]} array
 * @param {string} prop
 * @return {*} 
 */
const orderAlphabetical = (array: {[key: string]: any}[], prop: string) => {
  const sortedArray = array.sort((a, b) => {
    if (a[prop].toLowerCase() < b[prop].toLowerCase()) {
      return -1;
    }
    if (a[prop].toLowerCase() > b[prop].toLowerCase()) {
      return 1;
    }
    
    return 0;
  });
  
  return sortedArray
}

/**
 * Verifica si la imagen es válida.
 *
 * @param {string | File} image - La URL o el objeto File de la imagen.
 * @returns {boolean} - `true` si la imagen es válida, `false` en caso contrario.
 */
const showIfValidImage = (image: string | File) => {
  const regularExpURL = /^(ftp|http|https):\/\/[^ "]+$/
  if (typeof image === 'string') {
    return regularExpURL.test(image)
  } else if (image instanceof File) {
    return true
  }

  return false
}

/**
 * Modifica el brillo de un color hexagonal en porcentaje dado.
 *
 * @param {string} color - El color en formato hexadecimal (#RRGGBB).
 * @param {number} percent - El porcentaje de cambio en el brillo. Puede ser positivo o negativo.
 * @returns {string} El nuevo color en formato hexadecimal modificado por el porcentaje dado.
 *
 * @example
 * // Aclarar un color en un 20%
 * const nuevoColor = shadeColor("#3498db", 20);
 * // Oscurecer un color en un 10%
 * const otroColor = shadeColor("#e74c3c", -10);
 */
function shadeColor(color: string, percent: number) {
  let R = parseInt(color.substring(1, 3), 16)
  let G = parseInt(color.substring(3, 5), 16)
  let B = parseInt(color.substring(5, 7), 16)

  R = (R * (100 + percent)) / 100
  G = (G * (100 + percent)) / 100
  B = (B * (100 + percent)) / 100

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  R = Math.round(R)
  G = Math.round(G)
  B = Math.round(B)

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16)
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16)
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16)

  return '#' + RR + GG + BB

  // return '#FEF5E7'
}

/**
 * Devuelve a que nivel pertenece la ruta actual (tiene que coincidir completamente)
 * Formato: "/nombre-ruta"
 * @param {string} pathname
 * @return {string | null}
 */
const getCurrentRoute = (pathname: string) => {
  // console.log('pathname', pathname)
  for (const routeKey in desiredRoutes) {
    if (desiredRoutes[routeKey].includes(pathname)) {
      return routeKey;
    }
  }
  
  return null; // Si el path no coincide con ninguna ruta
}

/**
 * Encuentra el nivel de la ruta actual y devuelve la clave de seguridad asociada.
 * Ej de parámetro objectSecurity: { EZ?: 'clave-1', CUSTOMER?: 'clave-2', CLIENT?: 'clave-3' }
 * 
 * @param {{ [key: string]: string }} objectSecurity
 * @return {*} 
 */
const getKeyObjectSecurity = (objectSecurity: { [key: string]: string }) => {
  let path = location.pathname
  if (path.endsWith("/")) path = path.slice(0, -1)
  let level = getCurrentRoute(path)

  if (level) {
    level = level === 'ADMIN' ? 'EZ' : level

    return objectSecurity[level]
  }

  return ''
}

type objectSecurityFormat = { name: string, statusActive: boolean }
/**
 *  Busca si la clave está dentro del listado de objetos de seguridad, si esta indica
 *  que el elemento está visible o no.
 * @param {string} key
 * @return {boolean} visible
*/
const handleVisibilityAccordingKey = (key: string): boolean => {
  if (!key) return false

  const listKeys = profileStore().securityFormatted
  let visibility = false;
  if (Array.isArray(listKeys) && listKeys.length !== 0) {
    visibility = listKeys.find((e: objectSecurityFormat) => e.name === key)?.statusActive || false;
  }

  return visibility
}

interface Errors {
  [key: string]: string[];
}

/**
 * Convierte un objeto de errors devuelto de un servicio, en un cadena de texto con un formato más limpia listando los errores. 
 *
 * @param {Errors} errors
 * @return {*} Cadena de texto con todos los errores formateados.
 */
const formatErrors = (errors: Errors) => {
  
  return Object.entries(errors)
    .flatMap(([field, messages]) => messages.map((message: any) => `${field}: ${message}`))
    .join('\n');
}

export { capitaliseFirstLetter, deepCloneArray, formatErrors,getCurrentRoute,getKeyObjectSecurity,handleVisibilityAccordingKey, orderAlphabetical, shadeColor, showIfValidImage }

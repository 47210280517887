import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import TableRatesCarrierInvoice from 'src/pages/components/carrierInvoice/TableRatesCarrierInvoice'

const CarrierInvoice = () => {
  const navigate = useNavigate()
  const { carrierId } = useParams()
  const parsedCarrierId = carrierId ? parseInt(carrierId, 10) : -1

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <TableRatesCarrierInvoice carrierId={parsedCarrierId} />
      </Grid>
    </Grid>
  )
}

export default CarrierInvoice

import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
// import { getCustomers } from 'src/@http/customer'
import { getCityCache } from 'src/@http/address'
// import { CustomerModel } from 'src/types/models/CustomerModels'
// import { UserGetModel /*, UserModel */ } from 'src/types/models/userModels'
import { logger } from 'src/utils/Logger'

interface AutocompleteUsersProps {
  onSelectCity: (city: any) => void
  countryId: number
  stateId: number
  disabled: boolean
  defaultValue: number
}

interface City {
  intCountryId: number
  intStateId: number
  description: string
  id: number
  isActive: boolean
  lastModifiedDate: null
}

const valueDefault = {
  intCountryId: 0,
  intStateId: 0,
  description: '',
  id: 0,
  isActive: false,
  lastModifiedDate: null
}

const AutocompleteProps = ({ onSelectCity, countryId, stateId, defaultValue, disabled }: AutocompleteUsersProps) => {
  // const [customerList, setCustomerList] = useState<CustomerModel[]>([])
  const [value, setValue] = useState<City | null>(valueDefault)
  // const [value, setValue] = useState<any>()
  const { t } = useTranslation()
  const [cities, setCities] = useState<City[]>([])

  const handleChange = (event: any, value: any | null) => {
    logger.log('Value en Hijo:', value)
    logger.log('Event en Hijo:', event.target.value)

    // setCustomerName(value || '')

    onSelectCity(value || '')
    setValue(value || '')
  }

  // useEffect(() => {
  //   // debugger
  //   if(defaultValue !== 0 && cities.length !== 0) {
  //     logger.log('Cities', cities)
  //     logger.log('City select'  ,cities.find(item => item.id === defaultValue) )
  //     // setValue(cities.find(item => item.id === defaultValue))
  //     debugger
  //     setValue(valueNY)

  //   }

  // }, [defaultValue, cities]),

  useEffect(() => {
    if (defaultValue !== 0 && cities.length !== 0) {
      const defaultCity = cities.find(item => item.id === defaultValue)
      if (defaultCity) {
        setValue(defaultCity)
      }
    }
  }, [defaultValue, cities])

  useEffect(() => {
    getCityCache(countryId.toString(), stateId.toString())
      .then(res => setCities(res))
      .catch(err => {
        toast.error('CITY_ERRORS_GET')
        setCities([])
      })
  }, [])

  return (
    <Autocomplete
      fullWidth
      value={value}
      disabled={disabled}
      onChange={handleChange}
      options={cities}
      getOptionLabel={option => option.description}
      // options={users.map(user => user)}
      id='autocomplete-disableCloseOnSelect'
      renderInput={params => <TextField {...params} label={t('CITY')} />}
    />
  )
}

export default AutocompleteProps

import { mountStoreDevtool } from 'simple-zustand-devtools'
import { CarrierCustomerInvoiceModel } from 'src/types/models/CarrierModel'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type InvoiceFilter = {
  customerName?: string | null
  shopName?: string | null
  carrierName?: string | null
  trackingNumber?: string | null
  startDate?: string | null
  batchId?: number | null
  intStatusId: number | null
  endDate?: string | null
  isSearching: boolean
  isProp: boolean
  tabValue: string
}

type State = {
  invoiceFilter: InvoiceFilter
  invoiceData: CarrierCustomerInvoiceModel | null
}

type Actions = {
  setInvoiceFilter: (filter: InvoiceFilter) => void
  setCustomerName: (customerName: string | null) => void
  setCarrierName: (customerName: string | null) => void
  setTrackingNumber: (trackingNumber: string | null) => void
  setStartDate: (startDate: string | null) => void
  setEndDate: (endDate: string | null) => void
  setBatchId: (batchId: number | null) => void
  setStatusId: (intStatusId: number | null) => void
  setIsSearching: (isSearching: boolean) => void
  setIsProp: (isProp: boolean) => void
  setTabValue: (tabValue: string) => void
  setInvoiceData: (invoiceData: CarrierCustomerInvoiceModel | null) => void
  reset: () => void
}

const initialState: State = {
  invoiceFilter: {
    isSearching: false,
    isProp: false,
    tabValue: '1',
    intStatusId: 1,
    batchId: null
  },
  invoiceData: null
}

const invoiceStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      setInvoiceFilter: (filter: InvoiceFilter) => {
        set({ invoiceFilter: filter })
      },
      setInvoiceData: (invoiceData: CarrierCustomerInvoiceModel | null) => {
        set({ invoiceData: invoiceData })
      },
      setIsSearching: (isSearching: boolean) => {
        set({ invoiceFilter: { ...get().invoiceFilter, isSearching } })
      },
      setIsProp: (isProp: boolean) => {
        set({ invoiceFilter: { ...get().invoiceFilter, isProp } })
      },
      setCustomerName: (customerName: string | null) => {
        set({ invoiceFilter: { ...get().invoiceFilter, customerName } })
      },
      setCarrierName: (carrierName: string | null) => {
        set({ invoiceFilter: { ...get().invoiceFilter, carrierName } })
      },
      setBatchId: (batchId: number | null) => {
        set({ invoiceFilter: { ...get().invoiceFilter, batchId } })
      },
      setStatusId: (intStatusId: number | null) => {
        set({ invoiceFilter: { ...get().invoiceFilter, intStatusId } })
      },
      setTrackingNumber: (trackingNumber: string | null) => {
        set({ invoiceFilter: { ...get().invoiceFilter, trackingNumber } })
      },
      setStartDate: (startDate: string | null) => {
        set({ invoiceFilter: { ...get().invoiceFilter, startDate } })
      },
      setEndDate: (endDate: string | null) => {
        set({ invoiceFilter: { ...get().invoiceFilter, endDate } })
      },
      setTabValue: (tabValue: string) => {
        set({ invoiceFilter: { ...get().invoiceFilter, tabValue } })
      },

      reset: () => {
        set(initialState)
      }
    }),

    {
      name: 'invoice-store'
    }
  )
)

if (process.env.NODE_ENV === 'development') mountStoreDevtool('invoice-store', invoiceStore)

export default invoiceStore

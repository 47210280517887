import toast from 'react-hot-toast'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import {
  addBaseRateDetailsCustomer,
  getBaseRateCustomerDetails,
  getRateSheetVersionsCustomer
} from 'src/@http/customer'
import { getShopProduct } from 'src/@http/product'
import { addBaseRateDetailsShop, getBaseRateShopDetails, getRateSheetVersionsShop, getShops } from 'src/@http/shop'
import { getShopPlatformsIntegration, ShopIntegrationGet } from 'src/@http/shopIntegrations'
import { CustomerModel } from 'src/types/models/CustomerModels'
import { ProductShopResponse, ShopModel } from 'src/types/models/ShopModels'
import { logger } from 'src/utils/Logger'
import appStore from 'src/zustand/app'
import carrierStore from 'src/zustand/carrier'
import companiesStore from 'src/zustand/companies'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import profileStore from './profile'

interface ShopProductsProps {
  currectPage: number
  pageSize: number
  customerId: number
}

type Images = {
  eHub: string
  Shopify: string
  Magento: string
  Sap: string
  WooCommerce: string
  ShipStation: string
  [key: string]: string
}

const roundNumber = (n: number) => {
  return Math.round(n * 100) / 100
}

const selectorCalculate = (cell: any, typeOfTable: number) => {
  if (typeOfTable === 1) return roundNumber(cell.decCostBase * (1 - cell.decDiscountPercent * 0.01))
  if (typeOfTable === 2) return roundNumber(cell.decCostDiscount * (1 + cell.decMarkupPercent * 0.01))
  if (typeOfTable === 3) return cell.decSetRatePrice

  return 'Something is broken'
}

const images: Images = {
  eHub: 'https://upload.wikimedia.org/wikipedia/en/5/5f/IHub_logo.png',
  Shopify: 'https://1000logos.net/wp-content/uploads/2020/08/Shopify-Logo.png',
  Magento: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Magento_Logo.svg/250px-Magento_Logo.svg.png',
  Sap: 'https://www.sap.com/dam/application/shared/logos/sap-logo-svg.svg/sap-logo-svg.svg',
  WooCommerce: 'https://www.logo.wine/a/logo/WooCommerce/WooCommerce-Logo.wine.svg',
  ShipStation: 'https://www.shipstation.com/wp-content/uploads/2020/09/ShipStation-Logo-Color-Logo-1.png'
}

type State = {
  shopGlobal: ShopModel | null
  customerProductsProps: ShopProductsProps
  notFoundCustomerCourierRate: boolean
  customerProducts: ProductShopResponse[]
  shopPlatformsIntegration: ShopIntegrationGet[]
  customerName: string
  // shopName: string
  // shopId: number
  customerId: number
  versionSelected: string | null
  addSubstract: number
  showBtnAddSubstract: boolean
  selectedCellCoordCustomer: {
    coords: { rowStart: number; columnStart: number; rowEnd: number; columnEnd: number }
    applyOperation: boolean
    table: string
    operation: string
    type?: string
  } | null
  shops: ShopModel[]

  // Customer
  listRateSheetVersionsCustomer: any[]
  customerLogged: CustomerModel | null
}

type Actions = {
  reset: () => void
  refreshShopProduct: () => void
  setShopProductsProps: (customerProductsProps: ShopProductsProps) => void
  setCustomerName: (customerName: string) => void
  // setShopName: (shopName: string) => void
  // setShopId: (shopId: number) => void
  setCustomerId: (customerId: number) => void
  setShopProducts: (customerProducts: ProductShopResponse[]) => void
  setShopPlatformsIntegration: () => void
  createLstShopRateDetail: (payload: any) => Promise<void>
  updateLstShopRateDetail: (payload: any) => Promise<void>
  getRateSheetVersionsShopAndSheet: (data: any) => Promise<void>
  getRateShopSheetByVersionId: (id: number) => Promise<void>
  setAddSubstract: (value: number) => void
  setShowBtnAddSubstract: (show: boolean) => void
  setSelectedCellCoordinates: (coord: any, apply?: boolean, table?: string, operation?: string, type?: string) => void

  // Customer
  getRateCustomerSheetByVersionId: (id: number) => Promise<void>
  createLstCustomerRateDetail: (payload: any) => Promise<void>
  updateLstCustomerRateDetail: (payload: any) => Promise<void>
  getRateSheetVersionsCustomerAndSheet: (data: any) => Promise<void>
  getRateSheetVersionsCustomerCouriersAndSheet: (data: any) => Promise<void>
  getRateCustomerCouriersSheetByVersionId: (id: number) => Promise<void>
  getRateSheetVersionsClientCouriersAndSheet: (data: any) => Promise<void>
  getRateClientCouriersSheetByVersionId: (id: number) => Promise<void>
  setShops: (userType?: number) => Promise<void>
  setCustomerLogged: (customer: CustomerModel) => void
  setShopGlobal: (shop: ShopModel | null) => void
}

const initialState: State = {
  customerId: 0,
  customerName: '',
  // shopName: '',
  // shopId: 0,
  notFoundCustomerCourierRate: false,
  shopPlatformsIntegration: [],
  customerProductsProps: {
    currectPage: 0,
    pageSize: 5,
    customerId: 0
  },
  customerProducts: [],
  listRateSheetVersionsCustomer: [],
  versionSelected: null,
  addSubstract: 0,
  showBtnAddSubstract: false,
  selectedCellCoordCustomer: null,
  shops: [],
  customerLogged: null,
  shopGlobal: null
}

const customerStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      setAddSubstract: (value: number) => {
        set({ addSubstract: value })
      },
      setShowBtnAddSubstract: (show: boolean) => {
        set({ showBtnAddSubstract: show })
      },
      setSelectedCellCoordinates: (coord: any, apply?: boolean, table?: string, operation?: string, type?: string) => {
        if (coord === null) set({ selectedCellCoordCustomer: null })
        else {
          set({
            selectedCellCoordCustomer: {
              coords: coord,
              applyOperation: apply || false,
              table: table ? table : '',
              operation: operation ? operation : '',
              type: type ? type : ''
            }
          })
        }
      },
      // setShopId: (shopId: number) => {
      //   set({ shopId })
      // },
      setShopGlobal: (shop: ShopModel | null) => {
        set({ shopGlobal: shop })
      },
      setCustomerId: (customerId: number) => {
        set({ customerId })
      },
      setCustomerName: (customerName: string) => {
        set({ customerName })
      },
      // setShopName: (shopName: string) => {
      //   set({ shopName })
      // },
      setShopProductsProps: (customerProductsProps: ShopProductsProps) => {
        set({ customerProductsProps })
      },
      setShopProducts: (customerProducts: ProductShopResponse[]) => {
        set({ customerProducts })
      },
      refreshShopProduct: async () => {
        try {
          const response = await getShopProduct({
            customerId: get().customerProductsProps.customerId,
            PageSize: get().customerProductsProps.pageSize,
            CurrentPage: get().customerProductsProps.currectPage
          })
          set({ customerProducts: response.list })
          logger.log('[Get] ShopProducts:', response.list)
        } catch (error) {
          logger.log('[Get] ShopProducts:', error)
        }
      },
      setShopPlatformsIntegration: async () => {
        try {
          set({
            shopPlatformsIntegration: (await getShopPlatformsIntegration()).map(shop => ({
              ...shop,
              image: images[shop.description]
            }))
          })
        } catch {
          toast.error('ERROR_SHOP_INTEGRATION_GET')
        }
      },
      createLstShopRateDetail: async (payload: any) => {
        const { warehouseId, customerId, carrierId, serviceId, shopId, typeTableRateSheet } = payload
        appStore.setState({ loading: true })
        const newBaseRateDetails = {
          ...appStore.getState().headerBaseRateDetails,
          lstShopRateDetail: appStore.getState().cellListShop.map(cell => ({
            ...cell,
            intStatusId: 1,
            intCreatedUserId: 0,
            vchStatusName: '',
            decFinalPrice: selectorCalculate(cell, typeTableRateSheet)
          })),
          sinShopRateTypeId: typeTableRateSheet,
          intShopId: shopId,
          dtmStartDate: carrierStore.getState().startDateRateSheet,
          dtmEndDate: carrierStore.getState().endDateRateSheet,
          sinWarehouseId: warehouseId,
          intServiceId: serviceId,
          intCarrierId: carrierId,
          intStatusId: 1,
          sinCurrencyId: 1,
          intCustomerId: customerId
        }

        const res = await addBaseRateDetailsShop(newBaseRateDetails)
        logger.log('addBaseRate', res)
        appStore.setState({ loading: false })
        get().getRateSheetVersionsShopAndSheet({
          serviceId: serviceId,
          carrierId: carrierId,
          warehouseId: warehouseId,
          shopId: shopId
        })

        logger.log('Add Base Rate details', res)
      },

      updateLstShopRateDetail: async (payload: any) => {
        appStore.setState({ loading: true })
        try {
          const { warehouseId, customerId, carrierId, serviceId, typeTableRateSheet } = payload
          const newBaseRateDetails = {
            ...appStore.getState().headerBaseRateDetails,
            sinShopRateTypeId: typeTableRateSheet,
            lstShopRateDetail: appStore
              .getState()
              .cellListShop.map(cell => ({ ...cell, decFinalPrice: selectorCalculate(cell, typeTableRateSheet) }))
          }
          const res = await addBaseRateDetailsShop(newBaseRateDetails)
          logger.log('Add Base Rate details', res)
          appStore.setState({ loading: false })
          get().getRateSheetVersionsShopAndSheet({
            serviceId: serviceId,
            carrierId: carrierId,
            warehouseId: warehouseId,
            shopId: customerId
          })
        } catch (e: any) {
          logger.log('Error', e)
          toast.error('Error updating shop rate')
        } finally {
          appStore.setState({ loading: false })
        }
      },
      getRateSheetVersionsShopAndSheet: async (data: any) => {
        appStore.setState({ loading: true })
        logger.log('Data version customer and sheet:', data)
        try {
          const response = await getRateSheetVersionsShop({
            shopId: data.shopId,
            carrierId: data.carrierId,
            serviceId: data.serviceId,
            warehouseId: data.warehouseId,
            measureId: '-1', // serviceStore.getState().measure,
            customerId: companiesStore.getState()?.companyGlobal?.intCustomerId
          })
          if (response) {
            const cellList = response[0].lstShopRateDetail
            logger.log('Response versions : ', response)
            if (cellList === null) {
              // Exist sheet version
              appStore.setState({ listRateSheetVersion: response })
              appStore.setState({ versionSelected: response[0]?.vchNombreHoja || '0.00' })
              if (response[0]?.vchNombreHoja !== undefined) {
                get().getRateShopSheetByVersionId(response[0]?.bigShopRateId)
              } else appStore.getState().setHeaderBaseShopRateDetails([])
            } else {
              appStore.setState({ listRateSheetVersion: [] })
              appStore.setState({ versionSelected: '0.00' })
              // No exist sheet version
              appStore.getState().setHeaderBaseShopRateDetails(response[0])
            }
          }
        } catch (e: any) {
          logger.log('Error', e)
          toast.error(e.DescriptionError)
        }
        appStore.setState({ loading: false })
      },

      getRateShopSheetByVersionId: async (id: number) => {
        try {
          appStore.setState({ loading: true })
          const baseRateDeatails = await getBaseRateShopDetails(id, '-1')
          console.log('BaseRateDeatails: ', baseRateDeatails)
          appStore.getState().setHeaderBaseShopRateDetails(baseRateDeatails)
          appStore.setState({
            typeTableRateSheet: appStore.getState().typeTableValue[baseRateDeatails.sinShopRateTypeId - 1]
          })
          appStore.setState({ loading: false })
        } catch (e: any) {
          logger.log('Error', e)
          toast.error(e.DescriptionError)
        }
      },
      reset: () => {
        set(initialState)
      },

      // Customer
      createLstCustomerRateDetail: async (payload: any) => {
        const { warehouseId, customerId, carrierId, serviceId, typeTableRateSheet } = payload
        appStore.setState({ loading: true })
        const newBaseRateDetails = {
          ...appStore.getState().headerBaseRateDetails,
          lstCustomerRateDetail: appStore.getState().cellListShop.map(cell => ({
            ...cell,
            intStatusId: 1,
            intCreatedUserId: 0,
            vchStatusName: '',
            decFinalPrice: selectorCalculate(cell, typeTableRateSheet)
          })),
          dtmStartDate: carrierStore.getState().startDateRateSheet,
          dtmEndDate: carrierStore.getState().endDateRateSheet,
          sinWarehouseId: 2,
          intServiceId: serviceId,
          intCarrierId: carrierId,
          intStatusId: 1,
          sinCurrencyId: 1,
          sinCustomerRateTypeId: typeTableRateSheet,
          intCustomerId: customerId
        }

        const res = await addBaseRateDetailsCustomer(newBaseRateDetails)
        logger.log('addBaseRate', res)
        appStore.setState({ loading: false })
        get().getRateSheetVersionsCustomerAndSheet({
          serviceId: serviceId,
          carrierId: carrierId,
          warehouseId: warehouseId,
          customerId: customerId
        })

        logger.log('Add Base Rate details', res)
      },

      updateLstCustomerRateDetail: async (payload: any) => {
        appStore.setState({ loading: true })
        try {
          const { warehouseId, customerId, carrierId, serviceId, typeTableRateSheet } = payload
          const newBaseRateDetails = {
            ...appStore.getState().headerBaseRateDetails,
            sinCustomerRateTypeId: typeTableRateSheet,
            lstCustomerRateDetail: appStore
              .getState()
              .cellListShop.map(cell => ({ ...cell, decFinalPrice: selectorCalculate(cell, typeTableRateSheet) }))
          }
          const res = await addBaseRateDetailsCustomer(newBaseRateDetails)
          logger.log('Add Base Rate details', res)
          get().getRateSheetVersionsCustomerAndSheet({
            serviceId: serviceId,
            carrierId: carrierId,
            warehouseId: warehouseId,
            customerId: customerId
          })
        } catch (e: any) {
          logger.log('Error', e)
          toast.error('Error updating customer rate')
        } finally {
          appStore.setState({ loading: false })
        }
      },
      getRateSheetVersionsCustomerAndSheet: async (data: any) => {
        appStore.setState({ loading: true })
        // logger.log('Data version customer and sheet:', data)
        try {
          const response = await getRateSheetVersionsCustomer({
            customerId: data.customerId,
            carrierId: data.carrierId,
            serviceId: data.serviceId,
            warehouseId: data.warehouseId,
            measureId: '-1' // serviceStore.getState().measure
          })
          if (response) {
            const cellList = response[0].lstCustomerRateDetail
            logger.log('ListCustomerRateDetail response: ', cellList)
            if (cellList === null) {
              // Exist sheet version
              appStore.setState({ listRateSheetVersion: response }) //set({ listRateSheetVersionsCustomer: response })
              appStore.setState({ versionSelected: response[0]?.vchNombreHoja || '0.00' })
              if (response[0]?.vchNombreHoja !== undefined) {
                get().getRateCustomerSheetByVersionId(response[0]?.bigCustomerRateId)
              } else appStore.getState().setHeaderBaseCustomerRateDetails([])
            } else {
              // No exist sheet version
              appStore.getState().setHeaderBaseCustomerRateDetails(response[0])
            }
          }
        } catch (e: any) {
          logger.log('Error', e)
          toast.error(e.DescriptionError)
          appStore.setState({ isRenderTableError: true })
        }
        appStore.setState({ loading: false })
      },

      getRateCustomerSheetByVersionId: async (id: number) => {
        try {
          appStore.setState({ loading: true })
          const baseRateDeatails = await getBaseRateCustomerDetails(id, '-1')
          appStore.getState().setHeaderBaseCustomerRateDetails(baseRateDeatails)
          appStore.setState({ loading: false })
          appStore.setState({
            typeTableRateSheet: appStore.getState().typeTableValue[baseRateDeatails.sinCustomerRateTypeId - 1]
          })
        } catch (e: any) {
          logger.log('Error', e)
          toast.error(e.DescriptionError)
        }
      },

      getRateSheetVersionsCustomerCouriersAndSheet: async (data: any) => {
        appStore.setState({ loading: true })
        // logger.log('Data version customer and sheet:', data)
        try {
          const response = await getRateSheetVersionsCustomer({
            customerId: appStore.getState().paramsV2.CustomerId,
            carrierId: data.carrierId,
            serviceId: data.serviceId,
            warehouseId: data.warehouseId,
            measureId: '-1' // serviceStore.getState().measure
          })
          if (response) {
            const cellList = response[0].lstCustomerRateDetail
            if (cellList === null) {
              set({ notFoundCustomerCourierRate: false })
              // Exist sheet version
              appStore.setState({ listRateSheetVersion: response }) //set({ listRateSheetVersionsCustomer: response })
              appStore.setState({ versionSelected: response[0]?.vchNombreHoja || '0.00' })
              if (response[0]?.vchNombreHoja !== undefined) {
                await get().getRateCustomerCouriersSheetByVersionId(response[0]?.bigCustomerRateId)
              } else appStore.getState().setHeaderBaseCustomerCouriersRateDetails([])
            } else {
              set({ notFoundCustomerCourierRate: true })
              appStore.getState().clearTable()

              // appStore.getState().clearDataRateSheet()
            }
          }
        } catch (e: any) {
          logger.log('Error', e)
          toast.error(e.DescriptionError)
          appStore.setState({ isRenderTableError: true })
        }
        appStore.setState({ loading: false })
      },

      getRateCustomerCouriersSheetByVersionId: async (id: number) => {
        appStore.setState({ loading: true })
        try {
          const baseRateDeatails = await getBaseRateCustomerDetails(id, '-1')
          appStore.getState().setHeaderBaseCustomerCouriersRateDetails(baseRateDeatails)
          appStore.setState({ typeTableRateSheet: appStore.getState().typeTableValue[1] })
        } catch (e: any) {
          logger.log('Error', e)
          toast.error(e.DescriptionError)
        }
        appStore.setState({ loading: false })
      },

      getRateSheetVersionsClientCouriersAndSheet: async (data: any) => {
        appStore.setState({ loading: true })
        // logger.log('Data version customer and sheet:', data)
        try {
          const response = await getRateSheetVersionsShop({
            customerId: appStore.getState().paramsV2.CustomerId,
            shopId: appStore.getState().paramsV2.ShopId,
            carrierId: data.carrierId,
            serviceId: data.serviceId,
            warehouseId: data.warehouseId,
            measureId: '-1' // serviceStore.getState().measure
          })
          if (response) {
            const cellList = response[0].lstShopRateDetail
            if (cellList === null) {
              set({ notFoundCustomerCourierRate: false })
              // Exist sheet version
              appStore.setState({ listRateSheetVersion: response }) //set({ listRateSheetVersionsCustomer: response })
              appStore.setState({ versionSelected: response[0]?.vchNombreHoja || '0.00' })
              if (response[0]?.vchNombreHoja !== undefined) {
                await get().getRateCustomerCouriersSheetByVersionId(response[0]?.bigShopRateId)
              } else appStore.getState().setHeaderBaseCustomerCouriersRateDetails([])
            } else {
              set({ notFoundCustomerCourierRate: true })
              appStore.getState().clearTable()

              // appStore.getState().clearDataRateSheet()
            }
          }
        } catch (e: any) {
          logger.log('Error', e)
          toast.error(e.DescriptionError)
        }
        appStore.setState({ loading: false })
      },

      getRateClientCouriersSheetByVersionId: async (id: number) => {
        appStore.setState({ loading: true })
        try {
          const baseRateDeatails = await getBaseRateCustomerDetails(id, '-1')
          appStore.getState().setHeaderBaseCustomerCouriersRateDetails(baseRateDeatails)
          appStore.setState({ typeTableRateSheet: appStore.getState().typeTableValue[1] })
        } catch (e: any) {
          logger.log('Error', e)
          toast.error(e.DescriptionError)
        }
        appStore.setState({ loading: false })
      },
      setShops: async (userType?: number) => {
        try {
          const shopsRes = await getShops()
          set({ shops: shopsRes })
          if (shopsRes.length !== 0) {
            if (!userType || (userType && (userType === 2 || userType === 1))) {
              set({ shopGlobal: shopsRes[0] })
              appStore.getState().setParamsV2({ ...appStore.getState().paramsV2, ShopId: shopsRes[0].intShopId })
            } else if (userType && userType === 3) {
              let shopSelected
              const profile = profileStore.getState().profile
              const shop: number | null = (profile && profile.intShopId) || null
              if (shop) {
                shopSelected = shopsRes.find(element => element.intShopId === shop)
              }
              set({ shopGlobal: shopSelected || null })
              appStore.getState().setParamsV2({ ...appStore.getState().paramsV2, ShopId: shopSelected?.intShopId || 0 })
            }
          }
        } catch (error: any) {
          logger.log(
            'Error',
            error.hasOwnProperty('DescriptionError') ? error.DescriptionError : 'An unknown error occurred.'
          )
        }
      },
      setCustomerLogged: (customer: CustomerModel) => {
        set({ customerLogged: customer })
      }
    }),
    {
      name: 'customer-store'
    }
  )
)

if (process.env.NODE_ENV === 'development') mountStoreDevtool('customer-store', customerStore)

export default customerStore

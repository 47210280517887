import { useQuery } from '@tanstack/react-query'

import { getCarrierJson } from '.'

interface IGetCarrierJsonParams {
  id: number | null
}

const getCarrierJsonQueryKey = (params: IGetCarrierJsonParams) => ['getCarrierJson', params]

export const useGetCarrierJson = (
  params: IGetCarrierJsonParams,
  options?: {
    enabled?: boolean
  }
) =>
  useQuery({
    queryKey: getCarrierJsonQueryKey(params),
    queryFn: () => getCarrierJson(params.id),
    select: data => data.data,
    ...options
  })

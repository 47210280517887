// ** MUI Imports
// ** Third Party Imports
import ReactApexcharts from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { Icon } from '@iconify/react'
import { Box, Grid, Stack, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import { ApexOptions } from 'apexcharts'
import SummaryWidget from 'src/pages/components/SummaryWidget'

import FiltersByPeriods from '../components/dashboard/FiltersByPeriods'
// ** Components

const DashboardClient = () => {
  const { palette } = useTheme()
  const { t } = useTranslation()
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'))

  const dataRadialBar = [
    { title: 'Domestic', color: '#6EB545', porcent: 67 },
    { title: 'International', color: '#4039EE', porcent: 46 },
    { title: 'Other Services', color: '#DE3929', porcent: 15 }
  ]

  const options = {
    colors: [],
    plotOptions: {
      radialBar: {
        hollow: {
          size: '70%',
          background: palette.mode === 'light' ? '#00000010' : '#00000000',
          margin: 0
        },
        dataLabels: {
          name: { show: false },
          value: {
            color: palette.mode === 'dark' ? palette.customColors.terciary : palette.secondary.main,
            fontSize: '30px',
            show: true
          }
        }
      }
    }
  }

  const optionsPie: ApexOptions = {
    colors: ['#DE3A2A', '#4039EE', '#ECB225'],
    labels: ['UPS', 'FedEx', 'DHL'],
    stroke: {
      show: false,
      width: 0
    },
    legend: {
      position: 'bottom',
      labels: { colors: [palette.mode === 'dark' ? palette.customColors.terciary : palette.secondary.main] }
    },
    dataLabels: {
      style: {
        fontSize: '15px',
        fontWeight: 400
      }
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -25
        }
      }
    }
  }

  const customChart = [
    { ...options, colors: [dataRadialBar[0].color] },
    { ...options, colors: [dataRadialBar[1].color] },
    { ...options, colors: [dataRadialBar[2].color] }
  ]

  const seriesZonesChart = [{ name: 'Shipment', data: [21, 22, 60, 28, 16, 21, 53, 30, 10, 30, 90, 12] }]

  const optionsZonesChart: ApexOptions = {
    plotOptions: { bar: { borderRadius: 10 } },
    chart: { toolbar: { show: false }, background: 'transparent' },
    title: {
      text: 'Shipment',
      style: { fontWeight: 500, color: '#ECB225', fontSize: '16px', fontFamily: 'Inter,sans-serif' }
    },
    colors: ['#ECB225'],
    xaxis: { categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
    yaxis: { show: false },
    dataLabels: { enabled: false },
    theme: { mode: palette.mode === 'dark' ? 'dark' : 'light' }
  }

  const dataSummaryWidget = [
    {
      id: 1,
      title: 'Total Domestic',
      subtitle: '1000',
      amount: '$42,000.00',
      increase: true
    },
    {
      id: 2,
      title: 'Total International',
      subtitle: '500',
      amount: '$23,000.00',
      increase: true
    },
    {
      id: 3,
      title: 'Total Other Services',
      subtitle: '500',
      amount: '$19,000.00',
      increase: false
    },
    {
      id: 4,
      title: 'Total Shipment 2023',
      subtitle: '2300',
      amount: '$20,000.00',
      increase: true
    }
  ]

  return (
    <Grid container sx={{ flexDirection: { xs: 'column', md: 'row', lg: 'row' }, justifyContent: 'center' }}>
      <FiltersByPeriods />

      <SummaryWidget />

      <Grid item xs={12} lg={10} padding={3}>
        <Grid item xs={12} sx={{ my: 4 }}>
          <Typography variant='h6'>{t('SHIPMENTS')}</Typography>
        </Grid>
        <Grid container flexDirection='row' justifyContent='space-between' rowGap={2}>
          <Card sx={{ width: { xs: '100%', md: 'fit-content', lg: 'fit-content' } }}>
            <Stack flexDirection='row' justifyContent='space-between' alignItems='center'>
              <CardHeader sx={{ fontWeight: 600 }} title='Couriers' />
              <Box sx={{ mr: 5 }}>
                {[1, 2, 3].map(r => (
                  <Icon key={r} icon='bi:dot' height={20} />
                ))}
              </Box>
            </Stack>
            <CardContent sx={{ p: 0, display: 'flex', justifyContent: 'center' }}>
              <ReactApexcharts options={optionsPie} series={[65, 65, 65]} type='pie' width={300} />
            </CardContent>
          </Card>

          <Card sx={{ px: 5, width: { xs: '100%', md: 'fit-content', lg: 'fit-content' } }}>
            <Stack flexDirection='row' justifyContent='space-between' alignItems='center'>
              <CardHeader sx={{ fontWeight: 600 }} title='Zones' />
              <Box sx={{ mr: 5 }}>
                {[1, 2, 3].map(r => (
                  <Icon key={r} icon='bi:dot' height={20} />
                ))}
              </Box>
            </Stack>
            <CardContent sx={{ px: 0, pt: 0 }}>
              <ReactApexcharts
                options={optionsZonesChart}
                series={seriesZonesChart}
                type='bar'
                height={230}
                width={mobile ? '100%' : 490}
              />
            </CardContent>
          </Card>
          {/* <Card sx={{ px: 5, width: { xs: '100%', md: 'fit-content', lg: 'fit-content' } }}>
            <Stack
              sx={{ margin: '35px 0 10px 0', height: 0 }}
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <CardHeader sx={{ fontWeight: 600 }} title='Shipments Count' />
              <Box sx={{ mr: 5 }}>
                {[1, 2, 3].map(r => (
                  <Icon key={r} icon='bi:dot' height={20} />
                ))}
              </Box>
            </Stack>
            <Stack flexDirection='row' alignItems='center' justifyContent='flex-start' sx={{ pl: '1.25rem' }}>
              <Icon icon='bi:dot' height={10} />
              <Typography fontSize={14} sx={{ mt: 1 }} fontWeight={200}>
                FedEx + UPS + DHL
              </Typography>
            </Stack>
            <CardContent sx={{ px: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <BarShipmentCount />
            </CardContent>
          </Card> */}

        </Grid>

        {/* <Grid item xs={12} sx={{ my: 4 }}>
          <Typography variant='h6'>Customer Top 10</Typography>
        </Grid> */}
        {/* <Grid container>
          <Grid item xs={12}>
            <Card>
              <CustomerTopTable />
            </Card>
          </Grid>
        </Grid> */}
      </Grid>

      <Grid item xs={12} lg={2} display='flex' flexDirection='row' flexWrap='wrap' marginTop={8}>
        {dataRadialBar.map((item, i) => (
          <Card key={i} sx={{ width: '100%', marginY: { xs: 3, lg: 2, md: 2 } }}>
            <CardHeader titleTypographyProps={{ variant: 'body1' }} sx={{ fontWeight: 600 }} title={item.title} />
            <CardContent>
              <ReactApexcharts
                type='radialBar'
                height={180}
                options={{ ...options, ...customChart[i] }}
                series={[item.porcent]}
              />
            </CardContent>
          </Card>
        ))}
      </Grid>
    </Grid>
  )
}

export default DashboardClient

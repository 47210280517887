import { ReactNode } from 'react'
import { Box, styled } from '@mui/material'

const BackgroundBox = styled(Box)`
  background-image: url('/images/fondo/fondo5.jpg');
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
`

const InitialLayout = ({ children }: { children?: ReactNode }) => {
  return (
    <BackgroundBox>
      <Box>{children}</Box>
    </BackgroundBox>
  )
}

export default InitialLayout

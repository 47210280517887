import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoxProps, Card, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { postTotals } from 'src/@http/shop-invoices'
import { ShopInvoiceResumeDto } from 'src/types/models/ShopInvoiceModel'
import appStore from 'src/zustand/app'
import companiesStore from 'src/zustand/companies'
import customerStore from 'src/zustand/customer'

type InfoSection = {
  id?: number
  title: string
  subtitle?: string | number
  amount: number | string
  increase?: boolean
}

type PropSummaryWidget = {
  styleBox?: BoxProps['sx']
  children?: ReactNode
}

const SummaryWidgetShop = ({ styleBox, children }: PropSummaryWidget) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const { invoiceId, paramsV2 } = appStore()
  const { customerName } = customerStore(state => ({
    customerName: state.customerName
  }))
  const { companyGlobal } = companiesStore()
  const initialSummaryData: any = {
    totalShipPrice: 0,
    totalInvoice: 0,
    totalShipPricePaid: 0,
    totalInvoicePaid: 0
  }

  const [summaryData, setSummaryData] = useState<ShopInvoiceResumeDto>(initialSummaryData)

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await postTotals({
          customerName: companyGlobal?.vchName ?? null,
          vchShopName: customerName,
          id: -1,
          intId: -1,
          startDate: null,
          endDate: null,
          intStatusId: null
        })
        setSummaryData(response)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    setLoading(true)
    fetchData()
  }, [paramsV2])

  const dataSummaryWidget = [
    {
      id: 1,
      title: 'Total Invoiced',
      subtitle: summaryData.totalInvoice?.toString(),
      amount: summaryData.totalShipPrice?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
      increase: true
    },
    {
      id: 2,
      title: 'Total Paid',
      amount: summaryData.totalShipPricePaid?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
      subtitle: summaryData.totalInvoicePaid?.toString(),
      increase: true
    },
    {
      id: 3,
      title: 'Total Pending',
      subtitle: (summaryData.totalInvoice - summaryData.totalInvoicePaid).toString(),
      amount: (summaryData.totalShipPrice - summaryData.totalShipPricePaid).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      }),
      increase: false
    }
  ]

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container sx={styleBox}>
          <Grid item xs={12} lg={12}>
            <Card sx={{ py: 5, borderRadius: 2, xs: { px: 2 } }}>
              <Grid container justifyContent='center' alignItems='center' sx={{ minHeight: '100%' }} rowGap={3}>
                {dataSummaryWidget.map((item, i) => (
                  <Grid
                    key={item.id || i}
                    item
                    flexDirection='column'
                    xs={12}
                    lg={(!children ? 12 : 10) / dataSummaryWidget.length}
                    md={(!children ? 12 : 10) / dataSummaryWidget.length}
                    sx={{
                      px: 10,
                      borderRight: {
                        lg: dataSummaryWidget.length !== item.id ? '1px solid #d9dbda' : '',
                        md: dataSummaryWidget.length !== item.id ? '1px solid #d9dbda' : ''
                      }
                    }}
                  >
                    <Stack flexDirection='row' justifyContent='space-between'>
                      <Typography variant='body1'>{item.title}</Typography>
                      <Typography variant='body1' color={item.increase ? 'success.main' : 'error.main'}>
                        <span style={{ marginRight: '5px' }}>{t('ABBREVIATED_AMOUNT')}</span>
                        {item.subtitle}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography variant='h5'>{item.amount}</Typography>
                    </Stack>
                  </Grid>
                ))}
                {children ? <Grid item>{children}</Grid> : null}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default SummaryWidgetShop

import { toast } from 'react-hot-toast'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import {
  addBaseRateDetails,
  /*, updateBaseRateDetails*/ getBaseRateDetails,
  getRateSheetVersionsCarrier
} from 'src/@http/carriers'
import { CarrierModel } from 'src/types/models/CarrierModel'
import { logger } from 'src/utils/Logger'
import appStore from 'src/zustand/app'
import customerStore from 'src/zustand/customer'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type State = {
  refreshCarriersDate: Date | null
  carrierRateSheet: string | undefined
  startDateRateSheet: string | undefined
  endDateRateSheet: string | undefined
  carrierId: number | null
  carrierSelected: CarrierModel | null
  serviceId: number | null
  ratesId: any | null
  // listRateSheetVersionsZ: any[]
  versionSelected: string | null
}

type Actions = {
  reset: () => void
  setRefreshCarriersDate: () => void
  setstartDateRateSheet: (endDateRateSheet: string) => void
  setendDateRateSheet: (endDateRateSheet: string) => void
  setCarrierRateSheet: (carrierRateSheet: string) => void
  setCarrierId: (carrierId: number) => void
  setCarrierSelected: (carrier: CarrierModel) => void
  setServiceId: (carrierId: number) => void
  createLstCarrierRateDetail: (payload: any) => Promise<void>
  updateLstCarrierRateDetail: () => Promise<void>
  getRateSheetVersionsCarrierAndSheet: (data: any) => Promise<void> //   Promise<any>
  getRateCourierSheetByVersionId: (id: number) => Promise<void>
}

const initialState: State = {
  refreshCarriersDate: null,
  carrierRateSheet: '',
  startDateRateSheet: '',
  endDateRateSheet: '',
  carrierId: null,
  serviceId: null,
  ratesId: null,
  versionSelected: null,
  carrierSelected: null
}

const carrierStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      setRefreshCarriersDate: () => {
        set({ refreshCarriersDate: new Date() })
      },
      setstartDateRateSheet: (startDateRateSheet: string) => {
        set({ startDateRateSheet: startDateRateSheet })
      },
      setendDateRateSheet: (endDateRateSheet: string) => {
        set({ endDateRateSheet: endDateRateSheet })
      },
      setCarrierRateSheet: (carrierRateSheet: string | undefined) => {
        set({ carrierRateSheet: carrierRateSheet })
      },
      setCarrierId: (carrierId: number) => {
        set({ carrierId: carrierId })
      },
      setCarrierSelected: (carrier: CarrierModel) => {
        set({ carrierSelected: carrier })
      },
      setServiceId: (serviceId: number) => {
        set({ serviceId: serviceId })
      },
      createLstCarrierRateDetail: async () => {
        // const { warehouseId } = payload
        appStore.setState({ loading: true })
        try {
          const newBaseRateDetails = {
            ...appStore.getState().headerBaseRateDetails,
            lstCarrierRateDetail: appStore.getState().cellListCourier.map(cell => ({
              ...cell,
              intStatusId: 1,
              intCreatedUserId: 1
            })),
            vchNombreHoja: get().carrierRateSheet,
            vchArchivo: get().carrierRateSheet,
            dtmStartDate: get().startDateRateSheet,
            dtmEndDate: get().endDateRateSheet,
            sinWarehouseId: 6, // warehouseId
            intServiceId: get().serviceId,
            intCarrierId: get().carrierId,
            intStatusId: 1,
            intParentId: 3,
            sinCurrencyId: 1,
            sinCarrierRateTypeId: 1,
            intCreatedUserId: 1,
            intUpdatedUserId: 1
          }

          const res = await addBaseRateDetails(newBaseRateDetails)
          logger.log('addBaseRate', res)
          appStore.setState({ loading: false })
          get().getRateSheetVersionsCarrierAndSheet({
            intServiceId: get().serviceId,
            intCarrierId: get().carrierId,
            sinWarehouseId: 6
          })
        } catch (e: any) {
          // toast.error(e.DescriptionError)
          appStore.setState({ loading: false })
        }
      },

      updateLstCarrierRateDetail: async () => {
        appStore.setState({ loading: true })
        try {
          const newBaseRateDetails = {
            ...appStore.getState().headerBaseRateDetails,
            lstCarrierRateDetail: appStore.getState().cellListCourier
          }
          const res = await addBaseRateDetails(newBaseRateDetails)
          logger.log('Add Base Rate details', res)
          appStore.setState({ loading: false })
          get().getRateSheetVersionsCarrierAndSheet({
            intServiceId: get().serviceId,
            intCarrierId: get().carrierId,
            sinWarehouseId: 6 // appStore.getState().headerBaseRateDetails.sinWarehouseId
          })
        } catch (e: any) {
          logger.log('Error', e)
          toast.error(e.message)
        } finally {
          appStore.setState({ loading: false })
        }
      },
      getRateSheetVersionsCarrierAndSheet: async (data: any) => {
        appStore.setState({ loading: true })
        try {
          const response = await getRateSheetVersionsCarrier({
            intCarrierId: data.intCarrierId,
            intServiceId: data.intServiceId,
            sinWarehouseId: 6,
            measureId: '-1'
          })
          if (response) {
            const cellList = response[0].lstCarrierRateDetail
            if (cellList === null) {
              // Exist sheet version
              customerStore.setState({ notFoundCustomerCourierRate: false })
              appStore.setState({ listRateSheetVersion: response })

              appStore.setState({ versionSelected: response[0]?.vchNombreHoja || '0.00' })
              if (response[0]?.vchNombreHoja !== undefined) {
                get().getRateCourierSheetByVersionId(response[0]?.bigCarrierRateId) // getDetailBaseRateSelected(response[0]?.bigCarrierRateId)
              } else appStore.getState().setHeaderBaseRateDetails([])
            } else {
              customerStore.setState({ notFoundCustomerCourierRate: true })
              appStore.getState().setHeaderBaseRateDetails(response[0])
            }
          }
        } catch (e: any) {
          logger.log('Error', e)
          appStore.getState().clearTable()
          // toast.error(e.message)
          appStore.setState({ isRenderTableError: true })
        }
        appStore.setState({ loading: false })
      },

      getRateCourierSheetByVersionId: async (id: number) => {
        try {
          appStore.setState({ loading: true })
          const baseRateDeatails = await getBaseRateDetails(id, '-1')
          appStore.getState().setHeaderBaseRateDetails(baseRateDeatails)
        } catch (e: any) {
          logger.log('Error', e)
          toast.error(e.message)
        }
        appStore.setState({ loading: false })
      },

      reset: () => {
        set(initialState)
      }
    }),

    {
      name: 'carrier-store'
    }
  )
)

if (process.env.NODE_ENV === 'development') mountStoreDevtool('carrier-store', carrierStore)

export default carrierStore

import { ForwardedRef, forwardRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardMedia,
  Stack,
} from '@mui/material'
import { ShopIntegrationGet } from 'src/@http/shopIntegrations'
import { logger } from 'src/utils/Logger'
import customerStore from 'src/zustand/customer'

import { DialogExpose, DialogGeneric } from '../generic/DialogGeneric'

type ModalProp = {
  handleIntegrationSelected: (int: ShopIntegrationGet) => void
}

const ModalHandleSPlatfIntegration = forwardRef((props: ModalProp, ref: ForwardedRef<DialogExpose>) => {
  const { handleIntegrationSelected } = props
  const { t } = useTranslation()

  const { shopPlatformsIntegration, setShopPlatformsIntegration } = customerStore()
  useEffect(() => {
    if (shopPlatformsIntegration.length === 0) {
      setShopPlatformsIntegration()
    }

    logger.log(shopPlatformsIntegration)
  }, [])

  const handleConnect = (newShopInt: ShopIntegrationGet) => {
    handleIntegrationSelected(newShopInt)
  }

  /* const deleteShopSelected = (id: number) => {
    const arrayUpdated = shopIntegration.filter(el => el.id !== id)
    const listUpdated = listCompletedDataIntegration.filter(el => el.intTradingPlatformId !== id)
    if (arrayUpdated) setShopIntegration(arrayUpdated)
    if (listUpdated) setListCompletedDataIntegration(listUpdated)
    reset()
  } */

  return (
    <div>
      <DialogGeneric
        ref={ref}
        title={t('MANAGE_SHOP_PLATAFFORMS')}
        maxWidth={1150}
      >
        <Stack flexDirection='row' flexWrap='wrap'>
          {shopPlatformsIntegration.map((item, index) => (
            <Box key={index} sx={{ width: '50%', maxWidth: '50%', my: 3, p: 1 }}>
              <Card
                sx={{
                  backgroundColor: '#fff',
                  maxWidth: '50%',
                  p: 2,
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  position: 'relative'
                }}
              >
                {/* {shopAlreadySelected(item.id) && (
                  <Icon
                    style={{ position: 'absolute', right: 0, top: 0, margin: '.8rem .8rem 0 0' }}
                    color='green'
                    icon={'gg:check-o'}
                    fontSize={25}
                  />
                )} */}
                <CardHeader title={item.description} sx={{ pt: 1 }} />
                <CardMedia
                  image={item.image}
                  component='img'
                  sx={{ width: { xs: 260, sm: 300 }, height: { xs: 60, sm: 120 }, mb: 4, objectFit: 'contain' }}
                />
                <Box display='flex' width='100%'>
                  <Button
                    variant='contained'
                    onClick={() => handleConnect(item)}
                    color='secondary'
                    style={{ flex: 1, marginRight: '5px' }}
                    disabled={item.intStatusId !== 1}
                  >
                    {t('ADD')}
                  </Button>
                  {/* {shopAlreadySelected(item.id) && (
                    <IconButton color='error' onClick={() => deleteShopSelected(item.id)}>
                      <Icon icon='material-symbols:delete' fontSize={20} />
                    </IconButton>
                  )} */}
                </Box>
              </Card>
            </Box>
          ))}
        </Stack>
      </DialogGeneric>
    </div>
  )
})



export default ModalHandleSPlatfIntegration

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { getPackagesTypesByService } from "src/@http/carriers";
import { logger } from "src/utils/Logger";
import { formatErrors } from "src/utils/misc";

interface PackageType {
  intCarrierPackageTypeId: number;
  vchName: string;
  vchSourceId?: string;
  intStatusId?: number;
}

interface Props {
  serviceEditorCarrierId: number | null;
  form: any;
  handleCheckboxChange: (type: PackageType[]) => void;
}

const PackageTypes = ({ serviceEditorCarrierId, form, handleCheckboxChange }: Props) => {
  const [pkgTypes, setPkgTypes] = useState<any[]>([]);
  const { t } = useTranslation()
  
  useEffect(() => {
    const fetchPackageTypes = async () => {
      try {
        const data = await getPackagesTypesByService(serviceEditorCarrierId, form.intServiceId);
        setPkgTypes(data);
      } catch (err: any) {
        logger.error(`${err.title}. Errors: ${formatErrors(err.errors)}` || err.message || t('ERROR_LISTING_PACKAGE_TYPES'))
      }
    }

    fetchPackageTypes();
  }, []);

  const handleChange = (type: PackageType) => {
    const updatedPkgTypes = pkgTypes.map(pkgType => {
      if (pkgType.intCarrierPackageTypeId === type.intCarrierPackageTypeId)
        return {
          ...pkgType,
          enabled: pkgType.enabled === 1 ? 0 : 1
        };

      return pkgType;
    });
    logger.log('updatedPkgTypes: ', updatedPkgTypes);
    setPkgTypes(updatedPkgTypes);
    handleCheckboxChange(updatedPkgTypes);
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Active</TableCell>
              <TableCell align="center">Package Type ID</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Source ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pkgTypes.map((type, index) => (
              (type.intStatusId !== 2) &&
              <TableRow key={index}>
                <TableCell align="center">
                  <Checkbox
                    checked={type.enabled === 1}
                    onChange={() => handleChange(type)}
                    color="primary"
                  />
                </TableCell>
                <TableCell align="center">{type.intCarrierPackageTypeId}</TableCell>
                <TableCell align="center">{type.vchName}</TableCell>
                <TableCell align="center">{type.vchSourceId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default PackageTypes;
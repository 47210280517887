import { postAsync } from 'src/@http'
import { CustomerRateReportDto } from 'src/types/models/ReportCustomerSerivces'

/* Endpoints definition */
const EndpointCarrierService = '/v1.0/ControllerCustomerRate/Report'
const ReportServices = (customer: string | null, carrier: string | null) =>
  `${EndpointCarrierService}?customer=${customer}&carrier=${carrier}`

export const postReportCustomerServices = (
  customer: string | null = null,
  carrier: string | null = null
): Promise<CustomerRateReportDto[]> => {
  if ((!customer || customer === '') && (!carrier || carrier === '')) {
    return postAsync(EndpointCarrierService, {})
  } else if ((!customer || customer === '') && carrier !== null) {
    return postAsync(`${EndpointCarrierService}?carrier=${carrier}`, {})
  } else if (customer !== null && (!carrier || carrier === '')) {
    return postAsync(`${EndpointCarrierService}?customer=${customer}`, {})
  } else {
    return postAsync(ReportServices(customer, carrier), {})
  }
}

export interface DesiredRoutes {
  [key: string]: string[]
}

export const desiredRoutes: DesiredRoutes = {
  ADMIN: [
    '/admin-dashboard',
    '/admin-user',
    '/events',
    '/shopIntegration',
    '/roles-editor',
    '/profiles-editor',
    '/object-security-editor',
    '/admin/job',
    '/RegionManager',
    '/carriers',
    '/company-editor',
    '/admin-label-server',
    '/reports/service/summary'
  ],
  CUSTOMER: [
    '/dashboard',
    '/customer',
    '/rate-sheets',
    '/order-editor',
    '/customer/batch',
    '/labels',
    '/shipp',
    '/reports/admin',
    '/reports/accounting',
    '/reports/executive',
    '/reports/customer',
    '/reports/others',
    '/reports/service/version/summary',
    '/users-admin',
    '/managers-editor',
    '/task',
    '/support'
  ],
  CLIENTMULTI: ['/labels-client'],
  CLIENT: [
    '/c-dashboard',
    '/invoices',
    '/client-labels',
    '/orders',
    '/c-rate-sheets',
    '/client/reports',
    '/admin-user-client',
    '/client-shipp',
    '/client-labels/shipp/new'
  ]
}

// ** React Imports
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
// ** React
import { useDispatch /* , useSelector */ } from 'react-redux'
// ** Next Imports
import { Link, useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
// ** MUI Components
import Box, { BoxProps } from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import MuiFormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import { styled, useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import useMediaQuery from '@mui/material/useMediaQuery'
import jwt_decode from 'jwt-decode'
// ** Icon Imports
import Icon from 'src/@core/components/icon'
// ** Hooks
import { useSettings } from 'src/@core/hooks/useSettings'
import { axiosInstance } from 'src/@http'
import { axiosInstanceShippForm } from 'src/@http'
import { login } from 'src/@http/auth'
import { getCustomerById } from 'src/@http/customer'
import { getParents } from 'src/@http/customers'
// ** Configs
import { AppDispatch /* , RootState */ } from 'src/store'
import type { GetTokenModel } from 'src/types/models/getTokenModel'
import { logger } from 'src/utils/Logger'
// ** Demo Imports
import FooterIllustrationsV2 from 'src/views/pages/auth/FooterIllustrationsV2'
import appStore from 'src/zustand/app'
import companiesStore from 'src/zustand/companies'
import customerStore from 'src/zustand/customer'
import profileStore from 'src/zustand/profile'
import userStore from 'src/zustand/user'
// ** Third Party Imports
import * as yup from 'yup'

import { setToken } from '../../store/apps/profile'
import InitialLayout from '../components/layouts/InitialLayout'

interface Token {
  IdUsuario: string
  IdCustomer: string
  IdUserType: string
  IdParent: string
  nbf: number
  exp: number
  iat: number
  iss: string
  aud: string
}

const RightWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: 450
  }
}))

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    width: '100%'
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: 400
  }
}))

const LinkStyled = styled(Link)(({ theme }) => ({
  fontSize: '0.875rem',
  textDecoration: 'none',
  color: theme.palette.primary.main
}))

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary
  }
}))

const schema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().min(5).required()
})

interface FormData {
  email: string
  password: string
}

const getCatchErrorMessage = (err: any) => {
  logger.log(err.hasOwnProperty('DescriptionError') ? err.DescriptionError : 'An unknown error occurred.');
}

const LoginPage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const [rememberMe, setRememberMe] = useState<boolean>(true)
  const [showPassword, setShowPassword] = useState<boolean>(false)

  // Zustand
  const { setProfile, getPermissionList } = profileStore()
  const { setInitialSetup, paramsV2, setParamsV2 } = appStore()
  const { setCustomerLogged /*, setShops*/ } = customerStore()
  const { setCompanyGlobal } = companiesStore()
  const { setDataUserLogged } = userStore()

  const navigate = useNavigate()

  // ** Hooks
  const theme = useTheme()
  const { settings, saveSettings } = useSettings()
  const [isLoading, setIsLoading] = useState(false)

  const hidden = useMediaQuery(theme.breakpoints.down('md'))

  // ** Vars
  const { skin } = settings

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: FormData) => {
    const { email, password } = { email: data.email.trim(), password: data.password.trim() }
    setIsLoading(true)
    try {
      const response: GetTokenModel = await login(email, password)
      dispatch(setToken(response.token))
      const decodedToken: Token = jwt_decode(response.token)
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.token}`
      axiosInstanceShippForm.defaults.headers.common['Authorization'] = `Bearer ${response.token}`
      await getPermissionList(Number(decodedToken.IdUsuario))
      const profile = await setProfile(Number(decodedToken.IdUsuario), true)
      setInitialSetup()
      setDataUserLogged()
      setParamsV2({
        ...paramsV2,
        CustomerId: Number(decodedToken.IdParent || decodedToken.IdCustomer)
      })
      let userCompany;
      await getParents()
        .then((res: any) => {
          userCompany = res.find((company: any) => {
            const companyId = Number(decodedToken.IdParent || decodedToken.IdCustomer)
    
            return companyId === company.intCustomerId
          })
        })
        .catch(getCatchErrorMessage)

      logger.log(decodedToken.IdUserType)
      setCompanyGlobal(userCompany ?? null)

      if (Number(decodedToken.IdUserType) === 2) {
        // It is a customer, additional information will be required
        getCustomerById(Number(decodedToken.IdCustomer)).then(res => {
          setCustomerLogged(res)

          if (res) {
            saveSettings({
              ...settings,
              vchPrimaryColor: res.vchPrimaryColor || settings.vchPrimaryColor,
              vchSecondaryColor: res.vchSecondaryColor || settings.vchSecondaryColor,
              logoPath: res.vchLogoPath || settings.logoPath
            })
          }
        })
        .catch(getCatchErrorMessage)
        navigate('/dashboard')
      } else if (Number(decodedToken.IdUserType) === 1) {
        navigate('/admin-dashboard')
      } else if (Number(decodedToken.IdUserType) === 3) {
        if (profile?.intShopId === 0 || profile?.intShopId === null) {
          throw Error('CUSTOMER_INVALID')
        } else {
          navigate('/c-dashboard')
        }
      }
    } catch (err: any) {
      if (err) {
        toast.error(t(err.message || err || ''), { duration: 4000 })
        logger.log(err ? t(typeof err === 'string' ? err : err.message ) : '', { duration: 4000 })
      }
    } finally {
      setIsLoading(false)
    }
  }

  // TODO Sacar constante de test
  const test = true

  return (
    <InitialLayout>
      <Box
        className=''
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {test && !hidden ? (
          <Box sx={{ flex: 1, display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
            <FooterIllustrationsV2 />
          </Box>
        ) : null}
        {test ? (
          <RightWrapper sx={skin === 'bordered' && !hidden ? { borderLeft: `1px solid ${theme.palette.divider}` } : {}}>
            <Box
              sx={{
                p: 12,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'background.paper',
                opacity: 0.9,
                mr: 7
              }}
            >
              <BoxWrapper>
                {/* <Alert icon={false} sx={{ py: 3, mb: 6, ...bgColors.primaryLight, '& .MuiAlert-message': { p: 0 } }}>
                <Typography variant='caption' sx={{ mb: 2, display: 'block', color: 'primary.main' }}>
                  Admin: <strong>admin@admin.com</strong> / Pass: <strong>admin123</strong>
                </Typography>
                <Typography variant='caption' sx={{ display: 'block', color: 'primary.main' }}>
                  Internal: <strong>internal@internal.com</strong> / Pass: <strong>admin123</strong>
                </Typography>
                <Typography variant='caption' sx={{ display: 'block', color: 'primary.main' }}>
                  User: <strong>user@user.com</strong> / Pass: <strong>admin123</strong>
                </Typography>
              </Alert> */}
                <img
                  src='/images/logo-test-2.png'
                  alt='Logo'
                  width='300px'
                  height='200px'
                  style={{ marginBottom: '1rem' }}
                />
                <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                  <FormControl fullWidth sx={{ mb: 4 }}>
                    <Controller
                      name='email'
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <TextField
                          data-cy="email-input"
                          autoFocus
                          label='Email'
                          value={value || ''}
                          onBlur={onBlur}
                          onChange={onChange}
                          error={Boolean(errors.email)}
                          placeholder='youremail@gmail.com'
                        />
                      )}
                    />
                    {errors.email && (
                      <FormHelperText sx={{ color: 'error.main' }}>{errors.email.message}</FormHelperText>
                    )}
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.password)}>
                      Password
                    </InputLabel>
                    <Controller
                      name='password'
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <OutlinedInput
                          value={value || ''}
                          onBlur={onBlur}
                          data-cy="password-input"
                          label='Password'
                          onChange={onChange}
                          id='auth-login-v2-password'
                          error={Boolean(errors.password)}
                          type={showPassword ? 'text' : 'password'}
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                                edge='end'
                                onMouseDown={e => e.preventDefault()}
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                <Icon icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      )}
                    />
                    {errors.password && (
                      <FormHelperText sx={{ color: 'error.main' }} id=''>
                        {errors.password.message}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <Box
                    sx={{
                      mb: 4,
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between'
                    }}
                  >
                    <FormControlLabel
                      label='Remember Me'
                      control={<Checkbox checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} />}
                    />
                    <LinkStyled to='/forgot-password'>Forgot Password?</LinkStyled>
                  </Box>
                  <LoadingButton
                    loading={isLoading}
                    fullWidth
                    size='large'
                    type='submit'
                    variant='contained'
                    data-cy="login-btn"
                    sx={{ mb: 7, color: '#fff' }}
                  >
                    Login
                  </LoadingButton>
                </form>
              </BoxWrapper>
            </Box>
          </RightWrapper>
        ) : null}
      </Box>
    </InitialLayout>
  )
}

LoginPage.guestGuard = true

export default LoginPage

import { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { BaseTextFieldProps, TextField } from '@mui/material'

interface StyledTextFieldProps extends BaseTextFieldProps {
  hasFile?: boolean
}

const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  #file-selector::file-selector-button {
    display: none;
  }

  #file-selector:hover {
    cursor: pointer;
  }

  #file-selector {
    opacity: ${props => (props.hasFile ? '0' : '1')};
  }
`

interface FileInputProps extends BaseTextFieldProps {
  onChange: (file: File | null) => void
  hiddenNameFile?: boolean
}

const FileInput = ({ onChange, hiddenNameFile, ...props }: FileInputProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [hasFile, setHasFile] = useState(false)

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0]
    onChange(selectedFile)
    if (hiddenNameFile) setHasFile(true)
  }

  return (
    <div>
      <StyledTextField
        {...props}
        id='file-selector'
        type='file'
        ref={fileInputRef}
        onChange={handleFileChange}
        InputLabelProps={{ shrink: true }}
        hasFile={hasFile}
      />
    </div>
  )
}

export default FileInput

// ** MUI Imports
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
// ** Icon Imports
import Icon from 'src/@core/components/icon'
// ** Custom Components Imports
import CustomAvatar from 'src/@core/components/mui/avatar'
import appStore from 'src/zustand/app'

const CardCreate = () => {
  const { t } = useTranslation()
  const { setIsSaveAsModalOpen } = appStore()

  return (
    <Card>
      <CardContent
        sx={{
          display: 'flex',
          textAlign: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          p: theme => `${theme.spacing(9.75, 5, 9.25)} !important`
        }}
      >
        <CustomAvatar skin='light' sx={{ width: 50, height: 50, mb: 2.25 }}>
          <Icon icon='material-symbols:warning-outline' fontSize='2rem' />
        </CustomAvatar>
        <Typography variant='h6' sx={{ mb: 2.75 }}>
          {t('NOT_FOUND_RATE_SHEET_COURIER')}
        </Typography>
        <Typography variant='body2' sx={{ mb: 6 }}>
          {t('CREATE_RATE_SHEET')}
        </Typography>
        <Button
          style={{color: 'white'}}
          variant='contained'
          sx={{ p: theme => theme.spacing(1.75, 5.5) }}
          onClick={() => setIsSaveAsModalOpen(true)}
        >
          {t('CREATE_RATE_SHEET_BTN')}
        </Button>
      </CardContent>
    </Card>
  )
}

export default CardCreate

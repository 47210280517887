// import { Icon } from '@iconify/react'
import { /* Button,*/ Grid } from '@mui/material'

import ShopInvoiceTab from '../shop-invoice-tabs'
import SummaryWidgetShop from '../SummaryWidgetShop'

const ShopInvoice = () => {

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <SummaryWidgetShop></SummaryWidgetShop>
        <ShopInvoiceTab />
      </Grid>
    </Grid>
  )
}

export default ShopInvoice

// ** MUI Imports
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  // Switch,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import { grey } from '@mui/material/colors'
// ** Icon Imports
import Icon from 'src/@core/components/icon'
import { CrudView } from 'src/types/forms/CrudView'
import { getKeyObjectSecurity } from 'src/utils/misc'
import IconRecharge from 'src/views/icons/IconRecharge'
// ** Types Imports
import appStore from 'src/zustand/app'

import { DialogExpose } from '../generic/DialogGeneric'
import WrapperSecurity from '../generic/WrapperSeurityObject'
import ListCarrierTransaction from '../labels-server/ListCarrierTransaction'
import { IconAction } from '../rateSheets/TableRateSheet'

import ModalOptionAccount from './ModalOptionAccount'

/* const RowOptions = () => {
  const { setTabValue } = jobStore()

  const handleClick = (mode: string) => {
    if (mode === 'Detail') {
      setTabValue('1')
    } else if (mode === 'Step') {
      setTabValue('2')
    }
  }

  return (
    <Box width='100%' display='flex' justifyContent='center'>
      <IconAction title={'Order detail'} icon='mdi:eye' onClick={() => handleClick('Detail')} />
    </Box>
  )
} */

const CardStatsHorizontal = (props: any) => {
  const { t } = useTranslation()
  const { setShowPaymentDialog } = appStore()
  const navigate = useNavigate()
  const modalOptionAccount = useRef<DialogExpose>(null)
  const [handleDetailsAccount, setHandleDetailsAccount] = useState<{
    open: boolean
    data: null | any
  }>({
    open: false,
    data: null
  })

  const { item, onClick, setAmount, level } = props
  const { carrierName, accountNumber, balance, minBalance, image, accountType, bitEnabled, vchName } = item
  const filters = {
    intShop: -1,
    intCustomer: -1,
    intCarrier: item.intCarrierId,
    sinLSTransactionTypeId: -1 // 1 - Credit, 2 - Exchange
  }

  const colorStatus = (balance: number, minBalance: number) => {
    if (balance === 0) return 'error.main'
    if (balance <= minBalance) return 'warning.main'

    return 'success.main'
  }

  const handleOptionSelected = (option: string) => {
    if (option === 'Ez Account') {
      setShowPaymentDialog(true)
    } else if (option === 'Own Account') {
      setHandleDetailsAccount({
        open: true,
        data: item
      })
    }
  }
  const [checkingAccountModalOpen, setcheckingAccountModalOpen] = useState(false)

  const handleCheckingAccountModalClose = () => {
    setcheckingAccountModalOpen(false)
  }

  const getAccountNumberFormat = (an: string) => {
    if (an && an.startsWith('N°:')) return an.slice(3)

    return an || '-'
  }

  return (
    <Card
      sx={{
        boxShadow: theme => `${theme.shadows[0]} !important`,
        border: theme => `1px solid ${theme.palette.divider}`
      }}
    >
      <CardContent data-cy="account-server-card">
        <Stack sx={{ flexDirection: 'row', width: '100%' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant='h6' sx={{ lineHeight: 1.3, display: 'flex', alignItems: 'center' }}>
              {carrierName}
              {bitEnabled == 1 ? (
                <Tooltip title={bitEnabled == 1 ? t('ENABLED_STATUS') : t('DISABLED_STATUS')}>
                  <IconButton sx={{ height: '12px' }}>
                    <Icon icon={'lets-icons:check-fill'} style={{ marginLeft: 7 }} fontSize={22} color={'#177231'} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={t('DISABLED_STATUS')}>
                  <IconButton sx={{ height: '12px' }}>
                    <Icon icon={'lets-icons:close-fill'} style={{ marginLeft: 7 }} fontSize={22} color={'#C70000'} />
                  </IconButton>
                </Tooltip>
              )}
            </Typography>
            <Typography variant='caption' sx={{ lineHeight: 1.5, display: 'flex', alignItems: 'center', marginY: 1, }}>
              {vchName}
            </Typography>
            <Typography variant='caption' fontWeight='500' color={grey[800]}>
              {accountType} -{' '}
            </Typography>
            <Typography variant='caption'>
              {t('ACCOUNT_NUMBER')}: {getAccountNumberFormat(accountNumber)}
            </Typography>
            <Typography
              variant='h6'
              fontWeight={600}
              sx={{
                my: 2,
                mr: 1,
                lineHeight: 1.05,
                color: colorStatus(balance, minBalance)
              }}
            >
              $ {balance}
            </Typography>
            <Box sx={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
              <WrapperSecurity securityKey={getKeyObjectSecurity({
                CUSTOMER: 'edit_account_customer_btn',
                CLIENT: 'edit_account_client_btn'
              })}>
                <IconAction
                  title={t('EDIT')}
                  icon='mdi:pencil-outline'
                  color='primary'
                  sx={{ pl: 0 }}
                  onClick={() =>
                    navigate('account/edit', {
                      state: { data: item, mode: CrudView.EDIT, level }
                    })
                  }
                />
              </WrapperSecurity>
              <IconAction
                title={t('SERVICES')}
                icon='carbon:mobility-services'
                onClick={() =>
                  navigate('account/services-associates', {
                    state: { data: item }
                  })
                }
              />
              <WrapperSecurity securityKey={getKeyObjectSecurity({
                CUSTOMER: 'recharge_ls_customer_btn'
              })}>
                <IconRecharge
                  disabled={item.bitOwnAccount === 1}
                  color={item.bitOwnAccount === 0 ? '#908E95' : '#CCCBCE'}
                  title={t('RECHARGE')}
                  onClick={() => {
                    if (item.bitOwnAccount === 0) {
                      onClick(item)
                      modalOptionAccount.current?.open()
                    }
                  }}
                />
              </WrapperSecurity>
              <WrapperSecurity securityKey={getKeyObjectSecurity({
                CUSTOMER: 'ledger_ls_customer_btn'
              })}>
                <IconAction
                  title={t('LEDGER_TEXT')}
                  icon='mdi:invoice-list'
                  disabled={item.bitOwnAccount === 1}
                  onClick={() => {
                    item.bitOwnAccount === 0 && setcheckingAccountModalOpen(true)
                  }}
                />
              </WrapperSecurity>
              {/* <Bridge disabled={true} checked={item.bitOwnAccount === 1} /> */}
              <Chip
                label={t('OWN')}
                icon={<Icon icon={item.bitOwnAccount ? 'mdi:check-circle-outline' : 'mdi:close-circle-outline'} />}
                color={item.bitOwnAccount ? 'success' : 'error'}
                size={'small'}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '123px',
              textAlign: 'center'
            }}
          >
            <img width={'85'} src={image} height={'70'} alt={carrierName} />
          </Box>
        </Stack>
      </CardContent>
      {/* Dialogs */}
      <DialogLedger 
        data={{ carrierName: carrierName, filters }}
        open={checkingAccountModalOpen}
        handleClose={handleCheckingAccountModalClose}
      />
      <ModalOptionAccount
        ref={modalOptionAccount}
        setRechargeAmount={setAmount}
        title={carrierName}
        optionSelected={handleOptionSelected}
      />
    </Card>
  )
}

const DialogLedger = (props: any) => {
  const { data, open, handleClose } = props;
  const { t } = useTranslation()

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            width: '80%',
            maxWidth: 'none'
          }
        }}
      >
        <DialogTitle>
          {data.carrierName} {t('LEDGER_TEXT')}
        </DialogTitle>
        <DialogContent>
          <ListCarrierTransaction filters={data.filters} />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleClose} color='secondary'>
            {t('CLOSE')}
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default CardStatsHorizontal

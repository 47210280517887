import { mountStoreDevtool } from "simple-zustand-devtools"
import { create } from "zustand"
import { persist } from "zustand/middleware"

type State = {
  refreshShippGridDate: Date
  refreshCourierAccountDate: Date,
}

type Actions = {
  setRefreshShippGridDate: () => void
  setRefreshCourierAccountList: () => void
}

const initialState: State = {
  refreshShippGridDate: new Date(),
  refreshCourierAccountDate: new Date()
}

const userStore = create<State & Actions>()(
  persist(
    (set) => ({
      ...initialState,
      setRefreshShippGridDate: () => {
        set({ refreshShippGridDate: new Date() })
      },
      setRefreshCourierAccountList: () => {
        set({ refreshCourierAccountDate: new Date() })
      }
    }),
    {
      name: 'label-store'
    }
  )
)

if (process.env.NODE_ENV === 'development') mountStoreDevtool('label-store', userStore)

export default userStore
import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { Box, Button, Card, Grid, IconButton, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import CustomAvatar from 'src/@core/components/mui/avatar'
import CustomChip from 'src/@core/components/mui/chip'
import PageHeader from 'src/@core/components/page-header'
import { getCarrierInvoices } from 'src/@http/carriers'
import { formatOrderDate } from 'src/utils/formatDate'
import FileUploaderMultiple from 'src/views/forms/FileUploaderMultiple'
import appStore from 'src/zustand/app'
import filesStore from 'src/zustand/files'
import orderStore from 'src/zustand/order'

import { DialogExpose } from '../generic/DialogGeneric'
import { DialogGenericInvoice } from '../generic/DialogGenericInvoice'

interface Row {
  idBatch: number
  icon: { icon: string; color: string }
  user: string
  fileName: string
  issuedDate: string
  statusName: { paid: boolean; status: string }
}

const columns: GridColumns = [
  {
    flex: 1,
    field: 'idBatch',
    headerName: 'Batch id',
    minWidth: 100,
    renderCell: (params: any) => (
      <Typography variant='subtitle1' color='primary'>
        #{params.value}
      </Typography>
    )
  },
  {
    field: 'user',
    headerName: 'user',
    minWidth: 200,
    renderCell: (params: any) => (
      <Grid container columnGap={3}>
        <Grid
          sx={{ display: { xs: 'none', md: 'flex', lg: 'flex' } }}
          item
          xs={6}
          lg={2}
          justifyContent='center'
          alignItems='center'
        >
          <CustomAvatar src={params.value.avatar} alt='avatar' sx={{ width: 30, height: 30 }} />
        </Grid>
        <Grid item xs={6} lg={8} display='flex' flexDirection='column' justifyContent='flex-start'>
          <Typography variant='subtitle2' fontWeight={600}>
            {params.value}
          </Typography>
        </Grid>
      </Grid>
    )
  },
  {
    field: 'fileName',
    headerName: 'file name',
    minWidth: 250
  },
  {
    field: 'issuedDate',
    headerName: 'Issued Date',
    minWidth: 120
  },
  {
    field: 'statusName',
    headerName: 'status',
    minWidth: 100,
    renderCell: (params: any) => (
      <>
        {params.value.paid ? (
          <CustomChip label={params.value.status} skin='light' color='success' />
        ) : (
          <Typography variant='body1'>{params.value.status}</Typography>
        )}
      </>
    )
  },
  {
    field: '',
    headerName: 'Action',
    minWidth: 150,
    renderCell: ({ row }: CellType) => <RowOptions id={row.idBatch} />
  }
]

interface CellType {
  row: Row
}
const RowOptions = ({ id }: { id: number }) => {
  const { setInvoiceId } = appStore(state => ({
    setInvoiceId: state.setInvoiceId
  }))

  return (
    <Stack display='flex' flexDirection='row' width='100%' justifyContent='space-between'>
      <IconButton size='small'>
        <Icon icon='material-symbols:delete-outline' />
      </IconButton>
      <Link to='/invoice'>
        <IconButton size='small' onClick={() => setInvoiceId(id)}>
          <Icon icon='mdi:eye' />
        </IconButton>
      </Link>
      {/* <Link to='/invoiceSummary'>
        <IconButton size='small' onClick={() => setInvoiceId(id)}>
          <Icon icon='mdi:account-arrow-down' />
        </IconButton>
      </Link> */}
      <IconButton size='small'>
        <Icon icon='mdi:dots-vertical' />
      </IconButton>
    </Stack>
  )
}
/* const mapData = (data: CarrierInvoicesGetModel): Row => {
  const row: Row = {
    idBatch: data.intCarrierInvoiceBatchId,
    icon: { icon: '', color: '' },
    user: data.vchUserName,
    fileName: data.vchFileName,
    issuedDate: formatOrderDate(data.dtmCreatedDate.toISOString()),
    statusName: {
      paid: data.intStatusId === 1,
      status: data.vchStatusName
    }
  }

  return row
} */
const TableRatesCarrierInvoice = ({ carrierId }: { carrierId: number }) => {
  const { setCarrierNameStore, carrierName } = orderStore(state => ({
    setCarrierNameStore: state.setCarrierName,
    carrierName: state.carrierName
  }))
  const [rowData, setRowData] = useState<Row[]>([])
  const dialogRef = useRef<DialogExpose>(null)
  const [isLoading, setLoading] = useState(true)
  const { closeFileModal, setCloseFileModal } = filesStore(state => ({
    closeFileModal: state.closeFileModal,
    setCloseFileModal: state.setCloseFileModal
  }))

  useEffect(() => {
    setLoading(true)

    getCarrierInvoices({
      intCarrierId: carrierId,
      status: 'enabled'
    })
      .then(data => {
        const mappedRows = data.map(response => {
          const createdDate =
            response.dtmCreatedDate instanceof Date ? response.dtmCreatedDate : new Date(response.dtmCreatedDate)

          return {
            idBatch: response.intCarrierInvoiceBatchId,
            icon: { icon: '', color: '' },
            user: response.vchUserName,
            fileName: response.vchFileName,
            issuedDate: formatOrderDate(createdDate.toISOString()),
            statusName: {
              paid: response.intStatusId === 1,
              status: response.vchStatusName
            }
          }
        })
        setRowData(mappedRows)
        setCarrierNameStore(data[0].vchCarrierName)
      })
      .catch(error => {
        console.error('Error al obtener las órdenes:', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [closeFileModal])

  useEffect(() => {
    if (closeFileModal && dialogRef.current) {
      dialogRef.current.close()
      setCloseFileModal(false)
      toast.success('Successfully imported invoice', { duration: 4000 })
    }
  }, [closeFileModal])

  return (
    <>
      <PageHeader
        title={<Typography variant='h5'>{carrierName}</Typography>}
        subtitle={<Typography variant='body2'>{carrierName} Batch Invoice list</Typography>}
      />
      <Card sx={{ mt: 3 }}>
        <Stack
          sx={{ p: 4, flexDirection: { xs: 'column', md: 'row', lg: 'row' } }}
          display='flex'
          justifyContent='space-between'
        >
          <Select size='small' defaultValue='Action'>
            <MenuItem value='Action'>Action</MenuItem>
          </Select>
          <Box display='flex' sx={{ flexDirection: { xs: 'column', md: 'row', lg: 'row' } }} alignItems='center'>
            <TextField
              id='search-invoice'
              label='Search Invoice'
              size='small'
              sx={{
                m: { xs: '1rem 0', md: '0 .8rem 0 0', lg: '0 .8rem 0 0' },
                width: { xs: '100%', md: 'fit-content', lg: 'fit-content' }
              }}
            />
            <Button variant='contained' color='primary' onClick={() => dialogRef.current?.open()}>
              <Icon icon='formkit:arrowleft' fontSize={12} />
              Import Invoice
            </Button>
          </Box>
        </Stack>
        <DialogGenericInvoice title='Import Invoice' ref={dialogRef} maxWidth={800}>
          <FileUploaderMultiple />
        </DialogGenericInvoice>
        <DataGrid
          checkboxSelection
          autoHeight
          loading={isLoading}
          rows={rowData}
          columns={columns}
          getRowHeight={() => 61}
          getRowId={row => row.idBatch}
          pageSize={100}
          disableSelectionOnClick
          rowsPerPageOptions={[10, 25, 50]}
        />
      </Card>
    </>
  )
}

export default TableRatesCarrierInvoice

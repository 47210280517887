import { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from '@mui/material'
import { t } from 'i18next'
import appStore from 'src/zustand/app'
import customerStore from 'src/zustand/customer'

const ModalAddAndSubtractionHandle = (props: { [key: string]: any }) => {
  const { open, showDialog } = props

  const [addSubstractValue, setAddSubstractValue] = useState<string>('')

  const { subtractPercentCustomer, typeTableRateSheet, addPercentCustomer, addPercentCurrier, subtractPercentCourier } =
    appStore()
  const { setAddSubstract, selectedCellCoordCustomer, setSelectedCellCoordinates } = customerStore()

  const handleSubstract = () => {
    const cellSelected = selectedCellCoordCustomer?.coords || null
    const table = selectedCellCoordCustomer?.table
    if (cellSelected !== null) {
      setSelectedCellCoordinates(cellSelected, true, table, 'substract')
    } else {
      subtractPercentCustomer(typeTableRateSheet)
    }
    showDialog(false)
    setAddSubstractValue('')
  }

  const handleAdd = () => {
    const cellSelected = selectedCellCoordCustomer?.coords || null
    const table = selectedCellCoordCustomer?.table
    if (cellSelected !== null) {
      setSelectedCellCoordinates(cellSelected, true, table, 'add')
    } else {
      addPercentCustomer(typeTableRateSheet)
    }
    showDialog(false)
    setAddSubstractValue('')
  }

  const handleAddSubstractChange = (e: any) => {
    setAddSubstract(e.target.value)
    setAddSubstractValue(e.target.value)
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{t('ADD_OR_SUBSCTRACT')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin='dense'
          id='addSubstract'
          type='number'
          fullWidth
          value={addSubstractValue}
          onChange={handleAddSubstractChange}
          InputProps={{
            startAdornment: <InputAdornment position='start'>%</InputAdornment>
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={() => showDialog(false)} color='secondary'>
          {t('CANCEL')}
        </Button>
        <Button variant='contained' onClick={handleSubstract} color='error'>
          {t('SUBSTRACT -')}
        </Button>
        <Button variant='contained' onClick={handleAdd} color='success'>
          {t('ADD +')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalAddAndSubtractionHandle

import { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import { IconButton, Tooltip } from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { getCarriers } from 'src/@http/carriers'
import { getShopsPaginate, ShopWPagination } from 'src/@http/shop'
import appStore from 'src/zustand/app'
import carrierStore from 'src/zustand/carrier'

import { colsCarriers } from '../carrier/ListCouriersUtilsGrid'
import { colsShop } from '../shop/ListShopUtilsGrid'

export const IconAction = (props: {
  disabled?: boolean
  sizeIcon?: number
  title: string
  onClick?: (event: any | undefined, title: string) => void
  icon: string
  color?: 'inherit' | 'default' | 'primary' | 'error' | 'success' | 'secondary' | 'info' | 'warning'
  sx?: any
  datacy?: string
}) => {
  const { sizeIcon, title, onClick, icon, disabled, color, sx, datacy } = props
  console.log('IconAction', props)
  
return (
    <Tooltip title={title}>
        <IconButton data-cy={datacy} sx={sx} onClick={e => onClick && onClick(e, title)} disabled={disabled} color={color}>
          <Icon icon={icon} style={{ fontSize: `${sizeIcon || 20}px` }} />
        </IconButton>
    </Tooltip>
  )
}

function TableRateSheet({ type }: { type: string }) {
  const [cols, setCols] = useState<GridColumns | null>(null)
  const [dataPaginationGrid, setDataPaginationGrid] = useState({
    loading: true,
    rows: [],
    rowCount: 0,
    rowsPerPageOptions: [5, 10, 15],
    pageSize: 20,
    page: 1
  })
  const { paramsV2 } = appStore()
  const { refreshCarriersDate } = carrierStore()

  const updateDataPaginationGrid = (k: any, v: any) => setDataPaginationGrid(prev => ({ ...prev, [k]: v }))

  useEffect(() => {
    const fetch = async (typeUser: string) => {
      updateDataPaginationGrid('loading', true)
      try {
        let response
        if (typeUser === 'carriers') {
          response = await getCarriers(dataPaginationGrid.pageSize, dataPaginationGrid.page)
        } else {
          response = (await getShopsPaginate(dataPaginationGrid.pageSize, dataPaginationGrid.page)) as ShopWPagination
        }
        if (response) {
          updateDataPaginationGrid('rows', response.list)
          updateDataPaginationGrid('rowCount', response.pagination.numberOfRecords)
        }
      } catch (error) {
        // console.log(error)
      } finally {
        updateDataPaginationGrid('loading', false)
      }
    }

    if (type === 'shop') {
      fetch('shop')
      setCols(colsShop)
    } else {
      fetch('carriers')
      setCols(colsCarriers)
    }
  }, [dataPaginationGrid.page, dataPaginationGrid.pageSize, type, refreshCarriersDate, paramsV2.CustomerId])

  return (
    <>
      <DataGrid
        checkboxSelection
        disableSelectionOnClick
        autoHeight
        pagination
        paginationMode='server'
        loading={dataPaginationGrid.loading}
        rowCount={dataPaginationGrid.rowCount}
        page={dataPaginationGrid.page - 1}
        pageSize={dataPaginationGrid.pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        rows={dataPaginationGrid.rows}
        columns={cols || []}
        getRowHeight={() => 51}
        onPageChange={(page: any) => {
          updateDataPaginationGrid('page', page + 1)
        }}
        onPageSizeChange={(pageSize: any) => {
          updateDataPaginationGrid('page', 1)
          updateDataPaginationGrid('pageSize', pageSize)
        }}
      />
    </>
  )
}

export default TableRateSheet

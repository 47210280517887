// ** Type Imports
import { Fragment } from 'react'
import { Divider } from '@mui/material'
import { LayoutProps, NavGroup, NavLink, NavSectionTitle } from 'src/@core/layouts/types'

import VerticalNavGroup from './VerticalNavGroup'
// ** Custom Menu Components
import VerticalNavLink from './VerticalNavLink'
import VerticalNavSectionTitle from './VerticalNavSectionTitle'

interface Props {
  parent?: NavGroup
  navHover?: boolean
  navVisible?: boolean
  groupActive: string[]
  isSubToSub?: NavGroup
  currentActiveGroup: string[]
  navigationBorderWidth: number
  settings: LayoutProps['settings']
  saveSettings: LayoutProps['saveSettings']
  setGroupActive: (value: string[]) => void
  setCurrentActiveGroup: (item: string[]) => void
  verticalNavItems?: LayoutProps['verticalLayoutProps']['navMenu']['navItems']
}

const resolveNavItemComponent = (item: NavGroup | NavLink | NavSectionTitle | any) => {
  if ((item as NavSectionTitle).sectionTitle) return VerticalNavSectionTitle
  if ((item as NavGroup).children) return VerticalNavGroup

  return VerticalNavLink
}

const VerticalNavItems = (props: Props) => {
  // ** Props
  const { verticalNavItems, parent } = props

  const RenderMenuItems = verticalNavItems?.map((item: NavGroup | NavLink | NavSectionTitle, index: number) => {
    const TagName: any = resolveNavItemComponent(item)

    return (
      <Fragment key={index}>
        {parent?.children === undefined ? <Divider sx={{ mt: 4, mb: 4 }} /> : null}
        <TagName {...props} item={item} />
      </Fragment>
    );
    
  })

  return <>{RenderMenuItems}</>
}

export default VerticalNavItems

// ** React Imports
import { forwardRef, ReactElement, Ref } from 'react'
// ** MUI Imports
import Box from '@mui/material/Box'
// import Card from '@mui/material/Card'
// import CardContent from '@mui/material/CardContent'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Fade, { FadeProps } from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
// ** Third Party Imports
// ** Icon Imports
import Icon from 'src/@core/components/icon'
import { AccountCarrierModel } from 'src/types/models/AccountCarriersModels'
import appStore from 'src/zustand/app'

import StepperPayMethod from './StepperPayMethod'

// ** Styled Component Imports
// ** Util Import

const Transition = forwardRef(function Transition(
  props: FadeProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Fade ref={ref} {...props} />
})

type DialogAddProp = {
  carrierSelected: AccountCarrierModel | null
  onFinished: (finish: boolean) => void
  introducedAmount?: number
}

const DialogAddCard = (props: DialogAddProp) => {
  const { carrierSelected, introducedAmount } = props
  const { showPaymentDialog, setShowPaymentDialog } = appStore()

  // const [first, setfirst] = useState(second)

  const handleClose = () => {
    setShowPaymentDialog(false)
  }

  return (
    <Dialog fullWidth open={showPaymentDialog} maxWidth='md' scroll='body' TransitionComponent={Transition}>
      <DialogContent
        sx={{
          position: 'relative',
          pb: theme => `${theme.spacing(8)} !important`,
          px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`],
          pt: theme => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`]
        }}
      >
        <IconButton size='small' onClick={handleClose} sx={{ position: 'absolute', right: '1rem', top: '1rem' }}>
          <Icon icon='mdi:close' />
        </IconButton>
        <Box sx={{ mb: -10, textAlign: 'center' }}>
          <Typography variant='h5' sx={{ mb: 3 }}>
            Credit Recharge
          </Typography>
          <Typography variant='subtitle1' sx={{ mb: 3 }}>
            Current balance: $ {Number(introducedAmount).toLocaleString("en-US")}
            {/* {carrierSelected?.balance === undefined || carrierSelected?.balance === null
              ? '-'
              : carrierSelected?.balance} */}
          </Typography>
          <Typography variant='body2'>Add card for future billing</Typography>
        </Box>
        <StepperPayMethod
          carrierSelected={carrierSelected}
          introducedAmount={introducedAmount}
          onFinished={() => props.onFinished(true)}
        />
      </DialogContent>
    </Dialog>
  )
}

export default DialogAddCard

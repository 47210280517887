import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,TextField, Typography } from "@mui/material"

import GenericButton from "../generic/GenericButton"

type ModalDetailAccount = {
  open: boolean
  setClose: (open: boolean) => void
  data: any | null
}

const ModalDetailAccountServer = (props: ModalDetailAccount) => {
  const [editMode, setEditMode] = useState(false)
  const [data, setData] = useState({
    defaultAccount: false,
    accountNumber: props.data.accountNumber
  })
  const { t } = useTranslation()

  return (
    <Dialog sx={{ '& .MuiPaper-root': { width: 650 } }} open={props.open} onClose={() => props.setClose(false)}>
      <DialogTitle align="center">
        <Typography variant="h4">{props.data.carrierName}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="space-between" alignItems='center' columnGap={2} py={3}>
          <TextField 
            InputLabelProps={{ shrink: true }}
            label={t('ACCOUNT_NUMBER_FULL_TEXT')} 
            variant="outlined" 
            value={data.accountNumber}
            fullWidth
            size="small"
            sx={{ maxWidth: '80%' }}
            disabled={!editMode}
            onChange={(e) => setData({ ...data, accountNumber: e.target.value  })}
          />
          <GenericButton sx={{ width: 'fit-content' }} onClick={() => setEditMode(true)}>{t('EDIT')}</GenericButton>
        </Box>
        {/* <FormGroup>
          <FormControlLabel control={<Checkbox value={data.defaultAccount} />} label='Use default account' />
        </FormGroup> */}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={() => props.setClose(false)}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={() => props.setClose(false)} sx={{color: "white"}}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalDetailAccountServer
//**  Types
import { Theme } from '@mui/material'
import Handsontable from 'handsontable'
import { round } from 'src/utils/Numbers'

const setStyleCellCustomer = (td: HTMLTableCellElement, value: any, table: string, data: any) => {
  const containerDiv = td.querySelector('#container-cell-rendered')
  if (!containerDiv) {
    // El elemento contenedor no existe, se crea y establecen los valores
    const newContainerDiv = document.createElement('div')
    newContainerDiv.id = 'container-cell-rendered'
    newContainerDiv.classList.add('container-cell-rendered')

    const p1 = document.createElement('p')
    p1.classList.add('text-left')
    p1.textContent = `${value.discountPrice} / ${value.basePrice}`

    const p2 = document.createElement('p')
    const p3 = document.createElement('p')
    if (table === 'markup') {
      p2.classList.add('text-center')
      p2.textContent = `${value.markupCustomer}% [${(Number(value.discountPrice) + data.amount).toFixed(2)}]`

      p3.classList.add('text-right')
      p3.textContent = `${data.amount} (${data.percentageWithGain}%)`
    } else if (table === 'discount') {
      p2.classList.add('text-center')
      p2.textContent = `${value.discountCustomer}% [${round(data.amount)}]`

      p3.classList.add('text-right')
      p3.textContent = `${data.rawDiscount} (${round((data.rawDiscount * 100) / value.discountPrice)}%)`
    } else if (table === 'setrate') {
      p2.classList.add('text-center')
      p2.textContent = value.setRateCustomer

      p3.classList.add('text-right')
      p3.textContent = `${data.rawDiscount} (${round((data.rawDiscount * 100) / value.discountPrice)}%)`
    }

    // ** Estilos
    newContainerDiv.style.display = 'flex'
    newContainerDiv.style.flexDirection = 'column'
    newContainerDiv.style.marginTop = '0.4rem'
    p1.style.margin = '0'
    p1.style.padding = '0'
    p1.style.textAlign = 'left'
    p2.style.margin = '0'
    p2.style.padding = '0'
    p2.style.textAlign = 'center'
    p3.style.margin = '0'
    p3.style.padding = '0'
    p3.style.textAlign = 'right'
    p3.style.color = Math.sign(data.rawDiscount) === -1 ? '#C00000' : '#00a152'

    newContainerDiv.appendChild(p1)
    newContainerDiv.appendChild(p2)
    newContainerDiv.appendChild(p3)

    td.appendChild(newContainerDiv)
  } else {
    // El elemento contenedor ya existe, se actualizan los valores dentro del elemento
    const p1 = containerDiv.querySelector('.text-left')
    const p2 = containerDiv.querySelector('.text-center')
    const p3 = containerDiv.querySelector('.text-right')

    p1 && (p1.textContent = `${value.discountPrice} / ${value.basePrice}`)
    if (table === 'markup') {
      p2 && (p2.textContent = `${value.markupCustomer}% [${Number(value.discountPrice) + data.amount}]`)
      p3 && (p3.textContent = `${data.amount} (${data.percentageWithGain}%)`)
    } else if (table === 'discount') {
      p2 && (p2.textContent = `${value.discountCustomer}% [${round(data.amount)}]`)
      p3 && (p3.textContent = `${data.rawDiscount} (${round((data.rawDiscount * 100) / value.discountPrice)}%)`)
    } else if (table === 'setRate') {
      p2 && (p2.textContent = value.setRateCustomer)
      p3 && (p3.textContent = `${data.rawDiscount} (${round((data.rawDiscount * 100) / value.discountPrice)}%)`)
    }
  }
}

const cellRendererRateSheet = (
  _instance: Handsontable.Core,
  td: HTMLTableCellElement,
  _row: number,
  _col: number,
  _prop: string | number,
  value: any,
  _cellProperties: Handsontable.CellProperties,
  typeTable: string
) => {
  if (typeof value === 'object' && value !== null) {
    while (td.firstChild) {
      td.removeChild(td.firstChild)
    }

    // SET DATA ACCORDING TABLE
    if (typeTable === 'markup') {
      const markupNumber = value.markupCustomer
      const amount: number = round((markupNumber * Number(value.discountPrice)) / 100)
      const percentageWithGain = round((amount * 100) / Number(value.discountPrice))

      setStyleCellCustomer(td, value, typeTable, { amount, percentageWithGain })
    } else if (typeTable === 'discount') {
      const amount = value.basePrice - value.discountCustomer * value.basePrice * 0.01
      const rawDiscount: number = round(amount - value.discountPrice)
      setStyleCellCustomer(td, value, typeTable, { amount, rawDiscount })
    } else if (typeTable === 'setrate') {
      const rawDiscount: number = round(value.setRateCustomer - value.discountPrice)
      setStyleCellCustomer(td, value, typeTable, { rawDiscount })
    }
  }

  return td
}

const cellRendererBaseRates = (
  _instance: Handsontable.Core,
  td: HTMLTableCellElement,
  _row: number,
  _col: number,
  _prop: string | number,
  value: any
) => {
  if (typeof value === 'object' && value !== null) {
    while (td.firstChild) {
      td.removeChild(td.firstChild)
    }
    td.style.textAlign = 'center'
    td.style.verticalAlign = 'middle'
    td.textContent = `${value.discountPrice} / ${value.basePrice}`
  }

  return td
}

const changeEditedCellStyle = (value: any, theme: Theme, td: HTMLTableCellElement, table: string) => {
  if (value?.editionMode?.editedCell && value?.editionMode?.table === table) {
    td.style.backgroundColor = theme?.palette.customColors.changesBg || '#a5e1ff'

    return td
  }

  return td
}

export { cellRendererBaseRates, cellRendererRateSheet, changeEditedCellStyle }

import { useForm } from "react-hook-form"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { ShopIntegrationGet } from "src/@http/shopIntegrations"
import { CrudView } from "src/types/forms/CrudView"
import { IntegrationDataObj } from "src/types/models/ShopModels"

import { FormFieldsByJson } from "../generic/FormFieldsByJSON"

type ModalConnectionProps = {
  open: boolean
  setOpen: (open: boolean) => void
  handleActionFormConnection: (shopInt: IntegrationDataObj) => void
  mode?: string
  integration: ShopIntegrationGet | null
}

const ModalConnectionFIntegrations = (props: ModalConnectionProps) => {
  // Form
  const { control, handleSubmit, getValues, reset } = useForm<any>({
    defaultValues: ''
  })

  const handleSaveIntegrationConfig = () => {
    const data = getValues()
    const stringData = JSON.stringify(data)

    const shopInt: IntegrationDataObj = {
      intTradingPlatformId: props.integration?.id,
      vchConnection: stringData
    }

    props.handleActionFormConnection(shopInt)

    props.setOpen(false)
    reset()
  }

  return (
    <Dialog sx={{ '& .MuiPaper-root': { width: 650 } }} open={props.open} onClose={() => props.setOpen(false)}>
      <DialogTitle sx={{ textAlign: 'center', fontSize: '1.5rem !important', }}
      >
        {props.integration?.description || ''}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleSaveIntegrationConfig)}>
          {FormFieldsByJson(props.integration?.vchJsonFormat || '', control)}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setOpen(false)}>Cancel</Button>
        <Button onClick={handleSaveIntegrationConfig}>
          {props.mode && props.mode === 'edit' ? CrudView.EDIT : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalConnectionFIntegrations
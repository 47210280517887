import { Box, Grid } from '@mui/material'

import UserProfile from '../components/profile/UserProfile'

function Profile() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Grid item sx={{ opacity: 0.95, m: 3 }} xs={12} md={6} lg={4}>
            <UserProfile />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Profile

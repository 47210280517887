import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Icon } from "@iconify/react"
import { Accordion as MuiAccordion, AccordionDetails, AccordionProps, AccordionSummary, Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { getObjectSecurityByModule, getPermissionsByObjectSecurityId } from "src/@http/profiles";
import { logger } from "src/utils/Logger";
import { orderAlphabetical } from "src/utils/misc";
import appStore from "src/zustand/app";
import profileStore from "src/zustand/profile";

import GenericButton from "../generic/GenericButton";
import WrapperSecurity from "../generic/WrapperSeurityObject";

import AddEditObjSecurity from "./AddEditObjSecurity";
import TableProfiles from "./TableProfiles";

const RecursiveComponent = ({ items, module, handleClick }: { items: any, module?: number, handleClick?: (success: boolean) => void }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const [permissionObject, setPermissionObject] = useState<any>(null)
  const getPermissionByObject = async (id: number) => {
    try {
      const res = await getPermissionsByObjectSecurityId(id)
      setPermissionObject(res)
    } catch (error) {
      logger.error('Error fetching data:', error);
    }
  }

  const handleAccordionChange = (isExpanded: any, object: any, index: any) => {
    logger.log('Obj', object, 'isExpanded', isExpanded)
    setExpandedIndex(isExpanded ? index : false);
    if (isExpanded) {
      getPermissionByObject(object.id)
    } else {
      setPermissionObject(null)
    }
  };

  return (
    <>
      {
        items ? items.map((ob: any, index: number) => {
          return (
            <Accordion key={index} expanded={expandedIndex === index} onChange={(e: any, expanded: boolean) => handleAccordionChange(expanded, ob, index)}>
              <AccordionSummary expandIcon={<Icon icon="ooui:expand" />} id='panel-header-2' aria-controls='panel-content-2'>
                <HeaderAccordionItem item={ob} handleClickSave={(success: boolean) => handleClick && handleClick(success)} />
              </AccordionSummary>
              <AccordionDetails>
                <TableProfiles permissionsObject={permissionObject} objectSecurity={ob} />
                <RecursiveComponent items={ob.lstSecurityObjectsChilds} module={module} handleClick={(success: boolean) => handleClick && handleClick(success)}  />
                <FormAddObjectSecurity 
                  extraData={{
                    tinModuleId: module,
                    intSecurityObjectFatherId: ob.id,
                    tinSecurityObjecType: ob.tinSecurityObjecType
                  }} 
                  sx={{ py: 3 }} 
                  handleClickSave={(success: boolean) => handleClick && handleClick(success)} 
                />
              </AccordionDetails>
            </Accordion>
          )
        }) : null
      }
    </>
  );
};

const AccordionLevelsPages = () => {
  const { t } = useTranslation()
  const { loading, setLoading } = appStore()
  const { modules, setProfilesByModule } = profileStore()
  const [moduleIdSelected, setModuleIdSelected] = useState(1)
  const [levels, setDataLevels] = useState<any[]>([])
  const [refreshObjList, setRefreshObjList] = useState(false)

  const getObjectSecurityByModuleId = async (moduleId: number, modules: any) => {
    await getObjectSecurityByModule(moduleId)
      .then((objList: any) => {
        if (objList && objList.length > 0) {
          const levels = modules.map((module: any) => {
            return {
              id: module.id,
              name: module.description,
              pages: objList.find((ob: any) => ob.tinModuleId === module.id) 
                ? orderAlphabetical(objList.filter((ob: any) => ob.tinModuleId === module.id), 'vchName') 
                : null
            }
          })
          setDataLevels(levels)
        }
      })
      .catch((err: any) => logger.log('error', err))
    setLoading(false);
  }

  const getData = () => {
    setLoading(true);
    try {
      if (modules && modules.length > 0) {
        const firstTabId = modules.find((module: any) => module.id === 1).id || 1 // First EZ
        getObjectSecurityByModuleId(firstTabId, modules)
      } else {
        throw "Error getting modules"
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
    setProfilesByModule(moduleIdSelected)
  }, []);

  useEffect(() => {
    getObjectSecurityByModuleId(moduleIdSelected, modules)
    setProfilesByModule(moduleIdSelected)
  }, [moduleIdSelected]);

  useEffect(() => {
    if (refreshObjList) getObjectSecurityByModuleId(moduleIdSelected, modules)
  }, [refreshObjList])

  const [value, setValue] = useState(0);

  const handleChange = (e: any, newValue: number) => {
    setValue(newValue);
    setModuleIdSelected(modules[newValue].id)
  };

  return (
    <>
      {loading || levels.length === 0
        ? <Typography variant="subtitle2" color="primary">{t('LOADING')}...</Typography>
        :
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              {
                levels.map(({ id, name }) => {
                  return (
                    <Tab key={id} label={name} />
                  )
                })
              }
            </Tabs>
          </Box>
          <RecursiveComponent items={levels[value].pages} handleClick={(success: boolean) => setRefreshObjList(success)} module={moduleIdSelected}  />
          <FormAddObjectSecurity 
            extraData={{
              tinModuleId: moduleIdSelected,
              intSecurityObjectFatherId: null,
              tinSecurityObjecType: null
            }} 
            sx={{ pl: 3 }} 
            handleClickSave={(success: boolean) => setRefreshObjList(success)} 
          />
        </>
      }
    </>
  )
}

const Accordion = styled(MuiAccordion)<AccordionProps>(() => ({
  boxShadow: 'none !important',
  border: '1px solid #d8d8d850',
  borderRadius: '0 !important',
  overflow: 'hidden',
  '&:not(:first-of-type)': { borderBottom: '0 !important' },
  '&:before': { display: 'none' },
  '&.Mui-expanded': { margin: 'auto' },
}))

const HeaderAccordionItem = ({ item, handleClickSave }: any) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = (success: boolean) => {
    setIsEditing(false);
    handleClickSave(success)
  };

  return (
    <>
      {
        isEditing ? <AddEditObjSecurity objectSecurity={item} handleSuccessAction={handleSaveClick} />
          : (
            <Stack flexDirection='row' columnGap={4} alignItems='center'>
              <Typography variant="caption" textTransform='uppercase' color='secondary' fontSize={14}>{item.vchName}</Typography>
              {item.vchObjectDescription ? <Typography variant="overline" color='gray' fontSize={14}>- {item.vchObjectDescription}</Typography> : null}
              <WrapperSecurity securityKey="edit objsecurity btn"><Icon color="#D6A51D" icon="mdi:pencil" onClick={handleEditClick} /></WrapperSecurity>
            </Stack>
          )
      }
    </>
  )
}

const FormAddObjectSecurity = ({ extraData, sx, handleClickSave }: any) => {
  const { tinSecurityObjecType, intSecurityObjectFatherId } = extraData
  const [isAdd, setIsAdd] = useState(false);
  const { t } = useTranslation()

  const handleNewObjectSecurity = () => {
    setIsAdd(true)
  }

  const handleSaveClick = (success: boolean) => {
    setIsAdd(false);
    handleClickSave(success)
  };

  return (
    <Stack>
      <WrapperSecurity securityKey='add objsecurity btn'>
        <GenericButton
          sx={{
            '&.MuiButton-contained': { backgroundColor: intSecurityObjectFatherId ? "customColors.terciary" : "primary.main" },
            '&.Mui-disabled': { backgroundColor: grey[600] },
            m: 3
          }}
          disabled={!tinSecurityObjecType || (tinSecurityObjecType === 4 || tinSecurityObjecType === 5) ? false : true} // Only add if is Page or Menu Option
          onClick={handleNewObjectSecurity}
          icon="material-symbols:add"
        >
          {t('ADD_NEW_SECURITY_OBJECT')}
        </GenericButton>
      </WrapperSecurity>
      {isAdd && (
        <Stack display='flex' flexDirection='row' columnGap={2} sx={sx}>
          <AddEditObjSecurity extraData={extraData} handleSuccessAction={handleSaveClick} />
        </Stack>
      )}
    </Stack>
  )
}

export default AccordionLevelsPages;
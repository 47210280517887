import { MouseEvent, useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import { Box, Button, Popover, Stack, Typography } from '@mui/material'
import Fab from '@mui/material/Fab'
import { IDate } from 'src/views/filters/DateFilter'
import appStore from 'src/zustand/app'

import CustomOrdersFiltersPanel from './CustomOrdersFilters'

const filters: { text: string; icon: string; id: number }[] = [
  {
    text: '30 days',
    icon: 'ep:trend-charts',
    id: 1
  },
  {
    text: '90 days',
    icon: 'fluent:convert-range-24-regular',
    id: 2
  },
  {
    text: '6 months',
    icon: 'healthicons:i-schedule-school-date-time-outline',
    id: 3
  },
  {
    text: '1 year',
    icon: 'bx:customize',
    id: 4
  },
  {
    text: 'Custom',
    icon: 'bx:customize',
    id: 5
  }
]

function getDateXDaysAgo(daysAgo: number) {
  const today = new Date()
  const result = new Date(today)
  result.setDate(today.getDate() - daysAgo)

  const year = result.getFullYear()
  const month = (result.getMonth() + 1).toString().padStart(2, '0')
  const day = result.getDate().toString().padStart(2, '0')

  return `${month}/${day}/${year}`
}

const FiltersOrders = ({ setFilter }: { setFilter?: (param: any) => void }) => {
  const [filterState, setFilterState] = useState(filters[0])
  const [showCustom, setShowCustom] = useState<boolean>(false)
  const [dateCustom, setDateCustom] = useState<IDate>({ dateFrom: new Date(), dateTo: new Date() })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClickMobileMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleFilterDate = (date: IDate) => {
    setDateCustom(date)
    if (setFilter) {
      setFilter({ StartDate: date.dateFrom, EndDate: date.dateTo })
    }
  }

  const handleFilterCustom = () => setShowCustom(false)
  const { setOrderFilterStartDate, setOrderFilterEndDate } = appStore(state => ({
    setOrderFilterStartDate: state.setOrderFilterStartDate,
    setOrderFilterEndDate: state.setOrderFilterEndDate
  }))
  useEffect(() => {
    setOrderFilterStartDate(
      (() => {
        const currentDate = new Date()
        currentDate.setDate(currentDate.getDate() - 30)

        return currentDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
      })()
    )
    setOrderFilterEndDate(new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }))
  }, [setOrderFilterStartDate, setOrderFilterEndDate])
  const listFilter = filters.map((filter: any) => (
    <Fab
      key={filter.text}
      onClick={() => handleFilter(filter)}
      color={filter.id === filterState.id ? 'primary' : 'secondary'}
      aria-label='edit'
      size='small'
      variant='extended'
      sx={{ '& svg': { mr: 1 }, px: 3, mx: 2 }}
      style={{ width: 'max-content' }}
    >
      <Icon icon={filter.icon} color="white" />
      <Box sx={{ color: 'white' }}>{filter.text}</Box>
      
    </Fab>
  ))

  const handleFilter = (item: any) => {
    setFilterState(item)
    switch (item.id) {
      case 1: //30 days
        setShowCustom(false)
        setOrderFilterStartDate(getDateXDaysAgo(30).toString())
        setOrderFilterEndDate(getDateXDaysAgo(0).toString())
        if (setFilter) {
          setFilter({ StartDate: getDateXDaysAgo(30).toString(), EndDate: getDateXDaysAgo(0).toString() })
        }
        break
      case 2: //60 days
        setShowCustom(false)
        setOrderFilterStartDate(getDateXDaysAgo(60).toString())
        setOrderFilterEndDate(getDateXDaysAgo(0).toString())
        if (setFilter) {
          setFilter({ StartDate: getDateXDaysAgo(60).toString(), EndDate: getDateXDaysAgo(0).toString() })
        }
        break
      case 3: //90 days
        setShowCustom(false)
        setOrderFilterStartDate(getDateXDaysAgo(90).toString())
        setOrderFilterEndDate(getDateXDaysAgo(0).toString())
        if (setFilter) {
          setFilter({ StartDate: getDateXDaysAgo(90).toString(), EndDate: getDateXDaysAgo(0).toString() })
        }
        break
      case 4: //1 year
        setShowCustom(false)
        setOrderFilterStartDate(getDateXDaysAgo(365).toString())
        setOrderFilterEndDate(getDateXDaysAgo(0).toString())
        if (setFilter) {
          setFilter({ StartDate: getDateXDaysAgo(365).toString(), EndDate: getDateXDaysAgo(0).toString() })
        }
        break
      case 5: //Custom
        setShowCustom(true)
        break
      default:
        setShowCustom(false)
    }
  }

  const id = Boolean(anchorEl) ? 'popover-basic' : undefined
  const popoverContent = filters.map((item, index) => (
    <Typography key={index} sx={{ p: 2 }} onClick={() => handleFilter(item)}>
      {item.text}
    </Typography>
  ))

  return (
    <Stack sx={{ width: '100%' }}>
      <Stack flexDirection='row' justifyContent='center' display={{ xs: 'none', md: 'flex' }}>
        {listFilter}
      </Stack>
      <Stack flexDirection='row' justifyContent='flex-start' display={{ xs: 'flex', md: 'none' }}>
        <Button variant='contained' onClick={handleClickMobileMenu} sx={{ minWidth: '150px' }}>
          Filters
        </Button>
        <Popover
          id={id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          PaperProps={{ sx: { width: '150px' } }}
        >
          {popoverContent}
        </Popover>
      </Stack>
      {showCustom && (
        <CustomOrdersFiltersPanel sx={{ mt: 4 }} handleDates={handleFilterDate} handleConfirm={handleFilterCustom} />
      )}
    </Stack>
  )
}

export default FiltersOrders

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import ClearAllIcon from '@mui/icons-material/ClearAll' // Importa el icono ClearAll de MUI
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { getLSShipp } from 'src/@http/labels-server'
import { desiredRoutes } from 'src/types/enums/PagesByLevel'
import { getKeyObjectSecurity } from 'src/utils/misc'
import appStore from 'src/zustand/app'
import labelStore from 'src/zustand/label'

import SelectorCourierMultiple from '../components/carrier/SelectorCourierMultiple'
import GenericButton from '../components/generic/GenericButton'
import InputSearch from '../components/generic/InputSearch'
import ServerDataGrid, { ServiceDataGridProps } from '../components/generic/ServerDataGrid'
import WrapperSecurity from '../components/generic/WrapperSeurityObject'
import CustomOrdersFiltersPanel from '../components/orderEditor/CustomOrdersFilters'
import { IDate } from '../components/orderEditor/DatefilterOrder'

import { columnsShipp } from './shipp/columns'

const initialDates: IDate = { dateFrom: null, dateTo: null }
type FilterDataProp = {
  dates: IDate
  courierId: number
  trackingNumber: string
  moduleId: number
}

const initialFilters = {
  dates: initialDates,
  courierId: 0,
  trackingNumber: '',
  moduleId: 0
}

function isSameDate(dateString: string | Date): boolean {
  const currentDate = new Date()
  const inputDate = new Date(dateString)
  const currentDateFormatted = currentDate.toISOString().split('T')[0]
  const inputDateFormatted = inputDate.toISOString().split('T')[0]

  return currentDateFormatted === inputDateFormatted
}

const NewShipp = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleAddNewShipp = () => {
    navigate('/client-labels/shipp/new')
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} textAlign='right'>
        <WrapperSecurity securityKey={getKeyObjectSecurity({
          CLIENT: 'btn_add_newshipp_client'
        })}>
          <GenericButton onClick={handleAddNewShipp}>{t('ADD_NEW_SHIPP')}</GenericButton>
        </WrapperSecurity>
      </Grid>
    </Grid>
  )
}

const Shipp = () => {
  const [showFilterByDate, setShowFilterByDate] = useState(false)
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const [filterData, setFilterData] = useState<FilterDataProp>({
    dates: initialDates,
    courierId: 0,
    trackingNumber: '',
    moduleId: 0
  })

  const { refreshShippGridDate, setRefreshShippGridDate } = labelStore()

  const service = async ({ currectPage, pageSize }: ServiceDataGridProps) => {
    function level() {
      if (desiredRoutes.CLIENT?.includes(pathname)) return 3
      if (desiredRoutes.CUSTOMER?.includes(pathname)) return 2

      return 0
    }

    const response = await getLSShipp({
      PageSize: pageSize,
      CurrentPage: currectPage,
      startDate: filterData.dates.dateTo,
      endDate: filterData.dates.dateFrom,
      carrierIds: filterData.courierId ? filterData.courierId : [],
      trackingNumber: filterData.trackingNumber,
      moduleId: level(),
      customerIds: [paramsV2.CustomerId]
    })
    console.log('print return service function', response)

    return response
  }


  const paramsV2 = appStore((store: { paramsV2: any }) => {
    return store.paramsV2
  })

  useEffect(() => {
    setRefreshShippGridDate()
  }, [paramsV2])

  const handleFilterCustom = () => {
    setShowFilterByDate(false)
    const { dateFrom, dateTo } = filterData.dates
    if (dateFrom && dateTo && isSameDate(dateFrom) && isSameDate(dateTo)) {
      setFilterData({ ...filterData, dates: initialDates })
    }
    handleFilters('dates')
  }

  const handleFilterDate = (date: IDate) => setFilterData({ ...filterData, dates: date })

  const handleFilters = async (prop: string, value?: any) => {
    if (prop && prop !== 'dates') {
      setFilterData({ ...filterData, [prop]: value })
    }
    if (prop === 'trackingNumber' && String(value).length >= 4 && value !== filterData.trackingNumber)
      setRefreshShippGridDate()
    if (prop !== 'trackingNumber') setRefreshShippGridDate()
  }

  const handleClearFilters = () => {
    setFilterData(initialFilters)
    setRefreshShippGridDate()
  }

  const filtersApplied = Object.values(filterData).some(value => value !== null && value !== '')

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h5' sx={{ mx: 4, my: 5 }}>
            {t('LABEL')}
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            {desiredRoutes.CLIENT.includes(pathname) && <NewShipp />}
          </Box>
        </Box>
        <Box display='flex' maxWidth='800px' mx='auto' alignItems='center' columnGap={2} mt={2} mb={9}>
          <SelectorCourierMultiple
            title={'Courier'}
            onHandleSelectedOptions={options => handleFilters('courierId', options)}
            size='small'
            sx={{ maxWidth: '180px' }}
          />
          <GenericButton onClick={() => setShowFilterByDate(!showFilterByDate)} sx={{ minWidth: '210px' }}>
            {t('FILTER_BY_DATE')}
          </GenericButton>
          <InputSearch
            value={filterData.trackingNumber}
            onChange={e => handleFilters('trackingNumber', e)}
            placeholder='by tracking number'
            sx={{ minWidth: '200px' }}
          />
          <GenericButton
            onClick={handleClearFilters}
            sx={{ minWidth: '60px', borderRadius: '50%' }}
            disabled={!filtersApplied}
          >
            <ClearAllIcon />
          </GenericButton>
        </Box>
        {showFilterByDate && (
          <Box mb={3}>
            <CustomOrdersFiltersPanel
              sx={{ mt: 4 }}
              handleDates={handleFilterDate}
              handleConfirm={handleFilterCustom}
            />
          </Box>
        )}
        <Box>
          <ServerDataGrid
            columns={columnsShipp}
            service={service}
            refreshDate={refreshShippGridDate}
            checkboxSelection={false}
            rowHeight={70}
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                textOverflow: 'clip',
                whiteSpace: 'break-spaces',
                lineHeight: 1
              },
              '& .MuiDataGrid-cell > .MuiDataGrid-cellContent': {
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                lineHeight: 1
              }
            }}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default Shipp

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import { FormControl, InputLabel, MenuItem } from '@mui/material'
import Select from '@mui/material/Select'
import { UserType } from 'src/@http/user'
import userStore from 'src/zustand/user'

interface UserTypeSelectorProps {
  handleSelected: (ut: any) => any
  defaultValue?: any
  disabled?: boolean
}

const SelectUserTypes = ({ handleSelected, defaultValue, disabled }: UserTypeSelectorProps) => {
  const [userTypes, setUserTypes] = useState<UserType[]>([])
  const [usertypesSelected, setUserTypesSelected] = useState<any>(defaultValue || '')
  const { listUserTypes } = userStore()

  const handleUserTypes = (e: any) => {
    setUserTypes(e.target.value)
    handleSelected(e.target.value)
  }

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id='ut-label'>User Type</InputLabel>
        <Select
          label='User Type'
          value={usertypesSelected || ''}
          labelId='wh-label'
          onChange={handleUserTypes}
          disabled={disabled}
        >
          {listUserTypes.map((usertype: UserType, i: number) => (
            <MenuItem key={i} value={usertype.id}>
              {usertype.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default SelectUserTypes

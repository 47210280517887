// ** MUI Imports
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Typography, useMediaQuery } from '@mui/material'
import { IconButton } from '@mui/material'
import { Box, Fab } from '@mui/material'
// import IconButton from '@mui/material/IconButton'
// ** Icon Imports
import Icon from 'src/@core/components/icon'
// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'
import LanguageDropdown from 'src/@core/layouts/components/shared-components/LanguageDropdown'
// ** Components
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import AutocompleteClient from 'src/pages/components/clients/AutocompleteClient'
import AutocompleteProps from 'src/pages/components/companies/AutocompleteCompany'
import profileStore from 'src/zustand/profile'

import UserDropdown from '../shared-component/UserDropdown'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
}

const AppBarContent = (props: Props) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))
  const { hidden, settings, saveSettings, toggleNavVisibility } = props
  const { profile } = profileStore()

  // Type of user who logs in - Enabled or disabled
  const managePermissionsUseSelectors = (typeSelector: string) => {
    if (profile?.tinUserType === 1) return false // Enable both selectors
    else if (profile?.tinUserType === 2) {
      // Enable only client selector
      if (typeSelector === 'client') return false
      else if (typeSelector === 'customer') return true
    } else return profile?.tinUserType === 3
  }

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 4 }}>
      <Box className='actions-left' sx={{ ml: 2, display: 'flex', alignItems: 'center', columnGap: isMobile ? 1 : 2 }}>
        {hidden ? (
          <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
            <Icon icon='mdi:menu' />
          </IconButton>
        ) : null}
        <ButtonBack />
        {managePermissionsUseSelectors('customer') === false ? <AutocompleteProps addViewMobile={isMobile} size='small' /> : null}
        {managePermissionsUseSelectors('customer') === false ? <AutocompleteClient
          addViewMobile={isMobile}
          size='small'
          label={t('CLIENT')}
          onSelectClient={(client: any) => console.log('Shop:', client)}
        /> : null}
      </Box>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
        <ModeToggler settings={settings} saveSettings={saveSettings} />
        <LanguageDropdown settings={settings} saveSettings={saveSettings} />
        <Typography variant='body2'>
          {profile?.vchFullName} {isMobile && <br />} ({profile?.listUserRole[0].vchNameRole})
        </Typography>
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  )
}

const ButtonBack = () => {
  const pathname = window.location.pathname
  const navigate = useNavigate()

  if (pathname !== '/' && pathname !== '/dashboard') {
    return (
      <Fab color='primary' size='small' onClick={() => navigate(-1)} aria-label='Back'>
        <Icon icon={'bx:arrow-back'} color="white" fontSize='2rem' />
      </Fab>
    )
  } else return null
}

export default AppBarContent

import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import {
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { getServicesByServiceId } from 'src/@http/carriers'
import { deleteWeigth, updateWeigth } from 'src/@http/carriers'
import { deleteWeigthHTTP } from 'src/@http/weigth'
import MeasureList from 'src/pages/components/services-editor/MeasureList'
import { CrudView } from 'src/types/forms/CrudView'
import { ServicesCarrierModel } from 'src/types/models/ServicesCarrierModel'
import { logger } from 'src/utils/Logger'
import servicesStore from 'src/zustand/services'

import { WeigthModel } from '../../../types/models/SheetModels'
import CustomToolbarAdminGrid from '../generic/CustomToolbarAdminGrid'
import { DialogExpose, DialogGeneric } from '../generic/DialogGeneric'
import { IconAction } from '../rateSheets/TableRateSheet'

import { colorAccordingStatus, Status } from './ColorStatus'

const initialRows: WeigthModel = {
  id: 0,
  intServiceWeigthId: 0,
  vchCodWeigth: null,
  intCarrierId: 0,
  vchCarrierName: null,
  intServiceId: 0,
  vchServiceName: null,
  decWeigthMin: 0,
  decWeigthMax: 0,
  tinUnitMeasureId: 0,
  vchUnitMeasureName: null,
  intStatusId: 0,
  vchStatusName: null,
  intCreatedUserId: 0,
  intUpdatedUserId: 0,
  dtmCreatedDate: null,
  dtmUpdatedDate: null
}

const RowOptions = (props: {
  row: any
  editWeight: (editedData: WeigthModel) => void
  handleDeleteRow: (rowid: number) => void
}) => {
  const { row, editWeight, handleDeleteRow } = props
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const color = colorAccordingStatus(row.vchStatusName)

  return (
    <>
      <IconAction
        title={t('EDIT')}
        icon={'ep:edit'}
        sizeIcon={22}
        onClick={() => setOpen(true)}
        color={color}
        disabled={row.intStatusId !== Status.ACTIVE}
      />
      <IconAction
        title={t('DELETE')}
        icon={'ic:round-delete'}
        disabled={row.intStatusId === Status.DELETED}
        sizeIcon={22}
        onClick={() => handleDeleteRow(row.id)}
        color='error'
      />
      <ModalAddEdit
        open={open}
        setOpen={open => setOpen(open)}
        handleWeight={data => editWeight(data)}
        data={row}
        view={CrudView.EDIT}
      />
    </>
  )
}

interface ModalAddEditProps {
  handleWeight: (data: any, moreThanOne?: boolean) => void
  data?: WeigthModel
  view?: string
  open: boolean
  setOpen: (open: boolean) => void
}

const ModalAddEdit = (props: ModalAddEditProps) => {
  const { t } = useTranslation()
  const { handleWeight, view, data, open, setOpen } = props
  const [typeWeight, setTypeWeight] = useState<boolean>(false)
  const { setMeasure, setMeasureName, weightDtos } = servicesStore()
  const formRangeWeigths = useForm({
    defaultValues: { initialValue: 0, finalValue: 0, increase: 1 }
  })
  const form = useForm<WeigthModel>({
    defaultValues: view === CrudView.ADD ? initialRows : data
  })

  const {
    handleSubmit: handleSubmitRange,
    register: registerRange,
    formState: { errors: errorRange }
  } = formRangeWeigths
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty }
  } = form

  const rules = {
    validate: {
      greaterThanOrEqualMin: (value: number | string) => {
        const numericValue = Number(value)
        const min = Number(getValues('decWeigthMin'))

        return !isNaN(numericValue) && !isNaN(min) && numericValue >= min
      },
      verifyExistence: (value: string | null) => {
        const exist = value ? weightDtos.find(w => w.vchCodWeigth === value) !== undefined : false

        return exist ? false : true
      }
    }
  }

  const isSaveButtonDisabled = Object.keys(errors).length > 0 || !isDirty
  const close = () => setOpen(false)

  const onSubmit = (data: any) => {
    data.decWeigthMax = Number(data.decWeigthMax)
    data.decWeigthMin = Number(data.decWeigthMin)
    data.intStatusId = Status.ACTIVE
    data.vchStatusName = 'Active'
    handleWeight(data, false)
    setMeasure(1)
    setMeasureName('Pounds')
    setOpen(false)
  }

  const onSubmitRange = (data: any) => {
    const { initialValue, finalValue, increase } = data
    const dataArray = []
    let currentValue = initialValue

    while (currentValue <= finalValue) {
      const dataweight = {
        decWeigthMax: currentValue,
        decWeigthMin: currentValue,
        intStatusId: Status.ACTIVE,
        vchStatusName: 'Active',
        vchCodWeigth: 'Weight ' + currentValue
      }

      dataArray.push(dataweight)

      currentValue += increase
    }

    handleWeight(dataArray, true)
    setMeasure(1)
    setMeasureName('Pounds')
    setOpen(false)
    setTypeWeight(false)
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title' display='flex' flexDirection='row' justifyContent='space-between'>
        <Typography variant='h6'>{view === CrudView.ADD ? t(`${CrudView.ADD}`) : t(`${CrudView.EDIT}`)}</Typography>
        {view !== CrudView.EDIT && (
          <Stack display='flex' flexDirection='row' alignItems='center'>
            <Typography variant='body2'>{t('VALUE')}</Typography>
            <Switch color='primary' id="switch-format-create" onChange={e => setTypeWeight(e.target.checked)} />
            <Typography variant='body2'>{t('RANGE')}</Typography>
          </Stack>
        )}
      </DialogTitle>
      <form>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Grid py={2} container rowGap={5}>
              {!typeWeight && (
                <>
                  <Grid item xs={12} md={6}>
                  {view === CrudView.ADD ? (
                    <MeasureList onSelectMeasure={measure => logger.log('Measure en padre:', measure)} />
                  ) : (
                    <MeasureList disabled onSelectMeasure={measure => logger.log('Measure en padre:', measure)} />
                  )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {view === CrudView.ADD ? (
                      <TextField
                        fullWidth
                        sx={{ marginLeft: '10px', pr: 2 }}
                        label='Weigth'
                        error={!!errors.vchCodWeigth}
                        helperText={errors.vchCodWeigth && t('WEIGTH_ALREADY_EXISTS')}
                        {...register('vchCodWeigth', {
                          required: true,
                          validate: value => rules.validate.verifyExistence(value)
                        })}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        sx={{ marginLeft: '10px', pr: 2 }}
                        label='Weigth'
                        disabled
                        defaultValue={data?.vchCodWeigth}
                      />
                    )
                    }
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      type='number'
                      sx={{ pr: 2 }}
                      label='Minimum'
                      {...register('decWeigthMin', { required: true })}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      type='number'
                      sx={{ pr: 2 }}
                      label='Maximum'
                      {...register('decWeigthMax', {
                        validate: value => rules.validate.verifyExistence(value.toString())
                      })}
                      error={!!errors.decWeigthMax}
                      helperText={errors.decWeigthMax && 'Maximum value must be greater than minimum value'}
                    />
                  </Grid>
                </>
              )}
              {typeWeight && (
                <>
                  <Grid item xs={12} md={12}>
                    <MeasureList onSelectMeasure={measure => logger.log('Measure en padre:', measure)} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      type='number'
                      sx={{ pr: 2 }}
                      label='Initial Value'
                      error={!!errorRange.initialValue}
                      helperText={errorRange.initialValue && t('WEIGTH_ALREADY_EXISTS')}
                      {...registerRange('initialValue', {
                        valueAsNumber: true,
                        validate: value => rules.validate.verifyExistence(value.toString())
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      type='number'
                      sx={{ pr: 2 }}
                      label='Final Value'
                      error={!!errorRange.finalValue}
                      helperText={errorRange.finalValue && t('WEIGTH_ALREADY_EXISTS')}
                      {...registerRange('finalValue', {
                        valueAsNumber: true,
                        validate: value => rules.validate.verifyExistence(value.toString())
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      type='number'
                      sx={{ pr: 2 }}
                      label='Increase'
                      {...registerRange('increase', { valueAsNumber: true })}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!typeWeight ? (
            <Button disabled={isSaveButtonDisabled} onClick={handleSubmit(onSubmit)}>
              {t('SAVE')}
            </Button>
          ) : (
            <Button onClick={handleSubmitRange(onSubmitRange)}>{t('SAVE')}</Button>
          )}
          <Button color='secondary' onClick={close} autoFocus>
            {t('CANCEL')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

type propModalWeight = { typeUser?: string; serviceId: number }

const ModalWeigthManagment = (props: propModalWeight) => {
  const { setWeightDtos, weightDtos, measure, measureName } = servicesStore(state => ({
    setWeightDtos: state.setWeightDtos,
    weightDtos: state.weightDtos,
    measure: state.measure,
    measureName: state.measureName
  }))
  const [filter, setFilter] = useState<string | null>(null)
  const { serviceId } = props
  const [loading, setLoading] = useState(false)
  const dialogRef = useRef<DialogExpose>(null)
  const [rows, setRows] = useState<WeigthModel[]>([])
  const [open, setOpen] = useState(false)
  const { state } = useLocation()

  const { t } = useTranslation()

  const columns: GridColumns = [
    { field: 'vchCodWeigth', headerName: 'Weigth', flex: 1, maxWidth: 300 },
    { field: 'vchUnitMeasureName', headerName: 'Measure', flex: 1, filterable: true },
    { field: 'decWeigthMin', headerName: 'Minimum', flex: 1 },
    { field: 'decWeigthMax', headerName: 'Maximum', flex: 1 },
    {
      field: 'vchStatusName',
      align: 'center',
      headerName: 'Status',
      headerAlign: 'center',
      flex: 1,
      renderCell: ({ row }: { row: any }) => {
        const color = colorAccordingStatus(row.vchStatusName)

        return <Chip label={row.vchStatusName} color={color} />
      }
    },
    {
      field: '',
      headerName: 'Action',
      align: 'center',
      flex: 1,
      minWidth: 180,
      maxWidth: 180,
      headerAlign: 'center',
      renderCell: ({ row }: { row: any }) => (
        <RowOptions row={row} editWeight={editWeight} handleDeleteRow={handleDeleteRow} />
      )
    }
  ]

  const generateUniqueId = () => {
    return Math.random().toString(36).substring(2) + Date.now().toString(36)
  }

  const editWeight = (editedData: WeigthModel) => {
    const rowIndex = rows.findIndex(item => item.id === editedData.id)
    if (rowIndex !== -1) {
      const updatedRows = [...rows]
      updatedRows[rowIndex] = editedData
      setRows(updatedRows)

      const weightIndex = weightDtos.findIndex(item => item.id === editedData.id)
      if (weightIndex !== -1) {
        const updatedWeightDto = [...weightDtos]
        updatedWeightDto[weightIndex] = editedData
        setWeightDtos(updatedWeightDto)
      }
    }
    setOpen(false)
  }
  const handleDeleteRow = async (rowId: number) => {
    dialogRef.current?.open().then(async confirm => {
      if (confirm) {
        const updatedRows = rows.filter(row => row.id !== rowId)
        setRows(updatedRows)
        const updatedWeightDtos = weightDtos.filter(weight => weight.id !== rowId)
        setWeightDtos(updatedWeightDtos)
        try {
          const resp = await deleteWeigthHTTP(rowId)
        } catch (err: any) {
          const updatedRows = rows.filter(row => row.id !== rowId)
          setRows(updatedRows) // esto es un parche horrible pero anda.
        }
      }
    })
  }

  const setExtraData = (row: any) => {
    const { data } = state.data
    row.id = data?.id
      ; (row.intServiceWeigthId = data?.intServiceWeigthId), (row.intCarrierId = data?.intCarrierId)
    row.vchCarrierName = data?.Carrier
    row.intCreatedUserId = data?.intCreatedUserId
    row.idserviceId = serviceId
    row.tinUnitMeasureId = measure
    row.vchUnitMeasureName = measureName

    return row
  }

  const setExtraDataNew = (row: any) => {
    const { data } = state.data
    row.id = generateUniqueId()
    row.intCarrierId = data?.intCarrierId
    row.vchCarrierName = data?.Carrier
    row.intCreatedUserId = data?.intCreatedUserId
    row.idserviceId = serviceId
    row.tinUnitMeasureId = measure
    row.vchUnitMeasureName = measureName

    return row
  }

  const handleDialog = () => setOpen(true)
  const addWeight = async (newData: any, moreThanOne?: boolean) => {
    if (moreThanOne) {
      const updatedRows = newData.map((weight: WeigthModel) => setExtraDataNew(weight))
      setRows([...rows, ...updatedRows])
      setWeightDtos([...rows, ...updatedRows])
    } else {
      const data = setExtraDataNew(newData)
      setRows([...rows, data])
      setWeightDtos([...rows, data])
    }
  }

  const handleRows = (row: WeigthModel, mode: string) => {
    if (mode === CrudView.DELETE) {
      dialogRef.current?.open().then(async confirm => {
        if (confirm) {
          deleteZoneSelected(row.id ? row.id : 0)
        }
      })
    }
    if (mode === CrudView.EDIT) {
      updateWeigthSelected(setExtraData(row))
    } else if (mode === 'StatusRow') {
      const data = setExtraData(row)
      updateWeigthSelected({
        ...data,
        intStatusId: row.intStatusId === Status.ACTIVE ? Status.INACTIVE : Status.ACTIVE,
        vchStatusName: row.intStatusId === Status.ACTIVE ? 'Inactive' : 'Active',
        dtmUpdatedDate: new Date().toISOString().toString()
      })
    }
  }

  const getListWeight = async () => {
    setLoading(true)

    if (serviceId !== 0) {
      try {
        const response = (await getServicesByServiceId(serviceId)) as unknown as ServicesCarrierModel
        if (response.weigthListDtos) {
          const filteredRows = filter
            ? response.weigthListDtos.filter(row => row.vchUnitMeasureName === filter)
            : response.weigthListDtos
          setWeightDtos(response.weigthListDtos)
          setRows(filteredRows)
        }
        setLoading(false)
      } catch (err: any) {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }

  const updateWeigthSelected = async (body: WeigthModel) => {
    try {
      await updateWeigth(body).then(async () => {
        await getListWeight()
      })
    } catch (error) {
      // console.log('[Error]', error)
    }
  }
  const { resetZonesAndWeigths } = servicesStore()
  const deleteZoneSelected = async (weigthId: number) => {
    try {
      await deleteWeigth(weigthId).then(async () => {
        await getListWeight()
      })
      toast.success(t('DELETED_SUCCESSFULLY') + '! 👌', { duration: 4000 })
    } catch (err: any) {
      toast.error(err.DescriptionError + '🙄', { duration: 4000 })
    }
  }

  useEffect(() => {
    setRows(weightDtos || [])
  }, [])

  useEffect(() => {
    getListWeight()
  }, [resetZonesAndWeigths])

  return (
    <>
      <Stack display='flex'>
        <Card sx={{ width: '100%', boxShadow: 'none' }}>
          <CardContent>
            <Stack sx={{ mt: 2 }} spacing={2} direction='row' justifyContent='space-between' alignItems='center'>
              <Button style={{ color: 'white' }} variant='contained' size='small' onClick={handleDialog}>
                {t('ADD')}
              </Button>
            </Stack>
            <DataGrid
              autoHeight
              columns={columns}
              style={{ width: '100%' }}
              rows={rows}
              getRowClassName={rows => (rows.row ? 'disabled-row' : '')}
              pageSize={15}
              loading={loading}
              components={{ Toolbar: CustomToolbarAdminGrid }}
            />
          </CardContent>
        </Card>
      </Stack>
      <ModalAddEdit
        open={open}
        setOpen={open => setOpen(open)}
        handleWeight={(data, moreThanOne) => addWeight(data, moreThanOne)}
        view={CrudView.ADD}
      />
      <DialogGeneric title='DELETE' ref={dialogRef} confirmText='CONFIRM' maxWidth={500}>
        {t('CONFIRM_DELETE')}
      </DialogGeneric>
    </>
  )
}

export default ModalWeigthManagment

// ** Next Import
import { useState } from 'react'
import toast from 'react-hot-toast'
import { Link } from '@mui/material'
import Button from '@mui/material/Button'
// ** MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import { updateOrder } from 'src/@http/orders'
import orderStore from 'src/zustand/order'
// ** Icon Imports

interface Props {
  id: string | undefined
  toggleAddPaymentDrawer: () => void
  toggleSendInvoiceDrawer: () => void
}

/* const OptionsWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
})) */

const EditOrderActions = ({ /* ...props */ }: Props) => {
  const { orderZustand } = orderStore()
  const [isSaving, setIsSaving] = useState(false)

  const handleSaveClick = async () => {
    try {
      setIsSaving(true)
      if (orderZustand != null) {
        await updateOrder(orderZustand)
        toast.success('Successfully save!', { duration: 4000 })
      }
    } catch (error) {
      toast.error('Error saving data', { duration: 4000 })
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            {/* <Button
              fullWidth
              sx={{ mb: 3.5 }}
              variant='contained'
              onClick={toggleSendInvoiceDrawer}
              // startIcon={<Icon icon='mdi:send-outline' />}
            >
              Download
            </Button> */}
            <Button
              fullWidth
              sx={{ mb: 3.5 }}
              component={Link}
              color='secondary'
              variant='outlined'
              href={`/order-editor/preview`}
            >
              Back to Preview
            </Button>
            <Button fullWidth color='success' variant='contained' onClick={handleSaveClick} disabled={isSaving}>
              {isSaving ? 'Saving...' : 'Save'}
            </Button>
          </CardContent>
        </Card>
      </Grid>

      {/* <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id='payment-select'>Accept payments via</InputLabel>
          <Select
            fullWidth
            defaultValue='Internet Banking'
            label='Accept payments via'
            labelId='payment-select'
            sx={{ mb: 4 }}
          >
            <MenuItem value='Internet Banking'>Internet Banking</MenuItem>
            <MenuItem value='Debit Card'>Debit Card</MenuItem>
            <MenuItem value='Credit Card'>Credit Card</MenuItem>
            <MenuItem value='Paypal'>Paypal</MenuItem>
            <MenuItem value='UPI Transfer'>UPI Transfer</MenuItem>
          </Select>
        </FormControl>
        <OptionsWrapper sx={{ mb: 1 }}>
          <InputLabel
            htmlFor='invoice-edit-payment-terms'
            sx={{ cursor: 'pointer', fontSize: '0.875rem', color: 'text.secondary' }}
          >
            Payment Terms
          </InputLabel>
          <Switch defaultChecked id='invoice-edit-payment-terms' />
        </OptionsWrapper>
        <OptionsWrapper sx={{ mb: 1 }}>
          <InputLabel
            htmlFor='invoice-edit-client-notes'
            sx={{ cursor: 'pointer', fontSize: '0.875rem', color: 'text.secondary' }}
          >
            Client Notes
          </InputLabel>
          <Switch id='invoice-edit-client-notes' />
        </OptionsWrapper>
        <OptionsWrapper>
          <InputLabel
            htmlFor='invoice-edit-payment-stub'
            sx={{ cursor: 'pointer', fontSize: '0.875rem', color: 'text.secondary' }}
          >
            Payment Stub
          </InputLabel>
          <Switch id='invoice-edit-payment-stub' />
        </OptionsWrapper>
      </Grid> */}
    </Grid>
  )
}

export default EditOrderActions

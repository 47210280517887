import { getAsync, postAsync, postBlobAsync } from 'src/@http'
import {
  ShopInvoiceDto,
  ShopInvoiceFilters,
  ShopInvoiceResumeCustomerDto,
  ShopInvoiceResumeDto,
  ShopInvoiceResumePeriodDto
} from 'src/types/models/ShopInvoiceModel'

import { Pagination } from '../user'

/* Endpoints definition */
const vApi = '/v1.0/'
const controlShopInvoice = `${vApi}ControllerShopInvoice`
const controlShopBatchInvoice = `${vApi}ControllerShopBatchInvoice`

export const postTotals = (shopInvoiceFilters: ShopInvoiceFilters): Promise<ShopInvoiceResumeDto> => {
  return postAsync(`${controlShopInvoice}/Totals`, shopInvoiceFilters)
}

export const postTotalsByShop = (shopInvoiceFilters: ShopInvoiceFilters): Promise<ShopInvoiceResumeCustomerDto[]> => {
  return postAsync(`${controlShopInvoice}/TotalByShop`, shopInvoiceFilters)
}

export const postTotalsByPeriods = (shopInvoiceFilters: ShopInvoiceFilters): Promise<ShopInvoiceResumePeriodDto[]> => {
  return postAsync(`${controlShopInvoice}/TotalByPeriods`, shopInvoiceFilters)
}

export const postShopInvoice = (shopInvoiceFilters: ShopInvoiceFilters): Promise<any> => {
  return postAsync(`${controlShopInvoice}`, shopInvoiceFilters)
}

type ShopInvoicePaginateParams = {
  shopFilters: ShopInvoiceFilters
  PageSize: number
  CurrentPage: number
}
export const postShopInvoicePaginate = ({
  shopFilters,
  PageSize = 10,
  CurrentPage = 1
}: ShopInvoicePaginateParams): Promise<{ list: ShopInvoiceDto[]; pagination: Pagination }> => {
  return postAsync(`${controlShopInvoice}/Paginate?PageSize=${PageSize}&CurrentPage=${CurrentPage}`, shopFilters)
}

export const postShopInvoiceExport = (shopInvoiceFilters: ShopInvoiceFilters): Promise<any> => {
  return postBlobAsync(`${controlShopInvoice}/Export`, shopInvoiceFilters)
}

export const getBachShop = (): Promise<boolean> => {
  return getAsync(`${controlShopBatchInvoice}?Status=1`)
}

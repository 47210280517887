import { useTranslation } from 'react-i18next'
import { Box, Card, CardHeader, Grid } from '@mui/material'
import GenericButton from 'src/pages/components/generic/GenericButton'
import WrapperSecurity from 'src/pages/components/generic/WrapperSeurityObject'
import TableRateSheet from 'src/pages/components/rateSheets/TableRateSheet'

const CarriersPage = () => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card sx={{ p: 4 }}>
          <Grid container flexDirection='row' justifyContent='space-between' alignItems='center'>
            <CardHeader title={t('COURIER_LIST')} subheader={t('FIND_ALL_THE_COURIERS_REGISTERED_IN_THE_PLATFORM')} />
            <WrapperSecurity securityKey='add_couriers_btn'>
              <GenericButton data-cy="add_courier_btn" to={'/carriers/add'} state={{ customer: null, mode: 'add' }}>
                {t('ADD_CARRIER')}
              </GenericButton>
            </WrapperSecurity>
          </Grid>
          <Box sx={{ my: 3 }}>
            <TableRateSheet type={'carriers'} />
          </Box>
        </Card>
      </Grid>
    </Grid>
  )
}

export default CarriersPage

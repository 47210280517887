import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Icon } from '@iconify/react'
import {
  Button,
  /* Box, Button, */ Card,
  /*Fab, Grid,*/ IconButton,
  Stack /* TextField , Typography*/
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { getTotalsInvoicesByCarrier, postExportInvoicesShop } from 'src/@http/shop'
import invoiceStore from 'src/zustand/invoice'

import CustomToolbarAdminGrid from '../generic/CustomToolbarAdminGrid'
// import CustomAvatar from 'src/@core/components/mui/avatar'
// import CustomChip from 'src/@core/components/mui/chip'

// interface Row {
//   id: number
//   icon: { icon: string; color: string }
//   client: { avatar: string; name: string; email: string }
//   total: string
//   issuedDate: string
//   balance: { paid: boolean; amount: string }
// }

const columns: GridColumns = [
  {
    flex: 1,
    field: 'intCustomerInvoiceResumeId',
    headerName: 'Resume id',
    minWidth: 100
  },
  {
    flex: 1,
    field: 'vchCarrierName',
    headerName: 'Courier name',
    minWidth: 150
  },
  {
    flex: 1,
    field: 'intCountInvoice',
    headerName: 'Invoice count',
    minWidth: 250
  },
  {
    flex: 1,
    field: 'decTotalWeight',
    headerName: 'Total Weight',
    minWidth: 150,
    renderCell: ({ row }) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {parseFloat(row.decTotalWeight).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </div>
    )
  },
  {
    flex: 1,
    field: 'decTotalShipPrice',
    headerName: 'Total Ship Price',
    minWidth: 200,
    renderCell: ({ row }) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {parseFloat(row.decTotalShipCarrierPrice).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </div>
    )
  },
  {
    flex: 1,
    field: 'decTotalShipCarrierPrice',
    headerName: 'Total Ship Courier Price',
    minWidth: 250,
    renderCell: ({ row }) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {parseFloat(row.decTotalShipPrice).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </div>
    )
  },
  {
    flex: 1,
    field: 'decTotalShipPriceAdicional',
    headerName: 'Aditional Price',
    minWidth: 150,
    renderCell: ({ row }) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {parseFloat(row.decTotalShipPriceAdicional).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </div>
    )
  },
  {
    field: '',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    maxWidth: 250,
    headerAlign: 'center',
    renderCell: ({ row }: CellType) => <RowOptions id={row.id} carrierName={row.vchCarrierName} />
  }
]

interface CellType {
  row: any
}

const RowOptions = ({ id, carrierName }: { id: number; carrierName: string }) => {
  const handleEyeClick = () => {
    setTabValue('2')
    setCarrierName(carrierName)
    setIsProp(true)
  }

  const { setTabValue, setCarrierName, setIsProp } = invoiceStore()

  return (
    <Stack display='flex' flexDirection='row' width='100%' justifyContent='space-between'>
      <IconButton size='small'>
        <Icon icon='material-symbols:delete-outline' />
      </IconButton>
      <IconButton size='small' onClick={handleEyeClick}>
        <Icon icon='mdi:eye' />
      </IconButton>
      <IconButton size='small'>
        <Icon icon='mdi:dots-vertical' />
      </IconButton>
    </Stack>
  )
}

const TableRatesGroupByCarrier = () => {
  const { invoiceFilter, setIsSearching } = invoiceStore()
  const [processing, setProcessing] = useState(false)
  const [rows, setRows] = useState<any[]>([
    {
      intCustomerInvoiceResumeId: 0,
      vchCarrierName: '',
      decTotalWeight: 0,
      intCountInvoice: 0,
      decTotalShipPrice: 0,
      decTotalShipPriceAdicional: 0,
      id: 0
    }
  ])
  const [isLoading, setIsLoading] = useState(false)
  const [cols, setCols] = useState<GridColumns | null>(null)
  const handleExportInvoices = async () => {
    try {
      setProcessing(true)
      const response = await postExportInvoicesShop(
        invoiceFilter.batchId ?? null,
        invoiceFilter.shopName ?? null,
        invoiceFilter.customerName ?? null,
        invoiceFilter.startDate ?? null,
        invoiceFilter.endDate ?? null,
        1
      )

      const url = window.URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'CustomerInvoice.xlsx')
      document.body.appendChild(link)
      link.click()

      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)

      toast.success('File downloaded successfully', { duration: 4000 })
    } catch (error) {
      toast.error('There was an error exporting invoices', { duration: 4000 })
    } finally {
      setProcessing(false)
    }
  }

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      try {
        const response = await getTotalsInvoicesByCarrier(
          invoiceFilter.batchId ?? null,
          invoiceFilter.shopName ?? null,
          invoiceFilter.customerName ?? null,
          invoiceFilter.startDate ?? null,
          invoiceFilter.endDate ?? null,
          1
        )
        if (response) {
          setRows(response)
        }
      } catch (error) {
        // console.log(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetch()
    setCols(columns)
    setIsSearching(false)
  }, [invoiceFilter.isSearching])

  return (
    <Card sx={{ mt: 3 }}>
      <Stack
        sx={{ p: 4, flexDirection: { xs: 'column', md: 'row', lg: 'row' } }}
        display='flex'
        justifyContent='flex-end'
      >
        <Button
          variant='contained'
          color='secondary'
          size='small'
          sx={{ marginRight: '10px' }}
          onClick={handleExportInvoices}
          disabled={processing}
        >
          <Icon icon='material-symbols:upload' />
          {processing ? 'Processing' : 'Export'}
        </Button>
      </Stack>
      <DataGrid
        checkboxSelection
        disableSelectionOnClick
        autoHeight
        loading={isLoading}
        rowsPerPageOptions={[10, 25, 50]}
        rows={rows}
        columns={cols || []}
        getRowHeight={() => 51}
        components={{ Toolbar: CustomToolbarAdminGrid }}
      />
    </Card>
  )
}

export default TableRatesGroupByCarrier

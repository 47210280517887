import { MouseEvent, useEffect, useState } from 'react'
import { CircularProgress, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Typography from '@mui/material/Typography'
import { getMeasuresByService } from 'src/@http/services'
import { MeasureModel } from 'src/types/models/MeasureModels'
import { logger } from 'src/utils/Logger'
import carrierStore from 'src/zustand/carrier'
import servicesStore from 'src/zustand/services'

interface MeasureListProps {
  onSelectMeasure?: (measureId: string) => void
  onSelectMeasureRaw?: (measure: MeasureModel) => void
  onLoadMeasureList?: (measureList: MeasureModel[]) => void
  onLoadMeasureDefault?: (measure: MeasureModel) => void
  useAllValue?: boolean
}

const MeasureListRateSheet = ({
  onSelectMeasure,
  useAllValue,
  onSelectMeasureRaw,
  onLoadMeasureList,
  onLoadMeasureDefault
}: MeasureListProps) => {
  const [measureList, setMeasureList] = useState<MeasureModel[]>([])
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(true)
  const { setMeasure, setMeasureName } = servicesStore()
  const { serviceId } = carrierStore()
  const [alignment, setAlignment] = useState<MeasureModel | null>(null)
  const [isError, setIsError] = useState(false)

  const handleAlignment = (event: MouseEvent<HTMLElement>, newAlignment: MeasureModel | null) => {
    setAlignment(newAlignment)
  }

  const optionAll = {
    tinUnitMeasureId: '-1',
    vchName: 'All',
    id: '-1'
  }

  const handleChange = (event: any, value: string | null) => {
    logger.log('Value en Hijo:', value)
    logger.log('Event en Hijo:', event.target.value)

    const selectedMeasure = measureList.find(measure => measure.vchName === value)

    if (selectedMeasure) {
      const tinUnitMeasureId = selectedMeasure.tinUnitMeasureId
      logger.log('tinUnitMeasureId:', tinUnitMeasureId)

      onSelectMeasureRaw?.(selectedMeasure)
      onSelectMeasure?.(value || '')
      setValue(value || '')
      setMeasure(tinUnitMeasureId)
      setMeasureName(value || '')
    } else {
      console.error('El objeto con vchName no se encontró en measureList.')
    }
  }

  useEffect(() => {
    setLoading(true)
    setIsError(false)
    getMeasuresByService(serviceId)
      .then(data => {
        console.log('data', data)
        if (useAllValue) data.unshift(optionAll)
        setMeasureList(data)
        // setMeasure('-1')
        setValue(data[0].vchName)
        onLoadMeasureList?.(data)
        onLoadMeasureDefault?.(data[0])
        setLoading(false)
      })
      .catch(error => {
        setIsError(true)
      })
      .finally(() => setLoading(false))
  }, [setMeasureList, serviceId])

  return (
    <>
      {loading && !isError ? (
        <CircularProgress />
      ) : isError ? (
        <Typography
          style={{
            color: 'red'
          }}
        >
          No Measure units setup
        </Typography>
      ) : (
        <Autocomplete
          fullWidth
          size='small'
          onChange={handleChange}
          value={value}
          options={measureList.map(measure => measure.vchName)}
          id='autocomplete-disableCloseOnSelect'
          renderInput={params => <TextField {...params} label='Measure' />}
        />
      )}

      {false && (
        <ToggleButtonGroup exclusive color='secondary' value={alignment} onChange={handleAlignment}>
          {measureList.map(measure => (
            <ToggleButton key={measure.id} value={measure.id}>
              {measure.vchName}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    </>
  )
}

export default MeasureListRateSheet

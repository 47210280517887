import { deleteAsync, getAsync, postAsync, putAsync } from 'src/@http'
import { MeasureModel } from 'src/types/models/MeasureModels'
import { ServicesCarrierModel } from 'src/types/models/ServicesCarrierModel'

/* Endpoints definition */
const EndpointCarrierService = '/v1.0/ControllerService/Carrier'
const EndpointService = '/v1.0/ControllerService'
const EndPointMeasure = '/v1.0/ControllerUnitMeasure/Service'
const Measures = (serviceid: number | null) => `${EndPointMeasure}/${serviceid}`
const Services = (carrierid: number | null) => `${EndpointCarrierService}/${carrierid}`
const DeleteServices = (serviceid: number | null) => `${EndpointService}/${serviceid}`

export const getServices = (carrierid: number | null = null): Promise<ServicesCarrierModel[]> => {
  return getAsync(Services(carrierid))
}

export const getAllServices = (): Promise<ServicesCarrierModel[]> => {
  return getAsync(`${EndpointService}?Status=1`)
}

export const getMeasuresByService = (serviceid: number | null = null): Promise<MeasureModel[]> => {
  return getAsync(Measures(serviceid))
}

export const deleteServices = (serviceid: number | null = null): Promise<boolean> => {
  return deleteAsync(DeleteServices(serviceid))
}

export const deleteServicesPackageType = (servicePkgId: number | null = null): Promise<boolean> => {
  return getAsync(`${EndpointService}/Disabled/${servicePkgId}`)
}

export const addServices = (service: ServicesCarrierModel): Promise<boolean> => {
  return postAsync(EndpointService, {
    vchName: service.vchName,
    vchShortName: service.vchShortName,
    vchSourceId: service.vchSourceId,
    intServiceClasificationId: service.intServiceClasificationId,
    intCarrierId: service.intCarrierId,
    intStatusId: service.intStatusId,
    intCreatedUserId: service.intCreatedUserId,
    dtmServciesCreatedDate: service.dtmCreatedDate,
    zoneListDtos: service.zoneListDtos.map(zone => ({
      vchName: zone.vchName,
      vchShortName: zone.vchName,
      vchSourceId: zone.vchSourceId ?? zone.vchName,
      intCarrierId: zone.intCarrierId,
      intCountryId: 1,
      intServiceId: zone.intServiceId,
      intStatusId: zone.intStatusId,
      intCreatedUserId: zone.intCreatedUserId,
      intRegionId: zone.intRegionId || null
    })),
    weigthListDtos: service.weigthListDtos.map(weight => ({
      vchCodWeigth: weight.vchCodWeigth,
      intCarrierId: weight.intCarrierId,
      vchCarrierName: weight.vchCarrierName,
      intServiceId: weight.intServiceId,
      vchServiceName: weight.vchServiceName,
      decWeigthMin: weight.decWeigthMin,
      decWeigthMax: weight.decWeigthMax,
      tinUnitMeasureId: String(weight.tinUnitMeasureId) === '-1' ? 1 : weight.tinUnitMeasureId,
      intStatusId: weight.intStatusId,
      intCreatedUserId: weight.intCreatedUserId,
    })),
    servicePackageTypeDtos: service.packageTypeListDtos,
    vchCarrierShipCode: service.vchCarrierShipCode

  })
}

export const editServices = (service: ServicesCarrierModel): Promise<boolean> => {
  return putAsync(EndpointService, {
    intServiceId: service.intServiceId,
    vchName: service.vchName,
    vchSourceId: service.vchSourceId ?? service.vchName,
    vchShortName: service.vchShortName,
    intServiceClasificationId: service.intServiceClasificationId,
    intCarrierId: service.intCarrierId,
    intStatusId: service.intStatusId,
    intCreatedUserId: service.intCreatedUserId,
    dtmServciesCreatedDate: service.dtmCreatedDate,
    zoneListDtos: service.zoneListDtos.map(zone => ({
      id: zone.intServiceZoneId,
      intServiceZoneId: zone.intServiceZoneId,
      vchName: zone.vchName,
      vchShortName: zone.vchName,
      vchSourceId: zone.vchSourceId ?? zone.vchName,
      intCarrierId: zone.intCarrierId,
      intCountryId: zone.intCountryId,
      intServiceId: zone.intServiceId,
      intStatusId: zone.intStatusId,
      intCreatedUserId: zone.intCreatedUserId,
      intRegionId: zone.intRegionId || null
    })),
    weigthListDtos: service.weigthListDtos.map(weight => ({
      id: weight.intServiceWeigthId,
      intServiceWeigthId: weight.intServiceWeigthId,
      vchCodWeigth: weight.vchCodWeigth,
      intCarrierId: weight.intCarrierId,
      vchCarrierName: weight.vchCarrierName,
      intServiceId: weight.intServiceId,
      vchServiceName: weight.vchServiceName,
      decWeigthMin: weight.decWeigthMin,
      decWeigthMax: weight.decWeigthMax,
      tinUnitMeasureId: String(weight.tinUnitMeasureId) === '-1' ? 1 : weight.tinUnitMeasureId,
      intStatusId: weight.intStatusId,
      intCreatedUserId: weight.intCreatedUserId,
    })),
    servicePackageTypeDtos: service.packageTypeListDtos,
    vchCarrierShipCode: service.vchCarrierShipCode

  })
}

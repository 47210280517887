import { useRef,useState } from "react"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Icon } from "@iconify/react"
import { Grid, IconButton, Menu, MenuItem,Stack,Typography } from "@mui/material"
import { GridCellParams,GridColumns } from "@mui/x-data-grid"
import CustomAvatar from 'src/@core/components/mui/avatar'
import CustomChip from 'src/@core/components/mui/chip'
import { deleteCarrier } from "src/@http/carriers"
import { CarrierModel } from "src/types/models/CarrierModel"
import { handleVisibilityAccordingKey } from "src/utils/misc"
import appStore from "src/zustand/app"
import carrierStore from "src/zustand/carrier"
import orderStore from "src/zustand/order"

import { DialogExpose, DialogGeneric } from "../generic/DialogGeneric"
import WrapperSecurity from "../generic/WrapperSeurityObject"
import { IconAction } from "../rateSheets/TableRateSheet"

export const colsCarriers: GridColumns = [
  {
    field: 'intCarrierId',
    headerName: 'Courier Id',
    flex: 1,
    renderCell: ({ row }: GridCellParams<CarrierModel>) => {
      return (
        <Typography variant='subtitle1' color='primary'>
          {row.intCarrierId}
        </Typography>
      )
    }
  },
  {
    field: 'vchUserName',
    headerName: 'Name',
    flex: 1,
    minWidth: 300,
    maxWidth: 900,
    renderCell: ({ row }: GridCellParams<CarrierModel>) => (
      <Grid container columnGap={3}>
        <Grid
          sx={{ display: { xs: 'none', md: 'flex', lg: 'flex' }, maxWidth: '40px !important' }}
          item
          xs={6}
          lg={2}
          justifyContent='center'
          alignItems='center'
        >
          <CustomAvatar src={row.vchLogoPath} sx={{ width: 30, height: 30 }}>
            <Icon icon='mdi:truck-outline' />
          </CustomAvatar>
        </Grid>
        <Grid item xs={6} lg={8} display='flex' flexDirection='column' justifyContent='center'>
          <Typography variant='subtitle2' fontWeight={600}>
            {row.vchName}
          </Typography>
          {row.vchEmail ? (
            <Typography variant='subtitle2' fontWeight={300}>
              {row.vchEmail}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    )
  },

  {
    field: 'lastModifiedDate',
    headerName: 'Last Date',
    flex: 1,
    minWidth: 150,
    maxWidth: 150,
    renderCell: ({ row }: GridCellParams<CarrierModel>) => (
      <Typography variant='subtitle2' fontWeight={300}>
        {row.lastModifiedDate || '-'}
      </Typography>
    )
  },
  {
    field: 'intStatusId',
    headerName: 'Status',
    headerAlign: 'center',
    flex: 1,
    minWidth: 150,
    maxWidth: 150,
    renderCell: ({ row }: GridCellParams<CarrierModel>) => (
      <Stack width='100%' display='flex' flexDirection='row' justifyContent='center'>
        <CustomChip label={row.vchStatusName} skin='light' color={row.vchStatusColor} />
      </Stack>
    )
  },
  {
    field: '',
    headerName: 'Action',
    flex: 1,
    minWidth: 250,
    maxWidth: 250,
    headerAlign: 'center',
    renderCell: ({ row }: GridCellParams<CarrierModel>) => <RowOptionsCouriers row={row} />
  }
]

const RowOptionsCouriers = ({ row }: { row: CarrierModel }) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const dialogRef = useRef<DialogExpose>(null)

  const { setServiceEditorCarrierId } = appStore(state => ({
    setServiceEditorCarrierId: state.setServiceEditorCarrierId
  }))
  const { setCarrierName } = orderStore(state => ({
    setCarrierName: state.setCarrierName
  }))
  const { setRefreshCarriersDate, setCarrierId, setCarrierSelected } = carrierStore()

  const navigate = useNavigate()
  const url = `/carriers/base-rate/${row.intCarrierId | 0}`
  const urlView = '/carriers/view'

  // Menu más acciones
  const options = [
    { title: t('DELETE'), show: handleVisibilityAccordingKey('delete_menu_option_couriers') },
    { title: t('PAUSE'), show: true },
    { title: t('RESUME'), show: true },
    { title: t('CLONE'), show: handleVisibilityAccordingKey('clone_menu_option_couriers') },
  ]
  const handleClick = (event: any) => setAnchorEl(event.currentTarget)
  const handleClose = (option: string) => {
    setAnchorEl(null)

    switch (option) {
      case 'Delete': {
        handleDelete()
        break
      }
    }
  }

  const handleDelete = () => {
    dialogRef.current?.open().then(async confirm => {
      if (confirm)
        try {
          await deleteCarrier(row.id)
          toast.success(t('DELETED_SUCCESS') + '! 👌', { duration: 4000 })
          setRefreshCarriersDate()
        } catch (err: any) {
          toast.error(err.message + '🙄', { duration: 4000 })
        }
    })
  }

  const handleInvoiceCourier = () => {
    const carrierId = row.intCarrierId || null
    const carrierName = row.vchName || null
    setCarrierName(carrierName ?? '')
    const urlInvoice = `/carriers/invoice/${carrierId}`

    if (carrierId) {
      setCarrierId(carrierId)
      navigate(urlInvoice)
    }
  }

  return (
    <Stack display='flex' flexDirection='row' width='100%' justifyContent='space-around'>
      <WrapperSecurity securityKey="invoice_couriers_btn">
        <IconAction title={t('INVOICE')} datacy="btn-invoices-courier" icon={'fa6-solid:file-invoice-dollar'} onClick={handleInvoiceCourier} />
      </WrapperSecurity>
      <WrapperSecurity securityKey="rate_sheet_couriers_btn">
        <IconAction
          title={t('RATE_SHEET')}
          icon={'lucide:sheet'}
          datacy="btn-rate-sheet-courier"
          onClick={() => {
            setCarrierSelected(row as CarrierModel)
            navigate(url, { state: { data: row, type: 'carriers' } })
          }}
        />
      </WrapperSecurity>
      <WrapperSecurity securityKey="services_editor_couriers_btn">
        <IconAction
          title={'Services editor'}
          icon={'carbon:mobility-services'}
          datacy="btn-services-editor-courier"
          onClick={() => {
            setServiceEditorCarrierId(row.id)
            navigate('/carriers/services-editor', { state: { data: row, type: 'carriers' } })
          }}
        />
      </WrapperSecurity>
      <WrapperSecurity securityKey="view_couriers_btn">
        <IconAction
          title={t('SEE')}
          icon={'mdi:eye'}
          datacy={`btn-view-courier-${row.id}`}
          onClick={() => {
            setServiceEditorCarrierId(row.id)
            navigate(urlView, { state: { data: row, mode: 'view' } })
          }}
        />
      </WrapperSecurity>
      <IconButton 
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        data-cy={`actions-button-${row.id}`}
        onClick={handleClick}
      >
        <Icon icon='mdi:dots-vertical' />
      </IconButton>
      <Menu
        keepMounted
        id={`long-menu-${row.id}`}
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        PaperProps={{ style: { maxHeight: 200, maxWidth: 150 } }}
      >
        {options.map(({title, show}) => (
          show 
          ? (
              <MenuItem 
                key={title}
                onClick={() => handleClose(title)}
                data-cy={`menu-${row.id}-item-${title.toLowerCase()}`}
              >
                {title}
              </MenuItem>
            )
          : null
        ))}
      </Menu>
      <DialogGeneric
        title={`DELETE_CARRIER`}
        ref={dialogRef}
        confirmText='CONFIRM'
        cancelText='CANCEL'
      >
        {t(`CONFIRM_DELETE_CARRIER`)}
      </DialogGeneric>
    </Stack>
  )
}

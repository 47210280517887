import { SyntheticEvent, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Grid,
  MenuItem,
  Tab,
  TextField,
  Typography
} from '@mui/material'
import { getJobById, putJob } from 'src/@http/job'
import { JobScheduleModel, JobStepModel } from 'src/types/models/JobModel'
import companiesStore from 'src/zustand/companies'
import jobStore from 'src/zustand/Job'

const TaskEditCustomer = () => {
  const [errors, setErrors] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [saving, setSaving] = useState<boolean>(false)
  const [tabValue, setTabValue] = useState<string>('1')
  const { taskObj, setTaskModel, taskSchedule, setTaskScheduleModel, taskId } = jobStore()
  const { t } = useTranslation()
  const title = 'Edit Job'
  const { companies } = companiesStore()
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(companies[0].intCustomerId)
  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }
  useEffect(() => {
    const fetchData = async () => {
      const jobData = await getJobById(taskId)
      setSelectedCompanyId(jobData.intCustomerId ?? companies[0].intCustomerId)
      setTaskModel(jobData)
      setTaskScheduleModel(jobData.lstJobSchedule ?? null)
      setLoading(false)
    }
    setLoading(true)
    fetchData()
  }, [])

  const handleAddSchedule = () => {
    const newSchedule: JobScheduleModel = {
      id: 0,
      isActive: true,
      lastModifiedDate: new Date().toISOString(),
      intJobScheduleId: taskSchedule?.length ?? 1 - 1,
      intJobId: 0,
      vchName: '',
      intStatusId: 0,
      intCreatedUserId: 0,
      dtmCreatedDate: new Date().toISOString(),
      intUpdatedUserId: 0,
      dtmUpdatedDate: new Date().toISOString(),
      tinGlobalFrequencyId: 0,
      tinValueGlobalFrequency: 0,
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      tinDailyFrequencyId: 0,
      tinValueDailyFrequency: 0,
      timStartTime: '01:02:03',
      timEndTime: '23:59:59',
      dtmStartDay: new Date().toISOString().slice(0, 19),
      dtmEndDay: new Date().toISOString().slice(0, 19),
      lstJobStep: []
    }
    if (taskSchedule == null) {
      setTaskModel({ ...taskObj, lstJobSchedule: [newSchedule] })
      setTaskScheduleModel([newSchedule])
    } else {
      setTaskModel({ ...taskObj, lstJobSchedule: [...taskSchedule, newSchedule] })
      setTaskScheduleModel([...taskSchedule, newSchedule])
    }
  }

  const handleScheduleChange = (index: number, field: string, value: any) => {
    const updatedSchedules = taskSchedule?.map((schedule, i) =>
      i === index ? { ...schedule, [field]: value } : schedule
    )
    setTaskScheduleModel(updatedSchedules ?? null)
    setTaskModel({ ...taskObj, lstJobSchedule: updatedSchedules })
  }

  const handleAddStep = (scheduleIndex: number) => {
    const count =
      taskSchedule && taskSchedule[scheduleIndex]?.lstJobStep ? taskSchedule[scheduleIndex].lstJobStep.length : 0
    const newStep: JobStepModel = {
      id: 0,
      isActive: false,
      lastModifiedDate: new Date().toISOString().slice(0, 19),
      intJobStepId: 0,
      intJobId: 0,
      intJobScheduleId: scheduleIndex,
      vchName: '',
      sinExecOrder: count + 1,
      intCustomerId: 0,
      intCarrierId: 0,
      intStepId: count + 1
    }
    if (taskSchedule !== null) {
      const updatedLstJobStep = [...taskSchedule[scheduleIndex].lstJobStep, newStep]

      const updatedTaskSchedule = [
        ...taskSchedule.slice(0, scheduleIndex),
        {
          ...taskSchedule[scheduleIndex],
          lstJobStep: updatedLstJobStep
        },
        ...taskSchedule.slice(scheduleIndex + 1)
      ]
      setTaskModel({ ...taskObj, lstJobSchedule: updatedTaskSchedule })
      setTaskScheduleModel(updatedTaskSchedule)
    }
  }

  const handleRemoveLastSchedule = () => {
    if (taskSchedule !== null) {
      if (taskSchedule.length == 1) {
        return
      }
      if (taskSchedule?.length > 0) {
        const updatedSchedules = [...taskSchedule]
        updatedSchedules.pop()
        setTaskModel({ ...taskObj, lstJobSchedule: updatedSchedules })
        setTaskScheduleModel(updatedSchedules)
      }
    }
  }

  const handleRemoveLastStep = (scheduleIndex: number) => {
    if (taskSchedule !== null) {
      const updatedLstJobStep = [...taskSchedule[scheduleIndex].lstJobStep]
      updatedLstJobStep.pop()
      const updatedTaskSchedule = [
        ...taskSchedule.slice(0, scheduleIndex),
        {
          ...taskSchedule[scheduleIndex],
          lstJobStep: updatedLstJobStep
        },
        ...taskSchedule.slice(scheduleIndex + 1)
      ]
      setTaskModel({ ...taskObj, lstJobSchedule: updatedTaskSchedule })
      setTaskScheduleModel(updatedTaskSchedule)
    }
  }

  const handleStepChange = (index: number, field: string, value: any, scheduleIndex: number) => {
    if (taskSchedule !== null && taskSchedule[scheduleIndex] !== undefined) {
      const updatedSteps = (taskSchedule[scheduleIndex]?.lstJobStep ?? []).map((step: any, i: number) =>
        i === index ? { ...step, [field]: value } : step
      )
      const updatedTaskSchedule = [
        ...taskSchedule.slice(0, scheduleIndex),
        {
          ...taskSchedule[scheduleIndex],
          lstJobStep: updatedSteps
        },
        ...taskSchedule.slice(scheduleIndex + 1)
      ]
      setTaskModel({ ...taskObj, lstJobSchedule: updatedTaskSchedule })
      setTaskScheduleModel(updatedTaskSchedule)
    }
  }

  const handleSaveTask = async () => {
    try {
      setSaving(true)
      await putJob(
        taskObj ?? {
          intJobId: 0,
          intCustomerId: 0,
          vchName: '',
          intStatusId: 0,
          intCreatedUserId: 0,
          dtmCreatedDate: '',
          intUpdatedUserId: 0,
          dtmUpdatedDate: '',
          lstJobSchedule: [] as JobScheduleModel[]
        }
      )
      setSaving(false)
      toast.success('Successfully saved!', { duration: 4000 })
    } catch (error) {
      toast.error('Error while saving data.', { duration: 4000 })
      setSaving(false)
    }
  }

  interface JobScheduleItemProps {
    schedule: JobScheduleModel
    index: number
    handleScheduleChange: (index: number, field: string, value: any) => void
  }

  const JobScheduleItem: React.FC<JobScheduleItemProps> = ({ schedule, index, handleScheduleChange }) => {
    const [localSchedule, setLocalSchedule] = useState(schedule)
    const [isDaysEnabled, setIsDaysEnabled] = useState(localSchedule.tinGlobalFrequencyId == 2)
    const [isOneTimeSelected, setIsOneTimeSelected] = useState(localSchedule.tinDailyFrequencyId === 1)
    const handleFieldChange = (field: string, value: any) => {
      const updatedSchedule = { ...localSchedule, [field]: value }
      setLocalSchedule(updatedSchedule)
      if (field === 'tinGlobalFrequencyId') {
        setIsDaysEnabled(value == 2)
      }
    }

    useEffect(() => {
      setIsOneTimeSelected(localSchedule.tinDailyFrequencyId === 1)
    }, [localSchedule.tinDailyFrequencyId])

    const handleBlur = (field: string, value: any) => {
      if (field == 'dtmStartDay' || field == 'dtmEndDay') {
        value = new Date(value).toISOString().slice(0, 19)
      }
      handleScheduleChange(index, field, value)
      if (taskSchedule !== null) {
        if (taskSchedule[index] !== null) {
          const updatedSchedule = taskSchedule.map((step: any, i: number) =>
            i === index ? { ...step, [field]: value } : step
          )
          setTaskModel({ ...taskObj, lstJobSchedule: updatedSchedule })
          setTaskScheduleModel(updatedSchedule)
        }
      }
    }

    return (
      <Grid container spacing={2} key={index}>
        <Grid item xs={12} lg={12} sx={{ p: 2 }}>
          <Typography variant='subtitle1' mb={1}>
            {`Job Schedule N° ${index + 1}`}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ p: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            fullWidth
            label={'Job Schedule Name'}
            value={localSchedule.vchName ?? ''}
            onChange={e => handleFieldChange('vchName', e.target.value)}
            onBlur={e => handleBlur('vchName', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={6} sx={{ p: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            fullWidth
            select
            label='Global frequency'
            value={localSchedule.tinGlobalFrequencyId ?? 1}
            onChange={e => handleFieldChange('tinGlobalFrequencyId', Number(e.target.value))}
            onBlur={e => handleBlur('tinGlobalFrequencyId', Number(e.target.value))}
          >
            <MenuItem key={1} value={1}>
              {'Daily'}
            </MenuItem>
            <MenuItem key={2} value={2}>
              {'Weekly'}
            </MenuItem>
            <MenuItem key={3} value={3}>
              {'Monthly'}
            </MenuItem>
          </TextField>
        </Grid>
        {isDaysEnabled ? (
          <>
            <Grid item xs={12} lg={6} sx={{ p: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                fullWidth
                label={'Recurs every'}
                value={localSchedule.tinValueGlobalFrequency ?? ''}
                onChange={e => handleFieldChange('tinValueGlobalFrequency', e.target.value)}
                onBlur={e => handleBlur('tinValueGlobalFrequency', e.target.value)}
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={12} sx={{ p: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={Boolean(schedule.monday)}
                onChange={e => handleScheduleChange(index, 'monday', Number(e.target.checked))}
                color='primary'
              />
              <Typography style={{ fontSize: '80%' }}>{'Mon'}</Typography>
              <Checkbox
                checked={Boolean(schedule.tuesday)}
                onChange={e => handleScheduleChange(index, 'tuesday', Number(e.target.checked))}
                color='primary'
              />
              <Typography style={{ fontSize: '80%' }}>{'Tue'}</Typography>
              <Checkbox
                checked={Boolean(schedule.wednesday)}
                onChange={e => handleScheduleChange(index, 'wednesday', Number(e.target.checked))}
                color='primary'
              />
              <Typography style={{ fontSize: '80%' }}>{'Wed'}</Typography>
              <Checkbox
                checked={Boolean(schedule.thursday)}
                onChange={e => handleScheduleChange(index, 'thursday', Number(e.target.checked))}
                color='primary'
              />
              <Typography style={{ fontSize: '80%' }}>{'Thu'}</Typography>
              <Checkbox
                checked={Boolean(schedule.friday)}
                onChange={e => handleScheduleChange(index, 'friday', Number(e.target.checked))}
                color='primary'
              />
              <Typography style={{ fontSize: '80%' }}>{'Fri'}</Typography>
              <Checkbox
                checked={Boolean(schedule.saturday)}
                onChange={e => handleScheduleChange(index, 'saturday', Number(e.target.checked))}
                color='primary'
              />
              <Typography style={{ fontSize: '80%' }}>{'Sat'}</Typography>
              <Checkbox
                checked={Boolean(schedule.sunday)}
                onChange={e => handleScheduleChange(index, 'sunday', Number(e.target.checked))}
                color='primary'
              />
              <Typography style={{ fontSize: '80%' }}>{'Sun'}</Typography>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} lg={6} sx={{ p: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              fullWidth
              label={'Recurs every'}
              value={localSchedule.tinValueGlobalFrequency ?? ''}
              onChange={e => handleFieldChange('tinValueGlobalFrequency', e.target.value)}
              onBlur={e => handleBlur('tinValueGlobalFrequency', e.target.value)}
            />
          </Grid>
        )}
        <Grid item xs={12} lg={6} sx={{ p: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            fullWidth
            select
            label='Daily frequency'
            value={localSchedule.tinDailyFrequencyId ?? 1}
            onChange={e => handleFieldChange('tinDailyFrequencyId', Number(e.target.value))}
            onBlur={e => handleBlur('tinDailyFrequencyId', Number(e.target.value))}
          >
            <MenuItem key={1} value={1}>
              {'Occurs one at'}
            </MenuItem>
            <MenuItem key={2} value={2}>
              {'Occurs every'}
            </MenuItem>
          </TextField>
        </Grid>
        {isOneTimeSelected ? (
          <>
            <Grid item xs={12} lg={6} sx={{ p: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                fullWidth
                label={'Hours'}
                type='number'
                value={localSchedule.tinValueDailyFrequency ?? 0}
                onChange={e => handleFieldChange('tinValueDailyFrequency', e.target.value)}
                onBlur={e => handleBlur('tinValueDailyFrequency', e.target.value)}
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={6} sx={{ p: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                fullWidth
                label={'Start Time'}
                value={localSchedule.timStartTime ?? ''}
                onChange={e => handleFieldChange('timStartTime', e.target.value)}
                onBlur={e => handleBlur('timStartTime', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} lg={6} sx={{ p: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                fullWidth
                label={'End Time'}
                value={localSchedule.timEndTime ?? ''}
                onChange={e => handleFieldChange('timEndTime', e.target.value)}
                onBlur={e => handleBlur('timEndTime', e.target.value)}
                disabled
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} lg={6} sx={{ p: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                fullWidth
                label={'Hours'}
                type='number'
                value={localSchedule.tinValueDailyFrequency ?? 0}
                onChange={e => handleFieldChange('tinValueDailyFrequency', e.target.value)}
                onBlur={e => handleBlur('tinValueDailyFrequency', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} lg={6} sx={{ p: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                fullWidth
                label={'Start Time'}
                value={localSchedule.timStartTime ?? ''}
                onChange={e => handleFieldChange('timStartTime', e.target.value)}
                onBlur={e => handleBlur('timStartTime', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} lg={6} sx={{ p: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                fullWidth
                label={'End Time'}
                value={localSchedule.timEndTime ?? ''}
                onChange={e => handleFieldChange('timEndTime', e.target.value)}
                onBlur={e => handleBlur('timEndTime', e.target.value)}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} lg={3} sx={{ p: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
          <DatePicker
            selected={localSchedule.dtmStartDay ? new Date(localSchedule.dtmStartDay) : null}
            onChange={date => {
              if (date) {
                const formattedDate = date.toISOString().slice(0, 10)
                handleFieldChange('dtmStartDay', formattedDate)
                handleBlur('dtmStartDay', date)
              } else {
                handleFieldChange('dtmStartDay', null)
              }
            }}
            customInput={<TextField fullWidth label='Start Day' />}
          />
        </Grid>
        <Grid item xs={12} lg={3} sx={{ p: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
          <DatePicker
            selected={localSchedule.dtmEndDay ? new Date(localSchedule.dtmEndDay) : new Date()}
            onChange={(date: Date | null) => {
              if (date instanceof Date) {
                handleFieldChange('dtmEndDay', date.toISOString().slice(0, 10))
                handleBlur('dtmEndDay', date)
              } else {
                handleFieldChange('dtmEndDay', null)
              }
            }}
            customInput={<TextField fullWidth label='End Day' />}
          />
        </Grid>
        <Grid item xs={12} lg={6} sx={{ p: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
          {/* <Checkbox
            checked={Boolean(schedule.monday)} 
            onBlur={e => handleScheduleChange(index, 'Monday', e.target.checked)}
            color='primary'
          />
          <Typography>{'Global frequency Value'}</Typography> */}
        </Grid>
      </Grid>
    )
  }

  interface JobStepItemProps {
    step: JobStepModel
    index: number
    scheduleIndex: number
    handleStepChange: (index: number, field: string, value: any, vscheduleIndex: number) => void
  }

  const JobStepItem: React.FC<JobStepItemProps> = ({ step, index, scheduleIndex, handleStepChange }) => {
    const [localStep, setLocalStep] = useState(step)

    const handleFieldChange = (field: string, value: any) => {
      const updatedSchedule = { ...localStep, [field]: value }
      setLocalStep(updatedSchedule)
    }

    const handleBlur = (field: string, value: any) => {
      handleStepChange(index, field, value, scheduleIndex)
    }

    return (
      <Grid container spacing={2} key={index}>
        <Grid item xs={12} lg={12} sx={{ p: 2 }}>
          <Typography variant='subtitle1' mb={1}>
            {`Job Step N° ${index + 1}`}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ p: 2 }}>
          <TextField
            fullWidth
            label={'Job Step Name'}
            // value={step.vchName}
            value={localStep.vchName ?? ''}
            onChange={e => handleFieldChange('vchName', e.target.value)}
            onBlur={e => handleBlur('vchName', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={6} sx={{ p: 2 }}>
          <TextField
            fullWidth
            label={'Exec Order'}
            // value={step.sinExecOrder}
            value={localStep.sinExecOrder ?? ''}
            onChange={e => handleFieldChange('sinExecOrder', e.target.value)}
            onBlur={e => handleBlur('sinExecOrder', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={12} sx={{ p: 2 }}>
          <TextField
            fullWidth
            label={'Exec Order URL'}
            // value={step.vchExecURL}
            value={localStep.vchExecURL ?? ''}
            onChange={e => handleFieldChange('vchExecURL', e.target.value)}
            onBlur={e => handleBlur('vchExecURL', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={12} sx={{ p: 2 }}>
          <TextField
            fullWidth
            label={'Job Parameters'}
            // value={step.vchParameters}
            value={localStep.vchParameters ?? ''}
            onChange={e => handleFieldChange('vchParameters', e.target.value)}
            onBlur={e => handleBlur('vchParameters', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={6} sx={{ p: 2 }}></Grid>
      </Grid>
    )
  }

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Typography variant='h6' mb={1}>
              {title}
            </Typography>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sx={{ p: 2 }}>
                    <Typography variant='h6' mb={1}>
                      {'Job Details'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                    <TextField
                      fullWidth
                      label={'Job Name'}
                      value={taskObj?.vchName || ''}
                      onChange={e => setTaskModel({ ...taskObj, vchName: e.target.value })}
                      error={errors?.vchName}
                      helperText={errors?.vchName?.message?.toString()}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                    <TextField
                      fullWidth
                      select
                      label='Customer'
                      value={selectedCompanyId ?? ''}
                      onChange={e => {
                        const companyId = Number(e.target.value)
                        setSelectedCompanyId(companyId)
                        setTaskModel({ ...taskObj, intCustomerId: companyId })
                      }}
                    >
                      {companies.map(company => (
                        <MenuItem key={company.intCustomerId} value={company.intCustomerId}>
                          {company.vchName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <TabContext value={tabValue}>
              <TabList onChange={handleChange} aria-label='simple tabs example'>
                <Tab value='1' label='Schedules' />
                <Tab value='2' label='Steps' />
              </TabList>
              <TabPanel value='1'>
                <Card sx={{ marginTop: '20px' }}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sx={{ p: 2 }}>
                        <Typography variant='h6' mb={1}>
                          {'Job Schedule'}
                        </Typography>
                        {taskSchedule?.map((schedule, index) => (
                          <JobScheduleItem
                            key={index}
                            schedule={schedule}
                            index={index}
                            handleScheduleChange={handleScheduleChange}
                          />
                        ))}
                        <Button variant='contained' onClick={handleAddSchedule} sx={{ marginRight: '20px' }}>
                          Add Schedule
                        </Button>
                        <Button variant='contained' onClick={handleRemoveLastSchedule}>
                          Remove last Schedule
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </TabPanel>
              <TabPanel value='2'>
                <Card sx={{ marginTop: '20px', marginBottom: '20px' }}>
                  <CardContent>
                    <Typography variant='h6' mb={1}>
                      {'Job Step'}
                    </Typography>
                    {taskSchedule?.[0]?.lstJobStep.map((stepItem, stepIndex) => (
                      <JobStepItem
                        key={stepIndex}
                        step={stepItem}
                        index={stepIndex}
                        scheduleIndex={0}
                        handleStepChange={handleStepChange}
                      />
                    ))}
                    <Button variant='contained' onClick={() => handleAddStep(0)} sx={{ marginRight: '20px' }}>
                      Add Step
                    </Button>
                    <Button variant='contained' onClick={() => handleRemoveLastStep(0)}>
                      Remove last Step
                    </Button>
                  </CardContent>
                </Card>
              </TabPanel>
            </TabContext>
            <Grid item xs={12} sx={{ p: 2 }}>
              <LoadingButton loading={saving} fullWidth variant='contained' onClick={handleSaveTask}>
                <Typography color={'white'}>{t('SAVE_TASK')}</Typography>
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default TaskEditCustomer

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  deleteAsync,
  getAsync,
  getAsyncRaw,
  getWithQueryParamsAsync,
  postAsync,
  postBlobAsync,
  putAsync
} from 'src/@http'
import {
  BaseRateDetails,
  CarrierCustomerInvoiceModel,
  CarrierInvoiceResumeDto,
  CarrierInvoiceResumePeriodDto,
  CarrierInvoicesGetModel,
  CarrierModel,
  RateSheetVersionsGetModel,
  ServicesGetModel,
  TotalsByCustomerResumeDto,
  WarehouseGetModel
} from 'src/types/models/CarrierModel'
import { ServicesCarrierModel } from 'src/types/models/ServicesCarrierModel'
import { WeigthModel, ZoneModel } from 'src/types/models/SheetModels'
import { IGenericResponse } from 'src/types/response/response'
import { logger } from 'src/utils/Logger'

import { Pagination } from '../user'

/* Endpoints definition */
const vApi = '/v1.0/'
const controlCarrier = `${vApi}ControllerCarrier`
const Carriers = (pageSize = 10, currentPage = 1, status = 'Enabled') =>
  `${vApi}ControllerCarrier/Paginate?PageSize=${pageSize}&CurrentPage=${currentPage}&Status=${status}`

const ServicesForCarrier = (params: ServicesParams) =>
  `${vApi}ControllerService/Carrier/${params.intCarrierId}?Status=${params.status}`

const ServicesForCarrierByShop = (params: ServicesParams) =>
  `${vApi}ControllerService/byShop?shopId=${params.intShopId}&carrierId=${params.intCarrierId}&Status=${params.status}`

const RateSheetVersionCarrier = (props: RateSheetVersionsCarrierParams) =>
  `${vApi}ControllerCarrierRate/Carrier/${props.intCarrierId}/Service/${props.intServiceId}/Warehouse/${props.sinWarehouseId}/measure/${props.measureId}`

const Warehouses = `${vApi}ControllerCacheWarehouse/cacheWarehouses/496`

const CarrierRateEndpoint = `${vApi}ControllerCarrierRate/`

const BaseRatesDetails = (CarrierRateId: number, measuerId: number | string) =>
  `${CarrierRateEndpoint}${CarrierRateId}/measure/${measuerId}`

const ZonesEndpoint = `${vApi}ControllerZone/`

const ZoneList = (param: number | string) => ZonesEndpoint + param

const WeigthEndpoint = `${vApi}ControllerWeigth/`

const WeigthList = (param: number | string) => WeigthEndpoint + param

const CarrierBatchInvoice = (params: CarrierInvoiceParams) =>
  `${vApi}ControllerCarrierBatchInvoice?carrierId=${params.intCarrierId}&Status=${params.status}`

const CarrierCustomerInvoice = (batchId: number | null, pageSize = 10, currentPage = 1, status = 1) =>
  `${vApi}ControllerCarrierInvoice/Paginate?batchId=${batchId}&PageSize=${pageSize}&CurrentPage=${currentPage}&Status=${status}`

const TotalInvoices = () => `${vApi}ControllerCarrierInvoice/Totals`

const TotalInvoicesByCustomer = () => `${vApi}ControllerCarrierInvoice/TotalByCustomer`

const TotalInvoicesByPeriods = () => `${vApi}ControllerCarrierInvoice/TotalByPeriods`

const ExportInvoicesCarrier = () => `${vApi}ControllerCarrierInvoice/Export`

const ServiceEndpoint = `${vApi}ControllerService/`

const ServiceById = (serviceId: number) => `${ServiceEndpoint}${serviceId}`

const NewCarrierRate = ({ serviceId, measureId }: { serviceId: number; measureId: number | string }) =>
  `${vApi}ControllerCarrierRate/new/Service/${serviceId}/measure/${measureId}`

export const getCarriers = (
  pageSize = 30,
  currentPage = 1,
  status = 'Enabled'
): Promise<{ list: CarrierModel[]; pagination: Pagination }> => {
  return getAsync(Carriers(pageSize, currentPage, status))
}

type ServicesParams = { intCarrierId: number; status: string; intShopId?: number }
export const getServicesForCarrier = ({
  intCarrierId,
  status = 'Enabled'
}: ServicesParams): Promise<ServicesGetModel[]> => {
  return getAsync(ServicesForCarrier({ intCarrierId, status }))
}

export const getServicesForCarrierByShop = ({
  intCarrierId,
  intShopId,
  status = 'Enabled'
}: ServicesParams): Promise<ServicesGetModel[]> => {
  return getAsync(ServicesForCarrierByShop({ intCarrierId, status, intShopId }))
}

type RateSheetVersionsCarrierParams = {
  intCarrierId: number
  intServiceId: number
  sinWarehouseId?: number
  measureId: number | string
}
export const getRateSheetVersionsCarrier = ({
  intCarrierId,
  intServiceId,
  measureId,
  sinWarehouseId = 2
}: RateSheetVersionsCarrierParams): Promise<RateSheetVersionsGetModel[]> => {
  return getAsync(RateSheetVersionCarrier({ intCarrierId, intServiceId, sinWarehouseId, measureId }))
}

export const getWarehouses = (): Promise<WarehouseGetModel[]> => {
  return getAsync(Warehouses)
}

export const getBaseRateDetails = (CarrierRateId: number, measuerId: number | string): Promise<BaseRateDetails> => {
  return getAsync(BaseRatesDetails(CarrierRateId, measuerId))
}

export const updateBaseRateDetails = (newBaseRateDetails: BaseRateDetails) => {
  return putAsync(CarrierRateEndpoint, newBaseRateDetails)
}

export const addBaseRateDetails = (newBaseRateDetails: BaseRateDetails) => {
  return postAsync(CarrierRateEndpoint, newBaseRateDetails)
}

export const getZone = (Status?: number, ZoneId?: number): Promise<ZoneModel[] | ZoneModel> => {
  return Status ? getAsync(ZoneList(`?Status=${Status}`)) : getAsync(ZoneList(`${ZoneId}`))
}

export const deleteZone = (ZoneId: number) => {
  return deleteAsync(ZoneList(ZoneId))
}

export const updateZone = (Zone: ZoneModel) => {
  return putAsync(ZonesEndpoint, Zone)
}

export const addZone = (newZone: ZoneModel) => {
  return postAsync(ZonesEndpoint, newZone)
}

export const getWeigth = (Status?: number, ZoneId?: number): Promise<WeigthModel[] | WeigthModel> => {
  return Status ? getAsync(WeigthList(`?Status=${Status}`)) : getAsync(ZoneList(`${ZoneId}`))
}

export const getServicesByServiceId = (serviceId: number): Promise<ServicesCarrierModel> => {
  return getAsync(ServiceById(serviceId))
}

export const updateWeigth = (Weigth: WeigthModel) => {
  return putAsync(WeigthEndpoint, Weigth)
}

export const deleteWeigth = (WeigthId: number) => {
  return deleteAsync(WeigthList(WeigthId))
}

type CarrierInvoiceParams = { intCarrierId: number; status: string }
export const getCarrierInvoices = ({
  intCarrierId,
  status = 'Enabled'
}: CarrierInvoiceParams): Promise<CarrierInvoicesGetModel[]> => {
  return getAsync(CarrierBatchInvoice({ intCarrierId, status }))
}

type CarrierCustomerInvoiceParams = {
  batchId: number | null
  PageSize: number
  CurrentPage: number
  status: number
  CustomerName: string | null
  TrackingNumber: string | null
  StartDate: string | null
  EndDate: string | null
}

export const getCarrierCustomerInvoices = ({
  batchId,
  PageSize = 10,
  CurrentPage = 1,
  status = 1
}: CarrierCustomerInvoiceParams): Promise<{ list: CarrierCustomerInvoiceModel[]; pagination: Pagination }> => {
  return getAsync(CarrierCustomerInvoice(batchId, PageSize, CurrentPage, status))
}
export const postCarrierCustomerInvoices = ({
  batchId,
  PageSize = 10,
  CurrentPage = 1,
  status = -1,
  CustomerName = null,
  TrackingNumber = null,
  StartDate = null,
  EndDate = null
}: CarrierCustomerInvoiceParams): Promise<{ list: CarrierCustomerInvoiceModel[]; pagination: Pagination }> => {
  return postAsync(CarrierCustomerInvoice(batchId, PageSize, CurrentPage, status), {
    intBatchId: batchId,
    CustomerName: CustomerName,
    TrackingNumber: TrackingNumber,
    StartDate: StartDate,
    EndDate: EndDate,
    intStatusId: -1
  })
}
export const getTotalsInvoices = (batchId: number | null, status = 1): Promise<CarrierInvoiceResumeDto> => {
  return postAsync(TotalInvoices(), {
    intBatchId: batchId,
    CustomerName: null,
    TrackingNumber: null,
    StartDate: null,
    EndDate: null,
    intStatusId: 1
  })
}

export const getTotalsInvoicesByCustomer = (
  batchId: number | null,
  customerName: string | null = null,
  trackingNumber: string | null = null,
  startDate: string | null = null,
  endDate: string | null = null,
  status = 1
): Promise<TotalsByCustomerResumeDto[]> => {
  return postAsync(TotalInvoicesByCustomer(), {
    intBatchId: batchId,
    CustomerName: customerName,
    TrackingNumber: trackingNumber,
    StartDate: startDate,
    EndDate: endDate,
    intStatusId: 1
  })
}

export const getTotalsInvoicesByPeriods = (
  batchId: number | null,
  customerName: string | null = null,
  trackingNumber: string | null = null,
  startDate: string | null = null,
  endDate: string | null = null,
  status = 1
): Promise<CarrierInvoiceResumePeriodDto[]> => {
  return postAsync(TotalInvoicesByPeriods(), {
    intBatchId: batchId,
    CustomerName: customerName,
    TrackingNumber: trackingNumber,
    StartDate: startDate,
    EndDate: endDate,
    intStatusId: 1
  })
}

export const postExportInvoicesCarrier = (
  batchId: number | null,
  customerName: string | null = null,
  trackingNumber: string | null = null,
  startDate: string | null = null,
  endDate: string | null = null,
  status = 1
): Promise<any> => {
  return postBlobAsync(ExportInvoicesCarrier(), {
    intBatchId: batchId,
    CustomerName: customerName,
    TrackingNumber: trackingNumber,
    StartDate: startDate,
    EndDate: endDate,
    intStatusId: 1
  })
}

export const getTotalsInvoicesCustomer = (
  batchId: number | null,
  status = 1,
  customerName: string
): Promise<CarrierInvoiceResumeDto> => {
  return postAsync(TotalInvoices(), {
    intBatchId: -1,
    CustomerName: customerName,
    TrackingNumber: null,
    StartDate: null,
    EndDate: null,
    intStatusId: 1
  })
}

export const addWeigth = (newWeigth: WeigthModel) => {
  return postAsync(WeigthEndpoint, newWeigth)
}

interface CarrierPost {
  vchName: string
  vchLegacyNumber: string
}

interface CarrierPut extends CarrierPost {
  intCarrierId: number
}

export const postCarrier = (carrier: CarrierPost) => {
  logger.log('postCarrier', carrier)

  return postAsync<any>(controlCarrier, carrier)
}

export const putCarrier = (carrier: CarrierPut) => {
  return putAsync<any>(controlCarrier, carrier)
}

export const deleteCarrier = (id: number) => {
  return deleteAsync<any>(controlCarrier + '/' + id)
}

export const getCarrierById = (id: number) => {
  return getAsync<any>(controlCarrier + '/' + id)
}

export const getAllCarriers = (Status = -1) => {
  return getAsync<any>(`${controlCarrier}?Status=${Status}`)
}

export const getCarriersByCustomer = (Status = 1) => {
  return getAsync<any>(`${controlCarrier}/ByCustomer?Status=${Status}`)
}

export const getCarriersByShop = (Status = 1) => {
  return getAsync<any>(`${controlCarrier}/ByShop?Status=${Status}`)
}

export const getCarriersByModuleId = (params: any) => {
  return getWithQueryParamsAsync<any>(`${controlCarrier}/ByModule`, params)
}

export const getPackagesTypesByCarrier = (carrierId: number) => {
  return getAsync<any>(`/v1.0/ControllerCarrierPackageType?Status=1&carrierId=${carrierId}&packageTypeId=-1`)
}

export const getPackagesTypesByService = (carrierId: number | null, serviceId: number) => {
  return getAsync<any>(`/v1.0/ControllerServicePackageType/allbyservice?carrierId=${carrierId}&serviceId=${serviceId}`)
}

export const deleteCarrierPackageType = (carrierPkgId: number) => {
  return getAsync<any>(`/v1.0/ControllerCarrierPackageType/Disable/${carrierPkgId}`)
}

export const getCarrierRegions = (carrierId: number) => {
  return getAsync<any>(`/v1.0/ControllerServiceRegion?carrierId=${carrierId}&status=1`)
}

export const getCarrierRegionPostalCodes = (regionId: number) => {
  return getAsync<any>(`/v1.0/ControllerServiceRegion/PostalCodes?regionId=${regionId}&status=1`)
}

export const postCarrierRegion = (body: any) => {
  return postAsync<any>(`/v1.0/ControllerServiceRegion/`, body)
}

export const putCarrierRegion = (body: any) => {
  return putAsync<any>(`/v1.0/ControllerServiceRegion/`, body)
}

export const deleteCarrierRegion = (id: number) => {
  return getAsync<any>(`/v1.0/ControllerServiceRegion/Disable/${id}`)
}

export const createCarrierRate = (params: {
  serviceId: number
  measureId: number | string
  carrierRateId?: number
}) => {
  return getAsyncRaw<IGenericResponse<any>>(NewCarrierRate(params), {
    carrierRateId: params.carrierRateId
  })
}

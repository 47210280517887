// ** React Imports
import { ElementType } from 'react'
// ** Next Imports
import { Link } from 'react-router-dom'
import Box, { BoxProps } from '@mui/material/Box'
// ** MUI Imports
import Chip from '@mui/material/Chip'
import ListItem from '@mui/material/ListItem'
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Settings } from 'src/@core/context/settingsContext'
// ** Types
import { NavGroup, NavLink } from 'src/@core/layouts/types'
// ** Configs Import
import themeConfig from 'src/configs/themeConfig'
import Translations from 'src/layouts/components/Translations'
// ** Custom Components Imports
import UserIcon from 'src/layouts/components/UserIcon'
import { shadeColor } from 'src/utils/misc'

interface Props {
  parent?: boolean
  item: NavLink
  navHover?: boolean
  settings: Settings
  navVisible?: boolean
  collapsedNavWidth: number
  navigationBorderWidth: number
  toggleNavVisibility: () => void
  isSubToSub?: NavGroup | undefined
}

// ** Styled Components
const MenuNavLink = styled(ListItemButton)<
  ListItemButtonProps & { component?: ElementType; to: string; target?: '_blank' | undefined }
>(({ theme }) => ({
  width: '100%',
  borderTopRightRadius: 100,
  borderBottomRightRadius: 100,
  color: theme.palette.text.primary,
  transition: 'padding-left .25s ease-in-out',
  '&.active': {
    '&, &:hover': {
      boxShadow: theme.shadows[3],
      backgroundColor: shadeColor(theme.palette.primary.main, 20),
    },
    '& .MuiTypography-root, & .MuiListItemIcon-root': {
      color: `black !important`
    }
  }
}))

const MenuItemTextMetaWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  transition: 'opacity .25s ease-in-out',
  ...(themeConfig.menuTextTruncate && { overflow: 'hidden' })
}))

const VerticalNavLink = ({
  item,
  parent,
  navHover,
  settings,
  navVisible,
  isSubToSub,
  collapsedNavWidth,
  toggleNavVisibility,
  navigationBorderWidth
}: Props) => {
  // ** Vars
  const { navCollapsed } = settings

  const icon = parent && !item.icon ? themeConfig.navSubItemIcon : item.icon

  const isNavLinkActive = () => {
    return window.location.pathname === item.path
  }

  return (
    <ListItem
      disablePadding
      className='nav-link'
      disabled={item.disabled || false}
      sx={{ mt: 1.5, px: '0 !important' }}
    >
      <MenuNavLink
        style={isNavLinkActive() ? {
            backgroundColor: '#F4F5FA', // Very light blue background
            color: 'black', // Ensure text color is black
            borderColor: '#014D82',
            borderWidth: '1px',
            borderStyle: 'solid'
          } : 
          {
            backgroundColor: '#F4F5FA', // Very light blue background
            color: 'black', // Ensure text color is black
          }

          
        }
      component={Link}
      data-cy={`${item.path === undefined ? '' : item.path.substring(1) + '-path'}`}
      {...(item.disabled && { tabIndex: -1 })}
      className={isNavLinkActive() ? 'active' : ''}
      to={item.path === undefined ? '/' : `${item.path}`}
      onClick={e => {
        if (item.path === undefined) {
          e.preventDefault()
          e.stopPropagation()
        }
        if (navVisible) {
          toggleNavVisibility()
        }
      }}
      sx={{
        py: 2.25,
        color: 'black !important',
        ...(item.disabled ? { pointerEvents: 'none' } : { cursor: 'pointer' }),
        pl: navCollapsed && !navHover ? (collapsedNavWidth - navigationBorderWidth - 24) / 8 : 5.5,
        pr: navCollapsed && !navHover ? ((collapsedNavWidth - navigationBorderWidth - 24) / 2 - 5) / 4 : 3.5,
        '&.active': {
          color: 'black !important', // Keep text color black when active
          backgroundColor: '#f0f8ff !important' // Ensure background color stays the same when active
        }
      }}
      >
      {isSubToSub ? null : (
        <ListItemIcon
          sx={{
            color: 'black',
            transition: 'margin .25s ease-in-out',
            ...(navCollapsed && !navHover ? { mr: 0 } : { mr: 2.5 }),
            ...(parent ? { ml: 1.25, mr: 3.75 } : {}),
            '& svg': {
              fontSize: '0.875rem',
              ...(!parent ? { fontSize: '1.5rem' } : {}),
              ...(parent && item.icon ? { fontSize: '0.875rem' } : {})
            }
          }}
        >
          <UserIcon icon={icon as string} />
        </ListItemIcon>
      )}

      <MenuItemTextMetaWrapper
        sx={{
          ...(isSubToSub ? { ml: 9 } : {}),
          ...(navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 })
        }}
      >
        <Typography
          {...((themeConfig.menuTextTruncate || (!themeConfig.menuTextTruncate && navCollapsed && !navHover)) && {
            noWrap: true
          })}
          sx={{ color: 'black' }} // Ensure text color is black
        >
          <Translations text={item.title} />
        </Typography>
        {item.badgeContent ? (
          <Chip
            label={item.badgeContent}
            color={item.badgeColor}
            sx={{
              height: 20,
              fontWeight: 500,
              '& .MuiChip-label': { px: 1.5, textTransform: 'capitalize' }
            }}
          />
        ) : null}
      </MenuItemTextMetaWrapper>
    </MenuNavLink>
    </ListItem>

  )
}

export default VerticalNavLink

import { Card } from '@mui/material'
import { format } from 'date-fns'
import CustomChip from 'src/@core/components/mui/chip'
import { carrierTransactionPaginate } from 'src/@http/carrier-account'
import { ResponseCarrierTransaction } from 'src/types/models/AccountCarriersModels'

import ServerDataGrid, { ServiceDataGridProps } from '../generic/ServerDataGrid'

const ListCarrierTransaction = (props: any) => {
  const service = async ({ currectPage, pageSize }: ServiceDataGridProps) => {
    const res = await carrierTransactionPaginate(pageSize, currectPage, props.filters)
    const list = res.list.map((value: ResponseCarrierTransaction) => {
      return {
        ...value,
        vchAmount: value.decAmount.toLocaleString('en-US')
      }
    })
    res.list = list

    return res
  }

  return (
    <Card sx={{}}>
      <ServerDataGrid checkboxSelection={false} columns={columns} service={service} />
    </Card>
  )
}

const columns = [
  { field: 'bigLSCarrierAccountTransaction', headerName: 'ID', flex: 1, minWidth: 80 },
  { field: 'intLSCarrierAccount', headerName: 'Account Courier', flex: 1, minWidth: 150 },
  { field: 'intCarrierId', headerName: 'Courier ID', flex: 1, minWidth: 100 },
  { field: 'vchName', headerName: 'Name Courier', flex: 1, minWidth: 120 },
  { field: 'vchAmount', headerName: 'Amount', flex: 1, minWidth: 120 },
  { field: 'vchDescription', headerName: 'Description', flex: 1, minWidth: 200 },
  { field: 'bigOrderId', headerName: 'Order Id', flex: 1, minWidth: 120 },
  { field: 'vchTrakingNumber', headerName: 'Tracking Number', flex: 1, minWidth: 150 },
  { field: 'vchSource', headerName: 'Source', flex: 1, minWidth: 120 },
  {
    field: 'dtmCreatedDate',
    headerName: 'Created Date',
    valueFormatter: ({ value }: { value: string }) => {
      return value ? format(new Date(value), 'MM/dd/yyyy') : '-'
    },
    minWidth: 150
  },
  { field: 'intCustomerId', headerName: 'Customer ID', flex: 1, minWidth: 150 },
  { field: 'vchCustomerName', headerName: 'Name Customer', flex: 1, minWidth: 150 },
  { field: 'intShopId', headerName: 'Shop ID', flex: 1, minWidth: 150 },
  {
    field: 'vchShopName',
    headerName: 'Name Shop',
    flex: 1,
    minWidth: 150,
    valueFormatter: ({ value }: { value: string }) => {
      return value || '-'
    }
  },
  {
    field: 'intStatusId',
    headerName: 'Status',
    renderCell: ({ row }: { row: ResponseCarrierTransaction }) => {
      return (
        <CustomChip
          skin='light'
          size='small'
          label={row.vchStatusName}
          color={(row.vchStatusColor as ColorDefault) || 'default'}
          sx={{ textTransform: 'capitalize' }}
        />
      )
    },
    flex: 1,
    minWidth: 150
  },
  { field: 'sinLSTransactionTypeId', headerName: 'Id Transaction', flex: 1, minWidth: 150 },
  { field: 'vchTransactionTypeName', headerName: 'Transaction Name', flex: 1, minWidth: 200 }
]

type ColorDefault = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'

export default ListCarrierTransaction

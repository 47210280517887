// ** React Imports
import type { ReactNode } from 'react'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
// ** i18n Imports
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

interface Props {
  title: string
  colorTitle?: string
  cancelText?: string
  confirmText?: string
  colorConfirmBtn?: any
  children?: ReactNode
  confirmDisabled?: boolean
  confirmFunc?: () => any
  closeFunc?: () => any
  show?: boolean
  maxWidth?: number
  hideActions?: boolean
}

interface DialogExpose {
  open: () => Promise<boolean>
  close: () => void
}

let resultResolve: (value: boolean | PromiseLike<boolean>) => void

const DialogGenericInvoice = forwardRef((props: Props, ref) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState<boolean>(false)

  const theme = useTheme()
  const mode = theme.palette.mode

  useEffect(() => {
    if (props.show) open()
    else close()
  }, [props.show])

  const open = (): Promise<boolean> => {
    setVisible(true)

    return new Promise<boolean>(resolve => {
      resultResolve = resolve
    })
  }
  const close = () => {
    if (props.closeFunc) props.closeFunc()
    if (resultResolve) resultResolve(false)
    setVisible(false)
  }
  const save = () => {
    if (props.closeFunc) props.closeFunc()
    if (resultResolve) resultResolve(true)

    if (props.confirmFunc) props.confirmFunc()
    setVisible(false)
  }

  useImperativeHandle(
    ref,
    (): DialogExpose => ({
      open,
      close
    })
  )

  return (
    <Dialog
      disableEscapeKeyDown
      open={visible}
      style={{ zIndex: 1200 }}
      sx={{ '& .MuiPaper-root': { width: '100%', maxWidth: props.maxWidth || 650 } }}
    >
      <DialogTitle
        color={props.colorTitle}
        sx={{
          textAlign: 'center',
          fontSize: '1.5rem !important',
          px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`],
          pt: theme => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`]
        }}
      >
        {t(props.title)}
      </DialogTitle>
      <DialogContent
        sx={{
          pb: theme => `${theme.spacing(8)} !important`,
          px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`]
        }}
      >
        <DialogContentText variant='body2' sx={{ textAlign: 'center', mb: 7 }}>
          {props.children}
        </DialogContentText>
      </DialogContent>
      {!props.hideActions && (
        <DialogActions
          sx={{
            justifyContent: 'center',
            px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`],
            pb: theme => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`]
          }}
        >
          {/* <Button
            style={{ marginTop: '10px' }}
            variant='contained'
            color={props.colorConfirmBtn}
            sx={{ mr: 2 }}
            onClick={save}
            disabled={props.confirmDisabled}
          >
            {t(props.confirmText ? props.confirmText : '')}
          </Button> */}
          <Button
            style={{ marginTop: '10px' }}
            variant='outlined'
            color={mode === 'light' ? 'secondary' : 'info'}
            onClick={close}
          >
            {t('CLOSE')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
})

DialogGenericInvoice.defaultProps = {
  //confirmText: 'SAVE',
  cancelText: 'CLOSE',
  confirmDisabled: false,
  show: false,
  hideActions: false
}

export { DialogGenericInvoice }
export type { DialogExpose }

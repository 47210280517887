import { deleteAsync, getAsync, getWithQueryParamsAsync, postAsync, putAsync } from 'src/@http'
import { ProfileModel, ProfilePermission, SecurityObjectModel, SecurityObjectPost } from 'src/types/models/ProfileModel'

import { Pagination } from '../user'

/* Endpoints definition */
const vApi = '/v1.0/'
const EndpointProfiles = `${vApi}ControllerProfile`
const EndpointSecurityObjectBase = '/v1.0/ControllerCacheSecurityObject'
const SecurityObjectEndpoint = `${vApi}ControllerSecurityObject`
const CacheModules = `${vApi}ControllerCacheModule/cacheModules`

const getListProfiles = (statusId: number) => `${EndpointProfiles}?Status=${statusId}`
const getListSecurityObjects = `${EndpointSecurityObjectBase}Type/cacheSecurityObjectType`
const ProfileById = (profileId: number) => `${EndpointProfiles}/${profileId}`
const GetSecObjectWPagination = (pageSize = 10, currentPage = 1) =>
  `${SecurityObjectEndpoint}/Paginate?PageSize=${pageSize}&CurrentPage=${currentPage}&Status=1`
const securityObjectById = (securityObjectId: number) => `${SecurityObjectEndpoint}/${securityObjectId}`

export type SchemaBodyPermissionsObject = {
  intProfileId: number
  intSecurityObjectId: number
  tinPermissionType: number
  intStatusId?: number
}

export const getProfiles = (statusId = 1): Promise<ProfileModel[]> => {
  return getAsync(getListProfiles(statusId))
}

export const deleteProfileById = (profileId: number): Promise<ProfileModel[]> => {
  return deleteAsync(ProfileById(profileId))
}

export const createProfile = (profile: ProfileModel): Promise<boolean> => {
  return postAsync(EndpointProfiles, profile)
}

export const editProfile = (profile: ProfileModel): Promise<boolean> => {
  return putAsync(EndpointProfiles, profile)
}

export const getProfileById = (profileId: number): Promise<ProfileModel> => {
  return getAsync(ProfileById(profileId))
}

// Objectos de seguridad
export type SecurityObjectTypeModel = {
  description: string
  id: number
  isActive: boolean
  lastModifiedDate?: null | string
  statusObject?: { enabled: boolean; disabled: boolean; invisible: boolean }
}
export const getSecurityTypeObjects = (): Promise<SecurityObjectTypeModel[]> => {
  return getAsync(getListSecurityObjects)
}

export const getObjectSecurity = (
  ageSize = 15,
  currentPage = 1
): Promise<{ list: SecurityObjectModel[]; pagination: Pagination }> => {
  return getAsync(GetSecObjectWPagination(ageSize, currentPage))
}

export const addNewObjectSecurity = (body: SecurityObjectModel) => {
  return postAsync(SecurityObjectEndpoint, body)
}

export const editObjectSecurity = (body: SecurityObjectModel) => {
  return putAsync(SecurityObjectEndpoint, body)
}

export const deleteObjectSecurity = (securityObjectId: number) => {
  return deleteAsync(securityObjectById(securityObjectId))
}

export const getModules = () => {
  // Ez, Customer, Client
  return getAsync<any>(CacheModules)
}

export const getObjectSecurityByModule = (moduleId: number) => {
  return getAsync(`${SecurityObjectEndpoint}/Module/${moduleId}`)
}

export const createObjectSecurity = (data: SecurityObjectPost) => {
  return postAsync(`${SecurityObjectEndpoint}`, data)
}

export const updateObjectSecurity = (data: SecurityObjectPost) => {
  return putAsync(`${SecurityObjectEndpoint}`, data)
}

export const createPermissionsToObjectSecurity = (data: SchemaBodyPermissionsObject[]) => {
  return postAsync(`${EndpointProfiles}/SaveProfilePermissions`, data)
}

export const getPermissionsByObjectSecurityId = (objSecurity: number): Promise<ProfilePermission[]> => {
  return getWithQueryParamsAsync(`${EndpointProfiles}/byObjSecurity`, { objSecurity })
}

export const getProfilesByModuleId = (params: { Status: number; moduleId: number }): Promise<ProfileModel[]> => {
  return getWithQueryParamsAsync(`${EndpointProfiles}/ByModule`, params)
}

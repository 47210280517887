import { deleteAsync, getAsync, getAsyncRaw, postAsync, postBlobAsync, putAsync } from 'src/@http'
import { RateSheetVersionsGetModel } from 'src/types/models/CarrierModel'
import {
  BaseRateDetailsShop,
  RateSheetVersionsShopModel,
  ShopInvoiceDto,
  ShopModel,
  ShopModelPost,
  TotalsByCarrierResumeDto
} from 'src/types/models/ShopModels'
import { IGenericResponse } from 'src/types/response/response'

import { Pagination } from '../user'

export interface ShopWPagination {
  list: ShopModel[]
  pagination: Pagination
}

export interface ShopInvoicePagination {
  list: ShopInvoiceDto[]
  pagination: Pagination
}

/* Endpoints definition */
const vApi = '/v1.0/'
const EndpointShops = `${vApi}ControllerShop/`
const EndpointShopInvoices = `${vApi}ControllerShopInvoice/`
const GetShops = `${EndpointShops}?Status=Enabled`

const GetShopById = (shopId: string) => `${EndpointShops}${shopId}`
const Shop = (pageSize = 10, currentPage = 1) =>
  `${EndpointShops}Paginate?PageSize=${pageSize}&CurrentPage=${currentPage}&Status=Enabled`
const RateSheetVersionShop = (params: RateSheetVersionsShopParams) =>
  `${vApi}ControllerShopRate/Shop/${params.shopId}/Customer/${params.customerId}/Carrier/${params.carrierId}/Service/${params.serviceId}/Warehouse/${params.warehouseId}/measure/${params.measureId}`
const BaseRatesDetailsShop = (shopRateId: number, measureId: number | string) =>
  `${vApi}ControllerShopRate/${shopRateId}/measure/${measureId}`
const BaseRateDetails = `${vApi}ControllerShopRate`
const ShopInvoiceDetails = (pageSize = 10, currentPage = 1) =>
  `${vApi}ControllerShopInvoice/Paginate?PageSize=${pageSize}&CurrentPage=${currentPage}`
const ProcessInvoiceShop = (batchid: number) => `${vApi}ControllerCarrierInvoice/ProcessCarrierInvoice/${batchid}`

const TotalInvoicesByCarrier = () => `${EndpointShopInvoices}TotalByCarrier`

const TotalInvoicesByPeriodsShop = () => `${EndpointShopInvoices}TotalByPeriods`

const TotalInvoicesShop = () => `${EndpointShopInvoices}Totals`

const ExportInvoicesShop = () => `${EndpointShopInvoices}Export`

// api/v1.0/ControllerCustomerRate/validate?carrierId=3&serviceId=547&start=2025-05-08&end=2025-12-31&customerId=496&shop=13

const VerifyCustomerRate = () => `${vApi}ControllerCustomerRate/validate`
const VerifyCarrierRate = () => `${vApi}ControllerCarrierRate/validate`

///api/v1.0/ControllerShopRate/new/Service/232/measure/1?shopRateId=

const NewShopRate = ({ serviceId, measureId }: { serviceId: number; measureId: number | string }) =>
  `${vApi}ControllerShopRate/new/Service/${serviceId}/measure/${measureId}`

export const getShops = (): Promise<ShopModel[]> => {
  return getAsync(GetShops)
}

export const postShops = (body: ShopModelPost): Promise<null> => {
  return postAsync(EndpointShops, body)
}

export const putShops = (body: ShopModelPost): Promise<null> => {
  return putAsync(EndpointShops, body)
}

export const deleteShop = (id: number) => {
  return deleteAsync(EndpointShops + id)
}
export const getShopsPaginate = (pageSize: number, currentPage: number): Promise<ShopWPagination> => {
  return getAsync(Shop(pageSize, currentPage))
}
export const getShopById = (shopId: string): Promise<ShopModel> => {
  return getAsync(GetShopById(shopId))
}

type RateSheetVersionsShopParams = { [key: string]: number | string | undefined }
export const getRateSheetVersionsShop = ({
  shopId,
  carrierId,
  serviceId,
  measureId,
  warehouseId = 3,
  customerId
}: RateSheetVersionsShopParams): Promise<RateSheetVersionsShopModel[]> => {
  return getAsync(RateSheetVersionShop({ shopId, carrierId, serviceId, warehouseId, measureId, customerId }))
}

export const getBaseRateShopDetails = (
  CarrierRateId: number,
  measureId: number | string
): Promise<BaseRateDetailsShop> => {
  return getAsync(BaseRatesDetailsShop(CarrierRateId, measureId))
}

export const addBaseRateDetailsShop = (newData: any) => {
  return postAsync(BaseRateDetails, newData)
}

export const getShopInvoiceDetail = (
  shopName: string,
  pageSize = 10,
  currentPage = 1,
  startDate: string | null = null,
  endDate: string | null = null,
  batchId: number | null = null,
  customerName: string | null = null
): Promise<ShopInvoicePagination> => {
  return postAsync(ShopInvoiceDetails(pageSize, currentPage), {
    intId: batchId,
    customerName: customerName,
    vchShopName: shopName,
    startDate: startDate,
    endDate: endDate,
    intStatusId: 1
  })
}

export const getProcessInvoiceShop = (batchid: number): Promise<boolean> => {
  return getAsync(ProcessInvoiceShop(batchid))
}

export const getVerifyCustomerRate = (params: {
  carrierId: number
  start: string
  end: string
  customerId?: number
  shop?: number
  serviceId: number
}) => {
  return getAsyncRaw<IGenericResponse<unknown[]>>(VerifyCustomerRate(), params)
}

export const getVerifyCarrierRate = (params: { carrierId: number; start: string; end: string; serviceId: number }) => {
  return getAsyncRaw<IGenericResponse<unknown[]>>(VerifyCarrierRate(), params)
}

export const createShopRate = (params: { serviceId: number; measureId: number | string; shopRateId?: number }) => {
  return getAsyncRaw<IGenericResponse<RateSheetVersionsGetModel>>(NewShopRate(params), {
    shopRateId: params.shopRateId
  })
}

export const getTotalsInvoicesByCarrier = (
  batchId: number | null = null,
  shopName: string | null = null,
  customerName: string | null = null,
  startDate: string | null = null,
  endDate: string | null = null,
  status = 1
): Promise<TotalsByCarrierResumeDto[]> => {
  return postAsync(TotalInvoicesByCarrier(), {
    intId: batchId,
    customerName: customerName,
    vchShopName: shopName,
    StartDate: startDate,
    EndDate: endDate,
    intStatusId: status || 1
  })
}

export const getTotalsInvoicesByPeriodsShop = (
  batchId: number | null = null,
  shopName: string | null = null,
  customerName: string | null = null,
  startDate: string | null = null,
  endDate: string | null = null,
  status = 1
): Promise<TotalsByCarrierResumeDto[]> => {
  return postAsync(TotalInvoicesByPeriodsShop(), {
    intId: batchId,
    customerName: customerName,
    vchShopName: shopName,
    StartDate: startDate,
    EndDate: endDate,
    intStatusId: status || 1
  })
}

export const getTotalsInvoicesShop = (
  batchId: number | null = null,
  shopName: string | null = null,
  customerName: string | null = null,
  startDate: string | null = null,
  endDate: string | null = null,
  status = 1
): Promise<TotalsByCarrierResumeDto[]> => {
  return postAsync(TotalInvoicesShop(), {
    intId: batchId,
    customerName: customerName,
    vchShopName: shopName,
    StartDate: startDate,
    EndDate: endDate,
    intStatusId: status || 1
  })
}

export const postExportInvoicesShop = (
  batchId: number | null = null,
  shopName: string | null = null,
  customerName: string | null = null,
  startDate: string | null = null,
  endDate: string | null = null,
  status = 1
): Promise<any> => {
  return postBlobAsync(ExportInvoicesShop(), {
    intId: batchId,
    customerName: customerName,
    vchShopName: shopName,
    StartDate: startDate,
    EndDate: endDate,
    intStatusId: status || 1
  })
}

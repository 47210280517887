import { getAsync } from 'src/@http'

const root = '/v1.0/ControllerCacheTradingPlatform/cacheTradingPlatform'

export interface ShopIntegrationGet {
  vchJsonFormat: string
  description: string
  image: string
  id: number
  isActive: string
  lastModifiedDate: string
  intStatusId?: number
}

export const getShopPlatformsIntegration = (): Promise<ShopIntegrationGet[]> => {
  return getAsync(root)
}

// import { Icon } from '@iconify/react'
import { /* Button,*/ Grid } from '@mui/material'

import CustomerInvoiceTab from '../customer-invoice-tabs'
import SummaryWidgetCustomer from '../SummaryWidgetCustomer'

const CustomerBatch = () => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <SummaryWidgetCustomer></SummaryWidgetCustomer>
        <CustomerInvoiceTab />
      </Grid>
    </Grid>
  )
}

export default CustomerBatch

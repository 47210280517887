import { deleteAsync, getAsync, postAsync, putAsync } from 'src/@http'
import { ProductShopResponse } from 'src/types/models/ShopModels'

const root = '/v1.0/ControllerShopProduct'

export interface ShopProductResponse {
  list: ProductShopResponse[]
  pagination: {
    numberOfPages: number
    actualPage: number
    numberOfRecords: number
    pageSize: number
  }
}

interface ShopProductProps {
  customerId: number
  PageSize: number
  CurrentPage: number
}

export interface ShopProductPost {
  legacyNumber: string
  name: string
  intShopProductType: number
  intShopId: number
  vchSourceId: string
  vchPartNumber: string
  decWeight: number
  decPrice: number
  vchTariffCode: string
  decReserveQty: number
  decReserveQty2: number
  decReserveQty3: number
  vchDescription: string
  sinPackType: number
  decWidth: number
  decLength: number
  decHeight: number
  vchR4ShopId: string
}

export interface ShopProductPut {
  intShopProductId: number
  legacyNumber: string
  name: string
  intShopProductType: number
  intShopId: number
  intStatusId: number
  vchSourceId: string
  vchPartNumber: string
  decWeight: number
  decPrice: number
  vchTariffCode: string
  decReserveQty: number
  decReserveQty2: number
  decReserveQty3: number
  vchDescription: string
  sinPackType: number
  decWidth: number
  decLength: number
  decHeight: number
  vchR4ShopId: string
}

export const getShopProduct = ({
  customerId,
  PageSize,
  CurrentPage
}: ShopProductProps): Promise<ShopProductResponse> => {
  return getAsync(
    root +
      `/Shop/${customerId}/ShopProductType/-1/ShopProductPackType/-1/Paginate?PageSize=${PageSize}&CurrentPage=${CurrentPage}&Status=Enabled`
  )
}

export const postShopProduct = (data: ShopProductPost): Promise<ShopProductResponse> => {
  return postAsync(root, data)
}

export const putShopProduct = (data: ShopProductPut): Promise<ShopProductResponse> => {
  return putAsync(root, data)
}

export const deleteShopProduct = (id: number): Promise<boolean> => {
  return deleteAsync(root + '/' + id)
}

import { EventModel, EventTypeModel } from "src/types/models/EventModel";

import { Pagination } from "./user";
import { getAsync, getAsyncAdmin } from ".";

const Event = '/v1.0/ControllerEvent'
const EventType = (status = 1) => Event + `/EventType?Status=${status}`
const EventByType = (eventType: number) => Event + `/${eventType}`
const EventPaginate = (pageSize: number, currentPage: number, eventType: number) => `${Event}/Paginate?PageSize=${pageSize}&CurrentPage=${currentPage}&eventType=${eventType}`

export const getListEventPaginate = (pageSize = 10, currentPage = 1, eventType: number): Promise<{ list: EventModel[]; pagination: Pagination }> => {
  return getAsyncAdmin(EventPaginate(pageSize, currentPage, eventType))
}

export const getListEventType = (status: number): Promise<EventTypeModel[]> => {
  return getAsync(EventType(status))
}

export const getEventByType = (eventType: number): Promise<EventModel> => {
  return getAsync(EventByType(eventType))
}

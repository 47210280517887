import { Control, Controller,FieldValues } from "react-hook-form"
import { Grid, TextField } from "@mui/material"
import { capitaliseFirstLetter } from "src/utils/misc"

export const FormFieldsByJson = (jsonForm: string, control: Control<FieldValues>, disabled?: boolean) => {
  if (jsonForm === '') {
    return <>An error has occurred.</>
  }

  const json = JSON.parse(jsonForm)
  const formAttr = []
  for (const property in json) {
    formAttr.push({ property: capitaliseFirstLetter(property), value: json[property] })
  }

  return (
    <Grid container sx={{m:0, p:0}}>
      {formAttr.map(field => {
        return (
          <Grid item key={field.property} xs={12} sx={{ py: 2 }}>
            <Controller
              name={field.property}
              control={control}
              rules={{ required: false }}
              defaultValue={field.value}
              render={({ field: { value, onChange } }) => (
                <TextField
                  id={field.property}
                  onChange={onChange}
                  value={value}
                  autoFocus
                  InputLabelProps={{ shrink: true }}
                  InputProps={{readOnly: disabled}}
                  fullWidth
                  label={field.property}
                />
              )}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}
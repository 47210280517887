import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Icon } from '@iconify/react'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { TFunction } from 'i18next'
import { getShopById, postShops, putShops } from 'src/@http/shop'
import { RtaUploadFile, uploadFile, UploadFileType } from 'src/@http/uploadFile'
import AutocompleteUserManager from 'src/pages/components/customer/AutocompleteUserManager'
import CloneSheetForm from 'src/pages/components/customer/CloneSheetForm'
import ShopPlatformIntegration from 'src/pages/components/customer/ShopPlatformIntegration'
import BoxPreviewImage from 'src/pages/components/generic/BoxPreviewImage'
import { DialogExpose, DialogGeneric } from 'src/pages/components/generic/DialogGeneric'
import { AddressObjPost, IntegrationDataObj } from 'src/types/models/ShopModels'
import { showIfValidImage } from 'src/utils/misc'
// import { logger } from 'src/utils/Logger'
import AccountInformationForm from 'src/views/forms/AccountInformationForm'
import FileUploadImageSingle from 'src/views/forms/FileUploadImageSingle'
import appStore from 'src/zustand/app'
import * as yup from 'yup'

interface AddressModel {
  contact: string
  company: string
  telephone: string
  email: string
  cityId: number
  countryId: number
  stateId: number
  postCode: string
  vchAddress: string
  address1: string
}

interface FormEditAddCustomer {
  id?: number
  vchName: string
  vchSourceId: string
  vchLegacyNumber: string
  intWarehouseRoutingType: number
  intInternationalDutlType: number
  intAccountManager: number
  intAccountManagerBackup: number
  bitShipComplete: boolean
  bitCredit_hold: boolean
  bitShipSameDay: boolean
  bitAutoPull: boolean
  bitAutoPush: boolean
  addressForm?: AddressObjPost
  vchComments: string
  vchAdditionalInfoAddress: string
  vchLogoPath?: string | null
}

const initialForm: FormEditAddCustomer = {
  id: 0,
  vchName: '',
  vchSourceId: '',
  vchLegacyNumber: '',
  intWarehouseRoutingType: 0,
  intInternationalDutlType: 0,
  intAccountManager: 0,
  intAccountManagerBackup: 0,
  bitShipComplete: false,
  bitCredit_hold: false,
  bitShipSameDay: false,
  bitAutoPull: false,
  bitAutoPush: false,
  vchLogoPath: null,
  addressForm: {
    vchAddress: '',
    intParentId: null,
    intCountryId: 0,
    intCityId: 0,
    intStateId: 0,
    vchPostCode: '',
    intStatusId: 0
  },
  vchComments: '',
  vchAdditionalInfoAddress: ''
}

const initIntegrationData: IntegrationDataObj = {
  intTradingPlatformId: 0,
  vchConnection: '',
  intStatusId: 1
}

// Mockup data
const listWarehouseRouting = [
  { id: 1, text: 'UT Only' },
  { id: 2, text: 'GA Only' },
  { id: 3, text: 'Route by Shipping Zone' },
  { id: 4, text: 'Route by Shipping Zone then Inventory' }
]

const CustomerAddOrEdit = () => {
  const { t } = useTranslation()
  const modalLogoUploader = useRef<DialogExpose>(null)

  const [editable, setEditable] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [customerSelected, setCustomerSelected] = useState<any>(null)
  const { state } = useLocation()
  const navigate = useNavigate()
  const { paramsV2 } = appStore()

  const [integrationData, setIntegrationData] = useState<IntegrationDataObj[]>([initIntegrationData])
  const [showCloneSheet, setShowCloneSheet] = useState<boolean>(false)
  const [fileLogo, setFileLogo] = useState<string | File | null>(null)

  const title =
    state.mode === 'add' ? getTitleByMode(state.mode, t) : getTitleByMode(state.mode, t, state.data?.intShopId)

  // Form
  const schema = yup.object().shape({
    vchName: yup.string().required(),
    vchLegacyNumber: yup.string().required(`${t('LEGACY_ID')} is required.`),
    vchSourceId: yup.string().required(`${t('SOURCE_ID')} is required.`),
    bitShipComplete: yup.boolean(),
    bitCredit_hold: yup.boolean(),
    bitShipSameDay: yup.boolean(),
    bitAutoPull: yup.boolean(),
    autoPushR4: yup.boolean(),
    intWarehouseRoutingType: yup.number(),
    intInternationalDutlType: yup.number(),
    intAccountManagerBackup: yup.number()
  })

  const form = useForm({
    defaultValues: state.mode === 'add' ? initialForm : state.data,
    resolver: yupResolver(schema)
  })

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors }
  } = form

  useEffect(() => {
    if (state.mode === 'add') setEditable(true)

    const customerState = state.data
    if (customerState) {
      setEditable(false)
      getDetailsCustomer(customerState.id.toString())
    }
  }, [])

  const getDetailsCustomer = async (id: string) => {
    await getShopById(id)
      .then((res: any) => {
        setCustomerSelected(res)
        setIntegrationData(res.vchIntegrationData)
        setFileLogo(res.vchLogoPath)
        setValue('vchLogoPath', res.vchLogoPath)
      })
      .catch((e: any) => console.log(e))
  }

  const addressFormRef = useRef<{
    validate: () => Promise<boolean>
  } | null>(null)

  const handleSaveCustomer = async (data: FormEditAddCustomer, e: any) => {
    e.preventDefault()
    setLoading(true)

    if (!(await addressFormRef.current?.validate())) {
      setLoading(false)

      return
    }

    const addressStatus: AddressModel = getValues('addressForm')
    const newAddressObj = generateAddressObject(addressStatus)
    const checkboxesStates = setBooleanValuesCheckboxes(data)
    delete data.addressForm
    let uploadedLogo = getValues('vchLogoPath') // By default, use the current logo

    if (!(typeof fileLogo === 'string' && fileLogo === uploadedLogo)) {
      // If the logo file is different from the current one, then upload
      uploadedLogo = await uploadLogo().catch((err: any) => {
        toast.error(err.DescriptionError || err.message || 'Error uploading logo')
      })
    }

    data.vchLogoPath = uploadedLogo

    if (customerSelected) {
      const prevIntegrations: IntegrationDataObj[] = customerSelected.vchIntegrationData.map((int: any) => {
        return {
          intTradingPlatformId: int.intTradingPlatformId,
          vchConnection: int.vchConnection,
          intStatusId: 2 // Disable if you have set up an integration before.
        }
      })

      const customer = {
        ...data,
        ...checkboxesStates,
        addressObj: newAddressObj,
        vchIntegrationData: [...prevIntegrations, ...integrationData],
        vchEmail: addressStatus?.email || '',
        vchTelephone: addressStatus?.telephone || ''
      }
      callSaveChanges(customer, 'edit')
    } else if (state.mode === 'add') {
      const customer = {
        ...data,
        intCustomerId: paramsV2.CustomerId,
        sinShopType: 1,
        intStatusId: 1,
        intParentId: 3,
        ...checkboxesStates,
        addressObj: newAddressObj,
        vchEmail: addressStatus?.email || '',
        vchTelephone: addressStatus?.telephone || '',
        vchIntegrationData: integrationData
      }
      await callSaveChanges(customer, 'add')
    }
  }

  const callSaveChanges = async (data: any, mode: string) => {
    if (mode === 'edit') {
      try {
        await putShops(data)
        toast.success(t('SUCCESS_EDIT_CUSTOMER'))
        setEditable(false)
      } catch (err: any) {
        toast.error(err.DescriptionError)
      } finally {
        setLoading(false)
      }
    } else if (mode === 'add') {
      try {
        await postShops(data)
        toast.success(t('SUCCESS_CREATE_CUSTOMER'))
        setEditable(false)
        navigate(-1)
      } catch (err: any) {
        console.log(err)
        toast.error(err.DescriptionError)
      } finally {
        setLoading(false)
      }
    }
  }

  async function uploadLogo() {
    try {
      if (fileLogo) {
        const res = (await uploadFile(fileLogo as File, UploadFileType.Shop)) as RtaUploadFile

        return res.virtualPathFile
      } else {
        throw new Error('File not exist')
      }
    } catch (err: any) {
      throw new Error(err)
    }
  }

  const handleAccountInformation = (fieldEdited: any) => {
    const prevAddressValues = getValues('addressForm')
    setValue('addressForm', { ...prevAddressValues, ...fieldEdited })
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography variant='h6' mb={1}>
          {title}
        </Typography>
        <form onSubmit={handleSubmit(handleSaveCustomer)} autoComplete='off' noValidate>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                {!editable && (
                  <Grid item xs={12} sx={{ p: 2 }}>
                    <Button
                      variant='contained'
                      fullWidth
                      onClick={() => setEditable(true)}
                      endIcon={<Icon color={'white'} icon='ic:outline-edit' />}
                    >
                      <Typography color={'white'}>{t('EDIT')}</Typography>
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} lg={12} sx={{ p: 2 }}>
                  <TextField
                    fullWidth
                    label={t('NAME')}
                    {...register('vchName')}
                    error={!!errors.vchName}
                    helperText={errors?.vchName?.message?.toString()}
                    inputProps={{ readOnly: !editable }}
                  />
                </Grid>
                <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                  <TextField
                    fullWidth
                    label={t('SOURCE_ID')}
                    {...register('vchSourceId')}
                    error={!!errors.vchSourceId}
                    helperText={errors?.vchSourceId?.message?.toString()}
                    inputProps={{ readOnly: !editable }}
                  />
                </Grid>
                <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                  <TextField
                    fullWidth
                    label={t('LEGACY_ID')}
                    {...register('vchLegacyNumber')}
                    error={!!errors.vchLegacyNumber}
                    helperText={errors?.vchLegacyNumber?.message?.toString()}
                    inputProps={{ readOnly: false }}
                  />
                </Grid>
                <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                  <FormControl fullWidth variant='outlined'>
                    <InputLabel htmlFor='select-warehouse'>{t('WAREHOUSE_ROUTING')}</InputLabel>
                    <Controller
                      name='intWarehouseRoutingType'
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          value={value}
                          onChange={onChange}
                          label={t('WAREHOUSE_ROUTING')}
                          inputProps={{ readOnly: !editable }}
                          labelId='select-warehouse'
                        >
                          {listWarehouseRouting.map((wr, i) => (
                            <MenuItem key={i} value={wr.id}>
                              {wr.text}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                  <FormControl fullWidth variant='outlined'>
                    <InputLabel htmlFor='select-int-duty'>Intl Duty</InputLabel>
                    <Controller
                      name='intInternationalDutlType'
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          value={value}
                          onChange={onChange}
                          label='Intl Duty'
                          inputProps={{ readOnly: !editable }}
                          labelId='select-int-duty'
                        >
                          <MenuItem value={1}>DDU</MenuItem>
                          <MenuItem value={2}>DDP</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                  <FormControl fullWidth>
                    <Controller
                      name='intAccountManager'
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange } }) => (
                        <AutocompleteUserManager
                          disabled={!editable}
                          userSelected={customerSelected?.intAccountManager}
                          label={t('ACCOUNT_MANAGER')}
                          onSelectCustomer={userManager => onChange(userManager.intAccountManager)}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6} sx={{ p: 2 }}>
                  <FormControl fullWidth>
                    <Controller
                      name='intAccountManagerBackup'
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange } }) => (
                        <AutocompleteUserManager
                          disabled={!editable}
                          userSelected={customerSelected?.intAccountManagerBackup}
                          label={t('BACKUP_MANAGER')}
                          onSelectCustomer={userManager => onChange(userManager.intAccountManager)}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid container xs={6} sx={{ p: 2 }} direction='row' justifyContent='flex-end'>
                  <Controller
                    control={control}
                    name='bitShipComplete'
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        sx={{ maxWidth: 'fit-content' }}
                        disabled={!editable}
                        labelPlacement='start'
                        control={<Checkbox checked={value} onChange={onChange} color='success' />}
                        label={t('SHIP_COMPLETE')}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='bitCredit_hold'
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        sx={{ maxWidth: 'fit-content' }}
                        disabled={!editable}
                        labelPlacement='start'
                        control={<Checkbox checked={value} onChange={onChange} color='success' />}
                        label={t('CREDIT_HOLD')}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='bitShipSameDay'
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        sx={{ maxWidth: 'fit-content' }}
                        disabled={!editable}
                        labelPlacement='start'
                        control={<Checkbox checked={value} onChange={onChange} color='success' />}
                        label={t('SHIP_SAME_DAY')}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='bitAutoPull'
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        sx={{ maxWidth: 'fit-content' }}
                        disabled={!editable}
                        labelPlacement='start'
                        control={<Checkbox checked={value} onChange={onChange} color='success' />}
                        label={t('AUTO_PULL')}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='bitAutoPush'
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        sx={{ maxWidth: 'fit-content' }}
                        disabled={!editable}
                        labelPlacement='start'
                        control={<Checkbox checked={value} onChange={onChange} color='success' />}
                        label={t('AUTOPUSH_TO_R4')}
                      />
                    )}
                  />
                </Grid>
                <Grid container xs={6} sx={{ p: 2 }} direction='row' justifyContent='flex-end' alignContent='center'>
                  <Grid item xs={12} sx={{ p: 2 }}>
                    <Button fullWidth disabled={!editable} onClick={() => setShowCloneSheet(true)} variant='contained'>
                      <Typography color={'white'}>{t('CLONE_SHEET')}</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={12} sx={{ p: 2 }}>
                    <Button
                      fullWidth
                      disabled={!editable}
                      onClick={() => modalLogoUploader.current?.open()}
                      variant='contained'
                    >
                      <Typography color={'white'}>{t('UPLOAD_LOGO')}</Typography>
                    </Button>
                    {fileLogo && showIfValidImage(fileLogo) && (
                      <BoxPreviewImage
                        image={fileLogo}
                        heightBox={80}
                        showActions={!editable ? false : true}
                        handleIconAction={(action: string) => {
                          if (action === 'IconDelete') setFileLogo(null)
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {showCloneSheet && (
            <Grid sx={{ mt: 2 }}>
              <CloneSheetForm
                cancelClone={() => setShowCloneSheet(false)}
                onSelectParams={params => console.log('Params : ', params)}
              />
            </Grid>
          )}

          <Card sx={{ mt: 3 }}>
            <CardHeader title={t('CONTACT')} style={{ fontWeight: 400 }} />
            <CardContent>
              <AccountInformationForm
                ref={addressFormRef}
                disabled={!editable}
                defaultValues={customerSelected}
                onChange={handleAccountInformation}
                fieldsToShow={['telephone', 'email', 'city', 'country', 'state', 'postCode']}
                styleCard={{ my: 0, boxShadow: 'none' }}
              />
            </CardContent>
          </Card>

          <Card sx={{ mt: 3 }}>
            <CardContent>
              <Grid container spacing={2} p={2}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    disabled={!editable}
                    rows={4}
                    multiline
                    variant='filled'
                    label={t('COMMENTS')}
                    {...register('vchComments')}
                    onChange={e => setValue('vchComments', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    disabled={!editable}
                    rows={4}
                    multiline
                    variant='filled'
                    label={t('ADDITIONAL_EMAIL_ADDRESSES_FOR_ORDER_ALERTS')}
                    placeholder={t('CLARIFICATION_TEXTAREA_ADDITIONAL_ADDRESS').toString()}
                    {...register('vchAdditionalInfoAddress')}
                    onChange={e => setValue('vchAdditionalInfoAddress', e.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <ShopPlatformIntegration
            viewMode={editable ? 'edit' : 'view'}
            shopsSelected={state?.mode !== 'add' && integrationData ? integrationData : undefined}
            onSavePlatform={(shopIntegration: IntegrationDataObj[]) => {
              if (state?.mode === 'add') {
                setIntegrationData(shopIntegration)
              } else {
                setIntegrationData([...shopIntegration])
              }
            }}
            style={{ mt: 3 }}
          />

          <Grid item xs={12} sx={{ p: 2 }}>
            {editable ? (
              <LoadingButton loading={loading} fullWidth variant='contained' type='submit'>
                <Typography color={'white'}>{t('SAVE_CUSTOMER')}</Typography>
              </LoadingButton>
            ) : (
              <Grid container xs={12} flexDirection={'row'}>
                <Grid item xs={6} sx={{ p: 2 }}>
                  <Button
                    variant='contained'
                    fullWidth
                    onClick={() => navigate(-1)}
                    startIcon={<Icon color={'white'} icon='ion:arrow-back' />}
                  >
                    <Typography color={'white'}>{t('BACK')}</Typography>
                  </Button>
                </Grid>
                <Grid item xs={6} sx={{ p: 2 }}>
                  <Button
                    variant='contained'
                    fullWidth
                    onClick={() => setEditable(true)}
                    endIcon={<Icon color={'white'} icon='ic:outline-edit' />}
                  >
                    <Typography color={'white'}>{t('EDIT')}</Typography>
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      </Grid>

      <DialogGeneric ref={modalLogoUploader} title={t('UPLOAD_LOGO')} styleBoxContent={{}}>
        <FileUploadImageSingle
          selectedImage={fileLogo as File}
          handleFileImageUploaded={(file: File | null) => setFileLogo(file)}
        />
      </DialogGeneric>
    </Grid>
  )
}

const getTitleByMode = (mode: string, t: TFunction<'translation', undefined, 'translation'>, id?: number) => {
  if (mode === 'add') return t('CUSTOMER_ADD')
  if (mode === 'edit') return `${t('CUSTOMER_EDIT')}: ${id}`
  if (mode === 'view') return `View Client: ${id}`
}

const generateAddressObject = (addressStatus: any): AddressObjPost => {
  return {
    vchAddress: addressStatus?.address1 || '',
    intParentId: '0',
    intCountryId: addressStatus?.countryId || 0,
    intCityId: addressStatus?.cityId || 0,
    intStateId: addressStatus?.stateId || 0,
    vchPostCode: addressStatus?.postCode,
    intStatusId: 1
  }
}

const setBooleanValuesCheckboxes = (checkbox: any) => {
  return {
    bitAutoPush: checkbox.bitAutoPush ? 1 : 0,
    bitShipSameDay: checkbox.bitShipSameDay ? 1 : 0,
    bitCredit_hold: checkbox.bitCredit_hold ? 1 : 0,
    bitShipComplete: checkbox.bitShipComplete ? 1 : 0,
    bitAutoPull: checkbox.bitAutoPull ? 1 : 0
  }
}

export default CustomerAddOrEdit

import { useEffect, useState } from "react";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import { getCarrierRegions } from "src/@http/carriers";
import { logger } from "src/utils/Logger";

interface Region {
  id: number;
  vchName: string;
}

interface SelectorRegionProps {
  title: string;
  carrierId: number;
  selectedRegion: number | null;
  onChange: (regionId: number) => void;
  disabled?: boolean;
}

const SelectorRegion = (props: SelectorRegionProps) => {
  const [regions, setRegions] = useState<Region[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<Region>(null as unknown as Region); // This is a hack to avoid setting null to a non-nullable type, it should be null as Region | null =
  logger.log('selectedRegion', selectedRegion)
  useEffect(() => {
    const fetchRegions = async () => {
      const regionList = await getCarrierRegions(props.carrierId);
      setRegions(regionList);
      const initialRegion = regionList.find((region: Region) => region.id === props.selectedRegion);
      setSelectedRegion(initialRegion || null);
    };
    fetchRegions();
  }, [props.carrierId, props.selectedRegion]);

  return (
    <FormControl fullWidth>
      <Autocomplete
        disableClearable
        fullWidth
        title={props.title}
        value={selectedRegion}
        onChange={(event, newValue) => {
          setSelectedRegion(newValue);
          props.onChange(newValue.id);
        }}
        options={regions}
        getOptionLabel={(option) => option.vchName}
        renderInput={(params) => <TextField {...params} label="Region" />}
        defaultValue={regions.find((region) => region.id === props.selectedRegion)}
        disabled={props.disabled}
      />
    </FormControl>
  );
};

export default SelectorRegion;
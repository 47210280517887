import { Card, Grid, Stack, Typography, useTheme } from '@mui/material'

const ShipmentAccesorialSummary = () => {
  const theme = useTheme()

  const info = [
    {
      title: 'Shipment Summary',
      items: [
        { titleItem: 'Shipping Charges', data: '$15,841,913' },
        { titleItem: 'Package Volume', data: '1,415,591' },
        { titleItem: 'Avg $/Pack', data: '$11.19' }
      ]
    },
    {
      title: 'Accessorial Summary',
      items: [
        { titleItem: 'Accessorial Charges', data: '$5,110,533' },
        { titleItem: 'Acc % of Spend', data: '32.26%' },
        { titleItem: 'Avg Acc $/Pack', data: '$3.61' }
      ]
    }
  ]

  const themeConfig = {
    backgroundColor: theme.palette.mode === 'light' ? '#ebebeb' : theme.palette.secondary.dark,
    backgroundColorItems: theme.palette.mode === 'light' ? '#FBFBFB' : '#2b2842'
  }

  return (
    <Card sx={{ width: '100%', minHeight: 360 }}>
      <Stack flexDirection='row' px={2} pt={3} pb={2}>
        {info.map((item, index) => (
          <Grid
            container
            sx={{ minHeight: 340 }}
            md={6}
            xs={12}
            key={index}
            flexDirection='column'
            justifyContent='space-between'
          >
            <Stack
              sx={{ backgroundColor: themeConfig.backgroundColor, m: 1, p: 2 }}
              // padding={2}
              // flexDirection='column'
              // alignItems='center'
            >
              <Typography variant='h6' textAlign='center' fontWeight={600}>
                {item.title}
              </Typography>
            </Stack>
            {item.items.map((block, i) => (
              <Stack
                key={i}
                my={2}
                py={1}
                flexDirection='column'
                alignItems='center'
                sx={{ backgroundColor: themeConfig.backgroundColorItems }}
              >
                <Typography variant='body1'>{block.titleItem}</Typography>
                <Typography variant='h5' color='primary'>
                  {block.data}
                </Typography>
              </Stack>
            ))}
          </Grid>
        ))}
      </Stack>
    </Card>
  )
}

export default ShipmentAccesorialSummary

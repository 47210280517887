import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { Button, SxProps, Theme, Typography } from '@mui/material'

interface GenericButtonProps {
  icon?: string
  iconSize?: number | string
  children?: ReactNode
  to?: string
  state?: { [key: string]: any }
  colorText?: string
  sx?: SxProps<Theme>
  onClick?: (event: any) => void
  disabled?: boolean
}

const GenericButton = ({ children, sx, ...props }: GenericButtonProps) => {
  if (props.to) {
    return (
      <Link to={props.to} state={props.state || undefined} style={{ textDecoration: "none" }}>
        <Button disabled={props.disabled || false} variant="contained" {...props} sx={{ textAlign: 'center', ...sx }} onClick={props.onClick}>
          {props.icon && <Icon fontSize={props.iconSize || undefined} icon={props.icon} color="white" />}
          <Typography color={props.colorText || 'white'} component="span" pl={1}>
            {children}
          </Typography>
        </Button>
      </Link>
    )
  }

  return props.icon ? (
    <Button disabled={props.disabled || false} variant="contained" {...props} sx={{ textAlign: 'center', ...sx }} onClick={props.onClick}>
      {props.icon && <Icon fontSize={props.iconSize || undefined} icon={props.icon} color="white" />}
      <Typography color={props.colorText || 'white'} component="span" pl={1}>
        {children}
      </Typography>
    </Button>
  ) : (
    <Button disabled={props.disabled || false} variant="contained" {...props} sx={sx} onClick={props.onClick}>
      <Typography color={props.colorText || 'white'} component="span">
        {children}
      </Typography>
    </Button>
  )
}

export default GenericButton
